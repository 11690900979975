import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CustomParamsSerializer,
} from "axios"

import { getEnv } from "src/env"

const baseURL = getEnv().U1_API_BASE_URL

// 配列のクエリパラメータをカンマ区切りにする
const paramsSerializer: CustomParamsSerializer = (params) => {
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (value != null) {
      searchParams.append(key, value)
    }
  }
  return searchParams.toString().replace(/%2C/g, ",")
}

// カスタムインスタンスにすると認証処理が動かなくなるのでデフォルトインスタンスを使用する
// export const AXIOS_INSTANCE = axios.create({
//   baseURL: getEnv().U1_API_BASE_URL,
// })

// ref: https://orval.dev/guides/custom-axios
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  // index.tsx で axios.defaults.baseURL を設定しているが、テストを通すためにここでも baseURL を指定する必要がある
  // TODO: 置き換えが完了したら axios.defaults.baseURL を削除する
  return axios({
    baseURL,
    paramsSerializer,
    ...config,
    ...options,
  })
}
