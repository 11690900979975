import { useState, useMemo } from "react"

import { Stack, TableHead, TableRow } from "@mui/material"
import { useSetRecoilState } from "recoil"

import {
  Material,
  MaterialInventoryHistory,
  MaterialMachineStock,
  MaterialShelfStock,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
  MaterialMachinesElement,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { InventoryMaterialAmMachineWarningCard } from "src/components/organisms/materials/InventoryMaterialAmMachineWarningCard"
import { InventoryMaterialExecuteMultipleFilter } from "src/components/organisms/materials/InventoryMaterialExecuteMultipleFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryMaterialExecute } from "src/components/templates/InventoryMaterialExecute"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { snackbarErrorMessageState } from "src/recoil"

export type InventoryMaterialExecuteMultipleMenuItem = {
  material: Material
  stocks: {
    placementName: string
    placementStockId: MaterialShelfStock["id"] | MaterialMachineStock["id"]
    stock: MaterialShelfStock["stock"] | MaterialMachineStock["stock"]
    executedStock: MaterialInventoryHistory["stock"]
  }[]
}

interface InventoryMaterialExecuteMultipleProps {
  placementType: MaterialPlacementType
  placementStocks?: ((
    | MaterialOperationShelfStock
    | MaterialOperationMachineStock
  ) & {
    material: Material
  })[]
  histories?: MaterialInventoryHistory[]
  menuItems?: InventoryMaterialExecuteMultipleMenuItem[]
  warningMaterialMachines?: MaterialMachinesElement[]
  onSelected: (isSelected: boolean) => void
  onFinish?: () => void
}

export const InventoryMaterialExecuteMultiple: React.FC<
  InventoryMaterialExecuteMultipleProps
> = ({
  placementType,
  placementStocks,
  menuItems = [],
  histories,
  warningMaterialMachines = [],
  onSelected,
  onFinish = () => undefined,
}: InventoryMaterialExecuteMultipleProps) => {
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<InventoryMaterialExecuteMultipleMenuItem>()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const selectedPlacementStocks =
    useMemo(
      () =>
        placementStocks?.filter(
          (placementStock) =>
            placementStock.material.materialCd ===
            selectedMenuItem?.material.materialCd,
        ),
      [selectedMenuItem, placementStocks],
    ) || []

  useMemo(
    () => onSelected(selectedMenuItem !== undefined),
    [selectedMenuItem, onSelected],
  )

  if (selectedMenuItem && selectedPlacementStocks.length > 0 && histories) {
    return (
      <InventoryMaterialExecute
        placementType={placementType}
        placementStocks={selectedPlacementStocks}
        onFinish={() => {
          setSelectedMenuItem(undefined)
          onFinish()
        }}
        material={selectedMenuItem.material}
      />
    )
  } else {
    return (
      <Stack gap={2}>
        <InventoryMaterialExecuteMultipleFilter />
        {warningMaterialMachines.length > 0 && (
          <InventoryMaterialAmMachineWarningCard
            materialMachines={warningMaterialMachines}
          />
        )}
        <Stack>
          <PaginatedTable
            noMargin
            items={menuItems}
            stateKey="inventoryMaterialExecuteMultipleList"
            header={
              <TableHead>
                <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
                  <ExtTableCell>材料名</ExtTableCell>
                  <ExtTableCell>材料コード</ExtTableCell>
                  <ExtTableCell>
                    {placementType === MaterialPlacementType.InMachine
                      ? "シリンダー名"
                      : "棚名"}
                  </ExtTableCell>
                </TableRow>
              </TableHead>
            }
            renderRow={(menuItem) => {
              const { material, stocks } = menuItem
              const isEditable = stocks !== undefined
              const onClickRow = () => {
                if (isEditable) {
                  setSelectedMenuItem(menuItem)
                } else {
                  setErrorMessage("在庫が存在しなくなったため、編集できません")
                }
              }

              return (
                <TableBorderedRow
                  key={material.materialCd}
                  data-testid={material.materialCd}
                  sx={{
                    td: {
                      p: 1,
                    },
                    "&:hover td": {
                      cursor: "pointer",
                      background: (theme) => theme.palette.neutral[200],
                    },
                  }}
                  onClick={onClickRow}
                >
                  <ExtTableCell sx={{ whiteSpace: "nowrap" }}>
                    {material.materialName}
                  </ExtTableCell>
                  <ExtTableCell sx={{ whiteSpace: "nowrap" }}>
                    {material.materialCd}
                  </ExtTableCell>
                  <ExtTableCell sx={{ whiteSpace: "nowrap" }}>
                    {stocks.map((stock) => stock.placementName).join(", ")}
                  </ExtTableCell>
                </TableBorderedRow>
              )
            }}
          />
        </Stack>
        <BackButton>戻る</BackButton>
      </Stack>
    )
  }
}
