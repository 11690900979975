/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  DeleteMaterialOperationStocksRequest,
  GetMaterialOperationStocksParams,
  GetMaterialOperationStocksResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】店内在庫を削除
 */
export const deleteMaterialOperationStocks = (
  arcadeCd: string,
  deleteMaterialOperationStocksRequest: DeleteMaterialOperationStocksRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/stocks`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: deleteMaterialOperationStocksRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】在庫検索
 */
export const getMaterialOperationStocks = (
  arcadeCd: string,
  params?: GetMaterialOperationStocksParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialOperationStocksResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/stocks`,
      method: "GET",
      params,
    },
    options,
  )
}
export type DeleteMaterialOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof deleteMaterialOperationStocks>>
>
export type GetMaterialOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialOperationStocks>>
>
