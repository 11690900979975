/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizePlacementStatusesParams,
  GetPrizePlacementStatusesResponse,
  GetPrizePlacementTypeStatusResponse,
  PutPrizePlacementStatusRequest,
  PutPrizePlacementTypeStatusRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary PlacementType 毎の棚卸状態を設定
 */
export const putPrizePlacementTypeStatus = (
  arcadeCd: string,
  placementType: "storage" | "on_booth" | "in_booth",
  putPrizePlacementTypeStatusRequest: PutPrizePlacementTypeStatusRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/placementType/${placementType}/status`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizePlacementTypeStatusRequest,
    },
    options,
  )
}
/**
 * @summary PlacementType 毎の棚卸状態を取得
 */
export const getPrizePlacementTypeStatus = (
  arcadeCd: string,
  placementType: "storage" | "on_booth" | "in_booth",
  prizeExecutionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizePlacementTypeStatusResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/placementType/${placementType}/status/${prizeExecutionPeriodId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 棚卸ステータスを設定
 */
export const putPrizePlacementStatuses = (
  arcadeCd: string,
  placementType: "storage" | "on_booth" | "in_booth",
  putPrizePlacementStatusRequest: PutPrizePlacementStatusRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/placementType/${placementType}/placements/statuses`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizePlacementStatusRequest,
    },
    options,
  )
}
/**
 * @summary 棚卸ステータスを取得
 */
export const getPrizePlacementStatuses = (
  arcadeCd: string,
  placementType: "storage" | "on_booth" | "in_booth" | "all",
  prizeExecutionPeriodId: number,
  params?: GetPrizePlacementStatusesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizePlacementStatusesResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/placementType/${placementType}/placements/statuses/${prizeExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
export type PutPrizePlacementTypeStatusResult = NonNullable<
  Awaited<ReturnType<typeof putPrizePlacementTypeStatus>>
>
export type GetPrizePlacementTypeStatusResult = NonNullable<
  Awaited<ReturnType<typeof getPrizePlacementTypeStatus>>
>
export type PutPrizePlacementStatusesResult = NonNullable<
  Awaited<ReturnType<typeof putPrizePlacementStatuses>>
>
export type GetPrizePlacementStatusesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizePlacementStatuses>>
>
