import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  TextField,
  Card,
  FormHelperText,
  MenuItem,
  Stack,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { PutPrizeBoothRequest } from "src/api/models"
import { getPrizeBooth, putPrizeBooth } from "src/api/prize-booths"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

type FormType = PutPrizeBoothRequest

export const PrizeBoothDetails = () => {
  return (
    <MainContentLayout
      title="ブース編集"
      renderContent={() => <PrizeBoothDetailsForm />}
    />
  )
}

const PrizeBoothDetailsForm = () => {
  const { arcadeCd, boothName } = useParams()
  const { isEditableMachine } = useUserRole()

  const { booth, amMachine } =
    useResource({
      subject: "ブース詳細の取得",
      fetch:
        arcadeCd && boothName
          ? () => getPrizeBooth(arcadeCd, boothName)
          : undefined,
      recoilKey: `getPrizeBooth:${arcadeCd}:${boothName}`,
    }).resource?.data ?? {}

  const { amMachineNumber, isAvailable } = booth ?? {}
  const { amMachineName } = amMachine ?? {}

  const validationSchema = Yup.object({
    isAvailable: Yup.bool().required(),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    defaultValues: {
      isAvailable: isAvailable,
    },
    resolver: yupResolver(validationSchema),
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({ isAvailable }) => {
    if (!arcadeCd || !boothName || !isEditableMachine) return
    const request: PutPrizeBoothRequest = {
      isAvailable,
    }

    await submitPromises([
      {
        subject: "ブース詳細の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeBooth(arcadeCd, boothName, request)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Card sx={{ py: 2, px: 3 }}>
          <Typography sx={{ mb: 2 }} variant="h2">
            {boothName}
          </Typography>

          <Stack gap={3}>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                AM機器番号
              </Typography>
              <Typography>{amMachineNumber ?? ""}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                AM機器名称
              </Typography>
              <Typography>{amMachineName ?? ""}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                状態
              </Typography>
              <Controller
                control={control}
                name={"isAvailable"}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    defaultValue={field.value ? "有効" : "無効"}
                    onChange={(e) => {
                      if (e.target.value === "有効") {
                        setValue("isAvailable", true)
                      } else {
                        setValue("isAvailable", false)
                      }
                    }}
                  >
                    {["有効", "無効"].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.isAvailable?.message && (
                <FormHelperText>{errors.isAvailable.message}</FormHelperText>
              )}
            </Stack>
          </Stack>
        </Card>

        <Stack>
          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            disabled={!isEditableMachine}
            loading={isSubmitting}
          >
            変更した内容を反映させる
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
