import { TextField, MenuItem, Select } from "@mui/material"
import deepEqual from "fast-deep-equal"
import { atom, useRecoilState } from "recoil"

import { Prize } from "src/api/models"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  prizeDeliveriesSortByLabels,
  PrizeDeliveriesSortBy,
} from "src/domains/prizes/deliveryRepository"
import { formatApiDate, getMonthEnd, getMonthStart } from "src/utils"

type PrizeDeliveriesSearchParams = {
  arriveAtDateRange: DateRangePickerDateLabel
  prizeCd: Prize["prizeCd"]
  prizeName: Prize["prizeName"]
  prizeNameKana: string // Prize["prizeNameKana"]
  makerName: Prize["makerName"]
  ipName: Prize["ipName"]
  sortBy: PrizeDeliveriesSortBy
}

const defaultSearchParams: PrizeDeliveriesSearchParams = {
  arriveAtDateRange: {
    start: formatApiDate(getMonthStart()),
    end: formatApiDate(getMonthEnd()),
  },
  prizeCd: "",
  prizeName: "",
  prizeNameKana: "",
  makerName: "",
  ipName: "",
  sortBy: "arriveAtOrderAsc",
}

export const prizeDeliveriesSearchParamsState =
  atom<PrizeDeliveriesSearchParams>({
    key: "prizeDeliveriesSearchParamsState",
    default: defaultSearchParams,
  })

export const PrizeDeliveriesFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    prizeDeliveriesSearchParamsState,
  )

  return (
    <FilterAccordion
      defaultExpanded={!deepEqual(searchParams, defaultSearchParams)}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      formInputs={[
        {
          name: "arriveAtDateRange",
          fullWidth: true,
          label: "着荷日",
          render: ({ field }) => (
            <DateRangePicker
              startInputLabel="開始日"
              endInputLabel="終了日"
              dateRangeLabel={field.value as DateRangePickerDateLabel}
              setDateRangeLabel={field.onChange}
            />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              {Object.entries(prizeDeliveriesSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
