import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getInventoryCsvOutput } from "src/api/inventory-csv"
import { ExecutionPeriod } from "src/api/models"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { OUTPUT_HISTORIES_CSV_RELEASE_DATE } from "src/domains/inventoryCsvRepository"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentArcadeState,
  inventoryPeriodsState,
  snackbarErrorMessageState,
} from "src/recoil"

export const InventoryOutputHistoriesCsv: React.FC = () => {
  const { arcadeCd } = useParams()

  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const arcade = useRecoilValue(currentArcadeState)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  // NOTE: 棚卸確定未済 = 終了日が 2200-12-31 の棚卸期間は除外
  const rawInventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const inventoryPeriods = useMemo(
    () =>
      rawInventoryPeriods?.filter(
        (period) => period.endAt !== executionPeriodTentativeEndAt,
      ),
    [rawInventoryPeriods],
  )
  const [selectedInventoryPeriodId, setSelectedInventoryPeriodId] = useState<
    ExecutionPeriod["id"] | undefined
  >()
  const selectedInventoryPeriod = useMemo(
    () =>
      inventoryPeriods?.find(
        (period) => period.id === selectedInventoryPeriodId,
      ),
    [inventoryPeriods, selectedInventoryPeriodId],
  )
  const { isAvailableInventoryOutput } = useUserRole()

  useEffect(
    () =>
      inventoryPeriods && setSelectedInventoryPeriodId(inventoryPeriods[0]?.id),
    [inventoryPeriods],
  )

  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = useCallback(async () => {
    if (
      selectedInventoryPeriod &&
      selectedInventoryPeriod.endAt < OUTPUT_HISTORIES_CSV_RELEASE_DATE
    ) {
      return setErrorMessage("該当期間の棚卸結果は出力できません")
    }

    arcadeCd &&
      arcade &&
      selectedInventoryPeriod &&
      submitPromises([
        {
          subject: "過去棚卸結果の取得",
          promise: async () => {
            const {
              data: { csv },
            } = await getInventoryCsvOutput(
              arcadeCd,
              selectedInventoryPeriod.id,
            )
            if (csv) {
              downloadCsv({
                csv,
                fileName: `inventory_output_${arcadeCd}_${selectedInventoryPeriod.startAt}_${selectedInventoryPeriod.endAt}.csv`,
              })
            } else {
              throw new Error()
            }
          },
        },
      ])
  }, [
    arcade,
    arcadeCd,
    downloadCsv,
    submitPromises,
    selectedInventoryPeriod,
    setErrorMessage,
  ])

  return (
    <MainContentLayout
      title="過去棚卸結果CSV"
      renderContent={() => (
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption">
              結果出力で出力されるCSVファイルと同じものです。
              <br />
              結果出力をせずにGiGO NAVIを確定してしまった場合に使用します。
            </Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            {selectedInventoryPeriodId ? (
              <FormControl fullWidth>
                <InputLabel id="period">期間</InputLabel>
                <Select
                  fullWidth
                  label="期間"
                  labelId="period"
                  value={selectedInventoryPeriodId}
                  onChange={(e) =>
                    setSelectedInventoryPeriodId(
                      e.target.value as ExecutionPeriod["id"],
                    )
                  }
                >
                  {inventoryPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : inventoryPeriods?.length === 0 ? (
              "出力可能な期間がありません"
            ) : (
              <CircularProgress />
            )}
          </Card>
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={
              isSubmitting ||
              !selectedInventoryPeriodId ||
              !isAvailableInventoryOutput
            }
          >
            CSV出力
          </Button>
        </Stack>
      )}
    />
  )
}
