import { useMemo } from "react"

import { Typography, Stack, Card } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { PrizeMeterReadPayoutCategoryEnum } from "src/api/models"
import { getPrizeMeterReads, putPrizeMeterReads } from "src/api/prize-sales"
import {
  PrizeMeterReadAnalogForm,
  OnSubmitPrizeMeterReadAnalogForm,
} from "src/components/organisms/prizes/PrizeMeterReadAnalogForm"
import {
  PrizeMeterReadSoftForm,
  OnSubmitPrizeMeterReadSoftForm,
} from "src/components/organisms/prizes/PrizeMeterReadSoftForm"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { formatApiDate, getJpDateLabel } from "src/utils"

export const PrizeMeterReadEdit: React.FC = () => {
  return (
    <MainContentLayout
      title="プライズ売上情報の入力・更新"
      subtitle="プライズ機種編集"
      renderContent={() => <PrizeMeterReadEditInner />}
      backButtonLabel="保存せず戻る"
    />
  )
}

const PrizeMeterReadEditInner: React.FC = () => {
  const { arcadeCd, boothName } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const selectedDate = query.get("selected_date")

  const boothElements = useResource({
    subject: "プライズ売上情報の取得",
    fetch:
      arcadeCd && boothName && selectedDate
        ? () =>
            getPrizeMeterReads(arcadeCd, {
              from: selectedDate,
              to: selectedDate,
              boothName,
            })
        : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}:${selectedDate}:${boothName}`,
  }).resource?.data.booths

  const items = useMemo(
    () =>
      (boothElements || [])
        .map(({ booth, meters }) => {
          const meter = meters.find(
            (meter) => formatApiDate(meter.recordedAt) === selectedDate,
          )
          return meter && { booth, meter }
        })
        .filter((item) => !!item),
    [boothElements, selectedDate],
  )

  const { submitPromises } = useSubmitting()

  const navigateToMeterReadList = ({
    selectedDate,
    brokenBoothNames,
  }: {
    selectedDate: string
    brokenBoothNames: string[]
  }) => {
    const query = new URLSearchParams()
    query.set("edited_date", selectedDate)
    brokenBoothNames.forEach((boothName) =>
      query.append("broken_booths", boothName),
    )
    navigate(`/arcades/${arcadeCd}/prizes/meterRead?${query.toString()}`)
  }

  const onSubmitAnalogForm: OnSubmitPrizeMeterReadAnalogForm = async (data) => {
    if (!selectedDate || !arcadeCd) return

    await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeMeterReads(arcadeCd, {
            meters: data.meters.map((meter) => {
              const request = {
                ...meter,
                assumedPayoutRate:
                  meter.payoutCategory ===
                    PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate &&
                  meter.assumedPayoutRate
                    ? meter.assumedPayoutRate / 100
                    : undefined,
                thincaTerminalNumber: meter.thincaTerminalNumber ?? "",
                recordedAt: selectedDate,
              }
              if (meter.useThincaTerminal && !meter.isOverridePrize) {
                request.payout = undefined
              }
              return request
            }),
            softMeters: [],
          })
        },
      },
    ])

    navigateToMeterReadList({
      selectedDate,
      brokenBoothNames: data.meters
        .filter((meter) => meter.isBroken)
        .map((meter) => meter.boothName),
    })
  }

  const onSubmitSoftForm: OnSubmitPrizeMeterReadSoftForm = async (data) => {
    if (!selectedDate || !arcadeCd) return

    await submitPromises([
      {
        subject: "メーターリード結果の更新",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeMeterReads(arcadeCd, {
            meters: [],
            softMeters: data.softMeters.map((softMeter) => ({
              ...softMeter,
              assumedPayoutRate:
                softMeter.payoutCategory ===
                  PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate &&
                softMeter.assumedPayoutRate
                  ? softMeter.assumedPayoutRate / 100
                  : undefined,
              thincaTerminalNumber: softMeter.thincaTerminalNumber ?? "",
              recordedAt: selectedDate,
            })),
          })
        },
      },
    ])

    navigateToMeterReadList({
      selectedDate,
      brokenBoothNames: data.softMeters
        .filter((softMeter) => softMeter.isBroken)
        .map((softMeter) => softMeter.boothName),
    })
  }

  const booth = items[0]?.booth

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <Stack py={2} px={3} gap={2}>
          <Typography variant="h2">{boothName}</Typography>
          <Typography>{getJpDateLabel(selectedDate || "")}</Typography>
          <Stack>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              AM機器番号
            </Typography>
            <Typography>{booth?.amMachineNumber}</Typography>
          </Stack>
        </Stack>
      </Card>

      {booth?.meterCategory === "soft" ? (
        <PrizeMeterReadSoftForm items={items} onSubmit={onSubmitSoftForm} />
      ) : (
        <PrizeMeterReadAnalogForm items={items} onSubmit={onSubmitAnalogForm} />
      )}
    </>
  )
}
