/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialStorageResponse,
  GetMaterialStoragesResponse,
  PatchMaterialStoragesOrdersRequest,
  PutMaterialStoragesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】保管場所のリストを取得
 */
export const getMaterialStorages = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialStoragesResponse>(
    { url: `/arcades/${arcadeCd}/materials/storages`, method: "GET" },
    options,
  )
}
/**
 * @summary 【材料】保管場所を追加、名前を変更
 */
export const putMaterialStorages = (
  arcadeCd: string,
  putMaterialStoragesRequest: PutMaterialStoragesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialStoragesRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】保管場所の削除
 */
export const deleteMaterialStorage = (
  arcadeCd: string,
  storageId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/${storageId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary 【材料】保管場所の詳細を取得
 */
export const getMaterialStorage = (
  arcadeCd: string,
  storageId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialStorageResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/${storageId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 【材料】保管場所の並び替え
 */
export const patchMaterialStoragesOrders = (
  arcadeCd: string,
  patchMaterialStoragesOrdersRequest: PatchMaterialStoragesOrdersRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/orders`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialStoragesOrdersRequest,
    },
    options,
  )
}
export type GetMaterialStoragesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialStorages>>
>
export type PutMaterialStoragesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialStorages>>
>
export type DeleteMaterialStorageResult = NonNullable<
  Awaited<ReturnType<typeof deleteMaterialStorage>>
>
export type GetMaterialStorageResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialStorage>>
>
export type PatchMaterialStoragesOrdersResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialStoragesOrders>>
>
