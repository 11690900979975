/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeStorageResponse,
  GetPrizeStoragesResponse,
  PatchPrizeStoragesOrderRequest,
  PutPrizeStoragesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 保管場所のリストを取得
 */
export const getPrizeStorages = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeStoragesResponse>(
    { url: `/arcades/${arcadeCd}/prizes/storages`, method: "GET" },
    options,
  )
}
/**
 * @summary 保管場所を追加、名前を変更
 */
export const putPrizeStorages = (
  arcadeCd: string,
  putPrizeStoragesRequest: PutPrizeStoragesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeStoragesRequest,
    },
    options,
  )
}
/**
 * @summary 保管場所の削除
 */
export const deletePrizeStorage = (
  arcadeCd: string,
  storageId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages/${storageId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary 保管場所の詳細を取得
 */
export const getPrizeStorage = (
  arcadeCd: string,
  storageId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeStorageResponse>(
    { url: `/arcades/${arcadeCd}/prizes/storages/${storageId}`, method: "GET" },
    options,
  )
}
/**
 * @summary 保管場所の並び替え
 */
export const patchPrizeStoragesOrder = (
  arcadeCd: string,
  patchPrizeStoragesOrderRequest: PatchPrizeStoragesOrderRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages/order`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeStoragesOrderRequest,
    },
    options,
  )
}
export type GetPrizeStoragesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeStorages>>
>
export type PutPrizeStoragesResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeStorages>>
>
export type DeletePrizeStorageResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeStorage>>
>
export type GetPrizeStorageResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeStorage>>
>
export type PatchPrizeStoragesOrderResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeStoragesOrder>>
>
