import dayjs, { Dayjs } from "dayjs"
import "dayjs/locale/ja"
import isBetween from "dayjs/plugin/isBetween"
import isoWeek from "dayjs/plugin/isoWeek"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)

export const getToday = () => {
  const today = dayjs().tz("Asia/Tokyo")
  return formatApiDate(today)
}

export const getDaysAgo = (days: number, fromDate?: string) => {
  const d = fromDate ? new Date(fromDate) : new Date(getToday())
  d.setDate(d.getDate() - days)
  return formatApiDate(d.toISOString())
}

export const getYesterday = () => getDaysAgo(1)

export const getMonthsAgo = (months: number, fromDate?: string) => {
  const d = fromDate ? new Date(fromDate) : new Date(getToday())
  return formatApiDate(
    dayjs(d).subtract(months, "month").toDate().toISOString(),
  )
}

export const formatApiDate = (date: string | dayjs.Dayjs | undefined) => {
  if (date === undefined) return ""
  if (date === "") return ""
  return dayjs(date).format("YYYY-MM-DD")
}

export const isValidDateLabel = (dateLabel: string) => {
  return dateLabel.match(/^\d{4}-\d{2}-\d{2}$/) !== null
}

export const getJpDateLabel = (
  date: string | dayjs.Dayjs | undefined,
  defaultLabel: string | undefined = "-",
) => {
  if (date === undefined) return defaultLabel
  if (date === "") return defaultLabel
  return dayjs(date).locale("ja").format("YYYY/MM/DD(ddd)")
}

/**
 * 日付ラベルの色（土曜なら青、日曜なら赤、その他は黒）
 * @param date - 日付の dayjs オブジェクトまたは文字列
 * @returns 色を表す文字列または undefined
 */
export const getJpDateLabelColor = (date: string | dayjs.Dayjs | undefined) => {
  if (date === undefined) return undefined
  const dayOfWeek = dayjs(date).locale("ja").day()
  if (dayOfWeek === 0) {
    return "error.main"
  } else if (dayOfWeek === 6) {
    return "primary.main"
  }
  return undefined
}

export const getJpDateTimeLabel = (dateLabel: string) => {
  return dayjs(dateLabel).locale("ja").format("YYYY/MM/DD(ddd) HH:mm")
}

export const getJpWeek = (dateLabel: string) => {
  const date = dayjs(dateLabel)
  const dateOfMonth = date.date()
  // NOTE: 日曜日を週末に含めるため、曜日番号を 0 -> 7 に置換
  const dayOfWeek = date.day() > 0 ? date.day() : 7
  const weekOfMonth = Math.ceil((dateOfMonth + (7 - dayOfWeek)) / 7)

  return `${date.format("YYYY年M月")}第${weekOfMonth}週`
}

export const getJpTodayDayjs = () => dayjs.tz(getToday(), "Asia/Tokyo")

const getInventoryMonthBaseDayjs = () => {
  let base = getJpTodayDayjs()
  if (base.date() < 15) base = base.subtract(1, "month")
  return base
}

export const getInventoryMonthStart = (baseDayjs?: Dayjs) => {
  const base = baseDayjs ?? getInventoryMonthBaseDayjs()
  return base.set("date", 15).format("YYYY-MM-DD")
}

export const getInventoryMonthEnd = (baseDayjs?: Dayjs) => {
  const base = baseDayjs ?? getInventoryMonthBaseDayjs()
  return base.set("date", 14).add(1, "month").format("YYYY-MM-DD")
}

export const dayJsWithTimezone = dayjs

export const getMonthStart = (base?: Dayjs | string) => {
  return dayjs.tz(base || undefined, "Asia/Tokyo").startOf("month")
}

export const getMonthEnd = (base?: Dayjs | string) => {
  return dayjs.tz(base || undefined, "Asia/Tokyo").endOf("month")
}

export const getIsoWeekStart = (base?: Dayjs | string) => {
  return dayjs.tz(base || undefined, "Asia/Tokyo").startOf("isoWeek")
}

export const getIsoWeekEnd = (base?: Dayjs | string) => {
  return dayjs.tz(base || undefined, "Asia/Tokyo").endOf("isoWeek")
}

export const getRecordedAtFromMeterReadCsv = (dayStr: string) => {
  const base = dayjs(dayStr)
  return base.set("year", getJpTodayDayjs().year())
}

export const getUpdatableMeterReadDayjs = () => {
  return getJpTodayDayjs().add(-31, "day")
}

export const getNextSeamsUploadDateTime = () => {
  const base = dayjs().tz("Asia/Tokyo")
  return base.minute(0).second(0).add(1, "hour").format("YYYY-MM-DD HH:mm")
}

export const getDatesBetween = (from: Dayjs | string, to: Dayjs | string) => {
  const fromDate = dayjs(from).tz("Asia/Tokyo")
  const toDate = dayjs(to).tz("Asia/Tokyo")
  const dates: Dayjs[] = []
  if (toDate.isSameOrAfter(fromDate)) {
    for (
      let date = fromDate;
      toDate.isSameOrAfter(date);
      date = date.add(1, "day")
    ) {
      dates.push(date)
    }
  } else {
    for (
      let date = toDate;
      fromDate.isSameOrAfter(date);
      date = date.add(1, "day")
    ) {
      dates.push(date)
    }
  }
  return dates
}

export const isBetweenDateRangeLabel = (
  date: Dayjs | string,
  from: Dayjs | string | undefined,
  to: Dayjs | string | undefined,
) => dayjs(date).isBetween(from, to, "days", "[]")

export const shouldDisableMonth = (month: dayjs.Dayjs) => {
  const today = getJpTodayDayjs()
  if (today.get("date") >= 25) {
    return month.isAfter(today.add(7, "day"))
  }
  return month.isAfter(today)
}
