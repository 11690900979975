import { PropsWithChildren } from "react"

import { ListItemButton, SxProps } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

import { theme } from "src/theme"

interface MenuButtonProps {
  to?: string
  sx?: SxProps
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLDivElement>
}

export const MenuButton: React.FC<PropsWithChildren<MenuButtonProps>> = ({
  children,
  sx,
  to,
  onClick,
}) => {
  if (to) {
    return (
      <ListItemButton
        sx={{ color: theme.palette.primary.main, py: 2, ...sx }}
        component={RouterLink}
        to={to}
        onClick={onClick}
      >
        {children}
      </ListItemButton>
    )
  }
  return (
    <ListItemButton
      sx={{ color: theme.palette.primary.main, py: 2, ...sx }}
      onClick={onClick}
    >
      {children}
    </ListItemButton>
  )
}
