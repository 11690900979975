import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent, TextField } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

type InventoryExecutionPeriodEndModalProps = {
  onSubmit: SubmitHandler<InventoryExecutionPeriodEndFormInput>
  onClose: () => void
  showModal: boolean
  isSubmitting: boolean
  totalInventoryPrices?: number
}

export type InventoryExecutionPeriodEndFormInput = {
  note?: string
}

export const InventoryMaterialExecutionPeriodEndModal: React.FC<
  InventoryExecutionPeriodEndModalProps
> = ({ onSubmit, onClose, showModal, isSubmitting, totalInventoryPrices }) => {
  const validationSchema = Yup.object({
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InventoryExecutionPeriodEndFormInput>({
    resolver:
      yupResolver<InventoryExecutionPeriodEndFormInput>(validationSchema),
    defaultValues: {
      note: "",
    },
  })

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={() => onClose()}></DialogTitleWithClose>

        <DialogContent>
          <Typography variant="h1" pb={1}>
            材料確定処理の確認
          </Typography>
          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              材料確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO
                NAVI上の材料材料棚卸データに変更が加えられなくなります
              </li>
              <li>棚／材料機械についた「済」マークが全て消えます</li>
              <li>
                GiGO
                NAVI上の今回の材料棚卸期間が終了し、新しい棚卸期間が開始されます
              </li>
            </ul>
            <br />
            <p>
              今回の棚卸期間の材料代：
              {totalInventoryPrices?.toLocaleString() || "-"}円
            </p>
          </Typography>

          <TextField
            label="備考"
            fullWidth
            error={"note" in errors}
            helperText={errors.note?.message}
            sx={{ mt: 2 }}
            {...register("note")}
          />
        </DialogContent>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>戻る</BackButton>
          <LoadingButton
            variant="contained"
            color="error"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            確定する
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
