import React, { useMemo } from "react"

import { LoadingButton } from "@mui/lab"
import { Grid, TextField } from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import { getMaterialInventoryGroups } from "src/api/material-inventory-group"
import { putMaterialShelf } from "src/api/material-shelves"
import { getMaterialStorage } from "src/api/material-storages"
import { MaterialShelf, PutMaterialShelvesRequest } from "src/api/models"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"

type FormType = Pick<MaterialShelf, "name"> & {
  inventoryGroupName?: string
}

export const InventoryMaterialAddShelf = () => {
  const { arcadeCd, storageId } = useParams()

  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
  })
  const storage = resource?.data.storage
  const shelves = resource?.data.shelves

  return (
    <MainContentLayout
      title={(storage?.name || "") + " 棚を追加"}
      renderContent={() => <InventoryMaterialAddShelfForm shelves={shelves} />}
      backButtonLabel="保存せずに戻る"
    />
  )
}

type InventoryMaterialAddShelfFormProps = {
  shelves?: MaterialShelf[]
}

const InventoryMaterialAddShelfForm: React.FC<
  InventoryMaterialAddShelfFormProps
> = ({ shelves }) => {
  const { arcadeCd, storageId } = useParams()
  const navigate = useNavigate()

  const inventoryGroupNames =
    useResource({
      subject: "棚卸グループの取得",
      fetch: arcadeCd
        ? () => getMaterialInventoryGroups(arcadeCd, { placement: "storage" })
        : undefined,
      recoilKey: `getMaterialInventoryGroups:${arcadeCd}:storage`,
    }).resource?.data.groups.map((g) => g.groupName) || []

  const shelfNames = useMemo(() => {
    return (shelves || []).map((shelf) => shelf.name)
  }, [shelves])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>()

  const value = {
    shelfName: useWatch({ control, name: "name" }),
  }

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    name,
    inventoryGroupName,
  }) => {
    const numStorageId = Number(storageId)
    if (!arcadeCd || isNaN(numStorageId)) return
    const request: PutMaterialShelvesRequest = {
      name,
      inventoryGroupName: inventoryGroupName ?? "",
    }

    await submitPromises([
      {
        subject: "棚の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putMaterialShelf(arcadeCd, numStorageId, request)
          return navigate(-1)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"name"}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                label="棚名称"
                required
                fullWidth
                sx={{ background: "white" }}
                {...field}
                error={shelfNames.includes(value.shelfName)}
                helperText={
                  shelfNames.includes(value.shelfName) &&
                  "すでに存在する棚名です"
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"inventoryGroupName"}
            defaultValue={""}
            render={({ field, fieldState }) => (
              <SearchAutoComplete
                {...field}
                items={inventoryGroupNames.map((name) => ({
                  label: name,
                  value: name,
                }))}
                inputLabel="棚卸グループ"
                error={!!fieldState.error}
                inputHelperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={shelfNames.includes(value.shelfName)}
              loading={isSubmitting}
            >
              保存
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
