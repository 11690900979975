import { PrizeToneBoothInfo, PrizeBoothUnit } from "src/api/models"
import { formatApiDate } from "src/utils"

export const encodeBoothUnitsQuery = (boothUnits: PrizeBoothUnit[]) => {
  return boothUnits.map((u) => `${u.machineName}|${u.boothName}`).join(",")
}

export const decodeBoothUnitsQuery = (query: string) => {
  return query.split(",").map((unitLabel) => {
    const [machineName, boothName = ""] = unitLabel.split("|")
    const boothUnit: PrizeBoothUnit = { machineName, boothName }
    return boothUnit
  })
}

export const getBoothUnitUILabel = (boothUnit: PrizeBoothUnit) =>
  `${boothUnit.machineName} ${boothUnit.boothName}`

export const isSameBoothUnit = (a: PrizeBoothUnit, b: PrizeBoothUnit) =>
  getBoothUnitUILabel(a) === getBoothUnitUILabel(b)

export interface BoothSalesPrizeChange {
  arcadeCd: string
  machineName: string
  boothName: string
  soldOn: string
  isPrizeChanged: boolean
}

export const matchBoothSalePrimaryKey = (
  primaryKey: Pick<
    PrizeToneBoothInfo,
    "arcadeCd" | "machineName" | "boothName" | "soldOn"
  >,
  booth: PrizeToneBoothInfo,
) => {
  const { arcadeCd, machineName, boothName, soldOn } = primaryKey
  return (
    booth.arcadeCd === arcadeCd &&
    booth.machineName === machineName &&
    booth.boothName === boothName &&
    formatApiDate(booth.soldOn) === formatApiDate(soldOn)
  )
}

export const findBoothYesterday = (
  boothToday: PrizeToneBoothInfo,
  boothsYesterday: PrizeToneBoothInfo[],
) =>
  boothsYesterday.find(
    (boothYesterday) =>
      boothYesterday.arcadeCd === boothToday.arcadeCd &&
      boothYesterday.machineName === boothToday.machineName &&
      boothYesterday.boothName === boothToday.boothName,
  )

export const calcDailySalesStats = (
  booths: Pick<PrizeToneBoothInfo, "dailySales">[],
) => booths.reduce((sum, b) => sum + (b.dailySales || 0), 0)

export const calcDailyPayOutStats = (
  booths: Pick<PrizeToneBoothInfo, "dailySales" | "dailyPayOut">[],
) => {
  const unitPricesSum = booths.reduce(
    (sum, b) => sum + (b.dailySales || 0) * (b.dailyPayOut || 0),
    0,
  )
  const dailySalesSum = booths.reduce((sum, b) => sum + (b.dailySales || 0), 0)
  return dailySalesSum !== 0 ? unitPricesSum / dailySalesSum : 0
}

export const calcWeeklySalesStats = (booths: PrizeToneBoothInfo[]) =>
  booths.reduce((sum, b) => sum + (b.weeklySales || 0), 0)

export const calcWeeklyPayOutStats = (booths: PrizeToneBoothInfo[]) => {
  const unitPricesSum = booths.reduce(
    (sum, b) => sum + (b.weeklySales || 0) * (b.weeklyPayOut || 0),
    0,
  )
  const weeklySalesSum = booths.reduce(
    (sum, b) => sum + (b.weeklySales || 0),
    0,
  )
  return weeklySalesSum !== 0 ? unitPricesSum / weeklySalesSum : 0
}
