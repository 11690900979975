/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  DeleteBookmarkedArcadesRequest,
  PutBookmarkedArcadesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 店舗をお気に入りから削除
 */
export const deleteBookmarkedArcades = (
  deleteBookmarkedArcadesRequest: DeleteBookmarkedArcadesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/bookmarks/arcades`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: deleteBookmarkedArcadesRequest,
    },
    options,
  )
}
/**
 * @summary 店舗をお気に入りに追加
 */
export const putBookmarkedArcades = (
  putBookmarkedArcadesRequest: PutBookmarkedArcadesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/bookmarks/arcades`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putBookmarkedArcadesRequest,
    },
    options,
  )
}
export type DeleteBookmarkedArcadesResult = NonNullable<
  Awaited<ReturnType<typeof deleteBookmarkedArcades>>
>
export type PutBookmarkedArcadesResult = NonNullable<
  Awaited<ReturnType<typeof putBookmarkedArcades>>
>
