import React, { useMemo } from "react"

import { Typography, TableHead, TableRow, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialStockLedgerStorages } from "src/api/material-stock-ledger"
import { Material, MaterialStockLedgerStorage } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import {
  InventoryMaterialStockLedgerPlacementsFilter,
  materialStockLedgerPlacementsSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialStockLedgerPlacementsFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortMaterialStockLedgerPlacements } from "src/domains/materials/materialStockLedgerRepository"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

export const InventoryMaterialStockLedgerPlacements: React.FC = () => {
  const { arcadeCd, materialCd } = useParams()
  const searchParams = useRecoilValue(
    materialStockLedgerPlacementsSearchParamsState,
  )

  const { material, storages } =
    useResource({
      subject: "材料一覧の取得",
      fetch:
        arcadeCd && materialCd
          ? () =>
              getMaterialStockLedgerStorages(arcadeCd, materialCd, {
                placementName: searchParams.placementName,
              })
          : undefined,
      recoilKey: `getMaterialStockLedgerStorages:${arcadeCd}:${materialCd}:${searchParams.placementName}`,
    }).resource?.data ?? {}

  return (
    <MainContentLayout
      title="副帳票 保管場所一覧"
      subtitle={material?.materialName || ""}
      renderFilter={() => <InventoryMaterialStockLedgerPlacementsFilter />}
      renderContent={() => (
        <InventoryMaterialStockLedgerPlacementsMenu
          material={material}
          storages={storages}
        />
      )}
    />
  )
}

type InventoryMaterialStockLedgerPlacementsMenuProps = {
  material?: Material
  storages?: MaterialStockLedgerStorage[]
}

const InventoryMaterialStockLedgerPlacementsMenu: React.FC<
  InventoryMaterialStockLedgerPlacementsMenuProps
> = ({ storages }) => {
  const { arcadeCd, materialCd } = useParams()
  const searchParams = useRecoilValue(
    materialStockLedgerPlacementsSearchParamsState,
  )
  const navigate = useNavigate()

  const filteredPlacements = useMemo(
    () =>
      sortMaterialStockLedgerPlacements(storages ?? [], searchParams.sortBy),
    [storages, searchParams],
  )

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 360px)",
      }}
    >
      <InventoryMaterialStockLedgerPlacementsTable
        storages={filteredPlacements}
        onSelectedPlacementId={(placementId: number) =>
          navigate(
            `/arcades/${arcadeCd}/materials/ledger/materials/${materialCd}/placements/${placementId}`,
          )
        }
      />
    </Stack>
  )
}

interface InventoryMaterialStockLedgerPlacementsTableProps {
  storages: MaterialStockLedgerStorage[]
  onSelectedPlacementId: (placementId: number) => void
}

const InventoryMaterialStockLedgerPlacementsTable: React.FC<
  InventoryMaterialStockLedgerPlacementsTableProps
> = ({
  storages,
  onSelectedPlacementId,
}: InventoryMaterialStockLedgerPlacementsTableProps) => {
  const columnNames = ["有効/無効", "保管場所名", "残数"]
  return (
    <PaginatedTable
      scrollableY
      stickyHeader
      noMargin
      items={storages}
      stateKey="inventoryMaterialStockLedgerPlacementsTable"
      header={
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <ExtTableCell key={columnName}>{columnName}</ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(storage) => (
        <TableBorderedRow
          key={storage.placementId}
          hover
          sx={{
            color: theme.palette.primary.main,
            "&:hover td": {
              cursor: "pointer",
              background: theme.palette.neutral[200],
            },
            ...(!storage.isAvailable && {
              background: theme.palette.gray[20],
            }),
          }}
          onClick={() => onSelectedPlacementId(storage.placementId)}
        >
          <ExtTableCell sx={{ whiteSpace: "nowrap", p: 2 }}>
            <Typography
              color={theme.palette.gray[storage.isAvailable ? 80 : 50]}
              p={0.5}
              sx={{
                display: "inline",
                background: storage.isAvailable
                  ? theme.palette.primary.light
                  : theme.palette.gray[30],
              }}
              variant="inherit"
            >
              {storage.isAvailable ? "有効" : "無効"}
            </Typography>
          </ExtTableCell>
          <ExtTableCell>
            <CardItemNameBox sx={{ color: theme.palette.primary.main }}>
              {storage.placementName}
            </CardItemNameBox>
          </ExtTableCell>
          <ExtTableCell>
            <CardItemNameBox>{storage.stock}</CardItemNameBox>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
