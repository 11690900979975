import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialInventoryHistories } from "src/api/material-inventory-histories"
import { getMaterialMachines } from "src/api/material-machines"
import { getMaterialStorages } from "src/api/material-storages"
import {
  FeatureExecutionPeriod,
  Csv,
  GetMaterialInventoryHistoriesResponse,
  GetMaterialStoragesResponse,
  GetMaterialMachinesResponse,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import { materialPlacementTypeNames } from "src/domains/materials/materialInventoryHistoriesRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState, inventoryPeriodsState } from "src/recoil"
import { formatApiDate } from "src/utils"

export const InventoryMaterialExecuteHistoriesCsv: React.FC = () => {
  const { arcadeCd } = useParams()

  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const arcade = useRecoilValue(currentArcadeState)

  const getCsv = useCallback(
    (
      historiesRes: GetMaterialInventoryHistoriesResponse,
      storagesRes: GetMaterialStoragesResponse,
      machinesRes: GetMaterialMachinesResponse,
    ): Csv => ({
      headerRow: {
        columns: [
          "分類",
          "保管場所名",
          "棚名",
          "シリンダー名",
          "材料CD",
          "個数",
          "材料名",
          "棚卸日",
          "実施者",
        ],
      },
      rows: historiesRes.histories.map((history) => {
        const { storage, shelves } =
          storagesRes.storages.find(
            ({ storage }) => storage.id === history.shelfStock?.storageId,
          ) || {}
        const shelf = shelves?.find(
          (shelf) => shelf.id === history.shelfStock?.shelfId,
        )
        const materialMachine = machinesRes.materialMachines?.find(
          ({ materialMachine }) =>
            materialMachine.id === history.machineStock?.materialMachineId,
        )

        return {
          columns: [
            materialPlacementTypeNames[history.placement],
            storage?.name || "",
            shelf?.name || "",
            getDisplayMaterialMachineName(
              materialMachine?.amMachine,
              materialMachine?.materialMachine,
            ),
            history.materialCd,
            history.stock.toString(),
            history.material.materialName,
            formatApiDate(history.executedAt),
            history.executedBy?.name || "",
          ],
        }
      }),
    }),
    [],
  )

  // NOTE: 棚卸確定未済 = 終了日が 2200-12-31 の棚卸期間は除外
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const materialExecutionPeriods = useMemo(
    () =>
      inventoryPeriods?.flatMap((period) =>
        period.materialExecutionPeriods.filter(
          (materialPeriod) =>
            materialPeriod.status === FeatureExecutionPeriodStatusEnum.Ended,
        ),
      ),
    [inventoryPeriods],
  )

  const [
    selectedMaterialExecutionPeriodId,
    setSelectedMaterialExecutionPeriodId,
  ] = useState<FeatureExecutionPeriod["id"]>()
  const selectedMaterialExecutionPeriod = useMemo(
    () =>
      materialExecutionPeriods?.find(
        (period) => period.id === selectedMaterialExecutionPeriodId,
      ),
    [materialExecutionPeriods, selectedMaterialExecutionPeriodId],
  )

  const { isAvailableInventoryExecuteHistoriesCsv } = useUserRole()

  useEffect(
    () =>
      materialExecutionPeriods &&
      setSelectedMaterialExecutionPeriodId(materialExecutionPeriods[0]?.id),
    [materialExecutionPeriods],
  )

  const storagesData = useResource({
    subject: "保管場所の取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorages:${arcadeCd}`,
  }).resource?.data
  const machinesData = useResource({
    subject: "材料機械の取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}`,
  }).resource?.data

  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = useCallback(async () => {
    arcadeCd &&
      arcade &&
      selectedMaterialExecutionPeriod &&
      storagesData &&
      machinesData &&
      submitPromises([
        {
          subject: "店内総在庫証明の取得",
          promise: async () => {
            const { data: historiesData } = await getMaterialInventoryHistories(
              arcadeCd,
              selectedMaterialExecutionPeriod.id,
            )
            const csv = getCsv(historiesData, storagesData, machinesData)
            if (csv) {
              downloadCsv({
                csv,
                fileName: `材料店内総在庫証明_${arcadeCd}_${arcade.name}_${selectedMaterialExecutionPeriod.startAt}_${selectedMaterialExecutionPeriod.endAt}.csv`,
              })
            } else {
              throw new Error()
            }
          },
        },
      ])
  }, [
    arcadeCd,
    arcade,
    selectedMaterialExecutionPeriod,
    storagesData,
    machinesData,
    submitPromises,
    getCsv,
    downloadCsv,
  ])

  return (
    <MainContentLayout
      title="材料店内総在庫証明CSV"
      renderContent={() => (
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption">
              全ての保管場所単位別のカウント結果を表示します。GiGO
              NAVIを確定したら自動生成されます。
              <br />
              このファイルは会社から求められた時のみ提出します。
            </Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            {selectedMaterialExecutionPeriodId ? (
              <FormControl fullWidth>
                <InputLabel id="period">期間</InputLabel>
                <Select
                  fullWidth
                  label="期間"
                  labelId="period"
                  value={selectedMaterialExecutionPeriodId}
                  onChange={(e) =>
                    setSelectedMaterialExecutionPeriodId(
                      e.target.value as FeatureExecutionPeriod["id"],
                    )
                  }
                >
                  {materialExecutionPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : materialExecutionPeriods?.length === 0 ? (
              "出力可能な期間がありません"
            ) : (
              <CircularProgress />
            )}
          </Card>
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={
              isSubmitting ||
              !selectedMaterialExecutionPeriodId ||
              !isAvailableInventoryExecuteHistoriesCsv(arcadeCd)
            }
          >
            CSV出力
          </Button>
        </Stack>
      )}
    />
  )
}
