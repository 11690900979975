import { TextField, Autocomplete, MenuItem } from "@mui/material"
import Select from "@mui/material/Select"
import { useRecoilState } from "recoil"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  placementTypeNames,
  placementTypes,
  placementValidationNames,
  placementValidations,
} from "src/domains/prizes/inventoryExecuteRepository"
import {
  InventoryPrizePlacementsSearchParams,
  Placement,
} from "src/pages/prizes/placements/InventoryPrizePlacements"
import { inventoryPlacementsSearchParamsState } from "src/recoil/inventory"
import { generateFuzzyRegExp } from "src/utils"

export const InventoryPrizePlacementsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryPlacementsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={
        !!(
          searchParams.name ||
          searchParams.placementType ||
          searchParams.placementValidation
        )
      }
      formInputs={[
        {
          name: "placementType",
          label: "区分",
          render: ({ field, fieldState: { error } }) => (
            <Autocomplete
              options={placementTypes.map((placementType) => ({
                label: placementTypeNames[placementType],
                type: placementType,
              }))}
              defaultValue={
                placementTypes
                  .filter(
                    (placementType) =>
                      placementType === searchParams.placementType,
                  )
                  .map((placementType) => ({
                    label: placementTypeNames[placementType],
                    type: placementType,
                  }))[0]
              }
              renderOption={(props, option) => (
                <WordBreakAllList {...props} key={option.type}>
                  {option.label}
                </WordBreakAllList>
              )}
              onChange={(_, option) => field.onChange(option?.type)}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error}
                  placeholder="プライズ機外／内／上"
                />
              )}
            />
          ),
        },
        {
          name: "name",
          label: "名前",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "name" }}
            />
          ),
        },
        {
          name: "placementValidation",
          label: "有効・無効",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{ "aria-label": "placementValidation" }}
            >
              {placementValidations.map((key) => (
                <MenuItem value={key} key={key}>
                  {placementValidationNames[key]}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterPlacements = (
  placements: Placement[],
  params: InventoryPrizePlacementsSearchParams,
) => {
  const { name, placementType, placementValidation } = params

  return placements
    .filter((p) => (name ? generateFuzzyRegExp(name).test(p.name) : true))
    .filter((p) => (placementType ? p.placementType === placementType : true))
    .filter((p) => {
      if (!placementValidation || placementValidation === "all") return true
      if (placementValidation === (p.isAvailable ? "valid" : "invalid")) {
        return true
      }
      return false
    })
}
