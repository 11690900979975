import { forwardRef, PropsWithChildren } from "react"

import {
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  Button,
} from "@mui/material"

export const AccordionSummaryWithButton = forwardRef<
  HTMLDivElement,
  PropsWithChildren<AccordionSummaryProps> & {
    label: string
    labelExpanded?: string
  }
>(({ children, label, labelExpanded, ...props }, ref) => {
  return (
    <AccordionSummary
      sx={{
        ".Mui-expanded": {
          transform: "none !important",
          ".folded": { display: "none" },
          ".expanded": { display: "inherit" },
        },
      }}
      expandIcon={
        <Button
          variant="contained"
          sx={{ px: 1, py: 0.5, whiteSpace: "nowrap" }}
        >
          <Box className="folded">{label}</Box>
          <Box className="expanded" sx={{ display: "none" }}>
            {labelExpanded || label}
          </Box>
        </Button>
      }
      {...props}
      ref={ref}
    >
      {children}
    </AccordionSummary>
  )
})

AccordionSummaryWithButton.displayName = "AccordionSummaryWithButton"
