import { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  Card,
  CardContent,
  List,
  DialogContent,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import {
  deleteMaterialMachine,
  getMaterialMachine,
  patchMaterialMachines,
} from "src/api/material-machines"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import {
  PatchMaterialMachineRequest,
  AmMachineUsageCategoryEnum,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { MenuButton } from "src/components/atoms/MenuButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"
import { snackbarErrorMessageState } from "src/recoil"
import { theme } from "src/theme"

type FormType = Partial<PatchMaterialMachineRequest>

export const InventoryMaterialMachineDetails = () => {
  return (
    <MainContentLayout
      title=""
      renderContent={() => <InventoryMaterialMachineDetailsForm />}
      disableBackButton
    />
  )
}

const InventoryMaterialMachineDetailsForm = () => {
  const { arcadeCd, materialMachineId } = useParams()
  const { isEditableMachine } = useUserRole()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { amMachine, materialMachine } =
    useResource({
      subject: "材料機械詳細の取得",
      fetch:
        arcadeCd && materialMachineId
          ? () => getMaterialMachine(arcadeCd, Number(materialMachineId))
          : undefined,
      recoilKey: `getMaterialMachine:${materialMachineId}`,
    }).resource?.data ?? {}
  const { stocks } =
    useResource({
      subject: "在庫検索結果の取得",
      fetch:
        arcadeCd && materialMachineId
          ? () =>
              getMaterialOperationStocks(arcadeCd, {
                materialMachineIds: [Number(materialMachineId)],
              })
          : undefined,
      recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialMachineId}`,
    }).resource?.data ?? {}

  const {
    amMachineNumber,
    amMachineName,
    materialSetting,
    representativeHardItemCd,
    usageCategory,
  } = amMachine ?? {}
  const { numOfCylinders } = materialSetting || {}

  const { cylinderId, cylinderName, note, isGeneric } = materialMachine ?? {}

  const validationSchema = Yup.object({
    cylinderName: Yup.string().max(
      100,
      "文字数上限を超えているため変更できません(上限：全角100文字)",
    ),
    note: Yup.string().max(
      100,
      "文字数上限を超えているため変更できません(上限：全角100文字)",
    ),
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    defaultValues: {
      cylinderName,
      note,
    },
    resolver: yupResolver(validationSchema),
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({ cylinderName, note }) => {
    if (!arcadeCd || !materialMachineId || !isEditableMachine) return
    const request: PatchMaterialMachineRequest = {
      cylinderName: cylinderName ?? "",
      note: note ?? "",
    }
    await submitPromises([
      {
        subject: "材料機械詳細の変更",
        showSuccessMessage: true,
        promise: async () => {
          await patchMaterialMachines(
            arcadeCd,
            Number(materialMachineId),
            request,
          )
        },
      },
    ])
  }

  const menuItems = arcadeCd
    ? [
        {
          href: `/arcades/${arcadeCd}/materials/placements/machine/${materialMachineId}/materials`,
          title: "保管されている材料一覧",
        },
        {
          href: `/arcades/${arcadeCd}/inventory/materials/execute/machine/amMachines/${amMachineNumber}/${materialMachineId}`,
          title: "棚卸実施",
        },
      ]
    : []

  return (
    <>
      <InventoryMaterialMachineDeleteModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <List sx={{ width: "100%", color: theme.palette.primary.main }}>
            {menuItems.map((i) => (
              <MenuButton key={i.title} to={i.href}>
                {i.title}
              </MenuButton>
            ))}
          </List>
        </CardContent>
      </Card>
      <Card>
        <Box p={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ mb: 3 }} variant="h1">
              {getDisplayMaterialMachineName(amMachine, materialMachine)}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="AM機器番号"
                  fullWidth
                  value={amMachineNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="シリンダー番号"
                  fullWidth
                  value={cylinderId ? String(cylinderId).padStart(3, "0") : ""}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="AM機器名称"
                  fullWidth
                  value={amMachineName}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="シリンダー個数"
                  fullWidth
                  value={numOfCylinders}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"cylinderName"}
                  render={({ field }) => (
                    <TextField
                      label="シリンダー名称"
                      fullWidth
                      error={!!errors.cylinderName}
                      {...field}
                      disabled={isGeneric}
                    />
                  )}
                />
                {errors.cylinderName?.message && (
                  <FormHelperText>{errors.cylinderName.message}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="設置部門CD"
                  fullWidth
                  value={representativeHardItemCd}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="filled"
                  label="利用区分"
                  fullWidth
                  value={
                    usageCategory === AmMachineUsageCategoryEnum.Material
                      ? "材料"
                      : "その他"
                  }
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"note"}
                  render={({ field }) => (
                    <TextField
                      label="シリンダー備考"
                      fullWidth
                      error={!!errors.note}
                      {...field}
                      disabled={isGeneric}
                    />
                  )}
                />
                {errors.note?.message && (
                  <FormHelperText>{errors.note.message}</FormHelperText>
                )}
              </Grid>

              {!isGeneric && (
                <>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={!isEditableMachine}
                      loading={isSubmitting}
                    >
                      変更した内容を反映させる
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      type="button"
                      color="error"
                      disabled={!isEditableMachine}
                      loading={isSubmitting}
                      onClick={() => {
                        if (!stocks) return
                        if (stocks.length > 0)
                          return setErrorMessage(
                            "削除対象のシリンダー内に材料が1つ以上入っています",
                          )
                        setShowDeleteModal(true)
                      }}
                    >
                      削除する
                    </LoadingButton>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <BackButton>戻る</BackButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </>
  )
}

interface InventoryMaterialMachineDeleteModalProps {
  showModal: boolean
  onClose: () => void
}

const InventoryMaterialMachineDeleteModal: React.FC<
  InventoryMaterialMachineDeleteModalProps
> = ({ showModal, onClose }) => {
  const { arcadeCd, materialMachineId } = useParams()
  const navigate = useNavigate()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    await submitPromises([
      {
        subject: "シリンダーの削除",
        showSuccessMessage: true,
        promise:
          arcadeCd && materialMachineId
            ? async () => {
                await deleteMaterialMachine(arcadeCd, Number(materialMachineId))
                onClose()
              }
            : () => Promise.resolve(undefined),
      },
    ])
    navigate(-1)
  }

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">シリンダーの削除</Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Typography>
            このシリンダーをGiGONAVI上から削除します。よろしいですか？
          </Typography>
        </DialogContent>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>削除せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            color="error"
            loading={isSubmitting}
            fullWidth
          >
            削除
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
