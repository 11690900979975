import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Delete, Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  DialogContent,
  TextField,
  Stack,
  IconButton,
  FormHelperText,
  TableRow,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form"
import * as Yup from "yup"

import { PrizeFee, PutPrizeSettingFeeRequest } from "src/api/models"
import {
  deletePrizeSettingFee,
  getPrizeSettingFees,
  putPrizeSettingFee,
} from "src/api/prize-settings"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

export const PrizeFees = () => {
  return (
    <MainContentLayout
      title="プライズ機料金設定一覧"
      renderContent={() => <PrizeFeesInner />}
    />
  )
}

const PrizeFeesInner: React.FC = () => {
  const { isAdmin } = useUserRole()
  const { resource: feesResource, refetch } = useResource({
    subject: "料金設定リストの取得",
    fetch: () => getPrizeSettingFees(),
    recoilKey: `getPrizeSettingFees`,
  })
  const fees = useMemo(() => feesResource?.data.fees || [], [feesResource])

  const [showAddModal, setShowAddModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedPrizeFee, setSelectedPrizeFee] = useState<
    PrizeFee | undefined
  >(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onFinishModal = () => refetch()

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          sx={{ mr: 2, mb: 2, whiteSpace: "nowrap" }}
          startIcon={<Add />}
          onClick={() => setShowAddModal(true)}
        >
          追加
        </Button>
      )}

      <Stack sx={{ maxHeight: "80dvh" }}>
        <PrizeFeesTable
          prizeFees={fees}
          onClickEdit={(fee: PrizeFee) => {
            setSelectedPrizeFee(fee)
            setShowUpdateModal(true)
          }}
          onClickDelete={(fee: PrizeFee) => {
            setSelectedPrizeFee(fee)
            setShowDeleteModal(true)
          }}
        />
      </Stack>

      <PrizeFeeAddModal
        showModal={showAddModal}
        prizeFees={fees}
        onClose={() => setShowAddModal(false)}
        onFinish={onFinishModal}
      />

      <PrizeFeeUpdateModal
        showModal={showUpdateModal}
        prizeFee={selectedPrizeFee}
        prizeFees={fees}
        onClose={() => {
          setShowUpdateModal(false)
          setSelectedPrizeFee(undefined)
        }}
        onFinish={onFinishModal}
      />

      <PrizeFeeDeleteModal
        showModal={showDeleteModal}
        prizeFee={selectedPrizeFee}
        onClose={() => {
          setShowDeleteModal(false)
          setSelectedPrizeFee(undefined)
        }}
        onFinish={onFinishModal}
      />
    </>
  )
}

type PrizeFeesTableProps = {
  prizeFees: PrizeFee[]
  onClickEdit: (fee: PrizeFee) => void
  onClickDelete: (fee: PrizeFee) => void
}

const PrizeFeesTable: React.FC<PrizeFeesTableProps> = ({
  prizeFees,
  onClickEdit,
  onClickDelete,
}: PrizeFeesTableProps) => {
  const { isAdmin } = useUserRole()
  return (
    <PaginatedTable
      noMargin
      scrollableY
      items={prizeFees}
      stateKey="prizeFees"
      renderRow={(fee) => {
        return (
          <TableRow key={fee.id} data-testid={fee.id}>
            <ExtTableCell sx={{ p: 2 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack gap={1}>
                  <Typography variant="subtitle1">{fee.fee}円</Typography>
                  <Typography variant="caption">
                    プレイ回数 {fee.playCount}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <IconButton
                    color="primary"
                    disabled={!isAdmin}
                    onClick={() => onClickEdit(fee)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={!isAdmin}
                    onClick={() => onClickDelete(fee)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

type PrizeFeeFormInput = Omit<PutPrizeSettingFeeRequest, "id">

type PrizeFeeAddModalProps = {
  showModal: boolean
  prizeFees: PrizeFee[]
  onClose: () => void
  onFinish?: () => void
}

const validationSchema = Yup.object({
  fee: Yup.number()
    .required("必須です")
    .typeError("数値を入力してください")
    .min(0, "0以上の値を入力してください"),
  playCount: Yup.number()
    .required("必須です")
    .typeError("数値を入力してください")
    .min(0, "0以上の値を入力してください")
    .max(10, "10以下の値を入力してください"),
})

export const PrizeFeeAddModal: React.FC<PrizeFeeAddModalProps> = ({
  showModal,
  prizeFees,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const useFormReturn = useForm<PrizeFeeFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fee: 0,
      playCount: 0,
    },
  })
  const { reset } = useFormReturn

  const onSubmit: SubmitHandler<PrizeFeeFormInput> = async ({
    fee,
    playCount,
  }) => {
    const request: PutPrizeSettingFeeRequest = {
      fee: fee,
      playCount: playCount,
    }

    await submitPromises([
      {
        subject: "料金設定の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingFee(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      prizeFees={prizeFees}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機料金設定を追加"
    />
  )
}

type PrizeFeeUpdateModalProps = {
  showModal: boolean
  prizeFee?: PrizeFee
  prizeFees: PrizeFee[]
  onClose: () => void
  onFinish?: () => void
}

export const PrizeFeeUpdateModal: React.FC<PrizeFeeUpdateModalProps> = ({
  showModal,
  prizeFee,
  prizeFees,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const useFormReturn = useForm<PrizeFeeFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fee: 0,
      playCount: 0,
    },
  })
  const { setValue, reset } = useFormReturn

  useMemo(() => {
    if (prizeFee) {
      setValue("fee", prizeFee.fee)
      setValue("playCount", prizeFee.playCount)
    }
  }, [prizeFee, setValue])

  const onSubmit: SubmitHandler<PrizeFeeFormInput> = async ({
    fee,
    playCount,
  }) => {
    if (!prizeFee) return
    const request: PutPrizeSettingFeeRequest = {
      id: prizeFee.id,
      fee: fee,
      playCount: playCount,
    }

    await submitPromises([
      {
        subject: "料金設定の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingFee(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      prizeFees={prizeFees}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機料金設定編集"
    />
  )
}

type EditModalInnerProps = {
  showModal: boolean
  prizeFees: PrizeFee[]
  onSubmit: SubmitHandler<PrizeFeeFormInput>
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<PrizeFeeFormInput, any>
  title: string
}

const EditModalInner: React.FC<EditModalInnerProps> = ({
  showModal,
  prizeFees,
  onSubmit,
  onClose,
  useFormReturn,
  title,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useFormReturn

  const fee = useWatch({ control, name: "fee" })
  const playCount = useWatch({ control, name: "playCount" })
  const dupError = useMemo(() => {
    const dupPrizeFee = prizeFees.find(
      (f) => f.fee === Number(fee) && f.playCount === Number(playCount),
    )
    if (dupPrizeFee) {
      return "既にこの料金設定は登録されています"
    } else {
      return undefined
    }
  }, [fee, playCount, prizeFees])

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose
          sx={{ textAlign: "left", mt: 1 }}
          variant="h2"
          onClose={onClose}
        >
          <Typography variant="h2">{title}</Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Stack gap={1} sx={{ pb: 2 }}>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              金額
            </Typography>
            <Controller
              control={control}
              name={"fee"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  error={!!errors.fee?.message}
                  helperText={errors.fee?.message}
                />
              )}
            />
          </Stack>
          <Stack gap={1}>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              プレイ回数
            </Typography>
            <Controller
              control={control}
              name={"playCount"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  error={!!errors.playCount?.message}
                  helperText={errors.playCount?.message}
                />
              )}
            />
          </Stack>
          {dupError && (
            <FormHelperText error sx={{ mt: 2 }}>
              {dupError}
            </FormHelperText>
          )}
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!!dupError}
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

type PrizeFeeDeleteModalProps = {
  showModal: boolean
  prizeFee?: PrizeFee
  onClose: () => void
  onFinish?: () => void
}

export const PrizeFeeDeleteModal: React.FC<PrizeFeeDeleteModalProps> = ({
  showModal,
  prizeFee,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickDelete = async () => {
    if (!prizeFee) return
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "料金設定の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeSettingFee(prizeFee.id)
          onClose()
          onFinish()
        },
      },
    ])
    setIsSubmitting(false)
  }

  if (prizeFee) {
    // NOTE: onClose 時に prizeBoothCategory が undefined になるので、message のちらつきを防止
    return (
      <DeleteModal
        showModal={showModal}
        onSubmit={onClickDelete}
        onClose={onClose}
        isSubmitting={isSubmitting}
        message={`「${prizeFee.fee}円」「プレイ回数${prizeFee.playCount}」を削除しますか？`}
      />
    )
  } else {
    return <></>
  }
}
