/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialStockLedgerMaterialsParams,
  GetMaterialStockLedgerMaterialsResponse,
  GetMaterialStockLedgerResponse,
  GetMaterialStockLedgerStoragesParams,
  GetMaterialStockLedgerStoragesResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】副帳票の材料一覧を取得
 */
export const getMaterialStockLedgerMaterials = (
  arcadeCd: string,
  params?: GetMaterialStockLedgerMaterialsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialStockLedgerMaterialsResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/stockLedger`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】副帳票の材料毎の保管場所一覧を取得
 */
export const getMaterialStockLedgerStorages = (
  arcadeCd: string,
  materialCd: string,
  params?: GetMaterialStockLedgerStoragesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialStockLedgerStoragesResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/stockLedger/${materialCd}`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】副帳票を取得
 */
export const getMaterialStockLedger = (
  arcadeCd: string,
  materialCd: string,
  placementId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialStockLedgerResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/stockLedger/${materialCd}/${placementId}`,
      method: "GET",
    },
    options,
  )
}
export type GetMaterialStockLedgerMaterialsResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialStockLedgerMaterials>>
>
export type GetMaterialStockLedgerStoragesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialStockLedgerStorages>>
>
export type GetMaterialStockLedgerResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialStockLedger>>
>
