/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeOperationInParams,
  GetPrizeOperationInResponse,
  PatchPrizeOperationInRequest,
  PutPrizeOperationInRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 検収済みの景品リストを取得
 */
export const getPrizeOperationIn = (
  arcadeCd: string,
  params: GetPrizeOperationInParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeOperationInResponse>(
    { url: `/arcades/${arcadeCd}/prizes/operation/in`, method: "GET", params },
    options,
  )
}
/**
 * @summary 着荷した景品を検収・登録
 */
export const putPrizeOperationIn = (
  arcadeCd: string,
  date: string,
  putPrizeOperationInRequest: PutPrizeOperationInRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/in/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationInRequest,
    },
    options,
  )
}
/**
 * @summary 検収した景品の SEAMS データ処理状態フラグを更新
 */
export const patchPrizeOperationIn = (
  arcadeCd: string,
  date: string,
  prizeCd: string,
  patchPrizeOperationInRequest: PatchPrizeOperationInRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/in/${date}/${prizeCd}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeOperationInRequest,
    },
    options,
  )
}
export type GetPrizeOperationInResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeOperationIn>>
>
export type PutPrizeOperationInResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationIn>>
>
export type PatchPrizeOperationInResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeOperationIn>>
>
