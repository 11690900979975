import {
  PrizeBoothStock,
  PrizeOperationStock,
  PrizeOnBoothStock,
  PrizeShelfStock,
} from "src/api/models"
import { inventoryExecuteDetailsFilterSortByLabels } from "src/domains/prizes/inventoryExecuteRepository"
import { compareByPhoneticOrder } from "src/utils"

export const temporaryStorageName = "荷捌き"

export const filterTemporaryStorages = (
  stocks: PrizeOperationStock[],
  hideEmptyPrize: boolean,
) => {
  return stocks
    .filter((s) =>
      s.shelfStocks.find(
        (shelfStock) =>
          shelfStock.storageName === temporaryStorageName &&
          (hideEmptyPrize ? shelfStock.stock > 0 : true),
      ),
    )
    .map((s) => ({
      prize: s.prize,
      shelfStocks: s.shelfStocks.filter(
        (shelfStock) => shelfStock.storageName === temporaryStorageName,
      ),
      boothStocks: [],
      onBoothStocks: [],
    }))
}

export const isStockEmpty = (operationStock: PrizeOperationStock) => {
  const { shelfStocks, boothStocks, onBoothStocks } = operationStock

  return (
    !shelfStocks.some((shelfStock) => shelfStock.stock > 0) &&
    !boothStocks.some((boothStock) => boothStock.stock > 0) &&
    !onBoothStocks.some((onBoothStock) => onBoothStock.stock > 0)
  )
}

export const operationStockFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
} as const

export const sortOperationStocks = (
  operationStocks: PrizeOperationStock[],
  params: { sortBy?: keyof typeof operationStockFilterSortByLabels },
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: PrizeOperationStock, b: PrizeOperationStock) =>
        compareByPhoneticOrder(a.prize.prizeName, b.prize.prizeName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: PrizeOperationStock, b: PrizeOperationStock) =>
        compareByPhoneticOrder(b.prize.prizeName, a.prize.prizeName)
    }
    return () => 1
  }

  return operationStocks.sort(getSortCompareBy())
}

export type PlacementStock =
  | PrizeShelfStock
  | PrizeBoothStock
  | PrizeOnBoothStock

export const sortPlacementStocks = <T extends PlacementStock>(
  placementStock: T[],
  params: { sortBy?: keyof typeof inventoryExecuteDetailsFilterSortByLabels },
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: T, b: T) => compareByPhoneticOrder(a.prizeName, b.prizeName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: T, b: T) => compareByPhoneticOrder(b.prizeName, a.prizeName)
    }
    if (sortBy === "phoneticIpOrderAsc") {
      return (a: T, b: T) => {
        if (!a.ipName) return 1
        if (!b.ipName) return -1
        return compareByPhoneticOrder(a.ipName, b.ipName)
      }
    }
    if (sortBy === "phoneticIpOrderDesc") {
      return (a: T, b: T) => {
        if (!a.ipName) return 1
        if (!b.ipName) return -1
        return compareByPhoneticOrder(b.ipName, a.ipName)
      }
    }
    // NOTE: デフォルトの表示順を、IP名→景品名の順番で昇順にする
    return (a: T, b: T) => {
      if (!a.ipName) return 1
      if (!b.ipName) return -1
      if (a.ipName === b.ipName) {
        return compareByPhoneticOrder(a.prizeName, b.prizeName)
      }
      return compareByPhoneticOrder(a.ipName, b.ipName)
    }
  }

  return placementStock.sort(getSortCompareBy())
}
