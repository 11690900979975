import { SetStateAction } from "react"

import AutorenewIcon from "@mui/icons-material/Autorenew"
import InfoIcon from "@mui/icons-material/Info"
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  TableRow,
  Link,
  TableHead,
  Card,
  TextField,
  Chip,
} from "@mui/material"
import { useParams, Link as RouterLink } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { filterAccordionSearchState } from "src/recoil"

export const PrizeRecommendation: React.FC = () => {
  return (
    <MainContentLayout
      title="投入推奨景品一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 1, backgroundColor: "info.light" }}>
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <InfoIcon fontSize="small" color="primary" />
                <Typography variant="subtitle1">機能説明</Typography>
              </Stack>
              <Stack gap={1} pl={1}>
                <Typography variant="subtitle2">
                  似た立地の店舗において、直近の土日で、実績の良かった景品を表示しています。「入替推奨」欄にタグが表示されている景品は、入替を検討してください。
                </Typography>
                <Typography variant="caption">
                  機種変更推奨：投入されていますが、他に適した機種があります
                </Typography>
                <Typography variant="caption">
                  投入推奨：店内に在庫がありますが、投入されていません
                </Typography>
                <Typography variant="caption">
                  ※本機能はベータ版です。すでに完売した景品に対して在庫ありと表示されること、店舗にない機種を推奨することがあります。
                </Typography>
              </Stack>
            </Stack>
          </Card>
          <Stack>
            <PrizeRecommendationFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizeRecommendationInner />}
    />
  )
}

type SelectValue = "null" | "true" | "false"
type PrizeRecommendationFilterSearchParams = {
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
  arcadeStock?: SelectValue
  boothStock?: SelectValue
  recommend?: SelectValue
}

const defaultSearchParams = {
  arcadeStock: "null",
  boothStock: "null",
  recommend: "null",
}

const PrizeRecommendationFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeRecommendationFilterSearchParams =
    recoilSearchParams["prizeRecommendationSearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeRecommendationFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeRecommendationSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="並び替え"
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "arcadeStock",
          label: "店内在庫",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null" sx={{ height: 36 }}>
                すべて
              </MenuItem>
              {Object.entries({ true: "あり", false: "なし" }).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
        {
          name: "boothStock",
          label: "投入中",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null" sx={{ height: 36 }}>
                すべて
              </MenuItem>
              {Object.entries({ true: "投入あり", false: "投入なし" }).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
        {
          name: "recommend",
          label: "入替推奨",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null" sx={{ height: 36 }}>
                すべて
              </MenuItem>
              {Object.entries({
                true: "変更推奨あり",
                false: "変更推奨なし",
              }).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

const mockData = [
  {
    prize: {
      prizeCd: "00000001",
      prizeName: "景品名",
    },
    recommend: {
      machine: "機種名2",
    },
    machine: "機種名1",
    arcadeStock: true,
  },
  {
    prize: {
      prizeCd: "00000004",
      prizeName: "景品名",
    },
    recommend: {},
    arcadeStock: false,
  },
  {
    prize: {
      prizeCd: "00000009",
      prizeName: "景品名",
    },
    recommend: {
      machine: "機種名2",
    },
    machine: "機種名1",
    arcadeStock: true,
  },
]

const PrizeRecommendationInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: PrizeRecommendationFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "prizeRecommendationSearchParams"
    ] ?? defaultSearchParams

  return <PrizeRecommendationTable recommendation={mockData} />
}

interface PrizeRecommendationTableProps {
  recommendation: typeof mockData
}

const PrizeRecommendationTable: React.FC<PrizeRecommendationTableProps> = ({
  recommendation,
}) => {
  const { arcadeCd } = useParams()
  const stateKey = "prizeRecommendationTable"

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 460px)",
      }}
    >
      <PaginatedTable
        scrollableY
        scrollableX
        stickyHeader
        noMargin
        items={recommendation}
        stateKey={stateKey}
        header={
          <TableHead sx={{ th: { whiteSpace: "nowrap" } }}>
            <TableRow>
              <ExtTableCell border sticky zIndex={100} fixedWidth={280}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={160}>景品CD</ExtTableCell>
              <ExtTableCell fixedWidth={124}>入替推奨</ExtTableCell>
              <ExtTableCell fixedWidth={136}>投入景品の変更</ExtTableCell>
              <ExtTableCell fixedWidth={200}>投入中機種</ExtTableCell>
              <ExtTableCell fixedWidth={200}>投入推奨機種</ExtTableCell>
              <ExtTableCell fixedWidth={80}>店内在庫</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(item, index) => {
          return (
            <TableRow
              key={index}
              sx={{
                td: {
                  px: 1,
                },
              }}
            >
              <ExtTableCell border sticky zIndex={99}>
                <Link
                  underline="none"
                  sx={{ color: "primary.main", cursor: "pointer" }}
                  onClick={() => {
                    null
                  }}
                >
                  <Typography variant="subtitle1">
                    {item.prize.prizeName}
                  </Typography>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.prize.prizeCd}</ExtTableCell>
              <ExtTableCell>
                {item.recommend && (
                  <Chip
                    color="warning"
                    size="small"
                    label={item.recommend.machine ? "機種変更推奨" : "投入推奨"}
                  />
                )}
              </ExtTableCell>
              <ExtTableCell>
                <Link
                  underline="none"
                  sx={{ color: "primary.main", cursor: "pointer" }}
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/plans/daily/recommendation/${item.prize.prizeCd}/edit/${item.machine}`}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    component={"span"}
                  >
                    <AutorenewIcon sx={{ mr: 1.5 }} />
                    <Typography variant="subtitle1">変更</Typography>
                  </Stack>
                </Link>
              </ExtTableCell>
              <ExtTableCell>{item.machine ?? "投入なし"}</ExtTableCell>
              <ExtTableCell>{item.recommend.machine}</ExtTableCell>
              <ExtTableCell sx={{ textAlign: "center" }}>
                {item.arcadeStock ? "あり" : "なし"}
              </ExtTableCell>
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
