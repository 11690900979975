import { useState } from "react"

import { ExpandCircleDown } from "@mui/icons-material"
import {
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"

import { Prize } from "src/api/models"
import {
  PrizeNewArrival,
  PrizeNewArrivalSearchParams,
} from "src/pages/prizes/register/PrizeNewArrivals"
import { prizeNewArrivalSearchParamsState } from "src/recoil"
import { generateFuzzyRegExp } from "src/utils"

export const PrizeNewArrivalsFilter: React.FC = () => {
  const searchParams = useRecoilValue(prizeNewArrivalSearchParamsState)
  const { prizeName, makerName, ipName } = searchParams
  const [expanded, setExpanded] = useState(!!(prizeName || makerName || ipName))

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={
          <ExpandCircleDown
            sx={(theme) => ({ color: theme.palette.gray["40"] })}
          />
        }
      >
        絞り込み
      </AccordionSummary>

      <AccordionDetails
        sx={{
          m: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PrizeNewArrivalsFilterForm />
      </AccordionDetails>
    </Accordion>
  )
}

export interface PrizeNewArrivalsFilterFormInput {
  prizeName: Prize["prizeName"]
  makerName: Prize["makerName"]
  ipName: Prize["ipName"]
}

export const filterPrizeNewArrivals = (
  prizeNewArrivals: PrizeNewArrival[],
  params: PrizeNewArrivalSearchParams,
) => {
  const { prizeName, makerName, ipName } = params

  return prizeNewArrivals
    .filter((p) =>
      prizeName ? generateFuzzyRegExp(prizeName).test(p.prize.prizeName) : true,
    )
    .filter((p) =>
      makerName ? generateFuzzyRegExp(makerName).test(p.prize.makerName) : true,
    )
    .filter((p) =>
      ipName ? generateFuzzyRegExp(ipName).test(p.prize.ipName) : true,
    )
}

export const PrizeNewArrivalsFilterForm: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    prizeNewArrivalSearchParamsState,
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PrizeNewArrivalsFilterFormInput>()

  const onSubmit: SubmitHandler<PrizeNewArrivalsFilterFormInput> = (data) => {
    const { prizeName, makerName, ipName } = data

    setSearchParams({
      prizeName,
      makerName,
      ipName,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={4} pb={1}>
          景品名
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={searchParams.prizeName || ""}
            error={"prizeName" in errors}
            helperText={errors.prizeName?.message}
            {...register("prizeName")}
          />
        </Grid>

        <Grid item xs={4} pb={1}>
          メーカー
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={searchParams.makerName || ""}
            error={"makerName" in errors}
            helperText={errors.makerName?.message}
            {...register("makerName")}
          />
        </Grid>

        <Grid item xs={4} pb={1}>
          IP名
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={searchParams.ipName || ""}
            placeholder="カタカナ部分一致"
            error={"ipName" in errors}
            helperText={errors.ipName?.message}
            {...register("ipName")}
          />
        </Grid>

        <Grid item xs={12} pt={1}>
          <Button variant="contained" type="submit" fullWidth>
            反映
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
