import { useState } from "react"

import { Star, StarBorder } from "@mui/icons-material"
import { alpha, IconButton } from "@mui/material"

import { theme } from "src/theme"

interface BookmarkIconButtonProps {
  activeByDefault?: boolean
  onAdd?: () => void
  onRemove?: () => void
}

export const BookmarkIconButton: React.FC<BookmarkIconButtonProps> = ({
  activeByDefault = true,
  onAdd = () => undefined,
  onRemove = () => undefined,
}: BookmarkIconButtonProps) => {
  const [isActive, setIsActive] = useState(activeByDefault)
  const [isDisabled, setIsDisabled] = useState(false)

  const onClick = async () => {
    setIsDisabled(true)
    ;(await isActive) ? onRemove() : onAdd()
    setIsActive(!isActive)
    setIsDisabled(false)
  }

  return isActive ? (
    <IconButton
      disableRipple
      disabled={isDisabled}
      onClick={() => onClick()}
      sx={{
        color: "warning.light",
        "&:disabled": {
          color: alpha(theme.palette.warning.light, 0.5),
        },
      }}
    >
      <Star fontSize="inherit" />
    </IconButton>
  ) : (
    <IconButton
      disableRipple
      disabled={isDisabled}
      onClick={() => onClick()}
      sx={{
        color: "neutral.300",
        "&:disabled": {
          color: alpha(theme.palette.neutral[300], 0.5),
        },
      }}
    >
      <StarBorder fontSize="inherit" />
    </IconButton>
  )
}
