import { useParams } from "react-router-dom"

import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { Placement } from "src/pages/materials/placements/InventoryMaterialPlacements"

const placementsCsvHeader = ["URL", "分類"]

export const useMaterialInventoryPlacementsCsv = () => {
  const { downloadCsv } = useDownloadCsv()
  const { arcadeCd } = useParams()
  const { origin } = window

  const downloadPlacementsCsv = (placements: Placement[]) => {
    const headerRow = { columns: placementsCsvHeader }

    const rows = placements.map((placement) => {
      const { placementType, storage, shelf, materialMachine, amMachine } =
        placement
      const { Storage, InMachine } = MaterialPlacementType

      if (placementType === Storage) {
        return {
          columns: [
            `${origin}/arcades/${arcadeCd}/materials/placements/storage/${storage?.id}/${shelf?.id}`,
            `外 ${storage?.name || ""} ${shelf?.name || ""}`,
          ],
        }
      }
      if (placementType === InMachine) {
        return {
          columns: [
            `${origin}/arcades/${arcadeCd}/materials/placements/machine/${materialMachine?.id}`,
            `内 ${getDisplayMaterialMachineName(amMachine, materialMachine)}`,
          ],
        }
      }
      return { columns: [] }
    })

    const csv = {
      headerRow,
      rows,
    }

    downloadCsv({ csv, fileName: `material_placement_urls_${arcadeCd}` })
  }

  return {
    downloadPlacementsCsv,
  }
}
