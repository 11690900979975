import { AmMachine, MaterialMachine } from "src/api/models"

export const getGroupInitialName = (groupName: string) =>
  // NOTE: 文言「棚卸グループ」は手動で除去
  groupName.replace("棚卸", "").replace("グループ", "")[0]

export const getDisplayMaterialMachineName = (
  amMachine: AmMachine | undefined,
  materialMachine: MaterialMachine | undefined,
  isShortening = false,
) => {
  if (!amMachine) {
    return ""
  }
  if (!materialMachine) {
    return ""
  }
  const amMachineDisplayName =
    amMachine.amMachineName !== ""
      ? amMachine.amMachineName
      : amMachine.amMachineNumber
  const cylinderDisplayName =
    (materialMachine.cylinderName || "") !== ""
      ? materialMachine.cylinderName
      : String(materialMachine.cylinderId).padStart(3, "0")
  if (isShortening) {
    return cylinderDisplayName || ""
  }
  return amMachineDisplayName + "-" + cylinderDisplayName
}
