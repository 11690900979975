import axios, { AxiosError } from "axios"

import { ErrorResponse } from "src/api/models"

type Refresher = () => Promise<string | null>
type OnRefreshSuccess = (t: string | null) => void

const key = "X-Axios-Retry-Refresh"

export const refreshInterceptor = (
  refresher: Refresher,
  onSuccess: OnRefreshSuccess,
) => {
  return (error: Error) => {
    if (!axios.isAxiosError(error)) {
      return Promise.reject(error)
    }
    return handleAxiosError(error, refresher, onSuccess)
  }
}

const handleAxiosError = async (
  error: AxiosError<ErrorResponse>,
  refresher: Refresher,
  onSuccess: OnRefreshSuccess,
) => {
  const canRefreshError =
    error.response &&
    error.response.status === 401 &&
    error.response.data.code === 3
  if (!canRefreshError) return Promise.reject(error)

  if (!error.config) return Promise.reject(error)
  const isTwice = Boolean(error.config.headers && error.config.headers[key])
  if (isTwice) return Promise.reject(error)
  const firstConfig = error.config

  const onRefreshed = (tok: string | null) => {
    onSuccess(tok)

    if (firstConfig.headers) {
      firstConfig.headers["Authorization"] = `Bearer ${tok}`
      firstConfig.headers[key] = true
    }
    return axios(firstConfig)
  }

  const tok = await refresh(refresher)
  return onRefreshed(tok)
}

let pro: Promise<string | null> | undefined = undefined

const refresh = (refresher: Refresher) => {
  if (pro === undefined) {
    // TODO: clear interval
    setTimeout(
      () => {
        pro = undefined
      },
      3 * 60 * 1000, // TODO: fix time id tokenより短い時間
    )

    pro = refresher()
    return pro
  } else {
    return pro
  }
}
