import React, { ReactNode, Suspense } from "react"

import { Container, Stack, Typography } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { LoadingBox } from "src/components/molecules/LoadingBox"

export type MainContentLayoutProps = {
  title: string
  subtitle?: string
  caption?: string
  renderContent: () => ReactNode
  renderFilter?: () => ReactNode
  renderAction?: () => ReactNode | boolean
  disableBackButton?: boolean
  backButtonLabel?: string
  onClickBackButton?: React.MouseEventHandler<HTMLButtonElement>
}

// Refer: usage sample: https://github.com/gse-platon/u1-web/pull/1686/files#diff-b461bddd7e03f97dc12a3e30a64988a7b5ff1a9a4eff60b63c5ce0937e36b2fb
export const MainContentLayout: React.FC<MainContentLayoutProps> = ({
  title,
  subtitle,
  caption,
  renderContent,
  renderFilter,
  renderAction,
  disableBackButton = false,
  backButtonLabel = "戻る",
  onClickBackButton,
}) => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          pb: 2,
        }}
      >
        <Stack gap={2}>
          <Typography variant="h1">{title}</Typography>
          {subtitle && <Typography variant="h2">{subtitle}</Typography>}
          {caption && <Typography variant="caption">{caption}</Typography>}
        </Stack>

        {renderAction && renderAction()}
      </Stack>

      {renderFilter && <Stack sx={{ pb: 2 }}>{renderFilter()}</Stack>}

      <Suspense fallback={<LoadingBox />}>{renderContent()}</Suspense>

      {!disableBackButton && (
        <Stack sx={{ pt: 3 }}>
          {onClickBackButton ? (
            <BackButton onClick={onClickBackButton}>
              {backButtonLabel}
            </BackButton>
          ) : (
            <BackButton>{backButtonLabel}</BackButton>
          )}
        </Stack>
      )}
    </Container>
  )
}
