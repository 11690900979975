import { useMemo } from "react"

import { Grid, Typography, Button } from "@mui/material"
import { useParams } from "react-router-dom"

import { CsvRow } from "src/api/models"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { CsvTable } from "src/components/organisms/CsvTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { makeCSVRowsDataFromInventoryStocks } from "src/domains/inventoryCsvRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"

const csvHeader: CsvRow = {
  columns: [
    "分類",
    "保管場所名",
    "棚名",
    "フロアマップ位置名",
    "景品CD",
    "個数",
  ],
}

export const InventoryPrizeBatchCsvOutput = () => {
  return (
    <MainContentLayout
      title="現在の在庫をCSVで出力"
      renderContent={() => <InventoryPrizeBatchCsvOutputMenu />}
    />
  )
}

const InventoryPrizeBatchCsvOutputMenu = () => {
  const { arcadeCd } = useParams()

  const { resource } = useResource({
    subject: "CSV出力用在庫一覧の取得",
    fetch: arcadeCd ? () => getPrizeOperationStocks(arcadeCd) : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}`,
  })
  const inventoryStocks = resource?.data.stocks
  const outputCsv = useMemo(() => {
    const rows = makeCSVRowsDataFromInventoryStocks(inventoryStocks ?? [])
    return { headerRow: csvHeader, rows }
  }, [inventoryStocks])

  const { downloadCsv } = useDownloadCsv()

  return (
    <>
      {outputCsv ? (
        <>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography sx={{ mb: 1 }}>
              以下の内容をCSVファイルに出力します。
            </Typography>
            <CsvTable {...{ csv: outputCsv }} />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() =>
                downloadCsv({
                  csv: outputCsv,
                  fileName: `inventory_stocks_output_${arcadeCd}.csv`,
                })
              }
            >
              CSVファイルに出力する
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          出力対象のデータがありません。
        </Grid>
      )}
    </>
  )
}
