import { useState, useMemo, useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Typography,
  TextField,
  Chip,
  Card,
  Divider,
} from "@mui/material"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import * as Yup from "yup"

import {
  getMaterialInventoryHistories,
  putMaterialInventoryHistories,
} from "src/api/material-inventory-histories"
import {
  Material,
  MaterialInventoryHistory,
  PutMaterialInventoryHistoriesRequest,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { currentInventoryPeriodState } from "src/recoil"
import { PickPartial } from "src/types"
import { getToday } from "src/utils"

interface InventoryMaterialExecuteFormInput {
  histories: {
    stockId: number
    cartonsCount: number
    separatedCount: number
  }[]
  note?: string
}

export interface InventoryMaterialExecuteProps {
  placementType: MaterialPlacementType
  placementStocks: (
    | MaterialOperationShelfStock
    | MaterialOperationMachineStock
  )[]
  material: PickPartial<
    Material,
    "materialCd" | "materialName",
    "unitPerCarton"
  >
  onFinish: () => void
}

type Stock = {
  stockId: number
  stockCount: number
  historyCount: number | undefined
  placementName: string
}

const getStock = (
  placementType: MaterialPlacementType,
  placementStock: MaterialOperationShelfStock | MaterialOperationMachineStock,
  histories: MaterialInventoryHistory[],
): Stock => {
  const { Storage, InMachine } = MaterialPlacementType
  if (placementType === Storage) {
    const { shelfStock, shelf } = placementStock as MaterialOperationShelfStock
    const historyStock = histories.find(
      (history) => history.shelfStock?.id === shelfStock.id,
    )?.stock
    return {
      stockId: shelfStock.id,
      stockCount: shelfStock.stock,
      historyCount: historyStock,
      placementName: shelf.name,
    }
  }
  if (placementType === InMachine) {
    const { machineStock, amMachine, materialMachine } =
      placementStock as MaterialOperationMachineStock
    const historyStock = histories.find(
      (history) => history.machineStock?.id === machineStock.id,
    )?.stock
    return {
      stockId: machineStock.id,
      stockCount: machineStock.stock,
      historyCount: historyStock,
      placementName: getDisplayMaterialMachineName(
        amMachine,
        materialMachine,
        true,
      ),
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _never: never = placementType
  throw new Error()
}

export const InventoryMaterialExecute: React.FC<
  InventoryMaterialExecuteProps
> = ({
  placementType,
  material,
  placementStocks,
  onFinish,
}: InventoryMaterialExecuteProps) => {
  const { arcadeCd } = useParams()
  const { materialCd } = material
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const [isExecuted, setIsExecuted] = useState(false)

  const { resource: historiesResource, refetchForce: refetchHistories } =
    useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryHistories(
                arcadeCd,
                currentMaterialExecutionPeriod.id,
                { materialCd },
              )
          : undefined,
      recoilKey: `getMaterialInventoryHistories:${arcadeCd}:${currentMaterialExecutionPeriod?.id}:${materialCd}`,
    })
  const stocks = useMemo(
    () =>
      placementStocks.map((placementStock) =>
        getStock(
          placementType,
          placementStock,
          historiesResource?.data.histories || [],
        ),
      ),
    [placementType, placementStocks, historiesResource],
  )

  const onExecuted = () => {
    setIsExecuted(true)
    refetchHistories()
  }

  return (
    <Box>
      {!isExecuted ? (
        <InventoryMaterialExecuteInputMenu
          placementType={placementType}
          stocks={stocks}
          material={material}
          onExecuted={onExecuted}
          onClose={onFinish}
        />
      ) : (
        <InventoryMaterialExecuteConfirmMenu
          stocks={stocks}
          material={material}
          onClose={onFinish}
        />
      )}
    </Box>
  )
}

interface InventoryMaterialExecuteInputMenuProps {
  placementType: MaterialPlacementType
  stocks: Stock[]
  material: PickPartial<
    Material,
    "materialCd" | "materialName",
    "unitPerCarton"
  >
  onExecuted: () => void
  onClose: () => void
}

const InventoryMaterialExecuteInputMenu: React.FC<
  InventoryMaterialExecuteInputMenuProps
> = ({
  placementType,
  stocks,
  material,
  onExecuted,
  onClose,
}: InventoryMaterialExecuteInputMenuProps) => {
  const { arcadeCd } = useParams()
  const unitPerCarton = material.unitPerCarton || 0
  const getTotalStockCount = (stocks: Stock[]) =>
    stocks.reduce((sum, stock) => sum + stock.stockCount, 0)

  const { defaultTotalStockCount, defaultValues } = useMemo(() => {
    const defaultTotalStockCount = getTotalStockCount(stocks)
    const defaultValues = {
      histories: stocks.map((stock) => {
        return {
          stockId: stock.stockId,
          cartonsCount: 0,
          separatedCount: stock.historyCount || stock.stockCount || 0,
        }
      }),
      note: "",
    }
    return {
      defaultTotalStockCount,
      defaultValues,
    }
  }, [stocks])

  const validationSchema = Yup.object({
    histories: Yup.array(
      Yup.object({
        stockId: Yup.number().required("必須です"),
        cartonsCount: Yup.number()
          .required("必須です")
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください"),
        separatedCount: Yup.number()
          .required("必須です")
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください"),
      }),
    ).required("必須です"),
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    reset,
  } = useForm<InventoryMaterialExecuteFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })
  useEffect(() => reset(defaultValues), [reset, defaultValues])
  const value = {
    histories: useWatch({ control, name: "histories" }) || [],
  }
  const valueTotalStockCount = value.histories.reduce(
    (sum, history) =>
      sum +
      Number(history.cartonsCount) * unitPerCarton +
      Number(history.separatedCount),
    0,
  )

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryMaterialExecuteFormInput> = async (
    data,
  ) => {
    // NOTE: stock = 0 の場合も送信可能にする
    if (!arcadeCd) return

    await submitPromises([
      {
        subject: "棚卸実施",
        showSuccessMessage: true,
        promise: async () => {
          for (const history of data.histories) {
            const stock =
              history.cartonsCount * unitPerCarton + history.separatedCount
            const request: PutMaterialInventoryHistoriesRequest = {
              stock,
              placementStockId: history.stockId,
              placement: placementType,
              note: data.note ?? "",
            }
            await putMaterialInventoryHistories(arcadeCd, getToday(), request)
          }
          onExecuted()
        },
      },
    ])
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 2, mb: 3 }}>
          <Typography variant="h1" pb={1}>
            {material.materialName}
          </Typography>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography color="text.secondary">材料CD</Typography>
            <Chip
              label={
                <Typography variant="subtitle1">
                  {material.materialCd}
                </Typography>
              }
              sx={(theme) => ({
                ml: 1,
                background: theme.palette.primary.light,
              })}
            />
          </Box>

          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            rowSpacing={1}
          >
            <Grid item xs={8}>
              カートン入数
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <strong>{unitPerCarton}</strong> 個
            </Grid>
            <Grid item xs={8}>
              データ上の個数
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <strong>{defaultTotalStockCount}</strong> 個
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item xs={8}>
              カウントした個数
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              合計 <strong>{valueTotalStockCount}</strong> 個
            </Grid>

            {stocks.map((stock, index) => {
              const historyErrors =
                (errors.histories && errors.histories[index]) || {}
              return (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>

                  <Grid item xs={12}>
                    {stock.placementName}
                  </Grid>

                  {unitPerCarton > 0 && (
                    <>
                      <Grid item xs={8}>
                        <Typography variant="body2" color="text.secondary">
                          箱
                        </Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: "right" }}>
                        <TextField
                          error={"cartonsCount" in historyErrors}
                          helperText={historyErrors.cartonsCount?.message}
                          {...register(`histories.${index}.cartonsCount`)}
                          inputProps={{
                            inputMode: "numeric",
                            "aria-label": "cartonsCount",
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sx={{ textAlign: "right" }}>
                        CT
                      </Grid>
                    </>
                  )}

                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      バラ
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    <TextField
                      error={"separatedCount" in historyErrors}
                      helperText={historyErrors.separatedCount?.message}
                      {...register(`histories.${index}.separatedCount`)}
                      inputProps={{
                        inputMode: "numeric",
                        "aria-label": "separatedCount",
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "right" }}>
                    個
                  </Grid>
                </>
              )
            })}

            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                label="備考"
                fullWidth
                error={"note" in errors}
                helperText={errors.note?.message}
                {...register("note")}
              />
            </Grid>
          </Grid>
        </Card>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          fullWidth
          sx={{ mb: 3 }}
        >
          {stocks.some((stock) => !stock.historyCount)
            ? "変更を保存する"
            : "確定する"}
        </LoadingButton>

        <BackButton onClick={onClose}>戻る</BackButton>
      </form>
    </Box>
  )
}

interface InventoryMaterialExecuteConfirmMenuProps {
  stocks: Stock[]
  material: PickPartial<
    Material,
    "materialCd" | "materialName",
    "unitPerCarton"
  >
  onClose: () => void
}

const InventoryMaterialExecuteConfirmMenu: React.FC<
  InventoryMaterialExecuteConfirmMenuProps
> = ({
  stocks,
  material,
  onClose,
}: InventoryMaterialExecuteConfirmMenuProps) => {
  const unitPerCarton = material.unitPerCarton || 0

  const { totalStockCount, totalHistoryCount } = stocks.reduce(
    (sum, stock) => {
      return {
        totalStockCount: sum.totalStockCount + stock.stockCount,
        totalHistoryCount: sum.totalHistoryCount + (stock.historyCount || 0),
      }
    },
    { totalStockCount: 0, totalHistoryCount: 0 },
  )

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AlertCaptionCard label="下記の通り確定しました" />
      <Card sx={{ p: 2, mb: 3 }}>
        <Typography variant="h1" pb={1}>
          {material.materialName}
        </Typography>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography color="text.secondary">材料CD</Typography>
          <Chip
            label={
              <Typography variant="subtitle1">{material.materialCd}</Typography>
            }
            sx={(theme) => ({
              ml: 1,
              background: theme.palette.primary.light,
            })}
          />
        </Box>

        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={8}>
            カートン入数
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{unitPerCarton}</strong> 個
          </Grid>
          <Grid item xs={8}>
            データ上の個数
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{totalStockCount}</strong> 個
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={8}>
            カウントした個数
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            合計 <strong>{totalHistoryCount}</strong> 個
          </Grid>

          {stocks.map((stock) => (
            <>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
              </Grid>

              <Grid item xs={12}>
                {stock.placementName}
              </Grid>

              {unitPerCarton > 0 && (
                <>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      箱
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>
                      {Math.floor((stock.historyCount || 0) / unitPerCarton)}
                    </strong>{" "}
                    CT
                  </Grid>
                </>
              )}

              <Grid item xs={8}>
                <Typography variant="body2" color="text.secondary">
                  バラ
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <strong>{(stock.historyCount || 0) % unitPerCarton}</strong> 個
              </Grid>
            </>
          ))}
        </Grid>
      </Card>

      <BackButton onClick={onClose}>戻る</BackButton>
    </Box>
  )
}
