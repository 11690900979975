import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import { getMaterialMachines } from "src/api/material-machines"
import { AmMachine, MaterialInventoryPlacementStatus } from "src/api/models"
import { InventoryMaterialExecutePlacementListItem } from "src/components/organisms/materials/InventoryMaterialExecuteList"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"
import { formatApiDate } from "src/utils"

export const useInventoryExecuteMachines = (searchParams?: {
  amMachineName?: AmMachine["amMachineName"]
  amMachineNumber?: AmMachine["amMachineNumber"]
}) => {
  const { arcadeCd } = useParams()
  const { InMachine } = MaterialPlacementType

  const { resource: materialMachinesResource } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd
      ? () =>
          getMaterialMachines(arcadeCd, {
            amMachineName: searchParams?.amMachineName,
            exactAmMachineNumber: searchParams?.amMachineNumber,
          })
      : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const materialMachines = useMemo(
    () => materialMachinesResource?.data.materialMachines || [],
    [materialMachinesResource],
  )

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const { resource: placementStatusesResource } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? () =>
            getMaterialInventoryPlacementStatuses(
              arcadeCd,
              InMachine,
              currentMaterialExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${InMachine}:${JSON.stringify(
      currentMaterialExecutionPeriod,
    )}`,
  })
  const placementStatuses = useMemo(() => {
    const { counts, machineStatuses } = placementStatusesResource?.data || {}
    if (counts) {
      return machineStatuses
    }
    return
  }, [placementStatusesResource])

  const placementStatusesPerMachineId: {
    [materialMachineId: number]: MaterialInventoryPlacementStatus | undefined
  } = useMemo(() => {
    return materialMachines.reduce((obj, materialMachine) => {
      const placementId = materialMachine.materialMachine.id
      const placementStatus = placementStatuses?.find(
        (status) => status.placementId === placementId,
      )
      return { ...obj, [materialMachine.materialMachine.id]: placementStatus }
    }, {})
  }, [materialMachines, placementStatuses])

  const placementListItems: InventoryMaterialExecutePlacementListItem<"in_machine">[] =
    useMemo(
      () =>
        materialMachines.map((materialMachine) => {
          // TODO: fix
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const placement = materialMachine.materialMachine.machineShelves![0]!
          const placementStatus = (placementStatuses || []).find(
            (placementStatus) => placementStatus.placementId === placement.id,
          )
          return {
            amMachineNumber: materialMachine.amMachine.amMachineNumber,
            materialMachineId: materialMachine.materialMachine.id,
            name: getDisplayMaterialMachineName(
              materialMachine.amMachine,
              materialMachine.materialMachine,
            ),
            parentName: materialMachine.amMachine.amMachineName,
            isAvailable: placement.isAvailable,
            groupName: placement?.inventoryGroup?.groupName,
            executionStatus: placementStatus?.executionStatus,
            executedAt: formatApiDate(placementStatus?.executedAt || ""),
            warning: materialMachine.materialMachine.warning,
          }
        }),
      [materialMachines, placementStatuses],
    )

  const materialMachineGroupNames = useMemo(
    () =>
      Array.from(
        new Set(
          materialMachines
            .map(
              (materialMachine) =>
                // TODO: fix
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                materialMachine.materialMachine.machineShelves![0]!
                  .inventoryGroup?.groupName,
            )
            .filter((v) => !!v) as string[],
        ),
      ),
    [materialMachines],
  )

  return {
    placementListItems,
    materialMachineGroupNames,
    materialMachines,
    placementStatusesPerMachineId,
  }
}
