import { useState, useMemo, useCallback } from "react"

import { Typography, Button, Card, List, Stack } from "@mui/material"
import { AxiosResponse } from "axios"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  GetPrizePlacementStatusesResponse,
  GetPrizePlacementTypeStatusResponse,
  GetPrizePlacementTypeStatusResponseStatusEnum,
  PutPrizePlacementTypeStatusRequestStatusEnum,
} from "src/api/models"
import {
  getPrizePlacementStatuses,
  getPrizePlacementTypeStatus,
  putPrizePlacementTypeStatus,
} from "src/api/prize-placement-statuses"
import { MenuButton } from "src/components/atoms/MenuButton"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import { InventoryPrizeLockModal } from "src/components/organisms/prizes/InventoryPrizeLockModal"
import { InventoryPrizeRemoveLockModal } from "src/components/organisms/prizes/InventoryPrizeRemoveLockModal"
import { InventoryPrizeStatus } from "src/components/organisms/prizes/InventoryPrizeStatus"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource, UseResourceReturn } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentInventoryPeriodState,
  snackbarErrorMessageState,
} from "src/recoil"
import { getToday } from "src/utils"

export const InventoryPrizeExecuteInBooth = () => {
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  if (currentPrizeExecutionPeriod) {
    return (
      <MainContentLayout
        title="在庫カウント(プライズ機[内])"
        renderContent={() => <InventoryPrizeExecuteInBoothMenu />}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="ブース内棚卸"
      period={currentInventoryPeriod}
      featurePeriod={currentPrizeExecutionPeriod}
    />
  )
}

const InventoryPrizeExecuteInBoothMenu = () => {
  const { arcadeCd } = useParams()
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const placementStatusesReturn = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementStatuses(
              arcadeCd,
              PlacementType.InBooth,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      PlacementType.InBooth
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })

  const placementTypeStatusReturn = useResource({
    subject: "ロック状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementTypeStatus(
              arcadeCd,
              PlacementType.InBooth,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementTypeStatus:${arcadeCd}:${
      PlacementType.InBooth
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })

  return (
    <InventoryPrizeExecuteInBoothMenuTemplate
      {...{
        placementStatusesReturn,
        placementTypeStatusReturn,
      }}
    />
  )
}

interface InventoryExecuteInBoothMenuTemplateProps {
  placementStatusesReturn?: UseResourceReturn<
    AxiosResponse<GetPrizePlacementStatusesResponse>
  >
  placementTypeStatusReturn?: UseResourceReturn<
    AxiosResponse<GetPrizePlacementTypeStatusResponse>
  >
}

const InventoryPrizeExecuteInBoothMenuTemplate: React.FC<
  InventoryExecuteInBoothMenuTemplateProps
> = ({ placementStatusesReturn, placementTypeStatusReturn }) => {
  const { arcadeCd } = useParams()
  const [showLockModal, setLockModal] = useState(false)

  const { counts } = placementStatusesReturn?.resource?.data || {}

  const placementTypeStatus = placementTypeStatusReturn?.resource?.data.status
  const isLocked = useMemo(
    () =>
      placementTypeStatus ===
      GetPrizePlacementTypeStatusResponseStatusEnum.Locked,
    [placementTypeStatus],
  )

  const { isAvailableInventoryLock } = useUserRole()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const refetch = useCallback(() => {
    placementStatusesReturn?.refetch()
    placementTypeStatusReturn?.refetch()
  }, [placementStatusesReturn, placementTypeStatusReturn])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const callPutPlacemenTypeStatus = useCallback(
    (status: PutPrizePlacementTypeStatusRequestStatusEnum) => {
      arcadeCd &&
        submitPromises([
          {
            subject: "ロック状況の更新",
            showSuccessMessage: true,
            promise: async () => {
              await putPrizePlacementTypeStatus(
                arcadeCd,
                PlacementType.InBooth,
                {
                  date: getToday(),
                  status,
                },
              )
              refetch()
              setLockModal(false)
            },
          },
        ])
    },
    [arcadeCd, submitPromises, refetch],
  )

  const onClickLockButton = () => {
    if (!isAvailableInventoryLock) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!isLocked && counts && counts.notExecuted > 0) {
      return setErrorMessage("未対応箇所があるため、ロックできません")
    }
    setLockModal(true)
  }

  return (
    <>
      {placementTypeStatus && isLocked && (
        <InventoryPrizeRemoveLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutPrizePlacementTypeStatusRequestStatusEnum.Open,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      {placementTypeStatus && !isLocked && (
        <InventoryPrizeLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutPrizePlacementTypeStatusRequestStatusEnum.Locked,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      <Stack gap={2}>
        <InventoryPrizeStatus counts={counts} />

        <Card>
          <List sx={{ width: "100%", py: 2 }}>
            <MenuButton
              to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/floorMap`}
            >
              フロアマップで棚卸
            </MenuButton>

            <MenuButton
              to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/list`}
            >
              プライズ機一覧で棚卸
            </MenuButton>

            <MenuButton onClick={() => onClickLockButton()}>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Typography>ロック機能</Typography>
                {placementTypeStatus && (
                  <Typography color="text.primary">
                    {isLocked ? "ロック中" : "解除中"}
                  </Typography>
                )}
              </Stack>
            </MenuButton>
          </List>
        </Card>

        <Button
          variant="outlined"
          fullWidth
          component={RouterLink}
          to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/add`}
        >
          過去取扱景品からブース内に登録
        </Button>
      </Stack>
    </>
  )
}
