import { useLocation, useNavigate } from "react-router-dom"

import { Material } from "src/api/models"
import { InventoryMaterialsTableRow } from "src/components/organisms/materials/InventoryMaterialsTableRow"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"

export type InventoryMaterialPlacementMaterialsMenuItem = {
  material: Pick<Material, "materialCd" | "materialName">
  stock: number
}

export type InventoryMaterialPlacementMaterialsMenuProps = {
  menuItems: InventoryMaterialPlacementMaterialsMenuItem[]
  searchParams?: URLSearchParams
}

export const InventoryMaterialPlacementMaterialsMenu: React.FC<
  InventoryMaterialPlacementMaterialsMenuProps
> = ({ menuItems, searchParams }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <PaginatedTable
      noMargin
      items={menuItems}
      stateKey="inventoryPlacementMaterialsMenu"
      renderRow={({ material, stock }) => (
        <InventoryMaterialsTableRow
          key={material.materialCd}
          material={material}
          stock={stock}
          onRowClick={() =>
            navigate({
              pathname: `${pathname}/${encodeURIComponent(
                material.materialCd,
              )}`,
              ...(searchParams && { search: `?${searchParams}` }),
            })
          }
        />
      )}
    />
  )
}
