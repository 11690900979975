import { Suspense, useMemo } from "react"

import {
  alpha,
  Box,
  Typography,
  Grid,
  Card,
  Link,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
} from "@mui/material"
import { Link as RouterLink, useLocation, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import {
  PrizeToneBoothInfo,
  PrizeToneAlertConjunctionEnum,
} from "src/api/models"
import { getPrizeToneBooths } from "src/api/prize-tone-booths"
import {
  CardKeyCell,
  CardSubtitleCell,
  CardItemNameBox,
  CardValueCell,
  boldRedStyle,
} from "src/components/molecules/CardTableCells"
import {
  AlertFormModal,
  AlertParams,
  checkPayoutRateAlert,
  checkSalesAlert,
} from "src/components/organisms/AlertFormModal"
import { MachineAlertParams } from "src/components/organisms/MachineAlerts"
import {
  encodeBoothUnitsQuery,
  getBoothUnitUILabel,
} from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { alertParamsState } from "src/recoil"
import { theme } from "src/theme"
import { roundNearest100, getJpWeek, getBackgroundColors } from "src/utils"

export const BoothsSalesTab: React.FC = () => {
  const [alertParams, setAlertParams] = useRecoilState(alertParamsState)
  return (
    <>
      <Box mb={2}>
        <AlertFormModal {...{ alertParams, setAlertParams }} />
      </Box>

      <Grid container>
        <Suspense
          fallback={
            <CircularProgress sx={{ display: "block", margin: "auto" }} />
          }
        >
          <BoothsSalesTabInner />
        </Suspense>
      </Grid>
    </>
  )
}

const BoothsSalesTabInner: React.FC = () => {
  const { arcadeCd } = useParams()

  const alertParams = useRecoilValue(alertParamsState)

  const booths = useResource({
    subject: "ブース情報の取得",
    fetch: arcadeCd
      ? () => getPrizeToneBooths(arcadeCd, { soldOn: alertParams.dateLabel })
      : undefined,
    recoilKey: `getBooths:${arcadeCd}:${alertParams.dateLabel}`,
  }).resource?.data.booths

  return (
    <>
      {booths?.map((booth) => (
        <Grid key={getBoothUnitUILabel(booth)} item xs={6} sm={4} lg={3}>
          <BoothCard {...{ booth, alertParams }} />
        </Grid>
      ))}
    </>
  )
}

const checkMachineAlert = (
  booth: PrizeToneBoothInfo,
  machineAlerts?: MachineAlertParams[],
) => {
  const boothMachineAlerts = machineAlerts?.filter(
    (a) => a.machineName === booth.machineName,
  )
  return {
    isPayoutRateMachineAlert: boothMachineAlerts?.find((a) =>
      checkPayoutRateAlert(booth, a.payoutRateRange, a.payoutRate),
    ),
    isSalesMachineAlert: boothMachineAlerts?.find((a) =>
      checkSalesAlert(booth, a.salesRange, a.sales),
    ),
  }
}

const checkAlert = (booth: PrizeToneBoothInfo, alertParams: AlertParams) => {
  const { isPayoutRateMachineAlert, isSalesMachineAlert } = checkMachineAlert(
    booth,
    alertParams.machineAlerts,
  )
  return {
    isPayoutRateAlert:
      checkPayoutRateAlert(
        booth,
        alertParams.payoutRateRange,
        alertParams.payoutRate,
      ) || isPayoutRateMachineAlert,
    isSalesAlert:
      checkSalesAlert(booth, alertParams.salesRange, alertParams.sales) ||
      isSalesMachineAlert,
  }
}

interface BoothCardProps {
  booth: PrizeToneBoothInfo
  alertParams: AlertParams
}

const BoothCard: React.FC<BoothCardProps> = ({
  booth,
  alertParams,
}: BoothCardProps) => {
  const { pathname } = useLocation()
  const { arcadeCd } = useParams()
  const { isViewableCurrentArcadeSales } = useUserRole()
  const {
    machineName,
    boothName,
    prizeCd,
    prizeName,
    weeklySales,
    dailySales,
    dailyPayOut,
    weeklyPayOut,
    daysInWeek,
    publishedAt,
  } = booth

  const { isPayoutRateAlert, isSalesAlert } = checkAlert(booth, alertParams)
  const isSaleWeek =
    getJpWeek(publishedAt ?? "") === getJpWeek(alertParams.dateLabel)
  const isAlert = useMemo(() => {
    if (!isViewableCurrentArcadeSales) return false

    if (alertParams.conjunction === PrizeToneAlertConjunctionEnum.And) {
      return isPayoutRateAlert && isSalesAlert
    }
    return isPayoutRateAlert || isSalesAlert
  }, [
    isViewableCurrentArcadeSales,
    isPayoutRateAlert,
    isSalesAlert,
    alertParams,
  ])
  const getBackgroundStyle = () => {
    const colors = {
      [alpha(theme.palette.primary.light, 0.3)]: isSaleWeek,
      [alpha(theme.palette.error.light, 0.3)]: isAlert,
    }
    const shouldFillColors = Object.keys(colors).filter(
      (color) => colors[color],
    )
    if (shouldFillColors.length === 0) {
      return "white"
    }
    return getBackgroundColors(shouldFillColors)
  }
  const hasLabel = !!booth.publishedAt || false

  const PrizeNameBox = isViewableCurrentArcadeSales ? CardItemNameBox : Box

  return (
    <Card
      sx={{
        m: 1,
        p: 0,
        pb: 1,
        background: getBackgroundStyle(),
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 16px)",
      }}
    >
      <Link
        component={RouterLink}
        to={`${pathname}/details?boothUnits=${encodeBoothUnitsQuery([
          { boothName, machineName },
        ])}&date=${alertParams.dateLabel}`}
        underline="none"
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <CardValueCell colSpan={2} sx={{ p: 2 }}>
                <Typography variant="subtitle1">
                  {getBoothUnitUILabel(booth)}
                </Typography>
                {hasLabel && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "stretch",
                      minWidth: "100px",
                      mt: 1,
                    }}
                  >
                    {!!publishedAt && (
                      <Box
                        sx={{
                          border: 1,
                          flexGrow: 1,
                          borderRadius: "2px",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="label">発売週</Typography>
                      </Box>
                    )}
                  </Box>
                )}
                <PrizeNameBox sx={{ mt: 1 }}>
                  <Link
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/stocks/${encodeURIComponent(
                      prizeCd,
                    )}`}
                    underline="none"
                  >
                    <Typography variant="subtitle1">{prizeName}</Typography>
                  </Link>
                </PrizeNameBox>
              </CardValueCell>
            </TableRow>

            {isViewableCurrentArcadeSales && (
              <>
                <TableRow>
                  <CardSubtitleCell colSpan={2}>1日</CardSubtitleCell>
                </TableRow>
                <TableRow>
                  <CardKeyCell>売上</CardKeyCell>
                  <CardValueCell
                    sx={{ px: 0.5, ...(isSalesAlert && boldRedStyle) }}
                  >
                    {dailySales
                      ? roundNearest100(dailySales).toLocaleString()
                      : "-"}
                    円
                  </CardValueCell>
                </TableRow>
                <TableRow>
                  <CardKeyCell>PO率</CardKeyCell>
                  <CardValueCell
                    sx={{
                      px: 0.5,
                      ...(isPayoutRateAlert && { td: boldRedStyle }),
                    }}
                  >
                    {dailyPayOut ? `${dailyPayOut.toFixed(1)}%` : "-"}
                  </CardValueCell>
                </TableRow>

                <TableRow>
                  <CardSubtitleCell colSpan={2}>
                    1週間{daysInWeek < 7 ? ` (${daysInWeek}日間)` : ""}
                  </CardSubtitleCell>
                </TableRow>
                <TableRow>
                  <CardKeyCell>売上</CardKeyCell>
                  <CardValueCell sx={{ px: 0.5 }}>
                    {weeklySales
                      ? roundNearest100(weeklySales).toLocaleString()
                      : "-"}
                    円
                  </CardValueCell>
                </TableRow>
                <TableRow>
                  <CardKeyCell>PO率</CardKeyCell>
                  <CardValueCell sx={{ px: 0.5 }}>
                    {weeklyPayOut ? `${weeklyPayOut.toFixed(1)}%` : "-"}
                  </CardValueCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Link>
    </Card>
  )
}
