import { useMediaQuery } from "@mui/material"

import { theme } from "src/theme"

export const useBreakpoints = () => {
  const mobile = theme.breakpoints.down("sm")
  const tablet = theme.breakpoints.between("sm", "md")
  const pc = theme.breakpoints.up("md")
  const isMobile = useMediaQuery(mobile)
  const isTablet = useMediaQuery(tablet)
  const isPc = useMediaQuery(pc)

  return {
    mobile,
    tablet,
    pc,
    isMobile,
    isTablet,
    isPc,
  }
}
