import { useParams } from "react-router-dom"

import { InventoryMaterialStockDetails } from "src/components//templates/InventoryMaterialStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryMaterialPlacementStorageMaterialStock: React.FC = () => {
  const { materialCd, shelfId } = useParams()

  const defaultMaterialPlacementIdMoveFrom = {
    shelfId: Number(shelfId),
  }

  return (
    <MainContentLayout
      title="在庫確認・店内移動"
      renderContent={() => (
        <InventoryMaterialStockDetails
          materialCd={materialCd ?? ""}
          defaultMaterialPlacementIdMoveFrom={
            defaultMaterialPlacementIdMoveFrom
          }
        />
      )}
      backButtonLabel="一覧画面に戻る"
    />
  )
}
