import { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {
  Box,
  Button,
  Container,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material"
import axios from "axios"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"

import { ResetPasswordRequest } from "src/api/models"
import {
  isIncorrectUsernameOrPassword,
  passwordValidationSchema,
  resetPassword,
} from "src/domains/authRepository"
import { useAuth } from "src/hooks/useAuth"

export const NewPassword = () => {
  const { login, logout } = useAuth()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string>()

  const [showNewPass, setShowNewPass] = useState(false)
  const [showCurPass, setShowCurPass] = useState(false)
  const toggleNewPass = () => setShowNewPass((b) => !b)
  const toggleCurPass = () => setShowCurPass((b) => !b)

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("アカウント名の形式が正しくありません")
      .max(255)
      .required("アカウント名は必須です"),
    currentPassword: Yup.string()
      .max(255)
      .required("現在のパスワードは必須です"),
    newPassword: passwordValidationSchema,
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordRequest>({
    resolver: yupResolver(validationSchema),
  })

  const onResetPass: SubmitHandler<ResetPasswordRequest> = async (request) => {
    try {
      const {
        data: { credentials },
      } = await resetPassword(request)
      if (credentials) {
        login(credentials)
        navigate("/")
        return
      }
      logout()
      throw new Error()
    } catch (e) {
      logout()
      if (axios.isAxiosError(e) && isIncorrectUsernameOrPassword(e)) {
        setErrorMessage("アカウント名・現在のパスワードが一致しません")
      } else {
        setErrorMessage("ログインに失敗しました")
      }
    }
  }

  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onResetPass)}>
          <Box sx={{ my: 3, textAlign: "center" }}>
            <h1>新しいパスワードを設定してください</h1>
          </Box>
          <TextField
            error={"email" in errors}
            fullWidth
            helperText={errors.email?.message}
            label="アカウント名"
            margin="normal"
            type="email"
            variant="outlined"
            autoComplete="on"
            {...register("email")}
          />
          <TextField
            error={"currentPassword" in errors}
            fullWidth
            helperText={errors.currentPassword?.message}
            label="現在のパスワード"
            margin="normal"
            type={showCurPass ? "text" : "password"}
            variant="outlined"
            autoComplete="on"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleCurPass}
                    onMouseDown={toggleCurPass}
                    edge="end"
                  >
                    {!showCurPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register("currentPassword")}
          />

          <TextField
            error={"newPassword" in errors}
            fullWidth
            helperText={errors.newPassword?.message}
            label="新しいパスワード"
            margin="normal"
            type={showNewPass ? "text" : "password"}
            variant="outlined"
            autoComplete="on"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleNewPass}
                    onMouseDown={toggleNewPass}
                    edge="end"
                  >
                    {!showNewPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register("newPassword")}
          />
          <Box sx={{ ml: 1 }}>
            <Typography variant="caption" color="text.secondary">
              パスワードには、大文字・小文字・数字・記号を含めてください
            </Typography>
          </Box>
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
            >
              パスワードを設定
            </Button>
          </Box>
        </form>

        <Snackbar
          open={!!errorMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setErrorMessage("")}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Container>
    </Box>
  )
}
