import { useEffect, useMemo, useState } from "react"

import { Typography, Card, Stack, Divider, useMediaQuery } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { Prize, PrizeDeliveryElement } from "src/api/models"
import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { DatePicker } from "src/components/organisms/DatePicker"
import {
  PrizeDeliveriesFilter,
  prizeDeliveriesSearchParamsState,
} from "src/components/organisms/prizes/PrizeDeliveriesFilter"
import { SeeMore } from "src/components/organisms/SeeMore"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  filterFnPrizeDeliveries,
  sortFnPrizeDeliveries,
} from "src/domains/prizes/deliveryRepository"
import { useResource } from "src/hooks/useResource"
import {
  formatApiDate,
  getJpDateLabel,
  getMonthEnd,
  getMonthStart,
  isValidDateLabel,
  shouldDisableMonth,
} from "src/utils"

export interface PrizeDeliverySearchParams {
  prizeName?: Prize["prizeName"]
  ipName?: Prize["ipName"]
}

export const PrizeDeliveries: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [urlSearchParams] = useSearchParams()

  const setMonth = useSetRecoilState(prizeDeliveriesMonthState)
  const setSearchParams = useSetRecoilState(prizeDeliveriesSearchParamsState)

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (initialized) return

    // URLパラメータで開始日、終了日が指定されている場合反映し、着荷日降順で表示する
    const start = urlSearchParams.get("start")
    const end = urlSearchParams.get("end")
    if (start && end && isValidDateLabel(start) && isValidDateLabel(end)) {
      setMonth(formatApiDate(getMonthStart(end)))
      setSearchParams((searchParams) => ({
        ...searchParams,
        arriveAtDateRange: { start, end },
        sortBy: "arriveAtOrderDesc",
      }))
    }
    setInitialized(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchParams])

  if (!initialized) {
    return <LoadingBox />
  }

  return (
    <MainContentLayout
      title="着荷予定景品一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <PrizeDeliveriesMonthSelect />
          <Stack>
            <PrizeDeliveriesFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizeDeliveriesInner />}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}/prizes`)}
    />
  )
}

const prizeDeliveriesMonthState = atom<string>({
  key: "prizeDeliveriesMonthState",
  default: formatApiDate(getMonthStart()),
})

const PrizeDeliveriesMonthSelect: React.FC = () => {
  const [month, setMonth] = useRecoilState(prizeDeliveriesMonthState)
  const setSearchParams = useSetRecoilState(prizeDeliveriesSearchParamsState)

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <DatePicker
        value={month}
        onChange={(v) => {
          setMonth(v)
          setSearchParams((searchParams) => ({
            ...searchParams,
            arriveAtDateRange: {
              start: formatApiDate(getMonthStart(v)),
              end: formatApiDate(getMonthEnd(v)),
            },
          }))
        }}
        format={"YYYY年MM月"}
        views={["year", "month"]}
        openTo="month"
        shouldDisableMonth={shouldDisableMonth}
        hideTodayButton
      />
    </Stack>
  )
}

const PrizeDeliveriesInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const month = useRecoilValue(prizeDeliveriesMonthState)
  const searchParams = useRecoilValue(prizeDeliveriesSearchParamsState)
  const {
    arriveAtDateRange,
    prizeCd,
    prizeName,
    prizeNameKana,
    makerName,
    ipName,
    sortBy,
  } = searchParams

  const to = useMemo(() => formatApiDate(getMonthEnd(dayjs(month))), [month])
  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: month,
            to,
            prizeCd,
            prizeName,
            prizeNameKana,
            ipName,
            makerName,
            searchDateColumn: "arrive_at",
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${month}:${to}:${prizeCd}:${prizeName}:${prizeNameKana}:${ipName}:${makerName}`,
  }).resource
  const prizeDeliveries = prizeDeliveriesReturn?.data.deliveries

  const filteredAndSortedPrizeDeliveries = useMemo(
    () =>
      (prizeDeliveries || [])
        .filter(filterFnPrizeDeliveries({ arriveAtDateRange }))
        .sort(sortFnPrizeDeliveries(sortBy)),
    [prizeDeliveries, arriveAtDateRange, sortBy],
  )

  return (
    <Stack sx={{ pb: 2 }}>
      <SeeMore
        items={filteredAndSortedPrizeDeliveries}
        itemCountPerShow={20}
        renderWrapper={(renderItems) => (
          <Card sx={{ mb: 2 }}>
            <Stack divider={<Divider />}>{renderItems()}</Stack>
          </Card>
        )}
        renderItem={(item) => (
          <PrizeNewArrivalCardInner
            key={item.delivery.id}
            prizeDeliveryElement={item}
          />
        )}
      />
    </Stack>
  )
}

interface PrizeNewArrivalCardInnerProps {
  prizeDeliveryElement: PrizeDeliveryElement
}

const PrizeNewArrivalCardInner: React.FC<PrizeNewArrivalCardInnerProps> = ({
  prizeDeliveryElement,
}) => {
  const { delivery, prize } = prizeDeliveryElement
  const arrivalDateLabel = getJpDateLabel(delivery.arriveAt)
  const machineInputDateLabel = getJpDateLabel(delivery.machineInputDate)
  const { prizeCd, prizeName, ipName, widthCm, depthCm, heightCm } = prize

  const matches = useMediaQuery("(min-width:400px)")

  return (
    <Stack sx={{ p: 2 }}>
      <Stack sx={{ flexDirection: "row", gap: 2, pb: 1 }}>
        <Stack>
          <PrizeImageBox prizeCd={prizeCd} />
        </Stack>
        <Stack sx={{ flexDirection: "column", gap: 0.5 }}>
          <Typography variant="caption">{prizeCd}</Typography>
          <Typography variant="subtitle1">{prizeName}</Typography>
          <Typography variant="caption">
            {ipName} ({widthCm}cm*{depthCm}cm*{heightCm}cm)
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          ...(matches && {
            justifyContent: "flex-start",
            gap: 1,
          }),
        }}
      >
        <Stack sx={{ flexDirection: "row" }}>
          <Stack
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[20],
              width: "60px",
              py: 0.5,
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Typography variant="label">着荷日</Typography>
          </Stack>
          <Stack
            sx={{
              pl: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="label">{arrivalDateLabel}</Typography>
          </Stack>
        </Stack>
        <Stack sx={{ flexDirection: "row" }}>
          <Stack
            sx={(theme) => ({
              backgroundColor: theme.palette.gray[20],
              py: 0.5,
              px: 1,
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Typography variant="label">投入可能日</Typography>
          </Stack>
          <Stack
            sx={{
              pl: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="label">{machineInputDateLabel}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
