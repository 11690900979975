import { useMemo } from "react"

import {
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material"
import { useLocation } from "react-router-dom"

import { PrizeToneBoothInfo, PrizeToneBoothSale } from "src/api/models"
import { getPrizeToneBoothSales } from "src/api/prize-tone-booth-sales"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import {
  calcDailyPayOutStats,
  calcDailySalesStats,
} from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import {
  getYesterday,
  getDaysAgo,
  formatApiDate,
  getJpDateLabel,
  roundNearest100,
} from "src/utils"

interface BoothDetailsSalesModalProps {
  booths: PrizeToneBoothInfo[]
}

interface DailyBoothSales {
  [key: string]: PrizeToneBoothSale[]
}

export const BoothDetailsSalesModal: React.FC<BoothDetailsSalesModalProps> = ({
  booths,
}: BoothDetailsSalesModalProps) => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const date = query.get("date") || getYesterday()
  const allBoothSales =
    useResource({
      subject: "ブース売り上げの取得",
      recoilKey: `getAllBoothSales:${date}`,
      fetch: async () => {
        const allBoothSales: DailyBoothSales = {}
        await Promise.all(
          booths.map(async (booth) => {
            const {
              data: { boothSales },
            } = await getPrizeToneBoothSales(
              booth.arcadeCd,
              booth.machineName,
              booth.boothName,
              {
                from: getDaysAgo(Math.max(0, booth.daysInWeek - 1), date),
                to: date,
              },
            )
            if (boothSales) {
              boothSales.forEach((boothSale) => {
                const { soldOn } = boothSale
                allBoothSales[soldOn] = [
                  ...(allBoothSales[soldOn] ?? []),
                  boothSale,
                ]
              })
            } else {
              throw new Error()
            }
          }),
        )
        return allBoothSales
      },
    }).resource || {}

  const allBoothSelesKeys = Object.keys(allBoothSales).sort()

  return (
    <>
      {allBoothSelesKeys.length === 0 ? (
        <DialogTitle>
          <Typography variant="h1">
            直近１週間のデータは見つかりませんでした
          </Typography>
        </DialogTitle>
      ) : (
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow sx={{ th: { p: 2 } }}>
                <ExtTableCell>日付</ExtTableCell>
                <ExtTableCell>売上</ExtTableCell>
                <ExtTableCell>PO率</ExtTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allBoothSelesKeys.map((key) => {
                const boothSales = allBoothSales[key]
                if (!boothSales || !boothSales[0]) return null

                return (
                  <TableBorderedRow
                    key={boothSales[0].soldOn}
                    sx={{
                      td: {
                        border: "none",
                        whiteSpace: "nowrap",
                        p: 2,
                      },
                    }}
                  >
                    <ExtTableCell>
                      {formatApiDate(getJpDateLabel(boothSales[0].soldOn))}
                    </ExtTableCell>
                    <ExtTableCell>
                      {calcDailySalesStats(boothSales) !== 0
                        ? `${roundNearest100(
                            calcDailySalesStats(boothSales),
                          ).toLocaleString()}円`
                        : "-"}
                    </ExtTableCell>
                    <ExtTableCell>
                      {calcDailyPayOutStats(boothSales) !== 0
                        ? `${calcDailyPayOutStats(boothSales).toFixed(1)}%`
                        : "-"}
                    </ExtTableCell>
                  </TableBorderedRow>
                )
              })}
            </TableBody>
          </Table>
        </DialogContent>
      )}
    </>
  )
}
