import { AmMachine } from "src/api/models"
import { compareByPhoneticOrder } from "src/utils"

export const amMachineFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
} as const

export const sortAmMachines = (
  amMachines: AmMachine[],
  params: { sortBy?: keyof typeof amMachineFilterSortByLabels },
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: AmMachine, b: AmMachine) =>
        compareByPhoneticOrder(a.amMachineName, b.amMachineName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: AmMachine, b: AmMachine) =>
        compareByPhoneticOrder(b.amMachineName, a.amMachineName)
    }
    return () => 1
  }

  return amMachines.sort(getSortCompareBy())
}
