/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type UserRole = (typeof UserRole)[keyof typeof UserRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  Admin: "admin",
  DepartmentLeader: "department_leader",
  AreaLeader: "area_leader",
  PrizeProducer: "prize_producer",
  StoreLeader: "store_leader",
  FloorManager: "floor_manager",
  Chief: "chief",
  Staff: "staff",
  Unknown: "unknown",
} as const
