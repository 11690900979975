export * from "src/utils/datetime"
export * from "src/utils/search"

export const roundNearest100 = (n: number) => {
  return Math.round(n / 100) * 100
}

export const isNumberSet = (value?: number) => !!value || value === 0

export const calcRatio = (n1: number | undefined, n2: number | undefined) =>
  n1 !== undefined && n2 !== undefined && n2 > 0 ? n1 / n2 : undefined

export const round = (n: number | undefined, x: number | undefined = 1) => {
  const e = 10 ** (x - 1)
  return n !== undefined ? Math.round(n * e) / e : undefined
}

export const getRatioLabel = (n: number | undefined): string => {
  // パーセンテージ表記の場合、小数点以下第2位を四捨五入して表示する + 丸め誤差防止
  return n !== undefined && !isNaN(n) ? `${round(n * 100, 2)}%` : "-"
}

export const filterUniqueArray = <T>(
  value: T,
  index: number,
  self: T[],
): boolean => self.indexOf(value) === index

export const downloadFile = ({
  data,
  fileType,
  fileName,
}: {
  data: Uint8Array
  fileType: string
  fileName: string
}) => {
  const blob = new Blob([data], { type: fileType })
  downloadBlobFile({ blob, fileName })
}

export const downloadBlobFile = ({
  blob,
  fileName,
}: {
  blob: Blob
  fileName: string
}) => {
  const a = document.createElement("a")
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  a.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  )
  a.remove()
}

export const convertHyphenatedToSnakeCase = (hyphenatedString: string) =>
  hyphenatedString.replace("-", "_")

export const getBackgroundColors = (shouldFillColors: string[]) => {
  if (shouldFillColors.length === 1) {
    return shouldFillColors[0]
  }
  if (shouldFillColors.length > 0) {
    const percentage = (1 / shouldFillColors.length) * 100
    const linearGradientParam = shouldFillColors
      .map((color, i) => `${color} ${i * percentage}% ${(i + 1) * percentage}%`)
      .join(", ")
    return `linear-gradient(to right, ${linearGradientParam})`
  }
  return "transparent"
}

export const getBaseLog = (x: number, y: number) => Math.log(y) / Math.log(x)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shuffleArray = (array: any[]) => {
  return array.sort(() => 0.5 - Math.random())
}
