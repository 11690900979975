import { forwardRef, useMemo } from "react"

import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"

interface PaginationTableLabelProps {
  from: number
  to: number
  count: number
  offset: number
  limit: number
  handlePageChange: (page: number) => void
}

export const PaginationTableLabel: React.FC<PaginationTableLabelProps> = ({
  from,
  to,
  count,
  offset,
  limit,
  handlePageChange,
}: PaginationTableLabelProps) => {
  const items = useMemo(
    () => [...new Array(Math.ceil(count / limit))].map((_, i) => i),
    [count, limit],
  )
  return (
    <Box sx={{ py: 1, textAlign: "end" }}>
      <Typography sx={{ fontSize: "0.85rem" }}>
        {from}-{to} 件 / {count} 件中
      </Typography>
      <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
        <PaginateAutocomplete
          value={offset}
          items={items}
          // ページ切り替えでAPI呼び出しが走る場合に選択肢が消えない場合があるため
          // 遅延させることでautomatic batchingさせず、API呼び出しを待たずに選択肢を消すようにする
          onChange={(value) => setTimeout(() => handlePageChange(value), 0)}
        />
        <Typography variant="caption">
          / {Math.ceil(count / limit)} ページ
        </Typography>
      </Stack>
    </Box>
  )
}

type PaginateAutocompleteItem = {
  label: string
  value: number
}
type PaginateAutocompleteProps = {
  onChange: (value: number) => void
  value: number
  items: number[]
}

const PaginateAutocomplete = forwardRef<HTMLElement, PaginateAutocompleteProps>(
  ({ onChange, value, items }, ref) => {
    const autocompleteValue = useMemo(() => {
      return {
        label: value.toString(),
        value: value - 1,
      }
    }, [value])
    return (
      <Autocomplete<PaginateAutocompleteItem>
        ref={ref}
        value={autocompleteValue}
        options={items.map((item) => ({
          label: (item + 1).toString(),
          value: item,
        }))}
        renderOption={(props, option) => (
          <WordBreakAllList {...props} key={option.value}>
            {option.label}
          </WordBreakAllList>
        )}
        onChange={(_, option) => option && onChange(Number(option.value))}
        renderInput={(params) => <TextField {...params} />}
        slotProps={{
          clearIndicator: {
            sx: { display: "none" },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiFormControl-root": { minWidth: 80 },
          "& .MuiInputBase-root": {
            minWidth: 80,
            height: 20,
            px: "0!important",
          },
          "& .MuiButtonBase-root": {
            width: 20,
            height: 20,
            color: "gray.80",
          },
          "& input": {
            pr: "28px!important",
            minWidth: 80,
            height: 20,
            fontSize: 12,
            textAlign: "right",
          },
        }}
      />
    )
  },
)

PaginateAutocomplete.displayName = "PaginateAutocomplete"
