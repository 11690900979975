import { Suspense, startTransition, useState } from "react"

import { Box, CircularProgress } from "@mui/material"

import { getPrizeImageUrl } from "src/api/prizes"
import NoImageLarge from "src/assets/no_image_large.svg"
import NoImageSmall from "src/assets/no_image_small.svg"
import { useResource } from "src/hooks/useResource"

interface ImageBoxProps {
  prizeCd: string
  alt?: string
  noImageSize?: "small" | "large"
}

const PrizeImageBoxInner: React.FC<ImageBoxProps> = ({
  prizeCd,
  alt,
  noImageSize = "small",
}: ImageBoxProps) => {
  const imageUrl =
    useResource({
      subject: "景品画像の取得",
      fetch: prizeCd ? () => getPrizeImageUrl(prizeCd) : undefined,
      recoilKey: `getPrizeImageUrl:${prizeCd}`,
      hideErrorMessage: true,
    }).resource?.data.imageUrl || ""

  const maxImageSize = 240
  const [prizeImageUrl, setPrizeImageUrl] = useState(imageUrl)

  const NoImage = noImageSize === "small" ? NoImageSmall : NoImageLarge

  return (
    <Box
      sx={{
        maxWidth: maxImageSize,
        maxHeight: maxImageSize,
        margin: "auto",
      }}
    >
      <img
        src={prizeImageUrl || NoImage}
        alt={alt}
        loading="lazy"
        style={{ width: "100%" }}
        onError={() => startTransition(() => setPrizeImageUrl(""))}
      />
    </Box>
  )
}

export const PrizeImageBox = (props: ImageBoxProps) => {
  return (
    <Box sx={{ margin: "auto" }}>
      <Suspense
        fallback={
          <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        }
      >
        <PrizeImageBoxInner {...props} />
      </Suspense>
    </Box>
  )
}
