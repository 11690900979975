import { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Button,
  Container,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material"
import axios from "axios"
import { SubmitHandler, useForm } from "react-hook-form"
import { useNavigate, useLocation } from "react-router-dom"
import * as Yup from "yup"

import gigoNaviLogoImage from "src/assets/gigo_navi_logo.png"
import { ConfidentialAlert } from "src/components/molecules/ConfidentialAlert"
import {
  isIncorrectUsernameOrPassword,
  postSignIn,
} from "src/domains/authRepository"
import { useAuth } from "src/hooks/useAuth"
import { ChallengeType } from "src/types"

const validationSchema = Yup.object({
  email: Yup.string()
    // @ が含まれない場合 @u1.gigo.co.jp を付与するため email バリデーションを削除
    // .email("アカウント名の形式が正しくありません")
    .max(255)
    .required("アカウント名は必須です"),
  password: Yup.string().max(255).required("パスワードは必須です"),
})

const resolver = yupResolver(validationSchema)

interface LocationState {
  from?: Location
}

interface LoginFormInput {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const { login, logout } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormInput>({ resolver })

  const onLogin: SubmitHandler<LoginFormInput> = async ({
    email,
    password,
  }) => {
    // NOTE: https://github.com/remix-run/history/blob/dev/packages/history/index.ts#L106
    // unknow 返されるから仕方なくas
    const fromLocation = (location.state as LocationState)?.from
    const fromPath = fromLocation?.pathname
    const queryParams = fromLocation?.search || ""
    const toPath = fromPath ? `${fromPath}${queryParams}` : "/my-page"

    try {
      const {
        data: { credentials, challengeName },
      } = await postSignIn({
        email: email.includes("@") ? email : `${email}@u1.gigo.co.jp`,
        password,
      })

      if (credentials) {
        login(credentials)
        navigate(toPath)
        return
      }
      if (challengeName === ChallengeType.NewPasswordRequired) {
        logout()
        setErrorMessage("本パスワードを登録してください")
        navigate("/new_password")
        return
      }
      logout()
      throw new Error()
    } catch (e) {
      logout()
      if (axios.isAxiosError(e) && isIncorrectUsernameOrPassword(e)) {
        setErrorMessage("アカウント名・パスワードが一致しません")
      } else {
        setErrorMessage("ログインに失敗しました")
      }
    }
  }

  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
      }}
    >
      <Container maxWidth="sm">
        <form noValidate onSubmit={handleSubmit(onLogin)}>
          <Box sx={{ my: 3, textAlign: "center" }}>
            <img
              src={gigoNaviLogoImage}
              alt="GiGO NAVI"
              style={{ width: "100%", maxWidth: 400 }}
            />
          </Box>

          {(IS_DEBUG || IS_VADDY || IS_STAGING) && <ConfidentialAlert />}

          <TextField
            error={"email" in errors}
            fullWidth
            helperText={errors.email?.message}
            label="アカウント名"
            margin="normal"
            type="email"
            variant="outlined"
            autoComplete="on"
            {...register("email")}
          />
          <TextField
            error={"password" in errors}
            fullWidth
            helperText={errors.password?.message}
            label="パスワード"
            margin="normal"
            type="password"
            variant="outlined"
            autoComplete="on"
            {...register("password")}
          />
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
            >
              ログイン
            </Button>
          </Box>
        </form>

        <Snackbar
          open={!!errorMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => setErrorMessage("")}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      </Container>
    </Box>
  )
}
