import {
  PrizeFloorMapPoint,
  PrizeBoothShelf,
  PrizeOnBoothShelf,
  PrizeFloorMemoColor,
  PrizeInventoryGroup,
} from "src/api/models"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"

export type PrizeInventoryFloorMapPoint = BaseFloorMapPoint & {
  boothShelf: PrizeBoothShelf
  onBoothShelf: PrizeOnBoothShelf
}

export const convertToFloorMapPoint = (
  point: PrizeFloorMapPoint,
): PrizeInventoryFloorMapPoint => ({
  ...point,
  amMachineNumber: point["amMachineNo"] || "",
})

// NOTE: フロアメモ色を、棚卸フロアマップ色にも転用
export type InventoryFloorMapColor = PrizeFloorMemoColor

export const getGroupInitialName = (groupName: string) =>
  // NOTE: 文言「棚卸グループ」は手動で除去
  groupName.replace("棚卸", "").replace("グループ", "")[0]

export const getInventoryGroupByPlacementType = (
  point: PrizeInventoryFloorMapPoint | PrizeFloorMapPoint,
  placementType: Exclude<PlacementType, "storage">,
) => {
  const inventoryGroup =
    placementType === PlacementType.InBooth
      ? point?.boothShelf.inventoryGroup
      : point?.onBoothShelf.inventoryGroup
  return inventoryGroup
}

const getInventoryGroupIdsByPlacementType = (
  floorMapPoints: PrizeFloorMapPoint[],
  placementType: Exclude<PlacementType, "storage">,
) =>
  floorMapPoints?.reduce((ids: PrizeInventoryGroup["id"][], point) => {
    const { id } = getInventoryGroupByPlacementType(point, placementType) ?? {}
    if (id && !ids.includes(id)) {
      return [...ids, id]
    }
    return ids
  }, [])

export const getInventoryGroupColorByPlacementType = (props: {
  floorMapPoints: PrizeFloorMapPoint[]
  floorMapColors?: PrizeFloorMemoColor[]
  groupId: number
  placementType: Exclude<PlacementType, "storage">
}) => {
  // NOTE: 棚卸ツール以外と共用の FloorMapPoint 型は棚情報を持っていないので、state から取得
  const { floorMapPoints, floorMapColors, groupId, placementType } = props
  const index = getInventoryGroupIdsByPlacementType(
    floorMapPoints,
    placementType,
  ).findIndex((n) => n === groupId)

  if (index === -1 || !floorMapColors || floorMapColors.length === 0) return

  const colorIndex = index % floorMapColors.length
  return floorMapColors[colorIndex]?.hexColorCode
}

export const isFloorMapPointAvailable = (
  isInBooth: boolean,
  point: PrizeFloorMapPoint,
) => {
  return isInBooth
    ? point?.boothShelf.isAvailable
    : point?.onBoothShelf.isAvailable
}
