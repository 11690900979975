import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { getMaterialStorage } from "src/api/material-storages"
import { MaterialOperationShelfStock, Material } from "src/api/models"
import {
  InventoryMaterialPlacementMaterialsFilter,
  inventoryPlacementMaterialsSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsFilter"
import { InventoryMaterialPlacementMaterialsMenu } from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortPlacementStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"

export const InventoryMaterialPlacementStorageMaterials = () => {
  const { arcadeCd, storageId, shelfId } = useParams()
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
  })
  const { storage, shelf } = useMemo(() => {
    const { storage, shelves } = resource?.data || {}
    return {
      storage,
      shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
    }
  }, [resource, shelfId])

  return (
    <MainContentLayout
      title={
        storage && shelf ? storage.name + " " + shelf.name + " の材料一覧" : ""
      }
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialPlacementMaterialsFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialPlacementStorageMaterialsMenu />}
    />
  )
}

const InventoryMaterialPlacementStorageMaterialsMenu = () => {
  return <InventoryMaterialPlacementStorageMaterialsTable />
}

const InventoryMaterialPlacementStorageMaterialsTable = () => {
  const { arcadeCd, shelfId } = useParams()
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)
  const searchParams = useRecoilValue(
    inventoryPlacementMaterialsSearchParamsState,
  )
  const { resource: materialStocksResource } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && shelfId
        ? () =>
            getMaterialOperationStocks(arcadeCd, {
              materialName: searchParams.materialName,
              materialNameKana: searchParams.materialNameKana,
              materialCd: searchParams.materialCd,
              shelfIds: [Number(shelfId)],
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${shelfId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks: (MaterialOperationShelfStock & {
    material: Material
  })[] = useMemo(() => {
    const stocks = materialStocksResource?.data.stocks || []
    return sortPlacementStocks(
      stocks.flatMap((s) =>
        s.shelfStocks.map((shelfStock) => ({
          ...shelfStock,
          material: s.material,
        })),
      ),
      searchParams,
    )
  }, [materialStocksResource, searchParams])

  const menuItems = useMemo(
    () =>
      filteredStocks
        ?.filter((shelfStock) =>
          hideEmptyMaterial ? shelfStock.shelfStock.stock > 0 : true,
        )
        .map((shelfStock) => {
          const {
            shelfStock: { materialCd, stock },
            material: { materialName },
          } = shelfStock
          return {
            material: {
              materialCd,
              materialName,
            },
            stock,
          }
        }) ?? [],
    [filteredStocks, hideEmptyMaterial],
  )

  return <InventoryMaterialPlacementMaterialsMenu menuItems={menuItems} />
}
