import { useMemo } from "react"

import { PrizeStorageElement } from "src/api/models"

export const useShelfGroupNames = (storages: PrizeStorageElement[]) => {
  const groupNames = useMemo(() => {
    const namesSet = new Set<string>()
    storages.forEach(({ shelves }) =>
      shelves?.forEach(
        (shelf) =>
          shelf.inventoryGroup && namesSet.add(shelf.inventoryGroup.groupName),
      ),
    )
    return Array.from(namesSet)
  }, [storages])
  return groupNames
}
