import { useState, useMemo, useCallback } from "react"

import { Typography, Card, List, Stack } from "@mui/material"
import { AxiosResponse } from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import {
  getMaterialInventoryPlacementTypeStatus,
  putMaterialInventoryPlacementTypeStatus,
} from "src/api/material-inventory-placement-type-status"
import { getMaterialStorages } from "src/api/material-storages"
import {
  GetMaterialInventoryPlacementStatusesResponse,
  GetMaterialInventoryPlacementTypeStatusResponse,
  GetMaterialStoragesResponse,
  GetMaterialInventoryPlacementTypeStatusResponseStatusEnum,
  PutMaterialInventoryPlacementTypeStatusRequestStatusEnum,
} from "src/api/models"
import { MenuButton } from "src/components/atoms/MenuButton"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import {
  InventoryMaterialExecutePlacementListItem,
  InventoryMaterialExecuteList,
} from "src/components/organisms/materials/InventoryMaterialExecuteList"
import { InventoryMaterialLockModal } from "src/components/organisms/materials/InventoryMaterialLockModal"
import { InventoryMaterialRemoveLockModal } from "src/components/organisms/materials/InventoryMaterialRemoveLockModal"
import { InventoryMaterialStatus } from "src/components/organisms/materials/InventoryMaterialStatus"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useMaterialShelfGroupNames } from "src/hooks/useMaterialStorage"
import { useResource, UseResourceReturn } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentInventoryPeriodState,
  snackbarErrorMessageState,
} from "src/recoil"
import { getToday } from "src/utils"

export const InventoryMaterialExecuteStorages = () => {
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  if (currentMaterialExecutionPeriod) {
    return (
      <MainContentLayout
        title="在庫カウント(保管場所)"
        renderContent={() => <InventoryMaterialExecuteStoragesMenu />}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="在庫カウント(保管場所)"
      period={currentInventoryPeriod}
      featurePeriod={currentMaterialExecutionPeriod}
    />
  )
}

const InventoryMaterialExecuteStoragesMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const placementStatusesReturn = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? () =>
            getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementType.Storage,
              currentMaterialExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${
      MaterialPlacementType.Storage
    }:${JSON.stringify(currentMaterialExecutionPeriod)}`,
  })

  const placementTypeStatusReturn = useResource({
    subject: "ロック状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? () =>
            getMaterialInventoryPlacementTypeStatus(
              arcadeCd,
              MaterialPlacementType.Storage,
              currentMaterialExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getMaterialInventoryPlacementTypeStatus:${arcadeCd}:${
      MaterialPlacementType.Storage
    }:${JSON.stringify(currentMaterialExecutionPeriod)}`,
  })

  const storagesReturn = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorages:${arcadeCd}`,
  })

  return (
    <InventoryMaterialExecuteStoragesMenuTemplate
      {...{
        placementStatusesReturn,
        placementTypeStatusReturn,
        storagesReturn,
      }}
    />
  )
}

interface InventoryExecuteStoragesMenuTemplateProps {
  placementStatusesReturn?: UseResourceReturn<
    AxiosResponse<GetMaterialInventoryPlacementStatusesResponse>
  >
  placementTypeStatusReturn?: UseResourceReturn<
    AxiosResponse<GetMaterialInventoryPlacementTypeStatusResponse>
  >
  storagesReturn?: UseResourceReturn<AxiosResponse<GetMaterialStoragesResponse>>
}

const InventoryMaterialExecuteStoragesMenuTemplate: React.FC<
  InventoryExecuteStoragesMenuTemplateProps
> = ({
  placementStatusesReturn,
  placementTypeStatusReturn,
  storagesReturn,
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const [showLockModal, setLockModal] = useState(false)

  const { counts, shelfStatuses: placementStatuses } =
    placementStatusesReturn?.resource?.data || {}

  const placementTypeStatus = placementTypeStatusReturn?.resource?.data.status
  const isLocked = useMemo(
    () =>
      placementTypeStatus ===
      GetMaterialInventoryPlacementTypeStatusResponseStatusEnum.Locked,
    [placementTypeStatus],
  )

  const storages = useMemo(
    () => storagesReturn?.resource?.data.storages || [],
    [storagesReturn],
  )
  const shelfGroupNames = useMaterialShelfGroupNames(storages)

  const { isAvailableInventoryLock } = useUserRole()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const placementListItems = useMemo(() => {
    const result: InventoryMaterialExecutePlacementListItem<"storage">[] = []
    storages.forEach(({ storage, shelves }) =>
      shelves?.forEach((shelf) => {
        const placementStatus = (placementStatuses || []).find(
          (placementStatus) => placementStatus.placementId === shelf.id,
        )
        result.push({
          storageId: storage.id,
          shelfId: shelf.id,
          name: `${storage.name} ${shelf.name}`,
          parentName: storage.name,
          isAvailable: shelf.isAvailable,
          groupName: shelf.inventoryGroup?.groupName,
          executionStatus: placementStatus?.executionStatus,
          executedAt: placementStatus?.executedAt,
        })
      }),
    )
    return result
  }, [storages, placementStatuses])

  const refetch = useCallback(() => {
    placementStatusesReturn?.refetch()
    placementTypeStatusReturn?.refetch()
    storagesReturn?.refetch()
  }, [placementStatusesReturn, placementTypeStatusReturn, storagesReturn])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const callPutPlacemenTypeStatus = useCallback(
    (status: PutMaterialInventoryPlacementTypeStatusRequestStatusEnum) => {
      arcadeCd &&
        submitPromises([
          {
            subject: "ロック状況の更新",
            showSuccessMessage: true,
            promise: async () => {
              await putMaterialInventoryPlacementTypeStatus(
                arcadeCd,
                MaterialPlacementType.Storage,
                {
                  date: getToday(),
                  status,
                },
              )
              refetch()
              setLockModal(false)
            },
          },
        ])
    },
    [arcadeCd, submitPromises, refetch],
  )

  const handleOnClick = useCallback(
    ({
      storageId,
      shelfId,
      isAvailable,
    }: InventoryMaterialExecutePlacementListItem<"storage">) => {
      if (!isAvailable) return

      navigate(
        `/arcades/${arcadeCd}/inventory/materials/execute/storage/${storageId}/${shelfId}`,
      )
    },
    [arcadeCd, navigate],
  )

  const onClickLockButton = () => {
    if (!isAvailableInventoryLock) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!isLocked && counts && counts.notExecuted > 0) {
      return setErrorMessage("未対応箇所があるため、ロックできません")
    }
    setLockModal(true)
  }

  return (
    <>
      {placementTypeStatus && isLocked && (
        <InventoryMaterialRemoveLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutMaterialInventoryPlacementTypeStatusRequestStatusEnum.Open,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      {placementTypeStatus && !isLocked && (
        <InventoryMaterialLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutMaterialInventoryPlacementTypeStatusRequestStatusEnum.Locked,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      <Stack gap={2}>
        <InventoryMaterialStatus
          counts={counts}
          type={MaterialPlacementType.Storage}
        />

        <Card>
          <List sx={{ width: "100%", py: 3 }}>
            <MenuButton onClick={() => onClickLockButton()}>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Typography>ロック機能</Typography>
                {placementTypeStatus && (
                  <Typography color="text.primary">
                    {isLocked ? "ロック中" : "解除中"}
                  </Typography>
                )}
              </Stack>
            </MenuButton>
          </List>
        </Card>

        <InventoryMaterialExecuteList
          placement={MaterialPlacementType.Storage}
          groupNames={shelfGroupNames}
          listItems={placementListItems}
          handleOnClick={handleOnClick}
        />
      </Stack>
    </>
  )
}
