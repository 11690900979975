import { Box } from "@mui/material"
import { useForm } from "react-hook-form"

import { SearchBar } from "src/components/molecules/SearchBar"

interface SearchBarFormProps {
  onSearch: (inputValue: string) => void
  placeholder?: string
  defaultValue?: string
  showDeleteButton?: boolean
}

interface SearchBarInput {
  inputValue: string
}

export const SearchBarForm: React.FC<SearchBarFormProps> = ({
  onSearch,
  ...restProps
}) => {
  const { register, handleSubmit, reset } = useForm<SearchBarInput>()

  const onSubmit = (inputValue: string) => {
    onSearch(inputValue)
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data) => onSubmit(data.inputValue))}
    >
      <SearchBar
        {...restProps}
        {...register("inputValue")}
        onClickDeleteButton={() => reset()}
      />
    </Box>
  )
}
