import { Suspense, useMemo } from "react"

import {
  Box,
  Link,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material"
import {
  useLocation,
  useParams,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom"
import { useRecoilValue } from "recoil"

import { Prize } from "src/api/models"
import { getPrizeToneStocks } from "src/api/prize-tone-stocks"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { filterStocks, StockFilter } from "src/components/organisms/StockFilter"
import { useResource } from "src/hooks/useResource"
import { stockSearchParamsState } from "src/recoil"

export type StockSearchParams = {
  prizeCd?: Prize["prizeCd"]
  prizeName?: Prize["prizeName"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
  sortBy?:
    | ""
    | "publishedAtNewer"
    | "publishedAtOlder"
    | "phoneticOrderAsc"
    | "phoneticOrderDesc"
}

export const Stocks: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h1">過去取扱景品一覧</Typography>

          <Box sx={{ m: 1, mb: 2 }}>
            <StockFilter />
          </Box>

          <Suspense
            fallback={
              <CircularProgress sx={{ display: "block", margin: "auto" }} />
            }
          >
            <StocksTable />
          </Suspense>

          <Box pt={2}>
            <BackButton onClick={() => navigate(`/arcades/${arcadeCd}/prizes`)}>
              戻る
            </BackButton>
          </Box>
        </Container>
      </Box>
    </>
  )
}

const StocksTable: React.FC = () => {
  const { pathname } = useLocation()
  const { arcadeCd } = useParams()
  const stockSearchParams = useRecoilValue(stockSearchParamsState)

  const stocks = useResource({
    subject: "在庫情報の取得",
    fetch: arcadeCd ? () => getPrizeToneStocks(arcadeCd) : undefined,
    recoilKey: `getPrizeChanges:${arcadeCd}`,
  }).resource?.data.stocks

  const filteredStocks = useMemo(
    () => filterStocks(stocks ?? [], stockSearchParams),
    [stockSearchParams, stocks],
  )

  return (
    <PaginatedTable
      items={filteredStocks}
      renderRow={(stock) => (
        <TableBorderedRow key={stock.prize.prizeCd}>
          <ExtTableCell sx={{ p: 0 }}>
            <Link
              to={`${pathname}/${encodeURIComponent(stock.prize.prizeCd)}`}
              component={RouterLink}
              underline="none"
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" color="primary" pb={1}>
                  {stock.prize.prizeName}
                </Typography>
                <Typography variant="caption" color="gray.90">
                  {stock.prize.prizeCd}
                </Typography>
              </Box>
            </Link>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
