import { useMemo } from "react"

import { useLocation, useParams } from "react-router-dom"

import { InventoryPrizeStockDetails } from "src/components//templates/InventoryPrizeStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PlacementType,
  convertPrizePlacementTypeToURLPath,
} from "src/domains/prizes/placementStatusRepository"

export const InventoryPrizePlacementBoothPrizeStock: React.FC = () => {
  const { pathname, search } = useLocation()
  const { prizeCd } = useParams()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const boothShelfId = query.get("boothShelfId") || undefined
  const onBoothShelfId = query.get("onBoothShelfId") || undefined

  const { InBooth } = PlacementType
  const isInBooth = pathname
    .split("/")
    .includes(convertPrizePlacementTypeToURLPath(InBooth))

  const defaultPlacementIdMoveFrom = useMemo(() => {
    if (!boothShelfId && !onBoothShelfId) {
      return {}
    }
    return isInBooth
      ? {
          boothShelfId: Number(boothShelfId),
        }
      : {
          onBoothShelfId: Number(onBoothShelfId),
        }
  }, [isInBooth, boothShelfId, onBoothShelfId])

  return (
    <MainContentLayout
      title="景品在庫"
      renderContent={() => (
        <InventoryPrizeStockDetails
          prizeCd={prizeCd ?? ""}
          isPlacementPrize
          defaultPlacementIdMoveFrom={defaultPlacementIdMoveFrom}
        />
      )}
      disableBackButton
    />
  )
}
