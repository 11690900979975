/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialMachineResponse,
  GetMaterialMachinesParams,
  GetMaterialMachinesResponse,
  PatchMaterialMachineRequest,
  PutMaterialMachinesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】材料機械リストを取得
 */
export const getMaterialMachines = (
  arcadeCd: string,
  params?: GetMaterialMachinesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialMachinesResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/materialMachines`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】材料機械の情報を更新 (複数)
 */
export const putMaterialMachines = (
  arcadeCd: string,
  putMaterialMachinesRequest: PutMaterialMachinesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/materialMachines`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialMachinesRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】材料機械の削除
 */
export const deleteMaterialMachine = (
  arcadeCd: string,
  materialMachineId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/materialMachines/${materialMachineId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary 【材料】材料機械の詳細を取得
 */
export const getMaterialMachine = (
  arcadeCd: string,
  materialMachineId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialMachineResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/materialMachines/${materialMachineId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 【材料】材料機械の情報を更新 (単一)
 */
export const patchMaterialMachines = (
  arcadeCd: string,
  materialMachineId: number,
  patchMaterialMachineRequest: PatchMaterialMachineRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/materialMachines/${materialMachineId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialMachineRequest,
    },
    options,
  )
}
export type GetMaterialMachinesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialMachines>>
>
export type PutMaterialMachinesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialMachines>>
>
export type DeleteMaterialMachineResult = NonNullable<
  Awaited<ReturnType<typeof deleteMaterialMachine>>
>
export type GetMaterialMachineResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialMachine>>
>
export type PatchMaterialMachinesResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialMachines>>
>
