import { useState } from "react"

import { Grid, Button } from "@mui/material"
import {
  DateView,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers"
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import { useRecoilState } from "recoil"

import { datePickerDateLabelStateSelector } from "src/recoil"
import { formatApiDate, getToday } from "src/utils"

export interface DatePickerProps<T extends string> {
  hideTodayButton?: boolean
  value?: T
  label?: string
  format?: string
  minDate?: dayjs.Dayjs
  views?: DateView[]
  openTo?: DateView
  onChange?: (value: T) => void
  shouldDisableMonth?: (month: dayjs.Dayjs) => boolean
  shouldDisableDate?: (day: dayjs.Dayjs) => boolean
}

export const DatePicker = <T extends string>({
  hideTodayButton = false,
  value,
  label,
  format = "YYYY/MM/DD(ddd)",
  minDate,
  views = ["year", "month", "day"],
  openTo = "day",
  onChange,
  shouldDisableMonth,
  shouldDisableDate,
}: DatePickerProps<T>) => {
  const [datePickerDateLabel, setDatePickerDateLabel] = useRecoilState(
    datePickerDateLabelStateSelector,
  )
  const dayJsDefaultDate = dayjs(value ?? datePickerDateLabel)

  const [dayJsDate, setDayJsDate] = useState(dayJsDefaultDate)

  const onChangeDayJsDate = (date: dayjs.Dayjs) => {
    setDayJsDate(date)
    setDatePickerDateLabel(formatApiDate(date))
    onChange && onChange(formatApiDate(date) as T)
  }

  const setToday = () => onChangeDayJsDate(dayjs(getToday()))

  // TODO: inputのアイコンを先頭に持って来る
  // https://github.com/mui/mui-x/issues/9285
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid item xs={!hideTodayButton ? 8 : 12}>
        <LocalizationProvider adapterLocale={"ja"} dateAdapter={DateAdapter}>
          <DesktopDatePicker
            value={dayJsDate}
            label={label}
            format={format}
            minDate={minDate}
            onChange={(date) => date && onChangeDayJsDate(date)}
            views={views}
            openTo={openTo}
            shouldDisableMonth={shouldDisableMonth}
            shouldDisableDate={shouldDisableDate}
            slotProps={{
              textField: {
                inputProps: {
                  "aria-label": "datepicker",
                },
              },
            }}
            sx={{ display: "flex" }}
          />
        </LocalizationProvider>
      </Grid>
      {!hideTodayButton ? (
        <>
          <Grid item xs={0.5} />
          <Grid item xs={3.5}>
            <Button
              variant="text"
              onClick={() => setToday()}
              fullWidth
              sx={{ px: 0.5 }}
            >
              今日
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  )
}
