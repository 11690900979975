import { Typography, Card, Table, TableBody } from "@mui/material"
import { useParams } from "react-router-dom"

import { getMaterialArrivals } from "src/api/material-arrivals"
import {
  CardKeyCell,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"

export const InventoryMaterialDetails: React.FC = () => {
  return (
    <MainContentLayout
      title="入庫検収・材料詳細"
      renderContent={() => <InventoryMaterialDetailsCard />}
    />
  )
}

const InventoryMaterialDetailsCard = () => {
  const { arcadeCd, materialCd, date } = useParams()

  const { resource } = useResource({
    subject: "詳細情報の取得",
    fetch:
      arcadeCd && date && materialCd
        ? () =>
            getMaterialArrivals(arcadeCd, { from: date, to: date, materialCd })
        : undefined,
    recoilKey: `getMaterialArrivals:${arcadeCd}:${date}:${materialCd}`,
  })
  const arrival = resource?.data.arrivals[0]

  if (!arrival) return <></>

  const {
    arrival: { orderCount },
    material,
  } = arrival
  const { materialName, unitPerCarton, makerName, ipName, unitPriceJpy } =
    material

  const listItems = [
    {
      key: "材料CD",
      value: material.materialCd,
    },
    {
      key: "材料名",
      value: materialName,
    },
    {
      key: "着荷予定数",
      value: orderCount.toLocaleString(),
    },
    {
      key: "カートン入数",
      value: unitPerCarton.toLocaleString(),
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "単価",
      value: `${unitPriceJpy?.toLocaleString()}円`,
    },
  ].filter((item) => !!item)

  return (
    <Card>
      <Table>
        <TableBody>
          {listItems.map(
            (item) =>
              item &&
              item.value && (
                <TableBorderedRow key={item.key}>
                  <CardKeyCell>
                    <Typography variant="body2">{item.key}</Typography>
                  </CardKeyCell>
                  <CardValueCell>
                    <Typography variant="body2"> {item.value}</Typography>
                  </CardValueCell>
                </TableBorderedRow>
              ),
          )}
        </TableBody>
      </Table>
    </Card>
  )
}
