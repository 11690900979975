import { TextField } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"

export const inventoryPrizeSearchParamsState = atom<InventoryPrizeSearchParams>(
  {
    key: "inventoryPrizeSearchParamsState",
    default: {},
  },
)

export type InventoryPrizeSearchParams = {
  prizeCd?: Prize["prizeCd"]
  prizeNameKana?: string
  prizeName?: Prize["prizeName"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

export const InventoryPrizeFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryPrizeSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={true}
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => {
            return (
              <TextField
                {...field}
                error={!!error}
                inputProps={{ "aria-label": "prizeCd" }}
              />
            )
          },
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeName" }}
            />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeNameKana" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "makerName" }}
            />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "ipName" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
      ]}
    />
  )
}
