import { Suspense } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  FormHelperText,
  Card,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { getAmMachine, patchAmMachine } from "src/api/am-machines"
import {
  PatchAmMachineRequest,
  AmMachineUsageCategoryEnum,
  PatchAmMachineRequestUsageCategoryEnum,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { LabeledAutocomplete } from "src/components/molecules/LabeledAutocomplete"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

type FormType = Omit<PatchAmMachineRequest, "numOfCylinders" | "note"> & {
  numOfCylinders?: string
  note?: string
}

export const InventoryMaterialAmMachineDetails = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Card>
          <Box p={2}>
            <Suspense
              fallback={
                <CircularProgress sx={{ display: "block", margin: "auto" }} />
              }
            >
              <InventoryMaterialAmMachineDetailsForm />
            </Suspense>
          </Box>
        </Card>
      </Container>
    </Box>
  )
}

const InventoryMaterialAmMachineDetailsForm = () => {
  const { arcadeCd, amMachineNumber } = useParams()
  const { isEditableAmMachine } = useUserRole()

  const {
    amMachineName,
    representativeHardItemCd,
    isAvailable,
    usageCategory,
    materialSetting,
    note,
  } =
    useResource({
      subject: "AM機器詳細の取得",
      fetch:
        arcadeCd && amMachineNumber
          ? () => getAmMachine(arcadeCd, amMachineNumber)
          : undefined,
      recoilKey: `getAmMachine:${amMachineNumber}`,
    }).resource?.data.amMachine ?? {}
  const { numOfCylinders, isGeneric } = materialSetting || {}

  const validationSchema = Yup.object({
    usageCategory: Yup.string<AmMachineUsageCategoryEnum>()
      .required("利用区分を選択してください")
      .test({
        name: "should-be-zero-cylinder-when-other",
        exclusive: true,
        message:
          "材料以外の区分ではシリンダー個数を0個以上にできません。既にシリンダーが存在する材料区分のAM機器の区分を変更したい場合は、先に該当の材料機械の個別詳細ページから削除を行ってください。",
        test: (value, context) =>
          value !== AmMachineUsageCategoryEnum.Material
            ? Number(context.parent.numOfCylinders) === 0
            : true,
      }),
    numOfBooths: Yup.number(),
    numOfCylinders: Yup.string().when("usageCategory", ([usageCategory]) =>
      usageCategory === AmMachineUsageCategoryEnum.Material
        ? Yup.string()
            .required("シリンダー個数は空欄では登録できません")
            .test(
              "number-cast-possible",
              "数値を入力してください",
              (value) => !isNaN(Number(value)),
            )
            .test("min-1", "シリンダー個数は1個以上にして下さい", (value) =>
              value ? Number(value) > 0 : true,
            )
            .test(
              "max-30",
              "上限個数を超えているため変更できません(上限：30個)",
              (value) => (value ? Number(value) <= 30 : true),
            )
            .test(
              "should-not-be-less-than-current-count",
              "シリンダー個数は既存の個数よりも少ない数を登録することは出来ません。シリンダーを削除したい場合は、該当の材料機械の個別詳細ページから削除を行ってください。",
              (value) =>
                value && numOfCylinders
                  ? Number(value) >= numOfCylinders
                  : true,
            )
        : Yup.string().notRequired(),
    ),
    note: Yup.string().max(
      100,
      "文字数上限を超えているため変更できません(上限：全角100文字)",
    ),
  })

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    defaultValues: {
      numOfCylinders: numOfCylinders?.toString() ?? "",
      usageCategory,
      note,
    },
    resolver: yupResolver(validationSchema),
  })

  const selectedAmMachineUsageCategory = useWatch({
    control,
    name: "usageCategory",
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    usageCategory,
    numOfCylinders,
    note,
  }) => {
    if (!arcadeCd || !amMachineNumber || !isEditableAmMachine) return
    const request: PatchAmMachineRequest = {
      usageCategory,
      numOfCylinders:
        usageCategory === AmMachineUsageCategoryEnum.Material
          ? Number(numOfCylinders)
          : undefined,
      note: note ?? "",
    }
    await submitPromises([
      {
        subject: "AM機器詳細の変更",
        showSuccessMessage: true,
        promise: async () => {
          await patchAmMachine(arcadeCd, amMachineNumber, request)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ mb: 3 }} variant="h1">
        {amMachineNumber}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            label="AM機器名称"
            fullWidth
            value={amMachineName}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            label="設置部門CD"
            fullWidth
            value={representativeHardItemCd}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            label="AM機器有効/無効フラグ"
            fullWidth
            value={isAvailable ? "有効" : "無効"}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name={"usageCategory"}
            render={({ field, fieldState: { error } }) => (
              <LabeledAutocomplete
                {...field}
                error={!!error}
                items={[
                  {
                    label: "材料",
                    value: PatchAmMachineRequestUsageCategoryEnum.Material,
                  },
                  {
                    label: "その他",
                    value: PatchAmMachineRequestUsageCategoryEnum.Other,
                  },
                ]}
                inputLabel={"利用区分"}
                disabled={isGeneric}
              />
            )}
          />
          {errors.usageCategory?.message && (
            <FormHelperText>{errors.usageCategory.message}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name={"numOfCylinders"}
            render={({ field }) => (
              <TextField
                label="シリンダー個数"
                fullWidth
                error={!!errors.numOfCylinders}
                {...field}
                type="number"
                disabled={
                  isGeneric ||
                  selectedAmMachineUsageCategory !==
                    AmMachineUsageCategoryEnum.Material
                }
              />
            )}
          />
          {errors.numOfCylinders?.message && (
            <FormHelperText>{errors.numOfCylinders.message}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name={"note"}
            render={({ field }) => (
              <TextField
                label="備考"
                fullWidth
                error={!!errors.note}
                {...field}
                disabled={isGeneric}
              />
            )}
          />
          {errors.note?.message && (
            <FormHelperText>{errors.note.message}</FormHelperText>
          )}
        </Grid>

        {!isGeneric && (
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={!isEditableAmMachine}
              loading={isSubmitting}
            >
              変更した内容を反映させる
            </LoadingButton>
          </Grid>
        )}

        <Grid item xs={12}>
          <BackButton>戻る</BackButton>
        </Grid>
      </Grid>
    </form>
  )
}
