import { atom, DefaultValue, selector } from "recoil"

import {
  Arcade,
  ArcadeAlertDefaultValue,
  ExecutionPeriod,
  ArcadeAlertDefaultValueConjunctionEnum,
  ArcadeAlertDefaultValuePayoutRateRangeEnum,
  ArcadeAlertDefaultValueSalesRangeEnum,
} from "src/api/models"
import { DateRangePickerDateLabel } from "src/components/atoms/DateRangePicker"
import { AlertParams } from "src/components/organisms/AlertFormModal"
import {
  FloorMapPointInfoBoxSize,
  floorMapPointInfoBoxThreshold,
} from "src/components/organisms/FloorMapBox"
import { BoothSearchParams } from "src/components/templates/BoothsSearchTab"
import { findCurrentInventoryPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { FloorMapScrollPosition } from "src/hooks/useFloorMap"
import { AmMachinesSearchParams } from "src/pages/AmMachines"
import { PrizeNewArrivalSearchParams } from "src/pages/prizes/register/PrizeNewArrivals"
import { StockSearchParams } from "src/pages/prizes/search/Stocks"
import { DateLabelString } from "src/types"
import { getDaysAgo, getToday, getYesterday, isValidDateLabel } from "src/utils"

export const currentArcadeState = atom<Arcade | undefined>({
  key: "currentArcadeState",
  default: undefined,
})

export const inventoryPeriodsState = selector({
  key: "inventoryPeriodsState",
  get: ({ get }): ExecutionPeriod[] | undefined =>
    get(currentArcadeState)?.executionPeriods,
})

export const currentInventoryPeriodState = selector({
  key: "currentInventoryPeriodState",
  get: ({ get }): ExecutionPeriod | undefined => {
    const periods = get(inventoryPeriodsState)
    if (!periods) return

    const {
      id,
      startAt,
      endAt,
      prizeExecutionPeriods,
      materialExecutionPeriods,
      status,
    } = findCurrentInventoryPeriod(periods) || {}
    if (!id) return
    if (!startAt) return
    if (!status) return

    return {
      id,
      startAt,
      // NOTE: endAt が未定 (NULL) の棚卸期間の場合、今日を仮の endAt として扱う
      endAt: endAt || getToday(),
      prizeExecutionPeriods: prizeExecutionPeriods || [],
      materialExecutionPeriods: materialExecutionPeriods || [],
      status,
    }
  },
})

export const datePickerDateLabelState = atom<string>({
  key: "datePickerDateLabelState",
  default: getToday(), // NOTE: アラート以外の DatePicker 日付
})

export const datePickerDateLabelStateSelector = selector<DateLabelString>({
  key: "datePickerDateLabelStateSelector",
  get: ({ get }) => get(datePickerDateLabelState),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return set(datePickerDateLabelState, newValue)
    }
    if (isValidDateLabel(newValue)) {
      return set(datePickerDateLabelState, newValue)
    }
    set(datePickerDateLabelState, (v) => v)
  },
})

export const monthPickerDateLabelState = atom<string>({
  key: "monthPickerDateLabelState",
  default: getToday(),
})

export const monthPickerDateLabelStateSelector = selector<DateLabelString>({
  key: "monthPickerDateLabelStateSelector",
  get: ({ get }) => get(monthPickerDateLabelState),
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return set(monthPickerDateLabelState, newValue)
    }
    if (isValidDateLabel(newValue)) {
      return set(monthPickerDateLabelState, newValue)
    }
    set(monthPickerDateLabelState, (v) => v)
  },
})

export const defaultDateRangePickerDateLabelState =
  atom<DateRangePickerDateLabel>({
    key: "defaultDateRangePickerDateLabelState",
    default: {
      start: getDaysAgo(7),
      end: getToday(),
    },
  })

export const defaultDateRangePickerDateLabelStateSelector =
  selector<DateRangePickerDateLabel>({
    key: "defaultDateRangePickerDateLabelStateSelector",
    get: ({ get }) => get(defaultDateRangePickerDateLabelState),
    set: ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        return set(defaultDateRangePickerDateLabelState, newValue)
      }
      if (isValidDateLabel(newValue.start) && isValidDateLabel(newValue.end)) {
        return set(defaultDateRangePickerDateLabelState, newValue)
      }
      set(defaultDateRangePickerDateLabelState, (v) => v)
    },
  })

export const stockSearchParamsState = atom<StockSearchParams>({
  key: "stockSearchParamsState",
  default: {},
})

export const prizeNewArrivalSearchParamsState =
  atom<PrizeNewArrivalSearchParams>({
    key: "prizeNewArrivalSearchParamsState",
    default: {},
  })

export const boothSearchParamsState = atom<BoothSearchParams>({
  key: "boothSearchParamsState",
  default: {},
})

export const hidePrizeNewArrivalsBeforeYesterdayState = atom<boolean>({
  key: "hidePrizeNewArrivalsBeforeYesterdayState",
  default: true,
})

export const hideNotChangedPrizeState = atom<boolean>({
  key: "hideNotChangedPrizeState",
  default: false,
})

const alertDefaultValue = {
  payoutRateRange: ArcadeAlertDefaultValuePayoutRateRangeEnum.Gte,
  payoutRate: 35,
  salesRange: ArcadeAlertDefaultValueSalesRangeEnum.Lte,
  sales: 1000,
  conjunction: ArcadeAlertDefaultValueConjunctionEnum.Or,
}

export const alertDefaultValueState = atom<ArcadeAlertDefaultValue>({
  key: "alertDefaultValueState",
  default: {
    id: 0,
    arcadeCd: "",
    ...alertDefaultValue,
  },
})

export const alertParamsState = atom<AlertParams>({
  key: "alertParamsState",
  default: {
    ...alertDefaultValue,
    dateLabel: getYesterday(), // NOTE: アラート日付
    machineAlerts: [],
  },
})

export const floorMapZoomRangeState = atom<number>({
  key: "floorMapZoomRangeState",
  default: 50,
})

export const floorMapPointInfoBoxSizeState = selector({
  key: "floorMapPointInfoBoxSizeState",
  get: ({ get }): FloorMapPointInfoBoxSize => {
    const zoomRange = get(floorMapZoomRangeState)
    return zoomRange >= floorMapPointInfoBoxThreshold ? "large" : "small"
  },
})

export const floorMapScrollPositionState = atom<FloorMapScrollPosition>({
  key: "floorMapScrollPositionState",
  default: {
    x: 0,
    y: 0,
  },
})

export const floorMapFullscreenState = atom<boolean>({
  key: "floorMapFullScreenState",
  default: false,
})

export const floorMapMultiSelectState = atom<boolean>({
  key: "floorMapMultiSelectState",
  default: false,
})

export const amMachinesSearchParamsState = atom<AmMachinesSearchParams>({
  key: "amMachinesSearchParamsState",
  default: {},
})

export const prizeDailyFloorMapDateLabelState = atom({
  key: "prizeDailyFloorMapDateLabelState",
  default: getToday(),
})
