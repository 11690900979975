import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Typography,
  TableRow,
  TableHead,
  DialogContent,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { PrizeArrivalElement, PutPrizeOperationInRequest } from "src/api/models"
import { putPrizeOperationIn } from "src/api/prize-operation-in"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel, getToday } from "src/utils"

interface InventoryAcceptMultiArrivalsModalProps {
  showModal: boolean
  arrivals: PrizeArrivalElement[]
  onClose: () => void
  onFinish?: () => void
}

export const InventoryPrizeAcceptMultiArrivalsModal: React.FC<
  InventoryAcceptMultiArrivalsModalProps
> = ({
  showModal,
  arrivals,
  onClose,
  onFinish = () => undefined,
}: InventoryAcceptMultiArrivalsModalProps) => {
  const { arcadeCd } = useParams()

  const acceptDate = getToday()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    if (!arcadeCd) return
    const request: PutPrizeOperationInRequest = {
      prizes: arrivals.map((e) => ({
        prizeCd: e.prize.prizeCd,
        stock: e.arrival.orderCount,
        prizeOrderId: e.arrival.id,
      })),
    }

    await submitPromises([
      {
        subject: "検収",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeOperationIn(arcadeCd, acceptDate, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1" pb={2}>
            まとめて検収
          </Typography>
          <Typography variant="body2">表示中の着荷数で検収します</Typography>
          <Typography variant="body2">
            検収済の場合、着荷数が上書きされます
          </Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12}>
              <Typography variant="body2">
                検収日 <strong>{getJpDateLabel(acceptDate)}</strong>{" "}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InventoryPrizeAcceptMultiArrivalsTable {...{ arrivals }} />
            </Grid>
          </Grid>
        </DialogContent>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            まとめて検収
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

interface InventoryAcceptMultiArrivalsTableProps {
  arrivals: PrizeArrivalElement[]
}

const InventoryPrizeAcceptMultiArrivalsTable: React.FC<
  InventoryAcceptMultiArrivalsTableProps
> = ({ arrivals }: InventoryAcceptMultiArrivalsTableProps) => {
  return (
    <PaginatedTable
      scrollableX
      items={arrivals}
      stateKey="inventoryPrizeTable"
      header={
        <TableHead>
          <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
            <ExtTableCell>景品画像</ExtTableCell>
            <ExtTableCell>景品名</ExtTableCell>
            <ExtTableCell>着荷数</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(e) => {
        const { prize } = e
        return (
          <TableBorderedRow key={prize.prizeCd} sx={{ td: { p: 1 } }}>
            <ExtTableCell>
              <Box width={64}>
                <PrizeImageBox prizeCd={prize.prizeCd} alt={prize.prizeName} />
              </Box>
            </ExtTableCell>
            <ExtTableCell sx={{ minWidth: 120 }}>
              <CardItemNameBox
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {prize.prizeName}
              </CardItemNameBox>
            </ExtTableCell>
            <ExtTableCell>
              <CardItemNameBox>{e.arrival.orderCount}</CardItemNameBox>
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
