import { atom, DefaultValue, selector } from "recoil"

import { MaterialInventoryDifferenceGroupEnum } from "src/api/models"
import { DateRangePickerDateLabel } from "src/components/atoms/DateRangePicker"
import { MaterialStockLedgerMaterialsSearchParams } from "src/domains/materials/materialStockLedgerRepository"
import { InventoryMaterialDifferenceSearchParams } from "src/pages/inventory/materials/differences/InventoryMaterialExecuteDifferences"
import { InventoryMaterialExecuteInMachineMenuTabParams } from "src/pages/inventory/materials/execute/InventoryMaterialExecuteInMachineAmMachineDetails"
import { InventoryMaterialTemporaryStoragesSearchParams } from "src/pages/materials/move/InventoryMaterialTemporaryStorages"
import { InventoryMaterialPlacementsSearchParams } from "src/pages/materials/placements/InventoryMaterialPlacements"
import { InventoryMaterialAmMachinesSearchParams } from "src/pages/materials/settings/InventoryMaterialAmMachines"
import { InventoryMaterialMachinesSearchParams } from "src/pages/materials/settings/InventoryMaterialMachines"
import { getMonthsAgo, getToday, isValidDateLabel } from "src/utils"

export const InventoryMaterialExecuteInMachineMenuTabParamsState =
  atom<InventoryMaterialExecuteInMachineMenuTabParams>({
    key: "InventoryMaterialExecuteInMachineMenuTabParamsState",
    default: "materials",
  })

export const inventoryMaterialTemporaryStoragesSearchParamsState =
  atom<InventoryMaterialTemporaryStoragesSearchParams>({
    key: "inventoryMaterialTemporaryStoragesSearchParamsState",
    default: {},
  })

export const inventoryMaterialDifferenceSearchParamsState =
  atom<InventoryMaterialDifferenceSearchParams>({
    key: "inventoryMaterialDifferenceSearchParamsState",
    default: { group: MaterialInventoryDifferenceGroupEnum.NoDifference },
  })

export const materialStockLedgerMaterialsSearchParamsState =
  atom<MaterialStockLedgerMaterialsSearchParams>({
    key: "materialStockLedgerMaterialsSearchParamsState",
    default: {},
  })

export const inventoryMaterialPlacementsSearchParamsState =
  atom<InventoryMaterialPlacementsSearchParams>({
    key: "inventoryMaterialPlacementsSearchParamsState",
    default: {},
  })

export const hideMaterialInventoryHistoriesRegisteredSeamsState = atom<boolean>(
  {
    key: "hideMaterialInventoryHistoriesRegisteredSeamsState",
    default: true,
  },
)

export const hideEmptyMaterialState = atom<boolean>({
  key: "hideEmptyMaterialState",
  default: false,
})

export const showOnlyNotAcceptedMaterialsState = atom<boolean>({
  key: "showOnlyNotAcceptedMaterialsState",
  default: false,
})

export const inventoryMaterialAmMachinesSearchParamsState =
  atom<InventoryMaterialAmMachinesSearchParams>({
    key: "inventoryMaterialAmMachinesSearchParamsState",
    default: {},
  })

export const inventoryMaterialMachinesSearchParamsState =
  atom<InventoryMaterialMachinesSearchParams>({
    key: "inventoryMaterialMachinesSearchParamsState",
    default: {},
  })

export const inventoryMaterialStockLedgerDateRangePickerDateLabelState =
  atom<DateRangePickerDateLabel>({
    key: "inventoryMaterialStockLedgerDateRangePickerDateLabelState",
    default: {
      start: getMonthsAgo(2),
      end: getToday(),
    },
  })

export const inventoryMaterialStockLedgerDateRangePickerDateLabelStateSelector =
  selector<DateRangePickerDateLabel>({
    key: "inventoryMaterialStockLedgerDateRangePickerDateLabelStateSelector",
    get: ({ get }) =>
      get(inventoryMaterialStockLedgerDateRangePickerDateLabelState),
    set: ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        return set(
          inventoryMaterialStockLedgerDateRangePickerDateLabelState,
          newValue,
        )
      }
      if (isValidDateLabel(newValue.start) && isValidDateLabel(newValue.end)) {
        return set(
          inventoryMaterialStockLedgerDateRangePickerDateLabelState,
          newValue,
        )
      }
      set(inventoryMaterialStockLedgerDateRangePickerDateLabelState, (v) => v)
    },
  })
