import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { createSearchParams, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialMachine } from "src/api/material-machines"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { Material, MaterialOperationMachineStock } from "src/api/models"
import {
  InventoryMaterialPlacementMaterialsFilter,
  inventoryPlacementMaterialsSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsFilter"
import { InventoryMaterialPlacementMaterialsMenu } from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { sortPlacementStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"

export const InventoryMaterialPlacementInMachineMaterials = () => {
  const { arcadeCd, materialMachineId } = useParams()
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  const { resource: materialMachineResource } = useResource({
    subject: "材料機械情報の取得",
    fetch:
      arcadeCd && materialMachineId
        ? () => getMaterialMachine(arcadeCd, Number(materialMachineId))
        : undefined,
    recoilKey: `getMaterialMachine:${arcadeCd}:${materialMachineId}`,
  })
  const amMachine = materialMachineResource?.data.amMachine
  const materialMachine = materialMachineResource?.data.materialMachine

  return (
    <MainContentLayout
      title={
        getDisplayMaterialMachineName(amMachine, materialMachine) +
        "［内］ の材料一覧"
      }
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialPlacementMaterialsFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialPlacementInMachineMaterialsMenu />}
    />
  )
}

const InventoryMaterialPlacementInMachineMaterialsMenu = () => {
  return <InventoryMaterialPlacementInMachineMaterialsTable />
}

const InventoryMaterialPlacementInMachineMaterialsTable: React.FC = () => {
  const { arcadeCd, materialMachineId } = useParams()
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)

  const searchParams = useRecoilValue(
    inventoryPlacementMaterialsSearchParamsState,
  )
  const { resource: materialStocksResource } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && materialMachineId
        ? () =>
            getMaterialOperationStocks(arcadeCd, {
              materialName: searchParams.materialName,
              materialNameKana: searchParams.materialNameKana,
              materialCd: searchParams.materialCd,
              materialMachineIds: [Number(materialMachineId)],
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialMachineId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks: (MaterialOperationMachineStock & {
    material: Material
  })[] = useMemo(() => {
    const stocks = materialStocksResource?.data.stocks || []
    return sortPlacementStocks(
      stocks.flatMap((s) =>
        s.machineStocks.map((machineStock) => ({
          material: s.material,
          ...machineStock,
        })),
      ),
      searchParams,
    )
  }, [materialStocksResource, searchParams])

  const menuItems = useMemo(
    () =>
      filteredStocks
        ?.filter((machineStock) =>
          hideEmptyMaterial ? machineStock.machineStock.stock > 0 : true,
        )
        .map((machineStock) => {
          const {
            machineStock: { materialCd, stock },
            material: { materialName },
          } = machineStock
          return {
            material: {
              materialCd,
              materialName,
            },
            stock,
          }
        }) ?? [],
    [filteredStocks, hideEmptyMaterial],
  )

  return (
    <InventoryMaterialPlacementMaterialsMenu
      menuItems={menuItems}
      searchParams={createSearchParams({
        materialMachineId: String(materialMachineId),
      })}
    />
  )
}
