import { useState, useMemo, useEffect, useCallback } from "react"

import { Box, Button, CircularProgress, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { PatchPrizeStoragesOrderRequest, PrizeStorage } from "src/api/models"
import {
  getPrizeStorages,
  patchPrizeStoragesOrder,
} from "src/api/prize-storages"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { InventoryPrizeAddStorageModal } from "src/components/organisms/prizes/InventoryPrizeAddStorageModal"
import {
  SortableListTableRow,
  SortableTable,
} from "src/components/organisms/SortableTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { temporaryStorageName } from "src/domains/prizes/inventoryStockRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"

export const InventoryPrizeStorages = () => {
  return (
    <MainContentLayout
      title="保管場所一覧"
      renderContent={() => <InventoryPrizeStoragesMenu />}
    />
  )
}

const InventoryPrizeStoragesMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)

  const { resource, refetch } = useResource({
    subject: "保管場所情報の取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  })
  const storages = useMemo(
    () => (resource?.data.storages || []).map((e) => e.storage),
    [resource],
  )

  const onFinishAddStorage = () => refetch()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const sortedItems = useMemo(
    () => [...storages].sort((a, b) => a.displayOrder - b.displayOrder),
    [storages],
  )

  const [items, setItems] = useState(sortedItems)

  useEffect(() => setItems(sortedItems), [sortedItems])

  const onChangeDrag = useCallback(
    (newState: PrizeStorage[]) => {
      if (!arcadeCd) return
      setItems(newState)
      const request: PatchPrizeStoragesOrderRequest = {
        storageOrders: newState.map(({ id }, index) => ({
          id,
          displayOrder: index + 1,
        })),
      }
      submitPromises([
        {
          subject: "保管場所の並び替え",
          showSuccessMessage: true,
          promise: () => patchPrizeStoragesOrder(arcadeCd, request),
        },
      ])
    },
    [arcadeCd, submitPromises],
  )

  return (
    <>
      <Stack gap={2}>
        <Box sx={{ position: "relative" }}>
          {isSubmitting && (
            <CircularProgress
              sx={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "auto",
                position: "absolute",
              }}
            />
          )}
          <SortableTable
            items={items}
            renderRow={({ item, ...rest }) => {
              return (
                <SortableListTableRow
                  key={item.id}
                  onClick={() =>
                    item.name !== temporaryStorageName &&
                    navigate(
                      `/arcades/${arcadeCd}/prizes/settings/storages/${item.id}/shelves`,
                    )
                  }
                  id={item.id}
                  data-testid={`sortable-row-${item.id}`}
                  {...rest}
                >
                  <ExtTableCell
                    sx={(theme) => ({
                      p: 2,
                      pl: 0,
                      color: theme.palette.primary.main,
                    })}
                  >
                    {item.name}
                  </ExtTableCell>
                </SortableListTableRow>
              )
            }}
            onChangeDrag={onChangeDrag}
          />
        </Box>

        <Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowModal(true)}
          >
            保管場所を追加
          </Button>
        </Stack>
      </Stack>

      <InventoryPrizeAddStorageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onFinish={onFinishAddStorage}
        storages={storages}
      />
    </>
  )
}
