/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeImageUrlResponse,
  GetPrizesParams,
  GetPrizesResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 景品画像の URL を取得
 */
export const getPrizeImageUrl = (
  prizeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeImageUrlResponse>(
    { url: `/prizes/${prizeCd}/imageUrl`, method: "GET" },
    options,
  )
}
/**
 * @summary 景品一覧の取得
 */
export const getPrizes = (
  arcadeCd: string,
  params?: GetPrizesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizesResponse>(
    { url: `/arcades/${arcadeCd}/prizes`, method: "GET", params },
    options,
  )
}
export type GetPrizeImageUrlResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeImageUrl>>
>
export type GetPrizesResult = NonNullable<Awaited<ReturnType<typeof getPrizes>>>
