import {
  Csv,
  CsvRow,
  ExecutionPeriod,
  PrizeOperationStock,
} from "src/api/models"
import { dayJsWithTimezone, filterUniqueArray } from "src/utils"

export const ITEM_CD_COLUMN = "倉庫"
export const ORDER_DATE_COLUMN = "オーダ日"
export const ITEM_CD_LEFT_COLUMN = "品目"
export const STOCK_COLUMN = "計数済在庫 (保管単位)"
export const PCS_RIGHT_COLUMN = STOCK_COLUMN
export const OUTPUT_REQUIRED_COLUMNS = [
  "会社",
  "オーダ",
  "状況",
  "計数回数",
  "倉庫",
  "オーダ日",
  "会社",
  "ライン",
  "保管場所",
]
export const IMPORT_REQUIRED_COLUMNS = [
  ...OUTPUT_REQUIRED_COLUMNS,
  ITEM_CD_COLUMN,
  ITEM_CD_LEFT_COLUMN,
  STOCK_COLUMN,
]

export const OUTPUT_HISTORIES_CSV_RELEASE_DATE = "2023-01-25"

export const makeCSVRowsDataFromInventoryStocks = (
  inventoryStocks: PrizeOperationStock[],
) => {
  const csvRows: CsvRow[] = []
  inventoryStocks?.forEach(({ shelfStocks, onBoothStocks, boothStocks }) => {
    shelfStocks.forEach(({ storageName, shelfName, prizeCd, stock }) =>
      csvRows.push({
        columns: [
          "プライズ機外",
          storageName,
          shelfName,
          "",
          prizeCd,
          stock.toString(),
        ],
      }),
    )
    onBoothStocks.forEach(({ onBoothShelfName, prizeCd, stock }) =>
      csvRows.push({
        columns: [
          "プライズ機上",
          "",
          "",
          onBoothShelfName,
          prizeCd,
          stock.toString(),
        ],
      }),
    )
    boothStocks.forEach(({ boothShelfName, prizeCd, stock }) =>
      csvRows.push({
        columns: [
          "プライズ機内",
          "",
          "",
          boothShelfName,
          prizeCd,
          stock.toString(),
        ],
      }),
    )
  })
  return csvRows
}

export const isOrderDateBeforePeriodStarts = (
  csv: Csv,
  period: ExecutionPeriod,
) => {
  const orderDateColumnIndex = csv.headerRow?.columns.findIndex(
    (column) => column === ORDER_DATE_COLUMN,
  )
  if (orderDateColumnIndex === undefined) return false

  // NOTE: `row.columns[orderDateColumnIndex]` でクラッシュ可能性があるため、 try でラップする
  try {
    const orderDates = (csv.rows || [])
      .map((row) => row.columns[orderDateColumnIndex])
      .filter(filterUniqueArray)
      .map((date) => dayJsWithTimezone(date))
    if (
      orderDates.some((date) =>
        date.isBefore(dayJsWithTimezone(period.startAt)),
      )
    ) {
      return true
    }
  } catch {
    return false
  }
  return false
}
