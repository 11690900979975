/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type FeatureExecutionPeriodStatusEnum =
  (typeof FeatureExecutionPeriodStatusEnum)[keyof typeof FeatureExecutionPeriodStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureExecutionPeriodStatusEnum = {
  Active: "active",
  Ended: "ended",
  Skipped: "skipped",
  KidsMarketEnded: "kids_market_ended",
} as const
