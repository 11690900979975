import React, {
  useState,
  useCallback,
  startTransition,
  useEffect,
  useRef,
} from "react"

import { ExpandCircleDown } from "@mui/icons-material"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Typography,
} from "@mui/material"
import { Stack } from "@mui/system"
import deepEqual from "fast-deep-equal"
import {
  useForm,
  SubmitHandler,
  Controller,
  ControllerProps,
  DefaultValues,
  useWatch,
  FormProvider,
} from "react-hook-form"

type FilterAccordionProps<T extends Record<string, unknown>> = {
  searchParams: T
  setSearchParams: (searchParams: T) => void
  formInputs: (Omit<ControllerProps<T>, "control"> & {
    label?: string
    rowSpacing?: number
    fullWidth?: boolean
    hiddenAt?: (values: { [x: string]: unknown }) => boolean
  })[]
  accordionLabel?: string
  defaultExpanded?: boolean
}

export const FilterAccordion = <T extends Record<string, unknown>>({
  searchParams,
  setSearchParams,
  formInputs,
  accordionLabel,
  defaultExpanded = false,
}: FilterAccordionProps<T>) => {
  const useFormReturn = useForm<T>({
    defaultValues: searchParams as DefaultValues<T>,
  })
  const { handleSubmit, control, reset } = useFormReturn
  const values = useWatch({ control })

  // searchParams に変更があったときに value を更新する
  const searchParamsRef = useRef(searchParams)
  useEffect(() => {
    if (!deepEqual(searchParams, searchParamsRef.current)) {
      reset(searchParams)
      searchParamsRef.current = searchParams
    }
  }, [searchParams, reset])

  const onSubmit: SubmitHandler<T> = useCallback(
    (data) => {
      startTransition(() => setSearchParams({ ...searchParams, ...data }))
    },
    [searchParams, setSearchParams],
  )

  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={
          <ExpandCircleDown
            sx={(theme) => ({ color: theme.palette.gray["40"] })}
          />
        }
      >
        {accordionLabel ?? "絞り込み・並び替え"}
      </AccordionSummary>

      <AccordionDetails sx={{ pt: 1, pb: 2, px: 3 }}>
        <FormProvider {...useFormReturn}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <Grid container rowSpacing={2} columnSpacing={6}>
                {formInputs
                  .filter((input) =>
                    input.hiddenAt ? !input.hiddenAt(values) : true,
                  )
                  .map(
                    ({
                      label,
                      rowSpacing = 1,
                      fullWidth,
                      hiddenAt: _,
                      ...input
                    }) => (
                      <Grid
                        key={input.name}
                        item
                        xs={12}
                        sm={fullWidth ? 12 : 6}
                      >
                        {label ? (
                          <Grid
                            container
                            alignItems="center"
                            rowSpacing={rowSpacing}
                            columnSpacing={2}
                            // 要素幅が大きいときに意図せず折り返してしまうことを防ぐ
                            // TODO: まだ突き出してしまう場合があるので調整する
                            flexWrap={{ xs: "wrap", sm: "nowrap" }}
                          >
                            <Grid item xs={12} sm="auto">
                              <Typography variant="body2" width={{ sm: 100 }}>
                                {label}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs
                              display="flex"
                              flexDirection="column"
                              alignItems="stretch"
                            >
                              <Controller control={control} {...input} />
                            </Grid>
                          </Grid>
                        ) : (
                          <Controller control={control} {...input} />
                        )}
                      </Grid>
                    ),
                  )}
              </Grid>
              <Stack pt={1}>
                <Button variant="contained" type="submit">
                  検索する
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </AccordionDetails>
    </Accordion>
  )
}
