import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Typography,
  TextField,
  Divider,
  DialogContent,
} from "@mui/material"
import {
  FieldArrayWithId,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { PrizeOperationStock } from "src/api/models"
import { putPrizeOperationOut } from "src/api/prize-operation-out"
import { BackButton } from "src/components/atoms/BackButton"
import { tableHeadStyle } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getToday } from "src/utils"

interface InventoryOutFormShelf {
  shelfId: number
  stock: number
}

interface InventoryOutFormInput {
  shelves: InventoryOutFormShelf[]
}

interface InventoryOutModalProps {
  showModal: boolean
  stock: PrizeOperationStock
  onClose: () => void
  onFinish?: () => void
}

export const InventoryPrizeOutModal: React.FC<InventoryOutModalProps> = ({
  showModal,
  stock,
  onClose,
  onFinish = () => undefined,
}: InventoryOutModalProps) => {
  const { arcadeCd } = useParams()
  const { prize } = stock

  const validationSchema = Yup.object({
    shelves: Yup.array(
      Yup.object({
        shelfId: Yup.number().required("必須です"),
        stock: Yup.number()
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください")
          .required("必須です"),
      }),
    ).required("必須です"),
  })

  const useFormReturn = useForm<InventoryOutFormInput>({
    resolver: yupResolver<InventoryOutFormInput>(validationSchema),
    defaultValues: {
      shelves: stock.shelfStocks
        .filter((s) => s.stock > 0)
        .map(({ shelfId }) => ({ shelfId, stock: 0 })),
    },
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useFormReturn
  const { fields } = useFieldArray({
    control,
    name: "shelves",
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryOutFormInput> = async (data) => {
    const { shelves } = data
    const isAllZero = !shelves.some((shelf) => shelf.stock > 0)
    if (isAllZero) {
      return
    }

    const request = {
      prizes: [
        {
          prizeCd: stock.prize.prizeCd,
          shelves: shelves.filter((s) => s.stock > 0),
        },
      ],
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "店外出庫",
          showSuccessMessage: true,
          promise: async () => {
            await putPrizeOperationOut(arcadeCd, getToday(), request)
            onClose()
            onFinish()
          },
        },
      ]))
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1" pb={1}>
            {prize.prizeName}
          </Typography>
          <Box
            sx={{
              maxWidth: "80%",
              my: 1,
              mx: "auto",
            }}
          >
            <PrizeImageBox
              prizeCd={prize.prizeCd}
              alt={prize.prizeName}
              noImageSize="large"
            />
          </Box>
        </DialogTitleWithClose>

        <DialogContent>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={5} pb={2} sx={tableHeadStyle} />
            <Grid item xs={2} pb={2} sx={tableHeadStyle}>
              在庫
            </Grid>
            <Grid item xs={3} pb={2} sx={tableHeadStyle}>
              出庫数
            </Grid>
            <Grid item xs={2} pb={2} sx={tableHeadStyle}>
              出庫後
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />
            </Grid>

            {fields.map((field, index) => (
              <InventoryPrizeOutFormShelf
                {...{ stock, useFormReturn, field, index }}
                key={field.id}
              />
            ))}
          </Grid>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            店外に出庫する
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

interface InventoryOutFormShelfProps {
  stock: PrizeOperationStock
  useFormReturn: UseFormReturn<InventoryOutFormInput, object>
  field: FieldArrayWithId<InventoryOutFormInput, "shelves", "id">
  index: number
}

const InventoryPrizeOutFormShelf: React.FC<InventoryOutFormShelfProps> = ({
  stock,
  useFormReturn,
  field,
  index,
}: InventoryOutFormShelfProps) => {
  const {
    formState: { errors },
    control,
    register,
  } = useFormReturn
  const shelfErrors = (errors.shelves && errors.shelves[index]) || {}
  const shelfStock = stock.shelfStocks.find((s) => s.shelfId === field.shelfId)
  const { storageName, shelfName } = shelfStock || {}
  const value = useWatch({
    name: `shelves.${index}`,
    control,
  })

  return (
    <Grid container item xs={12} sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={5} pb={2} pr={1}>
        {storageName} {shelfName}
      </Grid>
      <Grid item xs={2} pb={2}>
        <strong>{shelfStock?.stock}</strong> 個
      </Grid>
      <Grid item xs={3} pb={2}>
        <TextField
          error={"stock" in shelfErrors}
          helperText={shelfErrors.stock?.message || ""}
          {...register(`shelves.${index}.stock`)}
          inputProps={{ inputMode: "numeric" }}
          sx={{ mr: 1 }}
        />
      </Grid>
      <Grid item xs={2} pb={2}>
        <strong>{(shelfStock?.stock || 0) - (Number(value.stock) || 0)}</strong>{" "}
        個
      </Grid>
    </Grid>
  )
}
