import { Card, CardContent, Chip, Stack, Typography } from "@mui/material"
import { useParams } from "react-router-dom"

import { Prize, PrizeMonthlyPlan } from "src/api/models"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { getDisplayPrizeFee } from "src/domains/prizes/prizeSettingRepository"
import { getRatioLabel } from "src/utils"

type PrizeMonthlyFormResultProps = {
  plan: PrizeMonthlyPlan
  prize: Prize
}

export const PrizeMonthlyFormResult: React.FC<PrizeMonthlyFormResultProps> = ({
  plan,
  prize,
}) => {
  const { arcadeCd } = useParams()

  const { prizeCd, prizeName } = prize ?? {}
  const {
    expectedCapturePrice,
    expectedConsumptionRate,
    numOfBooths,
    boothCategory,
    machine,
    fee,
    playCount,
  } = plan

  return (
    <Stack
      sx={{
        flexGrow: 1,
        gap: 2,
      }}
    >
      <AlertCaptionCard
        label="下記の通り入力しました"
        rightLinkText="一覧へ戻る"
        rightLinkTo={`/arcades/${arcadeCd}/prizes/plans/monthly`}
      />
      <Card>
        <CardContent sx={{ px: 3, py: 2, ":last-child": { pb: 2 } }}>
          <Stack gap={2}>
            <Typography variant="h2">{prizeName}</Typography>

            <Stack direction="row">
              <Typography>景品CD</Typography>
              <Chip
                label={prizeCd}
                size="small"
                sx={{ fontWeight: "bold", ml: 1 }}
              />
            </Stack>

            <Stack gap={2} pt={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography>1個獲得金額</Typography>
                <Typography>
                  <Typography variant="h3" component="span">
                    {expectedCapturePrice?.toLocaleString()}
                  </Typography>
                  円
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography>初週消化率</Typography>
                <Typography variant="h3" component="span">
                  {getRatioLabel(expectedConsumptionRate)}
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography>ブース展開数</Typography>
                <Typography>
                  <Typography variant="h3" component="span">
                    {numOfBooths
                      ? numOfBooths.toLocaleString() + "ブース"
                      : "-"}
                  </Typography>
                </Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography>料金設定</Typography>
                <Typography variant="h3" component="span">
                  {getDisplayPrizeFee(fee, playCount)}
                </Typography>
              </Stack>

              <Stack gap={1}>
                <Typography>ブース区分</Typography>
                <Typography variant="h3">{boothCategory || "-"}</Typography>
              </Stack>

              <Stack gap={1}>
                <Typography>投入予定機種</Typography>
                <Typography variant="h3">{machine || "-"}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  )
}
