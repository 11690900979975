import React, { useMemo } from "react"

import { Button, Checkbox, FormControlLabel, Stack } from "@mui/material"
import {
  createSearchParams,
  Link as RouterLink,
  useParams,
} from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { PrizeFloorMapPoint } from "src/api/models"
import { getPrizeFloorMapPoint } from "src/api/prize-floor-map"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import {
  InventoryPlacementPrizesFilter,
  placementPrizesSearchParamsState,
} from "src/components/organisms/prizes/InventoryPrizePlacementPrizesFilter"
import { InventoryPlacementPrizesMenu } from "src/components/organisms/prizes/InventoryPrizePlacementPrizesMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortPlacementStocks } from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyPrizeState } from "src/recoil/inventory"

export const InventoryPrizePlacementInBoothPrizes = () => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()
  const [hideEmptyPrize, setHideEmptyPrize] =
    useRecoilState(hideEmptyPrizeState)

  const { resource: inventoryFloorMapPointResource } = useResource({
    subject: "ブース情報の取得",
    fetch:
      arcadeCd && inventoryFloorMapPointId
        ? () =>
            getPrizeFloorMapPoint(arcadeCd, Number(inventoryFloorMapPointId))
        : undefined,
    recoilKey: `getInventoryFloorMapPoint:${arcadeCd}:${inventoryFloorMapPointId}`,
  })
  const inventoryFloorMapPoint =
    inventoryFloorMapPointResource?.data.floorMapPoint

  return (
    <MainContentLayout
      title={
        inventoryFloorMapPoint
          ? inventoryFloorMapPoint.name + "［内］ の景品一覧"
          : ""
      }
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryPlacementPrizesFilter />
          <FormControlLabel
            checked={hideEmptyPrize}
            onChange={() => setHideEmptyPrize(!hideEmptyPrize)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => (
        <InventoryPlacementInBoothPrizesMenu
          inventoryFloorMapPoint={inventoryFloorMapPoint}
        />
      )}
    />
  )
}

type InventoryPlacementInBoothPrizesMenuProps = {
  inventoryFloorMapPoint?: PrizeFloorMapPoint
}

const InventoryPlacementInBoothPrizesMenu: React.FC<
  InventoryPlacementInBoothPrizesMenuProps
> = ({ inventoryFloorMapPoint }) => {
  const { arcadeCd } = useParams()
  const boothShelfId = inventoryFloorMapPoint?.boothShelf.id

  return (
    <Stack gap={2}>
      <InventoryPlacementInBoothPrizesTable boothShelfId={boothShelfId} />

      <Stack>
        <Button
          variant="outlined"
          fullWidth
          component={RouterLink}
          to={`/arcades/${arcadeCd}/prizes/register/accept/search`}
        >
          検索して景品登録
        </Button>
      </Stack>
    </Stack>
  )
}

interface InventoryPlacementInBoothPrizesTableProps {
  boothShelfId?: number
}

const InventoryPlacementInBoothPrizesTable: React.FC<
  InventoryPlacementInBoothPrizesTableProps
> = ({ boothShelfId }) => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()
  const hideEmptyPrize = useRecoilValue(hideEmptyPrizeState)

  const searchParams = useRecoilValue(placementPrizesSearchParamsState)
  const { resource: inventoryStocksResource } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && inventoryFloorMapPointId
        ? () =>
            getPrizeOperationStocks(arcadeCd, {
              prizeName: searchParams.prizeName,
              prizeNameKana: searchParams.prizeNameKana,
              prizeCd: searchParams.prizeCd,
              inventoryFloorMapPointId: Number(inventoryFloorMapPointId),
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${inventoryFloorMapPointId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = inventoryStocksResource?.data.stocks || []
    return sortPlacementStocks(
      stocks.flatMap((s) => s.boothStocks),
      searchParams,
    )
  }, [inventoryStocksResource, searchParams])

  const menuItems = useMemo(
    () =>
      filteredStocks
        ?.filter((boothStock) => (hideEmptyPrize ? boothStock.stock > 0 : true))
        .map((boothStock) => {
          const { prizeCd, prizeName, stock } = boothStock
          return {
            prize: {
              prizeCd,
              prizeName,
            },
            stock,
          }
        }) ?? [],
    [filteredStocks, hideEmptyPrize],
  )

  return (
    <InventoryPlacementPrizesMenu
      menuItems={menuItems}
      searchParams={createSearchParams({
        boothShelfId: String(boothShelfId),
      })}
    />
  )
}
