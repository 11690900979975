import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { PrizeShelf, PrizeStorage } from "src/api/models"
import { getPrizeInventoryExecute } from "src/api/prize-inventory-execute"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { getPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import { getPrizeStorage } from "src/api/prize-storages"
import {
  inventoryExecuteDetailsSearchParamsState,
  InventoryPrizeExecuteDetailsFilter,
} from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsFilter"
import { InventoryPrizeExecuteDetailsMenuItem } from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsMenu"
import { InventoryPrizeExecuteDetails } from "src/components/templates/InventoryPrizeExecuteDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { sortPlacementStocks } from "src/domains/prizes/inventoryStockRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"

export const InventoryPrizeExecuteStorageDetails = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const { resource, refetch } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
  })

  const storage = resource?.data.storage
  const shelf = resource?.data.shelves?.find(
    (shelf) => shelf.id.toString() === shelfId,
  )
  const placementName = storage && shelf && `${storage.name} ${shelf.name}`

  return (
    <MainContentLayout
      title={placementName ? placementName + " の景品一覧" : ""}
      renderFilter={() => <InventoryPrizeExecuteDetailsFilter />}
      renderContent={() => (
        <InventoryPrizeExecuteStorageDetailsMenu
          storage={storage}
          shelf={shelf}
          refetchStorage={refetch}
        />
      )}
    />
  )
}

type InventoryExecuteStorageDetailsMenuProps = {
  storage?: PrizeStorage
  shelf?: PrizeShelf
  refetchStorage: () => void
}

const InventoryPrizeExecuteStorageDetailsMenu: React.FC<
  InventoryExecuteStorageDetailsMenuProps
> = ({ storage, shelf, refetchStorage }) => {
  const { arcadeCd, shelfId } = useParams()

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const { resource: statusesResource, refetch: refetchStatuses } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? async () => {
            if (!shelf) return
            return await getPrizePlacementStatuses(
              arcadeCd,
              PlacementType.Storage,
              currentPrizeExecutionPeriod.id,
              { placementId: shelf.id },
            )
          }
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      PlacementType.Storage
    }:${JSON.stringify(currentPrizeExecutionPeriod)}:${shelf?.id}`,
  })
  const shelfStatuses = useMemo(
    () => statusesResource?.data.shelfStatuses || [],
    [statusesResource],
  )

  const searchParams = useRecoilValue(inventoryExecuteDetailsSearchParamsState)
  const { resource: historiesResource, refetch: refetchHistories } =
    useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentPrizeExecutionPeriod
          ? () =>
              getPrizeInventoryExecute(
                arcadeCd,
                currentPrizeExecutionPeriod.id,
                {
                  prizeCd: searchParams.prizeCd,
                  prizeName: searchParams.prizeName,
                  prizeNameKana: searchParams.prizeNameKana,
                  ipName: searchParams.ipName,
                  makerName: searchParams.makerName,
                  placementType: PlacementType.Storage,
                  placementId: shelf?.id,
                },
              )
          : undefined,
      recoilKey: `getInventoryExecute:${arcadeCd}:${JSON.stringify(
        currentPrizeExecutionPeriod,
      )}:${JSON.stringify(searchParams)}:${PlacementType.Storage}:${shelf?.id}`,
    })
  const histories = historiesResource?.data.histories

  const { resource: stocksResource, refetch: refetchStocks } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && shelfId
        ? () =>
            getPrizeOperationStocks(arcadeCd, {
              prizeName: searchParams.prizeName,
              prizeNameKana: searchParams.prizeNameKana,
              prizeCd: searchParams.prizeCd,
              shelfId: Number(shelfId),
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${shelfId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = stocksResource?.data.stocks
    if (stocks && stocks.length > 0) {
      return sortPlacementStocks(
        stocks.flatMap((s) => s.shelfStocks),
        searchParams,
      )
    } else {
      return []
    }
  }, [searchParams, stocksResource])

  const refetch = () => {
    refetchStorage()
    refetchStatuses()
    refetchHistories()
    refetchStocks()
  }

  const menuItems: InventoryPrizeExecuteDetailsMenuItem[] = useMemo(() => {
    const shelfStocks =
      (shelf &&
        filteredStocks
          ?.map((shelfStock) => {
            const { id, prizeCd, prizeName, unitPerCarton } = shelfStock
            const prize = {
              prizeCd,
              prizeName,
              unitPerCarton,
            }
            const history = histories?.find(
              (history) => history.shelfStock?.id === id,
            )
            const executedStock = history?.stock
            const executed = !!history
            return {
              prize,
              anyStock: shelfStock.stock,
              placementStockId: shelfStock.id,
              placementType: PlacementType.Storage,
              executedStock,
              executed,
              executedAt: history?.executedAt,
            }
          })
          .filter(Boolean)) ??
      []

    const stockPrizeCds = shelfStocks.map((s) => s.prize.prizeCd)
    const { Storage } = PlacementType
    const historiesWithoutShelfStock = (histories || [])
      .filter((h) => h.placement === Storage && h.placementId === shelf?.id)
      .filter((h) => !stockPrizeCds.includes(h.prizeCd))
      .filter((h) => !h.shelfStock || h.shelfStock.stock <= 0)
      .map((h) => ({
        prize: {
          prizeCd: h.prizeCd,
          prizeName: h.prizeName,
        },
        placementType: PlacementType.Storage,
        executedStock: h.stock,
        executed: true,
        executedAt: h.executedAt,
      }))

    return [...shelfStocks, ...historiesWithoutShelfStock]
  }, [filteredStocks, histories, shelf])

  return (
    <InventoryPrizeExecuteDetails
      placementType="storage"
      placementId={shelf?.id}
      storageId={storage?.id}
      placementStatus={shelfStatuses[0]}
      placementStocks={filteredStocks}
      menuItems={menuItems}
      histories={histories}
      onFinish={() => refetch()}
    />
  )
}
