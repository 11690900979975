import { TextField, Select, MenuItem } from "@mui/material"
import { useRecoilState } from "recoil"

import {
  PrizeInventoryDifference,
  PrizeInventoryDifferenceGroupEnum,
} from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { inventoryDifferenceFilterSortByLabels } from "src/domains/prizes/inventoryExecuteRepository"
import { InventoryPrizeDifferenceSearchParams } from "src/pages/inventory/prizes/differences/InventoryPrizeExecuteDifferences"
import { inventoryDifferenceSearchParamsState } from "src/recoil/inventory"
import { compareByPhoneticOrder } from "src/utils"

export const InventoryPrizeDifferenceFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryDifferenceSearchParamsState,
  )
  const { prizeCd, prizeCdInSeams, prizeName, ipName } = searchParams

  return (
    <FilterAccordion
      defaultExpanded={!!(prizeCd || prizeCdInSeams || prizeName || ipName)}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD (GiGO NAVI)",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeCd" }}
              fullWidth
            />
          ),
        },
        {
          name: "prizeCdInSeams",
          label: "景品CD (SEAMS)",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeCdInSeams" }}
              fullWidth
            />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeName" }}
              fullWidth
            />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeNameKana" }}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "makerName" }}
              fullWidth
            />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "ipName" }}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "category",
          label: "景品種別",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? "all"} fullWidth>
              <MenuItem value="all" sx={{ height: 36 }} />
              <MenuItem value="prize" sx={{ height: 36 }}>
                通常景品
              </MenuItem>
              <MenuItem value="kidsMarket" sx={{ height: 36 }}>
                KM景品
              </MenuItem>
            </Select>
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(sortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    ></FilterAccordion>
  )
}

const sortByLabels = inventoryDifferenceFilterSortByLabels

export const filterDifferences = (
  differences: PrizeInventoryDifference[],
  params: InventoryPrizeDifferenceSearchParams,
) => {
  const { category, sortBy } = params
  const groupEnum = PrizeInventoryDifferenceGroupEnum

  const getSortCompareBy = () => {
    if (sortBy === "payoutStockGreater") {
      // 画面仕様上、払出個数はマイナス表示にするため、並び順も逆転させる
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        ((a.payoutStock || "") <= (b.payoutStock || "") ? 1 : -1) * -1
    }
    if (sortBy === "payoutStockLess") {
      // 画面仕様上、払出個数はマイナス表示にするため、並び順も逆転させる
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        ((a.payoutStock || "") >= (b.payoutStock || "") ? 1 : -1) * -1
    }
    if (sortBy === "consumedPriceGreater") {
      // 画面仕様上、消費金額はマイナス表示にするため、並び順も逆転させる
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        ((a.consumedPrice || "") <= (b.consumedPrice || "") ? 1 : -1) * -1
    }
    if (sortBy === "consumedPriceLess") {
      // 画面仕様上、消費金額はマイナス表示にするため、並び順も逆転させる
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        ((a.consumedPrice || "") >= (b.consumedPrice || "") ? 1 : -1) * -1
    }
    if (sortBy === "differentPriceGreater") {
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        (a.differentPrice || "") <= (b.differentPrice || "") ? 1 : -1
    }
    if (sortBy === "differentPriceLess") {
      return (a: PrizeInventoryDifference, b: PrizeInventoryDifference) =>
        (a.differentPrice || "") >= (b.differentPrice || "") ? 1 : -1
    }

    return () => 1
  }

  return (
    differences
      .filter((d) => {
        switch (category) {
          case "all":
            return true
          case "prize":
            return !d.isKidsMarket
          case "kidsMarket":
            return d.isKidsMarket
        }
        return true
      })
      .sort((a, b) => {
        if (a.ipName === b.ipName) {
          return compareByPhoneticOrder(a.prizeName, b.prizeName)
        }
        return compareByPhoneticOrder(a.ipName, b.ipName)
      })
      // NOTE: IP 名が空文字の景品を末尾に移動
      .sort((a, b) => (a.ipName !== "" && b.ipName === "" ? -1 : 0))
      .sort(getSortCompareBy())
      // NOTE: CSV 出力用に、すべての group の differences を保持
      .reduce(
        (
          diffByGroup: {
            [key: string]: PrizeInventoryDifference[] | undefined
          },
          d,
        ) => {
          return {
            ...diffByGroup,
            [d.group]: [...(diffByGroup[d.group] || []), d],
          }
        },
        {
          [groupEnum.NoDifference]: [],
          [groupEnum.InvalidPrizeCd]: [],
          [groupEnum.NotInGigoNavi]: [],
          [groupEnum.DiffPriceOver]: [],
        },
      )
  )
}

export interface DifferenceFilterFormInput {
  prizeCd: string
  prizeCdInSeams: string
  prizeName: string
  ipName: string
  category: InventoryPrizeDifferenceSearchParams["category"]
  sortBy: "" | keyof typeof sortByLabels
}
