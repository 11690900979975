/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetUserResponse,
  GetUsersParams,
  GetUsersResponse,
  PatchUserInArcadeRequest,
  PatchUserRequest,
  PostUserRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ログイン中のユーザーを取得
 */
export const getMe = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetUserResponse>(
    { url: `/users/me`, method: "GET" },
    options,
  )
}
/**
 * @summary ユーザー一覧を取得
 */
export const getUsers = (
  params?: GetUsersParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetUsersResponse>(
    { url: `/users`, method: "GET", params },
    options,
  )
}
/**
 * @summary ユーザーを作成
 */
export const postUser = (
  postUserRequest: PostUserRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/users/new`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postUserRequest,
    },
    options,
  )
}
/**
 * @summary ユーザーを削除
 */
export const deleteUser = (
  userId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/users/${userId}`, method: "DELETE" },
    options,
  )
}
/**
 * @summary ユーザー詳細を取得
 */
export const getUser = (
  userId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetUserResponse>(
    { url: `/users/${userId}`, method: "GET" },
    options,
  )
}
/**
 * @summary ユーザーのロールを変更
 */
export const patchUser = (
  userId: number,
  patchUserRequest: PatchUserRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/users/${userId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchUserRequest,
    },
    options,
  )
}
/**
 * @summary 所属店舗における自身の権限未満のユーザーの所属先・ロールを変更
 */
export const patchUserInArcade = (
  userId: number,
  patchUserInArcadeRequest: PatchUserInArcadeRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/users/arcades/user/${userId}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchUserInArcadeRequest,
    },
    options,
  )
}
export type GetMeResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetUsersResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type PostUserResult = NonNullable<Awaited<ReturnType<typeof postUser>>>
export type DeleteUserResult = NonNullable<
  Awaited<ReturnType<typeof deleteUser>>
>
export type GetUserResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type PatchUserResult = NonNullable<Awaited<ReturnType<typeof patchUser>>>
export type PatchUserInArcadeResult = NonNullable<
  Awaited<ReturnType<typeof patchUserInArcade>>
>
