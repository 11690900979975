/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚卸確定を実行
 */
export const postPrizeInventoryExecutionPeriodEnd = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/executionPeriods/end`,
      method: "POST",
    },
    options,
  )
}
/**
 * @summary 棚卸確定をスキップ
 */
export const postPrizeInventoryExecutionPeriodSkip = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/executionPeriods/skip`,
      method: "POST",
    },
    options,
  )
}
/**
 * @summary KM景品の棚卸確定を実行
 */
export const postPrizeInventoryExecutionPeriodEndKidsMarket = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/executionPeriods/endKidsMarket`,
      method: "POST",
    },
    options,
  )
}
export type PostPrizeInventoryExecutionPeriodEndResult = NonNullable<
  Awaited<ReturnType<typeof postPrizeInventoryExecutionPeriodEnd>>
>
export type PostPrizeInventoryExecutionPeriodSkipResult = NonNullable<
  Awaited<ReturnType<typeof postPrizeInventoryExecutionPeriodSkip>>
>
export type PostPrizeInventoryExecutionPeriodEndKidsMarketResult = NonNullable<
  Awaited<ReturnType<typeof postPrizeInventoryExecutionPeriodEndKidsMarket>>
>
