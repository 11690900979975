/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeSettingBoothCategoriesResponse,
  GetPrizeSettingBoothCategoryResponse,
  GetPrizeSettingFeeResponse,
  GetPrizeSettingFeesResponse,
  GetPrizeSettingFieldResponse,
  GetPrizeSettingFieldsResponse,
  PutPrizeSettingBoothCategoryRequest,
  PutPrizeSettingFeeRequest,
  PutPrizeSettingFieldRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ブース区分の一覧を取得
 */
export const getPrizeSettingBoothCategories = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingBoothCategoriesResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/settings/boothCategories`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary ブース区分の作成・更新
 */
export const putPrizeSettingBoothCategory = (
  arcadeCd: string,
  putPrizeSettingBoothCategoryRequest: PutPrizeSettingBoothCategoryRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/settings/boothCategories`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeSettingBoothCategoryRequest,
    },
    options,
  )
}
/**
 * @summary ブース区分の削除
 */
export const deletePrizeSettingBoothCategory = (
  arcadeCd: string,
  boothCategoryId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/settings/boothCategories/${boothCategoryId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary ブース区分の詳細を取得
 */
export const getPrizeSettingBoothCategory = (
  arcadeCd: string,
  boothCategoryId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingBoothCategoryResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/settings/boothCategories/${boothCategoryId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 料金設定一覧の取得
 */
export const getPrizeSettingFees = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingFeesResponse>(
    { url: `/settings/prizes/fees`, method: "GET" },
    options,
  )
}
/**
 * @summary 料金設定の作成・更新
 */
export const putPrizeSettingFee = (
  putPrizeSettingFeeRequest: PutPrizeSettingFeeRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/settings/prizes/fees`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeSettingFeeRequest,
    },
    options,
  )
}
/**
 * @summary 料金設定の削除
 */
export const deletePrizeSettingFee = (
  feeId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/settings/prizes/fees/${feeId}`, method: "DELETE" },
    options,
  )
}
/**
 * @summary 料金設定詳細の取得
 */
export const getPrizeSettingFee = (
  feeId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingFeeResponse>(
    { url: `/settings/prizes/fees/${feeId}`, method: "GET" },
    options,
  )
}
/**
 * @summary フィールド設定一覧の取得
 */
export const getPrizeSettingFields = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingFieldsResponse>(
    { url: `/settings/prizes/fields`, method: "GET" },
    options,
  )
}
/**
 * @summary フィールド設定の作成・更新
 */
export const putPrizeSettingField = (
  putPrizeSettingFieldRequest: PutPrizeSettingFieldRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/settings/prizes/fields`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeSettingFieldRequest,
    },
    options,
  )
}
/**
 * @summary フィールド設定の削除
 */
export const deletePrizeSettingField = (
  fieldId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/settings/prizes/fields/${fieldId}`, method: "DELETE" },
    options,
  )
}
/**
 * @summary フィールド設定詳細の取得
 */
export const getPrizeSettingField = (
  fieldId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeSettingFieldResponse>(
    { url: `/settings/prizes/fields/${fieldId}`, method: "GET" },
    options,
  )
}
export type GetPrizeSettingBoothCategoriesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingBoothCategories>>
>
export type PutPrizeSettingBoothCategoryResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeSettingBoothCategory>>
>
export type DeletePrizeSettingBoothCategoryResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeSettingBoothCategory>>
>
export type GetPrizeSettingBoothCategoryResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingBoothCategory>>
>
export type GetPrizeSettingFeesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingFees>>
>
export type PutPrizeSettingFeeResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeSettingFee>>
>
export type DeletePrizeSettingFeeResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeSettingFee>>
>
export type GetPrizeSettingFeeResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingFee>>
>
export type GetPrizeSettingFieldsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingFields>>
>
export type PutPrizeSettingFieldResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeSettingField>>
>
export type DeletePrizeSettingFieldResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeSettingField>>
>
export type GetPrizeSettingFieldResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeSettingField>>
>
