import { useState, useMemo, useCallback } from "react"

import { Typography, Button, Card, List, Stack } from "@mui/material"
import { AxiosResponse } from "axios"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  GetPrizePlacementStatusesResponse,
  GetPrizePlacementTypeStatusResponse,
  GetPrizeStoragesResponse,
  GetPrizePlacementTypeStatusResponseStatusEnum,
  PutPrizePlacementTypeStatusRequestStatusEnum,
} from "src/api/models"
import {
  getPrizePlacementStatuses,
  getPrizePlacementTypeStatus,
  putPrizePlacementTypeStatus,
} from "src/api/prize-placement-statuses"
import { getPrizeStorages } from "src/api/prize-storages"
import { MenuButton } from "src/components/atoms/MenuButton"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import {
  InventoryPrizeExecutePlacementListItem,
  InventoryPrizeExecuteList,
} from "src/components/organisms/prizes/InventoryPrizeExecuteList"
import { InventoryPrizeLockModal } from "src/components/organisms/prizes/InventoryPrizeLockModal"
import { InventoryPrizeRemoveLockModal } from "src/components/organisms/prizes/InventoryPrizeRemoveLockModal"
import { InventoryPrizeStatus } from "src/components/organisms/prizes/InventoryPrizeStatus"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource, UseResourceReturn } from "src/hooks/useResource"
import { useShelfGroupNames } from "src/hooks/useStorage"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentInventoryPeriodState,
  snackbarErrorMessageState,
} from "src/recoil"
import { getToday } from "src/utils"

export const InventoryPrizeExecuteStorages = () => {
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  if (currentPrizeExecutionPeriod) {
    return (
      <MainContentLayout
        title="在庫カウント(プライズ機[外])"
        renderContent={() => <InventoryPrizeExecuteStoragesMenu />}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="在庫カウント(プライズ機[外])"
      period={currentInventoryPeriod}
      featurePeriod={currentPrizeExecutionPeriod}
    />
  )
}

const InventoryPrizeExecuteStoragesMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const placementStatusesReturn = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementStatuses(
              arcadeCd,
              PlacementType.Storage,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      PlacementType.Storage
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })

  const placementTypeStatusReturn = useResource({
    subject: "ロック状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementTypeStatus(
              arcadeCd,
              PlacementType.Storage,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementTypeStatus:${arcadeCd}:${
      PlacementType.Storage
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })

  const storagesReturn = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  })

  return (
    <InventoryPrizeExecuteStoragesMenuTemplate
      {...{
        placementStatusesReturn,
        placementTypeStatusReturn,
        storagesReturn,
      }}
    />
  )
}

interface InventoryExecuteStoragesMenuTemplateProps {
  placementStatusesReturn?: UseResourceReturn<
    AxiosResponse<GetPrizePlacementStatusesResponse>
  >
  placementTypeStatusReturn?: UseResourceReturn<
    AxiosResponse<GetPrizePlacementTypeStatusResponse>
  >
  storagesReturn?: UseResourceReturn<AxiosResponse<GetPrizeStoragesResponse>>
}

const InventoryPrizeExecuteStoragesMenuTemplate: React.FC<
  InventoryExecuteStoragesMenuTemplateProps
> = ({
  placementStatusesReturn,
  placementTypeStatusReturn,
  storagesReturn,
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const [showLockModal, setLockModal] = useState(false)

  const { counts, shelfStatuses: placementStatuses } =
    placementStatusesReturn?.resource?.data || {}

  const placementTypeStatus = placementTypeStatusReturn?.resource?.data.status
  const isLocked = useMemo(
    () =>
      placementTypeStatus ===
      GetPrizePlacementTypeStatusResponseStatusEnum.Locked,
    [placementTypeStatus],
  )

  const storages = useMemo(
    () => storagesReturn?.resource?.data.storages || [],
    [storagesReturn],
  )
  const shelfGroupNames = useShelfGroupNames(storages)

  const { isAvailableInventoryLock } = useUserRole()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const placementListItems = useMemo(() => {
    const result: InventoryPrizeExecutePlacementListItem<"storage">[] = []
    storages.forEach(({ storage, shelves }) =>
      shelves?.forEach((shelf) => {
        const placementStatus = (placementStatuses || []).find(
          (placementStatus) => placementStatus.placementId === shelf.id,
        )
        result.push({
          storageId: storage.id,
          shelfId: shelf.id,
          name: `${storage.name} ${shelf.name}`,
          isAvailable: shelf.isAvailable,
          isKidsMarket: shelf.isKidsMarket,
          groupName: shelf.inventoryGroup?.groupName,
          executionStatus: placementStatus?.executionStatus,
          executedAt: placementStatus?.executedAt,
        })
      }),
    )
    return result
  }, [storages, placementStatuses])

  const refetch = useCallback(() => {
    placementStatusesReturn?.refetch()
    placementTypeStatusReturn?.refetch()
    storagesReturn?.refetch()
  }, [placementStatusesReturn, placementTypeStatusReturn, storagesReturn])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const callPutPlacemenTypeStatus = useCallback(
    (status: PutPrizePlacementTypeStatusRequestStatusEnum) => {
      arcadeCd &&
        submitPromises([
          {
            subject: "ロック状況の更新",
            showSuccessMessage: true,
            promise: async () => {
              await putPrizePlacementTypeStatus(
                arcadeCd,
                PlacementType.Storage,
                {
                  date: getToday(),
                  status,
                },
              )
              refetch()
              setLockModal(false)
            },
          },
        ])
    },
    [arcadeCd, submitPromises, refetch],
  )

  const handleOnClick = useCallback(
    ({
      storageId,
      shelfId,
      isAvailable,
    }: InventoryPrizeExecutePlacementListItem<"storage">) => {
      if (!isAvailable) return

      navigate(
        `/arcades/${arcadeCd}/inventory/prizes/execute/storage/${storageId}/${shelfId}`,
      )
    },
    [arcadeCd, navigate],
  )

  const onClickLockButton = () => {
    if (!isAvailableInventoryLock) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!isLocked && counts && counts.notExecuted > 0) {
      return setErrorMessage("未対応箇所があるため、ロックできません")
    }
    setLockModal(true)
  }

  return (
    <>
      {placementTypeStatus && isLocked && (
        <InventoryPrizeRemoveLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutPrizePlacementTypeStatusRequestStatusEnum.Open,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      {placementTypeStatus && !isLocked && (
        <InventoryPrizeLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutPrizePlacementTypeStatusRequestStatusEnum.Locked,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      <Stack gap={2}>
        <InventoryPrizeStatus counts={counts} type={PlacementType.Storage} />

        <Card>
          <List sx={{ width: "100%", py: 3 }}>
            <MenuButton onClick={() => onClickLockButton()}>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Typography>ロック機能</Typography>
                {placementTypeStatus && (
                  <Typography color="text.primary">
                    {isLocked ? "ロック中" : "解除中"}
                  </Typography>
                )}
              </Stack>
            </MenuButton>
          </List>
        </Card>

        <InventoryPrizeExecuteList
          placement={PlacementType.Storage}
          groupNames={shelfGroupNames}
          listItems={placementListItems}
          handleOnClick={handleOnClick}
        />

        <Button
          variant="outlined"
          fullWidth
          component={RouterLink}
          to={`/arcades/${arcadeCd}/inventory/prizes/execute/storage/add`}
        >
          過去取扱景品から棚に登録
        </Button>
      </Stack>
    </>
  )
}
