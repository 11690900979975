import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { createSearchParams, useLocation, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { Material, MaterialOperationMachineStock } from "src/api/models"
import {
  InventoryMaterialPlacementMaterialsFilter,
  inventoryPlacementMaterialsSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsFilter"
import { InventoryMaterialPlacementMaterialsMenu } from "src/components/organisms/materials/InventoryMaterialPlacementMaterialsMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortPlacementStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"

export const InventoryMaterialPlacementMoveFromMultipleMaterials = () => {
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  return (
    <MainContentLayout
      title="選択された材料機械内の材料一覧"
      caption="一括で移動させる材料を選択してください"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialPlacementMaterialsFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialPlacementInMachineMaterialsMenu />}
    />
  )
}

const InventoryMaterialPlacementInMachineMaterialsMenu = () => {
  return <InventoryMaterialPlacementInMachineMaterialsTable />
}
const InventoryMaterialPlacementInMachineMaterialsTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const { search } = useLocation()
  const machineIdsQuery = new URLSearchParams(search).get("machineIds")

  const materialMachineIds = useMemo(
    () =>
      machineIdsQuery
        ?.split(",")
        .map((id) => {
          const idNum = Number(id)
          return isNaN(idNum) ? undefined : idNum
        })
        .filter(Boolean) as number[],
    [machineIdsQuery],
  )
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)
  const searchParams = useRecoilValue(
    inventoryPlacementMaterialsSearchParamsState,
  )

  const { resource: machineStocksResource } = useResource({
    subject: "材料在庫情報の取得",
    fetch:
      arcadeCd && materialMachineIds
        ? () =>
            getMaterialOperationStocks(arcadeCd, {
              materialName: searchParams.materialName,
              materialNameKana: searchParams.materialNameKana,
              materialCd: searchParams.materialCd,
              materialMachineIds,
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialMachineIds}:${JSON.stringify(
      searchParams,
    )}`,
  })

  const filteredStocks: (MaterialOperationMachineStock & {
    material: Material
  })[] = useMemo(() => {
    const stocks = machineStocksResource?.data.stocks || []
    return sortPlacementStocks(
      stocks.flatMap((s) =>
        s.machineStocks.map((machineStock) => ({
          material: s.material,
          ...machineStock,
        })),
      ),
      searchParams,
    )
  }, [machineStocksResource, searchParams])

  const menuItems = useMemo(
    () =>
      filteredStocks
        ?.filter((machineStock) =>
          hideEmptyMaterial ? machineStock.machineStock.stock > 0 : true,
        )
        .map((machineStock) => {
          const {
            machineStock: { materialCd, stock },
            material: { materialName },
          } = machineStock
          return {
            material: {
              materialCd,
              materialName,
            },
            stock,
          }
        }) ?? [],
    [filteredStocks, hideEmptyMaterial],
  )

  // 重複した景品がある場合、個数をまとめて1件として表示する
  const summarizedMenuItems = menuItems.reduce(
    (acc, current) => {
      if (
        acc.every((v) => v.material.materialCd !== current.material.materialCd)
      ) {
        return [...acc, current]
      }
      return acc.map((v) =>
        v.material.materialCd === current.material.materialCd
          ? { ...v, stock: v.stock + current.stock }
          : v,
      )
    },
    [] as typeof menuItems,
  )

  return (
    <InventoryMaterialPlacementMaterialsMenu
      menuItems={summarizedMenuItems}
      searchParams={createSearchParams({
        machineIds: String(materialMachineIds),
      })}
    />
  )
}
