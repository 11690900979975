/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetInventoryCsvExportResponse,
  GetInventoryCsvOutputParams,
  GetInventoryCsvOutputResponse,
  PostInventoryCsvImportRequest,
  PostInventoryCsvImportSeamsResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚卸循環オーダー取り込み
 */
export const postInventoryCsvImport = (
  arcadeCd: string,
  executionPeriodId: number,
  postInventoryCsvImportRequest: PostInventoryCsvImportRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/inventory/csv/import/${executionPeriodId}`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postInventoryCsvImportRequest,
    },
    options,
  )
}
/**
 * @summary 棚卸循環オーダー取り込み
 */
export const postInventoryCsvImportSeams = (
  arcadeCd: string,
  executionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PostInventoryCsvImportSeamsResponse>(
    {
      url: `/arcades/${arcadeCd}/inventory/csv/import/${executionPeriodId}/seams`,
      method: "POST",
    },
    options,
  )
}
/**
 * @summary 棚卸循環オーダー取り込み内容確認
 */
export const getInventoryCsvExport = (
  arcadeCd: string,
  executionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetInventoryCsvExportResponse>(
    {
      url: `/arcades/${arcadeCd}/inventory/csv/export/${executionPeriodId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 棚卸結果 CSV 取得
 */
export const getInventoryCsvOutput = (
  arcadeCd: string,
  executionPeriodId: number,
  params?: GetInventoryCsvOutputParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetInventoryCsvOutputResponse>(
    {
      url: `/arcades/${arcadeCd}/inventory/csv/output/${executionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
export type PostInventoryCsvImportResult = NonNullable<
  Awaited<ReturnType<typeof postInventoryCsvImport>>
>
export type PostInventoryCsvImportSeamsResult = NonNullable<
  Awaited<ReturnType<typeof postInventoryCsvImportSeams>>
>
export type GetInventoryCsvExportResult = NonNullable<
  Awaited<ReturnType<typeof getInventoryCsvExport>>
>
export type GetInventoryCsvOutputResult = NonNullable<
  Awaited<ReturnType<typeof getInventoryCsvOutput>>
>
