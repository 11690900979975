/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeDailyPlansParams,
  GetPrizeDailyPlansResponse,
  PostPrizeDailyPlansRequest,
  PutPrizeMonthlyPlanRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary マンスリー入替計画の作成・更新
 */
export const putPrizeMonthlyPlan = (
  arcadeCd: string,
  prizeDeliveryId: number,
  putPrizeMonthlyPlanRequest: PutPrizeMonthlyPlanRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/deliveries/${prizeDeliveryId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeMonthlyPlanRequest,
    },
    options,
  )
}
/**
 * @summary 日毎の入替計画の取得
 */
export const getPrizeDailyPlans = (
  arcadeCd: string,
  params: GetPrizeDailyPlansParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeDailyPlansResponse>(
    { url: `/arcades/${arcadeCd}/prizes/plans/daily`, method: "GET", params },
    options,
  )
}
/**
 * @summary 日毎の入替計画を一括変更
 */
export const postPrizeDailyPlans = (
  arcadeCd: string,
  postPrizeDailyPlansRequest: PostPrizeDailyPlansRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/plans/daily`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPrizeDailyPlansRequest,
    },
    options,
  )
}
/**
 * @summary 入替オペレーションの実行
 */
export const replacePrize = (
  arcadeCd: string,
  prizeDailyPlanId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/plans/daily/${prizeDailyPlanId}`,
      method: "PATCH",
    },
    options,
  )
}
export type PutPrizeMonthlyPlanResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeMonthlyPlan>>
>
export type GetPrizeDailyPlansResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeDailyPlans>>
>
export type PostPrizeDailyPlansResult = NonNullable<
  Awaited<ReturnType<typeof postPrizeDailyPlans>>
>
export type ReplacePrizeResult = NonNullable<
  Awaited<ReturnType<typeof replacePrize>>
>
