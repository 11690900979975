import dayjs from "dayjs"

/**
 * 見込みPDPB
 * = 見込み売上 / (週末までの日数 * 設定したブース数)
 */
export const getExpectedPrizePdpb = ({
  // ブース数
  numOfBooths,
  machineInputDate,
  expectedSalesPrice,
}: {
  numOfBooths: number
  machineInputDate: string
  expectedSalesPrice: number
}): number => {
  const dayjsMachineInputDate = dayjs(machineInputDate)

  // GiGONAVIの週末までのカウント仕様に合わせるために+2
  const countDaysToEndOfWeek =
    dayjsMachineInputDate.endOf("week").diff(dayjsMachineInputDate, "day") + 2

  return expectedSalesPrice / (countDaysToEndOfWeek * numOfBooths)
}

/**
 * 見込み売上
 * = 1個獲得金額 * ( 着荷予定景品の入荷数 * 初週消化率 )
 */
export const getExpectedSalesPrice = ({
  // 一個獲得金額
  expectedCapturePrice,
  // 初週消化率
  expectedConsumptionRate,
  orderCarton,
  unitPerCarton,
}: {
  expectedCapturePrice: number
  expectedConsumptionRate: number
  orderCarton: number
  unitPerCarton: number
}): number => {
  return (
    expectedCapturePrice *
    (orderCarton * unitPerCarton * expectedConsumptionRate)
  )
}
