import React, {
  useState,
  useMemo,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react"

import { Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Grid, Typography, Card, Divider, Chip, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  FeatureExecutionPeriod,
  PrizeInventoryDifference,
  PrizeInventoryDifferenceGroupEnum,
} from "src/api/models"
import { getPrizeInventoryDifference } from "src/api/prize-inventory-differences"
import { getPrizeInventoryExecute } from "src/api/prize-inventory-execute"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { InventoryPrizeApplyDifferenceModal } from "src/components/organisms/prizes/InventoryPrizeApplyDifferenceModal"
import { InventoryPrizeApproveDifferenceModal } from "src/components/organisms/prizes/InventoryPrizeApproveDifferenceModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { getPrizeDifferenceLabel } from "src/domains/prizes/inventoryExecuteRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { currentInventoryPeriodState } from "src/recoil"

export const InventoryPrizeExecuteDifferenceDetails: React.FC = () => {
  const { arcadeCd, prizeCd } = useParams()
  const {
    isAvailableApplyInventoryDifference,
    isAvailableApproveInventoryDifference,
  } = useUserRole()

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const { resource: differencesResource, refetch: differencesRefetch } =
    useResource({
      subject: "差分一覧の取得",
      fetch:
        arcadeCd && currentPrizeExecutionPeriod
          ? () =>
              getPrizeInventoryDifference(
                arcadeCd,
                currentPrizeExecutionPeriod.id,
              )
          : undefined,
      recoilKey: `getInventoryDifference:${arcadeCd}:${JSON.stringify(
        currentPrizeExecutionPeriod,
      )}`,
    })
  const difference = differencesResource?.data.differences?.find(
    (d: PrizeInventoryDifference) => d.prizeCd === prizeCd,
  )

  const isEnableApply =
    !difference?.isApproved && isAvailableApplyInventoryDifference
  const isEnableApprove =
    !difference?.isApproved && (difference?.applicationId || 0) > 0
  const ref = useRef<ApprovalHandler>(null)

  return (
    <MainContentLayout
      title={difference?.prizeName || ""}
      renderAction={() =>
        difference?.group === PrizeInventoryDifferenceGroupEnum.DiffPriceOver &&
        isAvailableApproveInventoryDifference && (
          <LoadingButton
            variant="contained"
            disabled={!isEnableApprove}
            onClick={() => ref.current?.approve()}
          >
            承認する
          </LoadingButton>
        )
      }
      renderContent={() => (
        <InventoryPrizeExecuteDifferenceDetailsMenu
          ref={ref}
          difference={difference}
          refetchDifferences={differencesRefetch}
          currentPrizeExecutionPeriod={currentPrizeExecutionPeriod}
          isEnableApply={isEnableApply}
          isEnableApprove={isEnableApprove}
        />
      )}
    />
  )
}

interface ApprovalHandler {
  approve: () => void
}

type InventoryPrizeExecuteDifferenceDetailsMenuProps = {
  difference: PrizeInventoryDifference | undefined
  refetchDifferences: () => Promise<void>
  currentPrizeExecutionPeriod: FeatureExecutionPeriod | undefined
  isEnableApply: boolean
  isEnableApprove: boolean
}

const InventoryPrizeExecuteDifferenceDetailsMenu = forwardRef<
  ApprovalHandler,
  InventoryPrizeExecuteDifferenceDetailsMenuProps
>(
  (
    {
      difference,
      refetchDifferences: differencesRefetch,
      currentPrizeExecutionPeriod,
      isEnableApply,
      isEnableApprove,
    },
    ref,
  ) => {
    const { arcadeCd, prizeCd } = useParams()

    const { resource: historiesResource } = useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentPrizeExecutionPeriod
          ? () =>
              getPrizeInventoryExecute(
                arcadeCd,
                currentPrizeExecutionPeriod.id,
                {
                  prizeCd,
                },
              )
          : undefined,
      recoilKey: `getInventoryExecute:${arcadeCd}:${JSON.stringify(
        currentPrizeExecutionPeriod,
      )}:${prizeCd}`,
    })
    const histories = historiesResource?.data.histories

    const [showApplyModal, setShowApplyModal] = useState<boolean>(false)
    const onClickApplyApproval = () => {
      if (isEnableApply) setShowApplyModal(true)
    }
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false)
    const onClickApprove = () => {
      if (isEnableApprove) setShowApproveModal(true)
    }

    const stackRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(
      ref,
      () => ({
        approve() {
          stackRef.current?.click()
        },
      }),
      [],
    )

    const rows = useMemo(
      () =>
        histories
          ? histories.map((history) => {
              let name
              const count = history.stock
              const { Storage, InBooth, OnBooth } = PlacementType

              switch (history.placement) {
                case Storage:
                  name = `${history.shelfStock?.storageName} ${history.shelfStock?.shelfName}`
                  break
                case InBooth:
                  name = history.boothStock?.boothShelfName
                  break
                case OnBooth:
                  name = history.onBoothStock?.onBoothShelfName
                  break
                default:
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
                  const _never: never = history.placement
              }

              return (
                <Grid container item xs={12} key={name}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <p>{name}</p>
                    </Typography>
                    {history.placement === OnBooth && (
                      <Chip label="プライズ機上" size="small" />
                    )}
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography variant="body2">
                      <strong>{count}</strong> 個
                    </Typography>
                  </Grid>
                </Grid>
              )
            })
          : [],
      [histories],
    )

    const display = getPrizeDifferenceLabel(difference)
    return (
      <>
        <Stack gap={2}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {difference?.group ===
              PrizeInventoryDifferenceGroupEnum.DiffPriceOver && (
              <Stack sx={{ flexDirection: "row", mr: 3 }}>
                <Typography sx={{ mr: 1 }} variant="caption">
                  ステータス
                </Typography>
                <Typography variant="subtitle2" color="error.main">
                  {display.isApproved}
                </Typography>
              </Stack>
            )}
            <Stack sx={{ flexDirection: "row", mr: 3 }}>
              <Typography sx={{ mr: 1 }} variant="caption">
                景品CD(GiGO NAVI)
              </Typography>
              <Typography variant="subtitle2">{display.prizeCd}</Typography>
            </Stack>
            <Stack sx={{ flexDirection: "row", mr: 3 }}>
              <Typography sx={{ mr: 1 }} variant="caption">
                景品CD(SEAMS)
              </Typography>
              <Typography variant="subtitle2">
                {display.prizeCdsInSeams}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            {prizeCd && (
              <PrizeImageBox
                prizeCd={prizeCd}
                alt={difference?.prizeName}
                noImageSize="large"
              />
            )}
          </Stack>

          <Card sx={{ p: 2 }}>
            <Grid container spacing={3}>
              {difference?.group ===
                PrizeInventoryDifferenceGroupEnum.DiffPriceOver && (
                <>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      戻しが10万円を超えた理由
                    </Typography>
                  </Grid>
                  <Grid item xs={9} textAlign="left">
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        spacing: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "pre-wrap",
                          ...(display.application === "未入力" && {
                            color: "error.main",
                          }),
                        }}
                      >
                        {display.application}
                      </Typography>
                      {isEnableApply && (
                        <Edit
                          sx={(theme) => ({
                            color: theme.palette.icon.blue,
                            mx: 1,
                            ...(isEnableApply && {
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }),
                          })}
                          fontSize="small"
                          onClick={() => onClickApplyApproval()}
                        />
                      )}
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  データ個数(SEAMS)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.importedStock} 個
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  払い出し個数
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.payoutStock} 個
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  今回消費した金額
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.consumedPrice} 円
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  景品の有無(SEAMS)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">{display.isInSeams}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  景品の有無(GiGO NAVI)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">{display.isInGigoNavi}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  SEAMSとの差額
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.consumedPrice} 円
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  今回実査した個数
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="body2">
                  合計 <strong>{display.stock}</strong> 個
                </Typography>
              </Grid>

              {rows.length > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              {rows}
            </Grid>
          </Card>
        </Stack>

        {difference?.group ===
          PrizeInventoryDifferenceGroupEnum.DiffPriceOver && (
          <>
            <InventoryPrizeApplyDifferenceModal
              showModal={showApplyModal}
              difference={difference}
              prizeExecutionPeriodId={currentPrizeExecutionPeriod?.id || 0}
              onClose={() => setShowApplyModal(false)}
              onFinish={() => differencesRefetch()}
            />
            <InventoryPrizeApproveDifferenceModal
              showModal={showApproveModal}
              difference={difference}
              prizeExecutionPeriodId={currentPrizeExecutionPeriod?.id || 0}
              onClose={() => setShowApproveModal(false)}
              onFinish={() => differencesRefetch()}
            />
          </>
        )}

        <Stack onClick={onClickApprove} ref={stackRef} hidden />
      </>
    )
  },
)
InventoryPrizeExecuteDifferenceDetailsMenu.displayName =
  "InventoryPrizeExecuteDifferenceDetailsMenu"
