import { TextField, MenuItem, Select } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { InventoryPrizeExecuteDetailsMenuItem } from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsMenu"
import { inventoryExecuteDetailsFilterSortByLabels } from "src/domains/prizes/inventoryExecuteRepository"

export const inventoryExecuteDetailsSearchParamsState =
  atom<InventoryExecuteDetailsSearchParams>({
    key: "inventoryExecuteDetailsSearchParamsState",
    default: {
      prizeExecutionStatus: "notExecuted",
    },
  })

export const prizeExecutionStatus = {
  all: "実査済み＋未実査",
  executed: "実査済み",
  notExecuted: "未実査",
} as const

export type InventoryExecuteDetailsSearchParams = {
  prizeCd?: Prize["prizeCd"]
  prizeNameKana?: string
  prizeName?: Prize["prizeName"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
  sortBy?: keyof typeof inventoryExecuteDetailsFilterSortByLabels
  prizeExecutionStatus: keyof typeof prizeExecutionStatus
}

export const InventoryPrizeExecuteDetailsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryExecuteDetailsSearchParamsState,
  )
  const { prizeCd, prizeName, prizeNameKana, makerName, ipName, sortBy } =
    searchParams
  const isPrizeExecutionStatusChanged =
    searchParams.prizeExecutionStatus !== "notExecuted"

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え・表示切替"
      defaultExpanded={
        !!(
          prizeCd ||
          prizeName ||
          prizeNameKana ||
          makerName ||
          ipName ||
          sortBy ||
          isPrizeExecutionStatusChanged
        )
      }
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => {
            return (
              <TextField
                {...field}
                error={!!error}
                inputProps={{ "aria-label": "prizeCd" }}
              />
            )
          },
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeName" }}
            />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeNameKana" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "makerName" }}
            />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "ipName" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{ "aria-label": "sortBy" }}
            >
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(inventoryExecuteDetailsFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
        {
          name: "prizeExecutionStatus",
          label: "棚卸状態",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{
                "aria-label": "prizeExecutionStatus",
              }}
            >
              {Object.entries(prizeExecutionStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterInventoryPrizeExecuteDetailsMenuItems = (
  menuItems: InventoryPrizeExecuteDetailsMenuItem[],
  params: InventoryExecuteDetailsSearchParams,
) => {
  const { prizeExecutionStatus } = params

  return menuItems.filter((item) => {
    if (prizeExecutionStatus === "executed") {
      return item.executed
    }
    if (prizeExecutionStatus === "notExecuted") {
      return !item.executed
    }
    return true
  })
}
