import { useMemo } from "react"

import { alpha, Box } from "@mui/material"

import { PrizeFloorMapPoint } from "src/api/models"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
  FloorMapPointNameBox,
} from "src/components/organisms/FloorMapBox"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import {
  convertToFloorMapPoint,
  getGroupInitialName,
  getInventoryGroupByPlacementType,
  getInventoryGroupColorByPlacementType,
  InventoryFloorMapColor,
  isFloorMapPointAvailable,
  PrizeInventoryFloorMapPoint,
} from "src/domains/prizes/inventoryFloorMapRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { getRectCoords } from "src/hooks/useFloorMap"
import { theme } from "src/theme"

type InventoryGroupBoothFloorMapProps = {
  onClickPoint: (point: BaseFloorMapPoint) => void
  selectedPointIds: PrizeFloorMapPoint["id"][]
  placementType: Exclude<PlacementType, "storage">
  floorMapPoints: PrizeFloorMapPoint[]
  floorMapColors: InventoryFloorMapColor[]
}

export const InventoryPrizeGroupBoothFloorMap: React.FC<
  InventoryGroupBoothFloorMapProps
> = ({
  onClickPoint,
  selectedPointIds,
  placementType,
  floorMapPoints,
  floorMapColors,
}) => {
  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <InventoryPrizeGroupBoothFloorMapPointBox
      key={point.id}
      {...{
        point,
        onClickPoint,
        floorMapPoints,
        floorMapColors,
        placementType,
        selectedPointIds,
      }}
    />
  )

  return (
    <FloorMapBox
      {...{
        floorMapPoints: floorMapPoints.map((p) => convertToFloorMapPoint(p)),
        onClickPoint,
        getFloorMapPointBox,
      }}
    />
  )
}

interface InventoryGroupBoothFloorMapPointBoxProps
  extends FloorMapPointBoxProps {
  floorMapPoints: PrizeFloorMapPoint[]
  floorMapColors: InventoryFloorMapColor[]
  placementType: Exclude<PlacementType, "storage">
  selectedPointIds: number[]
}

export const InventoryPrizeGroupBoothFloorMapPointBox: React.FC<
  InventoryGroupBoothFloorMapPointBoxProps
> = ({
  point,
  onClickPoint = () => undefined,
  floorMapPoints,
  floorMapColors,
  placementType,
  selectedPointIds,
}: InventoryGroupBoothFloorMapPointBoxProps) => {
  // NOTE: 棚卸ツール以外と共用の FloorMapPoint 型は棚情報を持っていないので、state から取得
  const { id: groupId, groupName } =
    getInventoryGroupByPlacementType(
      point as PrizeInventoryFloorMapPoint,
      placementType,
    ) ?? {}
  const color =
    groupId &&
    getInventoryGroupColorByPlacementType({
      floorMapPoints,
      floorMapColors,
      groupId,
      placementType,
    })

  const isInBooth = placementType === PlacementType.InBooth
  const isAvailable = useMemo(
    () =>
      isFloorMapPointAvailable(isInBooth, point as PrizeInventoryFloorMapPoint),
    [isInBooth, point],
  )

  const backgroundColor = useMemo(() => {
    if (!isAvailable) {
      return alpha(theme.palette.text.disabled, 0.7)
    }
    if (selectedPointIds.includes(point.id)) {
      return alpha(theme.palette.neutral[500], 0.3)
    }
    return "transparent"
  }, [point.id, selectedPointIds, isAvailable])

  const { width, height } = getRectCoords(point)

  return (
    <FloorMapPointBox
      {...{
        point,
        onClickPoint: isAvailable ? onClickPoint : () => undefined,
      }}
      sx={{
        backgroundColor,
        cursor: isAvailable ? "pointer" : "default",
      }}
    >
      {groupName && color && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color,
            fontSize: Math.min(width, height),
            lineHeight: 1,
            fontWeight: "bold",
            opacity: 0.7,
          }}
        >
          {getGroupInitialName(groupName)}
        </Box>
      )}
      <FloorMapPointNameBox {...{ point }} sx={{ position: "absolute" }} />
    </FloorMapPointBox>
  )
}
