import { AlertColor } from "@mui/material"

export type IdToken = string
export type CognitoSub = string
export interface LoginTokens {
  idToken: IdToken
  cognitoSub: CognitoSub
}

export enum ChallengeType {
  NewPasswordRequired = "NEW_PASSWORD_REQUIRED",
}

export interface SnackbarMessage {
  severity: AlertColor
  content: string
}

export type PickPartial<
  T,
  K extends keyof T,
  G extends Exclude<keyof T, K>,
> = Required<Pick<T, K>> & Partial<Pick<T, G>>

/**
 * ```ts
 * typeof dateLabel.match(/^\d{4}-\d{2}-\d{2}$/)
 * ```
 */
export type DateLabelString = string
