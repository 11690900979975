import { useMemo, useState } from "react"

import { LoadingButton } from "@mui/lab"
import { Typography, Stack, Card, CardHeader, CardContent } from "@mui/material"
import { useParams } from "react-router-dom"

import {
  CsvRow,
  RequestMeterReadInit,
  RequestMeterReadInitPayoutCategoryEnum,
} from "src/api/models"
import { getPrizeBooths } from "src/api/prize-booths"
import { initPrizeMeterReads } from "src/api/prize-sales"
import { CsvTable } from "src/components/organisms/CsvTable"
import { FileDropzone } from "src/components/organisms/FileDropzone"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { meterReadTemplateHeaders } from "src/domains/prizes/prizeSalesRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUploadCsvFiles } from "src/hooks/useUploadCsvFiles"
import { theme } from "src/theme"

enum columnIndex {
  boothName = 0,
  thincaTerminalNumber,
  isBroken,
  payoutCategory,
  assumedPayoutRate,
  yen100CoinCount,
  yen500CoinCount,
  payout,
  error,
}

enum columnPosCsvIndex {
  boothNumber = 0,
  boothName,
  amMachineNumber,
  amMachineName,
  thincaTerminalName,
  thincaTerminalNumber,
  areaName,
  arcadeName,
  updatedDate,
}

const defaultColumns = ["no", "Pアウトメータ", "0%", "", "", ""]

const validateCsv = ({ columns }: CsvRow, boothNames: string[]) => {
  const errors = []
  if (columns.length != columnIndex.error) {
    errors.push("CSVファイルが不正です")
  }
  if (!columns[columnIndex.boothName]) {
    errors.push("プライズ機種名が空白です")
  } else if (!boothNames.includes(columns[columnIndex.boothName])) {
    errors.push("存在しないプライズ機種名です")
  }
  if (
    !(
      columns[columnIndex.isBroken] === "yes" ||
      columns[columnIndex.isBroken] === "no"
    )
  ) {
    errors.push("故障かどうかは「yes」もしくは「no」を入力して下さい")
  }
  if (
    !(
      columns[columnIndex.payoutCategory] === "Pアウトメータ" ||
      columns[columnIndex.payoutCategory] === "見なしP/O"
    )
  ) {
    errors.push(
      "P/O管理方法は「Pアウトメータ」もしくは「見なしP/O」を入力して下さい",
    )
  }
  if (columns[columnIndex.payoutCategory] === "見なしP/O") {
    if (!columns[columnIndex.assumedPayoutRate]) {
      errors.push(
        "P/O管理方法が「見なしP/O」の場合、見なしP/Oの値は必須です。%表記で入力して下さい",
      )
    } else {
      const parts = /([0-9]+)(%|％)/.exec(
        columns[columnIndex.assumedPayoutRate],
      )
      if (parts) {
        const v = Number(parts[1])
        if (isNaN(v)) {
          errors.push("見なしP/Oの値が不正です。%表記で入力して下さい")
        }
        if (v < 10 || v > 100) {
          errors.push("見なしP/Oの値は10%以上100%以下で入力して下さい")
        }
      } else {
        errors.push("見なしP/Oの値が不正です。%表記で入力して下さい")
      }
    }
  }
  if (columns[columnIndex.thincaTerminalNumber] === "") {
    if (columns[columnIndex.yen100CoinCount] === "") {
      errors.push("100円コイン枚数初期値の値は必須です")
    } else {
      const value = Number(columns[columnIndex.yen100CoinCount])
      if (isNaN(value)) {
        errors.push("100円コイン枚数初期値の値が不正です。整数で入力して下さい")
      }
      if (value < 0) {
        errors.push("100円コイン枚数初期値の値は0以上で入力して下さい")
      }
    }
    if (columns[columnIndex.yen500CoinCount] === "") {
      errors.push("500円コイン枚数初期値の値は必須です")
    } else {
      const value = Number(columns[columnIndex.yen500CoinCount])
      if (isNaN(value)) {
        errors.push("500円コイン枚数初期値の値が不正です。整数で入力して下さい")
      }
      if (value < 0) {
        errors.push("500円コイン枚数初期値の値は0以上で入力して下さい")
      }
    }
  }
  if (columns[columnIndex.payoutCategory] === "Pアウトメータ") {
    if (columns[columnIndex.thincaTerminalNumber] === "") {
      if (columns[columnIndex.payout] === "") {
        errors.push("プライズ初期値の値は必須です")
      } else {
        const value = Number(columns[columnIndex.payout])
        if (isNaN(value)) {
          errors.push("プライズ初期値の値が不正です。整数で入力して下さい")
        }
        if (value < 0) {
          errors.push("プライズ初期値の値は0以上で入力して下さい")
        }
      }
    } else if (columns[columnIndex.payout] !== "") {
      const value = Number(columns[columnIndex.payout])
      if (isNaN(value)) {
        errors.push("プライズ初期値の値が不正です。整数で入力して下さい")
      }
      if (value < 0) {
        errors.push("プライズ初期値の値は0以上で入力して下さい")
      }
    }
  }
  return errors
}

export const PrizeMeterReadInit: React.FC = () => {
  const { arcadeCd } = useParams()
  const { resource: boothsResource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeBooths(arcadeCd) : undefined,
    recoilKey: `getPrizeBooths:${arcadeCd}`,
  })

  const {
    uploadedCsvs: posCsvs,
    onUploadCsvs: onUploadPosCsv,
    resetUploadedCsvs: resetUploadedPosCsv,
  } = useUploadCsvFiles({
    requiredColumns: [],
  })
  const {
    uploadedCsvs: meterReadCsvs,
    onUploadCsvs: onUploadMeterReadCsv,
    resetUploadedCsvs: resetUploadedMeterReadCsv,
  } = useUploadCsvFiles({
    requiredColumns: [],
  })
  const posCsv = posCsvs?.[0]
  const meterReadCsv = meterReadCsvs?.[0]
  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false)
  const { submitPromises } = useSubmitting()

  const booths = useMemo(() => {
    if (!boothsResource) return []
    return boothsResource.data.booths
  }, [boothsResource])

  const onPosCsvUploaded = async (files: File[]) => {
    await onUploadPosCsv(files)
    setIsDownloaded(false)
  }
  const onMeterReadCsvUploaded = async (files: File[]) => {
    await onUploadMeterReadCsv(files)
  }

  useMemo(() => {
    if (!posCsv) return
    if (isDownloaded) return
    const posTerminalNumbers = posCsv.rows.map(({ columns }) => ({
      amMachineNumber: columns[columnPosCsvIndex.amMachineNumber],
      posBoothName: columns[columnPosCsvIndex.boothName],
      thinaTerminalNumber: columns[columnPosCsvIndex.thincaTerminalNumber],
    }))
    const headerRow = {
      columns: meterReadTemplateHeaders,
    }
    const rows = booths.map(({ booth }) => {
      const unitBoothName = booth.boothName.split("-")[1]
      const thincaTerminalNumber =
        posTerminalNumbers.find(
          (v) =>
            v.amMachineNumber === booth.amMachineNumber &&
            v.posBoothName === unitBoothName,
        )?.thinaTerminalNumber || ""
      return {
        columns: [booth.boothName, thincaTerminalNumber, ...defaultColumns],
      }
    })
    downloadCsv({
      csv: { headerRow, rows },
      fileName: `meter_reads_template_${arcadeCd}.csv`,
    })
    setIsDownloaded(true)
  }, [posCsv, booths, downloadCsv, isDownloaded, setIsDownloaded, arcadeCd])

  const displayMeterReadCsv = useMemo(() => {
    if (!meterReadCsv) {
      return
    }
    return {
      headerRow: {
        columns: [...(meterReadCsv.headerRow?.columns || []), "エラー"],
      },
      rows: meterReadCsv.rows.map((row) => {
        const errors = validateCsv(
          row,
          booths.map((booth) => booth.booth.boothName),
        )
        return { columns: [...row.columns, errors.join("\n")] }
      }),
    }
  }, [meterReadCsv, booths])

  const onSubmit = async () => {
    if (!arcadeCd) return
    const request = {
      meters: (meterReadCsv?.rows || []).map<RequestMeterReadInit>(
        ({ columns }) => ({
          boothName: columns[columnIndex.boothName] || "",
          thincaTerminalNumber: columns[columnIndex.thincaTerminalNumber] || "",
          isBroken: columns[columnIndex.isBroken] === "yes",
          payoutCategory:
            columns[columnIndex.payoutCategory] === "見なしP/O"
              ? RequestMeterReadInitPayoutCategoryEnum.AssumedPayoutRate
              : RequestMeterReadInitPayoutCategoryEnum.PayoutOutMeter,
          assumedPayoutRate: columns[columnIndex.assumedPayoutRate]
            ? Number(
                columns[columnIndex.assumedPayoutRate].replace(/(%|％)/, ""),
              ) / 100
            : undefined,
          defaultYen100CoinCount:
            columns[columnIndex.yen100CoinCount] !== ""
              ? Number(columns[columnIndex.yen100CoinCount])
              : undefined,
          defaultYen500CoinCount:
            columns[columnIndex.yen500CoinCount] !== ""
              ? Number(columns[columnIndex.yen500CoinCount])
              : undefined,
          defaultPayout:
            columns[columnIndex.payout] !== ""
              ? Number(columns[columnIndex.payout])
              : undefined,
        }),
      ),
    }
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "メーターリード初期設定の実行",
        showSuccessMessage: true,
        promise: async () => {
          await initPrizeMeterReads(arcadeCd, request)
          resetUploadedPosCsv()
          resetUploadedMeterReadCsv()
          setIsSubmitting(false)
        },
      },
    ])
  }

  const hasError =
    (displayMeterReadCsv?.rows || []).filter(
      (row) => row.columns[columnIndex.error] !== "",
    ).length > 0

  const manualStyle = {
    paddingLeft: "1em",
    textIndent: "-1em",
    listStyleType: "none",
  }
  const manuals = [
    {
      message:
        "・AM機器番号、AM機器名、ブース名および端末識別番号は変更しないで下さい",
      subs: [],
    },
    {
      message:
        "・故障かどうかは「yes」もしくは「no」のいずれかを入力して下さい",
      subs: ["・空白の場合は、「no」と同じ扱いとなります"],
    },
    {
      message:
        "・P/O管理方法は「Pアウトメータ」もしくは「見なしP/O」のいずれかを入力して下さい",
      subs: [
        "・見なしP/Oは上限100%、下限10%で入力してください",
        "・P/O管理方法が「Pアウトメータ」の場合、見なしP/Oは無視されます",
      ],
    },
    {
      message:
        "・100円コイン枚数初期値、500円コイン枚数初期値は、整数の値で入力して下さい",
      subs: [
        "・シンカクラウド連携を行っているブースは、100円コイン枚数初期値、500円コイン枚数初期値は無視されます",
      ],
    },
    {
      message: "・プライズ初期値は、整数の値で入力して下さい",
      subs: [
        "・シンカクラウド連携を行っているブースは、空白のままにするか、プライズの値を上書きたい場合のみ入力して下さい",
      ],
    },
  ]

  return (
    <MainContentLayout
      title="プライズ売上情報の初期設定"
      renderContent={() => (
        <>
          <Stack gap={2}>
            <Card sx={{ p: 3, pb: 0 }}>
              <CardHeader
                title="1. 初期設定CSVのテンプレートファイルのダウンロード"
                sx={{ p: 0, pb: 2 }}
              />
              <CardContent sx={{ p: 0 }}>
                <Stack gap={2}>
                  <Stack sx={{ px: 2 }}>
                    <ul style={{ paddingLeft: "1em", listStyle: "number" }}>
                      <li>
                        <Typography variant="body2">
                          「端末売上管理システム」の「景品管理」メニューから「景品ブース確認」を選択し、CSVをダウンロードして下さい。
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          下記のボタンをクリックして、1でダウンロードしたCSVを選択すると、自動でプライズ売上情報の初期設定を行うためのCSVファイルのテンプレートがダウンロードされます。
                        </Typography>
                      </li>
                    </ul>
                  </Stack>
                  <FileDropzone onFiles={onPosCsvUploaded} />
                </Stack>
              </CardContent>
            </Card>
            <Card sx={{ p: 3, pb: 0 }}>
              <CardHeader
                title="2. プライズ売上情報の初期設定CSVのアップロード"
                sx={{ p: 0, pb: 2 }}
              />
              <CardContent sx={{ px: 0, pt: 0, pb: 4 }}>
                <Stack gap={2}>
                  <Typography variant="body2">
                    ダウンロードしたテンプレートに、必要な項目を入力してアップロードして下さい。
                    <br />
                    ※アップロードを実施した日付のプライズ売上情報が更新されます。日付の指定はできません。
                  </Typography>
                  <Typography variant="subtitle1">
                    各入力項目について
                  </Typography>
                  <Stack sx={{ px: 2 }}>
                    <ul style={manualStyle}>
                      {manuals.map((m, i) => (
                        <li key={"mr-init-manual-key-" + i}>
                          <Typography variant="body2">{m.message}</Typography>
                          {m.subs.length > 0 && (
                            <ul style={manualStyle}>
                              {m.subs.map((m, i) => (
                                <li key={"mr-init-manual-sub-key-" + i}>
                                  <Typography variant="body2">{m}</Typography>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Stack>
                  {displayMeterReadCsv && (
                    <CsvTable
                      csv={displayMeterReadCsv}
                      limit={20}
                      borderedCell
                      overrideCellSxProps={[
                        {
                          index: columnIndex.error,
                          sx: {
                            minWidth: 450,
                            color: theme.palette.error.main,
                            whiteSpace: "pre-wrap",
                          },
                        },
                      ]}
                    />
                  )}
                  <FileDropzone onFiles={onMeterReadCsvUploaded} />
                </Stack>
              </CardContent>
            </Card>
          </Stack>
          <Stack sx={{ pb: 2, gap: 2 }}></Stack>

          <Stack>
            <LoadingButton
              type="button"
              variant="contained"
              disabled={!meterReadCsv || hasError}
              loading={isSubmitting}
              onClick={() => onSubmit()}
            >
              この内容で取り込む
            </LoadingButton>
          </Stack>
        </>
      )}
    />
  )
}
