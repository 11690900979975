import {
  Material,
  MaterialStockLedgerHistory,
  MaterialStockLedgerStorage,
  MaterialStockLedgerHistoryCategoryEnum,
} from "src/api/models"
import { compareByPhoneticOrder, getJpDateTimeLabel } from "src/utils"

export const ledgerTableItemNames = (shelfName: string) => ({
  recordedAt: ["日時"],
  // カートン
  stockInCt: ["IN", "(CT)"],
  // バラ
  stockInPcs: ["IN", "(PCS)"],
  stockOutCt: ["OUT", "(CT)"],
  stockOutPcs: ["OUT", "(PCS)"],
  stockCt: [`${shelfName}`, "残数", "(CT)"],
  stockPcs: [`${shelfName}`, "残数", "(PCS)"],
  moveFrom: ["移動元"],
  moveTo: ["移動先"],
  totalStocksCt: ["全保管場所", "合計残数", "(CT)"],
  totalStocksPcs: ["全保管場所", "合計残数", "(PCS)"],
  recordedBy: ["担当者"],
  category: ["区分"],
  note: ["備考"],
})

export const getCartonCount = (stock: number, unitPerCarton: number) => {
  const pcs = stock % unitPerCarton
  const ct = (stock - pcs) / unitPerCarton

  return { pcs, ct }
}

const ledgerCategoryByLabels = {
  [MaterialStockLedgerHistoryCategoryEnum.Back]: "戻し",
  [MaterialStockLedgerHistoryCategoryEnum.Execute]: "実査",
  [MaterialStockLedgerHistoryCategoryEnum.In]: "入庫",
  [MaterialStockLedgerHistoryCategoryEnum.Out]: "出庫",
  [MaterialStockLedgerHistoryCategoryEnum.To]: "投入",
} as const

export const getLedgerTableItem = (
  ledgerHistory: MaterialStockLedgerHistory,
  unitPerCarton: number,
) => {
  const { pcs: stockInPcs, ct: stockInCt } = getCartonCount(
    ledgerHistory.stockIn,
    unitPerCarton,
  )
  const { pcs: stockOutPcs, ct: stockOutCt } = getCartonCount(
    ledgerHistory.stockOut,
    unitPerCarton,
  )
  const { pcs: stockPcs, ct: stockCt } = getCartonCount(
    ledgerHistory.stock,
    unitPerCarton,
  )
  const { pcs: totalStocksPcs, ct: totalStocksCt } = getCartonCount(
    ledgerHistory.totalStocks,
    unitPerCarton,
  )

  return {
    recordedAt: getJpDateTimeLabel(ledgerHistory.recordedAt) || "-",
    stockInCt:
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.In ||
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.Back
        ? stockInCt
        : "-",
    stockInPcs:
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.In ||
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.Back
        ? stockInPcs
        : "-",
    stockOutCt:
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.Out ||
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.To
        ? stockOutCt
        : "-",
    stockOutPcs:
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.Out ||
      ledgerHistory.category === MaterialStockLedgerHistoryCategoryEnum.To
        ? stockOutPcs
        : "-",
    stockCt: stockCt,
    stockPcs: stockPcs,
    moveFrom: ledgerHistory.moveFrom || "-",
    moveTo: ledgerHistory.moveTo || "-",
    totalStocksCt:
      ledgerHistory.category !== MaterialStockLedgerHistoryCategoryEnum.Execute
        ? totalStocksCt
        : "-",
    totalStocksPcs:
      ledgerHistory.category !== MaterialStockLedgerHistoryCategoryEnum.Execute
        ? totalStocksPcs
        : "-",
    recordedBy: ledgerHistory.recordedBy?.name || "-",
    category: ledgerCategoryByLabels[ledgerHistory.category] || "-",
    note: ledgerHistory.note || "",
  }
}

export const materialStockLedgerSortByLabels = {
  recordedAtAsc: "記録日時新しい順",
  recordedAtDesc: "記録日時古い順",
} as const

export type MaterialStockLedgerMaterialsSearchParams = {
  materialCd?: string
  materialName?: string
  ipName?: string
  makerName?: string
  materialNameKana?: string
  sortBy?: keyof typeof materialStockLedgerMaterialsSortByLabels
}

export const materialStockLedgerMaterialsSortByLabels = {
  phoneticMaterialCdOrderAsc: "材料CD順",
  phoneticMaterialCdOrderDesc: "材料CD順（降順）",
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
} as const

export const sortMaterialStockLedgerMaterials = (
  materials: Material[],
  sortBy?: keyof typeof materialStockLedgerMaterialsSortByLabels,
) => {
  const getSortCompareBy = () => {
    if (sortBy === "phoneticMaterialCdOrderAsc") {
      return (a: Material, b: Material) =>
        compareByPhoneticOrder(a.materialCd, b.materialCd)
    }
    if (sortBy === "phoneticMaterialCdOrderDesc") {
      return (a: Material, b: Material) =>
        compareByPhoneticOrder(b.materialCd, a.materialCd)
    }
    if (sortBy === "phoneticOrderAsc") {
      return (a: Material, b: Material) =>
        compareByPhoneticOrder(a.materialName, b.materialName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: Material, b: Material) =>
        compareByPhoneticOrder(b.materialName, a.materialName)
    }
    return () => 1
  }

  return materials.sort(getSortCompareBy())
}

export const sortMaterialStockLedgerPlacements = (
  storages: MaterialStockLedgerStorage[],
  sortBy?: keyof typeof materialStockLedgerPlacementsSortByLabels,
) => {
  const getSortCompareBy = () => {
    if (sortBy === "totalRemainingCountAsc") {
      return (a: MaterialStockLedgerStorage, b: MaterialStockLedgerStorage) =>
        a.stock - b.stock
    }
    if (sortBy === "totalRemainingCountDesc") {
      return (a: MaterialStockLedgerStorage, b: MaterialStockLedgerStorage) =>
        b.stock - a.stock
    }
    return () => 1
  }

  return storages.sort(getSortCompareBy())
}

export const materialStockLedgerPlacementsSortByLabels = {
  totalRemainingCountAsc: "副帳票上の合計残数(多い順)",
  totalRemainingCountDesc: "副帳票上の合計残数(少ない順)",
} as const
