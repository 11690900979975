import {
  alpha,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material"
import { useSetRecoilState } from "recoil"

import { BackButton } from "src/components/atoms/BackButton"
import { QrReader } from "src/components/organisms/QrReader"
import { OnResultFunction } from "src/hooks/useQrReader"
import { snackbarErrorMessageState } from "src/recoil"

export type QrCodeReaderTemplateProps = {
  onFinish: (path: string) => void
  showBackButton?: boolean
}

export const QrCodeReaderTemplate: React.FC<QrCodeReaderTemplateProps> = ({
  onFinish,
  showBackButton = true,
}: QrCodeReaderTemplateProps) => {
  const { origin } = window
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const onResult: OnResultFunction = (result) => {
    const url = result?.getText()
    if (!url || !origin) return

    // NOTE: ドメイン名が一致する URL の場合のみ読み取る
    if (url.startsWith(origin)) {
      const path = url.replace(origin, "")
      onFinish(path)
    } else {
      setErrorMessage("利用できない二次元コードです")
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Typography sx={{ mb: 3 }} variant="h1">
          二次元コード読み取り
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            二次元コードをかざしてください。
            <br />
            ※カメラ利用を許可する必要があります
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                maxWidth: 480,
                m: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
              <QrReader
                constraints={{
                  facingMode: "environment",
                }}
                onResult={onResult}
                videoStyle={{ objectFit: "cover" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: `24px solid ${alpha("#000000", 0.5)}`,
                }}
              />
            </Box>
          </Grid>

          {showBackButton && (
            <Grid item xs={12}>
              <BackButton>戻る</BackButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  )
}
