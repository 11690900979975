import { useState, useMemo, Suspense } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  CircularProgress,
  Card,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  TextField,
  Divider,
  ListItemText,
  Checkbox,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import { getMaterialMachines } from "src/api/material-machines"
import {
  putMaterialOperationMoveBetweenMachines,
  putMaterialOperationMoveToMachine,
} from "src/api/material-operation-move"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { getMaterialStorages } from "src/api/material-storages"
import {
  AmMachine,
  Material,
  MaterialMachine,
  MaterialMachinesElement,
  MaterialMachineShelf,
  MaterialOperationMachineStock,
  MaterialOperationStock,
  PutMaterialOperationMoveBetweenMachinesRequest,
  MaterialMachineWarningEnum,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { InventoryMaterialAmMachineWarningCard } from "src/components/organisms/materials/InventoryMaterialAmMachineWarningCard"
import {
  getPlacementMoveFrom,
  MaterialStockMoveFrom,
  updateMaterialStockMoveFrom,
} from "src/components/templates/InventoryMaterialStockDetails"
import { sortAmMachines } from "src/domains/amMachinesRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { snackbarErrorMessageState } from "src/recoil"

const updateStock = (
  stocks: MaterialOperationStock[] | undefined,
  defaultStockMoveFrom: MaterialStockMoveFrom,
  machinesMoveTo: MaterialMachinesElement[],
) => {
  if (!stocks) {
    return {
      stockMoveFrom: defaultStockMoveFrom,
      machineStocks: [],
    }
  }

  const stockMoveFrom = updateMaterialStockMoveFrom(
    defaultStockMoveFrom,
    stocks,
  )

  const machineStocks = stocks.flatMap((stock) =>
    (stock.machineStocks || []).filter((stock) =>
      machinesMoveTo
        .map((machine) => machine.materialMachine.id)
        .includes(stock.materialMachine.id),
    ),
  )
  return { stockMoveFrom, machineStocks }
}

export interface MachineShelfMoveTo {
  materialMachine: MaterialMachinesElement
  machineShelf: MaterialMachineShelf
}

type MovePageState = "select" | "move" | "confirm"

interface InventoryMaterialMoveToMachineProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  onFinish: () => void
}

export const InventoryMaterialMoveToMachine: React.FC<
  InventoryMaterialMoveToMachineProps
> = (props) => {
  const { arcadeCd } = useParams()
  const { material, stockMoveFrom: defaultStockMoveFrom, onFinish } = props
  const [movePageState, setMovePageState] = useState<MovePageState>("select")
  const [machinesMoveTo, setMachinesMoveTo] = useState<
    MaterialMachinesElement[]
  >([])
  const [oldMachineStocks, setOldMachineStocks] = useState<
    MaterialOperationMachineStock[]
  >([])
  // TODO: 副帳票から取得するように変更する (要：API実装)
  const [note, setNote] = useState<string>("")

  const { materialCd } = material
  const { resource: resourceStocks, refetchForce: refetchStocks } = useResource(
    {
      subject: "在庫情報の取得",
      fetch: arcadeCd
        ? () => getMaterialOperationStocks(arcadeCd, { materialCd })
        : undefined,
      recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialCd}`,
      skip: movePageState === "select",
    },
  )
  const { stockMoveFrom, machineStocks } = useMemo(
    () =>
      updateStock(
        resourceStocks?.data.stocks,
        defaultStockMoveFrom,
        machinesMoveTo,
      ),
    [resourceStocks, defaultStockMoveFrom, machinesMoveTo],
  )

  const onSelected = (machinesMoveTo: MaterialMachinesElement[]) => {
    setMachinesMoveTo(machinesMoveTo)
    refetchStocks()
    setMovePageState("move")
  }
  const onMoved = ({ note }: { note: string }) => {
    setOldMachineStocks(machineStocks)
    refetchStocks()
    setNote(note)
    setMovePageState("confirm")
  }
  const reset = () => {
    setOldMachineStocks([])
    setMachinesMoveTo([])
  }

  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h2">
        {getPlacementMoveFrom(stockMoveFrom).name} から材料機械に移動
      </Typography>
      {movePageState === "select" && (
        <Typography variant="body2">
          移動先の材料機械を選択してください
        </Typography>
      )}

      <Box sx={{ mb: 2 }} />
      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        }
      >
        {movePageState === "select" && (
          <InventoryMaterialMoveToMachineSelectMenu
            stockMoveFrom={stockMoveFrom}
            onSelected={onSelected}
            onClose={() => onFinish()}
          />
        )}
        {movePageState === "move" && (
          <InventoryMaterialMoveToMachineInputMenu
            material={material}
            stockMoveFrom={stockMoveFrom}
            machinesMoveTo={machinesMoveTo}
            machineStocks={machineStocks}
            onMoved={onMoved}
            onClose={() => {
              reset()
              setMovePageState("select")
            }}
          />
        )}
        {movePageState === "confirm" && (
          <InventoryMaterialMoveToMachineConfirmMenu
            material={material}
            stockMoveFrom={stockMoveFrom}
            oldStockMoveFrom={defaultStockMoveFrom}
            machinesMoveTo={machinesMoveTo}
            machineStocks={machineStocks}
            oldMachineStocks={oldMachineStocks}
            note={note}
            onClose={() => {
              reset()
              onFinish()
            }}
          />
        )}
      </Suspense>
    </Box>
  )
}

export interface InventoryMaterialMoveToMachineSelectFormInput {
  amMachineNumber: AmMachine["amMachineNumber"]
  materialMachineIds: MaterialMachine["id"][]
}

interface InventoryMaterialMoveToMachineSelectMenuProps {
  stockMoveFrom: MaterialStockMoveFrom
  onSelected: (machinesMoveTo: MaterialMachinesElement[]) => void
  onClose: () => void
}

const InventoryMaterialMoveToMachineSelectMenu: React.FC<
  InventoryMaterialMoveToMachineSelectMenuProps
> = ({ stockMoveFrom, onSelected, onClose }) => {
  const { arcadeCd } = useParams()
  const { resource } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}`,
  })
  const materialMachines = resource?.data.materialMachines

  const amMachines = sortAmMachines(
    Array.from(
      new Map(
        (materialMachines || []).map((materialMachine) => [
          materialMachine.amMachine.amMachineNumber,
          materialMachine.amMachine,
        ]),
      ).values(),
    ),
    { sortBy: "phoneticOrderAsc" },
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    setValue,
  } = useForm<InventoryMaterialMoveToMachineSelectFormInput>()
  const value = {
    amMachineNumber: useWatch({ control, name: "amMachineNumber" }),
    materialMachineIds: useWatch({ control, name: "materialMachineIds" }),
  }

  const filteredMaterialMachines =
    materialMachines?.filter(
      (materialMachine) =>
        materialMachine.amMachine.amMachineNumber === value.amMachineNumber,
    ) || []
  const warningMaterialMachines =
    materialMachines?.filter(
      (materialMachine) =>
        materialMachine.materialMachine.warning !==
        MaterialMachineWarningEnum.None,
    ) || []

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const onSubmit: SubmitHandler<
    InventoryMaterialMoveToMachineSelectFormInput
  > = (data) => {
    const { materialMachineIds } = data
    const isMovable = materialMachineIds.includes(
      stockMoveFrom.operationMachineStock?.materialMachine.id || -1,
    )
    if (isMovable) {
      setErrorMessage("移動前と同じ材料機械には移動できません")
      return
    }
    if (materialMachineIds.length) {
      const machinesMoveTo =
        materialMachines?.filter((s) =>
          materialMachineIds.includes(s.materialMachine.id),
        ) || []
      onSelected(machinesMoveTo)
    }
  }

  const isMultipleSelect = stockMoveFrom.operationShelfStock ? true : false

  return (
    <Box sx={{ flexGrow: 1 }}>
      {warningMaterialMachines.length > 0 && (
        <InventoryMaterialAmMachineWarningCard
          materialMachines={warningMaterialMachines}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 2, mb: 3 }}>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              AM機器
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth error={"amMachineNumber" in errors}>
                <Controller
                  name="amMachineNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <SearchAutoComplete
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        resetField("materialMachineIds")
                      }}
                      items={amMachines.map((amMachine) => ({
                        label: amMachine.amMachineName,
                        value: amMachine.amMachineNumber,
                      }))}
                      error={!!fieldState.error}
                    />
                  )}
                />
                {errors.amMachineNumber?.message && (
                  <FormHelperText>
                    {errors.amMachineNumber?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {filteredMaterialMachines.length > 0 && (
              <>
                <Grid item xs={4} pt={1}>
                  シリンダー
                </Grid>
                <Grid item xs={8} pt={1}>
                  <FormControl fullWidth error={"materialMachineIds" in errors}>
                    <Controller
                      name="materialMachineIds"
                      control={control}
                      render={({ field }) => (
                        <Select
                          multiple={isMultipleSelect}
                          {...field}
                          value={field.value ?? []}
                          onChange={(e) =>
                            isMultipleSelect
                              ? field.onChange(e)
                              : // NOTE: 材料機械から戻しの場合は単一選択となり、e.target.value の値が配列ではなく数値となるため、強制的に配列にする
                                setValue("materialMachineIds", [
                                  Number(e.target.value),
                                ])
                          }
                          renderValue={() =>
                            filteredMaterialMachines
                              .filter((m) =>
                                field.value.includes(m.materialMachine.id),
                              )
                              .map((m) =>
                                getDisplayMaterialMachineName(
                                  m.amMachine,
                                  m.materialMachine,
                                  true,
                                ),
                              )
                              .join(", ")
                          }
                        >
                          {filteredMaterialMachines?.map((materialMachine) => (
                            <MenuItem
                              key={materialMachine.materialMachine.id}
                              value={materialMachine.materialMachine.id}
                            >
                              <Checkbox
                                checked={(field.value || []).includes(
                                  materialMachine.materialMachine.id,
                                )}
                              />
                              <ListItemText
                                primary={getDisplayMaterialMachineName(
                                  materialMachine.amMachine,
                                  materialMachine.materialMachine,
                                  true,
                                )}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.materialMachineIds?.message && (
                      <FormHelperText>
                        {errors.materialMachineIds?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Card>

        <Button
          variant="contained"
          type="submit"
          sx={{ mb: 3 }}
          fullWidth
          disabled={
            !value.amMachineNumber ||
            !value.materialMachineIds ||
            (value.materialMachineIds || []).length == 0
          }
        >
          選択した材料機械に移動する
        </Button>

        <BackButton onClick={onClose}>戻る</BackButton>
      </form>
    </Box>
  )
}

export interface InventoryMaterialMoveToMachineInputFormInputStock {
  materialMachineId: MaterialMachine["id"]
  stock: number
}

export interface InventoryMaterialMoveToMachineInputFormInput {
  stocks: InventoryMaterialMoveToMachineInputFormInputStock[]
  note?: string
}

interface InventoryMaterialMoveToMachineInputMenuProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  machinesMoveTo: MaterialMachinesElement[]
  machineStocks: MaterialOperationMachineStock[]
  onMoved: (v: { note: string }) => void
  onClose: () => void
}

const InventoryMaterialMoveToMachineInputMenu: React.FC<
  InventoryMaterialMoveToMachineInputMenuProps
> = ({
  material,
  stockMoveFrom,
  machinesMoveTo,
  machineStocks,
  onMoved,
  onClose,
}: InventoryMaterialMoveToMachineInputMenuProps) => {
  const { arcadeCd } = useParams()
  const { materialCd, unitPerCarton } = material

  const isFromShelf = !!stockMoveFrom.operationShelfStock
  const { resource: resourceStorages } = useResource({
    subject: "移動元保管場所情報の取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    skip: !isFromShelf,
    recoilKey: `getMaterialStorages:${arcadeCd}`,
  })
  const materialStorages = resourceStorages?.data.storages

  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  // TODO: 移動先の数が多いと処理が重くなる可能性があるので改善する
  const getDefaultStockMoveTo = (materialMachineId: number) =>
    machineStocks.find(
      (s) =>
        s.machineStock.materialCd === materialCd &&
        s.materialMachine.id === materialMachineId,
    )?.machineStock.stock || 0

  const validateMaxTotal = (
    stocks: InventoryMaterialMoveToMachineInputFormInputStock[],
  ) =>
    placementMoveFrom.stock >=
    stocks.reduce((sum, stock) => sum + Number(stock.stock || 0), 0)
  const validationSchema = Yup.object({
    stocks: Yup.array(
      Yup.object({
        materialMachineId: Yup.number().required(),
        stock: Yup.number()
          .required("必須です")
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください"),
      }),
    )
      .test({
        name: "maxTotal",
        exclusive: true,
        message: "移動元の在庫数を超えています",
        test: (stocks) => validateMaxTotal(stocks || []),
      })
      .required("必須です"),
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<InventoryMaterialMoveToMachineInputFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stocks: machinesMoveTo.map((machine) => {
        return {
          materialMachineId: machine.materialMachine.id,
        }
      }),
      note: "",
    },
  })
  const value = {
    stocks: useWatch({ control, name: "stocks" }) || [],
  }
  const totalMoveStock = value.stocks.reduce(
    (sum, stock) => sum + Number(stock.stock || 0),
    0,
  )

  const { submitPromises } = useSubmitting()

  const onSubmit: SubmitHandler<
    InventoryMaterialMoveToMachineInputFormInput
  > = async (data) => {
    const { operationShelfStock, operationMachineStock } = stockMoveFrom
    const putMove = (arcadeCd: string) => {
      const baseRequest = {
        material: { materialCd },
        note: data.note ?? "",
      }

      if (operationMachineStock) {
        const machine = machinesMoveTo[0]
        const request: PutMaterialOperationMoveBetweenMachinesRequest = {
          ...baseRequest,
          from: {
            machineShelfId: operationMachineStock.machineShelf.id,
            stock: totalMoveStock,
          },
          to: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            machineShelfId: machine!.materialMachine.machineShelves![0]!.id,
            stock:
              data.stocks.find(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (s) => s.materialMachineId === machine!.materialMachine.id,
              )?.stock || 0,
          },
        }
        return putMaterialOperationMoveBetweenMachines(arcadeCd, request)
      }
      if (operationShelfStock) {
        const materialStorage = materialStorages?.find(({ shelves }) =>
          shelves?.some((shelf) => shelf.id === operationShelfStock.shelf.id),
        )
        if (!materialStorage) return
        const request = {
          ...baseRequest,
          from: {
            shelfId: operationShelfStock.shelf.id,
            stock: totalMoveStock,
          },
          to: machinesMoveTo.map((machine) => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              machineShelfId: machine.materialMachine.machineShelves![0]!.id,
              stock:
                data.stocks.find(
                  (s) => s.materialMachineId === machine.materialMachine.id,
                )?.stock || 0,
            }
          }),
        }
        return putMaterialOperationMoveToMachine(arcadeCd, request)
      }
      return
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "棚への移動",
          showSuccessMessage: true,
          promise: async () => {
            await (putMove(arcadeCd) || new Promise((_, reject) => reject()))
            onMoved({ note: data.note ?? "" })
          },
        },
      ]))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 2, mb: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h2">{material.materialName}</Typography>
          </Grid>
          <Grid item xs={12} pb={1}>
            カートン入数：{unitPerCarton || 0} 個
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* 移動元 */}
          <Grid item xs={12}>
            {placementMoveFrom.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動させる数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{totalMoveStock}</strong> 個
          </Grid>
          {errors.stocks && (
            <>
              <Grid item xs={2} />
              <Grid item xs={10} sx={{ textAlign: "right" }}>
                <Typography variant="body2" color="error.main">
                  {errors.stocks?.message || ""}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動前
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong>
            {" 個"}
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動後
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock - totalMoveStock}</strong>
            {" 個"}
          </Grid>

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}
          {machinesMoveTo.map((machine, index) => {
            const stockErrors = (errors.stocks && errors.stocks[index]) || {}
            return (
              <>
                <Grid item xs={12}>
                  {getDisplayMaterialMachineName(
                    machine.amMachine,
                    machine.materialMachine,
                  )}
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    移動する数
                  </Typography>
                </Grid>

                <Grid item xs={3} sx={{ textAlign: "right" }}>
                  <Controller
                    control={control}
                    name={`stocks.${index}.stock`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        error={"stock" in stockErrors}
                        helperText={stockErrors.stock?.message || ""}
                        inputProps={{ inputMode: "numeric" }}
                        sx={{ textAlign: "right" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "right" }}>
                  個
                </Grid>

                <Grid item xs={0.5} />
                <Grid item xs={7.5}>
                  <Typography variant="body2" color="text.secondary">
                    移動前
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                  <strong>
                    {getDefaultStockMoveTo(machine.materialMachine.id)}
                  </strong>
                  {" 個"}
                </Grid>

                <Grid item xs={0.5} sx={{ pb: 2 }} />
                <Grid item xs={7.5} sx={{ pb: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    移動後
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
                  <strong>
                    {getDefaultStockMoveTo(machine.materialMachine.id) +
                      Number(value.stocks[index]?.stock || 0)}
                  </strong>
                  {" 個"}
                </Grid>
              </>
            )
          })}

          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              label="備考"
              fullWidth
              error={"note" in errors}
              helperText={errors.note?.message}
              {...register("note")}
            />
          </Grid>
        </Grid>
      </Card>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSubmitting}
        fullWidth
        sx={{ mb: 3, height: 44, lineHeight: 1.2, px: 0.5 }}
      >
        材料移動を保存
      </LoadingButton>

      <BackButton onClick={() => onClose()}>戻る</BackButton>
    </form>
  )
}

interface InventoryMaterialMoveToMachineConfirmMenuProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  oldStockMoveFrom: MaterialStockMoveFrom
  machinesMoveTo: MaterialMachinesElement[]
  machineStocks: MaterialOperationMachineStock[]
  oldMachineStocks: MaterialOperationMachineStock[]
  note: string
  onClose: () => void
}

const InventoryMaterialMoveToMachineConfirmMenu: React.FC<
  InventoryMaterialMoveToMachineConfirmMenuProps
> = ({
  material,
  stockMoveFrom,
  oldStockMoveFrom,
  machinesMoveTo,
  machineStocks,
  oldMachineStocks,
  note,
  onClose,
}: InventoryMaterialMoveToMachineConfirmMenuProps) => {
  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  const oldPlacementMoveFrom = getPlacementMoveFrom(oldStockMoveFrom)

  return (
    <>
      <AlertCaptionCard label="下記の通り移動しました" />
      <Card sx={{ p: 2, mb: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h2">{material.materialName}</Typography>
          </Grid>

          {/* 移動元 */}
          <Grid item xs={12}>
            {placementMoveFrom.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動した数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>
              {oldPlacementMoveFrom.stock - placementMoveFrom.stock}
            </strong>{" "}
            個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              元
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{oldPlacementMoveFrom.stock}</strong> 個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              現在
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong> 個
          </Grid>

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}
          {machinesMoveTo.map((machine) => {
            const oldMachineStock = oldMachineStocks.find(
              (stock) => stock.materialMachine.id == machine.materialMachine.id,
            )
            const machineStock = machineStocks.find(
              (stock) => stock.materialMachine.id == machine.materialMachine.id,
            )
            if (machineStock) {
              const oldMachineStockNum =
                oldMachineStock?.machineStock.stock || 0
              const machineStockNum = machineStock.machineStock.stock
              return (
                <>
                  <Grid item xs={12}>
                    {getDisplayMaterialMachineName(
                      machine.amMachine,
                      machine.materialMachine,
                    )}
                  </Grid>

                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      移動した数
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>{machineStockNum - oldMachineStockNum}</strong> 個
                  </Grid>

                  <Grid item xs={1} />
                  <Grid item xs={7}>
                    <Typography variant="body2" color="text.secondary">
                      元
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>{oldMachineStockNum}</strong> 個
                  </Grid>

                  <Grid item xs={1} sx={{ pb: 2 }} />
                  <Grid item xs={7} sx={{ pb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      現在
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
                    <strong>{machineStockNum}</strong> 個
                  </Grid>
                </>
              )
            } else {
              return
            }
          })}

          <Grid item xs={12} sx={{ mt: 2 }}>
            {note}
          </Grid>
        </Grid>
      </Card>

      <Button variant="outlined" fullWidth onClick={onClose} sx={{ mb: 3 }}>
        材料移動へ戻る
      </Button>

      <BackButton>検索画面へ戻る</BackButton>
    </>
  )
}
