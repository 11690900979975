import { Suspense, useMemo } from "react"

import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  TableHead,
  TableRow,
  alpha,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getAmMachines } from "src/api/am-machines"
import { AmMachineUsageCategoryEnum } from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { InventoryMaterialAmMachinesFilter } from "src/components/organisms/materials/InventoryMaterialAmMachinesFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { sortAmMachines } from "src/domains/amMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { inventoryMaterialAmMachinesSearchParamsState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export interface InventoryMaterialAmMachinesSearchParams {
  amMachineNumber?: string
  isAvailable?: boolean
  usageCategory?: AmMachineUsageCategoryEnum
  amMachineName?: string
}

export const InventoryMaterialAmMachines = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography variant="h1">AM機器一覧</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <InventoryMaterialAmMachinesFilter />
          </Grid>

          <Grid item xs={12}>
            <Suspense
              fallback={
                <CircularProgress sx={{ display: "block", margin: "auto" }} />
              }
            >
              <InventoryMaterialAmMachinesTable />
            </Suspense>
          </Grid>

          <Grid item xs={12}>
            <BackButton>戻る</BackButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const InventoryMaterialAmMachinesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const searchParams = useRecoilValue(
    inventoryMaterialAmMachinesSearchParamsState,
  )
  const { resource: AmMachinesResource } = useResource({
    subject: "AM機器リストの取得",
    fetch: arcadeCd
      ? () =>
          getAmMachines(arcadeCd, {
            amMachineNumber: searchParams.amMachineNumber,
            amMachineName: searchParams.amMachineName,
            isAvailable: searchParams.isAvailable,
            usageCategory: searchParams.usageCategory,
          })
      : undefined,
    recoilKey: `getAmMachines:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const amMachines = useMemo(
    () =>
      sortAmMachines(AmMachinesResource?.data.amMachines || [], {
        sortBy: "phoneticOrderAsc",
      }),
    [AmMachinesResource],
  )

  return (
    <Stack sx={{ maxHeight: "80dvh" }}>
      <PaginatedTable
        noMargin
        scrollableX
        scrollableY
        stickyHeader
        items={amMachines}
        stateKey="inventoryAmMachinesTable"
        header={
          <TableHead>
            <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
              <ExtTableCell>AM機器番号</ExtTableCell>
              <ExtTableCell>
                有効/
                <br />
                無効
              </ExtTableCell>
              <ExtTableCell>区分</ExtTableCell>
              <ExtTableCell>名称</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(amMachine) => {
          const { amMachineNumber, amMachineName, usageCategory, isAvailable } =
            amMachine

          return (
            <TableBorderedRow
              key={amMachineNumber}
              data-testid={amMachineNumber}
              sx={{
                ...(!isAvailable && {
                  td: { background: alpha(theme.palette.text.disabled, 0.3) },
                }),
              }}
              onClick={() =>
                isAvailable &&
                navigate(
                  `/arcades/${arcadeCd}/materials/settings/amMachines/${amMachineNumber}`,
                )
              }
            >
              <ExtTableCell
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: (theme) => theme.palette.neutral[200],
                  },
                  p: 1,
                }}
              >
                {amMachineNumber}
              </ExtTableCell>
              <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                {isAvailable ? (
                  <Typography color="primary.main" p={1} variant="subtitle1">
                    有効
                  </Typography>
                ) : (
                  <Typography color="text.disabled" p={1} variant="subtitle1">
                    無効
                  </Typography>
                )}
              </ExtTableCell>
              <ExtTableCell sx={{ p: 1 }}>
                {usageCategory === AmMachineUsageCategoryEnum.Material ? (
                  <strong style={{ color: theme.palette.error.main }}>
                    材料
                  </strong>
                ) : (
                  "その他"
                )}
              </ExtTableCell>
              <ExtTableCell
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: (theme) => theme.palette.neutral[200],
                  },
                  p: 1,
                }}
              >
                {amMachineName}
              </ExtTableCell>
            </TableBorderedRow>
          )
        }}
      />
    </Stack>
  )
}
