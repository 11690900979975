import { TextField, MenuItem, Select } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"

export const placementPrizesSearchParamsState =
  atom<PlacementPrizesSearchParams>({
    key: "placementPrizesSearchParamsState",
    default: {},
  })

export const placementPrizesFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
  phoneticIpOrderAsc: "IP順",
  phoneticIpOrderDesc: "IP順（降順）",
} as const

export type PlacementPrizesSearchParams = {
  prizeCd?: Prize["prizeCd"]
  prizeNameKana?: string
  prizeName?: Prize["prizeName"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
  sortBy?: keyof typeof placementPrizesFilterSortByLabels
}

export const InventoryPlacementPrizesFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    placementPrizesSearchParamsState,
  )
  const { prizeCd, prizeName, prizeNameKana, makerName, ipName, sortBy } =
    searchParams

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          prizeCd ||
          prizeName ||
          prizeNameKana ||
          makerName ||
          ipName ||
          sortBy
        )
      }
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => {
            return (
              <TextField
                {...field}
                error={!!error}
                inputProps={{ "aria-label": "prizeCd" }}
              />
            )
          },
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeName" }}
            />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeNameKana" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "makerName" }}
            />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "ipName" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{ "aria-label": "sortBy" }}
            >
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(placementPrizesFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
