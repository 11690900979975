import { Delete } from "@mui/icons-material"
import { Box, Typography, Chip, IconButton, Stack } from "@mui/material"

import { Prize } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  TableBorderedRow,
  CardItemNameBox,
} from "src/components/molecules/CardTableCells"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { getJpDateLabel } from "src/utils"

type InventoryPrizesTableRowProps = {
  prize: Pick<Prize, "prizeCd" | "prizeName">
  stock?: number
  onRowClick: React.MouseEventHandler<HTMLDivElement>
  executed?: boolean
  executedAt?: string
  isDeletable?: boolean
  onDeleteClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const InventoryPrizesTableRow: React.FC<
  InventoryPrizesTableRowProps
> = ({
  prize,
  stock,
  onRowClick,
  executed,
  executedAt,
  isDeletable,
  onDeleteClick,
}) => {
  return (
    <TableBorderedRow
      key={prize.prizeCd}
      data-testid={prize.prizeCd}
      onClick={onRowClick}
      sx={{
        cursor: "pointer",
        "&:hover": {
          background: (theme) => theme.palette.neutral[200],
        },
      }}
    >
      <ExtTableCell sx={{ p: 2, width: 76 }}>
        <Box width={64} height={64}>
          <PrizeImageBox prizeCd={prize.prizeCd} alt={prize.prizeName} />
        </Box>
      </ExtTableCell>
      <ExtTableCell
        sx={{
          p: 2,
          paddingLeft: 0,
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexGrow: 1,
              mb: 0.5,
            }}
          >
            <Typography
              variant="body2"
              component={CardItemNameBox}
              color="primary.main"
              sx={{ mr: 2 }}
            >
              {prize.prizeName}
            </Typography>
            <Typography
              variant="body2"
              component={Box}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {stock}個
            </Typography>
          </Box>
          <Typography color="text.secondary" variant="caption">
            {prize.prizeCd}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ml: 0.5,
              mt: 0.5,
            }}
          >
            {executed && (
              <Chip
                label="済"
                color="error"
                size="small"
                sx={{
                  px: 1,
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              />
            )}

            <Stack direction="row">
              {executedAt && executedAt !== "" && (
                <Typography variant="caption">
                  実査日: {getJpDateLabel(executedAt)}
                </Typography>
              )}
              {isDeletable && (
                <IconButton
                  color="primary"
                  data-testid="delete-button"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDeleteClick && onDeleteClick(e)
                  }}
                  sx={{ p: 0, pb: 0.5, pl: 0.5 }}
                >
                  <Delete />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Stack>
      </ExtTableCell>
    </TableBorderedRow>
  )
}
