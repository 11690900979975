import { useMemo, useRef, useState } from "react"

import { Print, Launch, SelectAll, Deselect } from "@mui/icons-material"
import {
  Box,
  Typography,
  Stack,
  Button,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { CheckCircle } from "src/components/atoms/CheckCircle"
import {
  FloorMapBox,
  FloorMapBoxHandler,
  FloorMapPointBoxProps,
  PrizeDailySalesFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import {
  PrizeDailyFloorMapFilter,
  PrizeDailyFloorMapFilterSearchParams,
  prizeDailyFloorMapFilterSearchParamsState,
} from "src/components/organisms/prizes/PrizeDailyFloorMapFilter"
import { PrizeDailyPrintInstruction } from "src/components/organisms/prizes/PrizeDailyPrintInstruction"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  convertPrizePlanFloorMapPoint,
  PrizePlanFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import { useResource } from "src/hooks/useResource"
import { getLocalStorageItem, setLocalStorageItem } from "src/storages"

export const PrizeDailyPrintSettings: React.FC = () => {
  const { arcadeCd } = useParams()

  const searchParams: PrizeDailyFloorMapFilterSearchParams = useRecoilValue(
    prizeDailyFloorMapFilterSearchParamsState,
  )

  const [pageSize, setPageSize] = useState<"a4" | "a3">(() =>
    getLocalStorageItem("printPageSize") === "a3" ? "a3" : "a4",
  )

  // 印刷用に選択中のポイント
  const [selectedPointIds, setSelectedPointIds] = useState<number[]>([])

  return (
    <MainContentLayout
      title="投入計画マップの印刷設定 ※PC推奨"
      renderFilter={() => (
        <>
          <PrizeDailyPrintInstruction sx={{ mb: 2 }} />
          <Paper
            sx={{
              px: 3,
              py: 3.5,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Stack sx={{ flex: 1 }}>
              <PrizeDailyFloorMapFilter />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <TextField
                select
                label="用紙サイズ"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value === "a3" ? "a3" : "a4")
                }}
              >
                <MenuItem value="a4">A4 横</MenuItem>
                <MenuItem value="a3">A3 横</MenuItem>
              </TextField>
            </Stack>
            <Button
              variant="outlined"
              startIcon={<Print />}
              endIcon={<Launch />}
              disabled={selectedPointIds.length === 0}
              onClick={() => {
                setLocalStorageItem("printPageSize", pageSize)
                window.open(
                  `/arcades/${arcadeCd}/prizes/plans/daily/floorMap/print?auto=1&pageSize=${pageSize}&date=${searchParams.datePickerDateLabel}&points=${selectedPointIds.join(",")}`,
                  "_blank",
                )
              }}
            >
              マップを印刷
            </Button>
          </Paper>
        </>
      )}
      renderContent={() => (
        <PrizeDailySalesFloorMap
          selectedPointIds={selectedPointIds}
          onSelectPoints={setSelectedPointIds}
        />
      )}
    />
  )
}

type PrizeDailySalesFloorMapProps = {
  selectedPointIds?: number[]
  onSelectPoints?: (pointIds: number[]) => void
}

const PrizeDailySalesFloorMap: React.FC<PrizeDailySalesFloorMapProps> = ({
  selectedPointIds = [],
  onSelectPoints,
}) => {
  const { arcadeCd } = useParams()
  const searchParams: PrizeDailyFloorMapFilterSearchParams = useRecoilValue(
    prizeDailyFloorMapFilterSearchParamsState,
  )

  const mapBoxHandler = useRef<FloorMapBoxHandler>(null)

  const floorMapReturn = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource
  const floorMapPoints = floorMapReturn?.data.floorMapPoints

  const date = searchParams.datePickerDateLabel
  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}:${undefined}`,
  }).resource
  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => {
    const isSelected = Boolean(selectedPointIds.includes(point.id))
    const { topLeftX, topLeftY, bottomRightX, bottomRightY } = point

    return (
      <Box
        sx={{
          position: "absolute",
          left: topLeftX,
          top: topLeftY,
          width: bottomRightX - topLeftX,
          height: bottomRightY - topLeftY,
        }}
        data-testid={`floor-map-point-${point.id}`}
        data-point-id={point.id}
      >
        <Box
          sx={(theme) => ({
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: `1px solid ${theme.palette.neutral[400]}`,
          })}
        >
          <PrizeDailySalesFloorMapPointBox key={point.id} point={point} />
        </Box>
        {isSelected && (
          <>
            {/* 選択時に borderWidth を変更すると文字の表示にも影響が出るため上から重ねる  */}
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                borderRadius: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              })}
            />
            <CheckCircle
              size={24}
              sx={{ position: "absolute", top: -4, right: -4 }}
            />
          </>
        )}
      </Box>
    )
  }

  const planFloorMapPoints: PrizePlanFloorMapPoint[] = useMemo(
    () =>
      (floorMapPoints || []).map((p) =>
        convertPrizePlanFloorMapPoint(p, prizeDailyPlans || []),
      ),
    [floorMapPoints, prizeDailyPlans],
  )

  if (!floorMapPoints) {
    return null
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
        <Button
          variant="outlined"
          startIcon={<SelectAll />}
          disabled={selectedPointIds.length === floorMapPoints.length}
          onClick={() => mapBoxHandler.current?.selectAll()}
        >
          全ブース選択
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<Deselect />}
          disabled={selectedPointIds.length === 0}
          onClick={() => mapBoxHandler.current?.deselectAll()}
        >
          ブース選択解除
        </Button>
        <Typography>{selectedPointIds.length}件選択中</Typography>
      </Stack>
      <FloorMapBox
        ref={mapBoxHandler}
        floorMapPoints={planFloorMapPoints}
        getFloorMapPointBox={getFloorMapPointBox}
        onSelectPoints={onSelectPoints}
      />
    </>
  )
}
