import { useState, useMemo, useCallback } from "react"

import { LoadingButton } from "@mui/lab"
import { Card, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { putMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import {
  MaterialInventoryPlacementStatus,
  MaterialMachineShelf,
  MaterialShelf,
  MaterialStorage,
  Material,
  MaterialInventoryHistory,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
  MaterialInventoryPlacementStatusExecutionStatusEnum,
} from "src/api/models"
import emptyIcon from "src/assets/icon_empty.png"
import finishedIcon from "src/assets/icon_finished.png"
import { BackButton } from "src/components/atoms/BackButton"
import {
  filterInventoryMaterialExecuteSingleMenuItems,
  InventoryMaterialExecuteSingleFilter,
  inventoryMaterialExecuteSingleSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialExecuteSingleFilter"
import { InventoryMaterialsTableRow } from "src/components/organisms/materials/InventoryMaterialsTableRow"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { SetEmptyStatusModal } from "src/components/organisms/SetEmptyStatusModal"
import { InventoryMaterialExecute } from "src/components/templates/InventoryMaterialExecute"
import {
  MaterialPlacementType,
  getMaterialPlacementTypeLabel,
} from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { snackbarErrorMessageState } from "src/recoil"
import { PickPartial } from "src/types"
import { getToday } from "src/utils"

interface InventoryMaterialExecuteSingleProps {
  placementType: MaterialPlacementType
  storageId?: MaterialStorage["id"]
  placementId?: MaterialShelf["id"] | MaterialMachineShelf["id"]
  placementStatus?: MaterialInventoryPlacementStatus
  placementStocks?: ((
    | MaterialOperationShelfStock
    | MaterialOperationMachineStock
  ) & {
    material: Material
  })[]
  histories?: MaterialInventoryHistory[]
  menuItems?: InventoryMaterialExecuteSingleMenuItem[]
  onFinish?: () => void
}

export const InventoryMaterialExecuteSingle: React.FC<
  InventoryMaterialExecuteSingleProps
> = ({
  placementType,
  placementId,
  placementStatus,
  placementStocks,
  menuItems = [],
  histories,
  onFinish = () => undefined,
}: InventoryMaterialExecuteSingleProps) => {
  const { arcadeCd } = useParams()

  const [showEmptyStatusModal, setShowEmptyStatusModal] = useState(false)

  const searchParams = useRecoilValue(
    inventoryMaterialExecuteSingleSearchParamsState,
  )
  const filteredMenuItems = useMemo(
    () =>
      filterInventoryMaterialExecuteSingleMenuItems(menuItems, searchParams),
    [menuItems, searchParams],
  )
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<InventoryMaterialExecuteSingleMenuItem>()

  const onClickItem = useCallback(
    (menuItem: InventoryMaterialExecuteSingleMenuItem) =>
      setSelectedMenuItem(menuItem),
    [],
  )

  const selectedPlacementStock = useMemo(
    () =>
      placementStocks?.find(
        (placementStock) =>
          placementStock.material.materialCd ===
          selectedMenuItem?.material.materialCd,
      ),
    [selectedMenuItem, placementStocks],
  )

  const isEmptyStatusAlreadySet =
    placementStatus?.executionStatus ===
    MaterialInventoryPlacementStatusExecutionStatusEnum.Empty
  const isEmptySettable =
    placementStocks?.length === 0 && !isEmptyStatusAlreadySet
  const showAllExecuted =
    menuItems.length > 0 &&
    filteredMenuItems.length === 0 &&
    !menuItems.find((item) => !item.executed)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const setEmptyStatus = () => {
    arcadeCd &&
      placementId &&
      submitPromises([
        {
          subject: "「空」の登録",
          showSuccessMessage: true,
          promise: async () => {
            await putMaterialInventoryPlacementStatuses(
              arcadeCd,
              placementType,
              {
                placementId,
                date: getToday(),
                executionStatus:
                  MaterialInventoryPlacementStatusExecutionStatusEnum.Empty,
              },
            )
            onFinish()
            setShowEmptyStatusModal(false)
          },
        },
      ])
  }

  if (selectedMenuItem && selectedPlacementStock && histories) {
    return (
      <Stack>
        <InventoryMaterialExecute
          placementType={placementType}
          placementStocks={[selectedPlacementStock]}
          onFinish={() => {
            setSelectedMenuItem(undefined)
            onFinish()
          }}
          material={selectedMenuItem.material}
        />
      </Stack>
    )
  } else {
    return (
      <>
        <SetEmptyStatusModal
          showModal={showEmptyStatusModal}
          onSubmit={() => setEmptyStatus()}
          onClose={() => setShowEmptyStatusModal(false)}
          isSubmitting={isSubmitting}
        />

        <Stack mb={3}>
          <InventoryMaterialExecuteSingleFilter />
        </Stack>

        <Stack gap={2}>
          {isEmptyStatusAlreadySet && (
            <Card
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getMaterialPlacementTypeLabel(placementType)}材料
              <img src={emptyIcon} alt="空" style={{ width: 32, height: 32 }} />
              で登録済です
            </Card>
          )}

          {showAllExecuted && (
            <Card
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              すべての材料を実査
              <img
                src={finishedIcon}
                alt="済"
                style={{ width: 32, height: 32 }}
              />
              です
            </Card>
          )}

          <InventoryMaterialExecuteSingleMenu
            onClickItem={onClickItem}
            menuItems={filteredMenuItems}
          />

          {placementStatus && (
            <LoadingButton
              variant="outlined"
              sx={{ background: "white" }}
              fullWidth
              disabled={!isEmptySettable}
              loading={isSubmitting}
              onClick={() => setShowEmptyStatusModal(true)}
            >
              {`${getMaterialPlacementTypeLabel(
                placementType,
              )}材料「空」で登録`}
            </LoadingButton>
          )}

          <Stack mt={1}>
            <BackButton>戻る</BackButton>
          </Stack>
        </Stack>
      </>
    )
  }
}

export type InventoryMaterialExecuteSingleMenuItem = {
  material: PickPartial<
    Material,
    "materialCd" | "materialName",
    "unitPerCarton"
  >
  anyStock?: number
  placementStockId?: number
  executedStock?: number
  executed: boolean
  executedAt?: string
}

export type InventoryMaterialExecuteSingleMenuProps = {
  onClickItem: (menuItem: InventoryMaterialExecuteSingleMenuItem) => void
  menuItems: InventoryMaterialExecuteSingleMenuItem[]
}

export const InventoryMaterialExecuteSingleMenu: React.FC<
  InventoryMaterialExecuteSingleMenuProps
> = ({ menuItems, onClickItem }) => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  return (
    <PaginatedTable
      noMargin
      items={menuItems}
      stateKey="inventoryExecuteDetailsMenu"
      renderRow={(menuItem) => {
        const { material, anyStock, executedStock, executed, executedAt } =
          menuItem
        const stock = executedStock !== undefined ? executedStock : anyStock
        const isEditable = anyStock !== undefined
        const onClickRow = () => {
          if (isEditable) {
            onClickItem(menuItem)
          } else {
            setErrorMessage("在庫が存在しなくなったため、編集できません")
          }
        }

        return (
          <InventoryMaterialsTableRow
            key={material.materialCd}
            material={material}
            stock={stock}
            executed={executed}
            executedAt={executedAt}
            onRowClick={onClickRow}
          />
        )
      }}
    />
  )
}
