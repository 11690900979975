import { atom, useRecoilState } from "recoil"

import { DatePicker } from "src/components/organisms/DatePicker"
import { DateLabelString } from "src/types"
import { getToday } from "src/utils"

export type PrizeDailyFloorMapFilterSearchParams = {
  datePickerDateLabel: DateLabelString
}

export const defaultSearchParams = {
  datePickerDateLabel: getToday(),
}

export const prizeDailyFloorMapFilterSearchParamsState =
  atom<PrizeDailyFloorMapFilterSearchParams>({
    key: "prizeDailyFloorMapSearchParams",
    default: defaultSearchParams,
  })

export const PrizeDailyFloorMapFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    prizeDailyFloorMapFilterSearchParamsState,
  )
  return (
    <DatePicker
      label="日付指定"
      value={searchParams.datePickerDateLabel}
      onChange={(v) => setSearchParams({ datePickerDateLabel: v })}
      hideTodayButton
    />
  )
}
