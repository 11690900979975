/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialInventoryPlacementTypeStatusResponse,
  PutMaterialInventoryPlacementTypeStatusRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】PlacementType 毎の棚卸状態を設定
 */
export const putMaterialInventoryPlacementTypeStatus = (
  arcadeCd: string,
  placementType: "storage" | "in_machine",
  putMaterialInventoryPlacementTypeStatusRequest: PutMaterialInventoryPlacementTypeStatusRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/placementType/${placementType}/status`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryPlacementTypeStatusRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】PlacementType 毎の棚卸状態を取得
 */
export const getMaterialInventoryPlacementTypeStatus = (
  arcadeCd: string,
  placementType: "storage" | "in_machine",
  materialExecutionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryPlacementTypeStatusResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/placementType/${placementType}/status/${materialExecutionPeriodId}`,
      method: "GET",
    },
    options,
  )
}
export type PutMaterialInventoryPlacementTypeStatusResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialInventoryPlacementTypeStatus>>
>
export type GetMaterialInventoryPlacementTypeStatusResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryPlacementTypeStatus>>
>
