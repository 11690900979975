import { useState, useMemo, useCallback } from "react"

import { Button, Stack } from "@mui/material"
import { useParams } from "react-router-dom"

import {
  PrizeFloorMapPoint,
  PutPrizeInventoryGroupRequest,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import {
  getPrizeInventoryGroups,
  putPrizeInventoryGroups,
} from "src/api/prize-inventory-group"
import { InventoryPrizeGroupBoothFloorMap } from "src/components/organisms/prizes/InventoryPrizeGroupBoothFloorMap"
import {
  InventoryPrizeGroupEditModalTableItem,
  InventoryPrizeGroupEditModal,
} from "src/components/organisms/prizes/InventoryPrizeGroupEditModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useFloorMapColors } from "src/hooks/useFloorMapColors"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"

export const InventoryPrizeGroupOnBooth = () => {
  return (
    <MainContentLayout
      title="棚卸グループ設定(プライズ機[上])"
      renderContent={() => <InventoryPrizeGroupOnBoothMenu />}
    />
  )
}

const InventoryPrizeGroupOnBoothMenu: React.FC = () => {
  const { arcadeCd } = useParams()

  const [selectedPointIds, setSelectedPointIds] = useState<
    BaseFloorMapPoint["id"][]
  >([])
  const [editModalOpen, setEditModalOpen] = useState(false)

  const { resource: floorMapPointsResource, refetch: refetchFloorMapPoints } =
    useResource({
      subject: "ブースリストの取得",
      fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
      recoilKey: `getInventoryFloorMap:${arcadeCd}`,
    })
  const floorMapPoints = useMemo(
    () => floorMapPointsResource?.data.floorMapPoints || [],
    [floorMapPointsResource],
  )

  const { resource: inventoryGroupsResource, refetch: refetchInventoryGroups } =
    useResource({
      subject: "棚卸グループの取得",
      fetch: arcadeCd
        ? () => getPrizeInventoryGroups(arcadeCd, { placement: "on_booth" })
        : undefined,
      recoilKey: `getInventoryGroups:${arcadeCd}:on_booth`,
    })
  const existGroupNames = useMemo(
    () => inventoryGroupsResource?.data.groups.map((g) => g.groupName) || [],
    [inventoryGroupsResource],
  )

  const { floorMapColors } = useFloorMapColors()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const isAlreadySelected = useCallback(
    (pointId: PrizeFloorMapPoint["id"]) => selectedPointIds.includes(pointId),
    [selectedPointIds],
  )

  const onClickPoint = (point: BaseFloorMapPoint) => {
    if (isAlreadySelected(point.id)) {
      setSelectedPointIds(
        selectedPointIds.filter((pointId) => pointId !== point.id),
      )
    } else {
      setSelectedPointIds([...selectedPointIds, point.id])
    }
  }

  const onSubmit = useCallback(
    (groupName: string, items: InventoryPrizeGroupEditModalTableItem[]) => {
      if (!arcadeCd) return
      const request: PutPrizeInventoryGroupRequest = {
        groupName,
        placementShelfIds: items.map((i) => i.placementShelfId),
        placement: PlacementType.OnBooth,
      }
      submitPromises([
        {
          subject: "棚卸グループの更新",
          showSuccessMessage: true,
          promise: async () => {
            await putPrizeInventoryGroups(arcadeCd, request)
            refetchFloorMapPoints()
            refetchInventoryGroups()
            setEditModalOpen(false)
            setSelectedPointIds([])
          },
        },
      ])
    },
    [arcadeCd, refetchFloorMapPoints, refetchInventoryGroups, submitPromises],
  )

  const tableItems = useMemo(
    () =>
      floorMapPoints
        .filter((point) => selectedPointIds.includes(point.id))
        .map((point) => ({
          name: point.name,
          placementShelfId: point.onBoothShelf.id,
          groupName: point.onBoothShelf.inventoryGroup?.groupName,
        })),
    [floorMapPoints, selectedPointIds],
  )

  return (
    <>
      <Stack gap={2}>
        <InventoryPrizeGroupBoothFloorMap
          onClickPoint={onClickPoint}
          selectedPointIds={selectedPointIds}
          placementType={PlacementType.OnBooth}
          floorMapPoints={floorMapPoints}
          floorMapColors={floorMapColors}
        />

        <Button
          variant="contained"
          type="submit"
          fullWidth
          onClick={() => setEditModalOpen(true)}
          disabled={selectedPointIds.length === 0}
        >
          棚卸グループ設定を更新
        </Button>
      </Stack>

      {selectedPointIds.length > 0 && (
        <InventoryPrizeGroupEditModal
          onSubmit={onSubmit}
          onClose={() => setEditModalOpen(false)}
          open={editModalOpen}
          tableItems={tableItems}
          existGroupNames={existGroupNames}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  )
}
