import { Button, DialogContent, Typography } from "@mui/material"

import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"

type Props = {
  open: boolean
  message: string
  onClose?: () => void
  onExited?: () => void
}

export const ErrorDialog: React.FC<Props> = ({
  open,
  message,
  onClose,
  onExited,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 360 } }}
      TransitionProps={{ onExited }}
    >
      <DialogContent>
        <Typography variant="body1" fontWeight="bold">
          {message}
        </Typography>
      </DialogContent>
      <CustomDialogActions>
        <Button variant="contained" color="primary" fullWidth onClick={onClose}>
          OK
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}
