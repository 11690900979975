import { IndexRouteObject, LazyRouteFunction } from "react-router-dom"

const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec))

export const dynamicImportWithRetry = async (
  importFn: LazyRouteFunction<IndexRouteObject>,
): ReturnType<LazyRouteFunction<IndexRouteObject>> => {
  const retryImport = async (retriesLeft = 5, interval = 1000) => {
    try {
      return await importFn()
    } catch (error) {
      if (retriesLeft === 1) throw error

      await sleep(interval)
      return await retryImport(retriesLeft - 1, interval)
    }
  }

  try {
    return await retryImport()
  } catch {
    location.reload()
    await sleep(30000)
    throw new Error("Failed to reload after chunk load failure")
  }
}
