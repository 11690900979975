import React, { useState, ReactNode } from "react"

import { Button, Table, TableBody, Card, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getMaterials } from "src/api/materials"
import { Material } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardItemNameBox,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import {
  InventoryMaterialFilter,
  inventoryMaterialSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import {
  InventoryMaterialAcceptSingleConfirm,
  InventoryMaterialAcceptSingleForm,
  MaterialAcceptResult,
} from "src/pages/materials/register/InventoryMaterialAcceptSingleForm"
import { datePickerDateLabelStateSelector } from "src/recoil"
import { theme } from "src/theme"
import { getToday } from "src/utils"

type PageState = "list" | "material" | "form" | "confirm"
type Page = {
  subTitle?: string
  caption?: string
  filter?: ReactNode
  content: ReactNode
  onClickBackButton?: () => void
  backButtonLabel?: string
}

export const InventoryMaterialSearch = () => {
  const [pageState, setPageState] = useState<PageState>("list")
  const [material, setMaterial] = useState<Material>()
  const [results, setResults] = useState<MaterialAcceptResult[]>([])

  const onClose = () => {
    setMaterial(undefined)
    setResults([])
    setPageState("list")
  }
  const onSelect = (material: Material) => {
    setMaterial(material)
    setPageState("material")
  }
  const onAccept = () => {
    setPageState("form")
  }
  const onAccepted = (results: MaterialAcceptResult[]) => {
    setResults(results)
    setPageState("confirm")
  }
  let page = {} as Page
  if (pageState === "list") {
    page = {
      caption: "検索項目を入力して「検索する」を実行してください",
      filter: <InventoryMaterialFilter />,
      content: <InventoryMaterialSearchMenu onSelect={onSelect} />,
    }
  }
  if (pageState === "material" && material) {
    page = {
      content: (
        <InventoryMaterialAccept material={material} onAccept={onAccept} />
      ),
      onClickBackButton: () => onClose(),
    }
  }
  if (pageState === "form" && material) {
    page = {
      subTitle: "選択した材料を検収する",
      content: (
        <InventoryMaterialAcceptSingleForm
          material={material}
          onAccepted={onAccepted}
        />
      ),
      onClickBackButton: () => onClose(),
      backButtonLabel: "確定せず戻る",
    }
  }
  if (pageState === "confirm" && results[0]) {
    page = {
      content: <InventoryMaterialAcceptSingleConfirm result={results[0]} />,
      onClickBackButton: () => onClose(),
    }
  }

  return (
    <MainContentLayout
      title="検索して入庫検収・材料登録"
      subtitle={page.subTitle}
      caption={page.caption}
      renderFilter={page.filter ? () => page.filter : undefined}
      renderContent={() => page.content}
      onClickBackButton={page.onClickBackButton}
      backButtonLabel={page.backButtonLabel}
    />
  )
}

interface InventoryMaterialSearchMenuProps {
  onSelect: (material: Material) => void
}

const InventoryMaterialSearchMenu: React.FC<
  InventoryMaterialSearchMenuProps
> = ({ onSelect }) => {
  const { arcadeCd } = useParams()
  const searchParams = useRecoilValue(inventoryMaterialSearchParamsState)

  const materialsReturn = useResource({
    subject: "材料一覧の取得",
    fetch: arcadeCd
      ? () => getMaterials(arcadeCd, { ...searchParams, all: true })
      : undefined,
    recoilKey: `getMaterials:${JSON.stringify({
      ...searchParams,
      all: true,
    })}`,
    skip: !(
      !!searchParams.materialCd ||
      !!searchParams.materialName ||
      !!searchParams.materialNameKana ||
      !!searchParams.ipName
    ),
  })
  const materials = materialsReturn.resource?.data.materials || []

  return (
    <InventoryMaterialSearchMenuTemplate
      materials={materials}
      onSelect={onSelect}
    />
  )
}

type InventoryMaterialSearchMenuTemplateProps = {
  materials: Material[]
  onSelect: (material: Material) => void
}

const InventoryMaterialSearchMenuTemplate: React.FC<
  InventoryMaterialSearchMenuTemplateProps
> = ({ materials, onSelect }) => {
  return (
    materials && (
      <InventoryMaterialTable materials={materials} onSelect={onSelect} />
    )
  )
}

interface InventoryMaterialTableProps {
  materials: Material[]
  onSelect: (material: Material) => void
}

const InventoryMaterialTable: React.FC<InventoryMaterialTableProps> = ({
  materials,
  onSelect,
}: InventoryMaterialTableProps) => {
  return (
    <PaginatedTable
      noMargin
      items={materials}
      stateKey="inventoryMaterialTable"
      renderRow={(material) => {
        return (
          <TableBorderedRow
            key={material.materialCd}
            sx={{
              "&:hover": {
                cursor: "pointer",
                td: { background: theme.palette.neutral[200] },
              },
            }}
            onClick={() => onSelect(material)}
          >
            <ExtTableCell>
              <CardItemNameBox>{material.materialName}</CardItemNameBox>
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}

interface InventoryMaterialAcceptProps {
  material: Material
  onAccept: () => void
}

const InventoryMaterialAccept: React.FC<InventoryMaterialAcceptProps> = ({
  material,
  onAccept,
}: InventoryMaterialAcceptProps) => {
  const setDatePickerDateLabel = useSetRecoilState(
    datePickerDateLabelStateSelector,
  )
  const {
    materialCd,
    materialName,
    makerName,
    ipName,
    unitPerCarton,
    unitPriceJpy,
  } = material

  const listItems = [
    {
      key: "材料CD",
      value: materialCd,
    },
    {
      key: "材料名",
      value: materialName,
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "カートン入数",
      value: unitPerCarton.toLocaleString(),
    },
    unitPriceJpy && {
      key: "単価",
      value: `${unitPriceJpy.toLocaleString()}円`,
    },
  ].filter((item) => !!item)

  return (
    <Stack gap={2}>
      <Card>
        <Table>
          <TableBody>
            {listItems.map(
              (item) =>
                item && (
                  <TableBorderedRow key={item.key}>
                    <CardKeyCell>{item.key}</CardKeyCell>
                    <CardValueCell>{item.value}</CardValueCell>
                  </TableBorderedRow>
                ),
            )}
          </TableBody>
        </Table>
      </Card>

      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          onAccept()
          // NOTE: 材料検索から検収モーダル遷移時は検収日付を今日にする
          setDatePickerDateLabel(getToday())
        }}
      >
        検収・材料登録
      </Button>
    </Stack>
  )
}
