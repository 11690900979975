import React from "react"

import { CardContent, List, Card } from "@mui/material"
import { useParams } from "react-router-dom"

import { PrizeFloorMapPoint } from "src/api/models"
import { getPrizeFloorMapPoint } from "src/api/prize-floor-map"
import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

export const InventoryPrizePlacementInBooth = () => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()

  const { resource } = useResource({
    subject: "ブース情報の取得",
    fetch:
      arcadeCd && inventoryFloorMapPointId
        ? () =>
            getPrizeFloorMapPoint(arcadeCd, Number(inventoryFloorMapPointId))
        : undefined,
    recoilKey: `getInventoryFloorMapPoint:${arcadeCd}:${inventoryFloorMapPointId}`,
  })
  const inventoryFloorMapPoint = resource?.data.floorMapPoint

  return (
    <MainContentLayout
      title={
        inventoryFloorMapPoint ? inventoryFloorMapPoint.name + "［内］" : ""
      }
      renderContent={() => (
        <InventoryPlacementInBoothInner
          inventoryFloorMapPoint={inventoryFloorMapPoint}
        />
      )}
    />
  )
}

type InventoryPlacementInBoothInnerProps = {
  inventoryFloorMapPoint?: PrizeFloorMapPoint
}

const InventoryPlacementInBoothInner: React.FC<
  InventoryPlacementInBoothInnerProps
> = ({ inventoryFloorMapPoint }) => {
  const { isAvailable } = inventoryFloorMapPoint?.boothShelf || {}

  return !isAvailable ? (
    <Card>
      <CardContent>無効に設定されています</CardContent>
    </Card>
  ) : (
    <InventoryPlacementInBoothMenu />
  )
}

const InventoryPlacementInBoothMenu: React.FC = () => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()

  const menuItems = arcadeCd
    ? [
        {
          href: `/arcades/${arcadeCd}/prizes/placements/inBooth/${inventoryFloorMapPointId}/prizes`,
          title: "保管されている景品一覧",
        },
        {
          href: `/arcades/${arcadeCd}/inventory/prizes/execute/inBooth/${inventoryFloorMapPointId}`,
          title: "棚卸実施",
        },
      ]
    : []

  return (
    <Card>
      <CardContent>
        <List sx={{ width: "100%", color: theme.palette.primary.main }}>
          {menuItems.map((i) => (
            <MenuButton key={i.title} to={i.href}>
              {i.title}
            </MenuButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
