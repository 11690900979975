import { useState, useMemo, useEffect, Fragment } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Grid,
  Button,
  Card,
  Typography,
  Divider,
  TextField,
} from "@mui/material"
import { useForm, useWatch, SubmitHandler, Controller } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import { getMaterialMachines } from "src/api/material-machines"
import { putMaterialOperationMoveToStorage } from "src/api/material-operation-move"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { getMaterialStorage } from "src/api/material-storages"
import { getMaterials } from "src/api/materials"
import {
  Material,
  MaterialMachine,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
  MaterialShelf,
  MaterialStorage,
} from "src/api/models"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { InventoryMaterialMoveToMachineInputFormInput } from "src/components/templates/InventoryMaterialMoveToMachine"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { snackbarErrorMessageState } from "src/recoil"

interface MaterialOperationMachineStockNullable {
  amMachine: MaterialOperationMachineStock["amMachine"]
  materialMachine: MaterialOperationMachineStock["materialMachine"]
  machineShelf?: MaterialOperationMachineStock["machineShelf"]
  machineStock?: MaterialOperationMachineStock["machineStock"]
}

export const InventoryMaterialPlacementMoveFromMultipleMaterialsInput = () => {
  return (
    <MainContentLayout
      title="複数のシリンダーから一括で移動"
      renderContent={() => (
        <InventoryMaterialMoveFromMultipleMaterialsInputMenu />
      )}
    />
  )
}

const InventoryMaterialMoveFromMultipleMaterialsInputMenu = () => {
  const { arcadeCd, materialCd, storageId, shelfId } = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()
  const machineIdsQuery = new URLSearchParams(search).get("machineIds")
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const [movedResult, setMovedResult] =
    useState<
      Omit<
        InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenuProps,
        "material"
      >
    >()

  const materialMachineIds = useMemo(
    () =>
      machineIdsQuery
        ?.split(",")
        .map((id) => {
          const idNum = Number(id)
          return isNaN(idNum) ? undefined : idNum
        })
        .filter(Boolean) as number[],
    [machineIdsQuery],
  )

  const { resource: resourceStorages } = useResource({
    subject: "保管場所棚リストの取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
  })
  const storage = resourceStorages?.data.storage
  const shelf = resourceStorages?.data.shelves.find(
    (shelf) => shelf.id.toString() === shelfId,
  )
  const storageShelfMoveTo = storage &&
    shelf && {
      storage,
      shelf,
    }
  if (!storageShelfMoveTo) throw new Error()

  const { resource: resourceMaterials } = useResource({
    subject: "在庫状況リストの取得",
    fetch:
      arcadeCd && materialCd
        ? () => getMaterials(arcadeCd, { materialCd })
        : undefined,
    recoilKey: `getMaterials:${arcadeCd}:${materialCd}`,
  })
  const material = resourceMaterials?.data.materials[0]
  const { unitPerCarton } = material || {}

  const { resource: resourceMachines } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}`,
  })
  const materialMachines = resourceMachines?.data.materialMachines.filter(
    (machine) => materialMachineIds.includes(machine.materialMachine.id),
  )

  // materialMachineIdsが不正な場合は移動元の画面に戻す
  useEffect(() => {
    if (
      materialMachines &&
      materialMachineIds &&
      materialMachines.length !== materialMachineIds.length
    ) {
      navigate(`/arcades/${arcadeCd}/materials/move/machines`, {
        replace: true,
      })
      setErrorMessage(
        "指定された移動元の材料機械が見つからないため、リダイレクトされました。",
      )
    }
  }, [
    arcadeCd,
    materialMachineIds,
    materialMachines,
    navigate,
    setErrorMessage,
  ])

  const { resource: resourceStocks } = useResource({
    subject: "在庫状況リストの取得",
    fetch:
      arcadeCd && materialCd && storageId && shelfId
        ? () => getMaterialOperationStocks(arcadeCd, { materialCd })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}`,
  })
  const moveFromMachineStocks = resourceStocks?.data.stocks.filter((stock) =>
    stock.machineStocks.some(
      (stock) =>
        materialMachineIds.includes(stock.materialMachine.id) &&
        stock.machineStock.materialCd === materialCd,
    ),
  )

  const moveFromMachineStocksMap = new Map<
    MaterialMachine["id"],
    MaterialOperationMachineStockNullable
  >(
    materialMachines?.map((machine) => {
      return [
        machine.materialMachine.id,
        (
          moveFromMachineStocks
            ?.flatMap((stock) =>
              stock.machineStocks.filter(
                (machineStock) =>
                  machineStock.materialMachine.id ===
                  machine.materialMachine.id,
              ),
            )
            .filter(Boolean) as MaterialOperationMachineStockNullable[]
        )[0] || {
          amMachine: machine.amMachine,
          materialMachine: machine.materialMachine,
        },
      ]
    }) ?? [],
  )

  const machinesMoveFrom = Array.from(moveFromMachineStocksMap.values()).map(
    (stock) => stock,
  )

  const shelfMoveTo = resourceStocks?.data.stocks
    .find((stock) => stock.material.materialCd === materialCd)
    ?.shelfStocks.find((stock) => stock.shelf.id.toString() === shelfId)

  const validationSchema = Yup.object({
    stocks: Yup.array(
      Yup.object({
        materialMachineId: Yup.number().required(),
        stock: Yup.number()
          .required("必須です")
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください")
          .test({
            name: "minRemainingStocks",
            message: "移動元の在庫数を超えています",
            test: (stock, ctx) =>
              (moveFromMachineStocksMap.get(ctx.parent.materialMachineId)
                ?.machineStock?.stock ?? 0) -
                Number(stock || 0) >=
              0,
          }),
      }),
    ).required("必須です"),
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<InventoryMaterialMoveToMachineInputFormInput>({
    resolver:
      yupResolver<InventoryMaterialMoveToMachineInputFormInput>(
        validationSchema,
      ),
    defaultValues: {
      stocks: machinesMoveFrom
        .filter((machine) => machine.machineStock && machine.machineShelf)
        .map((machine) => {
          return {
            materialMachineId: machine.materialMachine.id || 0,
          }
        }),
      note: "",
    },
  })
  const value = {
    stocks: useWatch({ control, name: "stocks" }) || [],
  }
  const totalMoveStock = value.stocks.reduce(
    (sum, stock) => sum + Number(stock.stock || 0),
    0,
  )

  const { submitPromises } = useSubmitting()

  const onSubmit: SubmitHandler<
    InventoryMaterialMoveToMachineInputFormInput
  > = async ({ stocks, note }) => {
    const request = {
      from: stocks.map((stock) => {
        return {
          machineShelfId: stock.materialMachineId,
          stock: stock.stock,
        }
      }),
      to: { shelfId: storageShelfMoveTo?.shelf.id, stock: totalMoveStock },
      material: { materialCd: materialCd || "" },
      note: note ?? "",
    }

    arcadeCd &&
      material &&
      (await submitPromises([
        {
          subject: "棚への移動",
          showSuccessMessage: true,
          promise: async () => {
            const oldMachinesMovedFrom = machinesMoveFrom
              .filter((machine) => machine.machineStock && machine.machineShelf)
              .map((machine) => machine as MaterialOperationMachineStock)
            const oldShelfStockMovedTo = shelfMoveTo

            await putMaterialOperationMoveToStorage(arcadeCd, request)

            const machinesMovedFrom = oldMachinesMovedFrom.map((moved) => {
              const movedStockCount = stocks.find(
                (stock) => stock.materialMachineId === moved.materialMachine.id,
              )

              return {
                ...moved,
                machineStock: {
                  ...moved.machineStock,
                  stock:
                    (moved.machineStock?.stock ?? 0) -
                    (movedStockCount?.stock ?? 0),
                },
              }
            })

            const shelfStockMovedTo: MaterialOperationShelfStock = {
              ...(oldShelfStockMovedTo ?? storageShelfMoveTo),
              shelfStock: {
                ...(oldShelfStockMovedTo?.shelfStock ?? {
                  id: 0, // 表示には使用しないので仮の値
                  storageId: storageShelfMoveTo.storage.id,
                  shelfId: storageShelfMoveTo.shelf.id,
                  materialCd: material.materialCd,
                }),
                stock:
                  (oldShelfStockMovedTo?.shelfStock.stock ?? 0) +
                  totalMoveStock,
              },
            }

            setMovedResult({
              machinesMovedFrom,
              oldMachinesMovedFrom,
              storageShelfMovedTo: storageShelfMoveTo,
              shelfStockMovedTo,
              oldShelfStockMovedTo,
              note: note ?? "",
            })

            window.scrollTo({ top: 0 })
          },
        },
      ]))
  }

  if (movedResult)
    return (
      <InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenu
        {...movedResult}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        material={material!}
      />
    )

  let indexInStocks = 0
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 2, mb: 3 }}>
        <Grid container pb={2}>
          <Grid item xs={12} pb={1}>
            <Typography variant="h2">{material?.materialName}</Typography>
          </Grid>
          <Grid item xs={12}>
            カートン入数：{unitPerCarton || 0} 個
          </Grid>
        </Grid>

        <Divider sx={{ mb: 1 }} />

        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          {/* 移動元 */}
          {machinesMoveFrom.map((stock, index) => {
            const stockErrors =
              (errors.stocks && errors.stocks[indexInStocks]) || {}
            const fragment =
              stock.machineStock === undefined ? (
                <Fragment key={index}>
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    {getDisplayMaterialMachineName(
                      stock.amMachine,
                      stock.materialMachine,
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ pb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      材料が存在しません
                    </Typography>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment key={index}>
                  <Grid item xs={12}>
                    {getDisplayMaterialMachineName(
                      stock.amMachine,
                      stock.materialMachine,
                    )}
                  </Grid>

                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      移動する数
                    </Typography>
                  </Grid>

                  <Grid item xs={3} sx={{ textAlign: "right" }}>
                    <Controller
                      control={control}
                      name={`stocks.${indexInStocks}.stock`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          error={"stock" in stockErrors}
                          helperText={stockErrors.stock?.message || ""}
                          inputProps={{ inputMode: "numeric" }}
                          sx={{ textAlign: "right" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1} sx={{ textAlign: "right" }}>
                    個
                  </Grid>

                  <Grid item xs={0.5} />
                  <Grid item xs={7.5}>
                    <Typography variant="body2" color="text.secondary">
                      移動前
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>{stock.machineStock.stock}</strong>
                    {" 個"}
                  </Grid>

                  <Grid item xs={0.5} sx={{ pb: 2 }} />
                  <Grid item xs={7.5} sx={{ pb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      移動後
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
                    <strong>
                      {stock.machineStock.stock -
                        Number(value.stocks[indexInStocks]?.stock || 0)}
                    </strong>
                    {" 個"}
                  </Grid>
                  {errors.stocks && (
                    <>
                      <Grid item xs={2} />
                      <Grid item xs={10} sx={{ textAlign: "right" }}>
                        <Typography variant="body2" color="error.main">
                          {errors.stocks?.message || ""}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Fragment>
              )
            if (stock.machineStock !== undefined) indexInStocks += 1
            return fragment
          })}

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}
          <Grid item xs={12}>
            {storageShelfMoveTo.storage.name}
          </Grid>
          <Grid item xs={12}>
            {storageShelfMoveTo.shelf.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動させる数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{totalMoveStock}</strong> 個
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動前
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfMoveTo?.shelfStock.stock ?? 0}</strong>
            {" 個"}
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動後
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>
              {(shelfMoveTo?.shelfStock.stock ?? 0) + totalMoveStock}
            </strong>
            {" 個"}
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              label="備考"
              fullWidth
              error={"note" in errors}
              helperText={errors.note?.message}
              {...register("note")}
            />
          </Grid>
        </Grid>
      </Card>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSubmitting}
        fullWidth
        sx={{ height: 44, lineHeight: 1.2, px: 0.5 }}
      >
        材料移動を保存
      </LoadingButton>
    </form>
  )
}

type MaterialStorageShelf = {
  storage: MaterialStorage
  shelf: MaterialShelf
}

interface InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenuProps {
  material: Material
  machinesMovedFrom: MaterialOperationMachineStock[]
  oldMachinesMovedFrom: MaterialOperationMachineStock[]
  storageShelfMovedTo: MaterialStorageShelf
  shelfStockMovedTo?: MaterialOperationShelfStock
  oldShelfStockMovedTo?: MaterialOperationShelfStock
  note: string
}

const InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenu: React.FC<
  InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenuProps
> = ({
  material,
  machinesMovedFrom,
  oldMachinesMovedFrom,
  storageShelfMovedTo,
  shelfStockMovedTo,
  oldShelfStockMovedTo,
  note,
}: InventoryMaterialMoveFromMultipleMaterialsInputConfirmMenuProps) => {
  const oldShelfStockNum = oldShelfStockMovedTo?.shelfStock.stock || 0
  const shelfStockNum = shelfStockMovedTo?.shelfStock.stock || 0
  const navigate = useNavigate()

  return (
    <>
      <AlertCaptionCard label="下記の通り移動しました" />

      <Card sx={{ p: 2, mb: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h2">{material.materialName}</Typography>
          </Grid>

          {/* 移動元 */}
          {machinesMovedFrom.map((machineStock) => {
            const oldMachineStock = oldMachinesMovedFrom.find(
              (stock) =>
                stock.materialMachine.id == machineStock.materialMachine.id,
            )
            if (oldMachineStock) {
              const diff =
                oldMachineStock.machineStock.stock -
                machineStock.machineStock.stock

              return (
                <Fragment key={machineStock.machineShelf.id}>
                  <Grid item xs={12}>
                    {getDisplayMaterialMachineName(
                      machineStock.amMachine,
                      machineStock.materialMachine,
                    )}
                  </Grid>

                  <Grid item xs={8}>
                    <Typography variant="body2" color="text.secondary">
                      移動した数
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>{diff}</strong> 個
                  </Grid>

                  <Grid item xs={1} />
                  <Grid item xs={7}>
                    <Typography variant="body2" color="text.secondary">
                      元
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>{oldMachineStock.machineStock.stock}</strong> 個
                  </Grid>

                  <Grid item xs={1} sx={{ pb: 2 }} />
                  <Grid item xs={7} sx={{ pb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      現在
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
                    <strong>{machineStock.machineStock.stock}</strong> 個
                  </Grid>
                </Fragment>
              )
            } else {
              return
            }
          })}

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}

          <Grid item xs={12}>
            {storageShelfMovedTo.storage.name} {storageShelfMovedTo.shelf.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動した数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfStockNum - oldShelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              元
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{oldShelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={1} sx={{ pb: 2 }} />
          <Grid item xs={7} sx={{ pb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              現在
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
            <strong>{shelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            {note}
          </Grid>
        </Grid>
      </Card>

      <Button variant="outlined" fullWidth onClick={() => navigate(-3)}>
        材料移動へ戻る
      </Button>
    </>
  )
}
