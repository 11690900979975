import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { PrizePlacementStatus } from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import { InventoryPrizeExecutePlacementListItem } from "src/components/organisms/prizes/InventoryPrizeExecuteList"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"
import { formatApiDate } from "src/utils"

type UseInventoryExecuteBoothFloorMapProps = {
  placementType: Exclude<PlacementType, "storage">
}

export const useInventoryExecuteBoothFloorMap = ({
  placementType,
}: UseInventoryExecuteBoothFloorMapProps) => {
  const { arcadeCd } = useParams()
  const { InBooth, OnBooth } = PlacementType
  const isInBooth = useMemo(
    () => placementType === InBooth,
    [placementType, InBooth],
  )

  const { resource: floorMapPointsResource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  })
  const floorMapPoints = useMemo(
    () => floorMapPointsResource?.data.floorMapPoints || [],
    [floorMapPointsResource],
  )

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const { resource: placementStatusesResource } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementStatuses(
              arcadeCd,
              isInBooth ? InBooth : OnBooth,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      isInBooth ? InBooth : OnBooth
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })
  const placementStatuses = useMemo(() => {
    const { counts, boothStatuses, onBoothStatuses } =
      placementStatusesResource?.data || {}
    if (counts) {
      return isInBooth ? boothStatuses : onBoothStatuses
    }
    return
  }, [isInBooth, placementStatusesResource])

  const placementStatusesPerPointId: {
    [pointId: number]: PrizePlacementStatus | undefined
  } = useMemo(() => {
    return floorMapPoints.reduce((obj, point) => {
      const placementId = isInBooth
        ? point.boothShelf.id
        : point.onBoothShelf.id
      const placementStatus = placementStatuses?.find(
        (status) => status.placementId === placementId,
      )
      return { ...obj, [point.id]: placementStatus }
    }, {})
  }, [floorMapPoints, isInBooth, placementStatuses])

  const placementListItems: InventoryPrizeExecutePlacementListItem<
    "in_booth" | "on_booth"
  >[] = useMemo(
    () =>
      floorMapPoints.map((point) => {
        const placement = isInBooth ? point.boothShelf : point.onBoothShelf
        const placementStatus = (placementStatuses || []).find(
          (placementStatus) => placementStatus.placementId === placement.id,
        )
        return {
          inventoryFloorMapPointId: point.id,
          name: point.name,
          isAvailable: placement.isAvailable,
          groupName: placement?.inventoryGroup?.groupName,
          executionStatus: placementStatus?.executionStatus,
          executedAt: formatApiDate(placementStatus?.executedAt || ""),
        }
      }),
    [floorMapPoints, isInBooth, placementStatuses],
  )

  const pointGroupNames = useMemo(
    () =>
      Array.from(
        new Set(
          floorMapPoints
            .map((point) =>
              isInBooth
                ? point.boothShelf?.inventoryGroup?.groupName
                : point.onBoothShelf?.inventoryGroup?.groupName,
            )
            .filter((v) => !!v) as string[],
        ),
      ),
    [floorMapPoints, isInBooth],
  )

  return {
    placementListItems,
    pointGroupNames,
    floorMapPoints,
    placementStatusesPerPointId,
  }
}
