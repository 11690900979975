/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeToneBoothsParams,
  GetPrizeToneBoothsResponse,
  PatchPrizeToneBoothSalePrizeChangeRequest,
  PatchPrizeToneBoothSalePrizeChangeResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ブース情報を取得
 */
export const getPrizeToneBooths = (
  arcadeCd: string,
  params: GetPrizeToneBoothsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeToneBoothsResponse>(
    { url: `/arcades/${arcadeCd}/tone/booths`, method: "GET", params },
    options,
  )
}
/**
 * @summary ブースの景品入れ替えフラグを更新
 */
export const patchPrizeToneBoothSalePrizeChange = (
  arcadeCd: string,
  machineName: string,
  boothName: string,
  soldOn: string,
  patchPrizeToneBoothSalePrizeChangeRequest: PatchPrizeToneBoothSalePrizeChangeRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PatchPrizeToneBoothSalePrizeChangeResponse>(
    {
      url: `/arcades/${arcadeCd}/tone/booths/${machineName}/${boothName}/${soldOn}/prizeChange`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeToneBoothSalePrizeChangeRequest,
    },
    options,
  )
}
export type GetPrizeToneBoothsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeToneBooths>>
>
export type PatchPrizeToneBoothSalePrizeChangeResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeToneBoothSalePrizeChange>>
>
