/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type AmMachineUsageCategoryEnum =
  (typeof AmMachineUsageCategoryEnum)[keyof typeof AmMachineUsageCategoryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AmMachineUsageCategoryEnum = {
  Prize: "prize",
  Material: "material",
  Other: "other",
} as const
