import { Grid, Typography } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/ja"

import { DateLabelString } from "src/types"
import { formatApiDate, getIsoWeekEnd, getIsoWeekStart } from "src/utils"

export type DateRangePickerDateLabel = {
  start: DateLabelString
  end: DateLabelString
}

export type DateRangePickerProps = {
  dateRangeLabel: DateRangePickerDateLabel
  setDateRangeLabel: (props: DateRangePickerDateLabel) => void
  startInputLabel?: string
  endInputLabel?: string
  fixedRange?: "week"
  minDate?: dayjs.Dayjs
}

export const DateRangePicker = ({
  dateRangeLabel,
  setDateRangeLabel,
  startInputLabel,
  endInputLabel,
  fixedRange,
  minDate,
}: DateRangePickerProps) => {
  const onChangeDateRange = (dateRange: {
    start?: dayjs.Dayjs
    end?: dayjs.Dayjs
  }) => {
    if (!dateRange.start && !dateRange.end) return
    if (!dateRangeLabel) return
    if (!setDateRangeLabel) return

    let dayJsStart = dateRange.start || dayjs(dateRangeLabel.start)
    let dayJsEnd = dateRange.end || dayjs(dateRangeLabel.end)

    if (fixedRange === "week") {
      if (dateRange.start) {
        dayJsStart = getIsoWeekStart(dateRange.start)
        dayJsEnd = getIsoWeekEnd(dateRange.start)
      } else if (dateRange.end) {
        dayJsStart = getIsoWeekStart(dateRange.end)
        dayJsEnd = getIsoWeekEnd(dateRange.end)
      }
    }

    setDateRangeLabel({
      start: formatApiDate(dayJsStart),
      end: formatApiDate(dayJsEnd),
    })
  }

  return (
    <LocalizationProvider adapterLocale={"ja"} dateAdapter={DateAdapter}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={true}>
          <DesktopDatePicker
            value={dayjs(dateRangeLabel.start)}
            format="YYYY/MM/DD(ddd)"
            minDate={minDate}
            onChange={(startDate) =>
              startDate && onChangeDateRange({ start: startDate })
            }
            label={startInputLabel ?? "開始日"}
            slotProps={{
              textField: {
                inputProps: {
                  "aria-label": "start-date",
                },
              },
            }}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sm="auto" display={{ xs: "none", sm: "flex" }}>
          <Typography>〜</Typography>
        </Grid>
        <Grid item xs={12} sm={true}>
          <DesktopDatePicker
            value={dayjs(dateRangeLabel.end)}
            format="YYYY/MM/DD(ddd)"
            minDate={minDate}
            onChange={(endDate) =>
              endDate && onChangeDateRange({ end: endDate })
            }
            label={endInputLabel ?? "終了日"}
            slotProps={{
              textField: {
                inputProps: {
                  "aria-label": "end-date",
                },
              },
            }}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}
