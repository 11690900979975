import { AxiosPromise, AxiosError } from "axios"
import * as Yup from "yup"

import * as authApi from "src/api/auth"
import {
  ErrorResponse,
  ResetPasswordRequest,
  SignInRequest,
  SignInResponse,
} from "src/api/models"

export const postSignIn = (
  request: SignInRequest,
): AxiosPromise<SignInResponse> => {
  return authApi.signin(request, { withCredentials: true })
}

export const resetPassword = (
  request: ResetPasswordRequest,
): AxiosPromise<SignInResponse> => {
  return authApi.resetPassword(request, { withCredentials: true })
}

export const refreshToken = () => {
  return authApi.refreshToken({}, { withCredentials: true })
}
export const signOut = () => {
  return authApi.signOut({ withCredentials: true })
}

export const passwordValidationSchema = Yup.string()
  .required("必須です")
  .matches(/[a-z]/, "パスワードにはアルファベット小文字を含めてください")
  .matches(/[A-Z]/, "パスワードにはアルファベット大文字を含めてください")
  .matches(/[0-9]/, "パスワードには数字を含めてください")
  .matches(
    /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/,
    "パスワードには記号を含めてください",
  )
  .min(8, "パスワードは8文字以上で設定してください")
  .max(255)

const incorrectUsernameOrPasswordErrorMessage =
  "NotAuthorizedException: Incorrect username or password."

export const isIncorrectUsernameOrPassword = (
  e?: AxiosError<ErrorResponse>,
) => {
  const { status, data } = e?.response || {}
  return (
    status === 400 &&
    data?.message.includes(incorrectUsernameOrPasswordErrorMessage)
  )
}

export const isUnauthorized = (e?: AxiosError) => e?.response?.status === 401

export const isForbidden = (e?: AxiosError) => e?.response?.status === 403

export const isTimeout = (e?: AxiosError) => e?.code === "ECONNABORTED"
