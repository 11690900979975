import { SetStateAction } from "react"

import { MenuItem, Select, TextField } from "@mui/material"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { filterAccordionSearchState } from "src/recoil"

type SelectType = "true" | "false" | "null"

export type PrizeMeterReadFilterSearchParams = {
  isAvailable: SelectType
  prizeBoothName?: string
  isThincaTerminal: SelectType
  showOnlySoftEmpty: SelectType
}

export const defaultSearchParams = {
  isAvailable: "null",
  isThincaTerminal: "null",
  showOnlySoftEmpty: "null",
}

export const PrizeMeterReadFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeMeterReadFilterSearchParams =
    recoilSearchParams["prizeMeterReadsSearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeMeterReadFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeMeterReadsSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "isAvailable",
          label: "撤去",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null">すべて</MenuItem>
              <MenuItem value="false">撤去済</MenuItem>
              <MenuItem value="true">未撤去</MenuItem>
            </Select>
          ),
        },
        {
          name: "prizeBoothName",
          label: "プライズ機種名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "isThincaTerminal",
          label: "シンカクラウド連携",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null">すべて</MenuItem>
              <MenuItem value="true">連携あり</MenuItem>
              <MenuItem value="false">連携なし</MenuItem>
            </Select>
          ),
        },
        {
          name: "showOnlySoftEmpty",
          label: "入力情報",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value="null">すべて</MenuItem>
              <MenuItem value="true">入力済</MenuItem>
              <MenuItem value="false">未入力</MenuItem>
            </Select>
          ),
        },
      ]}
    />
  )
}
