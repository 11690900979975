import { PrizeShelf, PrizeStorage, PrizeStorageElement } from "src/api/models"

export const findShelfWithId = (
  storages: PrizeStorageElement[],
  shelfId: string,
) => {
  let storageRes: PrizeStorage | undefined
  let shelfRes: PrizeShelf | undefined
  storages.some(({ storage, shelves }) => {
    const res = shelves?.find((shelf) => shelf.id.toString() === shelfId)
    if (res) {
      storageRes = storage
      shelfRes = res
    }
    return !!res
  })
  return { storage: storageRes, shelf: shelfRes }
}
