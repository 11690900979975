/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type PrizePlacementStatusExecutionStatusEnum =
  (typeof PrizePlacementStatusExecutionStatusEnum)[keyof typeof PrizePlacementStatusExecutionStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizePlacementStatusExecutionStatusEnum = {
  NotExecuted: "not_executed",
  Executed: "executed",
  Empty: "empty",
} as const
