export const getEnv = () => {
  if (IS_DEBUG) {
    const localhost = LOCALHOST || "localhost"
    return {
      U1_API_BASE_URL: `http://${localhost}:8081/api`,
      SENTRY_ENVIRONMENT: "development",
    }
  } else if (IS_VADDY) {
    return {
      U1_API_BASE_URL: "https://api.vaddy.u1.stg.gigo-inside.jp/api",
      SENTRY_ENVIRONMENT: "staging",
    }
  } else if (IS_STAGING) {
    return {
      U1_API_BASE_URL: "https://api.u1.stg.gigo-inside.jp/api",
      SENTRY_ENVIRONMENT: "staging",
    }
  } else {
    return {
      U1_API_BASE_URL: "https://api.u1.gigo-inside.jp/api",
      SENTRY_ENVIRONMENT: "production",
    }
  }
}
