import { useEffect, useMemo, useState } from "react"

import { Card, CardHeader, CardContent, List, Stack } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { getUsers } from "src/api/users"
import { MenuButton } from "src/components/atoms/MenuButton"
import {
  ListTableRow,
  PaginatedTable,
} from "src/components/organisms/PaginatedTable"
import { SearchBarForm } from "src/components/organisms/SearchBarForm"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { snackbarErrorMessageState } from "src/recoil"
import { compareByPhoneticOrder } from "src/utils"

export const Users: React.FC = () => {
  const { pathname } = useLocation()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const [searchUsers, setSearchUsers] = useState(query.get("searchUsers"))
  const [lastSearchedAt, setLastSearchedAt] = useState<number>()
  const {
    isAdmin,
    isDepartmentLeader,
    isAreaLeader,
    isPrizeProducer,
    isStoreLeader,
    isFloorManager,
    isChief,
  } = useUserRole()

  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="ユーザー管理"
      caption="空欄で検索すると、閲覧可能な全ユーザーが表示されます"
      renderFilter={() => (
        <SearchBarForm
          placeholder="ユーザー名"
          onSearch={(value) => {
            setSearchUsers(value)
            setLastSearchedAt(new Date().getTime())
          }}
          defaultValue={searchUsers || ""}
          showDeleteButton
        />
      )}
      renderContent={() => (
        <Stack gap={2}>
          <UsersTable
            searchUsers={searchUsers}
            lastSearchedAt={lastSearchedAt}
          />
          <Card>
            <CardHeader title="メニュー" />
            <CardContent sx={{ pt: 0 }}>
              <List
                sx={(theme) => ({
                  width: "100%",
                  color: theme.palette.primary.main,
                })}
              >
                {isAdmin && (
                  <>
                    <MenuButton to={`${pathname}/new`}>
                      ユーザーを追加する
                    </MenuButton>
                    <MenuButton to={`${pathname}/reset/password`}>
                      ユーザーのパスワード再設定
                    </MenuButton>
                    <MenuButton to={`${pathname}/delete`}>
                      ユーザーを削除する
                    </MenuButton>
                  </>
                )}

                {(isDepartmentLeader ||
                  isAreaLeader ||
                  isPrizeProducer ||
                  isStoreLeader ||
                  isFloorManager ||
                  isChief) && (
                  <>
                    <MenuButton to={`${pathname}/staffs/reset/password`}>
                      店舗スタッフのパスワード再設定
                    </MenuButton>
                  </>
                )}
              </List>
            </CardContent>
          </Card>
        </Stack>
      )}
      onClickBackButton={() => navigate("/my-page")}
    />
  )
}

type UsersTableProps = {
  searchUsers: string | null
  lastSearchedAt?: number
}

const UsersTable: React.FC<UsersTableProps> = ({
  searchUsers,
  lastSearchedAt,
}) => {
  const { pathname } = useLocation()

  const [isFirstView, setIsFirstView] = useState(true)
  const users = useResource({
    subject: "ユーザー一覧の取得",
    fetch: searchUsers ? () => getUsers({ search: searchUsers }) : getUsers,
    recoilKey: `getUsers:${searchUsers}:${lastSearchedAt}`,
    skip: isFirstView,
  }).resource?.data.users.sort((a, b) => compareByPhoneticOrder(a.name, b.name))

  useEffect(() => setIsFirstView(false), [])

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  useEffect(() => {
    if (users?.length === 0) {
      setErrorMessage("検索結果が0件でした")
    }
  }, [setErrorMessage, users])

  return (
    <PaginatedTable
      noMargin
      items={users ?? []}
      renderRow={(user) => (
        <ListTableRow
          key={user.id}
          linkPath={`${pathname}/${user.id}`}
          label={user.name}
        />
      )}
    />
  )
}
