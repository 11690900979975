import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import {
  Box,
  Card,
  Table,
  TableBody,
  TablePagination,
  Link,
  TableRowProps,
  Stack,
} from "@mui/material"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { useRecoilState } from "recoil"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginationTableLabel } from "src/components/atoms/PaginationTableLabel"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { usePrevious } from "src/hooks/usePrevious"
import { paginatedTablesState } from "src/recoil"
import { theme } from "src/theme"

interface PaginatedTableState {
  page?: number
}

export interface PaginatedTablesState {
  [pathname: string]: {
    [stateKey: string]: PaginatedTableState
  }
}

interface PaginatedTableProps<T> {
  items: T[]
  renderRow: (item: T, index: number) => React.ReactElement
  emptyRowProps?: {
    colCount: number
    text: string
  }
  endRows?: React.ReactElement
  limit?: number
  header?: React.ReactNode
  scrollableX?: boolean
  scrollableY?: boolean
  stickyHeader?: boolean
  stateKey?: string
  noMargin?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PaginatedTable = <T extends Record<string, any>>({
  items,
  limit = 20,
  renderRow,
  emptyRowProps,
  endRows,
  header = <></>,
  scrollableX = false,
  scrollableY = false,
  stickyHeader = false,
  stateKey,
  noMargin = false,
}: PaginatedTableProps<T>) => {
  const { pathname } = useLocation()
  // NOTE: mount のタイミングを検出するため、初期値は undefined
  const [page, setPage] = useState<number>()
  // NOTE: TablePagination へ渡すために丸めたページ番号
  const safePage = useMemo(() => {
    if (!page) {
      return 0
    }
    if (items.length <= (page - 1) * limit) {
      return Math.floor(items.length / limit)
    }
    return page
  }, [page, items.length, limit])
  const pageSliceStart = limit * (safePage || 0)
  const pageSliceEnd = pageSliceStart + limit
  const tableRef = useRef<HTMLTableElement>(null)

  const [tablesState, setTablesState] = useRecoilState(paginatedTablesState)
  const setRecoilPage = useCallback(
    (page: number) => {
      if (stateKey) {
        setTablesState({
          ...tablesState,
          [pathname]: {
            ...(tablesState[pathname] || {}),
            [stateKey]: { page },
          },
        })
      }
    },
    [pathname, setTablesState, tablesState, stateKey],
  )

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView()
    }
    setPage(newPage)
    setRecoilPage(newPage)
  }

  const prevItems = usePrevious(items)
  useEffect(() => {
    // NOTE: テーブル状態を Recoil の state に保存している場合のみ実行
    if (!stateKey) {
      return
    }

    const recoilPage = (tablesState[pathname] || {})[stateKey]?.page
    if (prevItems === undefined) {
      if (recoilPage && items.length > (recoilPage - 1) * limit) {
        // NOTE: mount 時、state にページ番号が保存されていたら復元
        setPage(recoilPage)
        return
      }
    } else {
      if (JSON.stringify(prevItems) !== JSON.stringify(items)) {
        // NOTE: ソートやフィルタでレコードが更新された場合にページ番号をリセットする
        // NOTE: オブジェクトの配列における比較ではポインタ比較のため、文字列にして比較する
        // TODO: オブジェクトと配列が大きくなると、文字列化および文字列比較に時間がかかる可能性があるので、他の方法を検討する
        setPage(0)
        setRecoilPage(0)
        return
      }
    }
  }, [items, prevItems, tablesState, stateKey, limit, pathname, setRecoilPage])

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        m: noMargin ? 0 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          ...(scrollableX && { overflowX: "scroll" }),
          ...(scrollableY && { overflowY: "scroll" }),
        }}
      >
        <Table
          ref={tableRef}
          stickyHeader={stickyHeader}
          sx={{
            tableLayout: "auto",
          }}
        >
          {header}
          <TableBody
            sx={{
              tr: {
                ":last-child": {
                  td: {
                    borderBottom: "none",
                  },
                },
              },
            }}
          >
            {items.length === 0 && emptyRowProps && (
              <TableBorderedRow>
                <ExtTableCell
                  colSpan={emptyRowProps.colCount}
                  sx={{ textAlign: "center" }}
                >
                  {emptyRowProps.text}
                </ExtTableCell>
              </TableBorderedRow>
            )}
            {items
              .slice(pageSliceStart, pageSliceEnd)
              .map((item, i) => renderRow(item, i))}
            {endRows}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={items.length}
        onPageChange={handlePageChange}
        page={safePage}
        rowsPerPage={limit}
        rowsPerPageOptions={[limit]}
        sx={(theme) => ({
          flexShrink: 0,
          borderTop: "1px solid",
          borderColor: theme.palette.divider,
        })}
        labelDisplayedRows={({ from, to, count }) => (
          <PaginationTableLabel
            {...{
              from,
              to,
              count,
              offset: safePage + 1,
              limit,
            }}
            handlePageChange={(page) => handlePageChange(null, page)}
          />
        )}
      />
    </Card>
  )
}

interface ListTableRowProps {
  linkPath?: string
  label: string
}

export const ListTableRow: React.FC<ListTableRowProps & TableRowProps> = ({
  linkPath,
  label,
  ...rowProps
}) => {
  return (
    <TableBorderedRow hover {...rowProps}>
      <ExtTableCell sx={{ p: 0 }}>
        {linkPath ? (
          <Link
            to={linkPath}
            component={RouterLink}
            underline="none"
            sx={{ color: theme.palette.primary.main }}
          >
            <Stack sx={{ p: 2 }}>{label}</Stack>
          </Link>
        ) : (
          <Link underline="none" sx={{ color: theme.palette.primary.main }}>
            <Stack sx={{ p: 2 }}>{label}</Stack>
          </Link>
        )}
      </ExtTableCell>
    </TableBorderedRow>
  )
}
