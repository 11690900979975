import { useState, useEffect, Fragment } from "react"

import {
  Box,
  Grid,
  Card,
  Table,
  TableBody,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import {
  Material,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
  MaterialOperationStock,
} from "src/api/models"
import { InventoryMaterialMoveToShelf } from "src/components//templates/InventoryMaterialMoveToShelf"
import {
  CardKeyCell,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { InventoryMaterialMoveToMachine } from "src/components/templates/InventoryMaterialMoveToMachine"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useDeleteMaterialOperationStocks } from "src/hooks/useDeleteMaterialOperationStocks"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"

export interface MaterialPlacementIdMoveFrom {
  shelfId?: number
  materialMachineId?: number
}

export interface MaterialStockMoveFrom {
  operationShelfStock?: MaterialOperationShelfStock
  operationMachineStock?: MaterialOperationMachineStock
}

export const getPlacementMoveFrom = (stockMoveFrom: MaterialStockMoveFrom) => {
  const { operationShelfStock, operationMachineStock } = stockMoveFrom
  const { Storage, InMachine } = MaterialPlacementType
  if (operationShelfStock) {
    const {
      storage: { name: storageName },
      shelf: { name: shelfName },
      shelfStock: { stock, id },
    } = operationShelfStock
    return {
      name: `${storageName} ${shelfName}`,
      parentName: storageName,
      childName: shelfName,
      stock,
      placementType: Storage,
      id,
    }
  }
  if (operationMachineStock) {
    const {
      amMachine,
      materialMachine,
      machineStock: { stock, id },
    } = operationMachineStock
    return {
      name: getDisplayMaterialMachineName(amMachine, materialMachine),
      parentName: amMachine.amMachineName,
      childName: getDisplayMaterialMachineName(
        amMachine,
        materialMachine,
        true,
      ),
      stock,
      placementType: InMachine,
      id,
    }
  }
  return {
    name: "-",
    stock: 0,
  }
}

export const updateMaterialStockMoveFrom = (
  stockMoveFrom: MaterialStockMoveFrom,
  stocks: MaterialOperationStock[],
): MaterialStockMoveFrom => ({
  operationShelfStock: stocks.flatMap((stock) =>
    (stock.shelfStocks || []).filter(
      (stock) => stock.shelf.id === stockMoveFrom.operationShelfStock?.shelf.id,
    ),
  )[0],
  operationMachineStock: stocks.flatMap((stock) =>
    (stock.machineStocks || []).filter(
      (stock) =>
        stock.machineStock.machineShelfId ===
        stockMoveFrom.operationMachineStock?.machineStock.machineShelfId,
    ),
  )[0],
})

interface InventoryMaterialStockDetailsProps {
  materialCd: Material["materialCd"]
  isTemporaryStorages?: boolean
  defaultMaterialPlacementIdMoveFrom?: MaterialPlacementIdMoveFrom
}

export const InventoryMaterialStockDetails: React.FC<
  InventoryMaterialStockDetailsProps
> = (props) => {
  return <InventoryMaterialStockDetailsMenu {...props} />
}

const InventoryMaterialStockDetailsMenu: React.FC<
  InventoryMaterialStockDetailsProps
> = ({
  materialCd,
  isTemporaryStorages = false,
  defaultMaterialPlacementIdMoveFrom = {},
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const [placementIdMoveFrom, setMaterialPlacementIdMoveFrom] =
    useState<MaterialPlacementIdMoveFrom>(defaultMaterialPlacementIdMoveFrom)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )
  const { resource, refetch } = useResource({
    subject: "在庫情報の取得",
    fetch: arcadeCd
      ? () => getMaterialOperationStocks(arcadeCd, { materialCd })
      : undefined,
    // FIXME: refetch 時のローディング表示を避けるため、他コンポーネントと key がかぶらないようコンポーネント名を入れている
    recoilKey: `InventoryMaterialStockDetails:getMaterialOperationStocks:${arcadeCd}:${materialCd}`,
  })
  const stocks = resource?.data.stocks
  useEffect(() => {
    if (stocks?.length === 0) return navigate(-1)
  }, [stocks, navigate])
  const stock = stocks?.find((s) => s.material.materialCd === materialCd)

  const onFinish = () => refetch()

  const getMaterialStockMoveFrom = () => {
    const { shelfId, materialMachineId } = placementIdMoveFrom || {}
    const { shelfStocks, machineStocks } = stock || {}

    if (shelfId) {
      const operationShelfStock = shelfStocks?.find(
        (s) => s.shelf.id === shelfId,
      )
      if (operationShelfStock) {
        return { operationShelfStock }
      }
    }
    if (materialMachineId) {
      const operationMachineStock = machineStocks?.find(
        (s) => s.materialMachine.id === materialMachineId,
      )
      if (operationMachineStock) {
        return { operationMachineStock }
      }
    }
    return
  }
  const stockMoveFrom: MaterialStockMoveFrom | undefined =
    getMaterialStockMoveFrom()

  const [placementTypeMoveTo, setMaterialPlacementTypeMoveTo] =
    useState<MaterialPlacementType>()
  const { Storage, InMachine } = MaterialPlacementType
  const { materialName, unitPriceJpy } = stock?.material || {}

  // NOTE: 荷捌きの場合は棚がひとつだけなので、先頭の棚を自動選択
  useEffect(() => {
    if (isTemporaryStorages && stock?.shelfStocks) {
      setMaterialPlacementIdMoveFrom({
        shelfId: stock.shelfStocks[0]?.shelf.id,
      })
    }
  }, [isTemporaryStorages, stock])

  const onFinishMoving = () => {
    onFinish()
    if (isTemporaryStorages && stock?.shelfStocks) {
      setMaterialPlacementIdMoveFrom({
        shelfId: stock.shelfStocks[0]?.shelf.id,
      })
    } else {
      setMaterialPlacementIdMoveFrom(placementIdMoveFrom)
    }
    setMaterialPlacementTypeMoveTo(undefined)
  }

  const listItems = [
    {
      key: "材料名",
      value: materialName,
    },
    {
      key: "材料CD",
      value: materialCd,
    },
    {
      key: "材料単価",
      value: unitPriceJpy ? unitPriceJpy + "円" : "",
    },
  ].filter((item) => !!item)

  const { onDeleteMaterialOperationStocks } = useDeleteMaterialOperationStocks()

  const onDeleteSubmit = async () => {
    if (!stockMoveFrom || !arcadeCd) return
    const stock = getPlacementMoveFrom(stockMoveFrom)
    if (stock.id) {
      onDeleteMaterialOperationStocks({
        arcadeCd,
        placementStockId: stock.id,
        placement: stock.placementType,
        onFinish,
      })
    }
    setShowDeleteModal(false)
  }

  if (!placementTypeMoveTo) {
    return (
      <>
        <DeleteModal
          showModal={showDeleteModal}
          onSubmit={onDeleteSubmit}
          onClose={() => setShowDeleteModal(false)}
          isSubmitting={false}
        />
        <Stack mb={1}>
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
        <Stack gap={2}>
          <Card>
            <Table>
              <TableBody>
                {listItems.map(
                  (item) =>
                    item && (
                      <TableBorderedRow key={item.key}>
                        <CardKeyCell>
                          <Typography variant="body2">{item.key}</Typography>
                        </CardKeyCell>
                        <CardValueCell>
                          <Typography variant="body2"> {item.value}</Typography>
                        </CardValueCell>
                      </TableBorderedRow>
                    ),
                )}

                <TableBorderedRow>
                  <CardKeyCell>
                    <Typography variant="body2">在庫</Typography>
                  </CardKeyCell>
                  <CardValueCell>
                    <Typography variant="body2" component="div">
                      <RadioGroup>
                        <Grid container mb={-1}>
                          {(stock?.shelfStocks || [])
                            .filter((s) =>
                              hideEmptyMaterial ? s.shelfStock.stock > 0 : true,
                            )
                            .map((s) => (
                              <Fragment key={s.shelf.id}>
                                <Grid item xs={9} data-testid={s.shelf.name}>
                                  <FormControlLabel
                                    onChange={() =>
                                      setMaterialPlacementIdMoveFrom({
                                        shelfId: s.shelf.id,
                                      })
                                    }
                                    checked={
                                      !!stockMoveFrom?.operationShelfStock &&
                                      s.shelf.id ===
                                        stockMoveFrom.operationShelfStock.shelf
                                          .id
                                    }
                                    disableTypography
                                    control={<Radio sx={{ p: 0, pr: 1 }} />}
                                    label={`${s.storage.name} ${s.shelf.name}`}
                                  />
                                </Grid>

                                <Grid item xs={3} data-testid={s.shelf.name}>
                                  {s.shelfStock.stock} 個
                                </Grid>
                              </Fragment>
                            ))}
                          {(stock?.machineStocks || [])
                            .filter((s) =>
                              hideEmptyMaterial
                                ? s.machineStock.stock > 0
                                : true,
                            )
                            .map((s) => (
                              <Fragment key={`${s.machineShelf.id}`}>
                                <Grid
                                  item
                                  xs={9}
                                  data-testid={s.materialMachine.cylinderName}
                                >
                                  <FormControlLabel
                                    onChange={() =>
                                      setMaterialPlacementIdMoveFrom({
                                        materialMachineId: s.materialMachine.id,
                                      })
                                    }
                                    checked={
                                      !!stockMoveFrom?.operationMachineStock &&
                                      s.materialMachine.id ===
                                        stockMoveFrom.operationMachineStock
                                          .materialMachine.id
                                    }
                                    disableTypography
                                    control={<Radio sx={{ p: 0, pr: 1 }} />}
                                    label={getDisplayMaterialMachineName(
                                      s.amMachine,
                                      s.materialMachine,
                                    )}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  data-testid={s.materialMachine.cylinderName}
                                >
                                  {s.machineStock.stock} 個
                                </Grid>
                              </Fragment>
                            ))}
                        </Grid>
                      </RadioGroup>
                    </Typography>
                  </CardValueCell>
                </TableBorderedRow>
              </TableBody>
            </Table>
          </Card>

          <Button
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            onClick={() => setMaterialPlacementTypeMoveTo(InMachine)}
            disabled={!stockMoveFrom}
          >
            材料機械に投入する
          </Button>

          <Button
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            onClick={() => setMaterialPlacementTypeMoveTo(Storage)}
            disabled={!stockMoveFrom}
          >
            棚に移動する
          </Button>

          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => setShowDeleteModal(true)}
            disabled={
              !stockMoveFrom || getPlacementMoveFrom(stockMoveFrom).stock !== 0
            }
          >
            削除する
          </Button>
        </Stack>
      </>
    )
  } else if (stock && stockMoveFrom) {
    if (placementTypeMoveTo === InMachine) {
      return (
        <InventoryMaterialMoveToMachine
          material={stock.material}
          stockMoveFrom={stockMoveFrom}
          onFinish={() => onFinishMoving()}
        />
      )
    }
    if (placementTypeMoveTo === Storage) {
      return (
        <InventoryMaterialMoveToShelf
          material={stock.material}
          stockMoveFrom={stockMoveFrom}
          onFinish={() => onFinishMoving()}
        />
      )
    }
  }
  return <Box></Box>
}
