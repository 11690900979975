import { Box, Typography, Chip } from "@mui/material"

import { Material } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  TableBorderedRow,
  CardItemNameBox,
} from "src/components/molecules/CardTableCells"

type InventoryMaterialsTableRowProps = {
  material: Pick<Material, "materialCd" | "materialName">
  stock?: number
  onRowClick: React.MouseEventHandler<HTMLDivElement>
  executed?: boolean
  executedAt?: string
}

export const InventoryMaterialsTableRow: React.FC<
  InventoryMaterialsTableRowProps
> = ({ material, stock, onRowClick, executed, executedAt }) => {
  return (
    <TableBorderedRow
      key={material.materialCd}
      data-testid={material.materialCd}
    >
      <ExtTableCell
        sx={{
          p: 2,
          "&:hover": {
            background: (theme) => theme.palette.neutral[200],
          },
        }}
      >
        <Box sx={{ display: "flex", cursor: "pointer" }} onClick={onRowClick}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexGrow: 1,
                mb: 0.5,
              }}
            >
              <Typography
                variant="body2"
                component={CardItemNameBox}
                color="primary.main"
                sx={{ mr: 2 }}
              >
                {material.materialName}
              </Typography>
              <Typography
                variant="body2"
                component={Box}
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {stock}個
              </Typography>
            </Box>
            <Typography color="text.secondary" variant="caption">
              {material.materialCd}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 0.5,
            mt: 0.5,
          }}
        >
          {executed ? (
            <Chip
              label="済"
              color="error"
              size="small"
              sx={{
                px: 1,
                fontSize: 18,
                fontWeight: "bold",
              }}
            />
          ) : (
            <Box />
          )}
          {executedAt && executedAt != "" && (
            <Typography variant="caption">実査日: {executedAt}</Typography>
          )}
        </Box>
      </ExtTableCell>
    </TableBorderedRow>
  )
}
