import {
  ExecutionPeriod,
  FeatureExecutionPeriod,
  Area,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { ArcadeDetail } from "src/domains/arcadeRepository"
import { getToday } from "src/utils"

export const findCurrentInventoryPeriod = (periods: ExecutionPeriod[]) =>
  periods.find(
    (period) => period.startAt <= getToday() && period.endAt >= getToday(),
  )

export const findNextInventoryPeriod = (periods: ExecutionPeriod[]) =>
  periods.find((period) => period.startAt > getToday())

// NOTE: 現棚卸期間の endAt が今日かつ次の期間が存在する場合、その期間は棚卸確定処理済みと扱う
export const isPeriodEndAlreadySet = (periods: ExecutionPeriod[]) => {
  const currentPeriodEndsToday =
    findCurrentInventoryPeriod(periods)?.endAt === getToday()
  const nextPeriodExists = !!findNextInventoryPeriod(periods)
  return currentPeriodEndsToday && nextPeriodExists
}

export const findCurrentFeatureExecutionPeriod = (
  periods: FeatureExecutionPeriod[],
) =>
  periods.find(
    (period) => period.startAt <= getToday() && period.endAt >= getToday(),
  )

export const findPreviousFeatureExecutionPeriod = (
  periods: ExecutionPeriod[],
  feature: "prize" | "material",
  featurePeriod: FeatureExecutionPeriod | undefined = undefined,
) => {
  let featureExecutionPeriods: FeatureExecutionPeriod[] = []
  if (feature === "prize") {
    featureExecutionPeriods = periods
      .flatMap((period) => period.prizeExecutionPeriods)
      .sort((a, b) => (a.endAt <= b.endAt ? 1 : -1))
  } else {
    featureExecutionPeriods = periods
      .flatMap((period) => period.materialExecutionPeriods)
      .sort((a, b) => (a.endAt <= b.endAt ? 1 : -1))
  }
  const index = featureExecutionPeriods.findIndex((period) =>
    featurePeriod === undefined
      ? period.startAt <= getToday() && period.endAt >= getToday()
      : period.startAt === featurePeriod.startAt &&
        period.endAt === featurePeriod.endAt,
  )
  return featureExecutionPeriods[index + 1]
}

export const findLastFeatureExecutionPeriod = (
  currentInventoryPeriod: ExecutionPeriod | undefined,
) => {
  if (currentInventoryPeriod === undefined) {
    return {}
  }
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )
  const prizeExecutionPeriod = currentPrizeExecutionPeriod
    ? currentPrizeExecutionPeriod
    : currentInventoryPeriod?.prizeExecutionPeriods[0]
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )
  const materialExecutionPeriod = currentMaterialExecutionPeriod
    ? currentMaterialExecutionPeriod
    : currentInventoryPeriod?.materialExecutionPeriods[0]
  return { prizeExecutionPeriod, materialExecutionPeriod }
}

export const findNextFeatureExecutionPeriod = (
  periods: FeatureExecutionPeriod[],
) => periods.find((period) => period.startAt > getToday())

export const isPrizePeriodEndedToday = (periods: ExecutionPeriod[]) => {
  const currentPeriod = findCurrentFeatureExecutionPeriod(
    periods.flatMap((period) => period.prizeExecutionPeriods),
  )
  return currentPeriod
    ? currentPeriod.status !== FeatureExecutionPeriodStatusEnum.Active
    : false
}

export const isMaterialPeriodEndedToday = (periods: ExecutionPeriod[]) => {
  const currentPeriod = findCurrentFeatureExecutionPeriod(
    periods.flatMap((period) => period.materialExecutionPeriods),
  )
  return currentPeriod
    ? currentPeriod.status !== FeatureExecutionPeriodStatusEnum.Active
    : false
}

// TODO: periods が endAt 順に並べ直す
export const findLastExecutionPeriod = (periods: ExecutionPeriod[]) =>
  periods.find((period) => period.endAt !== executionPeriodTentativeEndAt)

export const executionPeriodTentativeEndAt = "2200-12-31"

export type LastExecutionPeriodSearchParams = {
  lastEndAt: ExecutionPeriod["endAt"]
  lastEndAtFilter: keyof typeof lastEndAtFilterLabels
  prizeExecutionStatus: FeatureExecutionPeriodStatusEnum | undefined
  materialExecutionStatus: FeatureExecutionPeriodStatusEnum | undefined
  areaId?: Area["id"]
  sortBy: "" | keyof typeof executionPeriodsSortLabel
}

export const lastEndAtFilterLabels = {
  endAtSame: "と一致",
  endAtNewer: "以降",
  endAtOlder: "以前",
} as const

export const featureExecutionStatusLabels = {
  active: {
    key: FeatureExecutionPeriodStatusEnum.Active,
    label: "未実査",
  },
  ended: {
    key: FeatureExecutionPeriodStatusEnum.Ended,
    label: "全実査済",
  },
  skipped: {
    key: FeatureExecutionPeriodStatusEnum.Skipped,
    label: "カウント無し済",
  },
  kidsMarketEnded: {
    key: FeatureExecutionPeriodStatusEnum.KidsMarketEnded,
    label: "KM景品のみ済",
  },
} as const

export const executionPeriodsSortLabel = {
  endAtDesc: "日付新しい順",
  endAtAsc: "日付古い順",
} as const

export const filterArcadeDetailsByLastExecutionPeriods = (
  arcades: ArcadeDetail[],
  params: LastExecutionPeriodSearchParams,
  showAbolishedArcades: boolean,
) => {
  const {
    lastEndAt,
    lastEndAtFilter,
    prizeExecutionStatus,
    materialExecutionStatus,
    areaId,
    sortBy,
  } = params

  const getSortCompareBy = () => {
    if (sortBy === "endAtDesc") {
      return (a: ArcadeDetail, b: ArcadeDetail) =>
        a.lastEndAt <= b.lastEndAt ? 1 : -1
    }
    if (sortBy === "endAtAsc") {
      return (a: ArcadeDetail, b: ArcadeDetail) =>
        a.lastEndAt >= b.lastEndAt ? 1 : -1
    }

    return (a: ArcadeDetail, b: ArcadeDetail) =>
      a.arcadeCd >= b.arcadeCd ? 1 : -1
  }

  return arcades
    .filter((a) => {
      if (lastEndAt !== "") {
        if (lastEndAtFilter === "endAtNewer") {
          return a.lastEndAt >= lastEndAt
        } else if (lastEndAtFilter === "endAtOlder") {
          return a.lastEndAt <= lastEndAt
        } else {
          return a.lastEndAt === lastEndAt
        }
      }
      return true
    })
    .filter((a) => (areaId ? a.areaId === areaId : true))
    .filter((a) =>
      prizeExecutionStatus
        ? a.lastPrizeExecutionStatus === prizeExecutionStatus
        : true,
    )
    .filter((a) =>
      materialExecutionStatus
        ? a.lastMaterialExecutionStatus === materialExecutionStatus
        : true,
    )
    .filter((a) => (showAbolishedArcades ? true : !a.abolitionFlag))
    .sort(getSortCompareBy())
}
