import { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Box,
  Card,
  DialogContent,
  Typography,
} from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs as DateAdapter } from "@mui/x-date-pickers/AdapterDayjs"
import * as dayjs from "dayjs"
import "dayjs/locale/ja"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useRecoilValue } from "recoil"
import * as Yup from "yup"

import {
  PrizeToneAlert,
  PrizeToneBoothInfo,
  PrizeToneAlertConjunctionEnum,
  PrizeToneAlertPayoutRateRangeEnum,
  PrizeToneAlertSalesRangeEnum,
  PrizeToneMachineAlertPayoutRateRangeEnum,
  PrizeToneMachineAlertSalesRangeEnum,
  PutPrizeToneAlertRequestConjunctionEnum,
} from "src/api/models"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import {
  MachineAlertParams,
  MachineAlerts,
} from "src/components/organisms/MachineAlerts"
import { alertDefaultValueState } from "src/recoil"
import { isNumberSet, isValidDateLabel } from "src/utils"

export interface AlertParams
  extends Required<
    Pick<
      PrizeToneAlert,
      "payoutRateRange" | "payoutRate" | "salesRange" | "sales" | "conjunction"
    >
  > {
  name?: string
  dateLabel: string
  machineAlerts?: MachineAlertParams[]
}

export const checkPayoutRateAlert = (
  booth: PrizeToneBoothInfo,
  range: PrizeToneAlertPayoutRateRangeEnum,
  thresholdPayoutRate: number,
) => {
  if (range === "gte") {
    return (booth.dailyPayOut || 0) >= thresholdPayoutRate
  }
  if (range === "lte") {
    return (booth.dailyPayOut || 0) <= thresholdPayoutRate
  }
  return false
}

export const checkSalesAlert = (
  booth: PrizeToneBoothInfo,
  range: PrizeToneAlertSalesRangeEnum,
  thresholdSales: number,
) => {
  if (range === "gte") {
    return (booth.dailySales || 0) >= thresholdSales
  }
  if (range === "lte") {
    return (booth.dailySales || 0) <= thresholdSales
  }
  return false
}

interface AlertFormModalProps {
  alertParams: AlertParams
  setAlertParams: React.Dispatch<React.SetStateAction<AlertParams>>
}

export const AlertFormModal: React.FC<AlertFormModalProps> = ({
  alertParams,
  setAlertParams,
}: AlertFormModalProps) => {
  const [showModal, setShowModal] = useState(false)
  const onClose = () => setShowModal(false)

  return (
    <Box>
      <Card>
        <Box p={1}>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={5} p={2} sx={{ whiteSpace: "nowrap" }}>
              {alertParams.dateLabel}
            </Grid>
            <Grid item xs={7}>
              <Button
                onClick={() => setShowModal(true)}
                variant="contained"
                fullWidth
                sx={{ px: 0 }}
              >
                日付・アラート設定
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>

      <CustomDialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={showModal}
        onClose={() => onClose()}
      >
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1"> 日付・アラート設定</Typography>{" "}
        </DialogTitleWithClose>

        <DialogContent>
          <AlertForm {...{ alertParams, setAlertParams, onClose }} />
        </DialogContent>
      </CustomDialog>
    </Box>
  )
}

export const alertRangeFormLabels = {
  gte: "以上",
  lte: "以下",
}

export const getAlertRangeMenuItem = (rangeEnum: object) => {
  return Object.keys(rangeEnum)
    .filter((key): key is keyof typeof rangeEnum => !!key)
    .map((key) => {
      const range = rangeEnum[key]
      return (
        <MenuItem key={range} value={range}>
          {alertRangeFormLabels[range]}
        </MenuItem>
      )
    })
}

interface AlertFormProps extends AlertFormModalProps {
  onClose?: () => void
}

export const AlertForm: React.FC<AlertFormProps> = ({
  alertParams,
  setAlertParams,
  onClose = () => undefined,
}: AlertFormProps) => {
  const alertDefaultValue = useRecoilValue(alertDefaultValueState)
  const [datePickerDate, setDatePickerDate] = useState(
    dayjs(alertParams.dateLabel),
  )

  const validationSchema = Yup.object({
    dateLabel: Yup.string().required("必須です"),
    machineAlerts: Yup.array(
      Yup.object({
        machineName: Yup.string().required("必須です"),
        payoutRate: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("必須です"),
        sales: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .required("必須です"),
        payoutRateRange:
          Yup.string<PrizeToneMachineAlertPayoutRateRangeEnum>().required(
            "必須です",
          ),
        salesRange:
          Yup.string<PrizeToneMachineAlertSalesRangeEnum>().required(
            "必須です",
          ),
      }),
    ),
    name: Yup.string(),
    payoutRate: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("必須です"),
    sales: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("必須です"),
    payoutRateRange:
      Yup.string<PrizeToneAlertPayoutRateRangeEnum>().required("必須です"),
    salesRange: Yup.string<PrizeToneAlertSalesRangeEnum>().required("必須です"),
    conjunction:
      Yup.string<PrizeToneAlertConjunctionEnum>().required("必須です"),
  })

  const useFormReturn = useForm<AlertParams>({
    resolver: yupResolver<AlertParams>(validationSchema),
  })
  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useFormReturn

  useEffect(() => {
    // TODO: 日付が今日以降の場合のバリデーションを追加
    const newDateLabel = datePickerDate.format().split("T")[0]
    if (!isValidDateLabel(newDateLabel)) {
      return setValue("dateLabel", "")
    }
    setValue("dateLabel", newDateLabel, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [datePickerDate, setValue])

  useEffect(() => {
    reset(alertParams)
  }, [alertParams, reset])

  const onSubmit: SubmitHandler<AlertParams> = (data) => {
    const payoutRate = isNumberSet(data.payoutRate)
      ? Number(data.payoutRate)
      : alertDefaultValue.payoutRate
    const sales = isNumberSet(data.sales)
      ? Number(data.sales)
      : alertDefaultValue.sales
    const machineAlerts = data.machineAlerts?.map((a) => ({
      ...a,
      payoutRate: Number(a.payoutRate),
      sales: Number(a.sales),
    }))

    setAlertParams({
      ...data,
      payoutRate,
      sales,
      machineAlerts,
    })
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2.5} pb={2}>
          日付
        </Grid>

        <Grid item xs={9.5} pb={2}>
          <FormControl fullWidth error={"dateLabel" in errors}>
            <LocalizationProvider
              adapterLocale={"ja"}
              dateAdapter={DateAdapter}
            >
              <DesktopDatePicker
                value={datePickerDate}
                format="YYYY-MM-DD"
                onChange={(date) => date && setDatePickerDate(date)}
              />
            </LocalizationProvider>
            {errors.dateLabel?.message && (
              <FormHelperText>{errors.dateLabel?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={2.5} pb={2}>
          PO率
        </Grid>
        <Grid item xs={4} pb={2}>
          <TextField
            defaultValue={alertParams.payoutRate}
            placeholder={alertDefaultValue.payoutRate.toString()}
            error={"payoutRate" in errors}
            helperText={errors.payoutRate?.message}
            {...register("payoutRate")}
            inputProps={{ inputMode: "decimal" }}
          />
        </Grid>
        <Grid item xs={1.5} pb={2} pl={1}>
          ％
        </Grid>
        <Grid item xs={4} pb={2}>
          <FormControl fullWidth error={"payoutRateRange" in errors}>
            <Controller
              name="payoutRateRange"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || alertParams.payoutRateRange}
                >
                  {getAlertRangeMenuItem(PrizeToneAlertPayoutRateRangeEnum)}
                </Select>
              )}
            />

            {errors.payoutRateRange?.message && (
              <FormHelperText>{errors.payoutRateRange?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={3} />
        <Grid item xs={6} pb={2}>
          <FormControl fullWidth error={"conjunction" in errors}>
            <Controller
              name="conjunction"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || alertParams.conjunction}
                >
                  <MenuItem value={PutPrizeToneAlertRequestConjunctionEnum.Or}>
                    OR
                  </MenuItem>
                  <MenuItem value={PutPrizeToneAlertRequestConjunctionEnum.And}>
                    AND
                  </MenuItem>
                </Select>
              )}
            />

            {errors.payoutRateRange?.message && (
              <FormHelperText>{errors.conjunction?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={2.5} pb={2}>
          売上
        </Grid>
        <Grid item xs={4} pb={2}>
          <TextField
            defaultValue={alertParams.sales}
            placeholder={alertDefaultValue.sales.toString()}
            error={"sales" in errors}
            helperText={errors.sales?.message}
            {...register("sales")}
            inputProps={{ inputMode: "numeric" }}
          />
        </Grid>
        <Grid item xs={1.5} pb={2} pl={1}>
          円
        </Grid>
        <Grid item xs={4} pb={2}>
          <FormControl fullWidth error={"salesRange" in errors}>
            <Controller
              name="salesRange"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || alertParams.salesRange}
                >
                  {getAlertRangeMenuItem(PrizeToneAlertSalesRangeEnum)}
                </Select>
              )}
            />
            {errors.salesRange?.message && (
              <FormHelperText>{errors.salesRange?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} pt={1}>
          <MachineAlerts useFormReturn={useFormReturn} />
        </Grid>

        <Grid item xs={12} pt={2}>
          <Button variant="contained" type="submit" fullWidth>
            反映
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
