import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { MaterialOperationStock } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import {
  InventoryMaterialVerifyStockFilter,
  inventoryMaterialVerifyStockSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialVerifyStockFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  isStockEmpty,
  sortMaterialOperationStocks,
} from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export const InventoryMaterialVerifyStocks: React.FC = () => {
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  return (
    <MainContentLayout
      title="材料一覧から投入"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialVerifyStockFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialVerifyStocksMenu />}
    />
  )
}

const InventoryMaterialVerifyStocksMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const searchParams = useRecoilValue(
    inventoryMaterialVerifyStockSearchParamsState,
  )
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)
  const { resource } = useResource({
    subject: "在庫検索結果の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialOperationStocks(arcadeCd, {
            materialName: searchParams.materialName,
            materialNameKana: searchParams.materialNameKana,
            materialCd: searchParams.materialCd,
            storageId: searchParams.storageId,
            shelfIds: searchParams.shelfId ? [searchParams.shelfId] : undefined,
            amMachineNumber: searchParams.amMachineNumber,
            amMachineName: searchParams.amMachineName,
            materialMachineIds: searchParams.materialMachineId
              ? [searchParams.materialMachineId]
              : undefined,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = resource?.data.stocks
    if (!stocks) return

    return sortMaterialOperationStocks(
      stocks.filter((s) => (hideEmptyMaterial ? !isStockEmpty(s) : true)),
      searchParams,
    )
  }, [hideEmptyMaterial, resource, searchParams])

  return (
    filteredStocks && (
      <Stack>
        <InventoryMaterialStocksTable
          stocks={filteredStocks}
          onSelectedMaterialCd={(materialCd: string) =>
            navigate(
              `/arcades/${arcadeCd}/materials/move/stocks/materials/${materialCd}`,
            )
          }
        />
      </Stack>
    )
  )
}

interface InventoryMaterialStocksTableProps {
  stocks: MaterialOperationStock[]
  onSelectedMaterialCd: (materialCd: string) => void
}

const InventoryMaterialStocksTable: React.FC<
  InventoryMaterialStocksTableProps
> = ({ stocks, onSelectedMaterialCd }: InventoryMaterialStocksTableProps) => {
  return (
    <PaginatedTable
      noMargin
      items={stocks}
      stateKey="inventoryMaterialVerifyStocks-inventoryMaterialStocksTable"
      renderRow={(stock) => (
        <TableBorderedRow key={stock.material.materialCd}>
          <ExtTableCell
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                background: theme.palette.neutral[200],
              },
            }}
            onClick={() => onSelectedMaterialCd(stock.material.materialCd)}
          >
            <CardItemNameBox>{stock.material.materialName}</CardItemNameBox>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
