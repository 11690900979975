import { useCallback } from "react"

import * as Sentry from "@sentry/react"
import axios from "axios"
import { useResetRecoilState, useSetRecoilState } from "recoil"

import { isTimeout, signOut } from "src/domains/authRepository"
import {
  cognitoSubState,
  idTokenState,
  meState,
  resourceMapState,
  snackbarErrorMessageState,
} from "src/recoil"
import { LoginTokens } from "src/types"

export const useAuth = () => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const setIdToken = useSetRecoilState(idTokenState)
  const setCognitoSub = useSetRecoilState(cognitoSubState)
  const setResourceMap = useSetRecoilState(resourceMapState)
  const login = useCallback(
    ({ idToken, cognitoSub }: LoginTokens) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`
      setIdToken(idToken)
      setCognitoSub(cognitoSub)
    },
    [setIdToken, setCognitoSub],
  )

  const resetResourceMap = () => {
    const newMap = new Map()
    // NOTE: ログアウト時に refreshToken がコールされるのを回避する処置
    newMap.set("AuthProvider:refreshToken", { status: "skipped" })
    setResourceMap(newMap)
  }
  const resetMe = useResetRecoilState(meState)

  const logout = async () => {
    try {
      await signOut()
      delete axios.defaults.headers.common["Authorization"]
      setIdToken(null)
      setCognitoSub(null)
      resetResourceMap()
      resetMe()
      Sentry.setUser(null)
    } catch (e) {
      if (axios.isAxiosError(e) && isTimeout(e)) {
        return setErrorMessage("タイムアウトしました")
      }
      throw e
    }
  }

  return {
    login,
    logout,
  }
}
