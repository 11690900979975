import { useState } from "react"

import { ExpandCircleDown } from "@mui/icons-material"
import {
  Grid,
  Button,
  FormControl,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  MenuItem,
  Select,
} from "@mui/material"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useRecoilState, useRecoilValue } from "recoil"

import { AmMachineUsageCategoryEnum } from "src/api/models"
import { inventoryMaterialAmMachinesSearchParamsState } from "src/recoil/inventoryMaterials"

export const InventoryMaterialAmMachinesFilter: React.FC = () => {
  const { amMachineNumber, amMachineName, isAvailable, usageCategory } =
    useRecoilValue(inventoryMaterialAmMachinesSearchParamsState)

  const [expanded, setExpanded] = useState(
    !!(amMachineNumber || amMachineName || isAvailable || usageCategory),
  )

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={
          <ExpandCircleDown
            sx={(theme) => ({ color: theme.palette.gray["40"] })}
          />
        }
      >
        絞り込み
      </AccordionSummary>

      <AccordionDetails
        sx={{
          m: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InventoryMaterialPlacementsFilterForm />
      </AccordionDetails>
    </Accordion>
  )
}

export interface InventoryMaterialPlacementsFilterFormInput {
  amMachineNumber: string
  amMachineName: string
  isAvailable: "true" | "false" | ""
  usageCategory: AmMachineUsageCategoryEnum
}

export const InventoryMaterialPlacementsFilterForm: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialAmMachinesSearchParamsState,
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InventoryMaterialPlacementsFilterFormInput>()

  const onSubmit: SubmitHandler<InventoryMaterialPlacementsFilterFormInput> = ({
    amMachineNumber,
    amMachineName,
    isAvailable,
    usageCategory,
  }) => {
    setSearchParams({
      amMachineNumber,
      amMachineName,
      isAvailable:
        isAvailable === "" ? undefined : isAvailable === "true" ? true : false,
      usageCategory,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={4} pb={1}>
          AM機器番号
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={searchParams.amMachineNumber || ""}
            error={"amMachineNumber" in errors}
            helperText={errors.amMachineNumber?.message}
            inputProps={{ "aria-label": "amMachineNumber" }}
            {...register("amMachineNumber")}
          />
        </Grid>

        <Grid item xs={4} pb={1}>
          有効/無効
        </Grid>
        <Grid item xs={8} pb={1}>
          <FormControl fullWidth error={"isAvailable" in errors}>
            <Controller
              name="isAvailable"
              control={control}
              defaultValue={
                searchParams.isAvailable === undefined
                  ? ""
                  : searchParams.isAvailable
                    ? "true"
                    : "false"
              }
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value ?? ""}
                  inputProps={{
                    "aria-label": "isAvailable",
                  }}
                >
                  <MenuItem key="" value="" sx={{ height: 36 }} />
                  <MenuItem value="true">有効</MenuItem>
                  <MenuItem value="false">無効</MenuItem>
                </Select>
              )}
            />
            {errors.isAvailable?.message && (
              <FormHelperText>{errors.isAvailable?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} pb={1}>
          利用区分
        </Grid>
        <Grid item xs={8} pb={1}>
          <FormControl fullWidth error={"usageCategory" in errors}>
            <Controller
              name="usageCategory"
              control={control}
              defaultValue={searchParams.usageCategory}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value ?? ""}
                  inputProps={{
                    "aria-label": "usageCategory",
                  }}
                >
                  <MenuItem key="" value="" sx={{ height: 36 }} />
                  <MenuItem value={AmMachineUsageCategoryEnum.Material}>
                    材料
                  </MenuItem>
                  <MenuItem value={AmMachineUsageCategoryEnum.Other}>
                    その他
                  </MenuItem>
                </Select>
              )}
            />
            {errors.usageCategory?.message && (
              <FormHelperText>{errors.usageCategory?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} pb={1}>
          名前
        </Grid>
        <Grid item xs={8} pb={1}>
          <TextField
            defaultValue={searchParams.amMachineName || ""}
            error={"amMachineName" in errors}
            helperText={errors.amMachineName?.message}
            inputProps={{ "aria-label": "amMachineName" }}
            {...register("amMachineName")}
          />
        </Grid>

        <Grid item xs={12} pt={1}>
          <Button variant="contained" type="submit" fullWidth>
            反映
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
