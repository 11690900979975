import { useMemo } from "react"

import {
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { MaterialOperationStock } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { InventoryMaterialTemporaryStoragesFilter } from "src/components/organisms/materials/InventoryMaterialTemporaryStoragesFilter"
import { InventoryMaterialVerifyStockSearchParams } from "src/components/organisms/materials/InventoryMaterialVerifyStockFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  filterTemporaryStorages,
  sortMaterialOperationStocks,
  temporaryStorageName,
} from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import {
  hideEmptyMaterialState,
  inventoryMaterialTemporaryStoragesSearchParamsState,
} from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export type InventoryMaterialTemporaryStoragesSearchParams = Pick<
  InventoryMaterialVerifyStockSearchParams,
  | "materialName"
  | "materialNameKana"
  | "materialCd"
  | "ipName"
  | "makerName"
  | "sortBy"
>

export const InventoryMaterialTemporaryStorages: React.FC = () => {
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  return (
    <MainContentLayout
      title="荷捌き中の材料一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialTemporaryStoragesFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialTemporaryStoragesMenu />}
    />
  )
}

const InventoryMaterialTemporaryStoragesMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const searchParams = useRecoilValue(
    inventoryMaterialTemporaryStoragesSearchParamsState,
  )
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)
  const { resource } = useResource({
    subject: "荷捌き中材料一覧の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialOperationStocks(arcadeCd, {
            materialName: searchParams.materialName,
            materialNameKana: searchParams.materialNameKana,
            materialCd: searchParams.materialCd,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = resource?.data.stocks
    if (!stocks) return

    return sortMaterialOperationStocks(
      filterTemporaryStorages(stocks, hideEmptyMaterial),
      searchParams,
    )
  }, [hideEmptyMaterial, resource, searchParams])

  return (
    filteredStocks && (
      <Stack>
        <MaterialTemporaryStoragesTable
          stocks={filteredStocks}
          onSelectedMaterialCd={(materialCd: string) =>
            navigate(
              `/arcades/${arcadeCd}/materials/move/temporary/materials/${materialCd}`,
            )
          }
        />
      </Stack>
    )
  )
}

interface MaterialTemporaryStoragesTableProps {
  stocks: MaterialOperationStock[]
  onSelectedMaterialCd: (materialCd: string) => void
}

const MaterialTemporaryStoragesTable: React.FC<
  MaterialTemporaryStoragesTableProps
> = ({ stocks, onSelectedMaterialCd }: MaterialTemporaryStoragesTableProps) => {
  return (
    <PaginatedTable
      noMargin
      items={stocks}
      stateKey="materialTemporaryStorageTable"
      header={
        <TableHead>
          <TableRow>
            <ExtTableCell>材料名</ExtTableCell>
            <ExtTableCell sx={{ whiteSpace: "nowrap" }}>着荷数</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(stock) => (
        <TableBorderedRow key={stock.material.materialCd}>
          <ExtTableCell
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                background: theme.palette.neutral[200],
              },
            }}
            onClick={() => onSelectedMaterialCd(stock.material.materialCd)}
          >
            <CardItemNameBox>{stock.material.materialName}</CardItemNameBox>
          </ExtTableCell>
          <ExtTableCell>
            {
              stock.shelfStocks.find(
                (shelfStock) =>
                  shelfStock.storage.name === temporaryStorageName,
              )?.shelfStock.stock
            }
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
