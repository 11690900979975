import { forwardRef, useMemo } from "react"

import { Autocomplete, TextField } from "@mui/material"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"

type Value = string | number | null
export type LabeledAutocompleteItem = {
  label: string
  value: Value
}

export type LabeledAutocompleteProps = {
  onChange: (value: Value) => void
  value: Value
  items: LabeledAutocompleteItem[]
  inputLabel: string
  error: boolean
  disabled?: boolean
}

export const LabeledAutocomplete = forwardRef<
  HTMLElement,
  LabeledAutocompleteProps
>(({ onChange, value, items, inputLabel, error, disabled }, ref) => {
  const autocompleteValue = useMemo(() => {
    const label = items.find((item) => item.value === value)?.label ?? ""
    if (!label || !value) return null
    return {
      label,
      value,
    }
  }, [items, value])
  return (
    <Autocomplete
      ref={ref}
      value={autocompleteValue}
      options={items.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      renderOption={(props, option) => (
        <WordBreakAllList {...props} key={option.value}>
          {option.label}
        </WordBreakAllList>
      )}
      onChange={(_, option) => option && onChange(option.value)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} error={!!error} />
      )}
      slotProps={{
        clearIndicator: {
          sx: { display: "none" },
        },
      }}
      disabled={disabled}
    />
  )
})

LabeledAutocomplete.displayName = "LabeledAutocomplete"
