import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

type InventoryRemoveLockModalProps = {
  onSubmit: () => void
  onClose: () => void
  showModal: boolean
  isSubmitting: boolean
}

export const InventoryPrizeRemoveLockModal: React.FC<
  InventoryRemoveLockModalProps
> = ({ onSubmit, onClose, showModal, isSubmitting }) => {
  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => onClose()}
    >
      <DialogTitleWithClose onClose={() => onClose()}></DialogTitleWithClose>

      <DialogContent>
        <Typography variant="h1" pb={1}>
          棚卸の入力のロックを解除しますか？
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ロック解除すると、棚卸確定処理を行うまではブース内の棚卸入力や変更が可能になります。
        </Typography>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          onClick={() => onSubmit()}
          fullWidth
        >
          解除する
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
