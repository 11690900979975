import { useEffect, useState } from "react"

import { Box, Card, Table, TableBody, TableRow, Link } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
  setSessionStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
  setCookieItem,
  getCookieItem,
  removeCookieItem,
} from "src/storages"

const healthCheckString = "healthCheck"

export const HealthCheckSet: React.FC = () => {
  useEffect(() => {
    removeLocalStorageItem("healthCheck")
    setLocalStorageItem("healthCheck", healthCheckString)

    removeSessionStorageItem("healthCheck")
    setSessionStorageItem("healthCheck", healthCheckString)

    removeCookieItem("healthCheck")
    setCookieItem("healthCheck", healthCheckString)
  }, [])

  return (
    <Box m={3}>
      <Card>
        <Box m={3}>ストレージに確認用の値をセットしました</Box>

        <Box m={3}>
          <Link component={RouterLink} to="/health/get" underline="none">
            結果を確認する
          </Link>
        </Box>
      </Card>
    </Box>
  )
}

export const HealthCheckGet: React.FC = () => {
  const [isLocalStorageOk, setIsLocalStorageOk] = useState<
    boolean | undefined
  >()
  const [isSessionStorageOk, setIsSessionStorageOk] = useState<
    boolean | undefined
  >()
  const [isCookieOk, setIsCookieOk] = useState<boolean | undefined>()

  const checkHealth = (isOk?: boolean) => {
    if (isOk === true) {
      return "⭕"
    }
    if (isOk === false) {
      return "❌"
    }
    return "-"
  }

  useEffect(() => {
    setIsLocalStorageOk(
      getLocalStorageItem("healthCheck") === healthCheckString,
    )

    setIsSessionStorageOk(
      getSessionStorageItem("healthCheck") === healthCheckString,
    )

    setIsCookieOk(getCookieItem("healthCheck") === healthCheckString)
  }, [])

  return (
    <Box m={3}>
      <Card>
        <Table>
          <TableBody>
            <TableRow>
              <ExtTableCell>Local storage:</ExtTableCell>
              <ExtTableCell>{checkHealth(isLocalStorageOk)}</ExtTableCell>
            </TableRow>

            <TableRow>
              <ExtTableCell>Session storage:</ExtTableCell>
              <ExtTableCell>{checkHealth(isSessionStorageOk)}</ExtTableCell>
            </TableRow>

            <TableRow>
              <ExtTableCell>Cookie (Browser):</ExtTableCell>
              <ExtTableCell>{checkHealth(isCookieOk)}</ExtTableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box m={3}>
          <Link component={RouterLink} to="/health/set" underline="none">
            確認用の値を再度セットする
          </Link>
        </Box>
      </Card>
    </Box>
  )
}
