/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type ExecutionPeriodStatusEnum =
  (typeof ExecutionPeriodStatusEnum)[keyof typeof ExecutionPeriodStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExecutionPeriodStatusEnum = {
  Active: "active",
  Ended: "ended",
} as const
