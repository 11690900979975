import { SetStateAction } from "react"

import { MenuItem, Select, TextField } from "@mui/material"
import { useRecoilState } from "recoil"

import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  PrizeDailySortBy,
  prizeDailySortByLabels,
} from "src/domains/prizes/dailyRepository"
import { filterAccordionSearchState } from "src/recoil"
import { getDaysAgo, getToday } from "src/utils"

export type PrizeDailyFilterSearchParams = {
  dateRangeLabel: DateRangePickerDateLabel
  boothName?: string
  boothCategory?: string
  prizeCd?: string
  prizeName?: string
  sortBy: PrizeDailySortBy
}

export const defaultSearchParams = {
  dateRangeLabel: {
    start: getDaysAgo(7),
    end: getToday(),
  },
  sortBy: "recordedAtDesc",
}

export const PrizeDailyFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeDailyFilterSearchParams =
    recoilSearchParams["prizeDailySearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeDailyFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeDailySearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      formInputs={[
        {
          name: "dateRangeLabel",
          label: "日付",
          rowSpacing: 2,
          fullWidth: true,
          render: ({ field }) => (
            <DateRangePicker
              startInputLabel="開始日"
              endInputLabel="終了日"
              dateRangeLabel={field.value as DateRangePickerDateLabel}
              setDateRangeLabel={field.onChange}
            />
          ),
        },
        {
          name: "boothName",
          label: "プライズ機種名（ブース名）",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "boothCategory",
          label: "ブース区分",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              {Object.entries(prizeDailySortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
