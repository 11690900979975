import dayjs from "dayjs"

import {
  Prize,
  PrizeBooth,
  PrizeBoothSale,
  PrizeBoothSalesElement,
  PrizeDailyPlan,
  PrizeDailyPlansElement,
  PrizeDelivery,
  PrizeDeliveryElement,
  PrizeMonthlyPlan,
} from "src/api/models"
import { calcRatio, formatApiDate, getDatesBetween, round } from "src/utils"

type AggregatedSales = {
  count?: number
  sales?: number
  payout?: number
  payoutPrice?: number
  payoutRate?: number
  pdpb?: number
}

const calcAggregateSales = (
  sales: PrizeBoothSalesElement[] | undefined,
): AggregatedSales | undefined => {
  if (!sales) return undefined
  const total: AggregatedSales = sales.reduce(
    (prev, current) => ({
      sales:
        current.sale.sales !== undefined
          ? current.sale.sales + Number(prev.sales || 0)
          : prev.sales !== undefined
            ? prev.sales
            : undefined,
      payout:
        current.sale.payout !== undefined
          ? current.sale.payout + Number(prev.payout || 0)
          : prev.payout !== undefined
            ? prev.payout
            : undefined,
      payoutPrice:
        current.sale.payoutPrice !== undefined
          ? current.sale.payoutPrice + Number(prev.payoutPrice || 0)
          : prev.payoutPrice !== undefined
            ? prev.payoutPrice
            : undefined,
    }),
    {} as AggregatedSales,
  )
  const count = sales.filter(
    (sale) => sale.sale.sales !== undefined && sale.sale.payout !== undefined,
  ).length
  return {
    ...total,
    payoutRate: calcRatio(total.payoutPrice, total.sales),
    count: count,
    pdpb: calcRatio(total.sales, count),
  }
}

const calcPdpbRatio = (
  as1: AggregatedSales | undefined,
  as2: AggregatedSales | undefined,
) => calcRatio(as1?.pdpb, as2?.pdpb)

type PrizeRank = "S" | "A" | "B" | "C"

const judgePrizeRank = (
  pdpbRatio: number | undefined,
): PrizeRank | undefined => {
  if (pdpbRatio === undefined) return undefined
  const p = pdpbRatio * 100
  if (p >= 200) return "S"
  else if (p >= 100) return "A"
  else if (p >= 50) return "B"
  return "C"
}

export type PrizeTotalSale = AggregatedSales

const toPrizeTotalSale = (aggregatedSales: AggregatedSales | undefined) =>
  ({
    ...aggregatedSales,
  }) as PrizeTotalSale

export type PrizeSale = AggregatedSales & {
  count: number
  pdpbRatio?: number
  prizeRank?: PrizeRank
}

const toPrizeSale = (
  aggregatedSales: AggregatedSales | undefined,
  pdpbRatio: number | undefined,
) =>
  ({
    count: aggregatedSales?.count || 0,
    sales: aggregatedSales?.sales,
    payout: aggregatedSales?.payout,
    payoutPrice: aggregatedSales?.payoutPrice,
    payoutRate: aggregatedSales?.payoutRate,
    pdpb: round(aggregatedSales?.pdpb),
    pdpbRatio: pdpbRatio,
    prizeRank: judgePrizeRank(pdpbRatio),
  }) as PrizeSale

export type PrizeArcadeSale = PrizeSale & {
  recordedAt: string
}

export type PrizeArcadeSales = {
  total: PrizeTotalSale
  items: PrizeArcadeSale[]
}

export const calcPrizeArcadeSales = function (
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  sales: PrizeBoothSalesElement[],
): PrizeArcadeSales {
  const dataSet = new Map<string, PrizeBoothSalesElement[]>() // key: 日付
  sales.forEach((sale) => {
    const dateStr = formatApiDate(sale.sale.recordedAt)
    dataSet.set(dateStr, [...(dataSet.get(dateStr) || []), sale])
  })

  const arcadeSales = calcAggregateSales(sales)
  const dates = getDatesBetween(from, to)
  return {
    total: toPrizeTotalSale(arcadeSales),
    items: dates.map((date) => {
      const dateStr = formatApiDate(date)
      const aggregatedSales = calcAggregateSales(dataSet.get(dateStr))
      const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

      return {
        ...toPrizeSale(aggregatedSales, pdpbRatio),
        recordedAt: dateStr,
      }
    }),
  }
}

export type PrizeOrderSale = PrizeSale & {
  consumptionRate?: number
  stock: number
  logicalStock?: number
  prize: Prize
  delivery: PrizeDelivery
}

export type PrizeOrderSales = {
  total: PrizeTotalSale
  items: PrizeOrderSale[]
}

export const calcPrizeOrderSales = function (
  deliveries: PrizeDeliveryElement[],
  sales: PrizeBoothSalesElement[],
  filterFn: (e: PrizeOrderSale) => boolean,
  sortFn: ((a: PrizeOrderSale, b: PrizeOrderSale) => number) | undefined,
): PrizeOrderSales {
  const salesSet = new Map<string, PrizeBoothSalesElement[]>() // key: PrizeCd
  sales.forEach((sale) => {
    if (sale.sale.prize) {
      const prizeCd = sale.sale.prize.prizeCd
      salesSet.set(prizeCd, [...(salesSet.get(prizeCd) || []), sale])
    }
  })

  const arcadeSales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(arcadeSales),
    items: deliveries
      .map((delivery) => {
        const prizeBoothSales = salesSet.get(delivery.prize.prizeCd)
        const aggregatedSales = calcAggregateSales(prizeBoothSales)
        const stock =
          delivery.delivery.orderCarton * delivery.prize.unitPerCarton
        const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

        return {
          ...toPrizeSale(aggregatedSales, pdpbRatio),
          consumptionRate: calcRatio(aggregatedSales?.payout, stock),
          stock: stock,
          logicalStock: aggregatedSales?.payout
            ? stock - aggregatedSales.payout
            : undefined,
          prize: delivery.prize,
          delivery: delivery.delivery,
        }
      })
      .filter(filterFn)
      .sort(sortFn),
  }
}

export type PrizeBoothDateRangeSale = PrizeSale & {
  booth: PrizeBooth
  latestSale?: PrizeBoothSale
  salesByDate: Map<string, number | undefined> // key: recordedAt (YYYY-MM-DD)
  machinePdpb?: number
}

export type PrizeBoothDateRangeSales = {
  items: PrizeBoothDateRangeSale[]
}

export const calcPrizeBoothDateRangeSales = function (
  sales: PrizeBoothSalesElement[],
): PrizeBoothDateRangeSales {
  const boothSet = new Map<string, PrizeBooth>() // key: BoothName
  const boothSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: BoothName
  const machineSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: MachineName

  sales.forEach((sale) => {
    const boothName = sale.booth.boothName
    boothSet.set(boothName, sale.booth)

    boothSalesSet.set(boothName, [
      ...(boothSalesSet.get(boothName) || []),
      sale,
    ])

    const machineName = sale.booth.machineName
    machineSalesSet.set(machineName, [
      ...(machineSalesSet.get(machineName) || []),
      sale,
    ])
  })

  const machineAggregatedSalesSet = new Map<string, AggregatedSales>() // key: MachineName
  Array.from(machineSalesSet.entries()).forEach(
    ([machineName, machineSales]) => {
      const machineAggregatedSales = calcAggregateSales(machineSales)
      if (machineAggregatedSales) {
        machineAggregatedSalesSet.set(machineName, machineAggregatedSales)
      }
    },
  )

  const items = Array.from(boothSet.values()).map((booth) => {
    const prizeBoothSales = boothSalesSet.get(booth.boothName)
    const latestSale = prizeBoothSales?.reduce(
      (prev, current) => {
        if (prev === undefined) return current
        return prev.sale.recordedAt >= current.sale.recordedAt ? prev : current
      },
      undefined as PrizeBoothSalesElement | undefined,
    )
    const filteredSales = prizeBoothSales?.filter(
      (sale) => sale.sale.prize?.prizeCd === latestSale?.sale.prize?.prizeCd,
    )

    const aggregatedSales = calcAggregateSales(filteredSales)
    const salesByDate = new Map<string, number | undefined>() // key: recordedAt
    filteredSales?.forEach((sale) => {
      const date = formatApiDate(sale.sale.recordedAt)
      const value = salesByDate.get(date)
      const sales =
        sale.sale.sales !== undefined
          ? sale.sale.sales + Number(value || 0)
          : undefined
      salesByDate.set(date, sales)
    })

    const machineAggregatedSales = machineAggregatedSalesSet.get(
      booth.machineName,
    )

    const pdpbRatio = calcPdpbRatio(aggregatedSales, machineAggregatedSales)

    return {
      ...toPrizeSale(aggregatedSales, pdpbRatio),
      booth: booth,
      latestSale: latestSale?.sale,
      salesByDate: salesByDate,
      machinePdpb: round(machineAggregatedSales?.pdpb),
    }
  })

  return {
    items: items,
  }
}

export type PrizeBoothDailySale = PrizeSale & {
  recordedAt: string
  prize?: Prize
}

export type PrizeBoothDailySales = {
  total: PrizeTotalSale
  items: PrizeBoothDailySale[]
}

export const calcPrizeBoothDailySales = function (
  sales: PrizeBoothSalesElement[],
): PrizeBoothDailySales {
  const boothSalesSet = new Map<string, PrizeBoothSalesElement>() // key: recordedAt
  sales.forEach((sale) => {
    boothSalesSet.set(formatApiDate(sale.sale.recordedAt), sale)
  })

  const boothAggregatedSales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(boothAggregatedSales),
    items: Array.from(boothSalesSet).map(([recordedAt, prizeBoothSale]) => {
      const aggregatedSales = calcAggregateSales([prizeBoothSale])
      const pdpbRatio = calcPdpbRatio(aggregatedSales, boothAggregatedSales)

      return {
        ...toPrizeSale(aggregatedSales, pdpbRatio),
        recordedAt: recordedAt,
        prize: prizeBoothSale.sale.prize,
      }
    }),
  }
}

export type PrizeIpSale = PrizeSale & {
  ipName: string
  countRatio?: number
  ranking: number
}

export type PrizeIpSales = {
  total: PrizeTotalSale
  items: PrizeIpSale[]
}

export const calcPrizeIpSales = function (
  sales: PrizeBoothSalesElement[],
): PrizeIpSales {
  const ipSalesSet = new Map<string, PrizeBoothSalesElement[]>() // key: ipName
  sales.forEach((sale) => {
    if (sale.sale.prize) {
      const ipName = sale.sale.prize.ipName
      ipSalesSet.set(ipName, [...(ipSalesSet.get(ipName) || []), sale])
    }
  })

  const arcadeSales = calcAggregateSales(sales)
  const ipSales = Array.from(ipSalesSet.keys()).map((ipName) => {
    const prizeBoothSales = ipSalesSet.get(ipName)
    const aggregatedSales = calcAggregateSales(prizeBoothSales)
    const pdpbRatio = calcPdpbRatio(aggregatedSales, arcadeSales)

    return {
      ...toPrizeSale(aggregatedSales, pdpbRatio),
      ipName: ipName,
      countRatio: calcRatio(aggregatedSales?.count, arcadeSales?.count),
    }
  })

  return {
    total: toPrizeTotalSale(arcadeSales),
    items: ipSales
      .sort((a, b) => (b.sales ?? -1) - (a.sales ?? -1))
      .map((sale, i) => ({ ...sale, ranking: i + 1 })),
  }
}

export type PrizeDailySale = PrizeSale & {
  recordedAt: string
  prize?: Prize
  booth: PrizeBooth
  plan?: PrizeDailyPlan
  delivery?: PrizeDelivery
  monthlyPlan?: PrizeMonthlyPlan
  consumptionRate?: number
  stock: number
  logicalStock?: number
}

export type PrizeDailySales = {
  total: PrizeTotalSale
  items: PrizeDailySale[]
}

export const calcPrizeDailySales = function (
  sales: PrizeBoothSalesElement[],
  plans: PrizeDailyPlansElement[],
  deliveries: PrizeDeliveryElement[],
  filterFn: (e: PrizeDailySale) => boolean,
  sortFn: ((a: PrizeDailySale, b: PrizeDailySale) => number) | undefined,
): PrizeDailySales {
  const dailySales = calcAggregateSales(sales)
  return {
    total: toPrizeTotalSale(dailySales),
    items: sales
      .map((sale) => {
        const targetSales = calcAggregateSales([sale])
        const pdpbRatio = calcPdpbRatio(targetSales, dailySales)
        // NOTE: PrizeDailyPlan と PrizeBoothSale の各 id は同じものを参照しているので、id で判定する
        const plan = plans.find((plan) => plan.plan.id === sale.sale.id)
        const delivery = deliveries.find(
          (delivery) => delivery.prize.prizeCd === sale.sale.prize?.prizeCd,
        )
        const stock =
          (delivery?.delivery?.orderCarton ?? 0) *
          (delivery?.prize?.unitPerCarton ?? 0)
        return {
          ...toPrizeSale(targetSales, pdpbRatio),
          recordedAt: sale.sale.recordedAt,
          prize: sale.sale.prize,
          booth: sale.booth,
          plan: plan?.plan,
          delivery: delivery?.delivery,
          monthlyPlan: delivery?.plan,
          consumptionRate: calcRatio(sale.sale?.payout, stock),
          stock: stock,
          logicalStock: sale.sale?.payout
            ? stock - sale.sale.payout
            : undefined,
        }
      })
      .filter(filterFn)
      .sort(sortFn),
  }
}

export const meterReadTemplateHeaders = [
  "プライズ機種名(ブース名)",
  "端末識別番号",
  "故障かどうか",
  "P/O管理方法",
  "見なしP/O",
  "100円コイン枚数初期値",
  "500円コイン枚数初期値",
  "プライズ初期値",
]
