import { Fragment } from "react"

import { ChevronRight } from "@mui/icons-material"
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
} from "@mui/material"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getArcade } from "src/api/arcades"
import { Arcade, ArcadeUser, UserRole } from "src/api/models"
import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { groupArcadeUsersByRole } from "src/domains/arcadeRepository"
import { useResource } from "src/hooks/useResource"
import { currentArcadeState, meState } from "src/recoil"
import { convertNameFromSnakeCase } from "src/utils"

export const UsersArcadeDetails: React.FC = () => {
  return (
    <MainContentLayout
      title="店舗所属ユーザー"
      renderContent={() => <InnerUsersArcadeDetails />}
      disableBackButton
    />
  )
}

const InnerUsersArcadeDetails: React.FC = () => {
  const { arcadeCd } = useParams()

  const setCurrentArcade = useSetRecoilState(currentArcadeState)

  const { arcade, arcadeUsers } =
    useResource({
      subject: "店舗情報の取得",
      fetch: arcadeCd
        ? async () => {
            const res = await getArcade(arcadeCd)
            setCurrentArcade(res.data.arcade)
            return res
          }
        : undefined,
      recoilKey: `getArcade:${arcadeCd}`,
    }).resource?.data ?? {}

  return (
    <Stack gap={2}>
      {arcade && <UsersArcadeDetailsArcade arcade={arcade} />}
      {arcade && <UsersArcadeDetailsUsers arcadeUsers={arcadeUsers ?? []} />}
    </Stack>
  )
}

interface UsersArcadeDetailsArcadeProps {
  arcade: Arcade
}

const UsersArcadeDetailsArcade: React.FC<UsersArcadeDetailsArcadeProps> = ({
  arcade,
}: UsersArcadeDetailsArcadeProps) => {
  const me = useRecoilValue(meState)
  const isAdmin = me?.userRole === UserRole.Admin

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mb: 1,
          }}
        >
          <Typography color="textPrimary" gutterBottom variant="h2">
            {arcade.name}
          </Typography>
        </Box>

        {isAdmin && (
          <Button
            variant="contained"
            fullWidth
            component={RouterLink}
            endIcon={<ChevronRight />}
            to={`/arcades/${arcade.arcadeCd}`}
          >
            店舗トップページへ
          </Button>
        )}
      </CardContent>
    </Card>
  )
}

interface UsersArcadeDetailsUsersProps {
  arcadeUsers: ArcadeUser[]
}

const UsersArcadeDetailsUsers: React.FC<UsersArcadeDetailsUsersProps> = ({
  arcadeUsers,
}: UsersArcadeDetailsUsersProps) => {
  const arcadeUsersByRoles = groupArcadeUsersByRole(arcadeUsers)

  const sortedUserRole: Exclude<UserRole, "unknown">[] = [
    "admin",
    "area_leader",
    "prize_producer",
    "store_leader",
    "floor_manager",
    "chief",
    "staff",
    "department_leader",
  ]

  return (
    <>
      <Card>
        <CardContent>
          {sortedUserRole
            .filter((role) => (arcadeUsersByRoles[role]?.length || 0) > 0)
            .map((role) => (
              <Fragment key={role}>
                <Typography variant="h2">
                  {convertNameFromSnakeCase(role)}
                </Typography>
                {arcadeUsersByRoles[role]?.map((user) => (
                  <Box
                    key={user.id}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <MenuButton
                      to={`/users/${user.id}`}
                      sx={{
                        wordBreak: "break-all",
                      }}
                    >
                      {user.name}
                    </MenuButton>
                  </Box>
                ))}
              </Fragment>
            ))}
          {arcadeUsers.length === 0 && (
            <Box>店舗所属ユーザーが存在しません</Box>
          )}
        </CardContent>
      </Card>
    </>
  )
}
