/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialInventoryHistoriesParams,
  GetMaterialInventoryHistoriesResponse,
  PutMaterialInventoryHistoriesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚卸の実行記録を取得
 */
export const getMaterialInventoryHistories = (
  arcadeCd: string,
  materialExecutionPeriodId: number,
  params?: GetMaterialInventoryHistoriesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryHistoriesResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/histories/${materialExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】棚卸を実行
 */
export const putMaterialInventoryHistories = (
  arcadeCd: string,
  date: string,
  putMaterialInventoryHistoriesRequest: PutMaterialInventoryHistoriesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/histories/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryHistoriesRequest,
    },
    options,
  )
}
export type GetMaterialInventoryHistoriesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryHistories>>
>
export type PutMaterialInventoryHistoriesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialInventoryHistories>>
>
