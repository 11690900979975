import { useMemo } from "react"

import { Typography, Stack, Card, TableHead, TableRow } from "@mui/material"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PrizeArcadeSales,
  calcPrizeArcadeSales,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  getDaysAgo,
  getJpDateLabel,
  getJpDateLabelColor,
  getRatioLabel,
  getToday,
} from "src/utils"

type PrizeWeeklyArcadeSalesDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(7),
  end: getToday(),
}

const prizeWeeklyArcadeSalesDateRangeLabelState =
  atom<PrizeWeeklyArcadeSalesDateRangeLabelState>({
    key: "prizeWeeklyArcadeSalesDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizeWeeklyArcadeSales: React.FC = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizeWeeklyArcadeSalesDateRangeLabelState,
  )

  return (
    <MainContentLayout
      title="日別のプライズ全体売上・P/O一覧"
      renderFilter={() => (
        <Card sx={{ px: 3, py: 4 }}>
          <DateRangePicker
            dateRangeLabel={dateRangeLabel}
            setDateRangeLabel={setDateRangeLabel}
            startInputLabel="開始日"
            endInputLabel="終了日"
          />
        </Card>
      )}
      renderContent={() => (
        <>
          <Stack sx={{ pb: 1 }}>
            <Typography sx={{ pb: 1, textAlign: "right" }} variant="body2">
              単位:千円
            </Typography>
          </Stack>
          <PrizeWeeklyArcadeSalesTable />
        </>
      )}
    />
  )
}

const PrizeWeeklyArcadeSalesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const { isAdmin } = useUserRole()

  const dateRangeLabel = useRecoilValue(
    prizeWeeklyArcadeSalesDateRangeLabelState,
  )
  const { start, end } = dateRangeLabel

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}`,
  }).resource

  const sales = useMemo(() => {
    if (!prizeBoothSalesReturn)
      return {
        total: {},
        items: [],
      } as PrizeArcadeSales

    return calcPrizeArcadeSales(
      dayjs(start),
      dayjs(end),
      prizeBoothSalesReturn?.data.sales || [],
    )
  }, [start, end, prizeBoothSalesReturn])

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 420px)",
      }}
    >
      <PaginatedTable
        scrollableX
        noMargin
        items={sales.items}
        stateKey="PrizeWeeklyArcadeSalesTable"
        stickyHeader
        header={
          <TableHead
            sx={{
              th: {
                textAlign: "center",
                whiteSpace: "nowrap",
                py: 1,
              },
            }}
          >
            <TableRow>
              <ExtTableCell border>日付</ExtTableCell>
              <ExtTableCell border>実績</ExtTableCell>
              <ExtTableCell>P/O</ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell header word="払出個数" />
                  <AdminExtTableCell header word="景品代" />
                  <AdminExtTableCell header word="店舗全体PDPB" />
                  <AdminExtTableCell header word="ブース数" />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(item) => {
          return (
            <TableRow
              key={"PrizeWeeklyArcadeSalesTable" + item.recordedAt}
              sx={{
                td: {
                  py: 1,
                },
              }}
            >
              <ExtTableCell
                sx={{
                  color: getJpDateLabelColor(item.recordedAt),
                  textAlign: "center",
                }}
                border
              >
                {getJpDateLabel(item.recordedAt)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }} border>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.payoutRate)}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payoutPrice?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.pdpb?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.count?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
        endRows={
          <TableRow
            sx={{
              td: {
                py: 1,
              },
            }}
          >
            <ExtTableCell border colSpan={1} sx={{ textAlign: "center" }}>
              総合計
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "end" }} border>
              {sales.total.sales?.toLocaleString() ?? "-"}
            </ExtTableCell>
            <ExtTableCell></ExtTableCell>

            {isAdmin && (
              <>
                <AdminExtTableCell
                  sx={{ textAlign: "end" }}
                  word={sales.total.payout?.toLocaleString() ?? "-"}
                />
                <AdminExtTableCell
                  sx={{ textAlign: "end" }}
                  word={sales.total.payoutPrice?.toLocaleString() ?? "-"}
                />
                <AdminExtTableCell
                  sx={{ textAlign: "end" }}
                  word={sales.total.pdpb?.toLocaleString() ?? "-"}
                />
                <AdminExtTableCell
                  sx={{ textAlign: "end" }}
                  word={sales.total.count?.toLocaleString() ?? "-"}
                />
              </>
            )}
          </TableRow>
        }
      />
    </Stack>
  )
}
