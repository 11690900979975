import { useCallback } from "react"

import { Button, Stack, Typography } from "@mui/material"
import * as Sentry from "@sentry/react"
import { useRecoilValue } from "recoil"

import { meState } from "src/recoil"

export const AppErrorBoundary: React.FC = () => {
  const me = useRecoilValue(meState)

  const onCrashReportDialogShow = useCallback(() => {
    const nameInput = document.querySelector('input[name="name"]')
    const emailInput = document.querySelector('input[name="email"]')
    nameInput?.setAttribute("disabled", "true")
    emailInput?.setAttribute("disabled", "true")
  }, [])

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
        mx: 2,
      }}
    >
      <Typography>
        エラーが発生しました。しばらくお待ちいただき、再度お試しください。
      </Typography>
      <Typography>
        もし改善しない場合は、こちらからクラッシュレポートをお送りください。
      </Typography>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        type="button"
        onClick={() =>
          Sentry.showReportDialog({
            title: "クラッシュレポートを送信",
            subtitle: "ID・アカウント名は変更しないでください。",
            subtitle1: "",
            user: {
              email: "sentryreport@gigo-inside.jp",
              name: me ? me.user.id.toString() : "not logged in",
            },
            labelEmail: "アカウント名",
            labelName: "ID",
            onLoad: onCrashReportDialogShow,
          })
        }
      >
        クラッシュレポート送信
      </Button>
    </Stack>
  )
}
