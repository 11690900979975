/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialInventoryPlacementStatusesParams,
  GetMaterialInventoryPlacementStatusesResponse,
  PutMaterialInventoryPlacementStatusRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚卸ステータスを設定
 */
export const putMaterialInventoryPlacementStatuses = (
  arcadeCd: string,
  placementType: "storage" | "in_machine",
  putMaterialInventoryPlacementStatusRequest: PutMaterialInventoryPlacementStatusRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/placementType/${placementType}/placements/statuses`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryPlacementStatusRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】棚卸ステータスを取得
 */
export const getMaterialInventoryPlacementStatuses = (
  arcadeCd: string,
  placementType: "storage" | "in_machine" | "all",
  materialExecutionPeriodId: number,
  params?: GetMaterialInventoryPlacementStatusesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryPlacementStatusesResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/placementType/${placementType}/placements/statuses/${materialExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
export type PutMaterialInventoryPlacementStatusesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialInventoryPlacementStatuses>>
>
export type GetMaterialInventoryPlacementStatusesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryPlacementStatuses>>
>
