import { useState, useMemo } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  DialogContent,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import {
  Csv,
  CsvRow,
  PrizeOperationStock,
  PostPrizeOperationStocksRequest,
  RequestPostPrizeOperationStock,
} from "src/api/models"
import {
  getPrizeOperationStocks,
  postPrizeOperationStocks,
} from "src/api/prize-operation-stocks"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { CsvTable } from "src/components/organisms/CsvTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { makeCSVRowsDataFromInventoryStocks } from "src/domains/inventoryCsvRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUploadCsv } from "src/hooks/useUploadCsv"
import { snackbarErrorMessageState } from "src/recoil"

const IMPORT_REQUIRED_COLUMNS = [
  "分類",
  "保管場所名",
  "棚名",
  "フロアマップ位置名",
  "景品CD",
  "個数",
] as const

const DIFF_TABLE_COLUMNS = [
  "分類",
  "保管場所名",
  "棚名",
  "フロアマップ位置名",
  "景品名",
  "景品CD",
  "変更前個数",
  "変更後個数",
] as const

const placementMap = new Map([
  ["プライズ機外", PlacementType.Storage],
  ["プライズ機上", PlacementType.OnBooth],
  ["プライズ機内", PlacementType.InBooth],
])

type DiffKey = {
  placement: string
  storageName: string
  shelfName: string
  floorMapPointName: string
  prizeCd: PrizeOperationStock["prize"]["prizeCd"]
}

type Diff = Map<
  // DiffKey stringified
  string,
  {
    prizeName: PrizeOperationStock["prize"]["prizeName"]
    prizeCd: PrizeOperationStock["prize"]["prizeCd"]
    stock: `${RequestPostPrizeOperationStock["stock"]}`
  }
>

export const InventoryPrizeBatchCsvUpdate = () => {
  return (
    <MainContentLayout
      title="CSVで在庫を一括登録"
      renderContent={() => <InventoryPrizeBatchCsvUpdateMenu />}
    />
  )
}

export const InventoryPrizeBatchCsvUpdateMenu = () => {
  const { arcadeCd } = useParams()

  const { uploadedCsv, onUploadCsv, resetUploadedCsv } = useUploadCsv({
    requiredColumns: IMPORT_REQUIRED_COLUMNS,
    allowEmptyRows: true,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { resource, refetch } = useResource({
    subject: "CSV出力用在庫一覧の取得",
    fetch: arcadeCd ? () => getPrizeOperationStocks(arcadeCd) : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}`,
  })
  const inventoryStocks = resource?.data.stocks

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = () => {
    const { headerRow, rows } = uploadedCsv || {}
    if (!headerRow || !rows) {
      setErrorMessage("CSVファイルの取り込みに失敗しました")
      return
    }

    const missingColumn = IMPORT_REQUIRED_COLUMNS.find(
      (column) => !headerRow.columns.includes(column),
    )

    if (missingColumn) {
      setErrorMessage(`「${missingColumn}」列の位置を検出できませんでした`)
      return
    }

    arcadeCd &&
      submitPromises([
        {
          subject: "在庫一括登録取り込み",
          showSuccessMessage: true,
          promise: async () => {
            const req: PostPrizeOperationStocksRequest = {
              stocks: rows.map((row, i) => {
                const placement =
                  row.columns[0] && placementMap.get(row.columns[0])
                if (!placement)
                  throw new Error(`${i + 2}行目: 分類が無効な値です`)
                const stock = Number(row.columns[5])
                if (isNaN(stock))
                  throw new Error(`${i + 2}行目: 個数が無効な値です`)

                /* eslint-disable @typescript-eslint/no-non-null-assertion */
                return {
                  placement,
                  storageName: row.columns[1]!,
                  shelfName: row.columns[2]!,
                  floorMapPointName: row.columns[3]!,
                  prizeCd: row.columns[4]!,
                  stock,
                }
                /* eslint-enable @typescript-eslint/no-non-null-assertion */
              }),
            }

            await postPrizeOperationStocks(arcadeCd, req)
            setIsChecked(false)
            resetUploadedCsv()
            refetch()
          },
        },
      ])
  }

  const diffTableData: Csv = useMemo(() => {
    const csvDiffs: Diff = new Map()
    const u1Diffs: Diff = new Map()
    const existingRows = makeCSVRowsDataFromInventoryStocks(
      inventoryStocks ?? [],
    )
    uploadedCsv?.rows.forEach((updatedRow) => {
      const notInInventoryStocks =
        existingRows.findIndex(
          (existingRow) =>
            existingRow.columns.toString() === updatedRow.columns.toString(),
        ) === -1

      if (notInInventoryStocks) {
        const { columns } = updatedRow
        const inventoryStock = inventoryStocks?.find(
          (stock) => stock.prize.prizeCd === columns[4],
        )
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const diffKey: DiffKey = {
          placement: columns[0]!,
          storageName: columns[1]!,
          shelfName: columns[2]!,
          floorMapPointName: columns[3]!,
          prizeCd: columns[4]!,
        }
        csvDiffs.set(JSON.stringify(diffKey), {
          prizeName: inventoryStock?.prize.prizeName ?? "",
          prizeCd: columns[4]!,
          stock: columns[5] as `${number}`,
        })
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    })
    existingRows.forEach((existingRow) => {
      const notInUpdatedCsv =
        uploadedCsv?.rows.findIndex(
          (updatedRow) =>
            existingRow.columns.toString() === updatedRow.columns.toString(),
        ) === -1

      if (notInUpdatedCsv) {
        const { columns } = existingRow
        const inventoryStock = inventoryStocks?.find(
          (stock) => stock.prize.prizeCd === columns[4],
        )
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const diffKey: DiffKey = {
          placement: columns[0]!,
          storageName: columns[1]!,
          shelfName: columns[2]!,
          floorMapPointName: columns[3]!,
          prizeCd: columns[4]!,
        }
        u1Diffs.set(JSON.stringify(diffKey), {
          prizeName: inventoryStock?.prize.prizeName ?? "",
          prizeCd: columns[4]!,
          stock: columns[5] as `${number}`,
        })
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      }
    })

    const diffRow: CsvRow[] = []

    csvDiffs.forEach(({ prizeName, prizeCd, stock: afterStock }, diffKey) => {
      const { placement, storageName, shelfName, floorMapPointName }: DiffKey =
        JSON.parse(diffKey)
      const U1Data = u1Diffs.get(
        JSON.stringify({
          placement,
          storageName,
          shelfName,
          floorMapPointName,
          prizeCd,
        }),
      )
      const { stock: beforeStock } = U1Data ?? {}

      // 個数が変わる場合 or 登録前にはなく登録後に存在する場合
      diffRow.push({
        columns: [
          placement,
          storageName,
          shelfName,
          floorMapPointName,
          prizeName,
          prizeCd,
          beforeStock ?? "-",
          afterStock,
        ],
      })
    })

    u1Diffs.forEach(({ prizeName, prizeCd, stock: beforeStock }, diffKey) => {
      const { placement, storageName, shelfName, floorMapPointName }: DiffKey =
        JSON.parse(diffKey)
      const csvData = csvDiffs.get(
        JSON.stringify({
          placement,
          storageName,
          shelfName,
          floorMapPointName,
          prizeCd,
        }),
      )

      if (csvData) return
      // NOTE: 変更前個数 0 かつ CSV 非存在の場合、変更後個数も 0 のままなので、差分から除外
      if (beforeStock === "0") return

      // 登録後に存在しなくなる場合
      diffRow.push({
        columns: [
          placement,
          storageName,
          shelfName,
          floorMapPointName,
          prizeName,
          prizeCd,
          beforeStock,
          "0", // NOTE: CSVに無いデータは個数 0 として更新される
        ],
      })
    })

    return {
      headerRow: { columns: [...DIFF_TABLE_COLUMNS] },
      rows: diffRow,
    }
  }, [inventoryStocks, uploadedCsv?.rows])
  const hasDiff = diffTableData.rows.length > 0

  const isStep1 = !isSubmitting && !uploadedCsv
  const isStep2 = !isSubmitting && !!uploadedCsv

  return (
    <Grid container spacing={2}>
      {isSubmitting && (
        <Grid item xs={12} sx={{ display: "flex" }}>
          <CircularProgress sx={{ margin: "auto" }} />
        </Grid>
      )}

      {isStep1 && (
        <>
          <Grid item xs={12}>
            在庫一括登録のため、CSVファイルをアップロードしてください。
          </Grid>

          <Grid item xs={12}>
            <CsvUploadButton {...{ onUploadCsv }} />
          </Grid>
        </>
      )}

      {isStep2 && (
        <>
          <UploadConfirmModal
            showModal={showModal}
            onSubmit={() => {
              setShowModal(false)
              onSubmit()
            }}
            onClose={() => setShowModal(false)}
          />
          <Grid item xs={12}>
            以下の現在庫との差分を取り込みます。よろしければ「この内容で登録する」を選択してください。
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  disabled={!hasDiff}
                />
              }
              label="差分をしっかり確認しました"
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="error"
              fullWidth
              onClick={() => setShowModal(true)}
              disabled={!isChecked || !hasDiff}
              loading={isSubmitting}
            >
              この内容で登録する
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{ background: "white" }}
              fullWidth
              onClick={() => resetUploadedCsv()}
            >
              やめる
            </Button>
          </Grid>

          <Grid item xs={12}>
            {hasDiff ? (
              <CsvTable csv={diffTableData} />
            ) : (
              <Card>
                <CardContent>
                  <Typography variant="h2">
                    CSVの内容と現在のデータに差分がありません
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}

interface CsvUploadButtonProps {
  onUploadCsv: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const CsvUploadButton: React.FC<CsvUploadButtonProps> = ({
  onUploadCsv,
}: CsvUploadButtonProps) => {
  return (
    <Button variant="contained" component="label" fullWidth>
      CSVファイルを選択する
      <input
        type={"file"}
        accept={".csv"}
        hidden
        onChange={(e) => onUploadCsv(e)}
      />
    </Button>
  )
}

type UploadConfirmModalProps = {
  showModal: boolean
  onSubmit: React.MouseEventHandler<HTMLButtonElement>
  onClose: React.MouseEventHandler<HTMLButtonElement>
}

const UploadConfirmModal: React.FC<UploadConfirmModalProps> = ({
  showModal,
  onSubmit,
  onClose,
}) => {
  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="h1">
            本当に登録しますか？
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={onClose}>閉じる</BackButton>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={onSubmit}
          >
            登録する
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}
