import { TextField, MenuItem, Select } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { Material } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { materialPlacementFilterSortByLabels } from "src/domains/materials/materialInventoryPlacementStatusRepository"

export const inventoryPlacementMaterialsSearchParamsState =
  atom<InventoryPlacementMaterialsSearchParams>({
    key: "inventoryPlacementMaterialsSearchParamsState",
    default: {},
  })

export type InventoryPlacementMaterialsSearchParams = {
  materialCd?: string
  materialNameKana?: string
  materialName?: Material["materialName"]
  makerName?: Material["makerName"]
  ipName?: Material["ipName"]
  sortBy?: keyof typeof materialPlacementFilterSortByLabels
}

export const InventoryMaterialPlacementMaterialsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryPlacementMaterialsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.materialCd ||
          searchParams.materialName ||
          searchParams.materialNameKana ||
          searchParams.makerName ||
          searchParams.ipName ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "materialCd",
          label: "材料CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialName",
          label: "材料名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialNameKana",
          label: "材料名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(materialPlacementFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
