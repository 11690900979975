import {
  Grid,
  Button,
  Typography,
  TableRow,
  TableHead,
  TextField,
  Box,
  Stack,
} from "@mui/material"
import {
  FieldErrors,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from "react-hook-form"
import { useParams } from "react-router-dom"

import { putMaterialOperationIn } from "src/api/material-operation-in"
import {
  MaterialArrival,
  MaterialArrivalElement,
  PutMaterialOperationInRequest,
} from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { useSubmitting } from "src/hooks/useSubmitting"
import { MaterialAcceptResult } from "src/pages/materials/register/InventoryMaterialAcceptSingleForm"
import { getJpDateLabel, getToday } from "src/utils"

interface InventoryMaterialAcceptMultipleFormInputNote {
  materialArrivalId: MaterialArrival["id"]
  note: string
}

interface InventoryMaterialAcceptMultipleFormInput {
  notes: InventoryMaterialAcceptMultipleFormInputNote[]
}

interface InventoryMaterialAcceptMultipleFormProps {
  arrivals: MaterialArrivalElement[]
  onAccepted: (results: MaterialAcceptResult[]) => void
}

export const InventoryMaterialAcceptMultipleForm: React.FC<
  InventoryMaterialAcceptMultipleFormProps
> = ({ arrivals, onAccepted }: InventoryMaterialAcceptMultipleFormProps) => {
  const { arcadeCd } = useParams()

  const acceptDate = getToday()
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<InventoryMaterialAcceptMultipleFormInput>({
    defaultValues: {
      notes: arrivals.map((a) => {
        return { materialArrivalId: a.arrival.id, note: "" }
      }),
    },
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<
    InventoryMaterialAcceptMultipleFormInput
  > = async (data) => {
    if (!arcadeCd) return
    const request: PutMaterialOperationInRequest = {
      materials: arrivals.map((a) => ({
        materialCd: a.material.materialCd,
        stock: a.arrival.orderCount,
        materialOrderId: a.arrival.id,
        note:
          data.notes.find(
            (note) => Number(note.materialArrivalId) === a.arrival.id,
          )?.note || "",
      })),
    }

    await submitPromises([
      {
        subject: "検収",
        showSuccessMessage: true,
        promise: async () => {
          await putMaterialOperationIn(arcadeCd, acceptDate, request)
          // TODO: putMaterialOperationIn のレスポンスから生成する (要API実装)
          onAccepted(
            arrivals.map((a) => {
              return {
                ...a.arrival,
                material: a.material,
                willArriveAt: acceptDate,
                stock: a.arrival.orderCount,
                cartonsCount: 0,
                separatedCount: a.arrival.orderCount,
                note:
                  data.notes.find(
                    (note) => Number(note.materialArrivalId) === a.arrival.id,
                  )?.note || "",
              }
            }),
          )
        },
      },
    ])
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography>表示中の着荷数で検収します</Typography>
      <Typography sx={{ mb: 2 }}>
        検収済の場合、着荷数が上書きされます
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="body2">
              検収日 <strong>{getJpDateLabel(acceptDate)}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <InventoryMaterialAcceptMultiArrivalsTable
              register={register}
              errors={errors}
              items={arrivals}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          fullWidth
        >
          まとめて検収
        </Button>
      </form>
    </Box>
  )
}

interface InventoryMaterialAcceptMultipleConfirmProps {
  results: MaterialAcceptResult[]
}

export const InventoryMaterialAcceptMultipleConfirm: React.FC<
  InventoryMaterialAcceptMultipleConfirmProps
> = ({ results }: InventoryMaterialAcceptMultipleConfirmProps) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack sx={{ mb: 2 }}>
        <AlertCaptionCard label="下記の通り検収しました" />
      </Stack>

      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="body2">
            検収日 <strong>{getJpDateLabel(results[0]?.willArriveAt)}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InventoryMaterialAcceptMultiArrivalsTable {...{ items: results }} />
        </Grid>
      </Grid>
    </Box>
  )
}

interface InventoryAcceptMultiArrivalsTableProps {
  items: (MaterialArrivalElement | MaterialAcceptResult)[]
  register?: UseFormRegister<InventoryMaterialAcceptMultipleFormInput>
  errors?: FieldErrors<InventoryMaterialAcceptMultipleFormInput>
}

const InventoryMaterialAcceptMultiArrivalsTable: React.FC<
  InventoryAcceptMultiArrivalsTableProps
> = ({ items, register, errors }: InventoryAcceptMultiArrivalsTableProps) => {
  const isRegister = register && errors
  return (
    <PaginatedTable
      scrollableX
      items={items}
      stateKey="inventoryMaterialTable"
      noMargin
      header={
        <TableHead>
          <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
            <ExtTableCell>材料名</ExtTableCell>
            <ExtTableCell>着荷数</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(item, index) => {
        const { material } = item
        const { arrival } = item as MaterialArrivalElement
        const result = item as MaterialAcceptResult
        const noteErrors =
          (isRegister && errors.notes && errors.notes[index]) || {}
        return (
          <>
            <TableBorderedRow
              key={material.materialCd}
              sx={{ td: { p: 1, borderBottomStyle: "none" } }}
            >
              <ExtTableCell sx={{ minWidth: 120 }}>
                <CardItemNameBox
                  sx={(theme) => ({ color: theme.palette.primary.main })}
                >
                  {material.materialName}
                </CardItemNameBox>
              </ExtTableCell>
              <ExtTableCell sx={{ minWidth: 80 }}>
                <CardItemNameBox>
                  {isRegister ? arrival.orderCount : result.stock}
                </CardItemNameBox>
              </ExtTableCell>
            </TableBorderedRow>
            {isRegister ? (
              <TableBorderedRow
                key={`${material.materialCd}-note`}
                sx={{ td: { p: 1, borderTopStyle: "none" } }}
              >
                <ExtTableCell colSpan={2}>
                  <TextField
                    label="備考"
                    fullWidth
                    error={"note" in noteErrors}
                    helperText={noteErrors.note?.message}
                    {...register(`notes.${index}.note`)}
                  />
                </ExtTableCell>
              </TableBorderedRow>
            ) : (
              result.note !== "" && (
                <TableBorderedRow
                  key={`${material.materialCd}-note`}
                  sx={{ td: { p: 1, borderTopStyle: "none" } }}
                >
                  <ExtTableCell colSpan={2}>{result.note}</ExtTableCell>
                </TableBorderedRow>
              )
            )}
          </>
        )
      }}
    />
  )
}
