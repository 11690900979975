import { Suspense, useState, useEffect, startTransition } from "react"

import {
  Typography,
  Grid,
  Card,
  Link,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  Box,
} from "@mui/material"
import { Link as RouterLink, useLocation, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMachines } from "src/api/machines"
import { PrizeToneBoothInfo, MachineCategory } from "src/api/models"
import { getPrizeToneBooths } from "src/api/prize-tone-booths"
import {
  CardKeyCell,
  CardSubtitleCell,
  CardItemNameBox,
  CardValueCell,
} from "src/components/molecules/CardTableCells"
import {
  BoothFilter,
  BoothFilterFormInput,
  filterBooths,
} from "src/components/organisms/BoothFilter"
import {
  encodeBoothUnitsQuery,
  getBoothUnitUILabel,
  findBoothYesterday,
} from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { boothSearchParamsState } from "src/recoil"
import { getToday, getYesterday, roundNearest100 } from "src/utils"

export interface BoothSearchParams {
  machineCategoryId?: MachineCategory["id"]
  ipName?: PrizeToneBoothInfo["ipName"]
  sortBy?: BoothFilterFormInput["sortBy"]
}

export const BoothsSearchTab: React.FC = () => {
  return (
    <>
      <Box sx={{ m: 1 }}>
        <BoothFilter />
      </Box>

      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        }
      >
        <BoothsSearchTabInner />
      </Suspense>
    </>
  )
}

const BoothsSearchTabInner: React.FC = () => {
  const { arcadeCd } = useParams()

  const [filteredBoothsToday, setFilteredBoothsToday] =
    useState<PrizeToneBoothInfo[]>()
  const boothSearchParams = useRecoilValue(boothSearchParamsState)

  const boothsToday = useResource({
    subject: "今日のブース情報の取得",
    fetch: arcadeCd
      ? () => getPrizeToneBooths(arcadeCd, { soldOn: getToday() })
      : undefined,
    recoilKey: `getBooths:${arcadeCd}:${getToday()}`,
  }).resource?.data.booths

  const boothsYesterday = useResource({
    subject: "昨日のブース情報の取得",
    fetch: arcadeCd
      ? () => getPrizeToneBooths(arcadeCd, { soldOn: getYesterday() })
      : undefined,
    recoilKey: `getBooths:${arcadeCd}:${getYesterday()}`,
  }).resource?.data.booths

  const machines = useResource({
    subject: "機種情報の取得",
    fetch: getMachines,
    recoilKey: `getMachines`,
  }).resource?.data.machines

  useEffect(() => {
    if (boothsToday && boothsYesterday && machines) {
      startTransition(() =>
        setFilteredBoothsToday(
          filterBooths(
            boothsToday,
            boothsYesterday,
            machines,
            boothSearchParams,
          ),
        ),
      )
    }
  }, [boothSearchParams, boothsToday, boothsYesterday, machines])

  return (
    <Grid container>
      {filteredBoothsToday?.map((boothToday) => (
        <Grid key={getBoothUnitUILabel(boothToday)} item xs={6} sm={4} lg={3}>
          <SearchTabBoothCard
            {...{
              boothToday,
              boothYesterday: boothsYesterday
                ? findBoothYesterday(boothToday, boothsYesterday)
                : undefined,
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
}

interface SearchTabBoothCardProps {
  boothToday: PrizeToneBoothInfo
  boothYesterday?: PrizeToneBoothInfo
}

const SearchTabBoothCard: React.FC<SearchTabBoothCardProps> = ({
  boothToday,
  boothYesterday,
}: SearchTabBoothCardProps) => {
  const { pathname } = useLocation()
  const { arcadeCd } = useParams()
  const { isViewableCurrentArcadeSales } = useUserRole()
  const { machineName, boothName, prizeCd, prizeName } = boothToday
  const { weeklySales, dailySales, dailyPayOut, weeklyPayOut } =
    boothYesterday || {}

  const PrizeNameBox = isViewableCurrentArcadeSales ? CardItemNameBox : Box

  return (
    <Card
      sx={{
        m: 1,
        pt: 1,
        pb: 1,
      }}
    >
      <Link
        component={RouterLink}
        to={`${pathname}/details?boothUnits=${encodeBoothUnitsQuery([
          { boothName, machineName },
        ])}&date=${getToday()}`}
        underline="none"
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <CardValueCell colSpan={2}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {getBoothUnitUILabel(boothToday)}
                </Typography>
              </CardValueCell>
            </TableRow>
            <TableRow>
              <CardValueCell colSpan={2}>
                <PrizeNameBox
                  sx={(theme) => ({
                    minHeight: 60,
                    fontSize: theme.typography.body2,
                  })}
                >
                  <Link
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/stocks/${encodeURIComponent(
                      prizeCd,
                    )}`}
                    underline="none"
                  >
                    {prizeName}
                  </Link>
                </PrizeNameBox>
              </CardValueCell>
            </TableRow>

            {isViewableCurrentArcadeSales &&
              (boothYesterday ? (
                <>
                  <TableRow>
                    <CardSubtitleCell colSpan={2}>昨日</CardSubtitleCell>
                  </TableRow>
                  <TableRow>
                    <CardKeyCell>売上</CardKeyCell>
                    <CardValueCell sx={{ px: 0.5 }}>
                      {dailySales
                        ? roundNearest100(dailySales).toLocaleString()
                        : "-"}
                      円
                    </CardValueCell>
                  </TableRow>
                  <TableRow>
                    <CardKeyCell>PO率</CardKeyCell>
                    <CardValueCell sx={{ px: 0.5 }}>
                      {dailyPayOut ? `${dailyPayOut.toFixed(1)}%` : "-"}
                    </CardValueCell>
                  </TableRow>

                  <TableRow>
                    <CardSubtitleCell colSpan={2}>
                      昨日からの1週間
                    </CardSubtitleCell>
                  </TableRow>
                  <TableRow>
                    <CardKeyCell>売上</CardKeyCell>
                    <CardValueCell sx={{ px: 0.5 }}>
                      {weeklySales
                        ? roundNearest100(weeklySales).toLocaleString()
                        : "-"}
                      円
                    </CardValueCell>
                  </TableRow>
                  <TableRow>
                    <CardKeyCell>PO率</CardKeyCell>
                    <CardValueCell sx={{ px: 0.5 }}>
                      {weeklyPayOut ? `${weeklyPayOut.toFixed(1)}%` : "-"}
                    </CardValueCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <CardValueCell colSpan={2}>
                    昨日のブース売上・PO率の情報が存在しません
                  </CardValueCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Link>
    </Card>
  )
}
