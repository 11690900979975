import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

type InventoryExecutionPeriodEndModalProps = {
  onSubmit: () => void
  onClose: () => void
  showModal: boolean
  isSubmitting: boolean
  msgType?: "end" | "skip" | "endKidsMarket"
  totalInventoryPrices?: number
}

export const InventoryPrizeExecutionPeriodEndModal: React.FC<
  InventoryExecutionPeriodEndModalProps
> = ({
  onSubmit,
  onClose,
  showModal,
  isSubmitting,
  totalInventoryPrices,
  msgType = "end",
}) => {
  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => onClose()}
    >
      <DialogTitleWithClose onClose={() => onClose()}></DialogTitleWithClose>

      {msgType === "end" && (
        <DialogContent>
          <Typography variant="h1" pb={1}>
            景品確定処理の確認
          </Typography>
          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              景品確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO NAVI上の景品棚卸データに変更が加えられなくなります
              </li>
              <li>棚／ブース／景品についた「済」マークが全て消えます</li>
            </ul>
            <br />
            <p>
              今回の棚卸期間の景品代：
              {totalInventoryPrices?.toLocaleString() || "-"}円
            </p>
          </Typography>
        </DialogContent>
      )}
      {msgType === "endKidsMarket" && (
        <DialogContent>
          <Typography variant="h1" pb={1}>
            KM景品の棚卸確定処理の確認
          </Typography>
          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              KM景品の確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO NAVI上の景品棚卸データに変更が加えられなくなります
              </li>
              <li>棚／ブース／景品についた「済」マークが全て消えます</li>
            </ul>
            <br />
            <p>
              今回の棚卸期間の景品代（KM景品のみ）：
              {totalInventoryPrices?.toLocaleString() || "-"}円
            </p>
          </Typography>
        </DialogContent>
      )}
      {msgType === "skip" && (
        <DialogContent>
          <Typography variant="h1" pb={1}>
            棚卸カウント無し処理の確認
          </Typography>
          <Typography variant="body2" color="error.main" fontWeight="bold">
            <p>
              棚卸カウント無し処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
            </p>
            <ul style={{ paddingLeft: "1em" }}>
              <li>
                今回のGiGO NAVI上の景品棚卸データに変更が加えられなくなります
              </li>
              <li>棚／ブース／景品についた「済」マークが全て消えます</li>
            </ul>
            <br />
          </Typography>
        </DialogContent>
      )}

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <LoadingButton
          variant="contained"
          color="error"
          type="submit"
          loading={isSubmitting}
          onClick={onSubmit}
          fullWidth
        >
          {msgType == "skip" ? "棚卸カウント無し処理をする" : "確定する"}
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
