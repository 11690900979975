import { useState, Fragment, ReactNode } from "react"

import { Card, Table, TableBody, Grid, Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { MaterialOperationStock } from "src/api/models"
import {
  TableBorderedRow,
  CardKeyCell,
  CardValueCell,
} from "src/components/molecules/CardTableCells"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import {
  InventoryMaterialOutForm,
  InventoryMaterialOutConfirm,
  MaterialOutResult,
} from "src/pages/materials/register/InventoryMaterialOutForm"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"

type PageState = "list" | "form" | "confirm"
type Page = {
  subTitle?: string
  content: ReactNode
  onClickBackButton?: () => void
  backButtonLabel?: string
}

export const InventoryMaterialOutDetails: React.FC = () => {
  const { arcadeCd, materialCd } = useParams()
  const [pageState, setPageState] = useState<PageState>("list")
  const [results, setResults] = useState<MaterialOutResult[]>([])

  const { resource, refetchForce } = useResource({
    subject: "在庫情報の取得",
    fetch:
      arcadeCd && materialCd
        ? () => getMaterialOperationStocks(arcadeCd, { materialCd })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialCd}`,
  })
  const stock = resource?.data.stocks.find(
    (s) => s.material.materialCd === materialCd,
  )

  const onClose = () => {
    setResults([])
    refetchForce()
    setPageState("list")
  }
  const onOut = () => {
    setResults([])
    refetchForce()
    setPageState("form")
  }
  const onFinish = (results: MaterialOutResult[]) => {
    setResults(results)
    refetchForce()
    setPageState("confirm")
  }

  let page = {} as Page
  if (pageState === "list") {
    page = {
      content: <InventoryMaterialOutDetailsMenu stock={stock} onOut={onOut} />,
      backButtonLabel: "検索画面に戻る",
    }
  }
  if (pageState === "form" && stock) {
    page = {
      subTitle: "店外に出庫する",
      content: <InventoryMaterialOutForm stock={stock} onFinish={onFinish} />,
      onClickBackButton: () => onClose(),
      backButtonLabel: "保存せず戻る",
    }
  }
  if (pageState === "confirm") {
    page = {
      subTitle: "",
      content: <InventoryMaterialOutConfirm results={results} />,
      backButtonLabel: "検索画面に戻る",
    }
  }

  return (
    <MainContentLayout
      title="店外出庫"
      subtitle={page.subTitle}
      renderContent={() => page.content}
      onClickBackButton={page.onClickBackButton}
      backButtonLabel={page.backButtonLabel}
    />
  )
}

interface InventoryMaterialOutDetailsMenuProps {
  stock?: MaterialOperationStock
  onOut: () => void
}

const InventoryMaterialOutDetailsMenu: React.FC<
  InventoryMaterialOutDetailsMenuProps
> = ({ stock, onOut }: InventoryMaterialOutDetailsMenuProps) => {
  const { materialCd, materialName } = stock?.material || {}
  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)

  const listItems = [
    {
      key: "材料名",
      value: materialName,
    },
    {
      key: "材料CD",
      value: materialCd,
    },
  ].filter((item) => !!item)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <Table>
            <TableBody>
              {listItems.map(
                (item) =>
                  item && (
                    <TableBorderedRow key={item.key}>
                      <CardKeyCell>{item.key}</CardKeyCell>
                      <CardValueCell>{item.value}</CardValueCell>
                    </TableBorderedRow>
                  ),
              )}

              <TableBorderedRow>
                <CardKeyCell>
                  プライズ
                  <br />
                  機外在庫
                </CardKeyCell>
                <CardValueCell>
                  <Grid container>
                    {stock?.shelfStocks.length === 0 && (
                      <Grid item xs={12}>
                        なし
                      </Grid>
                    )}
                    {stock?.shelfStocks
                      .filter((s) =>
                        hideEmptyMaterial ? s.shelfStock.stock > 0 : true,
                      )
                      .map((s) => (
                        <Fragment key={s.shelf.id}>
                          <Grid item xs={9} pr={1} pb={1}>
                            {s.storage.name} {s.shelf.name}
                          </Grid>

                          <Grid item xs={3} pb={1}>
                            {s.shelfStock.stock} 個
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </CardValueCell>
              </TableBorderedRow>
            </TableBody>
          </Table>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          disabled={!stock || stock.shelfStocks.length === 0}
          onClick={onOut}
        >
          店外に出庫する
        </Button>
      </Grid>
    </Grid>
  )
}
