import { detect, stringToCode, convert } from "encoding-japanese"
import { useSetRecoilState } from "recoil"

import { Csv } from "src/api/models"
import { snackbarErrorMessageState } from "src/recoil"
import { downloadFile } from "src/utils"

export const useDownloadCsv = () => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const getCsvFileData = (csv: Csv) => {
    const { headerRow, rows } = csv
    const data = [headerRow, ...rows]
      .map((row) => row?.columns.join(",") || "")
      .join("\n")

    const detectedEncoding = detect(data)

    if (!detectedEncoding) {
      return setErrorMessage("文字コードが判定できませんでした")
    }
    const converted = convert(stringToCode(data), {
      to: "SJIS",
      from: detectedEncoding,
    })
    return new Uint8Array(converted)
  }

  const downloadCsv = ({ csv, fileName }: { csv: Csv; fileName: string }) => {
    const csvFileData = getCsvFileData(csv)
    if (!csvFileData) return

    downloadFile({
      data: csvFileData,
      fileType: "text/csv",
      fileName,
    })
  }

  return {
    getCsvFileData,
    downloadCsv,
  }
}
