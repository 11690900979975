import { Suspense, useMemo, useCallback } from "react"

import { Box, Grid, CircularProgress } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import {
  PrizeToneBoothInfo,
  PrizeBoothUnit,
  PrizeToneAlertConjunctionEnum,
  PrizeToneAlertPayoutRateRangeEnum,
  PrizeToneAlertSalesRangeEnum,
} from "src/api/models"
import {
  AlertFormModal,
  AlertParams,
  checkPayoutRateAlert,
  checkSalesAlert,
} from "src/components/organisms/AlertFormModal"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
  FloorMapPointInfoBox,
} from "src/components/organisms/FloorMapBox"
import { MachineAlertParams } from "src/components/organisms/MachineAlerts"
import {
  decodeBoothUnitsQuery,
  encodeBoothUnitsQuery,
} from "src/domains/prizes/boothRepository"
import {
  BaseFloorMapPoint,
  ToneFloorMapPoint,
  convertToneFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import { useUserRole } from "src/hooks/useUserRole"
// eslint-disable-next-line strict-dependencies/strict-dependencies
import { useFloorMapData } from "src/pages/prizes/sales/FloorMap"
import { alertParamsState } from "src/recoil"
import { theme } from "src/theme"
import { getJpWeek, getBackgroundColors } from "src/utils"

interface FloorMapSalesTabFillType {
  fillSaleWeek?: boolean
  fillPrizeCd?: boolean
  fillBoothUnit?: boolean
  fillAlert?: boolean
  fillMemo?: boolean
}

const checkHasAlert = (
  booths: PrizeToneBoothInfo[],
  payoutRateRange: PrizeToneAlertPayoutRateRangeEnum,
  payoutRate: number,
  salesRange: PrizeToneAlertSalesRangeEnum,
  sales: number,
  conjunction: PrizeToneAlertConjunctionEnum,
) => {
  const isPointPayoutRateAlert = !!booths.find((booth) =>
    checkPayoutRateAlert(booth, payoutRateRange, payoutRate),
  )
  const isPointSalesAlert = !!booths.find((booth) =>
    checkSalesAlert(booth, salesRange, sales),
  )

  if (conjunction === PrizeToneAlertConjunctionEnum.And) {
    return isPointPayoutRateAlert && isPointSalesAlert
  }
  return isPointPayoutRateAlert || isPointSalesAlert
}

const checkHasMachineAlert = (
  pointBooths: PrizeToneBoothInfo[],
  machineAlert: MachineAlertParams,
  conjunction: PrizeToneAlertConjunctionEnum,
) => {
  const machineBooths = pointBooths.filter(
    (b) => b.machineName === machineAlert.machineName,
  )
  return checkHasAlert(
    machineBooths,
    machineAlert.payoutRateRange,
    machineAlert.payoutRate,
    machineAlert.salesRange,
    machineAlert.sales,
    conjunction,
  )
}

const getShouldFill = (
  point: ToneFloorMapPoint,
  alertParams: AlertParams,
  isViewableCurrentArcadeSales: boolean,
  prizeCd?: string,
  boothUnit?: PrizeBoothUnit,
): FloorMapSalesTabFillType => {
  const { pointBooths } = point
  const fillPrizeCd = !!(
    prizeCd && pointBooths.find((booth) => booth.prizeCd === prizeCd)
  )
  if (fillPrizeCd) {
    return { fillPrizeCd }
  }

  const fillBoothUnit = !!(
    boothUnit &&
    pointBooths.find((booth) => {
      const { machineName, boothName } = boothUnit
      return booth.machineName === machineName && booth.boothName === boothName
    })
  )
  if (fillBoothUnit) {
    return { fillBoothUnit }
  }

  const fillSaleWeek = !!pointBooths.find((booth) => {
    return (
      getJpWeek(booth.publishedAt ?? "") === getJpWeek(alertParams.dateLabel)
    )
  })

  const fillAlert = (): boolean => {
    if (isViewableCurrentArcadeSales) {
      const fillAlert = checkHasAlert(
        pointBooths,
        alertParams.payoutRateRange,
        alertParams.payoutRate,
        alertParams.salesRange,
        alertParams.sales,
        alertParams.conjunction,
      )
      if (fillAlert) {
        return fillAlert
      }

      const { machineAlerts } = alertParams
      const fillMachineAlert = !!machineAlerts?.find((a) =>
        checkHasMachineAlert(pointBooths, a, alertParams.conjunction),
      )
      if (fillMachineAlert) {
        return fillMachineAlert
      }
    }
    return false
  }

  return {
    fillSaleWeek: fillSaleWeek,
    fillAlert: fillAlert(),
  }
}

export const FloorMapSalesTab: React.FC = () => {
  const [alertParams, setAlertParams] = useRecoilState(alertParamsState)

  return (
    <>
      <Box mb={1}>
        <AlertFormModal {...{ alertParams, setAlertParams }} />
      </Box>

      <Grid container>
        <Suspense fallback={<CircularProgress sx={{ margin: "auto" }} />}>
          <FloorMapSalesTabInner />
        </Suspense>
      </Grid>
    </>
  )
}

const FloorMapSalesTabInner: React.FC = () => {
  const alertParams = useRecoilValue(alertParamsState)
  const { isViewableCurrentArcadeSales } = useUserRole()

  const { floorMap, booths } = useFloorMapData()

  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  // NOTE: 在庫詳細から遷移した場合に使用
  const prizeCd = query.get("prizeCd") || undefined
  // NOTE: 当日入れ替える景品一覧から遷移した場合に使用
  const boothUnitQuery = query.get("boothUnit") || undefined
  const boothUnit = boothUnitQuery
    ? decodeBoothUnitsQuery(boothUnitQuery)[0]
    : undefined

  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const floorMapPoints = useMemo(
    () =>
      floorMap?.floorMapPoints.map((p) =>
        convertToneFloorMapPoint(p, booths || []),
      ) || [],
    [floorMap, booths],
  )

  const onClickPoint = useCallback(
    (point: BaseFloorMapPoint) => {
      navigate(
        `/arcades/${arcadeCd}/prizes/sales/toneFloorMap/booths/details?boothUnits=${encodeBoothUnitsQuery(
          point.boothNames,
        )}&date=${alertParams.dateLabel}`,
      )
    },
    [alertParams.dateLabel, arcadeCd, navigate],
  )

  const shouldScroll = useCallback(
    (point: BaseFloorMapPoint) => {
      const { fillPrizeCd, fillBoothUnit } = getShouldFill(
        point as ToneFloorMapPoint,
        alertParams,
        isViewableCurrentArcadeSales,
        prizeCd,
        boothUnit,
      )
      return !!(fillPrizeCd || fillBoothUnit)
    },
    [alertParams, boothUnit, isViewableCurrentArcadeSales, prizeCd],
  )

  const getFloorMapPointBox = useCallback(
    ({ point }: FloorMapPointBoxProps) => (
      <SalesTabFloorMapPointBox
        key={point.id}
        {...{
          point,
          prizeCd,
          boothUnit,
          isViewableCurrentArcadeSales,
          onClickPoint,
        }}
      />
    ),
    [boothUnit, isViewableCurrentArcadeSales, onClickPoint, prizeCd],
  )

  return (
    <>
      {floorMap && (
        <FloorMapBox
          {...{
            floorMapPoints: floorMapPoints,
            booths,
            shouldScroll,
            onClickPoint,
            getFloorMapPointBox,
          }}
        />
      )}
    </>
  )
}

interface SalesTabFloorMapPointBoxProps extends FloorMapPointBoxProps {
  prizeCd?: string
  boothUnit?: PrizeBoothUnit
  isViewableCurrentArcadeSales: boolean
}

export const SalesTabFloorMapPointBox: React.FC<
  SalesTabFloorMapPointBoxProps
> = ({
  point,
  offset,
  onClickPoint = () => undefined,
  prizeCd,
  boothUnit,
  isViewableCurrentArcadeSales,
}: SalesTabFloorMapPointBoxProps) => {
  const alertParams = useRecoilValue(alertParamsState)
  const { pointBooths } = point as ToneFloorMapPoint

  const background = useMemo(() => {
    const { fillPrizeCd, fillBoothUnit, fillAlert, fillSaleWeek } =
      getShouldFill(
        point as ToneFloorMapPoint,
        alertParams,
        isViewableCurrentArcadeSales,
        prizeCd,
        boothUnit,
      )

    const colors = {
      [theme.palette.success.light]: fillPrizeCd || fillBoothUnit,
      [theme.palette.primary.light]: fillSaleWeek,
      [theme.palette.error.light]: fillAlert,
    }
    const shouldFillColors = Object.keys(colors).filter(
      (color) => colors[color],
    )
    return getBackgroundColors(shouldFillColors)
  }, [alertParams, boothUnit, isViewableCurrentArcadeSales, point, prizeCd])

  return (
    <FloorMapPointBox
      {...{
        point,
        offset,
        onClickPoint,
      }}
      sx={{
        background,
        overflow: "hidden",
      }}
    >
      <FloorMapPointInfoBox {...{ point, pointBooths }} />
    </FloorMapPointBox>
  )
}
