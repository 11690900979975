import { Fragment, SetStateAction, useState } from "react"

import { OpenInNew as OpenInNewIcon } from "@mui/icons-material"
import InfoIcon from "@mui/icons-material/Info"
import {
  Typography,
  Stack,
  Link,
  Card,
  TextField,
  Chip,
  Button,
} from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"

import { PrizeDailyPlansElement } from "src/api/models"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeSettingBoothCategories } from "src/api/prize-settings"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { DatePicker } from "src/components/organisms/DatePicker"
import {
  SelectablePaginatedTable,
  SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
} from "src/components/organisms/SelectablePaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { isPrizeDailyPlanUpdatable } from "src/domains/prizes/dailyRepository"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"
import { getToday } from "src/utils"

type PrizeRecommendationEditFilterSearchParams = {
  selectedDate: string
  boothName?: string
  boothCategory?: string
}

const defaultSearchParams = {
  selectedDate: getToday(),
}

export const PrizeRecommendationEdit: React.FC = () => {
  const { arcadeCd, prizeCd, machineName } = useParams()

  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeRecommendationEditFilterSearchParams =
    recoilSearchParams["prizeRecommendationEditSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeRecommendationEditFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeRecommendationEditSearchParams: params,
    }))

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: searchParams.selectedDate,
            to: searchParams.selectedDate,
            prizeCd,
            boothName: searchParams.boothName,
            boothCategory: searchParams.boothCategory,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${searchParams.selectedDate}:${searchParams.selectedDate}:${prizeCd}:${searchParams.boothName}:${searchParams.boothCategory}`,
  }).resource
  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans

  return (
    <MainContentLayout
      title="投入推奨景品一覧　投入景品の変更"
      caption="投入景品を変更する日付とブースを選択してください。"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 1, backgroundColor: "info.light" }}>
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <InfoIcon fontSize="small" color="primary" />
                <Typography variant="subtitle1">景品情報</Typography>
              </Stack>
              <Stack gap={1} pl={1}>
                {prizeDailyPlans?.[0] && (
                  <>
                    <Link
                      href={`/arcades/${arcadeCd}/prizes/${prizeDailyPlans?.[0].plan.prize.prizeCd}`}
                      target="_blank"
                      rel="noopener noeferrer"
                      component={"a"}
                      underline="none"
                      sx={(theme) => ({ color: theme.palette.primary.main })}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                        }}
                        component="span"
                      >
                        <Typography variant="subtitle1">
                          {prizeDailyPlans?.[0]?.plan.prize.prizeName}
                        </Typography>
                        <OpenInNewIcon fontSize="small" />
                      </Stack>
                    </Link>
                    <Typography variant="caption">
                      {prizeDailyPlans?.[0]?.plan.prize.prizeCd}
                    </Typography>
                  </>
                )}
                <Typography variant="caption">
                  投入推奨機種：{machineName}
                </Typography>
              </Stack>
            </Stack>
          </Card>

          <Card>
            <DatePicker
              value={searchParams.selectedDate}
              onChange={(v) =>
                setSearchParams({ ...searchParams, selectedDate: v })
              }
              format={"YYYY/MM/DD(ddd)"}
              views={["year", "month", "day"]}
              openTo="day"
              hideTodayButton
            />
          </Card>

          <Stack>
            <PrizeRecommendationEditFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => (
        <PrizeRecommendationEditInner prizeDailyPlans={prizeDailyPlans ?? []} />
      )}
    />
  )
}

const PrizeRecommendationEditFilter: React.FC = () => {
  const { arcadeCd } = useParams()
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeRecommendationEditFilterSearchParams =
    recoilSearchParams["prizeRecommendationEditSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeRecommendationEditFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeRecommendationEditSearchParams: params,
    }))

  const { resource: prizeSettingBoothCategoriesReturn } = useResource({
    subject: "ブース区分の一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeSettingBoothCategories(arcadeCd)
      : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  })
  const prizeSettingBoothCategories =
    prizeSettingBoothCategoriesReturn?.data.boothCategories

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "boothName",
          label: "ブース名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "boothCategory",
          label: "ブース区分",
          render: ({ field, fieldState }) => (
            <SearchAutoComplete
              items={(prizeSettingBoothCategories || []).map(({ name }) => ({
                label: name,
                value: name,
              }))}
              {...field}
              error={!!fieldState.error}
            />
          ),
        },
      ]}
    />
  )
}

type PrizeRecommendationEditInnerProps = {
  prizeDailyPlans: PrizeDailyPlansElement[]
}
const PrizeRecommendationEditInner: React.FC<
  PrizeRecommendationEditInnerProps
> = ({ prizeDailyPlans }) => {
  const [selectedDailyPlans, setSelectedDailyPlans] = useState<
    PrizeDailyPlansElement[]
  >([])
  const onSubmit = () => {
    console.log("TODO")
  }

  return (
    <Stack gap={3}>
      <PrizeRecommendationEditTable
        prizeDailyPlans={prizeDailyPlans}
        selectedDailyPlans={selectedDailyPlans}
        setSelectedDailyPlans={setSelectedDailyPlans}
        onSubmit={onSubmit}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        disabled={selectedDailyPlans.length === 0}
      >
        投入する
      </Button>
    </Stack>
  )
}

type PrizeRecommendationEditTableProps = {
  prizeDailyPlans: PrizeDailyPlansElement[]
  selectedDailyPlans: PrizeDailyPlansElement[]
  setSelectedDailyPlans: React.Dispatch<
    React.SetStateAction<PrizeDailyPlansElement[]>
  >
  onSubmit: () => void
}
const PrizeRecommendationEditTable: React.FC<
  PrizeRecommendationEditTableProps
> = ({ prizeDailyPlans, selectedDailyPlans, setSelectedDailyPlans }) => {
  const stateKey = "prizeRecommendationEditTable"
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 460px)",
        gap: 2,
      }}
    >
      <Typography>{selectedDailyPlans.length}件選択中</Typography>
      <SelectablePaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={prizeDailyPlans}
        disabledItems={prizeDailyPlans.filter(
          (plan) => !isPrizeDailyPlanUpdatable(plan.plan.recordedAt),
        )}
        stateKey={stateKey}
        renderHeaderCells={() => (
          <>
            <ExtTableCell
              border
              sticky
              zIndex={4}
              fixedWidth={280 - SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH}
              sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH }}
            >
              プライズ機種名（ブース名）
            </ExtTableCell>
            <ExtTableCell fixedWidth={162}>ブース区分</ExtTableCell>
            <ExtTableCell fixedWidth={175}>景品CD(投入中)</ExtTableCell>
            <ExtTableCell fixedWidth={160}>景品名(投入中)</ExtTableCell>
            <ExtTableCell fixedWidth={162}>設定</ExtTableCell>
          </>
        )}
        renderRowCells={({ plan, booth }) => {
          return (
            <Fragment key={plan?.id}>
              <ExtTableCell
                border
                sticky
                zIndex={3}
                sx={{
                  left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
                }}
              >
                {booth.boothName}
              </ExtTableCell>
              <ExtTableCell>{booth.boothName}</ExtTableCell>
              <ExtTableCell>
                <Typography variant="body2">{plan?.boothCategory}</Typography>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isPrizePlanChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.prize.prizeCd}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isSettingChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan.setting}</Typography>
                </Stack>
              </ExtTableCell>
            </Fragment>
          )
        }}
        checkIsSameItem={(a, b) => a.plan.id === b.plan.id}
        selectedItems={selectedDailyPlans}
        onChangeSelectedItems={(selectedItems) =>
          setSelectedDailyPlans(selectedItems)
        }
      />
    </Stack>
  )
}
