import {
  Material,
  MaterialInventoryDifference,
  MaterialInventoryDifferenceGroupEnum,
} from "src/api/models"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"

export const materialPlacementTypes = [
  MaterialPlacementType.Storage,
  MaterialPlacementType.InMachine,
]

export const materialPlacementTypeNames = {
  [MaterialPlacementType.Storage]: "棚",
  [MaterialPlacementType.InMachine]: "材料機械内",
}

export const inventoryMaterialExecuteDetailsFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
  phoneticIpOrderAsc: "IP順",
  phoneticIpOrderDesc: "IP順（降順）",
} as const

export const materialExecutionStatus = {
  all: "実査済み＋未実査",
  executed: "実査済み",
  notExecuted: "未実査",
} as const

export type InventoryMaterialExecuteSingleSearchParams = {
  materialCd?: Material["materialCd"]
  materialNameKana?: string
  materialName?: Material["materialName"]
  makerName?: Material["makerName"]
  ipName?: Material["ipName"]
  sortBy?: keyof typeof inventoryMaterialExecuteDetailsFilterSortByLabels
  materialExecutionStatus: keyof typeof materialExecutionStatus
}

export type InventoryMaterialExecuteMultipleSearchParams = {
  materialCd?: Material["materialCd"]
  materialNameKana?: string
  materialName?: Material["materialName"]
  makerName?: Material["makerName"]
  ipName?: Material["ipName"]
  sortBy?: keyof typeof inventoryMaterialExecuteDetailsFilterSortByLabels
}

const groupEnum = MaterialInventoryDifferenceGroupEnum
export const differenceGroups = [
  groupEnum.NoDifference,
  groupEnum.NotInGigoNavi,
  groupEnum.DiffPriceOver,
]
export const differenceGroupNames = {
  [groupEnum.NoDifference]: "差分なし",
  [groupEnum.NotInGigoNavi]: "GiGO NAVIに存在しない",
  [groupEnum.DiffPriceOver]: "戻しが10万円を超えるもの",
}

export const materialDifferenceTableHeaders = {
  materialCd: {
    order: 0,
    label: ["GiGO NAVI", "材料CD"],
  },
  materialCdsInSeams: {
    order: 1,
    label: ["SEAMS", "材料CD"],
  },
  materialName: {
    order: 2,
    label: ["材料名"],
  },
  importedStock: {
    order: 3,
    label: ["SEAMS", "データ個数"],
  },
  stock: {
    order: 4,
    label: ["今回実査した", "個数"],
  },
  payoutStock: {
    order: 5,
    label: ["払い出し", "個数"],
  },
  consumedPrice: {
    order: 6,
    label: ["今回消費した", "金額"],
  },
  isInSeams: {
    order: 7,
    label: ["材料の有無", "(SEAMS)"],
  },
  isInGigoNavi: {
    order: 8,
    label: ["材料の有無", "(GiGO NAVI)"],
  },
  differentPrice: {
    order: 9,
    label: ["SEAMS", "との差額"],
  },
  isApproved: {
    order: 10,
    label: ["ステータス"],
  },
  approve: {
    order: 11,
    label: ["承認"],
  },
  application: {
    order: 12,
    label: ["理由"],
  },
}

type MaterialDifferenceTableKey = keyof typeof materialDifferenceTableHeaders

export const materialDifferenceCsvKeys = Object.entries(
  materialDifferenceTableHeaders,
)
  .map(([key, value]) => ({ ...value, key: key }))
  .filter((h) => !["isApproved", "approve", "application"].includes(h.key))
  .sort((a, b) => a.order - b.order)
  .map((h) => h.key) as MaterialDifferenceTableKey[]

type MaterialDifferenceLabel = {
  [key in Exclude<MaterialDifferenceTableKey, "materialCdsInSeams">]: string
} & {
  materialCdsInSeams: string[]
}

export const getMaterialDifferenceLabel = (
  difference: MaterialInventoryDifference | undefined,
): MaterialDifferenceLabel => {
  if (difference === undefined) {
    return {
      materialCd: "-",
      materialCdsInSeams: ["-"],
      materialName: "-",
      importedStock: "-",
      stock: "-",
      payoutStock: "-",
      consumedPrice: "-",
      isInSeams: "-",
      isInGigoNavi: "-",
      differentPrice: "-",
      isApproved: "-",
      approve: "-",
      application: "-",
    }
  }
  return {
    materialCd: difference.materialCd || "-",
    materialCdsInSeams:
      difference.materialCdsInSeams.length > 0
        ? difference.materialCdsInSeams
        : ["-"],
    materialName: difference.materialName,
    importedStock:
      difference.importedStock !== undefined
        ? difference.importedStock.toLocaleString()
        : "-",
    stock:
      difference.stock !== undefined ? difference.stock.toLocaleString() : "-",
    payoutStock:
      difference.payoutStock !== undefined
        ? (difference.payoutStock * -1).toLocaleString() // 画面仕様上、払出個数はマイナス表示にする
        : "-",
    consumedPrice:
      difference.consumedPrice !== undefined
        ? (difference.consumedPrice * -1).toLocaleString() // 画面仕様上、消費金額はマイナス表示にする
        : "-",
    isInSeams: difference.isInSeams ? "有" : "無",
    isInGigoNavi: difference.isInGigoNavi ? "有" : "無",
    differentPrice:
      difference.differentPrice !== undefined
        ? difference.differentPrice.toLocaleString()
        : "-",
    isApproved:
      difference.isApproved !== undefined
        ? difference.isApproved
          ? "承認済"
          : "未承認"
        : "-",
    approve: "-", // 画面仕様上、ボタンが表示される
    application:
      difference.isApproved !== undefined
        ? difference.approvalApplication !== undefined
          ? difference.approvalApplication
          : "未入力"
        : "-",
  }
}

export const inventoryDifferenceFilterSortByLabels = {
  payoutStockGreater: `${materialDifferenceTableHeaders.payoutStock.label.join(
    "",
  )} (多い順)`,
  payoutStockLess: `${materialDifferenceTableHeaders.payoutStock.label.join(
    "",
  )} (少ない順)`,
  consumedPriceGreater: `${materialDifferenceTableHeaders.consumedPrice.label.join(
    "",
  )} (多い順)`,
  consumedPriceLess: `${materialDifferenceTableHeaders.consumedPrice.label.join(
    "",
  )} (少ない順)`,
  differentPriceGreater: `${materialDifferenceTableHeaders.differentPrice.label.join(
    "",
  )} (多い順)`,
  differentPriceLess: `${materialDifferenceTableHeaders.differentPrice.label.join(
    "",
  )} (少ない順)`,
}
