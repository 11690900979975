import React, { useMemo } from "react"

import { Checkbox, FormControlLabel, Button, Stack } from "@mui/material"
import { Link as RouterLink, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { getPrizeStorage } from "src/api/prize-storages"
import {
  InventoryPlacementPrizesFilter,
  placementPrizesSearchParamsState,
} from "src/components/organisms/prizes/InventoryPrizePlacementPrizesFilter"
import { InventoryPlacementPrizesMenu } from "src/components/organisms/prizes/InventoryPrizePlacementPrizesMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortPlacementStocks } from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyPrizeState } from "src/recoil/inventory"

export const InventoryPrizePlacementStoragePrizes = () => {
  const { arcadeCd, storageId, shelfId } = useParams()
  const [hideEmptyPrize, setHideEmptyPrize] =
    useRecoilState(hideEmptyPrizeState)

  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
  })
  const { storage, shelf } = useMemo(() => {
    const { storage, shelves } = resource?.data || {}
    return {
      storage,
      shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
    }
  }, [resource, shelfId])

  return (
    <MainContentLayout
      title={
        storage && shelf ? storage.name + " " + shelf.name + " の景品一覧" : ""
      }
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryPlacementPrizesFilter />
          <FormControlLabel
            checked={hideEmptyPrize}
            onChange={() => setHideEmptyPrize(!hideEmptyPrize)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryPlacementStoragePrizesMenu />}
    />
  )
}

const InventoryPlacementStoragePrizesMenu: React.FC = () => {
  const { arcadeCd } = useParams()

  return (
    <Stack gap={2}>
      <InventoryPlacementStoragePrizesTable />

      <Stack>
        <Button
          variant="outlined"
          fullWidth
          component={RouterLink}
          to={`/arcades/${arcadeCd}/prizes/register/accept/search`}
        >
          検索して景品登録
        </Button>
      </Stack>
    </Stack>
  )
}

const InventoryPlacementStoragePrizesTable = () => {
  const { arcadeCd, shelfId } = useParams()
  const hideEmptyPrize = useRecoilValue(hideEmptyPrizeState)
  const searchParams = useRecoilValue(placementPrizesSearchParamsState)
  const { resource: inventoryStocksResource } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && shelfId
        ? () =>
            getPrizeOperationStocks(arcadeCd, {
              prizeName: searchParams.prizeName,
              prizeNameKana: searchParams.prizeNameKana,
              prizeCd: searchParams.prizeCd,
              shelfId: Number(shelfId),
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${shelfId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = inventoryStocksResource?.data.stocks || []
    return sortPlacementStocks(
      stocks.flatMap((s) => s.shelfStocks),
      searchParams,
    )
  }, [inventoryStocksResource, searchParams])

  const menuItems = useMemo(
    () =>
      filteredStocks
        ?.filter((shelfStock) => (hideEmptyPrize ? shelfStock.stock > 0 : true))
        .map((shelfStock) => {
          const { prizeCd, prizeName, stock } = shelfStock
          return {
            prize: {
              prizeCd,
              prizeName,
            },
            stock,
          }
        }) ?? [],
    [filteredStocks, hideEmptyPrize],
  )

  return <InventoryPlacementPrizesMenu menuItems={menuItems} />
}
