import { useMemo } from "react"

import { useLocation, useParams } from "react-router-dom"

import { InventoryMaterialStockDetails } from "src/components//templates/InventoryMaterialStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryMaterialPlacementMachineMaterialStock: React.FC = () => {
  const { search } = useLocation()
  const { materialCd } = useParams()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const materialMachineId = query.get("materialMachineId") || undefined

  const defaultMaterialPlacementIdMoveFrom = useMemo(() => {
    if (!materialMachineId) {
      return {}
    }
    return {
      materialMachineId: Number(materialMachineId),
    }
  }, [materialMachineId])

  return (
    <MainContentLayout
      title="在庫確認・店内移動"
      renderContent={() => (
        <InventoryMaterialStockDetails
          materialCd={materialCd ?? ""}
          defaultMaterialPlacementIdMoveFrom={
            defaultMaterialPlacementIdMoveFrom
          }
        />
      )}
      backButtonLabel="一覧画面に戻る"
    />
  )
}
