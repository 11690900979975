import { useMemo } from "react"

import { Edit } from "@mui/icons-material"
import {
  Typography,
  TableHead,
  TableRow,
  Stack,
  IconButton,
  TextField,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

import { getPrizeBooths } from "src/api/prize-booths"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"

type PrizeBoothsSearchParams = {
  boothName?: string
  amMachineNumber?: string
  amMachineName?: string
}

export const PrizeBooths: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeBoothsSearchParams =
    recoilSearchParams["prizeBoothsSearchParams"] ?? {}
  return (
    <MainContentLayout
      title="プライズ機ブース一覧"
      renderFilter={() => (
        <FilterAccordion
          searchParams={searchParams}
          setSearchParams={(params) =>
            setRecoilSearchParams((prev) => ({
              ...prev,
              prizeBoothsSearchParams: params,
            }))
          }
          formInputs={[
            {
              name: "boothName",
              label: "ブース名",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} fullWidth />
              ),
            },
            {
              name: "amMachineNumber",
              label: "AM機器番号",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} fullWidth />
              ),
            },
            {
              name: "amMachineName",
              label: "AM機器名称",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} fullWidth />
              ),
            },
          ]}
        />
      )}
      renderContent={() => <PrizeBoothsTable searchParams={searchParams} />}
    />
  )
}

const PrizeBoothsTable: React.FC<{
  searchParams: PrizeBoothsSearchParams
}> = ({ searchParams }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const { resource: boothsResource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeBooths(arcadeCd) : undefined,
    recoilKey: `getPrizeBooths:${arcadeCd}`,
  })
  const booths = useMemo(
    () =>
      boothsResource?.data.booths.filter((e) => {
        if (
          searchParams.boothName &&
          !e.booth.boothName.includes(searchParams.boothName)
        ) {
          return false
        }
        if (
          searchParams.amMachineNumber &&
          !e.booth.amMachineNumber.includes(searchParams.amMachineNumber)
        ) {
          return false
        }
        if (
          searchParams.amMachineName &&
          !e.amMachine.amMachineName.includes(searchParams.amMachineName)
        ) {
          return false
        }
        return true
      }) || [],
    [
      boothsResource?.data.booths,
      searchParams.amMachineNumber,
      searchParams.boothName,
      searchParams.amMachineName,
    ],
  )

  return (
    <PaginatedTable
      scrollableX
      scrollableY
      noMargin
      items={booths}
      stateKey="prizeBooths"
      stickyHeader
      header={
        <TableHead sx={{ whiteSpace: "nowrap" }}>
          <TableRow sx={{ th: { py: 2, textAlign: "center" } }}>
            <ExtTableCell border fixedWidth={300}>
              ブース名称
            </ExtTableCell>
            <ExtTableCell border fixedWidth={300}>
              AM機器番号
            </ExtTableCell>
            <ExtTableCell border fixedWidth={300}>
              AM機器名称
            </ExtTableCell>
            <ExtTableCell border fixedWidth={200}>
              有効/無効
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(e) => {
        const { booth, amMachine } = e
        return (
          <TableRow
            key={booth.boothName}
            data-testid={booth.boothName}
            sx={{ td: { p: 1 } }}
          >
            <ExtTableCell border>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Typography variant="caption">{booth.boothName}</Typography>
                <IconButton
                  onClick={() =>
                    navigate(
                      `/arcades/${arcadeCd}/prizes/settings/booths/${booth.boothName}`,
                    )
                  }
                >
                  <Edit color="primary" fontSize="small" />
                </IconButton>
              </Stack>
            </ExtTableCell>
            <ExtTableCell border>
              <Typography variant="caption">{booth.amMachineNumber}</Typography>
            </ExtTableCell>
            <ExtTableCell border>
              <Typography variant="caption">
                {amMachine.amMachineName}
              </Typography>
            </ExtTableCell>
            <ExtTableCell border sx={{ textAlign: "center" }}>
              {booth.isAvailable ? (
                <Typography color="primary.main" variant="subtitle2">
                  有効
                </Typography>
              ) : (
                <Typography color="text.disabled" variant="subtitle2">
                  無効
                </Typography>
              )}
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}
