import dayjs from "dayjs"

import {
  PrizeDailyPlan,
  PrizeDailyPlansElement,
  PrizeDelivery,
  PrizeDeliveryElement,
  PrizeMonthlyPlan,
  PrizeOperationStock,
} from "src/api/models"
import { getJpTodayDayjs } from "src/utils"

export const PRIZE_BOOTH_SALE_UPDATABLE_PERIODS = 31 // PrizeBoothSale の更新可能期間は過去1カ月

export const getUpdatablePrizeDailyPlanDayjs = () =>
  getJpTodayDayjs().add(-PRIZE_BOOTH_SALE_UPDATABLE_PERIODS, "day")

export const isPrizeDailyPlanUpdatable = (
  recordedAt: PrizeDailyPlan["recordedAt"],
) =>
  !dayjs
    .tz(recordedAt, "Asia/Tokyo")
    .isBefore(getUpdatablePrizeDailyPlanDayjs(), "day")

export const prizeDailySortByLabels = {
  recordedAtDesc: "日付(新しい順)",
  boothCategoryAsc: "ブース区分(名称順)",
} as const

export type PrizeDailySortBy = keyof typeof prizeDailySortByLabels

export type PrizeDailyPlanWithMonthlyPlan = PrizeDailyPlansElement & {
  monthlyPlan?: PrizeMonthlyPlan
  delivery?: PrizeDelivery
  stocks: Record<
    "shelfNames" | "onBoothShelfNames" | "boothShelfNames",
    string[]
  >
}

export const getPrizeChangePlans = (
  prizeDailyPlans: PrizeDailyPlansElement[],
  prizeDeliveries: PrizeDeliveryElement[],
  prizeOperationStocks: PrizeOperationStock[],
): PrizeDailyPlanWithMonthlyPlan[] => {
  return prizeDailyPlans.map((prizeDailyPlan) => {
    const prizeDelivery = prizeDeliveries.find(
      (prizeDelivery) =>
        prizeDelivery.prize.prizeCd === prizeDailyPlan.plan.prize.prizeCd,
    )
    const operationStocks = prizeOperationStocks.find(
      (stock) => stock.prize.prizeCd === prizeDailyPlan.plan.prize.prizeCd,
    )

    const stocks: PrizeDailyPlanWithMonthlyPlan["stocks"] = {
      shelfNames: (operationStocks?.shelfStocks || []).map(
        (stock) => stock.shelfName,
      ),
      onBoothShelfNames: (operationStocks?.onBoothStocks || []).map(
        (stock) => stock.onBoothShelfName,
      ),
      boothShelfNames: (operationStocks?.boothStocks || []).map(
        (stock) => stock.boothShelfName,
      ),
    }
    return {
      ...prizeDailyPlan,
      monthlyPlan: prizeDelivery?.plan,
      delivery: prizeDelivery?.delivery,
      stocks,
    }
  })
}
