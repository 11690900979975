import { useState, useTransition } from "react"

import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Box,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { getPrizes } from "src/api/prizes"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { useResource } from "src/hooks/useResource"

type PrizeCodeSearchModalSearchParams = {
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
}

type PrizeCodeSearchModalProps = {
  open: boolean
  handleClose: () => void
  onSelect: (prizeCd: string) => void
}

export const PrizeCodeSearchModal: React.FC<PrizeCodeSearchModalProps> = ({
  open,
  handleClose,
  onSelect,
}) => {
  const { arcadeCd } = useParams()
  const [searchParams, setSearchParams] =
    useState<PrizeCodeSearchModalSearchParams>({
      prizeCd: undefined,
      prizeName: undefined,
      prizeNameKana: undefined,
    })
  const { register, handleSubmit } = useForm<PrizeCodeSearchModalSearchParams>()

  const { resource: prizeReturn } = useResource({
    subject: "過去取扱景品一覧の取得",
    fetch: arcadeCd ? () => getPrizes(arcadeCd, searchParams) : undefined,
    recoilKey: `getPrizes:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const { prizes } = prizeReturn?.data ?? {}

  const [isLoading, startTransition] = useTransition()

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      {isLoading && <LoadingBox />}
      <DialogContent sx={{ p: 3 }}>
        <Typography variant="h1" pb={2}>
          景品CD検索
        </Typography>

        <Grid
          sx={{
            mb: 3,
            p: 2,
          }}
          container
        >
          <Grid container item spacing={1} pb={2}>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品CD</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeCd")}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品名</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeName")}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ display: "flex" }}>
              <Stack sx={{ justifyContent: "center" }}>
                <Typography variant="body1">景品名カナ</Typography>
              </Stack>
            </Grid>
            <Grid item xs={9.5}>
              <TextField
                fullWidth
                placeholder="検索ワードを入力/部分一致"
                {...register("prizeNameKana")}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit((data) =>
              startTransition(() =>
                setSearchParams({
                  prizeCd: data.prizeCd === "" ? undefined : data.prizeCd,
                  prizeName: data.prizeName === "" ? undefined : data.prizeName,
                  prizeNameKana:
                    data.prizeNameKana === "" ? undefined : data.prizeNameKana,
                }),
              ),
            )}
          >
            検索する
          </Button>
        </Grid>

        <Stack gap={1}>
          <Stack sx={{ flexDirection: "row" }} gap={1}>
            <Typography variant="h3" pb={1}>
              検索結果
            </Typography>
            <Typography variant="h3" pb={1}>
              {(prizes ?? []).length}件
            </Typography>
          </Stack>
          <PaginatedTable
            items={prizes ?? []}
            renderRow={(prize) => {
              return (
                <TableBorderedRow key={prize.prizeCd}>
                  <ExtTableCell sx={{ p: 2 }}>
                    <Box width={88} height={88}>
                      <PrizeImageBox
                        prizeCd={prize.prizeCd}
                        alt={prize.prizeName}
                      />
                    </Box>
                  </ExtTableCell>
                  <ExtTableCell sx={{ p: 2, paddingLeft: 0 }}>
                    <Typography variant="body2" pb={1}>
                      {prize.prizeName}
                    </Typography>
                    <Typography variant="body2">{prize.prizeCd}</Typography>
                  </ExtTableCell>
                  <ExtTableCell sx={{ p: 0, pr: 2 }}>
                    <Stack sx={{ alignItems: "center" }}>
                      <Button
                        variant="outlined"
                        sx={{ whiteSpace: "nowrap" }}
                        onClick={() => onSelect(prize.prizeCd)}
                      >
                        選択
                      </Button>
                    </Stack>
                  </ExtTableCell>
                </TableBorderedRow>
              )
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
