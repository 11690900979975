import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { QrCodeReaderTemplate } from "src/components/templates/QrCodeReaderTemplate"
import { snackbarSuccessMessageState } from "src/recoil"

export const QrCodeReader: React.FC = () => {
  const navigate = useNavigate()
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)

  const onFinish = (path: string) => {
    setSuccessMessage("二次元コードの読み取りに成功しました")
    navigate(path)
  }

  return <QrCodeReaderTemplate onFinish={onFinish} />
}
