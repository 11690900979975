import { Suspense, useEffect } from "react"

import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  Button,
  Stack,
} from "@mui/material"
import { AxiosResponse } from "axios"
import { Link as RouterLink } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { Arcade, GetUserResponse } from "src/api/models"
import { getMe } from "src/api/users"
import { MenuButton } from "src/components/atoms/MenuButton"
import { BookmarkIconButton } from "src/components/molecules/BookmarkIconButton"
import { ConfidentialAlert } from "src/components/molecules/ConfidentialAlert"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useBookmarkedArcades } from "src/hooks/useBookmarkedArcades"
import { UseResourceReturn, useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { meState } from "src/recoil"
import { compareByPhoneticOrder } from "src/utils"

export const MyPage = () => {
  const me = useRecoilValue(meState)
  const user = me?.user
  const userArcades = me?.userArcades || []
  const { isViewableArcadeSearch } = useUserRole()

  return (
    <MainContentLayout
      title="マイページ"
      renderContent={() => (
        <>
          <Stack gap={3}>
            <Stack>
              {user && (
                <Box mb={2}>
                  <Suspense fallback={<MyPageUserTemplate />}>
                    <MyPageUser />
                  </Suspense>
                </Box>
              )}
              {user && userArcades.length > 0 && (
                <Box mb={2}>
                  <MyPageArcades {...{ userArcades }} />
                </Box>
              )}
              {isViewableArcadeSearch && (
                <Box mb={2}>
                  <MyPageBookmarkedArcades />
                </Box>
              )}
            </Stack>
          </Stack>
          {(IS_DEBUG || IS_VADDY || IS_STAGING) && me && <ConfidentialAlert />}
        </>
      )}
      disableBackButton
    />
  )
}

const MyPageUser = () => {
  const meReturn = useResource({
    subject: "ログイン中のユーザー情報の取得",
    fetch: () => getMe(),
    recoilKey: "getMe",
  })

  return <MyPageUserTemplate {...{ meReturn }} />
}

interface MyPageUserTemplateProps {
  meReturn?: UseResourceReturn<AxiosResponse<GetUserResponse>>
}

const MyPageUserTemplate: React.FC<MyPageUserTemplateProps> = ({
  meReturn,
}) => {
  const [me, setMe] = useRecoilState(meState)
  const user = me?.user

  useEffect(() => {
    const me = meReturn?.resource?.data
    if (me) {
      setMe(me)
    }
  }, [meReturn, setMe])

  if (!user) return <></>

  return (
    <>
      <Card>
        <CardContent sx={{ py: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
              gap: 1,
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ wordBreak: "break-all", fontWeight: "bold" }}
            >
              {user.name}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            fullWidth
            component={RouterLink}
            to="/new_password"
          >
            パスワードを変更する
          </Button>
        </CardContent>
      </Card>
    </>
  )
}

interface MyPageArcadesProps {
  userArcades: Arcade[]
}

const MyPageArcades: React.FC<MyPageArcadesProps> = ({
  userArcades,
}: MyPageArcadesProps) => {
  return (
    <Card>
      <CardContent sx={{ py: 3 }}>
        <Typography variant="h2">所属店舗</Typography>
        <List sx={{ width: "100%", p: 0 }}>
          {[...userArcades]
            .sort((a, b) => compareByPhoneticOrder(a.name, b.name))
            .map((a) => (
              <MenuButton key={a.arcadeCd} to={`/arcades/${a.arcadeCd}`}>
                {a.name}
              </MenuButton>
            ))}
        </List>
      </CardContent>
    </Card>
  )
}

const MyPageBookmarkedArcades: React.FC = () => {
  const {
    bookmarkedArcades,
    callPutBookmarkedArcades,
    callDeleteBookmarkedArcades,
  } = useBookmarkedArcades({ doUpdateMe: false })

  if (!bookmarkedArcades) {
    return <></>
  }

  return (
    <Card>
      <CardContent sx={{ py: 3 }}>
        <Typography variant="h2" sx={{ mb: 1 }}>
          お気に入り店舗
        </Typography>
        <List sx={{ width: "100%", p: 0 }}>
          {[...bookmarkedArcades]
            .sort((a, b) => compareByPhoneticOrder(a.name, b.name))
            .map((a) => (
              <Box
                key={a.arcadeCd}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <BookmarkIconButton
                  onAdd={() => callPutBookmarkedArcades(a.arcadeCd)}
                  onRemove={() => callDeleteBookmarkedArcades(a.arcadeCd)}
                />
                <MenuButton to={`/arcades/${a.arcadeCd}`} sx={{ px: 0 }}>
                  {a.name}
                </MenuButton>
              </Box>
            ))}

          {bookmarkedArcades.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              店舗検索からお気に入り店舗を追加できます
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  )
}
