import { InfoOutlined } from "@mui/icons-material"
import { List, ListItem, Paper, Stack, Typography } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"

import MapSelectGuide from "src/assets/map_select_guide.gif"
import { useBreakpoints } from "src/hooks/useBreakpoints"

type Props = {
  sx?: SxProps<Theme>
}

/**
 * 印刷方法の説明
 */
export const PrizeDailyPrintInstruction: React.FC<Props> = ({ sx = [] }) => {
  const { isPc } = useBreakpoints()

  return (
    <Paper
      elevation={0}
      sx={[
        { p: 1, backgroundColor: "sub.cyanLight" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack direction={isPc ? "row" : "column"} gap={2}>
        <Stack flex={1}>
          <Stack direction="row" alignItems="center" gap={1} mb={1}>
            <InfoOutlined color="info" />
            <Typography variant="body1" fontWeight="bold">
              印刷方法
            </Typography>
          </Stack>
          <List
            sx={{
              px: 1,
              "& .MuiListItem-root": {
                p: 0,
                alignItems: "flex-start",
                gap: 1,
                ":not(:last-child)": {
                  mb: 1,
                },
              },
            }}
          >
            <ListItem>
              <Typography variant="body2">1.</Typography>
              <Typography variant="body2">
                印刷したい日付、用紙サイズを選択
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">2.</Typography>
              <Typography variant="body2">
                印刷したいブースを選択（クリックしながら移動で複数選択 or
                全ブース選択ボタン）
                <br />
                ※縮小表示すると、印刷時に文字が見づらくなる可能性があります
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">3.</Typography>
              <Typography variant="body2">
                「マップを印刷」のボタンクリック後、印刷設定画面へ遷移
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">4.</Typography>
              <Typography variant="body2">
                印刷設定で用紙サイズを選択して印刷
              </Typography>
            </ListItem>
          </List>
        </Stack>
        <Stack>
          <img
            src={MapSelectGuide}
            alt="ブース選択方法"
            width={isPc ? 300 : "100%"}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}
