/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * P/O管理方法
 */
export type PrizeMeterReadPayoutCategoryEnum =
  (typeof PrizeMeterReadPayoutCategoryEnum)[keyof typeof PrizeMeterReadPayoutCategoryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizeMeterReadPayoutCategoryEnum = {
  PayoutOutMeter: "payout_out_meter",
  AssumedPayoutRate: "assumed_payout_rate",
} as const
