import { useCallback, useEffect } from "react"

import axios from "axios"
import { Outlet, useLocation } from "react-router-dom"
import { useRecoilState } from "recoil"

import { getVersions } from "src/api/versions"
import { isTimeout } from "src/domains/authRepository"
import { lastFetchedVersionAtState } from "src/recoil"
import { getLocalStorageItem, setLocalStorageItem } from "src/storages"

const oneHour = 1 * 60 * 60 * 1000
export const fetchVersionInterval = oneHour

export const VersionProvider = () => {
  const [lastFetchedVersionAt, setLastFetchedVersionAt] = useRecoilState(
    lastFetchedVersionAtState,
  )
  const fetchVersion = useCallback(
    async (localStorageAppVersion: string | null) => {
      try {
        const { appVersion } = (await getVersions()).data.versions
        if (
          appVersion &&
          localStorageAppVersion &&
          appVersion > localStorageAppVersion
        ) {
          setLocalStorageItem("appVersion", appVersion)
          return location.reload()
        }
        return setLocalStorageItem("appVersion", appVersion)
      } catch (e) {
        if (axios.isAxiosError(e) && isTimeout(e)) {
          return
        }
        throw e
      }
    },
    [],
  )

  const routerLocation = useLocation()
  useEffect(() => {
    const now = new Date().getTime()
    const localStorageAppVersion = getLocalStorageItem("appVersion")
    const intervalPassed =
      lastFetchedVersionAt && now > lastFetchedVersionAt + fetchVersionInterval

    if (!localStorageAppVersion || !lastFetchedVersionAt || intervalPassed) {
      setLastFetchedVersionAt(now)
      fetchVersion(localStorageAppVersion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation, fetchVersion, setLastFetchedVersionAt])

  return <Outlet />
}
