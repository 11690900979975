import { useParams } from "react-router-dom"

import { InventoryPrizeStockDetails } from "src/components/templates/InventoryPrizeStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryPrizeVerifyTemporaryDetails: React.FC = () => {
  const { prizeCd } = useParams()

  return (
    <MainContentLayout
      title="荷捌き中の景品一覧"
      renderContent={() => (
        <InventoryPrizeStockDetails
          prizeCd={prizeCd ?? ""}
          isTemporaryStorages
        />
      )}
      disableBackButton
    />
  )
}
