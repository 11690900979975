import { useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Search } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Card,
  Container,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  PrizeBoothCategory,
  PrizeDailyPlansElement,
  PrizeFieldSetting,
} from "src/api/models"
import { getPrizeDailyPlans, postPrizeDailyPlans } from "src/api/prize-plans"
import {
  getPrizeSettingBoothCategories,
  getPrizeSettingFields,
} from "src/api/prize-settings"
import { BackButton } from "src/components/atoms/BackButton"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { PrizeCodeSearchModal } from "src/components/organisms/PrizeCodeSearchModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel } from "src/utils"

type FormValues = {
  plans: {
    planId: number
    boothCategory?: string
    prizeCd: string
    setting?: string
  }[]
}

export const PrizeDailyEdit: React.FC = () => {
  const { arcadeCd } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const from = query.get("from")
  const to = query.get("to")
  const prizeDailyPlanIds =
    query.get("prizeDailyPlanIds")?.split(",").map(Number) || []
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { resource: prizeDailyPlansReturn, refetchForce } = useResource({
    subject: "日毎の入替計画の取得",
    fetch:
      arcadeCd && from && to
        ? () =>
            getPrizeDailyPlans(arcadeCd, {
              from,
              to,
              prizeDailyPlanIds,
            })
        : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${from}:${to}:${prizeDailyPlanIds}`,
  })

  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans

  const { resource: prizeSettingBoothCategoriesReturn } = useResource({
    subject: "ブース区分の一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeSettingBoothCategories(arcadeCd)
      : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  })
  const prizeSettingBoothCategories =
    prizeSettingBoothCategoriesReturn?.data.boothCategories

  const { resource: prizeSettingFieldsReturn } = useResource({
    subject: "フィールド設定リストの取得",
    fetch: arcadeCd ? () => getPrizeSettingFields() : undefined,
    recoilKey: `getPrizeSettingFields`,
  })
  const prizeSettingFields = prizeSettingFieldsReturn?.data.fields

  const validationSchema = Yup.object({
    plans: Yup.array()
      .of(
        Yup.object({
          planId: Yup.number().required(),
          boothCategory: Yup.string(),
          prizeCd: Yup.string().required("必須です"),
          setting: Yup.string(),
        }),
      )
      .required(),
  })
  const useFormReturn = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      plans: (prizeDailyPlans || []).map((plan) => ({
        planId: plan.plan.id,
        boothCategory: plan.plan.boothCategory,
        prizeCd: plan.plan.prize.prizeCd,
        setting: plan.plan.setting,
      })),
    },
  })
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormReturn

  const { submitPromises } = useSubmitting()
  const onSubmit = async (data: FormValues) => {
    const result = await submitPromises([
      {
        subject: "日毎の入替計画の更新",
        showSuccessMessage: true,
        promise: async () => {
          arcadeCd &&
            (await postPrizeDailyPlans(arcadeCd, {
              plans: data.plans.map(
                ({ planId, prizeCd, boothCategory, setting }) => {
                  const plan = prizeDailyPlans?.find(
                    (prizeDailyPlan) => prizeDailyPlan.plan?.id === planId,
                  )
                  if (!plan) throw new Error("never")
                  return {
                    id: plan.plan.id,
                    prizeCd,
                    boothCategory,
                    setting,
                  }
                },
              ),
            }))
        },
      },
    ])

    if (!result.success) throw result.error
    await refetchForce()
    setIsSubmitted(true)
  }

  if (isSubmitted) {
    return <PrizeDailyEditComplete prizeDailyPlans={prizeDailyPlans ?? []} />
  }

  return (
    <MainContentLayout
      title="ブース別投入景品 ブース編集"
      renderContent={() => (
        <form onSubmit={handleSubmit(onSubmit, () => null)}>
          <Stack gap={3}>
            <Stack gap={2}>
              <FormProvider {...useFormReturn}>
                {prizeDailyPlans?.map((prizeDailyPlan, i) => (
                  <PrizeDailyEditCard
                    prizeDailyPlan={prizeDailyPlan}
                    prizeSettingBoothCategories={
                      prizeSettingBoothCategories ?? []
                    }
                    prizeSettingFields={prizeSettingFields ?? []}
                    index={i}
                    key={i}
                  />
                ))}
              </FormProvider>
            </Stack>

            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </form>
      )}
      backButtonLabel="保存せず戻る"
    />
  )
}

type PrizeDailyEditCardProps = {
  prizeDailyPlan: PrizeDailyPlansElement
  prizeSettingBoothCategories: PrizeBoothCategory[]
  prizeSettingFields: PrizeFieldSetting[]
  index: number
}
const PrizeDailyEditCard: React.FC<PrizeDailyEditCardProps> = ({
  prizeDailyPlan,
  prizeSettingBoothCategories,
  prizeSettingFields,
  index,
}) => {
  const [open, setOpen] = useState(false)

  const {
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FormValues>()

  const planErrors = (errors.plans && errors.plans[index]) || {}
  return (
    <>
      <Card sx={{ px: 3, py: 2 }}>
        <input
          hidden
          {...register(`plans.${index}.planId`, { required: true })}
          value={prizeDailyPlan.plan?.id}
        />
        <Typography variant="body1" sx={{ pb: 2 }}>
          {getJpDateLabel(prizeDailyPlan.plan?.recordedAt)}
        </Typography>

        <Typography variant="h2" sx={{ pb: 1 }}>
          {prizeDailyPlan.booth.boothName}
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          {prizeDailyPlan.plan?.prize.prizeName}
        </Typography>

        <Stack gap={2}>
          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="boothCategory"
            >
              ブース区分
            </Typography>

            <Controller
              name={`plans.${index}.boothCategory`}
              control={control}
              render={({ field, fieldState }) => (
                <SearchAutoComplete
                  items={(prizeSettingBoothCategories || []).map(
                    ({ name }) => ({
                      label: name,
                      value: name,
                    }),
                  )}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />
          </Stack>

          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="prizeCd"
            >
              景品CD
            </Typography>
            <Stack direction="row" gap={2}>
              <Stack flex={1}>
                <TextField
                  id="prizeCd"
                  {...register(`plans.${index}.prizeCd`)}
                  error={"prizeCd" in planErrors}
                  helperText={planErrors.prizeCd?.message || ""}
                  sx={{ flexGrow: 1 }}
                />
              </Stack>
              <Stack>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ whiteSpace: "nowrap", height: 48 }}
                  startIcon={
                    <SvgIcon>
                      <Search />
                    </SvgIcon>
                  }
                  onClick={() => setOpen(true)}
                >
                  検索
                </Button>
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={1}>
            <Typography
              variant="body2"
              color="gray.50"
              component="label"
              htmlFor="setting"
            >
              設定
            </Typography>
            <Controller
              name={`plans.${index}.setting`}
              control={control}
              render={({ field, fieldState }) => (
                <SearchAutoComplete
                  items={(prizeSettingFields || []).map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                  {...field}
                  error={!!fieldState.error}
                />
              )}
            />
          </Stack>
        </Stack>
      </Card>
      <PrizeCodeSearchModal
        open={open}
        handleClose={() => setOpen(false)}
        onSelect={(prizeCd: string) => {
          setValue(`plans.${index}.prizeCd`, prizeCd)
          setOpen(false)
        }}
      />
    </>
  )
}

type PrizeDailyEditCompleteProps = {
  prizeDailyPlans: PrizeDailyPlansElement[]
}
const PrizeDailyEditComplete: React.FC<PrizeDailyEditCompleteProps> = ({
  prizeDailyPlans,
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h1" sx={{ pb: 2 }}>
        ブース別投入景品 ブース編集
      </Typography>

      <Stack pb={2}>
        <AlertCaptionCard
          label="下記の通り保存しました"
          rightLinkText="一覧へ戻る"
          rightLinkTo={`/arcades/${arcadeCd}/prizes/plans/daily`}
        />
      </Stack>

      <Stack gap={3}>
        <Stack gap={2}>
          {prizeDailyPlans?.map((prizeDailyPlan, i) => (
            <>
              <Card sx={{ px: 3, py: 2 }} key={i}>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  {getJpDateLabel(prizeDailyPlan.plan?.recordedAt)}
                </Typography>

                <Typography variant="h2" sx={{ pb: 1 }}>
                  {prizeDailyPlan.booth.boothName}
                </Typography>
                <Typography variant="body1" sx={{ pb: 2 }}>
                  {prizeDailyPlan.plan?.prize.prizeName}
                </Typography>

                <Stack gap={2}>
                  <Stack gap={1}>
                    <Typography variant="body2" color="gray.50">
                      ブース区分
                    </Typography>

                    <Typography variant="h3">
                      {prizeDailyPlan.plan?.boothCategory}
                    </Typography>
                  </Stack>

                  <Stack gap={1}>
                    <Typography variant="body2" color="gray.50">
                      景品CD
                    </Typography>

                    <Typography variant="h3">
                      {prizeDailyPlan.plan?.prize.prizeCd}
                    </Typography>
                  </Stack>

                  <Stack gap={1}>
                    <Typography variant="body2" color="gray.50">
                      設定
                    </Typography>

                    <Typography variant="h3">
                      {prizeDailyPlan.plan?.setting}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </>
          ))}
        </Stack>

        <BackButton
          onClick={() => navigate(`/arcades/${arcadeCd}/prizes/plans/daily`)}
        >
          ブース別投入景品一覧へ戻る
        </BackButton>
      </Stack>
    </Container>
  )
}
