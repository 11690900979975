import { Typography } from "@mui/material"

import {
  ExecutionPeriod,
  FeatureExecutionPeriod,
  ExecutionPeriodStatusEnum,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

interface InventoryExecutionPeriodWarningProps {
  title?: string
  period?: ExecutionPeriod
  featurePeriod?: FeatureExecutionPeriod
}

export const InventoryExecutionPeriodWarning: React.FC<
  InventoryExecutionPeriodWarningProps
> = ({
  title,
  period,
  featurePeriod,
}: InventoryExecutionPeriodWarningProps) => {
  const isShowExecutionAlert =
    period === undefined ||
    featurePeriod === undefined ||
    (period?.status === ExecutionPeriodStatusEnum.Active &&
      featurePeriod.status !== FeatureExecutionPeriodStatusEnum.Active)

  return (
    <MainContentLayout
      title={title ?? ""}
      renderContent={() =>
        isShowExecutionAlert ? (
          <Typography>
            この項目は確定済です。次の棚卸は、結果出力を行った後に実施出来ます。
          </Typography>
        ) : (
          <Typography>
            有効な棚卸期間が見つかりません。既に本日中に結果出力を行った場合、翌日から新しい棚卸を実施出来ます。
          </Typography>
        )
      }
    />
  )
}
