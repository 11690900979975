import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

type InventoryLockModalProps = {
  onSubmit: () => void
  onClose: () => void
  showModal: boolean
  isSubmitting: boolean
}

export const InventoryMaterialLockModal: React.FC<InventoryLockModalProps> = ({
  onSubmit,
  onClose,
  showModal,
  isSubmitting,
}) => {
  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => onClose()}
    >
      <DialogTitleWithClose onClose={() => onClose()}></DialogTitleWithClose>

      <DialogContent>
        <Typography variant="h1" pb={1}>
          棚卸の入力をロックしますか？
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <ul style={{ paddingLeft: "1em" }}>
            <li>ロック中はブース内の棚卸入力や変更が出来ません</li>
            <li>棚卸確定処理を行うまではロックを解除できます</li>
          </ul>
        </Typography>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          onClick={onSubmit}
          fullWidth
        >
          ロックする
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
