import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { OutlinedMenuButton } from "src/components/atoms/OutlinedMenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { currentArcadeState } from "src/recoil"

export const Arcade: React.FC = () => {
  const currentArcade = useRecoilValue(currentArcadeState)
  const navigate = useNavigate()
  return (
    <>
      {currentArcade && (
        <MainContentLayout
          title={currentArcade.name}
          renderContent={() => (
            <Stack>
              <OperationMenu />
            </Stack>
          )}
          onClickBackButton={() => navigate("/my-page")}
        />
      )}
    </>
  )
}

const OperationMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  return (
    <Card sx={{ p: 3, pb: 0 }}>
      <CardHeader title="業務メニュー" sx={{ p: 0 }} />
      <CardContent sx={{ p: 0, pt: 0 }}>
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/prizes`}>
            <Typography variant="subtitle1">景品業務</Typography>
          </OutlinedMenuButton>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/materials`}>
            <Typography variant="subtitle1">材料業務</Typography>
          </OutlinedMenuButton>
        </Box>
        <Box sx={{ display: "flex", mt: 3 }}>
          <OutlinedMenuButton to={`/arcades/${arcadeCd}/inventory`}>
            棚卸業務
          </OutlinedMenuButton>
        </Box>
      </CardContent>
    </Card>
  )
}
