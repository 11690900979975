import { useState, Suspense, useMemo, useEffect } from "react"

import styled from "@emotion/styled"
import {
  Close as CloseIcon,
  Add as AddIcon,
  ManageSearch,
  Sync,
} from "@mui/icons-material"
import InfoIcon from "@mui/icons-material/Info"
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CircularProgress,
  Table,
  TableBody,
  IconButton,
  Button,
  Link,
  Stack,
} from "@mui/material"
import {
  useParams,
  useLocation,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { PrizeToneBoothInfo, PrizeBoothUnit } from "src/api/models"
import {
  getPrizeToneBooths,
  patchPrizeToneBoothSalePrizeChange,
} from "src/api/prize-tone-booths"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { BoothDetailsAddForm } from "src/components/organisms/BoothDetailsAddForm"
import { BoothDetailsSalesModal } from "src/components/organisms/BoothDetailsSalesModal"
import { PrizeChangeModal } from "src/components/organisms/PrizeChangeModal"
import {
  BoothSalesPrizeChange,
  calcDailyPayOutStats,
  calcDailySalesStats,
  calcWeeklyPayOutStats,
  calcWeeklySalesStats,
  decodeBoothUnitsQuery,
  encodeBoothUnitsQuery,
  getBoothUnitUILabel,
} from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { alertParamsState, snackbarErrorMessageState } from "src/recoil"
import { theme } from "src/theme"
import {
  filterUniqueArray,
  formatApiDate,
  getYesterday,
  roundNearest100,
} from "src/utils"

export const BoothDetails: React.FC = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="h1">ブース詳細</Typography>
            <AddButton onClick={() => setShowAddModal(true)} />
          </Stack>
          <Grid container>
            <Suspense fallback={<CircularProgress sx={{ margin: "auto" }} />}>
              <BoothDetailsInner
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
              />
            </Suspense>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

const BoothDetailsInner: React.FC<{
  showAddModal: boolean
  setShowAddModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ showAddModal, setShowAddModal }) => {
  const { arcadeCd } = useParams()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const boothUnits = decodeBoothUnitsQuery(query.get("boothUnits") || "")
  const date = query.get("date") || getYesterday()

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const [selectedBooths, setSelectedBooths] = useState<PrizeToneBoothInfo[]>([])

  const { resource: boothsResource, refetch: refetchBooths } = useResource({
    subject: "ブース情報の取得",
    fetch:
      arcadeCd && date
        ? () => getPrizeToneBooths(arcadeCd, { soldOn: date })
        : undefined,
    recoilKey: `getBooths:${arcadeCd}:${date}`,
  })
  const booths = boothsResource?.data.booths
  const prizeChanges =
    (arcadeCd &&
      booths
        ?.filter((b) => b.hasPrizeChange)
        .map((b) => {
          const { machineName, boothName, soldOn, isPrizeChanged } = b
          return {
            arcadeCd,
            machineName,
            boothName,
            soldOn,
            isPrizeChanged: isPrizeChanged || false,
          }
        })) ||
    []

  const getFilteredBooths = (boothUnits: PrizeBoothUnit[]) =>
    boothUnits
      .filter(filterUniqueArray)
      .map((u) =>
        booths?.find(
          (b) => b.boothName === u.boothName && b.machineName === u.machineName,
        ),
      )
      .filter((b): b is PrizeToneBoothInfo => !!b)
      .filter(
        (b) =>
          !selectedBooths.find(
            (selectedBooth) => selectedBooth.boothName === b.boothName,
          ),
      )

  const getSelectedBoothPrizeChange = (booth: PrizeToneBoothInfo) => {
    return prizeChanges.find(
      (c) =>
        c.arcadeCd === arcadeCd &&
        c.machineName === booth.machineName &&
        c.boothName === booth.boothName &&
        c.soldOn === booth.soldOn,
    )
  }

  useEffect(() => {
    if (!booths || !boothUnits) {
      return
    }

    const queryBooths = getFilteredBooths(boothUnits)
    setSelectedBooths([...selectedBooths, ...queryBooths])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booths])

  const addBooths = (inputtedBooths: PrizeToneBoothInfo[]) => {
    if (inputtedBooths.length > 0) {
      setSelectedBooths([...selectedBooths, ...inputtedBooths])
    } else {
      setErrorMessage("該当IDのブースが見つかりませんでした")
    }
  }

  const updatePrizeChange = async (
    prizeChange: BoothSalesPrizeChange,
    isPrizeChanged: boolean,
  ) => {
    const { data } = await patchPrizeToneBoothSalePrizeChange(
      prizeChange.arcadeCd,
      prizeChange.machineName,
      prizeChange.boothName,
      formatApiDate(prizeChange.soldOn),
      {
        isPrizeChanged,
      },
    )
    const isPrizeChangedRes = data.isPrizeChanged
    if (isPrizeChangedRes !== undefined && booths) {
      refetchBooths()
    } else {
      throw new Error()
    }
  }

  return (
    <>
      {booths && (
        <Box sx={{ display: "flex", overflowX: "scroll" }}>
          <BoothDetailsTable
            {...{
              selectedBooths,
              setSelectedBooths,
              getSelectedBoothPrizeChange,
              updatePrizeChange,
            }}
          />
        </Box>
      )}

      <CustomDialog
        fullWidth
        maxWidth="sm"
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        scroll="paper"
      >
        <BoothDetailsAddForm
          addBooths={addBooths}
          onClose={() => setShowAddModal(false)}
          booths={booths}
        />
      </CustomDialog>
    </>
  )
}

const TableKeyCell = styled(ExtTableCell)(() => ({
  color: theme.palette.text.secondary,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
}))

interface BoothDetailsTableProps {
  selectedBooths: PrizeToneBoothInfo[]
  setSelectedBooths: React.Dispatch<PrizeToneBoothInfo[]>
  getSelectedBoothPrizeChange: (
    booth: PrizeToneBoothInfo,
  ) => BoothSalesPrizeChange | undefined
  updatePrizeChange: (
    prizeChange: BoothSalesPrizeChange,
    isPrizeChanged: boolean,
  ) => Promise<void>
}

const BoothDetailsTable: React.FC<BoothDetailsTableProps> = ({
  selectedBooths,
  setSelectedBooths,
  getSelectedBoothPrizeChange,
  updatePrizeChange,
}: BoothDetailsTableProps) => {
  const [showModal, setShowModal] = useState(false)
  const { arcadeCd } = useParams()
  const { isViewableCurrentArcadeSales } = useUserRole()
  const onCloseBooth = (closedBooth: PrizeToneBoothInfo) => {
    setSelectedBooths(
      selectedBooths.filter((b) => b.boothName !== closedBooth.boothName),
    )
  }
  const showStats = selectedBooths.length > 1

  const navigate = useNavigate()
  const setAlertParams = useSetRecoilState(alertParamsState)
  const onClickFloorMapLink = (booth: PrizeToneBoothInfo) => {
    // NOTE: 遷移時、フロアマップの日付とタブをセット
    setAlertParams((p) => ({
      ...p,
      dateLabel: formatApiDate(booth.soldOn),
    }))
    navigate(
      `/arcades/${arcadeCd}/prizes/sales/toneFloorMap?boothUnit=${encodeBoothUnitsQuery(
        [booth],
      )}&tab=sales`,
    )
  }

  const [showSalesModal, setShowSalesModal] = useState<{
    show: boolean
    booths?: PrizeToneBoothInfo[]
  }>({
    show: false,
  })

  const onClose = () =>
    setShowSalesModal({
      show: false,
    })

  return (
    <Card sx={{ overflowX: "scroll" }}>
      <Table size="small">
        <TableBody>
          <TableBorderedRow>
            <TableKeyCell />
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ whiteSpace: "nowrap", textAlign: "center", p: 2 }}
              >
                <IconButton
                  aria-label="close"
                  disableRipple
                  onClick={() => onCloseBooth(b)}
                  sx={{
                    color: "primary.main",
                    borderColor: "primary.main",
                    border: "1px solid",
                    height: 40,
                    width: 40,
                  }}
                >
                  <CloseIcon
                    fontSize="inherit"
                    sx={{ height: 24, width: 24 }}
                  />
                </IconButton>
              </ExtTableCell>
            ))}
            {showStats && (
              <TableKeyCell sx={{ whiteSpace: "nowrap" }}>合計</TableKeyCell>
            )}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell sx={{ whiteSpace: "nowrap" }}>ブース名</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ minWidth: 120, p: 2 }}
              >
                {getBoothUnitUILabel(b)}
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>景品</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ minWidth: 120, p: 2 }}
              >
                {b.prizeName}
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>景品CD</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ minWidth: 120, p: 2 }}
              >
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/search/toneStocks/${encodeURIComponent(
                    b.prizeCd,
                  )}`}
                  underline="none"
                >
                  {b.prizeCd}
                </Link>
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          {isViewableCurrentArcadeSales && (
            <>
              <TableBorderedRow>
                <TableKeyCell>
                  <Box>1日</Box>
                  <Box>売上</Box>
                  <Box>PO率</Box>
                </TableKeyCell>
                {selectedBooths.map((b) => (
                  <ExtTableCell
                    key={getBoothUnitUILabel(b)}
                    sx={{ whiteSpace: "nowrap", p: 2 }}
                  >
                    <Box>&nbsp;</Box>
                    <Box>
                      {b.dailySales
                        ? roundNearest100(b.dailySales).toLocaleString()
                        : "-"}
                      円
                    </Box>
                    <Box>
                      {b.dailyPayOut ? `${b.dailyPayOut.toFixed(1)}%` : "-"}
                    </Box>
                  </ExtTableCell>
                ))}
                {showStats && (
                  <ExtTableCell sx={{ whiteSpace: "nowrap", p: 2 }}>
                    <Box>&nbsp;</Box>
                    <Box>
                      {calcDailySalesStats(selectedBooths) !== 0
                        ? roundNearest100(
                            calcDailySalesStats(selectedBooths),
                          ).toLocaleString()
                        : "-"}
                      円
                    </Box>
                    <Box>
                      {calcDailyPayOutStats(selectedBooths) !== 0
                        ? `${calcDailyPayOutStats(selectedBooths).toFixed(1)}%`
                        : "-"}
                    </Box>
                  </ExtTableCell>
                )}
              </TableBorderedRow>

              <TableBorderedRow>
                <TableKeyCell>
                  <Box>1週間</Box>
                  <Box>売上</Box>
                  <Box>PO率</Box>
                </TableKeyCell>
                {selectedBooths.map((b) => (
                  <ExtTableCell
                    key={getBoothUnitUILabel(b)}
                    sx={{ whiteSpace: "nowrap", p: 2 }}
                  >
                    <Box color="text.secondary">
                      {b.daysInWeek < 7 ? `(${b.daysInWeek}日間)` : <>&nbsp;</>}
                    </Box>
                    <Box>
                      {b.weeklySales
                        ? roundNearest100(b.weeklySales).toLocaleString()
                        : "-"}
                      円
                    </Box>
                    <Box>
                      {b.weeklyPayOut ? `${b.weeklyPayOut.toFixed(1)}%` : "-"}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          setShowSalesModal({ show: true, booths: [b] })
                        }
                      >
                        <InfoIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Box>
                  </ExtTableCell>
                ))}
                {showStats && (
                  <ExtTableCell sx={{ whiteSpace: "nowrap", p: 2 }}>
                    <Box>&nbsp;</Box>
                    <Box>
                      {calcWeeklySalesStats(selectedBooths) !== 0
                        ? roundNearest100(
                            calcWeeklySalesStats(selectedBooths),
                          ).toLocaleString()
                        : "-"}
                      円
                    </Box>
                    <Box>
                      {calcWeeklyPayOutStats(selectedBooths) !== 0
                        ? `${calcWeeklyPayOutStats(selectedBooths).toFixed(1)}%`
                        : "-"}
                      <IconButton
                        aria-label="info"
                        onClick={() =>
                          setShowSalesModal({
                            show: true,
                            booths: selectedBooths,
                          })
                        }
                      >
                        <InfoIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Box>
                  </ExtTableCell>
                )}
                {showSalesModal.booths && (
                  <CustomDialog
                    fullWidth
                    maxWidth="sm"
                    open={showSalesModal.show}
                    onClose={onClose}
                    scroll="paper"
                  >
                    <Suspense
                      fallback={
                        <Box sx={{ py: 10 }}>
                          <CircularProgress
                            size={64}
                            sx={{ display: "block", margin: "auto" }}
                          />
                        </Box>
                      }
                    >
                      <BoothDetailsSalesModal booths={showSalesModal.booths} />
                    </Suspense>
                  </CustomDialog>
                )}
              </TableBorderedRow>
            </>
          )}

          <TableBorderedRow>
            <TableKeyCell>設定</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ whiteSpace: "nowrap", p: 2 }}
              >
                {b.setting}
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>在庫</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ whiteSpace: "nowrap", p: 2 }}
              >
                {b.prizeStock?.toLocaleString() || "-"}
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>単価</TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ whiteSpace: "nowrap", p: 2 }}
              >
                {b.prizeUnitPriceJpy?.toLocaleString() || "-"}円/個
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>
              獲得金額
              <br />
              目安
            </TableKeyCell>
            {selectedBooths.map((b) => (
              <ExtTableCell
                key={getBoothUnitUILabel(b)}
                sx={{ whiteSpace: "nowrap", p: 2 }}
              >
                {b.expectSales?.toLocaleString() || "-"}円/個
              </ExtTableCell>
            ))}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>
              入れ替え
              <br />
              フラグ
            </TableKeyCell>
            {selectedBooths.map((b) => {
              const prizeChange = getSelectedBoothPrizeChange(b)
              const { machineName, boothName } = b

              return (
                <ExtTableCell key={getBoothUnitUILabel(b)} sx={{ p: 2 }}>
                  {prizeChange && (
                    <>
                      <Button
                        aria-label="prize-change"
                        variant="text"
                        sx={{ px: 0, fontSize: 12 }}
                        startIcon={
                          prizeChange.isPrizeChanged ? (
                            <img
                              src={finishedIcon}
                              style={{ width: 20, height: 20 }}
                            />
                          ) : (
                            <Sync />
                          )
                        }
                        onClick={() => setShowModal(true)}
                      >
                        {prizeChange.isPrizeChanged
                          ? "入れ替え処理を戻す"
                          : "入れ替える"}
                      </Button>

                      <CustomDialog
                        fullWidth
                        maxWidth="sm"
                        open={showModal}
                        onClose={() => setShowModal(false)}
                      >
                        <Suspense
                          fallback={
                            <Box sx={{ p: 1 }}>
                              <CircularProgress
                                sx={{ display: "block", margin: "auto" }}
                              />
                            </Box>
                          }
                        >
                          <PrizeChangeModal
                            prizeCd={b.prizeCd}
                            boothUnit={{ machineName, boothName }}
                            isPrizeChanged={prizeChange.isPrizeChanged}
                            updatePrizeChange={() =>
                              updatePrizeChange(
                                prizeChange,
                                !prizeChange.isPrizeChanged,
                              )
                            }
                            onClose={() => setShowModal(false)}
                          />
                        </Suspense>
                      </CustomDialog>
                    </>
                  )}
                </ExtTableCell>
              )
            })}
          </TableBorderedRow>

          <TableBorderedRow>
            <TableKeyCell>
              ブースの
              <br />
              場所
            </TableKeyCell>

            {selectedBooths.map((b) => (
              <ExtTableCell key={getBoothUnitUILabel(b)} sx={{ p: 2 }}>
                <Button
                  variant="text"
                  startIcon={<ManageSearch />}
                  onClick={() => onClickFloorMapLink(b)}
                  sx={{ px: 0, fontSize: 12 }}
                >
                  フロアマップを見る
                </Button>
              </ExtTableCell>
            ))}
          </TableBorderedRow>
        </TableBody>
      </Table>
    </Card>
  )
}

interface AddButtonProps {
  onClick: () => void
}

const AddButton: React.FC<AddButtonProps> = ({ onClick }: AddButtonProps) => {
  return (
    <Button
      onClick={onClick}
      aria-label="add"
      variant="outlined"
      startIcon={<AddIcon />}
    >
      ブースを追加する
    </Button>
  )
}
