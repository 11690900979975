/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type PrizeToneAlertPayoutRateRangeEnum =
  (typeof PrizeToneAlertPayoutRateRangeEnum)[keyof typeof PrizeToneAlertPayoutRateRangeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizeToneAlertPayoutRateRangeEnum = {
  Lte: "lte",
  Gte: "gte",
} as const
