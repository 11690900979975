import { useEffect, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Typography,
  TextField,
  TableRow,
  Table,
  TableBody,
  Chip,
  DialogContent,
} from "@mui/material"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  Prize,
  PrizeShelfStock,
  PrizeOnBoothStock,
  PrizeBoothStock,
  PrizeInventoryHistory,
  PutPrizeInventoryExecuteRequest,
} from "src/api/models"
import { putPrizeInventoryExecute } from "src/api/prize-inventory-execute"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useSubmitting } from "src/hooks/useSubmitting"
import { theme } from "src/theme"
import { PickPartial } from "src/types"
import { formatApiDate, getToday } from "src/utils"

interface InventoryExecuteFormInput {
  cartonsCount: number
  separatedCount: number
}

export interface InventoryExecuteModalProps {
  showModal: boolean
  placementType: PlacementType
  placementStock: PrizeShelfStock | PrizeOnBoothStock | PrizeBoothStock
  prize: PickPartial<Prize, "prizeCd" | "prizeName", "unitPerCarton">
  histories: PrizeInventoryHistory[]
  onClose: () => void
  onFinish?: () => void
}

const getStockIdAndCount = (
  placementType: PlacementType,
  placementStock: PrizeShelfStock | PrizeOnBoothStock | PrizeBoothStock,
): { stockId: number; stock: number } => {
  const { Storage, InBooth, OnBooth } = PlacementType
  if (placementType === Storage) {
    const { id, stock } = placementStock as PrizeShelfStock
    return {
      stockId: id,
      stock,
    }
  }
  if (placementType === OnBooth) {
    const { id, stock } = placementStock as PrizeOnBoothStock
    return {
      stockId: id,
      stock,
    }
  }
  if (placementType === InBooth) {
    const { id, stock } = placementStock as PrizeBoothStock
    return {
      stockId: id,
      stock,
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _never: never = placementType
  throw new Error()
}

export const InventoryPrizeExecuteModal: React.FC<
  InventoryExecuteModalProps
> = ({
  showModal,
  placementStock,
  placementType,
  prize,
  histories,
  onClose,
  onFinish = () => undefined,
}: InventoryExecuteModalProps) => {
  const { arcadeCd } = useParams()
  const unitPerCarton = prize.unitPerCarton || 0
  const { stockId, stock: stockCount } = getStockIdAndCount(
    placementType,
    placementStock,
  )

  const executedHistory = useMemo(
    () =>
      histories.find((history) => {
        const { Storage, InBooth, OnBooth } = PlacementType
        if (placementType === Storage) {
          return history.shelfStock?.id === stockId
        }
        if (placementType === InBooth) {
          return history.boothStock?.id === stockId
        }
        if (placementType === OnBooth) {
          return history.onBoothStock?.id === stockId
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _never: never = placementType
        return false
      }),
    [histories, placementType, stockId],
  )

  const executeDate = executedHistory?.executedAt
    ? formatApiDate(executedHistory.executedAt)
    : getToday()

  const validationSchema = Yup.object({
    cartonsCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
    separatedCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = useForm<InventoryExecuteFormInput>({
    resolver: yupResolver<InventoryExecuteFormInput>(validationSchema),
    defaultValues: {
      cartonsCount: 0,
      separatedCount: stockCount || 0,
    },
  })
  const value = {
    cartonsCount: Number(useWatch({ control, name: "cartonsCount" })) || 0,
    separatedCount: Number(useWatch({ control, name: "separatedCount" })) || 0,
  }
  const stock = value.cartonsCount * unitPerCarton + value.separatedCount

  useEffect(() => {
    const defaultCountValue = executedHistory
      ? executedHistory.stock
      : stockCount || 0
    setValue("cartonsCount", 0)
    setValue("separatedCount", defaultCountValue)
  }, [stockCount, setValue, executedHistory])

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryExecuteFormInput> = async () => {
    // NOTE: stock = 0 の場合も送信可能にする
    if (!arcadeCd || stock === undefined) return
    const request: PutPrizeInventoryExecuteRequest = {
      stock,
      placementStockId: stockId,
      placement: placementType,
    }

    await submitPromises([
      {
        subject: "棚卸実施",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeInventoryExecute(arcadeCd, executeDate, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1" pb={1}>
            {prize.prizeName}
          </Typography>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="caption" color="text.secondary">
              景品CD
            </Typography>
            <Chip label={prize.prizeCd} size="small" sx={{ ml: 0.5 }} />
          </Box>
        </DialogTitleWithClose>

        <DialogContent>
          <Table size="small" sx={{ td: { border: "none", px: 0 }, mb: 3 }}>
            <TableBody>
              {unitPerCarton > 0 && (
                <TableRow>
                  <ExtTableCell colSpan={2}>
                    カートン入数 <strong>{unitPerCarton}</strong> 個
                  </ExtTableCell>
                </TableRow>
              )}
              <TableRow>
                <ExtTableCell colSpan={2}>
                  データ上の個数 <strong>{stockCount}</strong> 個
                </ExtTableCell>
              </TableRow>
              <TableRow
                sx={{ td: { borderTop: `1px solid ${theme.palette.divider}` } }}
                data-testid="total-count"
              >
                <ExtTableCell>カウントした個数</ExtTableCell>
                <ExtTableCell align="right">
                  合計 <strong>{stock}</strong> 個
                </ExtTableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            {unitPerCarton > 0 && (
              <>
                <Grid item xs={2.5} pb={2}>
                  箱
                </Grid>
                <Grid item xs={4} pb={2}>
                  <TextField
                    error={"cartonsCount" in errors}
                    helperText={errors.cartonsCount?.message}
                    {...register("cartonsCount")}
                    inputProps={{
                      inputMode: "numeric",
                      "aria-label": "cartonsCount",
                    }}
                  />
                </Grid>
                <Grid item xs={1.5} pb={2} pl={1}>
                  CT
                </Grid>
                <Grid item xs={4} pb={2} />
              </>
            )}

            <Grid item xs={2.5} pb={2}>
              バラ
            </Grid>
            <Grid item xs={4} pb={2}>
              <TextField
                error={"separatedCount" in errors}
                helperText={errors.separatedCount?.message}
                {...register("separatedCount")}
                inputProps={{
                  inputMode: "numeric",
                  "aria-label": "separatedCount",
                }}
              />
            </Grid>
            <Grid item xs={1.5} pb={2} pl={1}>
              個
            </Grid>
          </Grid>
        </DialogContent>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>確定せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            {executedHistory ? "変更を保存する" : "確定する"}
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
