import { useCallback, useMemo, useState } from "react"

import { Typography, Card, List, Stack } from "@mui/material"
import { AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import {
  getMaterialInventoryPlacementTypeStatus,
  putMaterialInventoryPlacementTypeStatus,
} from "src/api/material-inventory-placement-type-status"
import {
  GetMaterialInventoryPlacementStatusesResponse,
  GetMaterialInventoryPlacementTypeStatusResponse,
  GetMaterialInventoryPlacementTypeStatusResponseStatusEnum,
  PutMaterialInventoryPlacementTypeStatusRequestStatusEnum,
} from "src/api/models"
import { MenuButton } from "src/components/atoms/MenuButton"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import { InventoryMaterialLockModal } from "src/components/organisms/materials/InventoryMaterialLockModal"
import { InventoryMaterialRemoveLockModal } from "src/components/organisms/materials/InventoryMaterialRemoveLockModal"
import { InventoryMaterialStatus } from "src/components/organisms/materials/InventoryMaterialStatus"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource, UseResourceReturn } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentInventoryPeriodState,
  snackbarErrorMessageState,
} from "src/recoil"
import { getToday } from "src/utils"

export const InventoryMaterialExecuteInMachine = () => {
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  if (currentMaterialExecutionPeriod) {
    return (
      <MainContentLayout
        title="在庫カウント(材料機械[内])"
        renderContent={() => <InventoryMaterialExecuteInMachineMenu />}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="材料機械内棚卸"
      period={currentInventoryPeriod}
      featurePeriod={currentMaterialExecutionPeriod}
    />
  )
}

const InventoryMaterialExecuteInMachineMenu = () => {
  const { arcadeCd } = useParams()
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const placementStatusesReturn = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? () =>
            getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementType.InMachine,
              currentMaterialExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${
      MaterialPlacementType.InMachine
    }:${JSON.stringify(currentMaterialExecutionPeriod)}`,
  })

  const placementTypeStatusReturn = useResource({
    subject: "ロック状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? () =>
            getMaterialInventoryPlacementTypeStatus(
              arcadeCd,
              MaterialPlacementType.InMachine,
              currentMaterialExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getMaterialInventoryPlacementTypeStatus:${arcadeCd}:${
      MaterialPlacementType.InMachine
    }:${JSON.stringify(currentMaterialExecutionPeriod)}`,
  })

  return (
    <InventoryMaterialExecuteInMachineMenuTemplate
      {...{
        placementStatusesReturn,
        placementTypeStatusReturn,
      }}
    />
  )
}

interface InventoryExecuteInMachineMenuTemplateProps {
  placementStatusesReturn?: UseResourceReturn<
    AxiosResponse<GetMaterialInventoryPlacementStatusesResponse>
  >
  placementTypeStatusReturn?: UseResourceReturn<
    AxiosResponse<GetMaterialInventoryPlacementTypeStatusResponse>
  >
}

const InventoryMaterialExecuteInMachineMenuTemplate: React.FC<
  InventoryExecuteInMachineMenuTemplateProps
> = ({ placementStatusesReturn, placementTypeStatusReturn }) => {
  const { arcadeCd } = useParams()
  const [showLockModal, setLockModal] = useState(false)

  const { counts } = placementStatusesReturn?.resource?.data || {}

  const placementTypeStatus = placementTypeStatusReturn?.resource?.data.status
  const isLocked = useMemo(
    () =>
      placementTypeStatus ===
      GetMaterialInventoryPlacementTypeStatusResponseStatusEnum.Locked,
    [placementTypeStatus],
  )

  const { isAvailableInventoryLock } = useUserRole()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const refetch = useCallback(() => {
    placementStatusesReturn?.refetch()
    placementTypeStatusReturn?.refetch()
  }, [placementStatusesReturn, placementTypeStatusReturn])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const callPutPlacemenTypeStatus = useCallback(
    (status: PutMaterialInventoryPlacementTypeStatusRequestStatusEnum) => {
      arcadeCd &&
        submitPromises([
          {
            subject: "ロック状況の更新",
            showSuccessMessage: true,
            promise: async () => {
              await putMaterialInventoryPlacementTypeStatus(
                arcadeCd,
                MaterialPlacementType.InMachine,
                {
                  date: getToday(),
                  status,
                },
              )
              refetch()
              setLockModal(false)
            },
          },
        ])
    },
    [arcadeCd, submitPromises, refetch],
  )

  const onClickLockButton = () => {
    if (!isAvailableInventoryLock) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!isLocked && counts && counts.notExecuted > 0) {
      return setErrorMessage("未対応箇所があるため、ロックできません")
    }
    setLockModal(true)
  }

  return (
    <>
      {placementTypeStatus && isLocked && (
        <InventoryMaterialRemoveLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutMaterialInventoryPlacementTypeStatusRequestStatusEnum.Open,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      {placementTypeStatus && !isLocked && (
        <InventoryMaterialLockModal
          showModal={showLockModal}
          onSubmit={() =>
            callPutPlacemenTypeStatus(
              PutMaterialInventoryPlacementTypeStatusRequestStatusEnum.Locked,
            )
          }
          onClose={() => setLockModal(false)}
          isSubmitting={isSubmitting}
        />
      )}
      <Stack gap={2}>
        <InventoryMaterialStatus counts={counts} />

        <Card>
          <List sx={{ width: "100%", py: 2 }}>
            <MenuButton
              to={`/arcades/${arcadeCd}/inventory/materials/execute/machine/amMachines`}
            >
              材料機械一覧で棚卸
            </MenuButton>

            <MenuButton onClick={() => onClickLockButton()}>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Typography>ロック機能</Typography>
                {placementTypeStatus && (
                  <Typography color="text.primary">
                    {isLocked ? "ロック中" : "解除中"}
                  </Typography>
                )}
              </Stack>
            </MenuButton>
          </List>
        </Card>
      </Stack>
    </>
  )
}
