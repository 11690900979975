import { Suspense } from "react"

import { ManageSearch as ManageSearchIcon } from "@mui/icons-material"
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  Button,
} from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { getPrizeToneStock } from "src/api/prize-tone-stocks"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { useResource } from "src/hooks/useResource"
import { alertParamsState } from "src/recoil"
import {
  getJpDateLabel,
  getJpWeek,
  getYesterday,
  roundNearest100,
} from "src/utils"

export const StockDetails: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h1">
            在庫詳細
          </Typography>
          <Grid container>
            <Suspense fallback={<CircularProgress sx={{ margin: "auto" }} />}>
              <StockCard />
            </Suspense>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

interface StockDetailsListItem {
  key: string
  value: JSX.Element
}

const StockCard: React.FC = () => {
  const { arcadeCd, prizeCd: prizeCdFromParams } = useParams()
  const navigate = useNavigate()
  const setAlertParams = useSetRecoilState(alertParamsState)

  const { stock, expectSale, boothNames } =
    useResource({
      subject: "在庫情報の取得",
      fetch:
        arcadeCd && prizeCdFromParams
          ? () => getPrizeToneStock(arcadeCd, prizeCdFromParams)
          : undefined,
      recoilKey: `getPrizeChanges:${arcadeCd}:${prizeCdFromParams}`,
    }).resource?.data ?? {}

  const isInLatestFloorMap = boothNames?.length ? boothNames.length > 0 : false

  if (!stock) return null

  const { prize, stock: stockNumber, publishedAt, prizeOrders } = stock
  const {
    prizeName,
    prizeCd,
    makerName,
    ipName,
    unitPriceJpy,
    assortmentKindCount,
    targetSegmentName,
    heightCm,
    widthCm,
    depthCm,
  } = prize

  const { expectSales, expectPayoutRate } = expectSale ?? {}

  const prizeOrdersTable =
    prizeOrders && prizeOrders.length > 0 ? (
      <Table>
        <TableBody>
          {prizeOrders.map((o) => (
            <TableRow
              key={o.id}
              sx={{
                td: {
                  border: "none",
                  whiteSpace: "nowrap",
                  px: 0,
                  py: 0.5,
                },
              }}
            >
              <ExtTableCell>
                {getJpDateLabel(o.willArriveAt, "着荷日未定")}
              </ExtTableCell>
              <ExtTableCell>{o.orderCount} 個</ExtTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <></>
    )

  const listItems = [
    {
      key: "景品",
      value: prizeName,
    },
    {
      key: "景品CD",
      value: prizeCd,
    },
    {
      key: "獲得金額目安",
      value: [
        {
          key: "売上",
          value: `${
            expectSales ? roundNearest100(expectSales).toLocaleString() : "-"
          }円`,
        },
        {
          key: "PO率",
          value: expectPayoutRate ? `${expectPayoutRate.toFixed(1)}%` : "-",
        },
      ].map((item) => (
        <TableRow key={item.key} sx={{ px: 0, td: { border: "none" } }}>
          <ExtTableCell sx={{ pl: 0, py: 0.5 }}>{item.key}</ExtTableCell>
          <ExtTableCell sx={{ pr: 0, py: 0.5 }}>{item.value}</ExtTableCell>
        </TableRow>
      )),
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "単価",
      value: `${unitPriceJpy?.toLocaleString()}円`,
    },
    {
      key: "在庫",
      value: `${stockNumber.toLocaleString()}個`,
    },
    {
      key: "アソート種数",
      value: `${assortmentKindCount.toLocaleString()}種`,
    },
    {
      key: "対象層",
      value: targetSegmentName,
    },
    {
      key: "高さ",
      value: heightCm ? `${heightCm.toFixed(1)}cm` : "-",
    },
    {
      key: "幅",
      value: widthCm ? `${widthCm.toFixed(1)}cm` : "-",
    },
    {
      key: "奥行",
      value: depthCm ? `${depthCm.toFixed(1)}cm` : "-",
    },
    {
      key: "発売日",
      value: publishedAt ? getJpDateLabel(publishedAt) : "-",
    },
    {
      key: "発売週",
      value: publishedAt ? getJpWeek(publishedAt) : "-",
    },
    {
      key: "着荷情報",
      value: prizeOrders ? prizeOrdersTable : "-",
    },
  ].filter((item): item is StockDetailsListItem => !!item)

  const onClickFloorMapLink = () => {
    // NOTE: 在庫詳細からフロアマップに遷移時は、前日の日付とタブをセット
    setAlertParams((p) => ({ ...p, dateLabel: getYesterday() }))
    navigate(
      `/arcades/${arcadeCd}/prizes/sales/toneFloorMap?prizeCd=${encodeURIComponent(prizeCd)}`,
    )
  }

  return (
    <Card sx={{ m: 1, width: "100%", overflowX: "scroll" }}>
      <Table>
        <TableBody>
          <TableBorderedRow>
            <ExtTableCell colSpan={2}>
              <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                <PrizeImageBox
                  prizeCd={prizeCd}
                  alt={prizeName}
                  noImageSize="large"
                />
              </Box>
            </ExtTableCell>
          </TableBorderedRow>

          {listItems.map((item) => (
            <TableBorderedRow key={item.key}>
              <ExtTableCell sx={{ color: "neutral.500", whiteSpace: "nowrap" }}>
                {item.key}
              </ExtTableCell>
              <ExtTableCell>{item.value}</ExtTableCell>
            </TableBorderedRow>
          ))}
        </TableBody>
      </Table>
      {isInLatestFloorMap && arcadeCd && (
        <Box p={2}>
          <Button
            variant="contained"
            fullWidth
            endIcon={<ManageSearchIcon />}
            onClick={() => onClickFloorMapLink()}
          >
            フロアマップで在庫をみる
          </Button>
        </Box>
      )}
    </Card>
  )
}
