import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"

type SetEmptyStatusModalProps = {
  showModal: boolean
  onSubmit: React.MouseEventHandler<HTMLButtonElement>
  onClose: React.MouseEventHandler<HTMLButtonElement>
  isSubmitting: boolean
}

export const SetEmptyStatusModal: React.FC<SetEmptyStatusModalProps> = ({
  showModal,
  onSubmit,
  onClose,
  isSubmitting,
}) => {
  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="h1">
            「空」で登録しますか？
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={onClose}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            onClick={onSubmit}
            loading={isSubmitting}
            fullWidth
          >
            登録する
          </LoadingButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}
