import { useState } from "react"

import AutorenewIcon from "@mui/icons-material/Autorenew"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { LoadingButton } from "@mui/lab"
import { Typography, Button, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  getInventoryCsvExport,
  postInventoryCsvImport,
  postInventoryCsvImportSeams,
} from "src/api/inventory-csv"
import { ExecutionPeriodStatusEnum } from "src/api/models/executionPeriodStatusEnum"
import { CsvTable } from "src/components/organisms/CsvTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  ITEM_CD_COLUMN,
  IMPORT_REQUIRED_COLUMNS,
  isOrderDateBeforePeriodStarts,
  ITEM_CD_LEFT_COLUMN,
  STOCK_COLUMN,
} from "src/domains/inventoryCsvRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUploadCsv } from "src/hooks/useUploadCsv"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentInventoryPeriodState,
  snackbarErrorMessageState,
} from "src/recoil"

export const InventoryPrizeCsvImport = () => {
  return (
    <MainContentLayout
      title="循環棚卸オーダー取り込み"
      renderContent={() => <InventoryPrizeCsvImportMenu />}
    />
  )
}

const InventoryPrizeCsvImportMenu = () => {
  const { arcadeCd } = useParams()

  const { uploadedCsv, onUploadCsv, resetUploadedCsv } = useUploadCsv({
    requiredColumns: IMPORT_REQUIRED_COLUMNS,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const { isAdmin } = useUserRole()

  const { resource, refetch } = useResource({
    subject: "取り込み済の循環棚卸オーダーの取得",
    hideErrorMessage: true,
    fetch:
      arcadeCd && currentInventoryPeriod
        ? () => getInventoryCsvExport(arcadeCd, currentInventoryPeriod.id)
        : undefined,
    recoilKey: `getInventoryCsvExport:${arcadeCd}:${JSON.stringify(
      currentInventoryPeriod,
    )}`,
  })
  const exportedCsv = resource?.data.csv

  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmit = () => {
    const { headerRow, rows } = uploadedCsv || {}
    if (!uploadedCsv || !headerRow || !rows) {
      setErrorMessage("CSVファイルの取り込みに失敗しました")
      return
    }

    const arcadeCdColumnIndex = headerRow.columns.findIndex(
      (column) => column === ITEM_CD_COLUMN,
    )
    if (arcadeCdColumnIndex === -1) {
      setErrorMessage(`「${ITEM_CD_COLUMN}」列の位置を検出できませんでした`)
      return
    }
    const prizeCdColumnIndex =
      headerRow.columns.findIndex((column) => column === ITEM_CD_LEFT_COLUMN) +
      1
    if (prizeCdColumnIndex === 0) {
      setErrorMessage(
        `「${ITEM_CD_LEFT_COLUMN}」列の位置を検出できませんでした`,
      )
      return
    }
    const stockColumnIndex = headerRow.columns.findIndex(
      (column) => column === STOCK_COLUMN,
    )
    if (stockColumnIndex === -1) {
      setErrorMessage(`「${STOCK_COLUMN}」列の位置を検出できませんでした`)
      return
    }

    if (!currentInventoryPeriod) {
      setErrorMessage("現在の棚卸期間を取得できませんでした")
      return
    }
    if (isOrderDateBeforePeriodStarts(uploadedCsv, currentInventoryPeriod)) {
      setErrorMessage("過去棚卸期間の巡回棚卸オーダーが入っています")
      return
    }

    arcadeCd &&
      submitPromises([
        {
          subject: "循環棚卸オーダー取り込み",
          showSuccessMessage: true,
          promise: async () => {
            await postInventoryCsvImport(arcadeCd, currentInventoryPeriod.id, {
              arcadeCdColumnIndex,
              prizeCdColumnIndex,
              stockColumnIndex,
              headerRow,
              rows,
            })
            resetUploadedCsv()
            refetch()
          },
        },
      ])
  }

  const onClickImportSeams = () => {
    arcadeCd &&
      currentInventoryPeriod &&
      submitPromises([
        {
          subject: "循環棚卸オーダーをSEAMSから取り込み",
          showSuccessMessage: true,
          promise: async () => {
            await postInventoryCsvImportSeams(
              arcadeCd,
              currentInventoryPeriod.id,
            )
            refetch()
          },
        },
      ])
  }

  const isStep1 = !exportedCsv && !uploadedCsv
  const isStep2 = !!uploadedCsv
  const isStep3 =
    currentInventoryPeriod?.status !== ExecutionPeriodStatusEnum.Ended &&
    !!exportedCsv &&
    !uploadedCsv
  const isStep4 =
    currentInventoryPeriod?.status === ExecutionPeriodStatusEnum.Ended &&
    !!exportedCsv

  if (isStep1) {
    return (
      <Stack gap={2}>
        <Stack>
          循環棚卸オーダーを登録します。
          <br />
          {isAdmin
            ? "SEAMSから自動で取り込むか、CSVファイルをアップロードしてください。"
            : "「SEAMSから自動で取り込む」を実行してください。"}
        </Stack>

        <Stack>
          <ImportSeamsButton onClick={onClickImportSeams} />
        </Stack>

        {isAdmin && (
          <Stack>
            <CsvUploadButton {...{ onUploadCsv }} />
          </Stack>
        )}
      </Stack>
    )
  }

  if (isStep2) {
    return (
      <Stack gap={2}>
        <Stack>
          以下の内容を取り込みます。よろしければ「この内容で取り込む」を選択してください。
        </Stack>

        <Stack>
          <CsvTable csv={uploadedCsv} />
        </Stack>

        <Stack>
          <LoadingButton
            variant="contained"
            onClick={() => onSubmit()}
            loading={isSubmitting}
          >
            この内容で取り込む
          </LoadingButton>
        </Stack>

        <Stack>
          <Button
            variant="contained"
            color="error"
            onClick={() => resetUploadedCsv()}
          >
            やめる
          </Button>
        </Stack>
      </Stack>
    )
  }

  if (isStep3) {
    return (
      <Stack gap={2}>
        <Stack>
          今月分の循環棚卸オーダーは取り込み済です。
          <br />
          {isAdmin
            ? "取り込み内容を変更する場合は、SEAMSから取り込み直すか、CSVファイルを再アップロードしてください。"
            : "取り込み内容を変更する場合は、「SEAMSから自動で取り込む」を再実行してください。"}
        </Stack>

        <Stack>
          <Typography variant="h2">今月分の取り込み済内容</Typography>
        </Stack>

        <Stack>
          <CsvTable csv={exportedCsv} />
        </Stack>

        <Stack>
          <ImportSeamsButton onClick={onClickImportSeams} />
        </Stack>

        {isAdmin && (
          <Stack>
            <CsvUploadButton {...{ onUploadCsv }} />
          </Stack>
        )}
      </Stack>
    )
  }

  if (isStep4) {
    return (
      <Stack gap={2}>
        <Stack>今月の棚卸は完了済みです。</Stack>

        <Stack>
          <Typography variant="h2">今月分の取り込み済内容</Typography>
        </Stack>

        <Stack>
          <CsvTable csv={exportedCsv} />
        </Stack>
      </Stack>
    )
  }

  return <></>
}

interface ImportSeamsButtonProps {
  onClick: () => void
}

const ImportSeamsButton: React.FC<ImportSeamsButtonProps> = ({
  onClick,
}: ImportSeamsButtonProps) => {
  return (
    <Button
      startIcon={<AutorenewIcon />}
      variant="contained"
      onClick={() => onClick()}
    >
      SEAMSから自動で取り込む
    </Button>
  )
}

interface CsvUploadButtonProps {
  onUploadCsv: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const CsvUploadButton: React.FC<CsvUploadButtonProps> = ({
  onUploadCsv,
}: CsvUploadButtonProps) => {
  return (
    <Button
      startIcon={<FileUploadIcon />}
      variant="contained"
      component="label"
    >
      CSVファイルをアップロードする
      <input
        type={"file"}
        accept={".csv"}
        hidden
        onChange={(e) => onUploadCsv(e)}
      />
    </Button>
  )
}
