import { LoadingButton } from "@mui/lab"
import { Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import {
  PrizeInventoryDifference,
  FeatureExecutionPeriod,
  PutPrizeInventoryApprovalApproveRequest,
} from "src/api/models"
import { approvePrizeInventoryDifference } from "src/api/prize-inventory-differences"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { useSubmitting } from "src/hooks/useSubmitting"

interface InventoryPrizeApproveDifferenceModalProps {
  showModal: boolean
  difference: PrizeInventoryDifference
  prizeExecutionPeriodId: FeatureExecutionPeriod["id"]
  onClose: () => void
  onFinish?: () => void
}

export const InventoryPrizeApproveDifferenceModal: React.FC<
  InventoryPrizeApproveDifferenceModalProps
> = ({
  showModal,
  difference,
  prizeExecutionPeriodId,
  onClose,
  onFinish = () => undefined,
}: InventoryPrizeApproveDifferenceModalProps) => {
  const { arcadeCd } = useParams()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    if (!arcadeCd || !difference.applicationId) return
    const request: PutPrizeInventoryApprovalApproveRequest = {
      applicationId: difference.applicationId,
      importedStock: difference.importedStock || 0,
      stock: difference.stock || 0,
      consumedPrice: difference.consumedPrice || 0,
      differentPrice: difference.differentPrice || 0,
    }

    await submitPromises([
      {
        subject: "差分の承認",
        showSuccessMessage: true,
        promise: async () => {
          await approvePrizeInventoryDifference(
            arcadeCd,
            prizeExecutionPeriodId,
            request,
          )
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">承認しますか？</Typography>
        </DialogTitleWithClose>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>承認せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            承認する
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
