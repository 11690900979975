import { ReactNode, startTransition, useEffect, useMemo, useState } from "react"

import { Button } from "@mui/material"

type SeeMoreProps<T> = {
  items: T[]
  itemCountPerShow: number
  renderItem: (item: T, index: number) => ReactNode
  renderWrapper?: (renderItems: () => ReactNode) => ReactNode
}
export const SeeMore = <T,>({
  items,
  itemCountPerShow,
  renderItem,
  renderWrapper = (renderItems) => <>{renderItems()}</>,
}: SeeMoreProps<T>) => {
  const [showItemCount, setShowItemCount] = useState(itemCountPerShow)
  const itemsToShow = useMemo(
    () => items.slice(0, showItemCount),
    [items, showItemCount],
  )

  useEffect(() => {
    setShowItemCount(itemCountPerShow)
  }, [itemCountPerShow, items])

  return (
    <>
      {renderWrapper(() => itemsToShow.map(renderItem))}
      {items.length > showItemCount && (
        <Button
          variant="outlined"
          onClick={() =>
            startTransition(() =>
              setShowItemCount((pref) => pref + itemCountPerShow),
            )
          }
        >
          もっと見る
        </Button>
      )}
    </>
  )
}
