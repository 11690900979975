import { useMemo } from "react"

import { Typography, TableHead, TableRow, alpha, Stack } from "@mui/material"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialMachines } from "src/api/material-machines"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { InventoryMaterialMachinesFilter } from "src/components/organisms/materials/InventoryMaterialMachinesFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { inventoryMaterialMachinesSearchParamsState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export type InventoryMaterialMachinesSearchParams = {
  amMachineNumber?: string
  isAvailable?: boolean
  amMachineName?: string
}

export const InventoryMaterialMachines = () => {
  return (
    <MainContentLayout
      title="材料機械一覧"
      renderFilter={() => <InventoryMaterialMachinesFilter />}
      renderContent={() => <InventoryMaterialMachinesTable />}
    />
  )
}

const InventoryMaterialMachinesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const [urlSearchParams, setUrlSearchParams] = useSearchParams()

  const searchParams = useRecoilValue(
    inventoryMaterialMachinesSearchParamsState,
  )
  const { resource: machinesResource } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd
      ? () =>
          getMaterialMachines(arcadeCd, {
            amMachineNumber: searchParams.amMachineNumber,
            amMachineName: searchParams.amMachineName,
            isAvailable: searchParams.isAvailable,
          })
      : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const materialMachines = useMemo(
    () => machinesResource?.data.materialMachines || [],
    [machinesResource],
  )

  return (
    <Stack sx={{ maxHeight: "80dvh" }}>
      <PaginatedTable
        noMargin
        scrollableX
        scrollableY
        stickyHeader
        items={materialMachines}
        stateKey="inventoryMachinesTable"
        header={
          <TableHead>
            <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
              <ExtTableCell>AM機器番号</ExtTableCell>
              <ExtTableCell>
                有効/
                <br />
                無効
              </ExtTableCell>
              <ExtTableCell>AM機器名称</ExtTableCell>
              <ExtTableCell>シリンダー番号</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={({ materialMachine, amMachine }) => {
          const { amMachineNumber, amMachineName } = amMachine
          const { id: materialMachineId, cylinderId } = materialMachine
          const isAvailable = materialMachine.machineShelves?.[0]?.isAvailable
          const isSelected =
            materialMachineId.toString() ===
            urlSearchParams.get("selectedMaterialMachineId")

          return (
            <TableBorderedRow
              key={materialMachineId}
              data-testid={materialMachineId}
              sx={{
                ...(!isAvailable && {
                  td: { background: alpha(theme.palette.text.disabled, 0.3) },
                }),
              }}
              onClick={() => {
                if (!isAvailable) return
                setUrlSearchParams(
                  {
                    selectedMaterialMachineId: materialMachineId.toString(),
                  },
                  { replace: true },
                )
                navigate(
                  `/arcades/${arcadeCd}/materials/settings/machines/${materialMachineId}`,
                )
              }}
            >
              <ExtTableCell
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: (theme) => theme.palette.neutral[200],
                  },
                  p: 1,
                }}
              >
                <Typography fontWeight={isSelected ? "bold" : ""}>
                  {amMachineNumber}
                </Typography>
              </ExtTableCell>
              <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                {isAvailable ? (
                  <Typography color="primary.main" p={1} variant="subtitle1">
                    有効
                  </Typography>
                ) : (
                  <Typography color="text.disabled" p={1} variant="subtitle1">
                    無効
                  </Typography>
                )}
              </ExtTableCell>
              <ExtTableCell
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: (theme) => theme.palette.neutral[200],
                  },
                  p: 1,
                }}
              >
                <Typography fontWeight={isSelected ? "bold" : ""}>
                  {amMachineName}
                </Typography>
              </ExtTableCell>
              <ExtTableCell
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    background: (theme) => theme.palette.neutral[200],
                  },
                  p: 1,
                }}
              >
                <Typography fontWeight={isSelected ? "bold" : ""}>
                  {cylinderId?.toString().padStart(3, "0")}
                </Typography>
              </ExtTableCell>
            </TableBorderedRow>
          )
        }}
      />
    </Stack>
  )
}
