import { useLocation, useNavigate } from "react-router-dom"

import { Prize } from "src/api/models"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizesTableRow } from "src/components/organisms/prizes/InventoryPrizesTableRow"

export type InventoryPlacementPrizesMenuItem = {
  prize: Pick<Prize, "prizeCd" | "prizeName">
  stock: number
}

export type InventoryPlacementPrizesMenuProps = {
  menuItems: InventoryPlacementPrizesMenuItem[]
  searchParams?: URLSearchParams
}

export const InventoryPlacementPrizesMenu: React.FC<
  InventoryPlacementPrizesMenuProps
> = ({ menuItems, searchParams }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <PaginatedTable
      noMargin
      items={menuItems}
      stateKey="inventoryPlacementPrizesMenu"
      renderRow={({ prize, stock }) => (
        <InventoryPrizesTableRow
          key={prize.prizeCd}
          prize={prize}
          stock={stock}
          onRowClick={() =>
            navigate({
              pathname: `${pathname}/${encodeURIComponent(prize.prizeCd)}`,
              ...(searchParams && { search: `?${searchParams}` }),
            })
          }
        />
      )}
    />
  )
}
