import { atom, selector } from "recoil"

import { PaginatedTablesState } from "src/components/organisms/PaginatedTable"
import { ResourceMapState } from "src/hooks/useResource"
import { SnackbarMessage } from "src/types"

export * from "src/recoil/arcade"
export * from "src/recoil/auth"

export const snackbarErrorMessageState = atom({
  key: "snackbarErrorMessageState",
  default: "",
})

export const snackbarSuccessMessageState = atom({
  key: "snackbarSuccessMessageState",
  default: "",
})

export const snackbarMessageState = selector({
  key: "snackbarMessageState",
  get: ({ get }): SnackbarMessage => {
    const successMessage = get(snackbarSuccessMessageState)
    if (successMessage) {
      return {
        severity: "success",
        content: successMessage,
      }
    }
    const errorMessage = get(snackbarErrorMessageState)
    return {
      severity: "error",
      content: errorMessage || "",
    }
  },
})

export const paginatedTablesState = atom<PaginatedTablesState>({
  key: "paginatedTablesState",
  default: {},
})

export const resourceMapState = atom<ResourceMapState>({
  key: "resourceMapState",
  default: new Map(),
})

export const resourceLastUpdatedAtState = atom<number>({
  key: "resourceLastUpdatedAtState",
  default: new Date().getTime(),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterAccordionSearchState = atom<Record<string, any>>({
  key: "filterAccordionSearchState",
  default: {},
})
