/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type PrizeToneAlertSalesRangeEnum =
  (typeof PrizeToneAlertSalesRangeEnum)[keyof typeof PrizeToneAlertSalesRangeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizeToneAlertSalesRangeEnum = {
  Lte: "lte",
  Gte: "gte",
} as const
