import React, { useState } from "react"

import { Box, Button, Table, TableBody, Card, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { getPrizes } from "src/api/prizes"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardItemNameBox,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeAcceptModal } from "src/components/organisms/prizes/InventoryPrizeAcceptModal"
import {
  InventoryPrizeFilter,
  inventoryPrizeSearchParamsState,
} from "src/components/organisms/prizes/InventoryPrizeFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { datePickerDateLabelStateSelector } from "src/recoil"
import { theme } from "src/theme"
import { getToday } from "src/utils"

export const InventoryPrizeSearch = () => {
  const [selectedPrizeCd, setSelectedPrizeCd] = useState<string>()

  return (
    <MainContentLayout
      title="検索して入庫検収・景品登録"
      caption="検索項目を入力して「検索する」を実行してください"
      renderFilter={
        selectedPrizeCd === undefined
          ? () => <InventoryPrizeFilter />
          : undefined
      }
      renderContent={() => (
        <InventoryPrizeSearchMenu
          selectedPrizeCd={selectedPrizeCd}
          setSelectedPrizeCd={setSelectedPrizeCd}
        />
      )}
    />
  )
}

type InventoryPrizeSearchMenuProps = {
  selectedPrizeCd?: string
  setSelectedPrizeCd: React.Dispatch<React.SetStateAction<string | undefined>>
}

const InventoryPrizeSearchMenu: React.FC<InventoryPrizeSearchMenuProps> = ({
  selectedPrizeCd,
  setSelectedPrizeCd,
}) => {
  const { arcadeCd } = useParams()
  const searchParams = useRecoilValue(inventoryPrizeSearchParamsState)
  const prizesReturn = useResource({
    subject: "景品一覧の取得",
    fetch: arcadeCd
      ? () => getPrizes(arcadeCd, { ...searchParams, all: true })
      : undefined,
    recoilKey: `getPrizes:${JSON.stringify({
      ...searchParams,
      all: true,
    })}`,
    skip: !(
      !!searchParams.prizeCd ||
      !!searchParams.prizeName ||
      !!searchParams.prizeNameKana ||
      !!searchParams.ipName ||
      !!searchParams.makerName
    ),
  })
  const prizes = prizesReturn.resource?.data.prizes || []

  return (
    <InventoryPrizeSearchMenuTemplate
      prizes={prizes}
      selectedPrizeCd={selectedPrizeCd}
      setSelectedPrizeCd={setSelectedPrizeCd}
    />
  )
}

type InventoryPrizeSearchMenuTemplateProps = {
  prizes: Prize[]
  selectedPrizeCd?: string
  setSelectedPrizeCd: React.Dispatch<React.SetStateAction<string | undefined>>
}

const InventoryPrizeSearchMenuTemplate: React.FC<
  InventoryPrizeSearchMenuTemplateProps
> = ({ prizes, selectedPrizeCd, setSelectedPrizeCd }) => {
  const selectedPrize = prizes.find((p) => p.prizeCd === selectedPrizeCd)

  if (selectedPrize) {
    return (
      <InventoryPrizeAccept
        prize={selectedPrize}
        onClose={() => setSelectedPrizeCd(undefined)}
      />
    )
  }
  return (
    <Stack>
      <InventoryPrizeTable
        prizes={prizes}
        setSelectedPrizeCd={setSelectedPrizeCd}
      />
    </Stack>
  )
}

interface InventoryPrizeTableProps {
  prizes: Prize[]
  setSelectedPrizeCd: React.Dispatch<React.SetStateAction<string | undefined>>
}

const InventoryPrizeTable: React.FC<InventoryPrizeTableProps> = ({
  prizes,
  setSelectedPrizeCd,
}: InventoryPrizeTableProps) => {
  return (
    <PaginatedTable
      noMargin
      items={prizes}
      stateKey="inventoryPrizeTable"
      renderRow={(prize) => {
        return (
          <TableBorderedRow
            key={prize.prizeCd}
            sx={{
              "&:hover": {
                cursor: "pointer",
                td: { background: theme.palette.neutral[200] },
              },
            }}
            onClick={() => setSelectedPrizeCd(prize.prizeCd)}
          >
            <ExtTableCell sx={{ p: 2 }}>
              <Box width={64} height={64}>
                <PrizeImageBox prizeCd={prize.prizeCd} alt={prize.prizeName} />
              </Box>
            </ExtTableCell>
            <ExtTableCell sx={{ p: 2, paddingLeft: 0 }}>
              <CardItemNameBox>{prize.prizeName}</CardItemNameBox>
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}

interface InventoryPrizeAcceptProps {
  prize: Prize
  onClose: () => void
}

const InventoryPrizeAccept: React.FC<InventoryPrizeAcceptProps> = ({
  prize,
  onClose,
}: InventoryPrizeAcceptProps) => {
  const [showModal, setShowModal] = useState(false)
  const setDatePickerDateLabel = useSetRecoilState(
    datePickerDateLabelStateSelector,
  )
  const { prizeCd, prizeName, makerName, ipName, unitPerCarton, unitPriceJpy } =
    prize

  const listItems = [
    {
      key: "景品CD",
      value: prizeCd,
    },
    {
      key: "景品名",
      value: prizeName,
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "カートン入数",
      value: unitPerCarton.toLocaleString(),
    },
    unitPriceJpy && {
      key: "単価",
      value: `${unitPriceJpy.toLocaleString()}円`,
    },
  ].filter((item) => !!item)

  return (
    <>
      <Stack gap={2}>
        <Card>
          <Table>
            <TableBody>
              <TableBorderedRow>
                <ExtTableCell colSpan={2}>
                  <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                    <PrizeImageBox
                      prizeCd={prizeCd}
                      alt={prizeName}
                      noImageSize="large"
                    />
                  </Box>
                </ExtTableCell>
              </TableBorderedRow>

              {listItems.map(
                (item) =>
                  item && (
                    <TableBorderedRow key={item.key}>
                      <CardKeyCell>{item.key}</CardKeyCell>
                      <CardValueCell>{item.value}</CardValueCell>
                    </TableBorderedRow>
                  ),
              )}
            </TableBody>
          </Table>
        </Card>

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            setShowModal(true)
            // NOTE: 景品検索から検収モーダル遷移時は検収日付を今日にする
            setDatePickerDateLabel(getToday())
          }}
        >
          検収・景品登録
        </Button>
      </Stack>

      <InventoryPrizeAcceptModal
        showModal={showModal}
        prize={prize}
        onClose={() => setShowModal(false)}
        onFinish={() => {
          setShowModal(false)
          onClose()
        }}
      />
    </>
  )
}
