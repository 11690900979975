import React from "react"

import { CardContent, List, Card } from "@mui/material"
import { useParams } from "react-router-dom"

import { getMaterialMachine } from "src/api/material-machines"
import { AmMachine, MaterialMachine } from "src/api/models"
import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

export const InventoryMaterialPlacementInMachine = () => {
  const { arcadeCd, materialMachineId } = useParams()

  const { resource } = useResource({
    subject: "材料機械情報の取得",
    fetch:
      arcadeCd && materialMachineId
        ? () => getMaterialMachine(arcadeCd, Number(materialMachineId))
        : undefined,
    recoilKey: `getMaterialMachine:${arcadeCd}:${materialMachineId}`,
  })
  const { amMachine, materialMachine } = resource?.data ?? {}

  return (
    <MainContentLayout
      title={
        getDisplayMaterialMachineName(amMachine, materialMachine) + "［内］"
      }
      renderContent={() => (
        <InventoryMaterialPlacementInMachineInner
          amMachine={amMachine}
          materialMachine={materialMachine}
        />
      )}
    />
  )
}

type InventoryMaterialPlacementInMachineInnerProps = {
  amMachine?: AmMachine
  materialMachine?: MaterialMachine
}

const InventoryMaterialPlacementInMachineInner: React.FC<
  InventoryMaterialPlacementInMachineInnerProps
> = ({ amMachine, materialMachine }) => {
  const { isAvailable } = amMachine ?? {}

  return !isAvailable ? (
    <Card>
      <CardContent>無効に設定されています</CardContent>
    </Card>
  ) : (
    <InventoryMaterialPlacementInMachineMenu
      amMachineNumber={materialMachine?.amMachineNumber || ""}
      materialMachineId={materialMachine?.id || 0}
    />
  )
}

type InventoryMaterialPlacementInMachineMenuProps = {
  amMachineNumber: MaterialMachine["amMachineNumber"]
  materialMachineId: MaterialMachine["id"]
}

const InventoryMaterialPlacementInMachineMenu = ({
  amMachineNumber,
  materialMachineId,
}: InventoryMaterialPlacementInMachineMenuProps) => {
  const { arcadeCd } = useParams()

  const menuItems = arcadeCd
    ? [
        {
          href: `/arcades/${arcadeCd}/materials/placements/machine/${materialMachineId}/materials`,
          title: "保管されている材料一覧",
        },
        {
          href: `/arcades/${arcadeCd}/inventory/materials/execute/machine/amMachines/${amMachineNumber}/${materialMachineId}`,
          title: "棚卸実施",
        },
      ]
    : []

  return (
    <Card>
      <CardContent>
        <List sx={{ width: "100%", color: theme.palette.primary.main }}>
          {menuItems.map((i) => (
            <MenuButton key={i.title} to={i.href}>
              {i.title}
            </MenuButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
