/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type { GetPrizeTonePrizeChangesResponse } from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 当日入れ替える景品一覧を取得
 */
export const getPrizeTonePrizeChanges = (
  arcadeCd: string,
  date: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeTonePrizeChangesResponse>(
    { url: `/arcades/${arcadeCd}/tone/prizeChanges/${date}`, method: "GET" },
    options,
  )
}
export type GetPrizeTonePrizeChangesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeTonePrizeChanges>>
>
