/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  PatchMaterialShelvesOrdersRequest,
  PutMaterialShelvesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚を追加、変更
 */
export const putMaterialShelf = (
  arcadeCd: string,
  storageId: number,
  putMaterialShelvesRequest: PutMaterialShelvesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/${storageId}/shelves`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialShelvesRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】棚を削除
 */
export const deleteMaterialShelf = (
  arcadeCd: string,
  storageId: number,
  shelfId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/${storageId}/shelves/${shelfId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary 【材料】棚の並び替え
 */
export const patchMaterialShelvesOrders = (
  arcadeCd: string,
  storageId: number,
  patchMaterialShelvesOrdersRequest: PatchMaterialShelvesOrdersRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/storages/${storageId}/shelves/orders`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialShelvesOrdersRequest,
    },
    options,
  )
}
export type PutMaterialShelfResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialShelf>>
>
export type DeleteMaterialShelfResult = NonNullable<
  Awaited<ReturnType<typeof deleteMaterialShelf>>
>
export type PatchMaterialShelvesOrdersResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialShelvesOrders>>
>
