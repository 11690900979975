import { useParams } from "react-router-dom"

import { InventoryPrizeStockDetails } from "src/components//templates/InventoryPrizeStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryPrizePlacementStoragePrizeStock: React.FC = () => {
  const { prizeCd, shelfId } = useParams()

  const defaultPlacementIdMoveFrom = {
    shelfId: Number(shelfId),
  }

  return (
    <MainContentLayout
      title="景品在庫"
      renderContent={() => (
        <InventoryPrizeStockDetails
          prizeCd={prizeCd ?? ""}
          isPlacementPrize
          defaultPlacementIdMoveFrom={defaultPlacementIdMoveFrom}
        />
      )}
      disableBackButton
    />
  )
}
