import { LoadingButton } from "@mui/lab"
import { Typography, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

type InventoryExecutionPeriodEndModalProps = {
  onSubmit: () => void
  onClose: () => void
  showModal: boolean
  isSubmitting: boolean
}

export const InventoryExecutionPeriodEndModal: React.FC<
  InventoryExecutionPeriodEndModalProps
> = ({ onSubmit, onClose, showModal, isSubmitting }) => {
  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => onClose()}
    >
      <DialogTitleWithClose onClose={() => onClose()}></DialogTitleWithClose>

      <DialogContent>
        <Typography variant="h1" pb={1}>
          確定処理の確認
        </Typography>
        <Typography variant="body2" color="error.main" fontWeight="bold">
          <p>
            確定処理を行うと以下の動作が行われます。問題ないことを確認して確定ボタンを押してください。
          </p>
          <ul style={{ paddingLeft: "1em" }}>
            <li>
              GiGO NAVI上の今回の棚卸期間が終了し、新しい棚卸期間が開始されます
            </li>
            <li>
              棚卸確定後、今回の棚卸結果CSVは「過去棚卸結果CSV」メニューから出力することが可能になります
            </li>
          </ul>
          <br />
        </Typography>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <LoadingButton
          variant="contained"
          color="error"
          type="submit"
          loading={isSubmitting}
          onClick={onSubmit}
          fullWidth
        >
          確定する
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
