/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeInventoryExecuteParams,
  GetPrizeInventoryExecuteResponse,
  PutPrizeInventoryExecuteFixPrizeCdRequest,
  PutPrizeInventoryExecuteRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚卸の実行記録を取得
 */
export const getPrizeInventoryExecute = (
  arcadeCd: string,
  prizeExecutionPeriodId: number,
  params?: GetPrizeInventoryExecuteParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeInventoryExecuteResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/execute/${prizeExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 棚卸を実行
 */
export const putPrizeInventoryExecute = (
  arcadeCd: string,
  date: string,
  putPrizeInventoryExecuteRequest: PutPrizeInventoryExecuteRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/execute/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeInventoryExecuteRequest,
    },
    options,
  )
}
/**
 * @summary 棚卸の景品CD間違いを修正
 */
export const putPrizeInventoryExecuteFixPrizeCd = (
  arcadeCd: string,
  date: string,
  putPrizeInventoryExecuteFixPrizeCdRequest: PutPrizeInventoryExecuteFixPrizeCdRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/execute/${date}/fixPrizeCd`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeInventoryExecuteFixPrizeCdRequest,
    },
    options,
  )
}
export type GetPrizeInventoryExecuteResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeInventoryExecute>>
>
export type PutPrizeInventoryExecuteResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeInventoryExecute>>
>
export type PutPrizeInventoryExecuteFixPrizeCdResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeInventoryExecuteFixPrizeCd>>
>
