import { TextField, Select, MenuItem } from "@mui/material"
import { useRecoilState } from "recoil"

import {
  MaterialInventoryDifference,
  MaterialInventoryDifferenceGroupEnum,
} from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { inventoryDifferenceFilterSortByLabels } from "src/domains/materials/materialInventoryHistoriesRepository"
import { InventoryMaterialDifferenceSearchParams } from "src/pages/inventory/materials/differences/InventoryMaterialExecuteDifferences"
import { inventoryMaterialDifferenceSearchParamsState } from "src/recoil/inventoryMaterials"
import { compareByPhoneticOrder } from "src/utils"

export const InventoryMaterialDifferenceFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialDifferenceSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.materialCd ||
          searchParams.materialCdInSeams ||
          searchParams.materialName ||
          searchParams.ipName ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "materialCd",
          label: "材料CD (GiGO NAVI)",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialCdInSeams",
          label: "材料CD (SEAMS)",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialName",
          label: "材料名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(inventoryDifferenceFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterDifferences = (
  differences: MaterialInventoryDifference[],
  params: InventoryMaterialDifferenceSearchParams,
) => {
  const { sortBy } = params
  const groupEnum = MaterialInventoryDifferenceGroupEnum

  const getSortCompareBy = () => {
    if (sortBy === "payoutStockGreater") {
      // 画面仕様上、払出個数はマイナス表示にするため、並び順も逆転させる
      return (a: MaterialInventoryDifference, b: MaterialInventoryDifference) =>
        ((a.payoutStock || "") <= (b.payoutStock || "") ? 1 : -1) * -1
    }
    if (sortBy === "payoutStockLess") {
      // 画面仕様上、払出個数はマイナス表示にするため、並び順も逆転させる
      return (a: MaterialInventoryDifference, b: MaterialInventoryDifference) =>
        ((a.payoutStock || "") >= (b.payoutStock || "") ? 1 : -1) * -1
    }
    if (sortBy === "consumedPriceGreater") {
      // 画面仕様上、消費金額はマイナス表示にするため、並び順も逆転させる
      return (a: MaterialInventoryDifference, b: MaterialInventoryDifference) =>
        ((a.consumedPrice || "") <= (b.consumedPrice || "") ? 1 : -1) * -1
    }
    if (sortBy === "consumedPriceLess") {
      // 画面仕様上、消費金額はマイナス表示にするため、並び順も逆転させる
      return (a: MaterialInventoryDifference, b: MaterialInventoryDifference) =>
        ((a.consumedPrice || "") >= (b.consumedPrice || "") ? 1 : -1) * -1
    }
    if (sortBy === "differentPriceGreater") {
      return (
        a: MaterialInventoryDifference,
        b: MaterialInventoryDifference,
      ) => ((a.differentPrice || "") <= (b.differentPrice || "") ? 1 : -1)
    }
    if (sortBy === "differentPriceLess") {
      return (
        a: MaterialInventoryDifference,
        b: MaterialInventoryDifference,
      ) => ((a.differentPrice || "") >= (b.differentPrice || "") ? 1 : -1)
    }

    return () => 1
  }

  return (
    differences
      .sort((a, b) => {
        if (a.ipName === b.ipName) {
          return compareByPhoneticOrder(a.materialName, b.materialName)
        }
        return compareByPhoneticOrder(a.ipName, b.ipName)
      })
      // NOTE: IP 名が空文字の材料を末尾に移動
      .sort((a, b) => (a.ipName !== "" && b.ipName === "" ? -1 : 0))
      .sort(getSortCompareBy())
      // NOTE: CSV 出力用に、すべての group の differences を保持
      .reduce(
        (
          diffByGroup: {
            [key: string]: MaterialInventoryDifference[] | undefined
          },
          d,
        ) => {
          return {
            ...diffByGroup,
            [d.group]: [...(diffByGroup[d.group] || []), d],
          }
        },
        {
          [groupEnum.NoDifference]: [],
          [groupEnum.NotInGigoNavi]: [],
          [groupEnum.DiffPriceOver]: [],
        },
      )
  )
}
