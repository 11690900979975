import { useState, useCallback } from "react"

import { useRecoilState } from "recoil"

import {
  putBookmarkedArcades,
  deleteBookmarkedArcades,
} from "src/api/bookmarks"
import { getMe } from "src/api/users"
import { useLoading } from "src/hooks/useLoading"
import { meState } from "src/recoil"

export const useBookmarkedArcades = ({ doUpdateMe = false }) => {
  const [me, setMe] = useRecoilState(meState)
  const { bookmarkedArcades } = me || {}
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const updateMe = useCallback(async () => {
    const { data } = await getMe()
    if (data) {
      setMe(data)
    }
  }, [setMe])

  const callPutBookmarkedArcades = useCallback(
    (arcadeCd: string) => {
      submitPromises([
        {
          subject: "お気に入り店舗の追加",
          showSuccessMessage: true,
          promise: async () => {
            await putBookmarkedArcades({ arcadeCds: [arcadeCd] })
            return doUpdateMe && updateMe()
          },
        },
      ])
    },
    [submitPromises, updateMe, doUpdateMe],
  )
  const callDeleteBookmarkedArcades = useCallback(
    (arcadeCd: string) => {
      submitPromises([
        {
          subject: "お気に入り店舗の解除",
          showSuccessMessage: true,
          promise: async () => {
            await deleteBookmarkedArcades({ arcadeCds: [arcadeCd] })
            return doUpdateMe && updateMe()
          },
        },
      ])
    },
    [submitPromises, updateMe, doUpdateMe],
  )

  return {
    bookmarkedArcades,
    isSubmitting,
    callPutBookmarkedArcades,
    callDeleteBookmarkedArcades,
  }
}
