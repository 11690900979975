import { codeToString, convert, detect } from "encoding-japanese"
import Papa, { LocalFile, ParseResult } from "papaparse"

function papaParseAsync<T = string[]>(
  source: LocalFile | string,
  config?: Papa.ParseConfig<T>,
) {
  return new Promise<ParseResult<T>>((resolve, reject) => {
    Papa.parse<T>(source, {
      ...config,
      complete: resolve,
      error: reject,
    })
  })
}

export async function parseCsvFile(file: File) {
  const fileArrayBuffer = await file.arrayBuffer()
  const codes = new Uint8Array(fileArrayBuffer)

  const detectedEncoding = detect(codes)
  if (!detectedEncoding) {
    throw new Error("文字コードが判定できませんでした")
  }

  const converted = convert(codes, {
    to: "UNICODE",
    from: detectedEncoding,
  })

  const result = await papaParseAsync(codeToString(converted), {
    skipEmptyLines: true,
  })

  const [headerRow, ...rows] = result.data.map((columns) => ({ columns }))

  return { headerRow, rows }
}
