import { atom } from "recoil"

import {
  PrizeInventoryDifferenceGroupEnum,
  RequestPrizeOperationMoveBoothPlacementEnum,
} from "src/api/models"
import { InventoryVerifyStockSearchParams } from "src/domains/prizes/inventoryMoveRepository"
import { InventoryOutStockSearchParams } from "src/domains/prizes/inventoryOutRepository"
import { InventoryPrizeDifferenceSearchParams } from "src/pages/inventory/prizes/differences/InventoryPrizeExecuteDifferences"
import { InventoryPrizeTemporaryStoragesSearchParams } from "src/pages/prizes/move/InventoryPrizeTemporaryStorages"
import { InventoryPrizePlacementsSearchParams } from "src/pages/prizes/placements/InventoryPrizePlacements"

export const inventoryOutStockSearchParamsState =
  atom<InventoryOutStockSearchParams>({
    key: "inventoryOutStockSearchParamsState",
    default: {},
  })

export const inventoryVerifyStockSearchParamsState =
  atom<InventoryVerifyStockSearchParams>({
    key: "inventoryVerifyStockSearchParamsState",
    default: {},
  })

export const inventoryTemporaryStoragesSearchParamsState =
  atom<InventoryPrizeTemporaryStoragesSearchParams>({
    key: "inventoryTemporaryStoragesSearchParamsState",
    default: {},
  })

export const inventoryDifferenceSearchParamsState =
  atom<InventoryPrizeDifferenceSearchParams>({
    key: "inventoryDifferenceSearchParamsState",
    default: { group: PrizeInventoryDifferenceGroupEnum.NoDifference },
  })

export const inventoryPlacementsSearchParamsState =
  atom<InventoryPrizePlacementsSearchParams>({
    key: "inventoryPlacementsSearchParamsState",
    default: {},
  })

export const hideInventoryHistoriesRegisteredSeamsState = atom<boolean>({
  key: "hideInventoryHistoriesRegisteredSeamsState",
  default: true,
})

export const hideEmptyPrizeState = atom<boolean>({
  key: "hideEmptyPrizeState",
  default: false,
})

export const showOnlyNotAcceptedPrizesState = atom<boolean>({
  key: "showOnlyNotAcceptedPrizesState",
  default: false,
})

export const moveFromBoothPlacementTypeState =
  atom<RequestPrizeOperationMoveBoothPlacementEnum>({
    key: "moveFromBoothPlacementTypeState",
    default: RequestPrizeOperationMoveBoothPlacementEnum.InBooth,
  })
