import Dialog from "@mui/material/Dialog"
import { styled } from "@mui/material/styles"

import { theme } from "src/theme"

export const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2)})`,
    maxHeight: `calc(100% - ${theme.spacing(4)})`,
  },
}))
