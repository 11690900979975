import { PaletteOptions, createTheme } from "@mui/material"
import { alpha } from "@mui/material/styles"
import shadows, { Shadows } from "@mui/material/styles/shadows"

declare module "@mui/material/styles" {
  interface TypeGray {
    [10]: string
    [20]: string
    [30]: string
    [40]: string
    [50]: string
    [60]: string
    [70]: string
    [80]: string
    [90]: string
  }
  interface TypeNeutral {
    [100]: string
    [200]: string
    [300]: string
    [400]: string
    [500]: string
    [600]: string
    [700]: string
    [800]: string
    [900]: string
  }
  interface TypeIcon {
    green: string
    blue: string
  }
  interface TypeSub {
    yellow: string
    green: string
    greenLight: string
    greenDark: string
    cyan: string
    cyanLight: string
    cyanDark: string
  }

  interface Palette {
    gray: TypeGray
    neutral: TypeNeutral
    sub: TypeSub
    icon: TypeIcon
  }
  interface PaletteOptions {
    gray: TypeGray
    neutral: TypeNeutral
    sub: TypeSub
    icon: TypeIcon
  }
  interface TypographyVariantsOptions {
    label: React.CSSProperties
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true
  }
}

const U1Colors = {
  white: "#FFFFFF",
  gray10: "#F9FAFC",
  gray20: "#F7F8F8",
  gray30: "#E6E8F0",
  gray40: "#D1D5DB",
  gray50: "#65748B",
  gray60: "#6B7280",
  gray70: "#4B5563",
  gray80: "#374151",
  gray90: "#121828",
  red: "#D14343",
  redLight: "#FFECEC",
  redDark: "#922D2E",
  blue: "#1C61AC",
  blueLight: "#F0F4F9",
  blueDark: "#00387C",
  orange: "#ED6C02",
  orangeLight: "#FFF4E5",
  orangeDark: "#663C00",
  yellow: "#FCCF00",
  green: "#4CAF50",
  greenLight: "#EDF7ED",
  greenDark: "#1E4620",
  cyan: "#0288D1",
  cyanLight: "#E5F6FD",
  cyanDark: "#014361",
} as const

const palette = {
  gray: {
    10: U1Colors.gray10,
    20: U1Colors.gray20,
    30: U1Colors.gray30,
    40: U1Colors.gray40,
    50: U1Colors.gray50,
    60: U1Colors.gray60,
    70: U1Colors.gray70,
    80: U1Colors.gray80,
    90: U1Colors.gray90,
  },
  icon: {
    green: U1Colors.green,
    blue: U1Colors.blue,
  },
  neutral: {
    // TODO: 色指定について確認・修正する
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: U1Colors.gray40,
    400: "#9CA3AF",
    500: U1Colors.gray60,
    600: U1Colors.gray70,
    700: U1Colors.gray80,
    800: "#1F2937",
    900: "#111827",
  },
  sub: {
    yellow: U1Colors.yellow,
    green: U1Colors.green,
    greenLight: U1Colors.greenLight,
    greenDark: U1Colors.greenDark,
    cyan: U1Colors.cyan,
    cyanLight: U1Colors.cyanLight,
    cyanDark: U1Colors.cyanDark,
  },
  action: {
    disabledBackground: alpha(U1Colors.gray80, 0.12),
    disabled: alpha(U1Colors.gray80, 0.26),
  },
  background: {
    default: U1Colors.gray10,
    paper: U1Colors.white,
  },
  divider: U1Colors.gray30,
  primary: {
    main: U1Colors.blue,
    light: U1Colors.blueLight,
    dark: U1Colors.blueDark,
    contrastText: U1Colors.white,
  },
  secondary: {
    // TODO: light/dark の色指定について確認・修正する
    main: U1Colors.yellow,
    light: "#FFFF51",
    dark: "#C49E00",
    contrastText: U1Colors.white,
  },
  success: {
    main: U1Colors.green,
    light: U1Colors.greenLight,
    dark: U1Colors.greenDark,
    contrastText: U1Colors.white,
  },
  info: {
    main: U1Colors.cyan,
    light: U1Colors.cyanLight,
    dark: U1Colors.cyanDark,
    contrastText: U1Colors.white,
  },
  warning: {
    main: U1Colors.orange,
    light: U1Colors.orangeLight,
    dark: U1Colors.orangeDark,
    contrastText: U1Colors.white,
  },
  error: {
    main: U1Colors.red,
    light: U1Colors.redLight,
    dark: U1Colors.redDark,
    contrastText: U1Colors.white,
  },
  text: {
    primary: U1Colors.gray90,
    secondary: U1Colors.gray50,
    disabled: alpha(U1Colors.gray80, 0.48),
  },
} satisfies PaletteOptions

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "100px",
          padding: "8px 24px",
        },
        outlined: {
          backgroundColor: palette.background.paper,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "48px",
        },
        input: {
          height: "48px",
          boxSizing: "border-box",
        },
        multiline: {
          height: "auto",
        },
        inputMultiline: {
          height: "auto",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: "100%",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: { root: { height: "48px", color: palette.gray[40] } },
    },
    MuiRadio: {
      styleOverrides: { root: { height: "48px" } },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "3px solid",
          borderColor: U1Colors.gray50,
          ".MuiTabs-indicator": {
            display: "none",
          },
        },
      },
      defaultProps: {
        variant: "scrollable",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: U1Colors.white,
          color: palette.text.primary,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          borderColor: U1Colors.gray50,
          border: "1px solid",
          borderBottom: "none",
          marginRight: "4px",
          textTransform: "none",
          "&.Mui-selected": {
            background: U1Colors.gray50,
            color: palette.primary.contrastText,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h2",
        },
        subheaderTypographyProps: {
          variant: "body2",
        },
      },
      styleOverrides: {
        root: {
          padding: "24px 16px 8px 16px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: "0px",
          paddingBottom: "0px",
        },
        popupIndicator: {
          color: palette.gray[40],
        },
        clearIndicator: {
          color: palette.gray[40],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: palette.gray[40],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              "& + .MuiSwitch-track": {
                backgroundColor: palette.primary.main,
                opacity: 1,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.12,
              },
            },
            "& + .MuiSwitch-track": {
              backgroundColor: palette.gray[50],
              opacity: 1,
            },
            "& .MuiSwitch-thumb": {
              color: palette.background.paper,
            },
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: palette.gray[20],
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: alpha(palette.gray["40"], 0.24),
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            color: palette.gray[80],
          },
          "& .MuiTableCell-root": {
            // subtitle2
            fontSize: "12px",
            fontWeight: 600,
            letterSpacing: 0.5,
          },
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          td: {
            backgroundColor: U1Colors.white,
            borderColor: palette.gray[30],
          },
          th: {
            backgroundColor: palette.gray[20],
            borderColor: palette.gray[30],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "& .MuiChip-label": {
            // caption
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "150%",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          color: palette.success.dark,
          backgroundColor: palette.success.light,
        },
        standardError: {
          color: palette.error.dark,
          backgroundColor: palette.error.light,
        },
      },
    },
  },
  palette,
  shape: {
    borderRadius: 8,
  },
  shadows: shadows.map((shadow, i) => {
    if (i === 0) return "none"
    return "0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
  }) as Shadows,
  typography: {
    fontWeightBold: 600,
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "150%",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "150%",
    },
    subtitle1: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "150%",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "150%",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 300,
      lineHeight: "150%",
    },
    h1: {
      fontWeight: 600,
      fontSize: "23px",
      lineHeight: "150%",
    },
    h2: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "150%",
    },
    h3: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
    },
    label: {
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "150%",
    },
  },
})
