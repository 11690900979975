import { useSetRecoilState } from "recoil"

import { Prize } from "src/api/models"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizesTableRow } from "src/components/organisms/prizes/InventoryPrizesTableRow"
import { snackbarErrorMessageState } from "src/recoil"
import { PickPartial } from "src/types"

export type InventoryPrizeExecuteDetailsMenuItem = {
  prize: PickPartial<Prize, "prizeCd" | "prizeName", "unitPerCarton">
  anyStock?: number
  placementStockId?: number
  executedStock?: number
  executed: boolean
  executedAt?: string // APIレスポンス形式のまま (ISO String)
}

export type InventoryPrizeExecuteDetailsMenuProps = {
  openExecuteModal: (menuItem: InventoryPrizeExecuteDetailsMenuItem) => void
  openDeleteModal: (menuItem: InventoryPrizeExecuteDetailsMenuItem) => void
  menuItems: InventoryPrizeExecuteDetailsMenuItem[]
}

export const InventoryPrizeExecuteDetailsMenu: React.FC<
  InventoryPrizeExecuteDetailsMenuProps
> = ({ menuItems, openExecuteModal, openDeleteModal }) => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  return (
    <PaginatedTable
      noMargin
      items={menuItems}
      stateKey="inventoryExecuteDetailsMenu"
      renderRow={(menuItem) => {
        const { prize, anyStock, executedStock, executed, executedAt } =
          menuItem
        const stock = executedStock !== undefined ? executedStock : anyStock
        const isEditable = anyStock !== undefined
        const isDeletable = anyStock === 0 && !executed
        const onClickRow = () => {
          if (isEditable) {
            openExecuteModal(menuItem)
          } else {
            setErrorMessage("在庫が存在しなくなったため、編集できません")
          }
        }

        return (
          <InventoryPrizesTableRow
            key={prize.prizeCd}
            prize={prize}
            stock={stock}
            executed={executed}
            executedAt={executedAt}
            onRowClick={onClickRow}
            isDeletable={isDeletable}
            onDeleteClick={() => openDeleteModal(menuItem)}
          />
        )
      }}
    />
  )
}
