/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type { GetAreasResponse } from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary エリア一覧を取得
 */
export const getAreas = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetAreasResponse>(
    { url: `/areas`, method: "GET" },
    options,
  )
}
export type GetAreasResult = NonNullable<Awaited<ReturnType<typeof getAreas>>>
