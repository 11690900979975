import { useEffect } from "react"

import { Card } from "@mui/material"
import dayjs from "dayjs"
import { atom, useRecoilState } from "recoil"

import { DatePicker } from "src/components/organisms/DatePicker"
import { DateLabelString } from "src/types"
import { formatApiDate, getToday } from "src/utils"

export const prizeMeterReadSelectedDateState = atom<
  DateLabelString | undefined
>({
  key: "prizeMeterReadSelectedDateState",
  default: undefined,
})

type Props = {
  defaultValue?: string | null
  minDate?: dayjs.Dayjs
}

export const PrizeMeterReadDatePicker: React.FC<Props> = ({
  defaultValue,
  minDate,
}) => {
  const [selectedDate, setSelectedDate] = useRecoilState(
    prizeMeterReadSelectedDateState,
  )

  // state が undefined のときのみ初期値をセットする
  // 最初にマウントされたときのみしか defaultValue が反映されないので注意
  useEffect(() => {
    setSelectedDate((prev) => {
      if (prev != null) return prev
      return defaultValue && dayjs(defaultValue).isValid()
        ? formatApiDate(defaultValue)
        : getToday()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <Card sx={{ p: 2 }}>
      {selectedDate && (
        <DatePicker
          value={selectedDate}
          minDate={minDate}
          onChange={(date) => setSelectedDate(date)}
        />
      )}
    </Card>
  )
}
