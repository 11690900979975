import { Box, Button, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

type Props = { to: string; children: React.ReactNode }

export const OutlinedMenuButton: React.FC<Props> = ({ to, children }) => (
  <Button
    component={RouterLink}
    to={to}
    variant="outlined"
    sx={{
      display: "block",
      borderRadius: 1,
      height: 64,
      p: 0,
      flexGrow: 1,
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        {children}
      </Typography>
    </Box>
  </Button>
)
