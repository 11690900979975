/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type { PatchMaterialPlacementAvailabilitiesRequest } from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚・ブース内・ブース上の有効・無効フラグの変更
 */
export const patchMaterialPlacementAvailabilities = (
  arcadeCd: string,
  patchMaterialPlacementAvailabilitiesRequest: PatchMaterialPlacementAvailabilitiesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/placements/availabilities`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialPlacementAvailabilitiesRequest,
    },
    options,
  )
}
export type PatchMaterialPlacementAvailabilitiesResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialPlacementAvailabilities>>
>
