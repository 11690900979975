import {
  Material,
  MaterialOperationMachineStock,
  MaterialOperationShelfStock,
  MaterialOperationStock,
} from "src/api/models"
import { inventoryMaterialExecuteDetailsFilterSortByLabels } from "src/domains/materials/materialInventoryHistoriesRepository"
import { compareByPhoneticOrder } from "src/utils"

export const temporaryStorageName = "荷捌き"

export const filterTemporaryStorages = (
  stocks: MaterialOperationStock[],
  hideEmptyMaterial: boolean,
) => {
  return stocks
    .filter((s) =>
      s.shelfStocks.find(
        (shelfStock) =>
          shelfStock.storage.name === temporaryStorageName &&
          (hideEmptyMaterial ? shelfStock.shelfStock.stock > 0 : true),
      ),
    )
    .map((s) => ({
      material: s.material,
      shelfStocks: s.shelfStocks.filter(
        (shelfStock) => shelfStock.storage.name === temporaryStorageName,
      ),
      machineStocks: [],
    }))
}

export const isStockEmpty = (
  materialOperationStock: MaterialOperationStock,
) => {
  const { shelfStocks, machineStocks } = materialOperationStock

  return (
    !shelfStocks.some((shelfStock) => shelfStock.shelfStock.stock > 0) &&
    !machineStocks.some((machineStock) => machineStock.machineStock.stock > 0)
  )
}

export const materialOperationStockFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
} as const

export const sortMaterialOperationStocks = (
  materialOperationStocks: MaterialOperationStock[],
  params: { sortBy?: keyof typeof materialOperationStockFilterSortByLabels },
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: MaterialOperationStock, b: MaterialOperationStock) =>
        compareByPhoneticOrder(a.material.materialName, b.material.materialName)
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: MaterialOperationStock, b: MaterialOperationStock) =>
        compareByPhoneticOrder(b.material.materialName, a.material.materialName)
    }
    return () => 1
  }

  return materialOperationStocks.sort(getSortCompareBy())
}

export type PlacementStock =
  | MaterialOperationShelfStock
  | MaterialOperationMachineStock

const isShelfStock = (
  stock: PlacementStock,
): stock is MaterialOperationShelfStock => {
  return (stock as MaterialOperationShelfStock).shelfStock !== undefined
}

export const sortPlacementStocks = <
  T extends PlacementStock & { material: Material },
>(
  placementStock: T[],
  params: {
    sortBy?: keyof typeof inventoryMaterialExecuteDetailsFilterSortByLabels
  },
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "phoneticOrderAsc") {
      return (a: T, b: T) =>
        isShelfStock(a) && isShelfStock(b)
          ? compareByPhoneticOrder(
              a.material.materialName,
              b.material.materialName,
            )
          : compareByPhoneticOrder(
              a.material.materialName,
              b.material.materialName,
            )
    }
    if (sortBy === "phoneticOrderDesc") {
      return (a: T, b: T) =>
        compareByPhoneticOrder(b.material.materialName, a.material.materialName)
    }
    if (sortBy === "phoneticIpOrderAsc") {
      return (a: T, b: T) => {
        if (!a.material.ipName) return 1
        if (!b.material.ipName) return -1
        return compareByPhoneticOrder(a.material.ipName, b.material.ipName)
      }
    }
    if (sortBy === "phoneticIpOrderDesc") {
      return (a: T, b: T) => {
        if (!a.material.ipName) return 1
        if (!b.material.ipName) return -1
        return compareByPhoneticOrder(b.material.ipName, a.material.ipName)
      }
    }
    return () => 1
  }

  return placementStock.sort(getSortCompareBy())
}
