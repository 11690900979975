/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeOperationOutParams,
  GetPrizeOperationOutResponse,
  PatchPrizeOperationOutRequest,
  PutPrizeOperationOutRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 出庫記録取得
 */
export const getPrizeOperationOut = (
  arcadeCd: string,
  params: GetPrizeOperationOutParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeOperationOutResponse>(
    { url: `/arcades/${arcadeCd}/prizes/operation/out`, method: "GET", params },
    options,
  )
}
/**
 * @summary 店外出庫
 */
export const putPrizeOperationOut = (
  arcadeCd: string,
  date: string,
  putPrizeOperationOutRequest: PutPrizeOperationOutRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/out/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationOutRequest,
    },
    options,
  )
}
/**
 * @summary 出庫記録の SEAMS データ処理状態フラグを更新
 */
export const patchPrizeOperationOut = (
  arcadeCd: string,
  date: string,
  prizeCd: string,
  patchPrizeOperationOutRequest: PatchPrizeOperationOutRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/out/${date}/${prizeCd}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeOperationOutRequest,
    },
    options,
  )
}
export type GetPrizeOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeOperationOut>>
>
export type PutPrizeOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationOut>>
>
export type PatchPrizeOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeOperationOut>>
>
