import { useMemo, useRef, useState } from "react"

import { Print, Launch, SelectAll, Deselect } from "@mui/icons-material"
import {
  Box,
  Typography,
  Stack,
  Button,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeBoothSales } from "src/api/prize-sales"
import { CheckCircle } from "src/components/atoms/CheckCircle"
import { DatePicker } from "src/components/organisms/DatePicker"
import {
  FloorMapBox,
  FloorMapBoxHandler,
  FloorMapPointBoxProps,
  PrizeDailySalesFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import { PrizeDailyPrintInstruction } from "src/components/organisms/prizes/PrizeDailyPrintInstruction"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { convertPrizePlanWithSalesFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import {
  calcPrizeDailySales,
  PrizeDailySale,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { prizeDailyFloorMapDateLabelState } from "src/recoil"
import { getLocalStorageItem, setLocalStorageItem } from "src/storages"

export const PrizeDailySalesFloorMapPrintSettings: React.FC = () => {
  const { arcadeCd } = useParams()

  const [datePickerDateLabel, setDatePickerDateLabel] = useRecoilState(
    prizeDailyFloorMapDateLabelState,
  )

  const [pageSize, setPageSize] = useState<"a4" | "a3">(() =>
    getLocalStorageItem("printPageSize") === "a3" ? "a3" : "a4",
  )

  // 印刷用に選択中のポイント
  const [selectedPointIds, setSelectedPointIds] = useState<number[]>([])

  return (
    <MainContentLayout
      title="フロアマップの印刷設定 ※PC推奨"
      renderFilter={() => (
        <>
          <PrizeDailyPrintInstruction sx={{ mb: 2 }} />
          <Paper
            sx={{
              px: 3,
              py: 3.5,
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Stack sx={{ flex: 1 }}>
              <DatePicker
                label="日付指定"
                value={datePickerDateLabel}
                onChange={setDatePickerDateLabel}
                hideTodayButton
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <TextField
                select
                label="用紙サイズ"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value === "a3" ? "a3" : "a4")
                }}
              >
                <MenuItem value="a4">A4 横</MenuItem>
                <MenuItem value="a3">A3 横</MenuItem>
              </TextField>
            </Stack>
            <Button
              variant="outlined"
              startIcon={<Print />}
              endIcon={<Launch />}
              disabled={selectedPointIds.length === 0}
              onClick={() => {
                setLocalStorageItem("printPageSize", pageSize)
                window.open(
                  `/arcades/${arcadeCd}/prizes/sales/daily/floorMap/print?auto=1&pageSize=${pageSize}&date=${datePickerDateLabel}&points=${selectedPointIds.join(",")}`,
                  "_blank",
                )
              }}
            >
              マップを印刷
            </Button>
          </Paper>
        </>
      )}
      renderContent={() => (
        <PrizeDailySalesFloorMap
          selectedPointIds={selectedPointIds}
          onSelectPoints={setSelectedPointIds}
        />
      )}
    />
  )
}

type PrizeDailySalesFloorMapProps = {
  selectedPointIds?: number[]
  onSelectPoints?: (pointIds: number[]) => void
}

const PrizeDailySalesFloorMap: React.FC<PrizeDailySalesFloorMapProps> = ({
  selectedPointIds = [],
  onSelectPoints,
}) => {
  const { arcadeCd } = useParams()
  const datePickerDateLabel = useRecoilValue(prizeDailyFloorMapDateLabelState)

  const mapBoxHandler = useRef<FloorMapBoxHandler>(null)

  const floorMapReturn = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource
  const floorMapPoints = floorMapReturn?.data.floorMapPoints

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeDailyPlans = useMemo(
    () => prizeDailyPlansReturn?.data.plans ?? [],
    [prizeDailyPlansReturn?.data.plans],
  )

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries || [],
    [prizeDeliveriesReturn],
  )

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const prizeOrderSales = useMemo(() => {
    const sortFn = (a: PrizeDailySale, b: PrizeDailySale) => {
      return (a.sales || 0) - (b.sales || 0)
    }

    return calcPrizeDailySales(
      prizeBoothSales,
      prizeDailyPlans,
      prizeDeliveries,
      () => true,
      sortFn,
    )
  }, [prizeBoothSales, prizeDailyPlans, prizeDeliveries])

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => {
    const isSelected = Boolean(selectedPointIds.includes(point.id))
    const { topLeftX, topLeftY, bottomRightX, bottomRightY } = point

    return (
      <Box
        sx={{
          position: "absolute",
          left: topLeftX,
          top: topLeftY,
          width: bottomRightX - topLeftX,
          height: bottomRightY - topLeftY,
        }}
        data-testid={`floor-map-point-${point.id}`}
        data-point-id={point.id}
      >
        <Box
          sx={(theme) => ({
            width: "100%",
            height: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: `1px solid ${theme.palette.neutral[400]}`,
          })}
        >
          <PrizeDailySalesFloorMapPointBox key={point.id} point={point} />
        </Box>
        {isSelected && (
          <>
            {/* 選択時に borderWidth を変更すると文字の表示にも影響が出るため上から重ねる  */}
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                borderRadius: 2,
                border: `2px solid ${theme.palette.primary.main}`,
              })}
            />
            <CheckCircle
              size={24}
              sx={{ position: "absolute", top: -4, right: -4 }}
            />
          </>
        )}
      </Box>
    )
  }

  const planFloorMapPoints = useMemo(
    () =>
      (floorMapPoints || []).map((p) =>
        convertPrizePlanWithSalesFloorMapPoint(
          p,
          prizeDailyPlans ?? [],
          prizeOrderSales.items,
        ),
      ),
    [floorMapPoints, prizeDailyPlans, prizeOrderSales.items],
  )

  if (!floorMapPoints) {
    return null
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
        <Button
          variant="outlined"
          startIcon={<SelectAll />}
          disabled={selectedPointIds.length === floorMapPoints.length}
          onClick={() => mapBoxHandler.current?.selectAll()}
        >
          全ブース選択
        </Button>
        <Button
          variant="contained"
          color="error"
          startIcon={<Deselect />}
          disabled={selectedPointIds.length === 0}
          onClick={() => mapBoxHandler.current?.deselectAll()}
        >
          ブース選択解除
        </Button>
        <Typography>{selectedPointIds.length}件選択中</Typography>
      </Stack>
      <FloorMapBox
        ref={mapBoxHandler}
        floorMapPoints={planFloorMapPoints}
        getFloorMapPointBox={getFloorMapPointBox}
        onSelectPoints={onSelectPoints}
      />
    </>
  )
}
