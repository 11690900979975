import { useState, useMemo } from "react"

import { ErrorOutline, SearchRounded } from "@mui/icons-material"
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  TableHead,
  TableRow,
  Typography,
  alpha,
  Card,
  CardContent,
  Stack,
} from "@mui/material"

import {
  MaterialInventoryPlacementStatus,
  MaterialMachinesElement,
  MaterialInventoryPlacementStatusExecutionStatusEnum,
  MaterialMachineWarningEnum,
} from "src/api/models"
import emptyIcon from "src/assets/icon_empty.png"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { InventoryMaterialAmMachineWarningCard } from "src/components/organisms/materials/InventoryMaterialAmMachineWarningCard"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { theme } from "src/theme"

const NOT_SELECTED = "_not_selected" as const

export type InventoryMaterialExecutePlacementListItem<
  T extends MaterialPlacementType,
> = {
  // NOTE: 保管場所の場合は shelfId, 材料機械内の場合は materialMachineId
  storageId?: T extends "storage" ? number : never
  shelfId?: T extends "storage" ? number : never
  amMachineNumber?: T extends "storage" ? never : string
  materialMachineId?: T extends "storage" ? never : number
  name: string
  parentName: string
  isAvailable: boolean
  groupName?: string
  executionStatus?: MaterialInventoryPlacementStatusExecutionStatusEnum
  executedAt?: MaterialInventoryPlacementStatus["executedAt"]
  warning?: T extends "in_machine" ? MaterialMachineWarningEnum : never
}

export type InventoryMaterialExecuteListColumn = {
  name: string
  key: "name" | "parentName" | "groupName" | "executionStatus" | "executedAt"
}

type InventoryExecuteListProps<T extends MaterialPlacementType> = {
  placement: T
  groupNames?: string[]
  listItems: InventoryMaterialExecutePlacementListItem<T>[]
  warningMaterialMachines?: MaterialMachinesElement[]
  columns?: InventoryMaterialExecuteListColumn[]
  handleOnClick: (
    listItem: InventoryMaterialExecutePlacementListItem<T>,
  ) => void
}

export const InventoryMaterialExecuteList = <T extends MaterialPlacementType>({
  placement,
  groupNames,
  listItems,
  warningMaterialMachines = [],
  columns,
  handleOnClick,
}: InventoryExecuteListProps<T>) => {
  const [selectedGroupName, setSelectedGroupName] =
    useState<string>(NOT_SELECTED)

  return (
    <Grid container item xs={12}>
      {groupNames && (
        <Grid item xs={12} pb={2}>
          <Card>
            <CardContent sx={{ ":last-child": { pb: 2 } }}>
              <TextField
                select
                label="棚卸グループ"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchRounded />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSelectedGroupName(e.target.value)}
                value={selectedGroupName}
              >
                <MenuItem value={NOT_SELECTED}>指定なし</MenuItem>
                {groupNames.map((groupName: string) => (
                  <MenuItem value={groupName} key={groupName}>
                    {groupName}
                  </MenuItem>
                ))}
              </TextField>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        {warningMaterialMachines.length > 0 && (
          <InventoryMaterialAmMachineWarningCard
            materialMachines={warningMaterialMachines}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <InventoryMaterialExecuteListMenu
          placement={placement}
          selectedGroupName={selectedGroupName}
          listItems={listItems}
          columns={columns}
          handleOnClick={handleOnClick}
        />
      </Grid>
    </Grid>
  )
}

export const defaultInventoryMaterialExecuteListColumns = (
  placement: MaterialPlacementType,
): InventoryMaterialExecuteListColumn[] => [
  {
    name: placement === MaterialPlacementType.Storage ? "棚" : "材料機械",
    key: "name",
  },
  { name: "棚卸グループ", key: "groupName" },
  { name: "確定", key: "executionStatus" },
  { name: "実査日", key: "executedAt" },
]

export const amMachineInventoryMaterialExecuteListColumns: InventoryMaterialExecuteListColumn[] =
  [
    { name: "AM機器名称", key: "parentName" },
    { name: "確定", key: "executionStatus" },
    { name: "実査日", key: "executedAt" },
  ]

type InventoryExecuteListMenuProps<T extends MaterialPlacementType> = Omit<
  InventoryExecuteListProps<T>,
  "groupNames"
> & {
  selectedGroupName: string
}

const InventoryMaterialExecuteListMenu = <T extends MaterialPlacementType>({
  placement,
  selectedGroupName,
  listItems,
  columns = defaultInventoryMaterialExecuteListColumns(placement),
  handleOnClick,
}: InventoryExecuteListMenuProps<T>) => {
  const filteredListItems = useMemo(
    () =>
      selectedGroupName !== NOT_SELECTED
        ? listItems.filter(({ groupName }) => groupName === selectedGroupName)
        : listItems,
    [selectedGroupName, listItems],
  )

  const onRowClick = (
    listItem: InventoryMaterialExecutePlacementListItem<T>,
  ) => {
    handleOnClick(listItem)
  }

  return (
    <PaginatedTable
      noMargin
      items={filteredListItems}
      stateKey="inventoryMaterialExecuteList"
      header={
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <ExtTableCell
                sx={{ px: 1, whiteSpace: "nowrap" }}
                key={column.name}
              >
                {column.name}
              </ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(listItem) => {
        const {
          shelfId,
          materialMachineId,
          executionStatus,
          isAvailable,
          warning,
        } = listItem
        const { Executed, Empty } =
          MaterialInventoryPlacementStatusExecutionStatusEnum
        const isBackgroundError =
          warning != MaterialMachineWarningEnum.None &&
          columns.findIndex((c) => c.key == "parentName") != -1
        return (
          <TableBorderedRow
            key={
              placement === MaterialPlacementType.Storage
                ? shelfId
                : materialMachineId
            }
            sx={{
              td: {
                ...(isBackgroundError
                  ? {
                      background: theme.palette.error.light,
                    }
                  : !isAvailable && {
                      background: alpha(theme.palette.text.disabled, 0.3),
                    }),
                px: 2,
                py: 1,
              },
              "&:hover td": {
                cursor: "pointer",
                background: (theme) => theme.palette.neutral[200],
              },
            }}
          >
            {columns.map((column) => {
              if (column.key === "parentName") {
                return (
                  <ExtTableCell
                    key={column.key}
                    onClick={() => onRowClick(listItem)}
                  >
                    {listItem[column.key]}
                    {warning != MaterialMachineWarningEnum.None && (
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        <ErrorOutline
                          sx={{ color: theme.palette.error.main }}
                        />
                        <Typography variant="body2" color="error.main">
                          {warning ==
                            MaterialMachineWarningEnum.AmMachineArcadeChanged &&
                            "AM機器が店舗変更されています"}
                          {warning ==
                            MaterialMachineWarningEnum.AmMachineDisabled &&
                            "AM機器が無効です"}
                        </Typography>
                      </Stack>
                    )}
                  </ExtTableCell>
                )
              } else if (column.key === "executionStatus") {
                return (
                  <ExtTableCell key={column.key} sx={{ whiteSpace: "nowrap" }}>
                    {executionStatus === Executed && (
                      <img
                        src={finishedIcon}
                        alt="済"
                        style={{ width: 32, height: 32 }}
                      />
                    )}
                    {executionStatus === Empty && (
                      <img
                        src={emptyIcon}
                        alt="空"
                        style={{ width: 32, height: 32 }}
                      />
                    )}
                    {!isAvailable && (
                      <Typography
                        sx={{
                          background: theme.palette.text.disabled,
                          color: "white",
                          p: 1,
                          textAlign: "center",
                        }}
                        variant="subtitle1"
                      >
                        無効
                      </Typography>
                    )}
                  </ExtTableCell>
                )
              } else {
                return (
                  <>
                    <ExtTableCell
                      key={column.key}
                      onClick={() => onRowClick(listItem)}
                    >
                      {listItem[column.key]}
                    </ExtTableCell>
                  </>
                )
              }
            })}
          </TableBorderedRow>
        )
      }}
    />
  )
}
