import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  TableRow,
  Table,
  TableBody,
  DialogContent,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { PrizeOperationInHistoryElement } from "src/api/models"
import { patchPrizeOperationIn } from "src/api/prize-operation-in"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { useSubmitting } from "src/hooks/useSubmitting"
import { formatApiDate } from "src/utils"

interface InventorySeamsInModalProps {
  showModal: boolean
  inHistory: PrizeOperationInHistoryElement
  onClose: () => void
  onFinish: () => void
}

export const InventoryPrizeSeamsInModal: React.FC<
  InventorySeamsInModalProps
> = ({
  showModal,
  inHistory,
  onClose,
  onFinish,
}: InventorySeamsInModalProps) => {
  const { arcadeCd } = useParams()
  const {
    inHistory: { registeredSeams, stock, inAt },
    prize: { prizeCd, prizeName },
  } = inHistory

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    const request = {
      registerSeams: !registeredSeams,
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "SEAMS登録状況の更新",
          showSuccessMessage: true,
          promise: async () => {
            await patchPrizeOperationIn(
              arcadeCd,
              formatApiDate(inAt),
              prizeCd,
              request,
            )
            onClose()
            onFinish()
          },
        },
      ]))
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1" pb={1}>
            {prizeName}
          </Typography>

          <Box
            sx={{
              maxWidth: "80%",
              my: 1,
              mx: "auto",
            }}
          >
            <PrizeImageBox
              prizeCd={prizeCd}
              alt={prizeName}
              noImageSize="large"
            />
          </Box>
        </DialogTitleWithClose>

        <DialogContent>
          <Table size="small" sx={{ td: { border: "none", px: 0 } }}>
            <TableBody>
              <TableRow>
                <ExtTableCell>入庫数</ExtTableCell>
                <ExtTableCell align="right">
                  <strong>{stock}</strong> 個
                </ExtTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>

        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
            sx={{ height: 44, lineHeight: 1.2, px: 0.5 }}
          >
            {registeredSeams ? "入庫登録状況を変更する" : "SEAMS登録済にする"}
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
