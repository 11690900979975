import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Delete, Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  DialogContent,
  TextField,
  Stack,
  IconButton,
  TableRow,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from "react-hook-form"
import * as Yup from "yup"

import { PrizeFieldSetting, PutPrizeSettingFieldRequest } from "src/api/models"
import {
  deletePrizeSettingField,
  getPrizeSettingFields,
  putPrizeSettingField,
} from "src/api/prize-settings"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

export const PrizeFieldSettings = () => {
  return (
    <MainContentLayout
      title="プライズ機フィールド設定一覧"
      renderContent={() => <PrizeFieldSettingsInner />}
    />
  )
}

const PrizeFieldSettingsInner: React.FC = () => {
  const { isAdmin } = useUserRole()
  const { resource: fieldSettingsResource, refetch } = useResource({
    subject: "フィールド設定リストの取得",
    fetch: () => getPrizeSettingFields(),
    recoilKey: `getPrizeSettingFields`,
  })
  const fields = useMemo(
    () => fieldSettingsResource?.data.fields || [],
    [fieldSettingsResource],
  )

  const [showAddModal, setShowAddModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedPrizeFieldSetting, setSelectedPrizeFieldSetting] = useState<
    PrizeFieldSetting | undefined
  >(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const onFinishModal = () => refetch()

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          sx={{ mr: 2, mb: 2, whiteSpace: "nowrap" }}
          startIcon={<Add />}
          onClick={() => setShowAddModal(true)}
        >
          追加
        </Button>
      )}

      <Stack sx={{ maxHeight: "80dvh" }}>
        <PrizeFieldSettingsTable
          prizeFieldSettings={fields}
          onClickEdit={(field: PrizeFieldSetting) => {
            setSelectedPrizeFieldSetting(field)
            setShowUpdateModal(true)
          }}
          onClickDelete={(field: PrizeFieldSetting) => {
            setSelectedPrizeFieldSetting(field)
            setShowDeleteModal(true)
          }}
        />
      </Stack>

      <PrizeFieldSettingAddModal
        showModal={showAddModal}
        prizeFieldSettings={fields}
        onClose={() => setShowAddModal(false)}
        onFinish={onFinishModal}
      />

      <PrizeFieldSettingUpdateModal
        showModal={showUpdateModal}
        prizeFieldSetting={selectedPrizeFieldSetting}
        prizeFieldSettings={fields}
        onClose={() => {
          setShowUpdateModal(false)
          setSelectedPrizeFieldSetting(undefined)
        }}
        onFinish={onFinishModal}
      />

      <PrizeFieldSettingDeleteModal
        showModal={showDeleteModal}
        prizeFieldSetting={selectedPrizeFieldSetting}
        onClose={() => {
          setShowDeleteModal(false)
          setSelectedPrizeFieldSetting(undefined)
        }}
        onFinish={onFinishModal}
      />
    </>
  )
}

type PrizeFieldSettingsTableProps = {
  prizeFieldSettings: PrizeFieldSetting[]
  onClickEdit: (fieldSetting: PrizeFieldSetting) => void
  onClickDelete: (fieldSetting: PrizeFieldSetting) => void
}

const PrizeFieldSettingsTable: React.FC<PrizeFieldSettingsTableProps> = ({
  prizeFieldSettings,
  onClickEdit,
  onClickDelete,
}: PrizeFieldSettingsTableProps) => {
  const { isAdmin } = useUserRole()
  return (
    <PaginatedTable
      noMargin
      scrollableY
      items={prizeFieldSettings}
      stateKey="prizeFieldSettings"
      renderRow={(fieldSetting) => {
        return (
          <TableRow key={fieldSetting.id} data-testid={fieldSetting.id}>
            <ExtTableCell sx={{ p: 2 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle1">{fieldSetting.name}</Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <IconButton
                    color="primary"
                    disabled={!isAdmin}
                    onClick={() => onClickEdit(fieldSetting)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={!isAdmin}
                    onClick={() => onClickDelete(fieldSetting)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

type PrizeFieldSettingFormInput = Omit<PutPrizeSettingFieldRequest, "id">

type PrizeFieldSettingAddModalProps = {
  showModal: boolean
  prizeFieldSettings: PrizeFieldSetting[]
  onClose: () => void
  onFinish?: () => void
}

export const PrizeFieldSettingAddModal: React.FC<
  PrizeFieldSettingAddModalProps
> = ({
  showModal,
  onClose,
  prizeFieldSettings,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) => !prizeFieldSettings.some((s) => s.name === value),
      ),
  })
  const useFormReturn = useForm<PrizeFieldSettingFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  })
  const { reset } = useFormReturn

  const onSubmit: SubmitHandler<PrizeFieldSettingFormInput> = async ({
    name,
  }) => {
    const request: PutPrizeSettingFieldRequest = {
      name,
    }

    await submitPromises([
      {
        subject: "フィールド設定の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingField(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機フィールド設定を追加"
    />
  )
}

type PrizeFieldSettingUpdateModalProps = {
  showModal: boolean
  prizeFieldSetting?: PrizeFieldSetting
  prizeFieldSettings: PrizeFieldSetting[]
  onClose: () => void
  onFinish?: () => void
}

export const PrizeFieldSettingUpdateModal: React.FC<
  PrizeFieldSettingUpdateModalProps
> = ({
  showModal,
  prizeFieldSetting,
  prizeFieldSettings,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) =>
          !prizeFieldSettings
            .filter((s) => prizeFieldSetting && s.id !== prizeFieldSetting.id)
            .some((s) => s.name === value),
      ),
  })
  const useFormReturn = useForm<PrizeFieldSettingFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  })
  const { setValue, reset } = useFormReturn

  useMemo(() => {
    if (prizeFieldSetting) {
      setValue("name", prizeFieldSetting.name)
    }
  }, [prizeFieldSetting, setValue])

  const onSubmit: SubmitHandler<PrizeFieldSettingFormInput> = async ({
    name,
  }) => {
    if (!prizeFieldSetting) return
    const request: PutPrizeSettingFieldRequest = {
      id: prizeFieldSetting.id,
      name,
    }

    await submitPromises([
      {
        subject: "フィールド設定の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingField(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機フィールド設定編集"
    />
  )
}

type EditModalInnerProps = {
  showModal: boolean
  onSubmit: SubmitHandler<PrizeFieldSettingFormInput>
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<PrizeFieldSettingFormInput, any>
  title: string
}

const EditModalInner: React.FC<EditModalInnerProps> = ({
  showModal,
  onSubmit,
  onClose,
  useFormReturn,
  title,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useFormReturn

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose
          sx={{ textAlign: "left", mt: 1 }}
          variant="h2"
          onClose={onClose}
        >
          <Typography variant="h2">{title}</Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Stack gap={1}>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              設定名
            </Typography>
            <Controller
              control={control}
              name={"name"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

type PrizeFieldSettingDeleteModalProps = {
  showModal: boolean
  prizeFieldSetting?: PrizeFieldSetting
  onClose: () => void
  onFinish?: () => void
}

export const PrizeFieldSettingDeleteModal: React.FC<
  PrizeFieldSettingDeleteModalProps
> = ({ showModal, prizeFieldSetting, onClose, onFinish = () => undefined }) => {
  const { submitPromises } = useSubmitting()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickDelete = async () => {
    if (!prizeFieldSetting) return
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "フィールド設定の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeSettingField(prizeFieldSetting.id)
          onClose()
          onFinish()
        },
      },
    ])
    setIsSubmitting(false)
  }

  if (prizeFieldSetting) {
    // NOTE: onClose 時に prizeFieldSetting が undefined になるので、message のちらつきを防止
    return (
      <DeleteModal
        showModal={showModal}
        onSubmit={onClickDelete}
        onClose={onClose}
        isSubmitting={isSubmitting}
        message={`「${prizeFieldSetting.name}」を削除しますか？`}
      />
    )
  } else {
    return <></>
  }
}
