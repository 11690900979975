import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import {
  CsvRow,
  FeatureExecutionPeriod,
  MaterialInventoryPlacementStatus,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import {
  getMaterialExecutionStatusText,
  getMaterialPlacementTypeText,
  MaterialPlacementType,
  MaterialPlacementTypeWithAll,
} from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState, inventoryPeriodsState } from "src/recoil"

type MaterialInventoryPlacementStatusWithMaterialPlacementType =
  MaterialInventoryPlacementStatus & {
    placementType: MaterialPlacementType
  }

const csvHeader: CsvRow = {
  columns: ["有効/無効", "区分", "名前", "ステータス"],
}

export const InventoryMaterialPlacementStatusHistoriesCsv: React.FC = () => {
  const { arcadeCd } = useParams()

  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const arcade = useRecoilValue(currentArcadeState)
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const materialExecutionPeriods = useMemo(
    () =>
      inventoryPeriods?.flatMap((period) =>
        period.materialExecutionPeriods.filter(
          (materialPeriod) =>
            materialPeriod.status === FeatureExecutionPeriodStatusEnum.Ended,
        ),
      ),
    [inventoryPeriods],
  )

  const [
    selectedMaterialExecutionPeriodId,
    setSelectedMaterialExecutionPeriodId,
  ] = useState<FeatureExecutionPeriod["id"] | undefined>()
  const selectedMaterialExecutionPeriod = useMemo(
    () =>
      materialExecutionPeriods?.find(
        (period) => period.id === selectedMaterialExecutionPeriodId,
      ),
    [materialExecutionPeriods, selectedMaterialExecutionPeriodId],
  )

  const { isAvailablePlacementStatusHistoriesCsv } = useUserRole()

  useEffect(
    () =>
      materialExecutionPeriods &&
      setSelectedMaterialExecutionPeriodId(materialExecutionPeriods[0]?.id),
    [materialExecutionPeriods],
  )

  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = useCallback(async () => {
    arcadeCd &&
      arcade &&
      selectedMaterialExecutionPeriod &&
      submitPromises([
        {
          subject: "全保管場所_棚卸済証明の取得",
          promise: async () => {
            const res = await getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementTypeWithAll.All,
              selectedMaterialExecutionPeriod.id,
            )
            const { shelfStatuses, machineStatuses } = res.data
            if (!shelfStatuses && !machineStatuses) {
              throw new Error()
            }

            const placementStatuses: MaterialInventoryPlacementStatusWithMaterialPlacementType[] =
              [
                ...(
                  shelfStatuses?.map((s) => ({
                    ...s,
                    placementType: MaterialPlacementType.Storage,
                  })) ?? []
                ).sort((a, b) => a.placementId - b.placementId),
                ...(
                  machineStatuses?.map((s) => ({
                    ...s,
                    placementType: MaterialPlacementType.InMachine,
                  })) ?? []
                ).sort((a, b) => a.placementId - b.placementId),
              ]

            const csvRows: CsvRow[] = placementStatuses.map(
              ({
                isAvailable,
                placementType,
                placementName,
                executionStatus,
              }) => ({
                columns: [
                  isAvailable ? "有効" : "無効",
                  getMaterialPlacementTypeText(placementType),
                  placementName,
                  getMaterialExecutionStatusText(executionStatus),
                ],
              }),
            )

            downloadCsv({
              csv: { headerRow: csvHeader, rows: csvRows },
              fileName: `材料全保管場所_棚卸済証明-${arcade.name}-${selectedMaterialExecutionPeriod.startAt}_${selectedMaterialExecutionPeriod.endAt}.csv`,
            })
          },
        },
      ])
  }, [
    arcadeCd,
    arcade,
    selectedMaterialExecutionPeriod,
    submitPromises,
    downloadCsv,
  ])

  return (
    <MainContentLayout
      title="材料全保管場所_棚卸済証明CSV"
      renderContent={() => (
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption">
              全ての保管場所が棚卸時に網羅された事を証明します。
              <br />
              このファイルは会社から求められた時のみ提出します。
            </Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            {selectedMaterialExecutionPeriodId ? (
              <FormControl fullWidth>
                <InputLabel id="period">期間</InputLabel>
                <Select
                  fullWidth
                  label="期間"
                  labelId="period"
                  value={selectedMaterialExecutionPeriodId}
                  onChange={(e) =>
                    setSelectedMaterialExecutionPeriodId(
                      e.target.value as FeatureExecutionPeriod["id"],
                    )
                  }
                >
                  {materialExecutionPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : materialExecutionPeriods?.length === 0 ? (
              "出力可能な期間がありません"
            ) : (
              <CircularProgress />
            )}
          </Card>
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={
              isSubmitting ||
              !selectedMaterialExecutionPeriodId ||
              !isAvailablePlacementStatusHistoriesCsv
            }
          >
            CSV出力
          </Button>
        </Stack>
      )}
    />
  )
}
