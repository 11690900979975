import React, {
  useState,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react"

import { Edit } from "@mui/icons-material"
import { Grid, Typography, Card, Divider, Button, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialInventoryDifferences } from "src/api/material-inventory-differences"
import { getMaterialInventoryHistories } from "src/api/material-inventory-histories"
import { getMaterialMachines } from "src/api/material-machines"
import { getMaterialStorages } from "src/api/material-storages"
import {
  FeatureExecutionPeriod,
  MaterialInventoryDifference,
  MaterialInventoryHistory,
  MaterialInventoryDifferenceGroupEnum,
} from "src/api/models"
import { InventoryMaterialApplyDifferenceModal } from "src/components/organisms/materials/InventoryMaterialApplyDifferenceModal"
import { InventoryMaterialApproveDifferenceModal } from "src/components/organisms/materials/InventoryMaterialApproveDifferenceModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { getMaterialDifferenceLabel } from "src/domains/materials/materialInventoryHistoriesRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { currentInventoryPeriodState } from "src/recoil"

export const InventoryMaterialExecuteDifferenceDetails: React.FC = () => {
  const { arcadeCd, materialCd } = useParams()
  const {
    isAvailableApplyInventoryDifference,
    isAvailableApproveInventoryDifference,
  } = useUserRole()

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const { resource: differencesResource, refetch: refetchDifferences } =
    useResource({
      subject: "差分一覧の取得",
      fetch:
        arcadeCd && currentMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryDifferences(
                arcadeCd,
                currentMaterialExecutionPeriod.id,
              )
          : undefined,
      recoilKey: `getMaterialInventoryDifferences:${arcadeCd}:${JSON.stringify(
        currentMaterialExecutionPeriod,
      )}`,
    })
  const difference = differencesResource?.data.differences?.find(
    (d: MaterialInventoryDifference) => d.materialCd === materialCd,
  )

  const isEnableApply =
    !difference?.isApproved && isAvailableApplyInventoryDifference
  const isEnableApprove =
    !difference?.isApproved && (difference?.applicationId || 0) > 0
  const ref = useRef<ApprovalHandler>(null)

  return (
    <MainContentLayout
      title={difference?.materialName ?? ""}
      renderAction={() =>
        difference?.group ===
          MaterialInventoryDifferenceGroupEnum.DiffPriceOver &&
        isAvailableApproveInventoryDifference && (
          <Button
            variant="contained"
            disabled={!isEnableApprove}
            onClick={() => ref.current?.approve()}
          >
            承認する
          </Button>
        )
      }
      renderContent={() => (
        <InventoryMaterialExecuteDifferenceDetailsMenu
          ref={ref}
          difference={difference}
          refetchDifferences={refetchDifferences}
          currentMaterialExecutionPeriod={currentMaterialExecutionPeriod}
          isEnableApply={isEnableApply}
          isEnableApprove={isEnableApprove}
        />
      )}
    />
  )
}

interface ApprovalHandler {
  approve: () => void
}

type InventoryMaterialExecuteDifferenceDetailsMenuProps = {
  difference: MaterialInventoryDifference | undefined
  refetchDifferences: () => Promise<void>
  currentMaterialExecutionPeriod: FeatureExecutionPeriod | undefined
  isEnableApply: boolean
  isEnableApprove: boolean
}

const InventoryMaterialExecuteDifferenceDetailsMenu = forwardRef<
  ApprovalHandler,
  InventoryMaterialExecuteDifferenceDetailsMenuProps
>(
  (
    {
      difference,
      refetchDifferences,
      currentMaterialExecutionPeriod,
      isEnableApply,
      isEnableApprove,
    },
    ref,
  ) => {
    const { arcadeCd, materialCd } = useParams()

    const { resource: historiesResource } = useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryHistories(
                arcadeCd,
                currentMaterialExecutionPeriod.id,
                { materialCd },
              )
          : undefined,
      recoilKey: `getMaterialInventoryHistories:${arcadeCd}:${JSON.stringify(
        currentMaterialExecutionPeriod,
      )}:${materialCd}`,
    })
    const histories = historiesResource?.data.histories

    const [showApplyModal, setShowApplyModal] = useState<boolean>(false)
    const onClickApplyApproval = () => {
      if (isEnableApply) setShowApplyModal(true)
    }
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false)
    const onClickApprove = () => {
      if (isEnableApprove) setShowApproveModal(true)
    }

    const stackRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(
      ref,
      () => ({
        approve() {
          stackRef.current?.click()
        },
      }),
      [],
    )

    const { storages } =
      useResource({
        subject: "保管場所一覧の取得",
        fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
        recoilKey: `getMaterialStorages:${arcadeCd}`,
      }).resource?.data ?? {}
    const { materialMachines: materialMachinesElements } =
      useResource({
        subject: "材料機械一覧の取得",
        fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
        recoilKey: `getMaterialMachines:${arcadeCd}`,
      }).resource?.data ?? {}

    const { Storage, InMachine } = MaterialPlacementType
    const items: (MaterialInventoryHistory & { name: string })[] = useMemo(
      () =>
        histories?.map((history) => {
          let name = ""

          switch (history.placement) {
            case Storage:
              storages?.some(({ storage, shelves }) => {
                let storageName = ""
                if (storage.id !== history.shelfStock?.storageId) return false

                storageName = storage.name

                return shelves?.some((shelf) => {
                  if (shelf.id !== history.shelfStock?.shelfId) return false

                  name = `${storageName} ${shelf.name}`
                  return true
                })
              })
              break
            case InMachine:
              materialMachinesElements?.some(
                ({ amMachine, materialMachine }) => {
                  if (
                    materialMachine.id !==
                    history.machineStock?.materialMachineId
                  )
                    return false

                  name = getDisplayMaterialMachineName(
                    amMachine,
                    materialMachine,
                  )
                  return true
                },
              )

              break
            default:
              // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-case-declarations
              const _never: never = history.placement
          }

          return { ...history, name }
        }) ?? [],
      [InMachine, Storage, histories, materialMachinesElements, storages],
    )

    const rows = useMemo(
      () =>
        items
          ? items.map(({ name, stock }) => {
              return (
                <Grid container item xs={12} key={name}>
                  <Grid item xs={6}>
                    <p>{name}</p>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <strong>{stock}</strong> 個
                  </Grid>
                </Grid>
              )
            })
          : [],
      [items],
    )

    const display = getMaterialDifferenceLabel(difference)
    return (
      <>
        <Stack gap={2}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {difference?.group ===
              MaterialInventoryDifferenceGroupEnum.DiffPriceOver && (
              <Stack sx={{ flexDirection: "row", mr: 3 }}>
                <Typography sx={{ mr: 1 }} variant="caption">
                  ステータス
                </Typography>
                <Typography variant="subtitle2" color="error.main">
                  {display.isApproved}
                </Typography>
              </Stack>
            )}
            <Stack sx={{ flexDirection: "row", mr: 3 }}>
              <Typography sx={{ mr: 1 }} variant="caption">
                材料CD(GiGO NAVI)
              </Typography>
              <Typography variant="subtitle2">{display.materialCd}</Typography>
            </Stack>
            <Stack sx={{ flexDirection: "row", mr: 3 }}>
              <Typography sx={{ mr: 1 }} variant="caption">
                材料CD(SEAMS)
              </Typography>
              <Typography variant="subtitle2">
                {display.materialCdsInSeams}
              </Typography>
            </Stack>
          </Stack>

          <Card sx={{ p: 2 }}>
            <Grid container spacing={3}>
              {difference?.group ===
                MaterialInventoryDifferenceGroupEnum.DiffPriceOver && (
                <>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      戻しが10万円を超えた理由
                    </Typography>
                  </Grid>
                  <Grid item xs={9} textAlign="left">
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "fit-content",
                        spacing: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "pre-wrap",
                          ...(display.application === "未入力" && {
                            color: "error.main",
                          }),
                        }}
                      >
                        {display.application}
                      </Typography>
                      {isEnableApply && (
                        <Edit
                          sx={(theme) => ({
                            color: theme.palette.icon.blue,
                            mx: 1,
                            ...(isEnableApply && {
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }),
                          })}
                          fontSize="small"
                          onClick={() => onClickApplyApproval()}
                        />
                      )}
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  データ個数(SEAMS)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.importedStock} 個
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  払い出し個数
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.payoutStock} 個
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  今回消費した金額
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.consumedPrice} 円
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  材料の有無(SEAMS)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">{display.isInSeams}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  材料の有無(GiGO NAVI)
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">{display.isInGigoNavi}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  SEAMSとの差額
                </Typography>
              </Grid>
              <Grid item xs={9} textAlign="left">
                <Typography variant="body2">
                  {display.consumedPrice} 円
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  今回実査した個数
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="body2">
                  合計 <strong>{display.stock}</strong> 個
                </Typography>
              </Grid>

              {rows.length > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}

              {rows}
            </Grid>
          </Card>
        </Stack>

        {difference?.group ===
          MaterialInventoryDifferenceGroupEnum.DiffPriceOver && (
          <>
            <InventoryMaterialApplyDifferenceModal
              showModal={showApplyModal}
              difference={difference}
              materialExecutionPeriodId={
                currentMaterialExecutionPeriod?.id || 0
              }
              onClose={() => setShowApplyModal(false)}
              onFinish={() => refetchDifferences()}
            />
            <InventoryMaterialApproveDifferenceModal
              showModal={showApproveModal}
              difference={difference}
              materialExecutionPeriodId={
                currentMaterialExecutionPeriod?.id || 0
              }
              onClose={() => setShowApproveModal(false)}
              onFinish={() => refetchDifferences()}
            />
          </>
        )}

        <Stack onClick={onClickApprove} ref={stackRef} hidden />
      </>
    )
  },
)
InventoryMaterialExecuteDifferenceDetailsMenu.displayName =
  "InventoryMaterialExecuteDifferenceDetailsMenu"
