import { Fragment, useState } from "react"

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { LoadingButton } from "@mui/lab"
import { Stack, TextField, MenuItem, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"

const NEW_INVENTORY_GROUP = "_new_inventory_group" as const

export type InventoryPrizeGroupEditModalTableItem = {
  placementShelfId: number
  name: string
  groupName?: string
}
type InventoryGroupEditModalProps = {
  onSubmit: (
    groupName: string,
    items: InventoryPrizeGroupEditModalTableItem[],
  ) => void
  onClose: () => void
  open: boolean
  tableItems: InventoryPrizeGroupEditModalTableItem[]
  existGroupNames: string[]
  isSubmitting: boolean
}

export const InventoryPrizeGroupEditModal: React.FC<
  InventoryGroupEditModalProps
> = ({
  onSubmit,
  onClose,
  open,
  tableItems,
  existGroupNames,
  isSubmitting,
}) => {
  const [groupName, setGroupName] = useState("")
  const [newGroupName, setNewGroupName] = useState("")

  return (
    <CustomDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitleWithClose
        onClose={onClose}
        variant="h2"
        sx={{ pt: 3, pb: 1 }}
      >
        {tableItems.map(({ name, groupName }, i) => (
          <Fragment key={i}>
            {name} {groupName || "棚卸グループ設定なし"}
          </Fragment>
        ))}
      </DialogTitleWithClose>
      <DialogContent>
        <Stack sx={{ alignItems: "center" }} spacing={1}>
          <ArrowDownwardIcon />
          <TextField
            select
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          >
            {existGroupNames.map((groupName) => (
              <MenuItem value={groupName} key={groupName}>
                {groupName}
              </MenuItem>
            ))}
            <MenuItem
              value={NEW_INVENTORY_GROUP}
              sx={(t) => ({
                backgroundColor: t.palette.warning.light,
              })}
            >
              新規作成
            </MenuItem>
          </TextField>
          {groupName === NEW_INVENTORY_GROUP && (
            <TextField
              value={newGroupName}
              fullWidth
              onChange={(e) => setNewGroupName(e.target.value)}
              label="棚卸グループ名"
              error={existGroupNames.includes(newGroupName)}
              helperText={
                existGroupNames.includes(newGroupName) &&
                "既に存在するグループ名です"
              }
            />
          )}
        </Stack>
      </DialogContent>
      <CustomDialogActions sx={{ pt: 0 }}>
        <BackButton onClick={onClose}>保存せず戻る</BackButton>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() =>
            onSubmit(
              groupName === NEW_INVENTORY_GROUP ? newGroupName : groupName,
              tableItems,
            )
          }
          disabled={
            groupName === "" ||
            (groupName === NEW_INVENTORY_GROUP &&
              (newGroupName === "" || existGroupNames.includes(newGroupName)))
          }
          loading={isSubmitting}
        >
          変更を保存
        </LoadingButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
