/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeInventoryApprovalsResponse,
  GetPrizeInventoryDifferenceParams,
  GetPrizeInventoryDifferenceResponse,
  PutPrizeInventoryApprovalApplyRequest,
  PutPrizeInventoryApprovalApproveRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚卸時の在庫と論理在庫の差分一覧を取得
 */
export const getPrizeInventoryDifference = (
  arcadeCd: string,
  prizeExecutionPeriodId: number,
  params?: GetPrizeInventoryDifferenceParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeInventoryDifferenceResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/difference/${prizeExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 承認済みの差分一覧を取得
 */
export const getPrizeInventoryApprovals = (
  arcadeCd: string,
  prizeExecutionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeInventoryApprovalsResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/approvals/${prizeExecutionPeriodId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 戻しが高額な差分の承認の申請
 */
export const applyPrizeInventoryDifference = (
  arcadeCd: string,
  prizeExecutionPeriodId: number,
  putPrizeInventoryApprovalApplyRequest: PutPrizeInventoryApprovalApplyRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/approvals/${prizeExecutionPeriodId}/apply`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeInventoryApprovalApplyRequest,
    },
    options,
  )
}
/**
 * @summary 戻しが高額な差分の承認
 */
export const approvePrizeInventoryDifference = (
  arcadeCd: string,
  prizeExecutionPeriodId: number,
  putPrizeInventoryApprovalApproveRequest: PutPrizeInventoryApprovalApproveRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/approvals/${prizeExecutionPeriodId}/approve`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeInventoryApprovalApproveRequest,
    },
    options,
  )
}
export type GetPrizeInventoryDifferenceResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeInventoryDifference>>
>
export type GetPrizeInventoryApprovalsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeInventoryApprovals>>
>
export type ApplyPrizeInventoryDifferenceResult = NonNullable<
  Awaited<ReturnType<typeof applyPrizeInventoryDifference>>
>
export type ApprovePrizeInventoryDifferenceResult = NonNullable<
  Awaited<ReturnType<typeof approvePrizeInventoryDifference>>
>
