import {
  Arcade,
  ArcadeUser,
  Area,
  FeatureExecutionPeriodStatusEnum,
  UserRole,
} from "src/api/models"
import {
  findCurrentInventoryPeriod,
  findLastExecutionPeriod,
  findLastFeatureExecutionPeriod,
} from "src/domains/inventoryExecutionPeriodRepository"
import { UserRoleKey } from "src/domains/userRepository"

export const groupArcadeUsersByRole = (
  arcadeUsers: ArcadeUser[],
): { [key: string]: ArcadeUser[] | undefined } => {
  const roles: { [key: string]: ArcadeUser[] | undefined } = Object.keys(
    UserRole,
  )
    .filter((roleKey): roleKey is UserRoleKey => !!roleKey)
    .reduce((obj, userRoleKey) => {
      return { ...obj, [UserRole[userRoleKey]]: [] }
    }, {})
  arcadeUsers.forEach((user) => roles[user.userRole]?.push(user))
  return roles
}

export const arcadeUserRoles: UserRole[] = [
  UserRole.StoreLeader,
  UserRole.FloorManager,
  UserRole.Chief,
  UserRole.Staff,
]

export const isArcadeUserRole = (role: UserRole) =>
  arcadeUserRoles.includes(role)

export const getLastEndAt = (arcade: Arcade) => {
  const period = findLastExecutionPeriod(arcade.executionPeriods)
  return period !== undefined ? period.endAt : undefined
}

export const isMaterialEnabled = (arcade: Arcade | undefined) =>
  arcade
    ? arcade.executionPeriods.flatMap((v) => v.materialExecutionPeriods)
        .length > 0
    : false

export type ArcadeDetail = Arcade & {
  areaName: string
  lastEndAt: string
  lastPrizeExecutionStatus: FeatureExecutionPeriodStatusEnum | undefined
  lastMaterialExecutionStatus: FeatureExecutionPeriodStatusEnum | undefined
}

export const getArcadeDetails = (arcades: Arcade[], areas: Area[]) =>
  arcades.map<ArcadeDetail>((arcade) => {
    const currentInventoryPeriod = findCurrentInventoryPeriod(
      arcade.executionPeriods,
    )
    const { prizeExecutionPeriod, materialExecutionPeriod } =
      findLastFeatureExecutionPeriod(currentInventoryPeriod)
    const { status: prizeExecutionStatus } = prizeExecutionPeriod || {}
    const { status: materialExecutionStatus } = materialExecutionPeriod || {}

    const lastEndAt = getLastEndAt(arcade)

    return {
      ...arcade,
      areaName: areas.find((area) => arcade.areaId === area.id)?.name || "",
      lastEndAt: lastEndAt ? lastEndAt : "",
      lastPrizeExecutionStatus: prizeExecutionStatus,
      lastMaterialExecutionStatus: materialExecutionStatus,
    }
  })
