import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  TextField,
  FormHelperText,
  Card,
  Stack,
  Select,
  MenuItem,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { getAmMachine, patchAmMachine } from "src/api/am-machines"
import { getMachines } from "src/api/machines"
import {
  PatchAmMachineRequest,
  AmMachineUsageCategoryEnum,
  PatchAmMachineRequestUsageCategoryEnum,
  PatchAmMachineRequestPrizeMeterCategoryEnum,
} from "src/api/models"
import { LabeledAutocomplete } from "src/components/molecules/LabeledAutocomplete"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"
import { toNumber } from "src/utils/validation"

type FormType = Omit<PatchAmMachineRequest, "note"> & {
  note?: string
}

export const AmMachineDetails = () => {
  return (
    <MainContentLayout
      title="AM機器編集"
      renderContent={() => <AmMachineDetailsForm />}
    />
  )
}

const AmMachineDetailsForm = () => {
  const { arcadeCd, amMachineNumber } = useParams()
  const { isEditableAmMachine } = useUserRole()

  const {
    amMachineName,
    representativeHardItemCd,
    isAvailable,
    usageCategory,
    prizeSetting,
    materialSetting,
    note,
  } =
    useResource({
      subject: "AM機器詳細の取得",
      fetch:
        arcadeCd && amMachineNumber
          ? () => getAmMachine(arcadeCd, amMachineNumber)
          : undefined,
      recoilKey: `getAmMachine:${amMachineNumber}`,
    }).resource?.data.amMachine ?? {}
  const { numOfBooths, numOfAvailableBooths, machineName, meterCategory } =
    prizeSetting || {}
  const { numOfCylinders, isGeneric } = materialSetting || {}

  const machinesReturn = useResource({
    subject: "機種一覧の取得",
    fetch: () => getMachines(),
    recoilKey: `getMachines`,
  }).resource
  const machines = machinesReturn?.data.machines || []

  const validationSchema = Yup.object({
    usageCategory:
      Yup.string<PatchAmMachineRequestUsageCategoryEnum>().required(
        "利用区分を選択してください",
      ),
    numOfBooths: Yup.number().when("usageCategory", {
      is: AmMachineUsageCategoryEnum.Prize,
      then: (schema) =>
        schema
          .transform(toNumber)
          .required("ブース数は空欄では登録できません")
          .test(
            "number-cast-possible",
            "数値を入力してください",
            (value) => !isNaN(Number(value)),
          )
          .min(1, "ブース数は1個以上にして下さい")
          .max(12, "上限個数を超えているため変更できません(上限：12個)")
          .test(
            "should-not-be-less-than-current-count",
            "ブース数は既存の個数よりも少ない数を登録することは出来ません。ブースを削除したい場合は、該当のブースの詳細ページから無効化を行ってください。",
            (value) =>
              value && numOfBooths ? Number(value) >= numOfBooths : true,
          ),
      otherwise: (schema) => schema.transform(toNumber).notRequired(),
    }),
    prizeMachineName: Yup.string().when("usageCategory", {
      is: AmMachineUsageCategoryEnum.Prize,
      then: (schema) => schema.required("機種は空欄では登録できません"),
      otherwise: (schema) => schema.notRequired(),
    }),
    prizeMeterCategory:
      Yup.string<PatchAmMachineRequestPrizeMeterCategoryEnum>().when(
        "usageCategory",
        {
          is: AmMachineUsageCategoryEnum.Prize,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.notRequired(),
        },
      ),
    numOfCylinders: Yup.number().when("usageCategory", {
      is: AmMachineUsageCategoryEnum.Material,
      then: (schema) =>
        schema
          .transform(toNumber)
          .required("シリンダー個数は空欄では登録できません")
          .min(1, "シリンダー個数は1個以上にして下さい")
          .max(30, "上限個数を超えているため変更できません(上限：30個)")
          .test(
            "should-not-be-less-than-current-count",
            "シリンダー個数は既存の個数よりも少ない数を登録することは出来ません。シリンダーを削除したい場合は、該当の材料機械の個別詳細ページから削除を行ってください。",
            (value) =>
              value && numOfCylinders ? Number(value) >= numOfCylinders : true,
          ),
      otherwise: (schema) => schema.transform(toNumber).notRequired(),
    }),
    note: Yup.string().max(
      100,
      "文字数上限を超えているため変更できません(上限：全角100文字)",
    ),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({
    defaultValues: {
      usageCategory,
      numOfBooths,
      prizeMachineName: machineName,
      prizeMeterCategory: meterCategory,
      numOfCylinders,
      note,
    },
    resolver: yupResolver<FormType>(validationSchema),
  })

  const value = {
    selectedAmMachineUsageCategory: useWatch({
      control,
      name: "usageCategory",
    }),
    currentNumOfBooths: useWatch({
      control,
      name: "numOfBooths",
    }),
    selectedPrizeMachineName: useWatch({
      control,
      name: "prizeMachineName",
    }),
  }
  const availableBooths =
    (value.currentNumOfBooths || 0) -
    ((numOfBooths || 0) - (numOfAvailableBooths || 0))
  const warningMachineChanged =
    machineName &&
    value.selectedPrizeMachineName &&
    value.selectedPrizeMachineName !== machineName

  const onUsageCategoryChanged = (usageCategory: number | string | null) => {
    // 利用区分が変更されると全て初期化する
    setValue("numOfBooths", numOfBooths)
    setValue("prizeMachineName", machineName)
    setValue("prizeMeterCategory", meterCategory)
    setValue("numOfCylinders", numOfCylinders)
    setValue(
      "usageCategory",
      usageCategory === null
        ? AmMachineUsageCategoryEnum.Other
        : (usageCategory as AmMachineUsageCategoryEnum),
    )
  }

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    usageCategory,
    numOfBooths,
    numOfCylinders,
    prizeMachineName,
    prizeMeterCategory,
    note,
  }) => {
    if (!arcadeCd || !amMachineNumber || !isEditableAmMachine) return
    const request: PatchAmMachineRequest = {
      usageCategory,
      numOfBooths: numOfBooths ? Number(numOfBooths) : undefined,
      numOfCylinders: numOfCylinders ? Number(numOfCylinders) : undefined,
      prizeMachineName,
      prizeMeterCategory,
      note: note ?? "",
    }
    await submitPromises([
      {
        subject: "AM機器詳細の変更",
        showSuccessMessage: true,
        promise: async () => {
          await patchAmMachine(arcadeCd, amMachineNumber, request)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Card sx={{ py: 2, px: 3 }}>
          <Typography sx={{ mb: 3 }} variant="h1">
            {amMachineNumber}
          </Typography>

          <Stack gap={3}>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                AM機器名称
              </Typography>
              <Typography>{amMachineName}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                設置部門CD
              </Typography>
              <Typography>{representativeHardItemCd}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                AM機器有効/無効フラグ
              </Typography>
              <Typography>{isAvailable ? "有効" : "無効"}</Typography>
            </Stack>
            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                利用区分
              </Typography>
              <Controller
                control={control}
                name={"usageCategory"}
                render={({ field, fieldState: { error } }) => (
                  <LabeledAutocomplete
                    {...field}
                    error={!!error}
                    items={[
                      {
                        label: "景品",
                        value: PatchAmMachineRequestUsageCategoryEnum.Prize,
                      },
                      {
                        label: "材料",
                        value: PatchAmMachineRequestUsageCategoryEnum.Material,
                      },
                      {
                        label: "その他",
                        value: PatchAmMachineRequestUsageCategoryEnum.Other,
                      },
                    ]}
                    inputLabel=""
                    onChange={(v) => onUsageCategoryChanged(v)}
                    disabled={isGeneric}
                  />
                )}
              />
              {errors.usageCategory?.message && (
                <FormHelperText error>
                  {errors.usageCategory.message}
                </FormHelperText>
              )}
            </Stack>

            {value.selectedAmMachineUsageCategory ===
              AmMachineUsageCategoryEnum.Prize && (
              <>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  gap={4}
                >
                  <Stack sx={{ flex: 1 }} gap={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      ブース数
                    </Typography>
                    <Controller
                      control={control}
                      name={"numOfBooths"}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          error={!!errors.numOfBooths}
                          {...field}
                          type="number"
                          disabled={
                            isGeneric ||
                            value.selectedAmMachineUsageCategory !==
                              AmMachineUsageCategoryEnum.Prize
                          }
                        />
                      )}
                    />
                    {errors.numOfBooths?.message && (
                      <FormHelperText error>
                        {errors.numOfBooths.message}
                      </FormHelperText>
                    )}
                  </Stack>
                  <Stack sx={{ flex: 1 }} gap={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      有効なブース数
                    </Typography>
                    <Stack sx={{ p: 1 }}>
                      <Typography>{availableBooths}</Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    機種
                  </Typography>
                  <Controller
                    control={control}
                    name={"prizeMachineName"}
                    render={({ field, fieldState }) => (
                      <SearchAutoComplete
                        items={machines.map((machine) => ({
                          label: machine.name,
                          value: machine.name,
                        }))}
                        {...field}
                        error={!!fieldState.error}
                      />
                    )}
                  />
                  {errors.prizeMachineName?.message && (
                    <FormHelperText error>
                      {errors.prizeMachineName.message}
                    </FormHelperText>
                  )}
                  {warningMachineChanged && (
                    <FormHelperText error>
                      機種名を変更するとブース名も変更されます
                    </FormHelperText>
                  )}
                </Stack>
                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    メーター入力方法
                  </Typography>
                  <Controller
                    control={control}
                    name={"prizeMeterCategory"}
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem
                          value={
                            PatchAmMachineRequestPrizeMeterCategoryEnum.Analog
                          }
                        >
                          アナログ
                        </MenuItem>
                        <MenuItem
                          value={
                            PatchAmMachineRequestPrizeMeterCategoryEnum.Soft
                          }
                        >
                          ソフト
                        </MenuItem>
                      </Select>
                    )}
                  />
                  {errors.prizeMeterCategory?.message && (
                    <FormHelperText error>
                      {errors.prizeMeterCategory.message}
                    </FormHelperText>
                  )}
                </Stack>
              </>
            )}

            {value.selectedAmMachineUsageCategory ===
              AmMachineUsageCategoryEnum.Material && (
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  シリンダー個数
                </Typography>
                <Controller
                  control={control}
                  name={"numOfCylinders"}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      error={!!errors.numOfCylinders}
                      {...field}
                      type="number"
                      disabled={
                        isGeneric ||
                        value.selectedAmMachineUsageCategory !==
                          AmMachineUsageCategoryEnum.Material
                      }
                    />
                  )}
                />
                {errors.numOfCylinders?.message && (
                  <FormHelperText>
                    {errors.numOfCylinders.message}
                  </FormHelperText>
                )}
              </Stack>
            )}

            <Stack gap={1}>
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.gray[50] })}
              >
                備考
              </Typography>
              <Controller
                control={control}
                name={"note"}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    error={!!errors.note}
                    {...field}
                    disabled={isGeneric}
                  />
                )}
              />
              {errors.note?.message && (
                <FormHelperText>{errors.note.message}</FormHelperText>
              )}
            </Stack>
          </Stack>
        </Card>

        <Stack gap={3}>
          {!isGeneric && (
            <Stack>
              <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isEditableAmMachine}
                loading={isSubmitting}
              >
                変更した内容を反映させる
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
