import Cookies from "js-cookie"

// Local storage
type LocalStorageKey = "healthCheck" | "appVersion" | "printPageSize"

export const getLocalStorageItem = (key: LocalStorageKey) =>
  localStorage.getItem(key)

export const setLocalStorageItem = (key: LocalStorageKey, value: string) =>
  localStorage.setItem(key, value)

export const removeLocalStorageItem = (key: LocalStorageKey) =>
  localStorage.removeItem(key)

// Session storage
type SessionStorageKey = "healthCheck"

export const getSessionStorageItem = (key: SessionStorageKey) =>
  sessionStorage.getItem(key)

export const setSessionStorageItem = (key: SessionStorageKey, value: string) =>
  sessionStorage.setItem(key, value)

export const removeSessionStorageItem = (key: SessionStorageKey) =>
  sessionStorage.removeItem(key)

// Cookie
type CookieKey = "healthCheck"

export const getCookieItem = (key: CookieKey) => Cookies.get(key)

export const setCookieItem = (key: CookieKey, value: string) =>
  Cookies.set(key, value)

export const removeCookieItem = (key: CookieKey) => Cookies.remove(key)
