import { useMemo, useState } from "react"

import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  Accordion,
  AccordionDetails,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { getArcade } from "src/api/arcades"
import { getInventoryCsvExport } from "src/api/inventory-csv"
import {
  PrizeInventoryDifference,
  PrizeInventoryDifferenceGroupEnum,
} from "src/api/models"
import { getPrizeInventoryDifference } from "src/api/prize-inventory-differences"
import {
  postPrizeInventoryExecutionPeriodEnd,
  postPrizeInventoryExecutionPeriodEndKidsMarket,
  postPrizeInventoryExecutionPeriodSkip,
} from "src/api/prize-inventory-execution-period"
import { getPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import { getSettings } from "src/api/settings"
import { AccordionSummaryWithButton } from "src/components/atoms/AccordionSummaryWithButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeExecutionPeriodEndModal } from "src/components/organisms/prizes/InventoryPrizeExecutionPeriodEndModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  isPrizePeriodEndedToday,
  findCurrentFeatureExecutionPeriod,
} from "src/domains/inventoryExecutionPeriodRepository"
import {
  prizeDifferenceTableHeaders,
  getPrizeDifferenceLabel,
  placementTypeNames,
} from "src/domains/prizes/inventoryExecuteRepository"
import {
  getNotExecutedPlacements,
  PlacementTypeWithAll,
} from "src/domains/prizes/placementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentArcadeState,
  currentInventoryPeriodState,
  inventoryPeriodsState,
  snackbarErrorMessageState,
} from "src/recoil"

export const InventoryPrizeConfirmation = () => {
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const isPeriodEndedToday =
    inventoryPeriods && isPrizePeriodEndedToday(inventoryPeriods)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  if (currentPrizeExecutionPeriod) {
    return (
      <MainContentLayout
        title="景品棚卸確定"
        caption="現在の景品棚卸期間を確定"
        renderContent={() => (
          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {isPeriodEndedToday ? (
                <Grid item xs={12}>
                  <Typography variant="caption" color="success.main">
                    景品棚卸確定処理済です。結果出力を行った翌日から次の棚卸期間が開始します。
                  </Typography>
                </Grid>
              ) : (
                <InventoryPrizeConfirmationMenu />
              )}
            </Grid>
          </Card>
        )}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="景品棚卸確定"
      period={currentInventoryPeriod}
      featurePeriod={currentPrizeExecutionPeriod}
    />
  )
}

const InventoryPrizeConfirmationMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )
  const [currentArcade, setCurrentArcade] = useRecoilState(currentArcadeState)
  const {
    hasKidsMarket: arcadeHasKidsMarket,
    isSkipExecutionEnabled: arcadeSkipEnabled,
  } = currentArcade || {}

  const { resource: settingsResource } = useResource({
    subject: "全店舗棚卸カウント無し状態の取得",
    fetch: getSettings,
    recoilKey: `getSettings`,
  })
  const isSettingSkippable = settingsResource?.data.isExecutionSkippable

  const { resource } = useResource({
    subject: "取り込み済の循環棚卸オーダーの取得",
    hideErrorMessage: true,
    fetch:
      arcadeCd && currentInventoryPeriod
        ? () => getInventoryCsvExport(arcadeCd, currentInventoryPeriod.id)
        : undefined,
    recoilKey: `getInventoryCsvExport:${arcadeCd}:${JSON.stringify(
      currentInventoryPeriod,
    )}`,
  })
  const exportedCsv = resource?.data.csv

  const { resource: statusesResource } = useResource({
    subject: "棚卸実施状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? async () => {
            return await getPrizePlacementStatuses(
              arcadeCd,
              PlacementTypeWithAll.All,
              currentPrizeExecutionPeriod.id,
            )
          }
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      PlacementTypeWithAll.All
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })
  const placementStatusRes = statusesResource?.data
  const notExecutedPlacements = useMemo(
    () =>
      placementStatusRes
        ? getNotExecutedPlacements(placementStatusRes).filter(
            (p) => p.placementName !== "荷捌き",
          )
        : [],
    [placementStatusRes],
  )
  const kmNotExecutedPlacements = notExecutedPlacements.filter(
    (placement) => placement.isKidsMarket,
  )

  const { resource: differencesResource } = useResource({
    subject: "差分情報の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? async () => {
            return await getPrizeInventoryDifference(
              arcadeCd,
              currentPrizeExecutionPeriod.id,
            )
          }
        : undefined,
    recoilKey: `getInventoryDifference:${arcadeCd}:${JSON.stringify(
      currentPrizeExecutionPeriod,
    )}`,
  })

  const differencesRes = differencesResource?.data
  const differences = useMemo(
    () => differencesRes?.differences || [],
    [differencesRes],
  )
  const { NotInGigoNavi, DiffPriceOver } = PrizeInventoryDifferenceGroupEnum
  const differencesNotInGigoNavi = differences.filter(
    (difference) => difference.group === NotInGigoNavi,
  )
  const differencesNotApproved = differences.filter(
    (difference) =>
      difference.group === DiffPriceOver && !difference.isApproved,
  )
  const kmDifferencesNotInGigoNavi = differencesNotInGigoNavi.filter(
    (difference) => difference.isKidsMarket,
  )
  const kmDifferencesNotApproved = differencesNotApproved.filter(
    (difference) => difference.isKidsMarket,
  )

  const { isAvailableInventoryExecutionPeriodEnd } = useUserRole()
  // 棚卸確定が可能か判定
  // 1. (荷捌きを除く)全ての場所の実査が完了している
  // 2. 差分確認
  //  - GiGONAVI に存在しない差分がない
  //  - 戻しが10万円を超える差分が全て承認済み
  const isEndableExecution =
    isAvailableInventoryExecutionPeriodEnd &&
    exportedCsv &&
    notExecutedPlacements.length === 0 &&
    differencesNotInGigoNavi.length === 0 &&
    differencesNotApproved.length === 0
  // KM景品のみの棚卸確定が可能か判定
  const isEndableKidsMarket =
    isAvailableInventoryExecutionPeriodEnd &&
    exportedCsv &&
    kmNotExecutedPlacements.length === 0 &&
    kmDifferencesNotInGigoNavi.length === 0 &&
    kmDifferencesNotApproved.length === 0

  const [showModal, setShowModal] = useState(false)
  const [showSkipModal, setShowSkipModal] = useState(false)
  const [showKidsMarketModal, setShowKidsMarketModal] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = async () => {
    if (!arcadeCd) return
    if (!isAvailableInventoryExecutionPeriodEnd) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!currentInventoryPeriod) {
      return setErrorMessage("現在の棚卸期間が取得できませんでした")
    }
    submitPromises([
      {
        subject: "棚卸確定処理",
        showSuccessMessage: true,
        promise: async () => {
          await postPrizeInventoryExecutionPeriodEnd(arcadeCd)

          const {
            data: { arcade },
          } = await getArcade(arcadeCd)
          setCurrentArcade(arcade)
          setShowSkipModal(false)
          setShowModal(false)
        },
      },
    ])
  }
  const onSubmitSkip = async () => {
    if (!arcadeCd) return
    if (!isAvailableInventoryExecutionPeriodEnd) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!currentInventoryPeriod) {
      return setErrorMessage("現在の棚卸期間が取得できませんでした")
    }
    submitPromises([
      {
        subject: "棚卸カウント無し処理",
        showSuccessMessage: true,
        promise: async () => {
          await postPrizeInventoryExecutionPeriodSkip(arcadeCd)

          const {
            data: { arcade },
          } = await getArcade(arcadeCd)
          setCurrentArcade(arcade)
          setShowSkipModal(false)
          setShowModal(false)
        },
      },
    ])
  }
  const onSubmitKidsMarket = async () => {
    if (!arcadeCd) return
    if (!isAvailableInventoryExecutionPeriodEnd) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!currentInventoryPeriod) {
      return setErrorMessage("現在の棚卸期間が取得できませんでした")
    }
    submitPromises([
      {
        subject: "KM景品のみ棚卸確定処理",
        showSuccessMessage: true,
        promise: async () => {
          await postPrizeInventoryExecutionPeriodEndKidsMarket(arcadeCd)

          const {
            data: { arcade },
          } = await getArcade(arcadeCd)
          setCurrentArcade(arcade)
          setShowSkipModal(false)
          setShowModal(false)
        },
      },
    ])
  }

  return (
    <>
      {!exportedCsv && (
        <Grid item xs={12} data-testid="exportedCsv-section">
          <Card sx={{ px: 2, py: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="error.main">
                循環棚卸オーダーが取り込まれていません。
              </Typography>
              <Button
                variant="contained"
                sx={{ px: 1, py: 0.5, whiteSpace: "nowrap" }}
                onClick={() =>
                  navigate(`/arcades/${arcadeCd}/inventory/seams/import`)
                }
              >
                ページに移動
              </Button>
            </Stack>
          </Card>
        </Grid>
      )}
      {notExecutedPlacements.length > 0 && (
        <Grid item xs={12} data-testid="notExecutedPlacements-section">
          <Accordion>
            <AccordionSummaryWithButton
              label="対象をみる"
              labelExpanded="　閉じる　"
            >
              <Typography variant="caption" color="error.main">
                棚卸が完了していない棚・ブースがあります。
                <br />
                （荷捌きは棚卸未完了でも確定可能）
              </Typography>
            </AccordionSummaryWithButton>

            <AccordionDetails>
              <PaginatedTable
                scrollableX
                noMargin
                items={notExecutedPlacements}
                stateKey="inventoryConfirmationNotExecutedPlacements"
                header={
                  <TableHead>
                    <TableRow sx={{ th: { px: 1, whiteSpace: "nowrap" } }}>
                      <ExtTableCell>
                        プライズ機
                        <br />
                        外／内／上
                      </ExtTableCell>
                      <ExtTableCell>棚・ブース名</ExtTableCell>
                      {arcadeHasKidsMarket && (
                        <ExtTableCell>棚の種別</ExtTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                }
                renderRow={({ placementType, placementName, isKidsMarket }) => {
                  return (
                    <TableBorderedRow
                      sx={{
                        td: {
                          p: 1,
                          whiteSpace: "nowrap",
                        },
                      }}
                      key={`${placementType}-${placementName}`}
                      data-testid={`${placementType}-${placementName}`}
                    >
                      <ExtTableCell>
                        {placementTypeNames[placementType]}
                      </ExtTableCell>
                      <ExtTableCell>{placementName}</ExtTableCell>
                      {arcadeHasKidsMarket && (
                        <ExtTableCell>
                          {isKidsMarket ? "KM景品専用" : ""}
                        </ExtTableCell>
                      )}
                    </TableBorderedRow>
                  )
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {differencesNotInGigoNavi.length > 0 && (
        <Grid item xs={12} data-testid="differencesNotInGigoNavi-section">
          <Accordion>
            <AccordionSummaryWithButton
              label="対象をみる"
              labelExpanded="　閉じる　"
            >
              <Typography variant="caption" color="error.main">
                循環棚卸オーダーの景品でGiGO
                NAVIに登録されておらず、棚卸が確認できないものがあります。
              </Typography>
            </AccordionSummaryWithButton>

            <AccordionDetails>
              <InventoryPrizeConfirmationDifferencesTable
                differences={differencesNotInGigoNavi}
                stateKey="inventoryConfirmationDifferencesNotInGigoNavi"
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {differencesNotApproved.length > 0 && (
        <Grid item xs={12} data-testid="differencesNotApproved-section">
          <Card sx={{ px: 2 }}>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption" color="error.main">
                承認が完了していない「戻しが10万円を超える項目」があります。
              </Typography>
              <Button
                variant="contained"
                color="error"
                sx={{ my: 1 }}
                onClick={() =>
                  navigate(`/arcades/${arcadeCd}/inventory/prizes/differences`)
                }
              >
                対象画面に遷移
              </Button>
            </Stack>
          </Card>
        </Grid>
      )}
      {isEndableExecution && (
        <Grid item xs={12} data-testid="inventoryCompleted-section">
          <Typography variant="caption" color="primary.main">
            全ての棚・ブースの棚卸が完了しています。
            <br />
            （荷捌きは棚卸未完了でも確定可能）
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {!arcadeHasKidsMarket && arcadeSkipEnabled && isSettingSkippable && (
            <Stack flex={1}>
              <Button
                variant="outlined"
                sx={{ background: "white" }}
                fullWidth
                onClick={() => setShowSkipModal(true)}
              >
                棚卸カウント無し処理
              </Button>
            </Stack>
          )}
          {arcadeHasKidsMarket && arcadeSkipEnabled && isSettingSkippable && (
            <Stack flex={1}>
              <Button
                variant="outlined"
                sx={{ background: "white" }}
                fullWidth
                onClick={() => setShowKidsMarketModal(true)}
                disabled={!isEndableKidsMarket}
              >
                KM景品の棚卸確定
              </Button>
            </Stack>
          )}
          <Stack flex={1}>
            <Button
              variant="outlined"
              sx={{ background: "white" }}
              fullWidth
              onClick={() => setShowModal(true)}
              disabled={!isEndableExecution}
            >
              景品の棚卸確定
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <InventoryPrizeExecutionPeriodEndModal
        showModal={showSkipModal}
        onSubmit={onSubmitSkip}
        onClose={() => setShowSkipModal(false)}
        isSubmitting={isSubmitting}
        totalInventoryPrices={differencesRes?.totalInventoryPrices}
        msgType="skip"
      />
      <InventoryPrizeExecutionPeriodEndModal
        showModal={showKidsMarketModal}
        onSubmit={onSubmitKidsMarket}
        onClose={() => setShowKidsMarketModal(false)}
        isSubmitting={isSubmitting}
        totalInventoryPrices={differencesRes?.totalInventoryPrices}
        msgType="endKidsMarket"
      />
      <InventoryPrizeExecutionPeriodEndModal
        showModal={showModal}
        onSubmit={onSubmit}
        onClose={() => setShowModal(false)}
        isSubmitting={isSubmitting}
        totalInventoryPrices={differencesRes?.totalInventoryPrices}
        msgType="end"
      />
    </>
  )
}

interface InventoryConfirmationDifferencesTableProps {
  differences: PrizeInventoryDifference[]
  stateKey: string
}

export const InventoryPrizeConfirmationDifferencesTable: React.FC<
  InventoryConfirmationDifferencesTableProps
> = ({ differences, stateKey }: InventoryConfirmationDifferencesTableProps) => {
  const prizeNameGradation =
    "-webkit-gradient(linear, 95% 0%, 98% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))"
  const getLines = (itemName: string[]) =>
    itemName.map((line, i) => <p key={`${i}-${line}`}>{line}</p>)

  return (
    <PaginatedTable
      scrollableX
      noMargin
      items={differences}
      stateKey={stateKey}
      header={
        <TableHead>
          <TableRow
            sx={{ th: { px: 1, whiteSpace: "nowrap", textAlign: "center" } }}
          >
            <ExtTableCell>
              {getLines(prizeDifferenceTableHeaders.prizeCd.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(prizeDifferenceTableHeaders.prizeCdsInSeams.label)}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "start !important" }}>
              {getLines(prizeDifferenceTableHeaders.prizeName.label)}
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(difference) => {
        const item = getPrizeDifferenceLabel(difference)
        return (
          <TableBorderedRow
            sx={{
              td: {
                p: 1,
                textAlign: "center",
              },
            }}
            key={`${difference.prizeCd}-${difference.prizeCdsInSeams.join(
              "/",
            )}`}
            data-testid={`${
              difference.prizeCd
            }-${difference.prizeCdsInSeams.join("/")}`}
          >
            <ExtTableCell>{item.prizeCd}</ExtTableCell>
            <ExtTableCell>
              {item.prizeCdsInSeams.map((prizeCdInSeams) => (
                <Box key={prizeCdInSeams}>{prizeCdInSeams}</Box>
              ))}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                textAlign: "start !important",
                whiteSpace: "nowrap",
                maxWidth: 400,
                WebkitMaskImage: prizeNameGradation,
              }}
            >
              <CardItemNameBox>{item.prizeName}</CardItemNameBox>
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
