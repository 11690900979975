/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type RequestPrizeOperationMoveBoothPlacementEnum =
  (typeof RequestPrizeOperationMoveBoothPlacementEnum)[keyof typeof RequestPrizeOperationMoveBoothPlacementEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestPrizeOperationMoveBoothPlacementEnum = {
  OnBooth: "on_booth",
  InBooth: "in_booth",
} as const
