import { getPrizeFloorMemoColors } from "src/api/prize-floor-memo-colors"
import { useResource } from "src/hooks/useResource"

export const useFloorMapColors = () => {
  const floorMapColors =
    useResource({
      subject: "フロアマップ色の取得",
      fetch: () => getPrizeFloorMemoColors(),
      recoilKey: "getFloorMemoColors",
      useCache: true,
      // フロアメモ色先頭の白は、フロアマップ色からは除外
    }).resource?.data.floorMemoColors.slice(1) || []

  return { floorMapColors }
}
