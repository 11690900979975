import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  startTransition,
} from "react"

import { Box, Link, Switch, TableHead, TableRow } from "@mui/material"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { getArcades, patchArcade } from "src/api/arcades"
import { Arcade } from "src/api/models"
import { getMe } from "src/api/users"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { BookmarkIconButton } from "src/components/molecules/BookmarkIconButton"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { SearchBarForm } from "src/components/organisms/SearchBarForm"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { isMaterialEnabled } from "src/domains/arcadeRepository"
import { useBookmarkedArcades } from "src/hooks/useBookmarkedArcades"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { meState } from "src/recoil"
import { theme } from "src/theme"

export const ArcadeSearch: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="店舗検索"
      caption="空欄で検索すると、閲覧可能な全店舗が表示されます"
      renderFilter={() => (
        <SearchBarForm
          placeholder="店舗名"
          onSearch={(value) => startTransition(() => setSearchValue(value))}
          showDeleteButton
        />
      )}
      renderContent={() => <ArcadeSearchTable searchValue={searchValue} />}
      onClickBackButton={() => navigate("/my-page")}
    />
  )
}

const ArcadeSearchTable: React.FC<{ searchValue?: string }> = ({
  searchValue,
}) => {
  const setMe = useSetRecoilState(meState)
  const { isEditableArcadeInventorySkip } = useUserRole()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const {
    bookmarkedArcades,
    callPutBookmarkedArcades,
    callDeleteBookmarkedArcades,
  } = useBookmarkedArcades({ doUpdateMe: true })
  const bookmarkedArcadeCds = useMemo(
    () => (bookmarkedArcades || []).map((arcade) => arcade.arcadeCd),
    [bookmarkedArcades],
  )

  const { resource: arcadesResource, refetch: arcadesRefetch } = useResource({
    subject: "店舗一覧の取得",
    fetch: () => getArcades({ search: searchValue }),
    recoilKey: `getArcades:${searchValue}`,
  })
  const arcades = arcadesResource?.data.arcades

  const { resource: meResource } = useResource({
    subject: "お気に入り店舗の取得",
    fetch: getMe,
    recoilKey: `getMe`,
  })
  const me = meResource?.data
  useEffect(() => {
    if (me) {
      setMe(me)
    }
  }, [me, setMe])

  const onClickSkipSwitch = useCallback(
    async (arcade: Arcade) => {
      await submitPromises([
        {
          subject: "店舗の棚卸カウント無し設定",
          showSuccessMessage: true,
          promise: async () => {
            await patchArcade(arcade.arcadeCd, {
              isSkipExecutionEnabled: !arcade.isSkipExecutionEnabled,
              hasKidsMarket: arcade.hasKidsMarket,
            })
            await arcadesRefetch()
          },
        },
      ])
    },
    [arcadesRefetch, submitPromises],
  )

  const onClickKMSwitch = useCallback(
    async (arcade: Arcade) => {
      await submitPromises([
        {
          subject: "店舗のKM景品取り扱い設定",
          showSuccessMessage: true,
          promise: async () => {
            await patchArcade(arcade.arcadeCd, {
              isSkipExecutionEnabled: arcade.isSkipExecutionEnabled,
              hasKidsMarket: !arcade.hasKidsMarket,
            })
            await arcadesRefetch()
          },
        },
      ])
    },
    [arcadesRefetch, submitPromises],
  )

  return (
    <PaginatedTable
      items={arcades ?? []}
      noMargin
      header={
        <TableHead>
          <TableRow sx={{ th: { whiteSpace: "nowrap" } }}>
            <ExtTableCell>店舗名</ExtTableCell>
            <ExtTableCell align="center">棚卸カウント無し</ExtTableCell>
            <ExtTableCell align="center">KM景品取扱</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(arcade) => (
        <TableBorderedRow hover key={arcade.arcadeCd}>
          <ExtTableCell sx={{ p: 0 }}>
            <Link
              to={`/arcades/${arcade.arcadeCd}`}
              component={RouterLink}
              underline="none"
              sx={{
                color: theme.palette.neutral[900],
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 2,
              }}
            >
              <Box onClick={(e) => e.preventDefault()}>
                <BookmarkIconButton
                  activeByDefault={bookmarkedArcadeCds.some(
                    (arcadeCd) => arcadeCd === arcade.arcadeCd,
                  )}
                  onAdd={() => {
                    callPutBookmarkedArcades(arcade.arcadeCd)
                  }}
                  onRemove={() => callDeleteBookmarkedArcades(arcade.arcadeCd)}
                />
              </Box>
              {arcade.name}
            </Link>
          </ExtTableCell>
          <ExtTableCell align="center">
            {!arcade.abolitionFlag && isMaterialEnabled(arcade) && (
              <Switch
                onClick={() => onClickSkipSwitch(arcade)}
                checked={arcade.isSkipExecutionEnabled}
                disabled={!isEditableArcadeInventorySkip || isSubmitting}
              />
            )}
          </ExtTableCell>
          <ExtTableCell align="center">
            {!arcade.abolitionFlag && (
              <Switch
                onClick={() => onClickKMSwitch(arcade)}
                checked={arcade.hasKidsMarket}
                disabled={isSubmitting}
              />
            )}
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
