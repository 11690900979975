import { useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Divider,
  Card,
  Stack,
} from "@mui/material"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { putMaterialOperationIn } from "src/api/material-operation-in"
import {
  Material,
  MaterialArrival,
  MaterialArrivalElement,
  PutMaterialOperationInRequest,
} from "src/api/models"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { DatePicker } from "src/components/organisms/DatePicker"
import { getArrivalCount } from "src/domains/materials/arrivalRepository"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getJpDateLabel, getToday } from "src/utils"

interface InventoryMaterialAcceptSingleFormInput {
  cartonsCount: number
  separatedCount: number
  note?: string
}

export interface MaterialAcceptResult
  extends Omit<MaterialArrival, "id" | "inHistoryStock" | "orderCount"> {
  material: Material
  orderCount: number | undefined
  stock: number
  cartonsCount: number
  separatedCount: number
  note: string
}

interface InventoryMaterialAcceptSingleFormProps {
  arrival?: MaterialArrivalElement
  material: Material
  onAccepted: (results: MaterialAcceptResult[]) => void
}

export const InventoryMaterialAcceptSingleForm: React.FC<
  InventoryMaterialAcceptSingleFormProps
> = ({
  arrival,
  material,
  onAccepted,
}: InventoryMaterialAcceptSingleFormProps) => {
  const { arcadeCd } = useParams()
  const { unitPerCarton } = material

  const acceptDate = getToday()

  const validationSchema = Yup.object({
    cartonsCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
    separatedCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = useForm<InventoryMaterialAcceptSingleFormInput>({
    resolver:
      yupResolver<InventoryMaterialAcceptSingleFormInput>(validationSchema),
    defaultValues: {
      cartonsCount: 0,
      separatedCount: arrival ? getArrivalCount(arrival) : 0,
      note: "",
    },
  })
  const value = {
    cartonsCount: Number(useWatch({ control, name: "cartonsCount" })) || 0,
    separatedCount: Number(useWatch({ control, name: "separatedCount" })) || 0,
  }
  const stock = value.cartonsCount * unitPerCarton + value.separatedCount

  // NOTE: 着荷予定選択を変更時に値をリセットする処理
  useEffect(() => {
    setValue("cartonsCount", 0)
    setValue("separatedCount", arrival ? getArrivalCount(arrival) : 0)
  }, [arrival, setValue])

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<
    InventoryMaterialAcceptSingleFormInput
  > = async (data) => {
    if (!arcadeCd) return
    const request: PutMaterialOperationInRequest = {
      materials: [
        {
          materialCd: material.materialCd,
          stock,
          materialOrderId: arrival?.arrival.id,
          note: data.note ?? "",
        },
      ],
    }

    await submitPromises([
      {
        subject: "検収",
        showSuccessMessage: true,
        promise: async () => {
          await putMaterialOperationIn(arcadeCd, acceptDate, request)
          // TODO: putMaterialOperationIn のレスポンスから生成する (要API実装)
          onAccepted([
            {
              orderCount: arrival?.arrival.orderCount,
              accepted: arrival?.arrival.accepted ?? false,
              material,
              willArriveAt: acceptDate,
              stock: stock,
              cartonsCount: data.cartonsCount,
              separatedCount: data.separatedCount,
              note: data.note ?? "",
            },
          ])
        },
      },
    ])
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ py: 2, px: 3, mb: 2 }}>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            rowSpacing={1}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h1">{material.materialName}</Typography>
            </Grid>

            {arrival?.arrival.orderCount && (
              <>
                <Grid item xs={8}>
                  着荷予定数
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                  <strong>{arrival?.arrival.orderCount}</strong> 個
                </Grid>
              </>
            )}
            {!arrival && (
              <>
                <Grid item xs={8}>
                  検収日
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                  <DatePicker />
                </Grid>
              </>
            )}
            {unitPerCarton > 0 && (
              <>
                <Grid item xs={8}>
                  カートン入数
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                  <strong>{unitPerCarton}</strong> 個
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>

            <Grid item xs={8}>
              着荷数
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              合計 <strong>{stock}</strong> 個
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>

            {unitPerCarton > 0 && (
              <>
                <Grid item xs={8}>
                  箱
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    error={"cartonsCount" in errors}
                    helperText={errors.cartonsCount?.message}
                    {...register("cartonsCount")}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "right" }}>
                  CT
                </Grid>
              </>
            )}

            <Grid item xs={8}>
              バラ
            </Grid>
            <Grid item xs={3}>
              <TextField
                error={"separatedCount" in errors}
                helperText={errors.separatedCount?.message}
                {...register("separatedCount")}
                inputProps={{ inputMode: "numeric" }}
              />
            </Grid>
            <Grid item xs={1} sx={{ textAlign: "right" }}>
              個
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <TextField
                label="備考"
                fullWidth
                error={"note" in errors}
                helperText={errors.note?.message}
                {...register("note")}
              />
            </Grid>
          </Grid>
        </Card>

        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          fullWidth
        >
          確定する
        </Button>
      </form>
    </Box>
  )
}

export interface InventoryMaterialAcceptSingleConfirmProps {
  result: MaterialAcceptResult
}

export const InventoryMaterialAcceptSingleConfirm: React.FC<
  InventoryMaterialAcceptSingleConfirmProps
> = ({ result }: InventoryMaterialAcceptSingleConfirmProps) => {
  const { orderCount, material } = result || {}
  const { unitPerCarton } = material

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack sx={{ mb: 2 }}>
        <AlertCaptionCard label="下記の通り検収しました" />
      </Stack>

      <Card sx={{ p: 2 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Typography variant="h1">{material.materialName}</Typography>{" "}
          </Grid>

          {orderCount && (
            <>
              <Grid item xs={8}>
                着荷予定数
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <strong>{orderCount}</strong> 個
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            検収日
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <strong>{getJpDateLabel(result.willArriveAt)}</strong>
          </Grid>

          {unitPerCarton > 0 && (
            <>
              <Grid item xs={8}>
                カートン入数
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <strong>{unitPerCarton}</strong> 個
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid item xs={8}>
            着荷数
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            合計 <strong>{result.stock}</strong> 個
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          {unitPerCarton > 0 && (
            <>
              <Grid item xs={8}>
                箱
              </Grid>
              <Grid item xs={4} sx={{ textAlign: "right" }}>
                <strong>{result.cartonsCount}</strong> CT
              </Grid>
            </>
          )}

          <Grid item xs={8}>
            バラ
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{result.separatedCount}</strong> 個
          </Grid>

          {result.note !== "" && (
            <Grid item xs={12} sx={{ mt: 1 }}>
              {result.note}
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  )
}
