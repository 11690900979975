import { useMemo } from "react"

import { TableHead, TableRow, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialStockLedgerMaterials } from "src/api/material-stock-ledger"
import { Material } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { InventoryMaterialStockLedgerMaterialsFilter } from "src/components/organisms/materials/InventoryMaterialStockLedgerMaterialsFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortMaterialStockLedgerMaterials as sortStockLedgerMaterials } from "src/domains/materials/materialStockLedgerRepository"
import { useResource } from "src/hooks/useResource"
import { materialStockLedgerMaterialsSearchParamsState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export const InventoryMaterialStockLedgerMaterials: React.FC = () => {
  return (
    <MainContentLayout
      title="副帳票材料一覧"
      renderFilter={() => <InventoryMaterialStockLedgerMaterialsFilter />}
      renderContent={() => <InventoryMaterialStockLedgerMaterialsMenu />}
    />
  )
}

const InventoryMaterialStockLedgerMaterialsMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const searchParams = useRecoilValue(
    materialStockLedgerMaterialsSearchParamsState,
  )

  const { resource } = useResource({
    subject: "材料一覧の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialStockLedgerMaterials(arcadeCd, {
            materialCd: searchParams.materialCd,
            materialName: searchParams.materialName,
            materialNameKana: searchParams.materialNameKana,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getMaterialStockLedgerMaterials:${arcadeCd}::${JSON.stringify(searchParams)}`,
  })

  const materials = useMemo(
    () =>
      sortStockLedgerMaterials(
        resource?.data.materials ?? [],
        searchParams.sortBy,
      ),
    [resource?.data.materials, searchParams],
  )

  return (
    <Stack sx={{ maxHeight: "calc(100dvh - 420px)" }}>
      <InventoryMaterialStockLedgerMaterialsTable
        materials={materials}
        onSelectedMaterialCd={(materialCd: string) =>
          navigate(
            `/arcades/${arcadeCd}/materials/ledger/materials/${materialCd}/placements`,
          )
        }
      />
    </Stack>
  )
}

interface InventoryMaterialStockLedgerMaterialsTableProps {
  materials: Material[]
  onSelectedMaterialCd: (materialCd: string) => void
}

const InventoryMaterialStockLedgerMaterialsTable: React.FC<
  InventoryMaterialStockLedgerMaterialsTableProps
> = ({
  materials,
  onSelectedMaterialCd,
}: InventoryMaterialStockLedgerMaterialsTableProps) => {
  const columnNames = ["材料CD", "材料名"]
  return (
    <PaginatedTable
      noMargin
      items={materials}
      scrollableY
      stickyHeader
      stateKey="inventoryMaterialStockLedgerMaterialsTable"
      header={
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <ExtTableCell key={columnName} sx={{ lineHeight: "18px" }}>
                {columnName}
              </ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(material) => (
        <TableBorderedRow
          key={material.materialCd}
          sx={{
            color: theme.palette.primary.main,
            "&:hover td": {
              cursor: "pointer",
              background: theme.palette.neutral[200],
            },
          }}
          onClick={() => onSelectedMaterialCd(material.materialCd)}
        >
          <ExtTableCell>
            <CardItemNameBox>{material.materialCd}</CardItemNameBox>
          </ExtTableCell>
          <ExtTableCell>
            <CardItemNameBox style={{ color: theme.palette.primary.main }}>
              {material.materialName}
            </CardItemNameBox>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
