import { LoadingButton } from "@mui/lab"
import { DialogContent, TextField, Typography } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { putMaterialStorages } from "src/api/material-storages"
import { MaterialStorage, PutMaterialStoragesRequest } from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { temporaryStorageName } from "src/domains/materials/materialOperationStocksRepository"
import { useSubmitting } from "src/hooks/useSubmitting"

type InventoryAddStorageFormInput = { name: string }

type InventoryAcceptModalProps = {
  showModal: boolean
  onClose: () => void
  onFinish?: () => void
  storages: MaterialStorage[]
}

export const InventoryMaterialAddStorageModal: React.FC<
  InventoryAcceptModalProps
> = ({ showModal, onClose, onFinish = () => undefined, storages }) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isValid },
  } = useForm<InventoryAddStorageFormInput>({ mode: "onChange" })
  const { arcadeCd } = useParams()
  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryAddStorageFormInput> = async ({
    name,
  }) => {
    if (!arcadeCd) return
    const request: PutMaterialStoragesRequest = {
      name,
    }

    await submitPromises([
      {
        subject: "保管場所の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putMaterialStorages(arcadeCd, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitleWithClose
            sx={{ textAlign: "center", pb: 0, mt: 1 }}
            variant="h2"
            onClose={onClose}
          >
            <Typography variant="h2">保管場所を追加</Typography>
          </DialogTitleWithClose>
          <DialogContent>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="保管場所名"
              {...register("name", {
                required: true,
                validate: (value) =>
                  value !== temporaryStorageName &&
                  storages.find((storage) => {
                    return storage.name === value
                  }) === undefined,
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </DialogContent>
          <CustomDialogActions>
            <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              fullWidth
            >
              保存
            </LoadingButton>
          </CustomDialogActions>
        </form>
      </CustomDialog>
    </>
  )
}
