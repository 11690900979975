import { useParams } from "react-router-dom"

import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { Placement } from "src/pages/prizes/placements/InventoryPrizePlacements"

const placementsCsvHeader = ["URL", "分類"]

export const useInventoryPlacementsCsv = () => {
  const { downloadCsv } = useDownloadCsv()
  const { arcadeCd } = useParams()
  const { origin } = window

  const downloadPlacementsCsv = (placements: Placement[]) => {
    const headerRow = { columns: placementsCsvHeader }

    const rows = placements.map((placement) => {
      const { placementType, storage, shelf, floorMapPoint } = placement
      const { Storage, InBooth, OnBooth } = PlacementType

      if (placementType === Storage) {
        return {
          columns: [
            `${origin}/arcades/${arcadeCd}/prizes/placements/storage/${storage?.id}/${shelf?.id}`,
            `外 ${storage?.name || ""} ${shelf?.name || ""}`,
          ],
        }
      }
      if (placementType === InBooth) {
        return {
          columns: [
            `${origin}/arcades/${arcadeCd}/prizes/placements/inBooth/${floorMapPoint?.id}`,
            `内 ${floorMapPoint?.name || ""}`,
          ],
        }
      }
      if (placementType === OnBooth) {
        return {
          columns: [
            `${origin}/arcades/${arcadeCd}/prizes/placements/onBooth/${floorMapPoint?.id}`,
            `上 ${floorMapPoint?.name || ""}`,
          ],
        }
      }
      return { columns: [] }
    })

    const csv = {
      headerRow,
      rows,
    }

    downloadCsv({ csv, fileName: `inventory_placement_urls_${arcadeCd}` })
  }

  return {
    downloadPlacementsCsv,
  }
}
