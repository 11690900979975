import { useCallback } from "react"

import { deleteMaterialOperationStocks } from "src/api/material-operation-stocks"
import { DeleteMaterialOperationStocksRequest } from "src/api/models"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useSubmitting } from "src/hooks/useSubmitting"

export const useDeleteMaterialOperationStocks = () => {
  const { submitPromises } = useSubmitting()
  const onDeleteMaterialOperationStocks = useCallback(
    ({
      arcadeCd,
      placementStockId,
      placement,
      onFinish,
    }: {
      arcadeCd: string
      placementStockId: number
      placement: MaterialPlacementType
      onFinish: () => void
    }) => {
      if (!arcadeCd) return
      const request: DeleteMaterialOperationStocksRequest = {
        placement,
        placementStockId,
      }

      submitPromises([
        {
          subject: "在庫の削除",
          showSuccessMessage: true,
          promise: async () => {
            await deleteMaterialOperationStocks(arcadeCd, request)
            return onFinish()
          },
        },
      ])
    },
    [submitPromises],
  )

  return {
    onDeleteMaterialOperationStocks,
  }
}
