/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeBoothResponse,
  GetPrizeBoothsResponse,
  PutPrizeBoothRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ブース一覧の取得
 */
export const getPrizeBooths = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeBoothsResponse>(
    { url: `/arcades/${arcadeCd}/prizes/booths`, method: "GET" },
    options,
  )
}
/**
 * @summary ブース詳細の取得
 */
export const getPrizeBooth = (
  arcadeCd: string,
  boothName: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeBoothResponse>(
    { url: `/arcades/${arcadeCd}/prizes/booths/${boothName}`, method: "GET" },
    options,
  )
}
/**
 * @summary ブース設定の変更
 */
export const putPrizeBooth = (
  arcadeCd: string,
  boothName: string,
  putPrizeBoothRequest: PutPrizeBoothRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/booths/${boothName}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeBoothRequest,
    },
    options,
  )
}
export type GetPrizeBoothsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeBooths>>
>
export type GetPrizeBoothResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeBooth>>
>
export type PutPrizeBoothResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeBooth>>
>
