import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Delete, Edit } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  DialogContent,
  TextField,
  Stack,
  IconButton,
  TableRow,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  UseFormReturn,
  useForm,
} from "react-hook-form"
import * as Yup from "yup"

import {
  deleteMachineCategory,
  getMachineCategories,
  putMachineCategory,
} from "src/api/machine-categories"
import { getMachines } from "src/api/machines"
import {
  Machine,
  MachineCategory,
  PutMachineCategoryRequest,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUserRole } from "src/hooks/useUserRole"

export const MachineCategories = () => {
  return (
    <MainContentLayout
      title="プライズ機種分類一覧"
      renderContent={() => <MachineCategoriesInner />}
    />
  )
}

const MachineCategoriesInner: React.FC = () => {
  const { isAdmin } = useUserRole()
  const { resource: machinesResource, refetch: refetchMachines } = useResource({
    subject: "機種リストの取得",
    fetch: () => getMachines(),
    recoilKey: `getMachines`,
  })
  const machines = useMemo(
    () => machinesResource?.data.machines || [],
    [machinesResource],
  )
  const {
    resource: machineCategoriesResource,
    refetch: refetchMachineCategories,
  } = useResource({
    subject: "機種分類リストの取得",
    fetch: () => getMachineCategories(),
    recoilKey: `getMachineCategories`,
  })
  const machineCategories = useMemo(
    () => machineCategoriesResource?.data.machineCategories || [],
    [machineCategoriesResource],
  )
  const refetch = () => {
    refetchMachines()
    refetchMachineCategories()
  }

  const [showAddModal, setShowAddModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedMachineCategory, setSelectedMachineCategory] = useState<
    MachineCategory | undefined
  >(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const onFinishModal = () => refetch()

  return (
    <>
      {isAdmin && (
        <Button
          variant="contained"
          sx={{ mr: 2, mb: 2, whiteSpace: "nowrap" }}
          startIcon={<Add />}
          onClick={() => setShowAddModal(true)}
        >
          追加
        </Button>
      )}

      <Stack sx={{ maxHeight: "80dvh" }}>
        <MachinesTable
          machineCategories={machineCategories}
          machines={machines}
          onClickEdit={(machineCategory: MachineCategory) => {
            setSelectedMachineCategory(machineCategory)
            setShowUpdateModal(true)
          }}
          onClickDelete={(machineCategory: MachineCategory) => {
            setSelectedMachineCategory(machineCategory)
            setShowDeleteModal(true)
          }}
        />
      </Stack>

      <MachineCategoryAddModal
        showModal={showAddModal}
        machineCategories={machineCategories}
        onClose={() => setShowAddModal(false)}
        onFinish={onFinishModal}
      />

      <MachineCategoryUpdateModal
        showModal={showUpdateModal}
        machineCategory={selectedMachineCategory}
        machineCategories={machineCategories}
        onClose={() => {
          setShowUpdateModal(false)
          setSelectedMachineCategory(undefined)
        }}
        onFinish={onFinishModal}
      />

      <MachineCategoryDeleteModal
        showModal={showDeleteModal}
        machineCategory={selectedMachineCategory}
        onClose={() => {
          setShowDeleteModal(false)
          setSelectedMachineCategory(undefined)
        }}
        onFinish={onFinishModal}
      />
    </>
  )
}

type MachinesTableProps = {
  machineCategories: MachineCategory[]
  machines: Machine[]
  onClickEdit: (machineCategory: MachineCategory) => void
  onClickDelete: (machineCategory: MachineCategory) => void
}

const MachinesTable: React.FC<MachinesTableProps> = ({
  machineCategories,
  machines,
  onClickEdit,
  onClickDelete,
}: MachinesTableProps) => {
  const { isAdmin } = useUserRole()
  return (
    <PaginatedTable
      noMargin
      scrollableY
      items={machineCategories}
      stateKey="machineCategories"
      renderRow={(machineCategory) => {
        const isEnableDelete = !machines.some(
          (machine) => machine.machineCategory.id === machineCategory.id,
        )
        return (
          <TableRow key={machineCategory.id} data-testid={machineCategory.id}>
            <ExtTableCell sx={{ p: 2 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle1">
                  {machineCategory.name}
                </Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <IconButton
                    color="primary"
                    disabled={!isAdmin}
                    onClick={() => onClickEdit(machineCategory)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="error"
                    disabled={!isEnableDelete || !isAdmin}
                    onClick={() => onClickDelete(machineCategory)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

type MachineCategoryFormInput = Omit<PutMachineCategoryRequest, "id">

type MachineCategoryAddModalProps = {
  showModal: boolean
  machineCategories: MachineCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const MachineCategoryAddModal: React.FC<
  MachineCategoryAddModalProps
> = ({ showModal, machineCategories, onClose, onFinish = () => undefined }) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) => !machineCategories.some((mc) => mc.name === value),
      ),
  })

  const useFormReturn = useForm<MachineCategoryFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  })
  const { reset } = useFormReturn

  const onSubmit: SubmitHandler<MachineCategoryFormInput> = async ({
    name,
  }) => {
    const request: PutMachineCategoryRequest = {
      name: name,
    }

    await submitPromises([
      {
        subject: "機種の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putMachineCategory(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機種分類を追加"
    />
  )
}

type MachineCategoryUpdateModalProps = {
  showModal: boolean
  machineCategory?: MachineCategory
  machineCategories: MachineCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const MachineCategoryUpdateModal: React.FC<
  MachineCategoryUpdateModalProps
> = ({
  showModal,
  machineCategory,
  machineCategories,
  onClose,
  onFinish = () => undefined,
}) => {
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) =>
          !machineCategories
            .filter((mc) => machineCategory && mc.id !== machineCategory.id)
            .some((mc) => mc.name === value),
      ),
  })

  const useFormReturn = useForm<MachineCategoryFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: machineCategory?.name || "",
    },
  })
  const { setValue, reset } = useFormReturn

  useMemo(() => {
    if (machineCategory) {
      setValue("name", machineCategory.name)
    }
  }, [machineCategory, setValue])

  const onSubmit: SubmitHandler<MachineCategoryFormInput> = async ({
    name,
  }) => {
    if (!machineCategory) return
    const request: PutMachineCategoryRequest = {
      id: machineCategory.id,
      name: name,
    }

    await submitPromises([
      {
        subject: "機種分類の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putMachineCategory(request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="プライズ機種分類編集"
    />
  )
}

type EditModalInnerProps = {
  showModal: boolean
  onSubmit: SubmitHandler<MachineCategoryFormInput>
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<MachineCategoryFormInput, any>
  title: string
}

const EditModalInner: React.FC<EditModalInnerProps> = ({
  showModal,
  onSubmit,
  onClose,
  useFormReturn,
  title,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useFormReturn

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose
          sx={{ textAlign: "left", mt: 1 }}
          variant="h2"
          onClose={onClose}
        >
          <Typography variant="h2">{title}</Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Stack gap={1}>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              機種分類名
            </Typography>
            <Controller
              control={control}
              name={"name"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Typography variant="subtitle2" color="error" sx={{ mt: 2 }}>
              必ずMachineDB.xlsxと一致するように、MachineDB.xlsxも変更して下さい
            </Typography>
          </Stack>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

type MachineCategoryDeleteModalProps = {
  showModal: boolean
  machineCategory?: MachineCategory
  onClose: () => void
  onFinish?: () => void
}

export const MachineCategoryDeleteModal: React.FC<
  MachineCategoryDeleteModalProps
> = ({ showModal, machineCategory, onClose, onFinish = () => undefined }) => {
  const { submitPromises } = useSubmitting()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickDelete = async () => {
    if (!machineCategory) return
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "機種分類の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deleteMachineCategory(machineCategory.id)
          onClose()
          onFinish()
        },
      },
    ])
    setIsSubmitting(false)
  }

  if (machineCategory) {
    // NOTE: onClose 時に machineCategory が undefined になるので、message のちらつきを防止
    return (
      <DeleteModal
        showModal={showModal}
        onSubmit={onClickDelete}
        onClose={onClose}
        isSubmitting={isSubmitting}
        message={`「${machineCategory.name}」を削除しますか？`}
      />
    )
  } else {
    return <></>
  }
}
