import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  CsvRow,
  FeatureExecutionPeriod,
  PrizePlacementStatus,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { getPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import {
  getExecutionStatusText,
  getPlacementTypeText,
  PlacementType,
  PlacementTypeWithAll,
} from "src/domains/prizes/placementStatusRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState, inventoryPeriodsState } from "src/recoil"

type PlacementStatusWithPlacementType = PrizePlacementStatus & {
  placementType: PlacementType
}

const csvHeader: CsvRow = {
  columns: ["有効/無効", "区分", "名前", "ステータス"],
}

export const InventoryPlacementStatusHistoriesCsv: React.FC = () => {
  const { arcadeCd } = useParams()

  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const arcade = useRecoilValue(currentArcadeState)
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const prizeExecutionPeriods = useMemo(
    () =>
      inventoryPeriods?.flatMap((period) =>
        period.prizeExecutionPeriods.filter(
          (prizePeriod) =>
            prizePeriod.status === FeatureExecutionPeriodStatusEnum.Ended,
        ),
      ),
    [inventoryPeriods],
  )
  const [selectedPrizeExecutionPeriodId, setSelectedPrizeExecutionPeriodId] =
    useState<FeatureExecutionPeriod["id"] | undefined>()
  const selectedPrizeExecutionPeriod = useMemo(
    () =>
      prizeExecutionPeriods?.find(
        (period) => period.id === selectedPrizeExecutionPeriodId,
      ),
    [prizeExecutionPeriods, selectedPrizeExecutionPeriodId],
  )

  const { isAvailablePlacementStatusHistoriesCsv } = useUserRole()

  useEffect(
    () =>
      prizeExecutionPeriods &&
      setSelectedPrizeExecutionPeriodId(prizeExecutionPeriods[0]?.id),
    [prizeExecutionPeriods],
  )

  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = useCallback(async () => {
    arcadeCd &&
      arcade &&
      selectedPrizeExecutionPeriod &&
      submitPromises([
        {
          subject: "全保管場所_棚卸済証明の取得",
          promise: async () => {
            const res = await getPrizePlacementStatuses(
              arcadeCd,
              PlacementTypeWithAll.All,
              selectedPrizeExecutionPeriod.id,
            )
            const { shelfStatuses, onBoothStatuses, boothStatuses } = res.data
            if (!shelfStatuses && !onBoothStatuses && !boothStatuses) {
              throw new Error()
            }

            const placementStatuses: PlacementStatusWithPlacementType[] = [
              ...(
                shelfStatuses?.map((s) => ({
                  ...s,
                  placementType: PlacementType.Storage,
                })) ?? []
              ).sort((a, b) => a.placementId - b.placementId),
              ...(
                boothStatuses?.map((s) => ({
                  ...s,
                  placementType: PlacementType.InBooth,
                })) ?? []
              ).sort((a, b) => a.placementId - b.placementId),
              ...(
                onBoothStatuses?.map((s) => ({
                  ...s,
                  placementType: PlacementType.OnBooth,
                })) ?? []
              ).sort((a, b) => a.placementId - b.placementId),
            ]

            const csvRows: CsvRow[] = placementStatuses.map(
              ({
                isAvailable,
                placementType,
                placementName,
                executionStatus,
              }) => ({
                columns: [
                  isAvailable ? "有効" : "無効",
                  getPlacementTypeText(placementType),
                  placementName,
                  getExecutionStatusText(executionStatus),
                ],
              }),
            )

            downloadCsv({
              csv: { headerRow: csvHeader, rows: csvRows },
              fileName: `景品全保管場所_棚卸済証明-${arcade.name}-${selectedPrizeExecutionPeriod.startAt}_${selectedPrizeExecutionPeriod.endAt}.csv`,
            })
          },
        },
      ])
  }, [
    arcade,
    arcadeCd,
    downloadCsv,
    submitPromises,
    selectedPrizeExecutionPeriod,
  ])

  return (
    <MainContentLayout
      title="景品全保管場所_棚卸済証明CSV"
      renderContent={() => (
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption">
              全ての保管場所が棚卸時に網羅された事を証明します。
              <br />
              このファイルは会社から求められた時のみ提出します。
            </Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            {selectedPrizeExecutionPeriodId ? (
              <FormControl fullWidth>
                <InputLabel id="period">期間</InputLabel>
                <Select
                  fullWidth
                  label="期間"
                  labelId="period"
                  value={selectedPrizeExecutionPeriodId}
                  onChange={(e) =>
                    setSelectedPrizeExecutionPeriodId(
                      e.target.value as FeatureExecutionPeriod["id"],
                    )
                  }
                >
                  {prizeExecutionPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : prizeExecutionPeriods?.length === 0 ? (
              "出力可能な期間がありません"
            ) : (
              <CircularProgress />
            )}
          </Card>
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={
              isSubmitting ||
              !selectedPrizeExecutionPeriodId ||
              !isAvailablePlacementStatusHistoriesCsv
            }
          >
            CSV出力
          </Button>
        </Stack>
      )}
    />
  )
}
