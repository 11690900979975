import { useMemo } from "react"

import { useRecoilValue } from "recoil"

import { getMaterialInventoryHistories } from "src/api/material-inventory-histories"
import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { MaterialMachine, MaterialMachineShelf } from "src/api/models"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import {
  InventoryMaterialExecuteMultipleSearchParams,
  InventoryMaterialExecuteSingleSearchParams,
} from "src/domains/materials/materialInventoryHistoriesRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { sortPlacementStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"

export type useInventoryExecuteMaterialParams = {
  arcadeCd: string
  amMachineNumber?: MaterialMachine["amMachineNumber"]
  machineShelf?: MaterialMachineShelf
  searchParams:
    | InventoryMaterialExecuteSingleSearchParams
    | InventoryMaterialExecuteMultipleSearchParams
}

export const useInventoryExecuteMaterials = ({
  arcadeCd,
  amMachineNumber,
  machineShelf,
  searchParams,
}: useInventoryExecuteMaterialParams) => {
  const materialMachineId = machineShelf?.materialMachineId

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  // TODO: amMachineNumber による filter (要 API 実装)
  const { resource: statusesResource, refetch: refetchStatuses } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? async () => {
            return await getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementType.InMachine,
              currentMaterialExecutionPeriod.id,
              { placementId: machineShelf?.id },
            )
          }
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${
      MaterialPlacementType.InMachine
    }:${JSON.stringify(currentMaterialExecutionPeriod)}:${machineShelf?.id}`,
  })
  const machineStatuses = useMemo(
    () => statusesResource?.data.machineStatuses || [],
    [statusesResource],
  )

  // TODO: amMachineNumber による filter (要 API 実装)
  const { resource: historiesResource, refetch: refetchHistories } =
    useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryHistories(
                arcadeCd,
                currentMaterialExecutionPeriod.id,
                {
                  materialCd: searchParams.materialCd,
                  materialName: searchParams.materialName,
                  materialNameKana: searchParams.materialNameKana,
                  ipName: searchParams.ipName,
                  makerName: searchParams.makerName,
                },
              )
          : undefined,
      recoilKey: `getMaterialInventoryHistories:${arcadeCd}:${JSON.stringify(
        currentMaterialExecutionPeriod,
      )}:${JSON.stringify(searchParams)}`,
    })
  const histories = historiesResource?.data.histories

  const { resource: stocksResource, refetch: refetchStocks } = useResource({
    subject: "在庫検索結果の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialOperationStocks(arcadeCd, {
            materialName: searchParams.materialName,
            materialNameKana: searchParams.materialNameKana,
            materialCd: searchParams.materialCd,
            materialMachineIds: materialMachineId
              ? [materialMachineId]
              : undefined,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
            exactAmMachineNumber: amMachineNumber,
          })
      : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${amMachineNumber}:${materialMachineId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = stocksResource?.data.stocks
    if (stocks && stocks.length > 0) {
      return sortPlacementStocks(
        stocks.flatMap((s) =>
          s.machineStocks.map((machineStock) => ({
            material: s.material,
            ...machineStock,
          })),
        ),
        searchParams,
      )
    } else {
      return []
    }
  }, [searchParams, stocksResource])

  return {
    filteredStocks,
    machineStatuses,
    histories,
    refetchStatuses,
    refetchHistories,
    refetchStocks,
  }
}
