import { useMemo, useState, useEffect, useCallback } from "react"

import {
  Button,
  TableHead,
  Checkbox,
  TableRow,
  alpha,
  Stack,
} from "@mui/material"
import { useParams } from "react-router-dom"

import {
  getMaterialInventoryGroups,
  putMaterialInventoryGroups,
} from "src/api/material-inventory-group"
import { getMaterialStorages } from "src/api/material-storages"
import {
  MaterialShelf,
  MaterialStorage,
  PutMaterialInventoryGroupRequest,
} from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import {
  InventoryMaterialGroupEditModalTableItem,
  InventoryMaterialGroupEditModal,
} from "src/components/organisms/materials/InventoryMaterialGroupEditModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

type TableItem = {
  storage: MaterialStorage
  shelf: MaterialShelf
  groupName?: string | undefined
}

export const InventoryMaterialGroupStorage = () => {
  return (
    <MainContentLayout
      title="棚卸グループ設定(保管場所)"
      renderContent={() => <InventoryMaterialGroupStorageMenu />}
    />
  )
}

const InventoryMaterialGroupStorageMenu = () => {
  const { arcadeCd } = useParams()

  const { resource: storagesResource, refetch: refetchStorages } = useResource({
    subject: "保管場所情報の取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorages:${arcadeCd}`,
  })
  const storages = useMemo(
    () => storagesResource?.data.storages || [],
    [storagesResource],
  )

  const { resource: inventoryGroupsResource, refetch: refetchInventoryGroups } =
    useResource({
      subject: "棚卸グループの取得",
      fetch: arcadeCd
        ? () => getMaterialInventoryGroups(arcadeCd, { placement: "storage" })
        : undefined,
      recoilKey: `getMaterialInventoryGroups:${arcadeCd}:storage`,
    })
  const existGroupNames = useMemo(
    () => inventoryGroupsResource?.data.groups.map((g) => g.groupName) || [],
    [inventoryGroupsResource],
  )

  const [tableItems, setTableItems] = useState<TableItem[]>([])
  const [selectedItems, setSelectedItems] = useState<TableItem[]>([])
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  useEffect(() => {
    setTableItems(
      storages
        .flatMap(({ storage, shelves }) =>
          shelves?.map((shelf) => ({
            selected: false,
            storage,
            shelf,
            groupName: shelf.inventoryGroup?.groupName,
          })),
        )
        .filter(Boolean) as TableItem[],
    )
  }, [storages])

  const onSubmit = useCallback(
    (groupName: string, items: InventoryMaterialGroupEditModalTableItem[]) => {
      if (!arcadeCd) return
      const request: PutMaterialInventoryGroupRequest = {
        groupName,
        placementShelfIds: items.map((i) => i.placementShelfId),
        placement: MaterialPlacementType.Storage,
      }
      submitPromises([
        {
          subject: "棚卸グループの更新",
          showSuccessMessage: true,
          promise: async () => {
            await putMaterialInventoryGroups(arcadeCd, request)
            refetchStorages()
            refetchInventoryGroups()
            setSelectedItems([])
            setEditModalOpen(false)
          },
        },
      ])
    },
    [arcadeCd, refetchStorages, refetchInventoryGroups, submitPromises],
  )

  return (
    <>
      <Stack gap={2}>
        <InventoryMaterialGroupStorageTable
          tableItems={tableItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />

        <Button
          variant="contained"
          fullWidth
          onClick={() => setEditModalOpen(true)}
          disabled={selectedItems.length === 0}
        >
          棚卸グループを設定
        </Button>
      </Stack>

      {selectedItems.length > 0 && (
        <InventoryMaterialGroupEditModal
          onSubmit={onSubmit}
          onClose={() => setEditModalOpen(false)}
          open={editModalOpen}
          tableItems={selectedItems.map((item) => ({
            ...item,
            placementShelfId: item.shelf.id,
            name: `${item.storage.name} ${item.shelf.name}`,
          }))}
          existGroupNames={existGroupNames}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  )
}

type InventoryGroupStorageTableProps = {
  tableItems: TableItem[]
  selectedItems: TableItem[]
  setSelectedItems: React.Dispatch<React.SetStateAction<TableItem[]>>
}

const InventoryMaterialGroupStorageTable: React.FC<
  InventoryGroupStorageTableProps
> = ({ tableItems, selectedItems, setSelectedItems }) => {
  return (
    <PaginatedTable
      scrollableX
      stateKey="inventoryMaterialGroupStorage"
      header={
        <TableHead>
          <TableRow
            sx={{
              th: {
                p: 1,
                whiteSpace: "nowrap",
              },
            }}
          >
            <ExtTableCell />
            <ExtTableCell>保管場所名称</ExtTableCell>
            <ExtTableCell>棚名称</ExtTableCell>
            <ExtTableCell>
              棚卸
              <br />
              グループ
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      items={tableItems}
      renderRow={(item, index) => (
        <TableBorderedRow
          data-testid={item.shelf.id}
          hover
          sx={{
            color: theme.palette.neutral[900],
            cursor: "pointer",
            "&:hover td": {
              background: (theme) => theme.palette.neutral[200],
            },
            td: {
              px: 1,
              py: 2,
              ...(!item.shelf.isAvailable && {
                background: alpha(theme.palette.text.disabled, 0.3),
              }),
            },
          }}
          key={index}
          onClick={() => {
            if (!item.shelf.isAvailable) return

            const checked = selectedItems.some(
              (selectedItem) =>
                selectedItem.storage.id === item.storage.id &&
                selectedItem.shelf.id === item.shelf.id,
            )
            if (!checked) return setSelectedItems([...selectedItems, item])

            let newSelectedItems = [...selectedItems]
            newSelectedItems = newSelectedItems.filter(
              (selectedItem) =>
                selectedItem.storage.id !== item.storage.id ||
                selectedItem.shelf.id !== item.shelf.id,
            )
            setSelectedItems(newSelectedItems)
          }}
          selected={selectedItems.some(
            (selectedItem) =>
              selectedItem.storage.id === item.storage.id &&
              selectedItem.shelf.id === item.shelf.id,
          )}
        >
          <ExtTableCell>
            <Checkbox
              checked={selectedItems.some(
                (selectedItem) =>
                  selectedItem.storage.id === item.storage.id &&
                  selectedItem.shelf.id === item.shelf.id,
              )}
              disabled={!item.shelf.isAvailable}
              sx={{ p: 0 }}
            />
          </ExtTableCell>
          <ExtTableCell>{item.storage.name}</ExtTableCell>

          <ExtTableCell>{item.shelf.name}</ExtTableCell>

          <ExtTableCell>{item?.groupName}</ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
