/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetArcadeResponse,
  GetArcadesParams,
  GetArcadesResponse,
  PatchArcadeRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 店舗一覧を取得
 */
export const getArcades = (
  params?: GetArcadesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetArcadesResponse>(
    { url: `/arcades`, method: "GET", params },
    options,
  )
}
/**
 * @summary 店舗詳細を取得
 */
export const getArcade = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetArcadeResponse>(
    { url: `/arcades/${arcadeCd}`, method: "GET" },
    options,
  )
}
/**
 * @summary 店舗情報を更新
 */
export const patchArcade = (
  arcadeCd: string,
  patchArcadeRequest: PatchArcadeRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchArcadeRequest,
    },
    options,
  )
}
export type GetArcadesResult = NonNullable<
  Awaited<ReturnType<typeof getArcades>>
>
export type GetArcadeResult = NonNullable<Awaited<ReturnType<typeof getArcade>>>
export type PatchArcadeResult = NonNullable<
  Awaited<ReturnType<typeof patchArcade>>
>
