import React, { useMemo, useState, useEffect } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Stack,
  Checkbox,
  FormHelperText,
  Card,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PutPrizeShelvesRequest,
  PrizeShelf,
  PrizeStorage,
  PrizeShelfStorageTypeEnum,
} from "src/api/models"
import { getPrizeInventoryGroups } from "src/api/prize-inventory-group"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { deletePrizeShelf, putPrizeShelf } from "src/api/prize-shelves"
import { getPrizeStorage } from "src/api/prize-storages"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { temporaryStorageName } from "src/domains/prizes/inventoryStockRepository"
import {
  getShelfStorageTypeLabel,
  suffixKidsMarketShelfName,
  trimPrizeShelfName,
} from "src/domains/prizes/shelfRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { currentArcadeState } from "src/recoil"

type FormType = Pick<PrizeShelf, "name" | "storageType"> & {
  inventoryGroupName?: string
  isKidsMarket: boolean
}

export const InventoryPrizeEditShelf = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
  })
  const { storage, shelves, shelf } = useMemo(() => {
    const { storage, shelves } = resource?.data || {}
    return {
      storage,
      shelves,
      shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
    }
  }, [resource, shelfId])

  return (
    <MainContentLayout
      title={storage && shelf ? storage.name + " " + shelf.name : ""}
      renderContent={() => (
        <InventoryPrizeEditShelfForm
          storage={storage}
          shelves={shelves}
          shelf={shelf}
        />
      )}
      backButtonLabel="保存せずに戻る"
    />
  )
}

type InventoryPrizeEditShelfFormProps = {
  storage?: PrizeStorage
  shelves?: PrizeShelf[]
  shelf?: PrizeShelf
}

const InventoryPrizeEditShelfForm: React.FC<
  InventoryPrizeEditShelfFormProps
> = ({ storage, shelves, shelf }) => {
  const { arcadeCd, storageId, shelfId } = useParams()
  const navigate = useNavigate()

  const arcade = useRecoilValue(currentArcadeState)

  const inventoryGroups = useResource({
    subject: "棚卸グループの取得",
    fetch: arcadeCd
      ? () => getPrizeInventoryGroups(arcadeCd, { placement: "storage" })
      : undefined,
    recoilKey: `getInventoryGroups:${arcadeCd}:storage`,
  }).resource?.data.groups
  const inventoryGroupNames = useMemo(
    () => inventoryGroups?.map((g) => g.groupName) || [],
    [inventoryGroups],
  )

  const stocks = useResource({
    subject: "棚内の在庫の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeOperationStocks(arcadeCd, {
            storageId: Number(storageId),
            shelfId: Number(shelfId),
          })
      : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${storageId}:${shelfId}`,
  }).resource?.data.stocks

  const shelfNames = useMemo(() => {
    return (shelves || []).map((shelf) => shelf.name)
  }, [shelves])

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const {
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>()

  const value = {
    shelfName: useWatch({ control, name: "name" }),
    isKidsMarket: useWatch({ control, name: "isKidsMarket" }),
  }

  const hasKidsMarketStock = useMemo(
    () => stocks?.some((stock) => stock.prize.isKidsMarket) || false,
    [stocks],
  )
  const hasPrizeStock = useMemo(
    () => stocks?.some((stock) => !stock.prize.isKidsMarket) || false,
    [stocks],
  )

  const isOtherShelfName =
    shelf?.name === value.shelfName
      ? false
      : shelfNames.includes(value.shelfName)
  const unableToKidsMarket = hasPrizeStock && !(shelf?.isKidsMarket || false)
  const unableToNormal = hasKidsMarketStock && (shelf?.isKidsMarket || false)

  useEffect(() => {
    shelf &&
      reset({
        ...shelf,
        name: trimPrizeShelfName(shelf.name),
      })
  }, [reset, shelf])

  useEffect(() => {
    const { groupName } = shelf?.inventoryGroup || {}
    if (groupName && inventoryGroupNames.includes(groupName)) {
      setValue("inventoryGroupName", groupName)
    }
  }, [shelf, setValue, inventoryGroupNames])

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    name,
    storageType,
    isKidsMarket,
    inventoryGroupName,
  }) => {
    const numStorageId = Number(storageId)
    const numShelfId = Number(shelfId)
    if (!arcadeCd || isNaN(numStorageId)) return
    const request: PutPrizeShelvesRequest = {
      id: numShelfId,
      name,
      storageType,
      isKidsMarket,
      inventoryGroupName: inventoryGroupName ?? "",
    }
    request.name = name + " " + getShelfStorageTypeLabel(storageType, true)
    if (isKidsMarket) {
      request.name = request.name + " " + suffixKidsMarketShelfName
    }
    if (!(arcade?.hasKidsMarket || false)) {
      request.isKidsMarket = false
    }

    await submitPromises([
      {
        subject: "棚の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeShelf(arcadeCd, numStorageId, request)
          return navigate(-1)
        },
      },
    ])
  }

  const onDelete = async () => {
    const numStorageId = Number(storageId)
    const numShelfId = Number(shelfId)
    if (!arcadeCd || isNaN(numStorageId) || isNaN(numShelfId)) return

    submitPromises([
      {
        subject: "棚の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeShelf(arcadeCd, numStorageId, numShelfId)
          return navigate(-1)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DeleteModal
        onSubmit={onDelete}
        onClose={() => setDeleteModalOpen(false)}
        showModal={deleteModalOpen}
        isSubmitting={isSubmitting}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"name"}
                defaultValue={shelf?.name ?? ""}
                render={({ field }) => (
                  <TextField
                    label="棚名称"
                    fullWidth
                    sx={{ background: "white" }}
                    {...field}
                    error={isOtherShelfName}
                    helperText={isOtherShelfName && "すでに存在する棚名です"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"storageType"}
                defaultValue={
                  shelf?.storageType ?? PrizeShelfStorageTypeEnum.ForStorage
                }
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    sx={{ background: "white" }}
                    label="保管分類"
                    {...field}
                    error={!!errors.storageType?.message}
                    helperText={errors.storageType?.message}
                  >
                    <MenuItem
                      value={PrizeShelfStorageTypeEnum.ForReplenishment}
                    >
                      補充在庫
                    </MenuItem>
                    <MenuItem value={PrizeShelfStorageTypeEnum.ForStorage}>
                      保管在庫
                    </MenuItem>
                    <MenuItem value={PrizeShelfStorageTypeEnum.InBooth}>
                      機械内在庫
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"inventoryGroupName"}
                defaultValue={""}
                render={({ field, fieldState }) => (
                  <SearchAutoComplete
                    items={inventoryGroupNames.map((name) => ({
                      label: name,
                      value: name,
                    }))}
                    inputLabel="棚卸グループ"
                    {...field}
                    error={!!fieldState.error}
                  />
                )}
              />
            </Grid>
            {(arcade?.hasKidsMarket || false) && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"isKidsMarket"}
                  defaultValue={shelf?.isKidsMarket}
                  render={({ field }) => (
                    <Stack>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          onChange={field.onChange}
                          checked={field.value}
                          inputRef={field.ref}
                          sx={{ pl: 0 }}
                          disabled={unableToKidsMarket || unableToNormal}
                        />
                        <Typography
                          color={
                            unableToKidsMarket || unableToNormal
                              ? "text.secondary"
                              : "text.primary"
                          }
                        >
                          この棚をKM景品専用にする
                        </Typography>
                      </Stack>
                      {(unableToKidsMarket || unableToNormal) && (
                        <FormHelperText error>
                          {unableToKidsMarket &&
                            "通常景品が入っているため、KM景品専用にできません。KM専用景品にする場合は、棚から通常景品を移動させてください。"}
                          {unableToNormal &&
                            "KM景品が入っているため、KM景品専用を解除できません。KM景品を解除するには、棚からKM景品を移動させてください。"}
                        </FormHelperText>
                      )}
                    </Stack>
                  )}
                />
              </Grid>
            )}
            {value.isKidsMarket && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="h3">{`変更後の棚名称：${
                  (value.shelfName || "") + suffixKidsMarketShelfName
                }`}</Typography>
              </Grid>
            )}
          </Card>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                storage?.name === temporaryStorageName || isOtherShelfName
              }
              loading={isSubmitting}
            >
              棚の変更を保存
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="error"
              fullWidth
              disabled={storage?.name === temporaryStorageName}
              loading={isSubmitting}
              onClick={() => setDeleteModalOpen(true)}
            >
              棚を削除
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
