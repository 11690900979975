import { useCallback, useEffect } from "react"

import axios from "axios"
import { useLocation } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { isForbidden } from "src/domains/authRepository"
import {
  resourceLastUpdatedAtState,
  resourceMapState,
  snackbarErrorMessageState,
  snackbarSuccessMessageState,
} from "src/recoil"

export interface ResourceItem<T> {
  fetch?: () => Promise<T>
  recoilKey: string
  useCache?: boolean
  subject: string
  showSuccessMessage?: boolean
  hideErrorMessage?: boolean
  skip?: boolean
  providerName?: string
}

export interface ResourceMapItem<T> {
  status?: "fetching" | "success" | "error" | "skipped"
  resource?: T
  error?: Error
}
export type ResourceMap<T> = Map<string, ResourceMapItem<T>>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResourceMapState = ResourceMap<any>

export type UseResourceReturn<T> = ResourceMapItem<T> & {
  refetch: () => Promise<void>
  refetchForce: () => Promise<void>
}

// ref: https://zenn.dev/uhyo/books/react-concurrent-handson/viewer/data-fetching-2

export const useResource = <T>({
  fetch,
  recoilKey,
  useCache = false,
  subject,
  showSuccessMessage = false,
  hideErrorMessage = false,
  skip = false,
  providerName,
}: ResourceItem<T>): UseResourceReturn<T> => {
  const { pathname } = useLocation()
  const key = providerName
    ? `${providerName}:${recoilKey}`
    : `${pathname}:${recoilKey}`

  const resourceMap = useRecoilValue<ResourceMap<T>>(resourceMapState)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const setLastUpdatedAt = useRecoilState(resourceLastUpdatedAtState)[1]
  const forceUpdate = useCallback(
    () => setLastUpdatedAt(new Date().getTime()),
    [setLastUpdatedAt],
  )

  useEffect(() => {
    return () => {
      if (
        resourceMap.get(key)?.status === "error" ||
        resourceMap.get(key)?.status === "skipped" ||
        (!useCache &&
          resourceMap.get(key) &&
          resourceMap.get(key)?.status !== "fetching")
      ) {
        resourceMap.delete(key)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, useCache])

  const fetchWithSnackbarMessage = useCallback(
    async (isRefetch = false, isForce = false) => {
      if (skip && !isForce) {
        resourceMap.set(key, { status: "skipped" })
        return
      }
      // NOTE: fetching のときは空の Promise を throw することで、多重 fetch や return undefined を回避
      if (!fetch || resourceMap.get(key)?.status === "fetching") {
        return
      }

      const keepCache = (useCache || isRefetch) && !isForce
      if (!keepCache) {
        resourceMap.delete(key)
      }

      try {
        const cacheExists = keepCache && !!resourceMap.get(key)?.resource
        if (!cacheExists) {
          resourceMap.set(key, { ...resourceMap.get(key), status: "fetching" })
        }
        const resource = await fetch()
        resourceMap.set(key, { status: "success", resource })
        showSuccessMessage && setSuccessMessage(`${subject}に成功しました`)
      } catch (e) {
        if (!hideErrorMessage) {
          if (axios.isAxiosError(e) && isForbidden(e)) {
            setErrorMessage("権限の関係で失敗しました")
          } else {
            setErrorMessage(`${subject}に失敗しました`)
          }
        }

        resourceMap.set(key, {
          status: "error",
          error: e instanceof Error ? e : undefined,
        })
        throw e
      } finally {
        forceUpdate()
      }
    },
    [
      key,
      showSuccessMessage,
      hideErrorMessage,
      subject,
      fetch,
      setErrorMessage,
      setSuccessMessage,
      resourceMap,
      forceUpdate,
      useCache,
      skip,
    ],
  )

  if (
    resourceMap.get(key) === undefined ||
    resourceMap.get(key)?.status === "fetching"
  ) {
    throw fetchWithSnackbarMessage()
  }

  return {
    ...resourceMap.get(key),
    refetch: () => fetchWithSnackbarMessage(true),
    refetchForce: () => fetchWithSnackbarMessage(false, true),
  }
}
