import { Box, Container, Typography } from "@mui/material"

export const NotFound: React.FC = () => (
  <>
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="center" color="textPrimary" variant="h1">
            Not Found
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle1">
            ページが見つかりませんでした
          </Typography>
        </Box>
      </Container>
    </Box>
  </>
)
