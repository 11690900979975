import { Theme, TableCellProps, SxProps, TableCell } from "@mui/material"

export interface ExtTableCellProps extends TableCellProps {
  border?: boolean
  sticky?: boolean
  zIndex?: number
  fixedWidth?: number
  sx?: SxProps<Theme>
}

export const ExtTableCell = ({
  border,
  sticky,
  fixedWidth,
  zIndex,
  children,
  sx,
  ...props
}: ExtTableCellProps) => (
  <TableCell
    {...props}
    sx={[
      {
        ...(border && {
          borderRight: "1px solid",
        }),
        ...(sticky && {
          position: "sticky",
          left: 0,
          zIndex: 100,
        }),
        ...(zIndex !== undefined && { zIndex: zIndex }),
        ...(fixedWidth !== undefined && {
          minWidth: `${fixedWidth}px!important`,
          maxWidth: `${fixedWidth}px!important`,
        }),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    {children}
  </TableCell>
)
