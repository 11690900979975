import { PrizeShelfStorageTypeEnum } from "src/api/models"

export const suffixKidsMarketShelfName = "【KM景品専用】"

export const getShelfStorageTypeLabel = (
  storageType: PrizeShelfStorageTypeEnum,
  inName: boolean | undefined = false,
) => {
  let name = ""
  switch (storageType) {
    case PrizeShelfStorageTypeEnum.ForReplenishment:
      name = "補充在庫"
      break

    case PrizeShelfStorageTypeEnum.ForStorage:
      name = "保管在庫"
      break

    case PrizeShelfStorageTypeEnum.InBooth:
      name = "機械内在庫"
      break

    default:
      // eslint-disable-next-line no-case-declarations
      const never: never = storageType
      throw new Error(never)
  }
  if (inName) {
    return "(" + name + ")"
  } else {
    return name
  }
}

const shelfStorageTypeNameRegexp = /\(((補充|保管|機械内)在庫|ブース内)\)/

export const trimPrizeShelfName = (name: string) =>
  name
    .replace(new RegExp(suffixKidsMarketShelfName, "g"), "")
    .replace(new RegExp(shelfStorageTypeNameRegexp, "g"), "")
    .trimEnd()
