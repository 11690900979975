import { useMemo, useState } from "react"

import { TextField, MenuItem, Select, Autocomplete } from "@mui/material"
import {
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

import { PrizeStorage } from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeStorages } from "src/api/prize-storages"
import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { InventoryVerifyStockSearchParams } from "src/domains/prizes/inventoryMoveRepository"
import { operationStockFilterSortByLabels } from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import { inventoryVerifyStockSearchParamsState } from "src/recoil/inventory"

export const InventoryPrizeVerifyStockFilter: React.FC = () => {
  const { arcadeCd } = useParams()
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryVerifyStockSearchParamsState,
  )
  const [storageId, setStorageId] = useState<number | undefined>(undefined)

  const { resource: storagesResource } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  })
  const storages = useMemo(
    () => storagesResource?.data.storages || [],
    [storagesResource],
  )

  const shelves = useMemo(
    () =>
      (storages || []).find((e) => storageId === e.storage.id)?.shelves || [],
    [storageId, storages],
  )
  const shelfOptions = useMemo(
    () =>
      shelves.map((shelf) => ({
        label: shelf.name,
        id: shelf.id,
      })),
    [shelves],
  )

  const { resource: floorMapResource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  })
  const floorMapPointOptions = useMemo(
    () =>
      (floorMapResource?.data.floorMapPoints || []).map((p) => ({
        label: p.name,
        id: p.id,
      })),
    [floorMapResource],
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.prizeCd ||
          searchParams.prizeName ||
          searchParams.prizeNameKana ||
          searchParams.makerName ||
          searchParams.ipName ||
          searchParams.storageId ||
          searchParams.shelfId ||
          searchParams.inventoryFloorMapPointId ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              inputProps={{ "aria-label": "prizeNameKana" }}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField id={field.name} {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "storageId",
          label: "保管場所",
          render: (form) => (
            <StorageIdAutocomplete
              storages={storages.map((e) => e.storage)}
              storageId={searchParams.storageId}
              setStorageId={setStorageId}
              form={form}
            />
          ),
        },
        {
          name: "shelfId",
          label: "棚",
          hiddenAt: (values) =>
            values["storageId"] === undefined || values["storageId"] === "",
          render: ({ field, fieldState: { error } }) => (
            <Autocomplete
              key={`storageId${storageId}-shelfId`} // storageId 変更時に key を変更して、state をリセットすることで、InputValue が残るのを防ぐ
              options={shelfOptions}
              defaultValue={
                shelfOptions.filter(({ id }) => id === searchParams.shelfId)[0]
              }
              renderOption={(props, option) => (
                <WordBreakAllList {...props} key={option.id}>
                  {option.label}
                </WordBreakAllList>
              )}
              onChange={(_, option) => field.onChange(option?.id)}
              renderInput={(params) => (
                <TextField {...params} error={!!error} />
              )}
            />
          ),
        },
        {
          name: "inventoryFloorMapPointId",
          label: "ブース",
          render: ({ field, fieldState: { error } }) => (
            <Autocomplete
              options={floorMapPointOptions}
              defaultValue={
                floorMapPointOptions.filter(
                  ({ id }) => id === searchParams.inventoryFloorMapPointId,
                )[0]
              }
              renderOption={(props, option) => (
                <WordBreakAllList {...props} key={option.id}>
                  {option.label}
                </WordBreakAllList>
              )}
              onChange={(_, option) => field.onChange(option?.id)}
              renderInput={(params) => (
                <TextField {...params} error={!!error} />
              )}
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(operationStockFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}

type StorageIdAutocompleteProps = {
  storages: PrizeStorage[]
  storageId: number | undefined
  setStorageId: React.Dispatch<React.SetStateAction<number | undefined>>
  form: {
    field: ControllerRenderProps<
      InventoryVerifyStockSearchParams,
      | "storageId"
      | "prizeCd"
      | "prizeName"
      | "prizeNameKana"
      | "makerName"
      | "ipName"
      | "shelfId"
      | "sortBy"
      | "inventoryFloorMapPointId"
    >
    fieldState: ControllerFieldState
  }
}

const StorageIdAutocomplete = ({
  storages,
  storageId,
  setStorageId,
  form: {
    field,
    fieldState: { error },
  },
}: StorageIdAutocompleteProps) => {
  const formContext = useFormContext()
  const storageOptions = (storages || []).map((storage) => ({
    label: storage.name,
    id: storage.id,
  }))
  return (
    <Autocomplete
      options={storageOptions}
      defaultValue={storageOptions.filter(({ id }) => id === storageId)[0]}
      renderOption={(props, option) => (
        <WordBreakAllList {...props} key={option.id}>
          {option.label}
        </WordBreakAllList>
      )}
      onChange={(_, option) => {
        setStorageId(option?.id)
        field.onChange(option?.id)
        formContext.setValue("shelfId", undefined)
      }}
      renderInput={(params) => <TextField {...params} error={!!error} />}
      data-testid="storage"
    />
  )
}
