import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material"

import { PrizePlacementStatusesCount } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"

type InventoryStatusProps = {
  counts?: PrizePlacementStatusesCount
  type?: PlacementType
}

export const InventoryPrizeStatus: React.FC<InventoryStatusProps> = ({
  counts,
  type,
}) => {
  return (
    <Card>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography variant="h2">棚卸状況</Typography>
        {type === PlacementType.Storage && (
          <Typography variant="caption" sx={{ pt: 1 }} color="text.secondary">
            ※荷捌きはカウントから除外
          </Typography>
        )}
      </Box>

      <Divider />

      <Box
        sx={{
          px: 2,
          pb: 1,
        }}
      >
        <Table>
          <TableBody
            sx={(theme) => ({
              th: {
                borderColor: theme.palette.gray[30],
              },
              td: {
                borderColor: theme.palette.gray[30],
              },
            })}
          >
            <TableRow>
              <ExtTableCell component="th" scope="row">
                済
              </ExtTableCell>
              <ExtTableCell align="right" data-testid="status-executed-count">
                {counts?.executed}
              </ExtTableCell>
            </TableRow>
            <TableRow>
              <ExtTableCell component="th" scope="row">
                空
              </ExtTableCell>
              <ExtTableCell align="right" data-testid="status-empty-count">
                {counts?.empty}
              </ExtTableCell>
            </TableRow>
            <TableRow>
              <ExtTableCell component="th" scope="row">
                未対応
              </ExtTableCell>
              <ExtTableCell
                align="right"
                data-testid="status-notExecuted-count"
              >
                {counts?.notExecuted}
              </ExtTableCell>
            </TableRow>
            <TableRow sx={{ th: { border: 0 }, td: { border: 0 } }}>
              <ExtTableCell component="th" scope="row">
                無効
              </ExtTableCell>
              <ExtTableCell align="right">{counts?.unavailable}</ExtTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  )
}
