import { PropsWithChildren } from "react"

import { Card, Stack, Typography } from "@mui/material"

import { Prize, PrizeDelivery } from "src/api/models"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { getJpDateLabel } from "src/utils"

type PrizeDetailsProps = {
  prize: Prize
  delivery?: PrizeDelivery
  ListWrapper?: React.FC<PropsWithChildren>
}

export const PrizeDetails: React.FC<PrizeDetailsProps> = ({
  prize,
  delivery,
  ListWrapper = ({ children }) => <Card sx={{ p: 2 }}>{children}</Card>,
}) => {
  return (
    <Stack>
      <Typography variant="h1" pb={3}>
        {prize.prizeName}
      </Typography>
      <Stack gap={3}>
        <Stack direction="row" gap={3}>
          <Stack direction="row" gap={1}>
            <Typography variant="caption" color="text.secondary">
              景品CD
            </Typography>
            <Typography variant="subtitle2">{prize.prizeCd}</Typography>
          </Stack>
          {delivery && (
            <>
              <Stack direction="row" gap={1}>
                <Typography variant="caption" color="text.secondary">
                  着荷日
                </Typography>
                <Typography variant="subtitle2">
                  {getJpDateLabel(delivery.arriveAt)}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Typography variant="caption" color="text.secondary">
                  指定投入日
                </Typography>
                <Typography variant="subtitle2">
                  {getJpDateLabel(delivery.specifiedEntryDate)}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>

        <PrizeImageBox prizeCd={prize.prizeCd} noImageSize="large" />

        <Stack gap={1}>
          <ListWrapper>
            <Stack direction="row" py={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                flexBasis="160px"
              >
                キャラクター名
              </Typography>
              <Typography variant="body2">{prize.ipName}</Typography>
            </Stack>
            {delivery && (
              <Stack direction="row" py={2}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  flexBasis="160px"
                >
                  納品数量(CT)
                </Typography>
                <Typography variant="body2">{delivery.orderCarton}</Typography>
              </Stack>
            )}
            <Stack direction="row" py={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                flexBasis="160px"
              >
                入数
              </Typography>
              <Typography variant="body2">{prize.unitPerCarton}</Typography>
            </Stack>
            <Stack direction="row" py={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                flexBasis="160px"
              >
                単価
              </Typography>
              <Typography variant="body2">{prize.unitPriceJpy}円</Typography>
            </Stack>
            <Stack direction="row" py={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                flexBasis="160px"
              >
                パッケージサイズ
              </Typography>
              <Typography variant="body2">{`${prize.heightCm}cm × ${prize.widthCm}cm × ${prize.depthCm}cm`}</Typography>
            </Stack>
            <Stack direction="row" py={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                flexBasis="160px"
              >
                景品サイズ
              </Typography>
              <Typography variant="body2">{prize.prizeSizeCategory}</Typography>
            </Stack>
          </ListWrapper>
        </Stack>
      </Stack>
    </Stack>
  )
}
