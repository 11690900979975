import { useParams } from "react-router-dom"

import { InventoryPrizeStockDetails } from "src/components/templates/InventoryPrizeStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryPrizeVerifyStockDetails: React.FC = () => {
  const { prizeCd } = useParams()

  return (
    <MainContentLayout
      title="在庫確認・店内移動"
      renderContent={() => (
        <InventoryPrizeStockDetails prizeCd={prizeCd ?? ""} />
      )}
      disableBackButton
    />
  )
}
