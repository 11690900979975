/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type { GetPrizeFloorMemoColorsResponse } from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary フロアメモ色一覧を取得
 */
export const getPrizeFloorMemoColors = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeFloorMemoColorsResponse>(
    { url: `/floorMemoColors`, method: "GET" },
    options,
  )
}
export type GetPrizeFloorMemoColorsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeFloorMemoColors>>
>
