import { forwardRef } from "react"

import { DialogActionsProps, DialogActions } from "@mui/material"

interface CustomDialogActionsProps extends DialogActionsProps {
  sx?: DialogActionsProps["sx"]
}

export const CustomDialogActions = forwardRef<
  HTMLDivElement,
  CustomDialogActionsProps
>(({ sx, ...props }, ref) => (
  <DialogActions {...props} sx={{ ...sx, px: 3, mb: 2 }} ref={ref} />
))

CustomDialogActions.displayName = "CustomDialogActions"
