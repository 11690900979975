import React, { useMemo } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Stack,
  Checkbox,
  Card,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PutPrizeShelvesRequest,
  PrizeShelf,
  PrizeShelfStorageTypeEnum,
} from "src/api/models"
import { getPrizeInventoryGroups } from "src/api/prize-inventory-group"
import { putPrizeShelf } from "src/api/prize-shelves"
import { getPrizeStorage } from "src/api/prize-storages"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  getShelfStorageTypeLabel,
  suffixKidsMarketShelfName,
} from "src/domains/prizes/shelfRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { currentArcadeState } from "src/recoil"

type FormType = Pick<PrizeShelf, "name" | "storageType"> & {
  inventoryGroupName?: string
  isKidsMarket: boolean
}

export const InventoryPrizeAddShelf = () => {
  const { arcadeCd, storageId } = useParams()
  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
  })
  const storage = resource?.data.storage
  const shelves = resource?.data.shelves

  return (
    <MainContentLayout
      title={(storage?.name || "") + " 棚を追加"}
      renderContent={() => <InventoryPrizeAddShelfForm shelves={shelves} />}
      backButtonLabel="保存せずに戻る"
    />
  )
}

type InventoryPrizeAddShelfFormProps = {
  shelves?: PrizeShelf[]
}

const InventoryPrizeAddShelfForm: React.FC<InventoryPrizeAddShelfFormProps> = ({
  shelves,
}) => {
  const { arcadeCd, storageId } = useParams()
  const navigate = useNavigate()

  const arcade = useRecoilValue(currentArcadeState)

  const inventoryGroupNames =
    useResource({
      subject: "棚卸グループの取得",
      fetch: arcadeCd
        ? () => getPrizeInventoryGroups(arcadeCd, { placement: "storage" })
        : undefined,
      recoilKey: `getInventoryGroups:${arcadeCd}:storage`,
    }).resource?.data.groups.map((g) => g.groupName) || []

  const shelfNames = useMemo(() => {
    return (shelves || []).map((shelf) => shelf.name)
  }, [shelves])

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>()

  const value = {
    shelfName: useWatch({ control, name: "name" }),
    isKidsMarket: useWatch({ control, name: "isKidsMarket" }),
  }

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    name,
    storageType,
    isKidsMarket,
    inventoryGroupName,
  }) => {
    const numStorageId = Number(storageId)
    if (!arcadeCd || isNaN(numStorageId)) return
    const request: PutPrizeShelvesRequest = {
      name,
      storageType,
      isKidsMarket,
      inventoryGroupName: inventoryGroupName ?? "",
    }
    request.name = name + " " + getShelfStorageTypeLabel(storageType, true)
    if (isKidsMarket) {
      request.name = request.name + " " + suffixKidsMarketShelfName
    }
    if (!(arcade?.hasKidsMarket || false)) {
      request.isKidsMarket = false
    }

    await submitPromises([
      {
        subject: "棚の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeShelf(arcadeCd, numStorageId, request)
          return navigate(-1)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"name"}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    label="棚名称"
                    required
                    fullWidth
                    sx={{ background: "white" }}
                    {...field}
                    error={shelfNames.includes(value.shelfName)}
                    helperText={
                      shelfNames.includes(value.shelfName) &&
                      "すでに存在する棚名です"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"storageType"}
                defaultValue={PrizeShelfStorageTypeEnum.ForStorage}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    sx={{ background: "white" }}
                    label="保管分類"
                    {...field}
                    error={!!errors.storageType?.message}
                    helperText={errors.storageType?.message}
                  >
                    <MenuItem
                      value={PrizeShelfStorageTypeEnum.ForReplenishment}
                    >
                      補充在庫
                    </MenuItem>
                    <MenuItem value={PrizeShelfStorageTypeEnum.ForStorage}>
                      保管在庫
                    </MenuItem>
                    <MenuItem value={PrizeShelfStorageTypeEnum.InBooth}>
                      機械内在庫
                    </MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Controller
                control={control}
                name={"inventoryGroupName"}
                defaultValue={""}
                render={({ field, fieldState }) => (
                  <SearchAutoComplete
                    items={inventoryGroupNames.map((name) => ({
                      label: name,
                      value: name,
                    }))}
                    inputLabel="棚卸グループ"
                    {...field}
                    error={!!fieldState.error}
                  />
                )}
              />
            </Grid>
            {(arcade?.hasKidsMarket || false) && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={"isKidsMarket"}
                  defaultValue={false}
                  render={({ field }) => (
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Checkbox
                        onChange={field.onChange}
                        checked={field.value}
                        inputRef={field.ref}
                      />
                      <Typography>この棚をKM景品専用にする</Typography>
                    </Stack>
                  )}
                />
              </Grid>
            )}
            {value.isKidsMarket && (
              <Grid item xs={12}>
                <Typography variant="h3">{`追加後の棚名称：${
                  (value.shelfName || "") + suffixKidsMarketShelfName
                }`}</Typography>
              </Grid>
            )}
          </Card>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={shelfNames.includes(value.shelfName)}
              loading={isSubmitting}
            >
              保存
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
