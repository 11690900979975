import { Typography } from "@mui/material"

import {
  ExtTableCell,
  ExtTableCellProps,
} from "src/components/atoms/ExtTableCell"
import { useUserRole } from "src/hooks/useUserRole"

interface AdminExtTableCellProps extends ExtTableCellProps {
  word: string
  header?: boolean
}

export const AdminExtTableCell = ({
  word,
  header = false,
  ...props
}: AdminExtTableCellProps) => {
  const { isAdmin } = useUserRole()
  if (!isAdmin) {
    return <></>
  }

  return (
    <ExtTableCell {...props}>
      <Typography
        variant={header ? "subtitle2" : "caption"}
        color={header ? "primary.main" : "text.primary"}
      >
        {(header ? "Ⓐ " : "") + word}
      </Typography>
    </ExtTableCell>
  )
}
