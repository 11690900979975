import { useEffect, useState } from "react"

import {
  Card,
  Button,
  TableHead,
  TableRow,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { PrizeOperationOutHistoryElement } from "src/api/models"
import { getPrizeOperationOut } from "src/api/prize-operation-out"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { DefaultDateRangePicker } from "src/components/organisms/DefaultDateRangePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeSeamsOutModal } from "src/components/organisms/prizes/InventoryPrizeSeamsOutModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import {
  defaultDateRangePickerDateLabelStateSelector,
  snackbarSuccessMessageState,
} from "src/recoil"
import { hideInventoryHistoriesRegisteredSeamsState } from "src/recoil/inventory"
import { formatApiDate, getJpDateLabel } from "src/utils"

type InventoryOutHistoriesPerDate = {
  [date: string]: PrizeOperationOutHistoryElement[]
}

export const InventoryPrizeSeamsOut: React.FC = () => {
  const [hideRegisteredSeams, setHideRegisteredSeams] = useRecoilState(
    hideInventoryHistoriesRegisteredSeamsState,
  )

  return (
    <MainContentLayout
      title="出庫ログ一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 2 }}>
            <DefaultDateRangePicker />
          </Card>
          <FormControlLabel
            checked={hideRegisteredSeams}
            onChange={() => setHideRegisteredSeams(!hideRegisteredSeams)}
            control={<Checkbox />}
            disableTypography
            label="SEAMS登録済みを表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryPrizeSeamsOutMenu />}
    />
  )
}

const InventoryPrizeSeamsOutMenu = () => {
  const { arcadeCd } = useParams()

  const dateRange = useRecoilValue(defaultDateRangePickerDateLabelStateSelector)
  const { resource, refetch } = useResource({
    subject: "出庫記録の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeOperationOut(arcadeCd, {
            from: dateRange.start,
            to: dateRange.end,
          })
      : undefined,
    recoilKey: `getInventoryOut:${arcadeCd}:${dateRange.start}:${dateRange.end}`,
  })
  const { outHistories } = resource?.data || {}

  const outHistoriesPerDate = outHistories?.reduce(
    (result: InventoryOutHistoriesPerDate, history) => {
      const date = formatApiDate(history.outHistory.outAt)
      result[date] = [...(result[date] || []), history]
      return result
    },
    {},
  )
  const [selectedOutHistoryId, setSelectedOutHistoryId] = useState<
    number | undefined
  >()
  const selectedOutHistory = outHistories?.find(
    (h) => h.outHistory.id === selectedOutHistoryId,
  )
  const hideRegisteredSeams = useRecoilValue(
    hideInventoryHistoriesRegisteredSeamsState,
  )
  const [showModal, setShowModal] = useState(false)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  useEffect(() => {
    if (outHistories?.length === 0) {
      setSuccessMessage("指定期間の出庫記録が存在しませんでした")
    }
  }, [outHistories, setSuccessMessage])

  useEffect(() => {
    // NOTE: 日付変更時には選択中の入庫記録をリセット
    setSelectedOutHistoryId(undefined)
  }, [dateRange])

  return (
    <>
      {outHistoriesPerDate &&
        Object.keys(outHistoriesPerDate)
          .sort()
          .reverse()
          .map((date) => {
            const outHistories = (outHistoriesPerDate[date] || []).filter(
              (h) =>
                hideRegisteredSeams ? !h.outHistory.registeredSeams : true,
            )
            return (
              outHistories.length > 0 && (
                <Stack key={date} mb={2}>
                  <Stack sx={{ fontWeight: "bold" }}>
                    {getJpDateLabel(date)}
                  </Stack>
                  <Stack>
                    <OutHistoryTable
                      {...{
                        outHistories,
                        selectedOutHistory,
                        setSelectedOutHistoryId,
                        date,
                      }}
                    />
                  </Stack>
                </Stack>
              )
            )
          })}

      <Stack>
        <Button
          variant="contained"
          fullWidth
          disabled={!selectedOutHistory}
          onClick={() => setShowModal(true)}
        >
          {selectedOutHistory?.outHistory.registeredSeams
            ? "出庫登録状況を変更する"
            : "選択した景品をSEAMS登録済にする"}
        </Button>
      </Stack>

      {selectedOutHistory && (
        <InventoryPrizeSeamsOutModal
          showModal={showModal}
          outHistory={selectedOutHistory}
          onClose={() => setShowModal(false)}
          onFinish={() => refetch()}
        />
      )}
    </>
  )
}

interface OutHistoryTableProps {
  outHistories: PrizeOperationOutHistoryElement[]
  selectedOutHistory: PrizeOperationOutHistoryElement | undefined
  setSelectedOutHistoryId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  date: string
}

const OutHistoryTable: React.FC<OutHistoryTableProps> = ({
  outHistories,
  selectedOutHistory,
  setSelectedOutHistoryId,
  date,
}: OutHistoryTableProps) => {
  const columnNames = ["選択", "景品名", "出庫数", "登録済"]

  return (
    <RadioGroup>
      <PaginatedTable
        items={outHistories}
        stateKey={`outHistoryTable-${date}`}
        header={
          <TableHead>
            <TableRow>
              {columnNames.map((columnName) => (
                <ExtTableCell
                  sx={{ px: 1, whiteSpace: "nowrap" }}
                  key={columnName}
                >
                  {columnName}
                </ExtTableCell>
              ))}
            </TableRow>
          </TableHead>
        }
        renderRow={(h) => {
          return (
            <TableBorderedRow
              key={h.outHistory.id}
              sx={{
                td: { p: 1 },
              }}
              onClick={() => setSelectedOutHistoryId(h.outHistory.id)}
              selected={selectedOutHistory?.outHistory.id === h.outHistory.id}
              data-testid={h.outHistory.id}
            >
              <ExtTableCell>
                <Radio
                  sx={{ p: 0 }}
                  checked={
                    selectedOutHistory?.outHistory.id === h.outHistory.id
                  }
                />
              </ExtTableCell>
              <ExtTableCell>
                <CardItemNameBox>{h.prize.prizeName}</CardItemNameBox>
              </ExtTableCell>
              <ExtTableCell>{h.outHistory.stock}</ExtTableCell>
              <ExtTableCell>
                {h.outHistory.registeredSeams && (
                  <img
                    src={finishedIcon}
                    style={{ width: 32, height: 32 }}
                    alt="済"
                  />
                )}
              </ExtTableCell>
            </TableBorderedRow>
          )
        }}
      />
    </RadioGroup>
  )
}
