import styled from "@emotion/styled"
import { alpha, Box, TableCell, TableRow } from "@mui/material"

import { theme } from "src/theme"

const borderStyle = "1px solid"

export const TableBorderedRow = styled(TableRow)(() => ({
  td: { borderBottom: `${borderStyle} ${theme.palette.gray[30]}` },
  th: { borderBottom: `${borderStyle} ${theme.palette.gray[30]}` },
}))

export const CardSubtitleCell = styled(TableCell)(() => ({
  color: theme.palette.neutral[700],
  fontSize: 12,
  fontWeight: 600,
  whiteSpace: "nowrap",
  borderBottom: `${borderStyle} ${alpha(theme.palette.neutral[500], 0.1)}`,
}))

export const CardKeyCell = styled(TableCell)(() => ({
  color: theme.palette.text.secondary,
  paddingRight: 0,
  fontSize: 12,
  lineHeight: "20px",
  whiteSpace: "nowrap",
  borderBottom: "none",
}))

export const CardValueCell = styled(TableCell)(() => ({
  fontSize: 12,
  lineHeight: "20px",
  borderBottom: "none",
}))

export const CardItemNameBox = styled(Box)(() => ({
  display: "-webkit-box",
  WebkitLineClamp: "3",
  lineClamp: "3",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  lineHeight: "20px",
}))

export const boldRedStyle = {
  color: `${theme.palette.error.main} !important`,
  fontWeight: "bold",
}

export const tableHeadStyle = {
  color: "#374151",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: 1,
  letterSpacing: 0.5,
}
