import { MaterialArrivalElement } from "src/api/models"

export const getArrivalCount = (arrival: MaterialArrivalElement) => {
  const {
    arrival: { accepted, orderCount },
    inHistory,
  } = arrival

  if (accepted && inHistory !== undefined) {
    return inHistory.stock
  }
  return orderCount
}
