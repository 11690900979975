import { Typography, Button, DialogContent } from "@mui/material"

import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"

type DeleteModalProps = {
  showModal: boolean
  onSubmit: React.MouseEventHandler<HTMLButtonElement>
  onClose: React.MouseEventHandler<HTMLButtonElement>
  isSubmitting: boolean
  message?: string
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  showModal,
  onSubmit,
  onClose,
  isSubmitting,
  message,
}) => {
  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="h1">
            {message ?? "本当に削除しますか？"}
          </Typography>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={onClose}>閉じる</BackButton>
          <Button
            variant="contained"
            color="error"
            fullWidth
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            削除する
          </Button>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}
