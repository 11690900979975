import {
  MaterialInventoryApproval,
  PrizeInventoryApproval,
} from "src/api/models"
import { getJpDateLabel } from "src/utils"

export const approvalTableHeaders = {
  itemName: {
    order: 0,
    label: ["品目名"],
  },
  kind: {
    order: 1,
    label: ["種別"],
  },
  arcadeCd: {
    order: 2,
    label: ["申請店舗CD"],
  },
  application: {
    order: 3,
    label: ["申請理由"],
  },
  appliedAt: {
    order: 4,
    label: ["申請日時"],
  },
  appliedBy: {
    order: 5,
    label: ["申請者"],
  },
  approvedAt: {
    order: 6,
    label: ["承認日時"],
  },
  approvedBy: {
    order: 7,
    label: ["承認者"],
  },
  itemCd: {
    order: 8,
    label: ["品目CD", "(SEAMS)"],
  },
  importedStock: {
    order: 9,
    label: ["データ個数", "(SEAMS)"],
  },
  stock: {
    order: 10,
    label: ["今回実査した個数"],
  },
  payoutStock: {
    order: 11,
    label: ["払い出し個数"],
  },
  consumedPrice: {
    order: 12,
    label: ["今回消費した金額"],
  },
  isInSeams: {
    order: 13,
    label: ["品目の有無", "(SEAMS)"],
  },
  isInGigoNavi: {
    order: 14,
    label: ["品目の有無", "(GiGO NAVI)"],
  },
  differentPrice: {
    order: 15,
    label: ["SEAMSとの差額"],
  },
}

type InventoryApprovalTableKey = keyof typeof approvalTableHeaders

export const approvalTableKeys = Object.entries(approvalTableHeaders)
  .map(([key, value]) => ({ ...value, key: key }))
  .sort((a, b) => a.order - b.order)
  .map((h) => h.key) as InventoryApprovalTableKey[]

type InventoryApprovalLabel = {
  [key in InventoryApprovalTableKey]: string
}

export const getInventoryApprovalLabel = (
  arcadeCd: string | undefined,
  approval: PrizeInventoryApproval | MaterialInventoryApproval,
): InventoryApprovalLabel => ({
  itemName:
    (approval as PrizeInventoryApproval).prize?.prizeName ||
    (approval as MaterialInventoryApproval).material?.materialName ||
    "-",
  itemCd:
    (approval as PrizeInventoryApproval).prize?.prizeCd ||
    (approval as MaterialInventoryApproval).material?.materialCd,
  kind: (approval as PrizeInventoryApproval).prize ? "景品" : "材料",
  arcadeCd: arcadeCd ?? "-",
  importedStock:
    approval.importedStock !== undefined
      ? approval.importedStock.toLocaleString()
      : "-",
  stock: approval.stock !== undefined ? approval.stock.toLocaleString() : "-",
  payoutStock:
    approval.payoutStock !== undefined
      ? (approval.payoutStock * -1).toLocaleString() // 画面仕様上、払出個数はマイナス表示にする
      : "-",
  consumedPrice:
    approval.consumedPrice !== undefined
      ? (approval.consumedPrice * -1).toLocaleString() // 画面仕様上、消費金額はマイナス表示にする
      : "-",
  isInSeams: approval.isInSeams ? "有" : "無",
  isInGigoNavi: approval.isInGigoNavi ? "有" : "無",
  differentPrice:
    approval.differentPrice !== undefined
      ? approval.differentPrice.toLocaleString()
      : "-",
  application: approval.application,
  appliedAt: getJpDateLabel(approval.appliedAt),
  appliedBy: approval.appliedBy ? approval.appliedBy.name : "-",
  approvedAt: getJpDateLabel(approval.approvedAt),
  approvedBy: approval.approvedBy ? approval.approvedBy.name : "-",
})
