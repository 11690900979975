import { Box, Typography, Container, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeToneBooths } from "src/api/prize-tone-booths"
import { getPrizeToneFloorMap } from "src/api/prize-tone-floor-maps"
import { BackButton } from "src/components/atoms/BackButton"
import { FloorMapSalesTab } from "src/components/templates/FloorMapSalesTab"
import { useResource } from "src/hooks/useResource"
import { alertParamsState } from "src/recoil"
import { getToday } from "src/utils"

export type FloorMapPageTab = "sales"

export const useFloorMapData = () => {
  const { arcadeCd } = useParams()
  const alertParams = useRecoilValue(alertParamsState)
  const dateLabel = alertParams.dateLabel
  const floorMap = useResource({
    subject: "フロアマップ情報の取得",
    fetch: arcadeCd
      ? async () => {
          if (dateLabel > getToday()) return undefined
          return await getPrizeToneFloorMap(arcadeCd, { recordedAt: dateLabel })
        }
      : undefined,
    recoilKey: `getFloorMap:${arcadeCd}:${dateLabel}`,
  }).resource?.data.floorMap

  const booths = useResource({
    subject: "ブース情報の取得",
    fetch: arcadeCd
      ? () => getPrizeToneBooths(arcadeCd, { soldOn: dateLabel })
      : undefined,
    recoilKey: `getBooths:${arcadeCd}:${dateLabel}`,
  }).resource?.data.booths

  return { floorMap, booths }
}

export const FloorMap: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Box pb={2}>
            <Typography variant="h1" sx={{ pb: 2 }}>
              フロアマップ
            </Typography>
          </Box>

          <Stack sx={{ pb: 2 }}>
            <FloorMapSalesTab />
          </Stack>

          <Stack sx={{ mt: 2 }}>
            <BackButton onClick={() => navigate(`/arcades/${arcadeCd}/prizes`)}>
              戻る
            </BackButton>
          </Stack>
        </Container>
      </Box>
    </>
  )
}
