import { SetStateAction, useMemo } from "react"

import { Edit } from "@mui/icons-material"
import {
  Typography,
  TableHead,
  Stack,
  IconButton,
  TextField,
  Select,
  MenuItem,
  TableRow,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getAmMachines } from "src/api/am-machines"
import { AmMachineUsageCategoryEnum } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortAmMachines } from "src/domains/amMachinesRepository"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"
import { theme } from "src/theme"

export interface AmMachinesSearchParams {
  amMachineNumber?: string
  isAvailable?: boolean
  usageCategory?: AmMachineUsageCategoryEnum
  amMachineName?: string
}

export const AmMachines = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  return (
    <MainContentLayout
      title="AM機器一覧"
      renderFilter={() => <AmMachinesFilter />}
      renderContent={() => <AmMachinesTable />}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}`)}
    />
  )
}

type AmMachineFilterFormValues = {
  amMachineNumber: string
  amMachineName: string
  isAvailable?: "true" | "false"
  usageCategory?: AmMachineUsageCategoryEnum
}

const defaultSearchParams = {
  amMachineNumber: "",
  amMachineName: "",
  isAvailable: undefined,
  usageCategory: undefined,
}

const AmMachinesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams =
    recoilSearchParams["amMachinesSearchParams"] ?? defaultSearchParams
  const setSearchParams = (params: SetStateAction<AmMachineFilterFormValues>) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      amMachinesSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      formInputs={[
        {
          name: "amMachineNumber",
          label: "AM機器番号",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "amMachineName",
          label: "名称",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "isAvailable",
          label: "有効/無効",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value={undefined} sx={{ height: 36 }}></MenuItem>
              <MenuItem value="true">有効</MenuItem>
              <MenuItem value="false">無効</MenuItem>
            </Select>
          ),
        },
        {
          name: "usageCategory",
          label: "利用区分",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem value={undefined} sx={{ height: 36 }}></MenuItem>
              <MenuItem value={AmMachineUsageCategoryEnum.Prize}>景品</MenuItem>
              <MenuItem value={AmMachineUsageCategoryEnum.Material}>
                材料
              </MenuItem>
              <MenuItem value={AmMachineUsageCategoryEnum.Other}>
                その他
              </MenuItem>
            </Select>
          ),
        },
      ]}
    />
  )
}

const AmMachinesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const searchParams: AmMachineFilterFormValues =
    useRecoilValue(filterAccordionSearchState)["amMachinesSearchParams"] ??
    defaultSearchParams

  const { resource: AmMachinesResource } = useResource({
    subject: "AM機器リストの取得",
    fetch: arcadeCd
      ? () =>
          getAmMachines(arcadeCd, {
            amMachineNumber:
              searchParams.amMachineNumber !== ""
                ? searchParams.amMachineNumber
                : undefined,
            amMachineName:
              searchParams.amMachineName !== ""
                ? searchParams.amMachineName
                : undefined,
            isAvailable: searchParams.isAvailable
              ? searchParams.isAvailable === "true"
                ? true
                : searchParams.isAvailable === "false"
                  ? false
                  : undefined
              : undefined,
            usageCategory: searchParams.usageCategory,
          })
      : undefined,
    recoilKey: `getAmMachines:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const amMachines = useMemo(
    () =>
      sortAmMachines(AmMachinesResource?.data.amMachines || [], {
        sortBy: "phoneticOrderAsc",
      }),
    [AmMachinesResource],
  )
  const onClickEdit = (amMachineNumber: string) => {
    navigate(`/arcades/${arcadeCd}/amMachines/${amMachineNumber}`)
  }

  return (
    <PaginatedTable
      scrollableX
      scrollableY
      noMargin
      items={amMachines}
      stateKey="AmMachinesTable"
      stickyHeader
      header={
        <TableHead sx={{ whiteSpace: "nowrap" }}>
          <TableRow
            sx={{
              th: {
                py: 2,
                textAlign: "center",
              },
            }}
          >
            <ExtTableCell border sticky zIndex={100} fixedWidth={300}>
              AM機器番号
            </ExtTableCell>
            <ExtTableCell border fixedWidth={87}>
              有効/無効
            </ExtTableCell>
            <ExtTableCell border fixedWidth={87}>
              区分
            </ExtTableCell>
            <ExtTableCell border fixedWidth={300}>
              名称
            </ExtTableCell>
            <ExtTableCell border fixedWidth={300}>
              備考
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(amMachine) => {
        const {
          amMachineNumber,
          amMachineName,
          usageCategory,
          isAvailable,
          note,
        } = amMachine

        return (
          <TableRow
            key={amMachineNumber}
            data-testid={amMachineNumber}
            sx={(theme) => ({
              td: {
                p: 1,
                ...(!isAvailable && {
                  background: theme.palette.gray[40],
                }),
              },
            })}
          >
            <ExtTableCell
              border
              sticky
              zIndex={99}
              sx={{
                ...(!isAvailable && {
                  background: theme.palette.gray[40],
                }),
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 1,
                }}
              >
                <Typography variant="caption">{amMachineNumber}</Typography>
                <IconButton onClick={() => onClickEdit(amMachineNumber)}>
                  <Edit color="primary" fontSize="small" />
                </IconButton>
              </Stack>
            </ExtTableCell>
            <ExtTableCell border sx={{ textAlign: "center" }}>
              {isAvailable ? (
                <Typography color="primary.main" variant="subtitle2">
                  有効
                </Typography>
              ) : (
                <Typography color="text.disabled" variant="subtitle2">
                  無効
                </Typography>
              )}
            </ExtTableCell>
            <ExtTableCell border sx={{ textAlign: "center" }}>
              {usageCategory === AmMachineUsageCategoryEnum.Prize ? (
                <Typography color="error.main" variant="subtitle2">
                  景品
                </Typography>
              ) : usageCategory === AmMachineUsageCategoryEnum.Material ? (
                <Typography color="error.main" variant="subtitle2">
                  材料
                </Typography>
              ) : (
                "その他"
              )}
            </ExtTableCell>
            <ExtTableCell border>
              <Typography variant="caption">{amMachineName}</Typography>
            </ExtTableCell>
            <ExtTableCell border>
              <Typography variant="caption">{note}</Typography>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}
