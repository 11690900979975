import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeOutStockFilter } from "src/components/organisms/prizes/InventoryPrizeOutStockFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortOperationStocks } from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import {
  hideEmptyPrizeState,
  inventoryOutStockSearchParamsState,
} from "src/recoil/inventory"
import { theme } from "src/theme"

export const InventoryPrizeOut: React.FC = () => {
  const [hideEmptyPrize, setHideEmptyPrize] =
    useRecoilState(hideEmptyPrizeState)

  return (
    <MainContentLayout
      title="店外出庫"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryPrizeOutStockFilter />
          <FormControlLabel
            checked={hideEmptyPrize}
            onChange={() => setHideEmptyPrize(!hideEmptyPrize)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryPrizeStocksTable />}
    />
  )
}

const InventoryPrizeStocksTable = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const hideEmptyPrize = useRecoilValue(hideEmptyPrizeState)
  const searchParams = useRecoilValue(inventoryOutStockSearchParamsState)

  const { resource } = useResource({
    subject: "在庫検索結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeOperationStocks(arcadeCd, {
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            prizeCd: searchParams.prizeCd,
            storageId: searchParams.storageId,
            shelfId: searchParams.shelfId,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getInventoryStocks:${JSON.stringify(searchParams)}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = resource?.data.stocks || []
    const stocksWithShelfStocks = stocks.filter((stock) =>
      hideEmptyPrize ? stock.shelfStocks.some((s) => s.stock > 0) : true,
    )
    return sortOperationStocks(stocksWithShelfStocks, searchParams)
  }, [hideEmptyPrize, resource, searchParams])

  return (
    <PaginatedTable
      noMargin
      items={filteredStocks}
      stateKey="inventoryOut-inventoryStocksTable"
      renderRow={(stock) => (
        <TableBorderedRow key={stock.prize.prizeCd}>
          <ExtTableCell
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                background: theme.palette.neutral[200],
              },
            }}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/prizes/register/out/details/${stock.prize.prizeCd}`,
              )
            }
          >
            <CardItemNameBox>{stock.prize.prizeName}</CardItemNameBox>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
