import { useEffect, useMemo, useState } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Typography,
  DialogContent,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  FormControl,
} from "@mui/material"
import { useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PrizeBoothShelf,
  PrizeBoothUnit,
  Prize,
  GetPrizePlacementTypeStatusResponseStatusEnum,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import {
  getPrizeOperationStocks,
  putPrizeOperationStocks,
} from "src/api/prize-operation-stocks"
import { getPrizePlacementTypeStatus } from "src/api/prize-placement-statuses"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { isSameBoothUnit } from "src/domains/prizes/boothRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { currentInventoryPeriodState } from "src/recoil"

type PrizeChangeFormInput = {
  placementId: PrizeBoothShelf["id"]
}

interface PrizeChangeModalProps {
  prizeCd: Prize["prizeCd"]
  boothUnit: PrizeBoothUnit
  isPrizeChanged: boolean
  updatePrizeChange: () => Promise<void>
  onClose: () => void
}

export const PrizeChangeModal: React.FC<PrizeChangeModalProps> = ({
  prizeCd,
  boothUnit,
  isPrizeChanged,
  updatePrizeChange,
  onClose,
}: PrizeChangeModalProps) => {
  const { arcadeCd } = useParams()
  const [addInventoryStockChecked, setAddInventoryStockChecked] =
    useState(false)
  const { resource: floorMapPointsResource } = useResource({
    subject: "棚卸ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  })
  const floorMapPoint = floorMapPointsResource?.data.floorMapPoints
    .filter((point) => point.boothShelf.isAvailable)
    .find((point) =>
      point.boothNames.find((pointBoothUnit) =>
        isSameBoothUnit(pointBoothUnit, boothUnit),
      ),
    )

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const { resource: stocksResource } = useResource({
    subject: "在庫情報の取得",
    fetch:
      arcadeCd && currentInventoryPeriod
        ? async () => {
            if (!floorMapPoint) return
            return await getPrizeOperationStocks(arcadeCd, {
              prizeCd,
              inventoryFloorMapPointId: Number(floorMapPoint.id),
            })
          }
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${prizeCd}:${floorMapPoint?.id}`,
  })

  const inventoryStockExists = useMemo(() => {
    const boothStocks =
      stocksResource?.data.stocks.flatMap((stock) => stock.boothStocks) || []
    return boothStocks.some((boothStock) => boothStock.prizeCd === prizeCd)
  }, [stocksResource, prizeCd])

  const { resource: placementTypeStatusResource } = useResource({
    subject: "棚卸ロック状態の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? () =>
            getPrizePlacementTypeStatus(
              arcadeCd,
              PlacementType.InBooth,
              currentPrizeExecutionPeriod.id,
            )
        : undefined,
    recoilKey: `getPlacementTypeStatus:${arcadeCd}:${
      PlacementType.InBooth
    }:${JSON.stringify(currentPrizeExecutionPeriod)}`,
  })
  const inBoothStatus = placementTypeStatusResource?.data.status
  const isLocked =
    inBoothStatus === GetPrizePlacementTypeStatusResponseStatusEnum.Locked

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<PrizeChangeFormInput>()

  const placementId = useWatch({ control, name: "placementId" })

  useEffect(() => {
    reset({
      placementId: floorMapPoint?.boothShelf.id,
    })
  }, [reset, placementId, floorMapPoint])

  const { submitPromises } = useSubmitting()
  const onSubmit = async ({ placementId }: PrizeChangeFormInput) => {
    if (!arcadeCd) return
    if (addInventoryStockChecked && !placementId) return

    await submitPromises([
      {
        subject: "更新",
        showSuccessMessage: true,
        promise: async () => {
          if (!isPrizeChanged && addInventoryStockChecked) {
            const request = {
              prizeCd,
              placement: PlacementType.InBooth,
              placementId,
            }
            await putPrizeOperationStocks(arcadeCd, request)
          }
          await updatePrizeChange()
          onClose()
        },
      },
    ])
  }

  const isPrizeChangedInitValue = useState(isPrizeChanged)[0]

  if (isLocked) {
    return (
      <Box sx={{ p: 1 }}>
        <DialogTitleWithClose onClose={onClose} color="error.main">
          <Typography variant="h1">
            棚卸ロック中のため入れ替え処理はできません
          </Typography>
        </DialogTitleWithClose>

        <DialogContent>棚卸ロック解除してから実施してください</DialogContent>

        <CustomDialogActions>
          <Button color="primary" variant="contained" onClick={() => onClose()}>
            OK
          </Button>
        </CustomDialogActions>
      </Box>
    )
  }

  return (
    <Box
      component="form"
      sx={{ p: 1, position: "relative" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h1">
          {isPrizeChangedInitValue
            ? "入れ替え処理を戻しますか？"
            : "入れ替え処理を実施しますか？"}
        </Typography>
      </DialogTitleWithClose>

      {!isPrizeChangedInitValue && (
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12}>
              <Typography variant="body1"></Typography>
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={!addInventoryStockChecked}
                      onClick={() => setAddInventoryStockChecked(false)}
                    />
                  }
                  label="ブースの景品を入れ替え"
                />

                <FormControl
                  fullWidth
                  error={!floorMapPoint || inventoryStockExists}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={addInventoryStockChecked}
                        onClick={() => setAddInventoryStockChecked(true)}
                      />
                    }
                    label="ブースに景品を追加"
                    disabled={!floorMapPoint || !!inventoryStockExists}
                  />
                  {!floorMapPoint && (
                    <FormHelperText sx={{ m: 0, ml: 4 }}>
                      棚卸ブースが見つかりません
                    </FormHelperText>
                  )}
                  {inventoryStockExists && (
                    <FormHelperText sx={{ m: 0, ml: 4 }}>
                      すでに景品追加済です
                    </FormHelperText>
                  )}
                </FormControl>
              </RadioGroup>
            </Grid>

            {addInventoryStockChecked &&
              (floorMapPoint ? (
                <>
                  <Grid item xs={4}>
                    ブース
                  </Grid>
                  <Grid item xs={8} sx={{ textAlign: "right" }}>
                    <strong>{floorMapPoint.name}</strong>
                  </Grid>

                  <Grid item xs={8}>
                    棚卸在庫追加数
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <strong>0</strong> 個
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  該当するプライズ機内ブースが見つかりませんでした
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      )}

      <CustomDialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          // NOTE: 送信成功後の state 更新中は disabled にする
          loading={isSubmitting || isPrizeChanged !== isPrizeChangedInitValue}
        >
          実行
        </LoadingButton>
      </CustomDialogActions>
    </Box>
  )
}
