/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * タブ表示用のグループ
 */
export type MaterialInventoryDifferenceGroupEnum =
  (typeof MaterialInventoryDifferenceGroupEnum)[keyof typeof MaterialInventoryDifferenceGroupEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialInventoryDifferenceGroupEnum = {
  NoDifference: "no_difference",
  NotInGigoNavi: "not_in_gigo_navi",
  DiffPriceOver: "diff_price_over",
} as const
