import { Box, Card, CardContent, Typography, List, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState } from "src/recoil"

export const InventoryTop: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const arcade = useRecoilValue(currentArcadeState)
  const { isLocationTest } = arcade || {}
  const { isAdmin } = useUserRole()

  return (
    <MainContentLayout
      title="棚卸業務"
      renderContent={() => (
        <Stack gap={2}>
          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                循環棚卸オーダー取り込み
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/seams/import`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  循環棚卸オーダー取り込み
                  <Typography variant="caption" sx={{ color: "text.primary" }}>
                    循環棚卸オーダー＝前回在庫数＋当期間入荷数
                  </Typography>
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                景品
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/execute/storage`}
                >
                  <Typography>在庫カウント(プライズ機[外])</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/execute/onBooth`}
                >
                  <Typography>在庫カウント(プライズ機[上])</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/execute/inBooth`}
                >
                  <Typography>在庫カウント(プライズ機[内])</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/differences`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  景品差分確認
                  <Typography variant="caption" sx={{ color: "text.primary" }}>
                    循環棚卸オーダーと棚卸の差分確認することで、払い出しされた景品代を算出
                  </Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/confirmation`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  景品棚卸確定
                  <Typography variant="caption" sx={{ color: "text.primary" }}>
                    現在の景品棚卸期間を確定
                  </Typography>
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                材料
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/execute/storage`}
                >
                  <Typography>在庫カウント(保管場所)</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/execute/machine`}
                >
                  <Typography>在庫カウント(材料機械[内])</Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/differences`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  材料差分確認
                  <Typography variant="caption" sx={{ color: "text.primary" }}>
                    循環棚卸オーダーと棚卸の差分確認することで、払い出しされた材料代を算出
                  </Typography>
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/confirmation`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  材料棚卸確定
                  <Typography variant="caption" sx={{ color: "text.primary" }}>
                    現在の材料棚卸期間を確定
                  </Typography>
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                結果出力
              </Typography>
              <List>
                <MenuButton to={`/arcades/${arcadeCd}/inventory/seams/output`}>
                  結果出力(SEAMS棚卸データ連携)
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                設定
              </Typography>
              <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
                景品
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/groups/storage`}
                >
                  棚卸グループ設定(プライズ機[外])
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/groups/onBooth`}
                >
                  棚卸グループ設定(プライズ機[上])
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/prizes/groups/inBooth`}
                >
                  棚卸グループ設定(プライズ機[内])
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/prizes/settings/storages`}
                >
                  保管場所一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/prizes/placements`}>
                  棚・ブース一覧
                </MenuButton>
              </List>
              <Typography variant="h3" sx={{ mt: 2, mb: 1 }}>
                材料
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/groups/storage`}
                >
                  棚卸グループ設定(保管場所)
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/materials/groups/machine`}
                >
                  棚卸グループ設定(材料機械[内])
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/settings/storages`}
                >
                  保管場所一覧
                </MenuButton>
                {!isLocationTest && (
                  <MenuButton
                    to={`/arcades/${arcadeCd}/materials/settings/amMachines`}
                  >
                    AM機器一覧
                  </MenuButton>
                )}
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/settings/machines`}
                >
                  材料機械一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/materials/placements`}>
                  棚・材料機械一覧
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                保管用CSV
              </Typography>
              <Typography
                variant="caption"
                sx={{ mb: 2 }}
                color="text.secondary"
              >
                以下のCSVファイルはGiGO
                NAVI内の保管用ファイルとなりますので印刷などは不要です。
                <br />
                会社から求められた場合や指示があった時、各種CSVを出力します。
              </Typography>

              <Box sx={{ py: 2 }}>
                <Typography variant="h3">
                  結果出力(SEAMS棚卸データ連携)
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ pb: 1 }}
                  color="text.secondary"
                >
                  結果出力で出力されるCSVファイルと同じものです。
                  <br />
                  結果出力をせずにGiGO NAVIを確定してしまった場合に使用します。
                </Typography>
              </Box>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/output`}
                >
                  過去棚卸結果CSV
                </MenuButton>
              </List>

              {isLocationTest && isAdmin && (
                <>
                  <Box sx={{ py: 2 }}>
                    <Typography variant="h3">棚卸カウント無し用CSV</Typography>
                    <Typography
                      variant="caption"
                      sx={{ pt: 1 }}
                      color="text.secondary"
                    >
                      Toneの代わりにつかうカウント無しファイルの払出情報です。
                      <br />
                      カウント無しファイル内の説明を読み、対応してください。
                    </Typography>
                  </Box>
                  <List>
                    <MenuButton
                      to={`/arcades/${arcadeCd}/prizes/batch/payouts/output`}
                    >
                      カウント無し用CSVダウンロード
                    </MenuButton>
                  </List>
                </>
              )}

              <Box sx={{ py: 2 }}>
                <Typography variant="h3">全保管場所_棚卸済証明CSV</Typography>
                <Typography
                  variant="caption"
                  sx={{ pb: 1 }}
                  color="text.secondary"
                >
                  全ての保管場所が棚卸時に網羅された事を証明します。
                  <br />
                  このファイルは会社から求められた時のみ提出します。
                </Typography>
              </Box>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/prizes/placement-statuses`}
                >
                  景品全保管場所_棚卸済証明CSV
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/materials/placement-statuses`}
                >
                  材料全保管場所_棚卸済証明CSV
                </MenuButton>
              </List>

              <Box sx={{ py: 2 }}>
                <Typography variant="h3">店内総在庫証明CSV</Typography>
                <Typography
                  variant="caption"
                  sx={{ pt: 1 }}
                  color="text.secondary"
                >
                  全ての保管場所単位別のカウント結果を表示します。
                  <br />
                  GiGO NAVIの確定ボタンで自動生成されます。
                  <br />
                  このファイルは会社から求められた時のみ提出します。
                </Typography>
              </Box>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/prizes/execute`}
                >
                  景品店内総在庫証明CSV
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/materials/execute`}
                >
                  材料店内総在庫証明CSV
                </MenuButton>
              </List>

              <Box sx={{ py: 2 }}>
                <Typography variant="h3">
                  戻しが10万円以上の差分の申請一覧
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ pt: 1 }}
                  color="text.secondary"
                >
                  過去の戻しが10万円以上になった差分の申請と承認を表示します。
                  <br />
                  一覧画面からCSVファイルをダウンロードすることが可能です。
                  <br />
                  このファイルは会社から求められた時のみ提出します。
                </Typography>
              </Box>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/inventory/history/approvals`}
                >
                  戻しが10万円以上の差分の申請一覧
                </MenuButton>
              </List>
            </CardContent>
          </Card>
        </Stack>
      )}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}`)}
    />
  )
}
