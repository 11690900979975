import {
  PrizeInventoryDifference,
  PrizeInventoryDifferenceGroupEnum,
} from "src/api/models"
import {
  PlacementType,
  PlacementValidation,
} from "src/domains/prizes/placementStatusRepository"

export const placementTypes = [
  PlacementType.Storage,
  PlacementType.InBooth,
  PlacementType.OnBooth,
]

export const placementTypeNames = {
  [PlacementType.Storage]: "プライズ機外",
  [PlacementType.InBooth]: "プライズ機内",
  [PlacementType.OnBooth]: "プライズ機上",
}

export const placementValidations = [
  PlacementValidation.Valid,
  PlacementValidation.Invalid,
  PlacementValidation.All,
]

export const placementValidationNames = {
  [PlacementValidation.Valid]: "有効のみ",
  [PlacementValidation.Invalid]: "無効のみ",
  [PlacementValidation.All]: "すべて",
}

export const inventoryExecuteDetailsFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
  phoneticIpOrderAsc: "IP順",
  phoneticIpOrderDesc: "IP順（降順）",
} as const

const groupEnum = PrizeInventoryDifferenceGroupEnum
export const differenceGroups = [
  groupEnum.NoDifference,
  groupEnum.InvalidPrizeCd,
  groupEnum.NotInGigoNavi,
  groupEnum.DiffPriceOver,
]
export const differenceGroupNames = {
  [groupEnum.NoDifference]: "差分なし",
  [groupEnum.InvalidPrizeCd]: "景品CD間違い",
  [groupEnum.NotInGigoNavi]: "GiGO NAVIに存在しない",
  [groupEnum.DiffPriceOver]: "戻しが10万円を超えるもの",
}

export const prizeDifferenceTableHeaders = {
  prizeCd: {
    order: 0,
    label: ["景品CD", "(GiGO NAVI)"],
  },
  prizeCdsInSeams: {
    order: 1,
    label: ["景品CD", "(SEAMS)"],
  },
  prizeName: {
    order: 2,
    label: ["景品名"],
  },
  importedStock: {
    order: 3,
    label: ["データ個数", "(SEAMS)"],
  },
  stock: {
    order: 4,
    label: ["今回実査した個数"],
  },
  payoutStock: {
    order: 5,
    label: ["払い出し個数"],
  },
  consumedPrice: {
    order: 6,
    label: ["今回消費した金額"],
  },
  isInSeams: {
    order: 7,
    label: ["景品の有無", "(SEAMS)"],
  },
  isInGigoNavi: {
    order: 8,
    label: ["景品の有無", "(GiGO NAVI)"],
  },
  differentPrice: {
    order: 9,
    label: ["SEAMSとの差額"],
  },
  isApproved: {
    order: 10,
    label: ["ステータス"],
  },
  approve: {
    order: 11,
    label: ["承認"],
  },
  application: {
    order: 12,
    label: ["理由"],
  },
}

type PrizeDifferenceTableKey = keyof typeof prizeDifferenceTableHeaders

export const prizeDifferenceCsvKeys = Object.entries(
  prizeDifferenceTableHeaders,
)
  .map(([key, value]) => ({ ...value, key: key }))
  .filter((h) => !["isApproved", "approve", "application"].includes(h.key))
  .sort((a, b) => a.order - b.order)
  .map((h) => h.key) as PrizeDifferenceTableKey[]

type PrizeDifferenceLabel = {
  [key in Exclude<PrizeDifferenceTableKey, "prizeCdsInSeams">]: string
} & {
  prizeCdsInSeams: string[]
}

export const getPrizeDifferenceLabel = (
  difference: PrizeInventoryDifference | undefined,
): PrizeDifferenceLabel => {
  if (difference === undefined) {
    return {
      prizeCd: "-",
      prizeCdsInSeams: ["-"],
      prizeName: "-",
      importedStock: "-",
      stock: "-",
      payoutStock: "-",
      consumedPrice: "-",
      isInSeams: "-",
      isInGigoNavi: "-",
      differentPrice: "-",
      isApproved: "-",
      approve: "-",
      application: "-",
    }
  }
  return {
    prizeCd: difference.prizeCd || "-",
    prizeCdsInSeams:
      difference.prizeCdsInSeams.length > 0
        ? difference.prizeCdsInSeams
        : ["-"],
    prizeName: difference.prizeName,
    importedStock:
      difference.importedStock !== undefined
        ? difference.importedStock.toLocaleString()
        : "-",
    stock:
      difference.stock !== undefined ? difference.stock.toLocaleString() : "-",
    payoutStock:
      difference.payoutStock !== undefined
        ? (difference.payoutStock * -1).toLocaleString() // 画面仕様上、払出個数はマイナス表示にする
        : "-",
    consumedPrice:
      difference.consumedPrice !== undefined
        ? (difference.consumedPrice * -1).toLocaleString() // 画面仕様上、消費金額はマイナス表示にする
        : "-",
    isInSeams: difference.isInSeams ? "有" : "無",
    isInGigoNavi: difference.isInGigoNavi ? "有" : "無",
    differentPrice:
      difference.differentPrice !== undefined
        ? difference.differentPrice.toLocaleString()
        : "-",
    isApproved:
      difference.isApproved !== undefined
        ? difference.isApproved
          ? "承認済"
          : "未承認"
        : "-",
    approve: "-", // 画面仕様上、ボタンが表示される
    application:
      difference.isApproved !== undefined
        ? difference.approvalApplication !== undefined
          ? difference.approvalApplication
          : "未入力"
        : "-",
  }
}

export const inventoryDifferenceFilterSortByLabels = {
  payoutStockGreater: `${prizeDifferenceTableHeaders.payoutStock.label.join("")} (多い順)`,
  payoutStockLess: `${prizeDifferenceTableHeaders.payoutStock.label.join("")} (少ない順)`,
  consumedPriceGreater: `${prizeDifferenceTableHeaders.consumedPrice.label.join(
    "",
  )} (多い順)`,
  consumedPriceLess: `${prizeDifferenceTableHeaders.consumedPrice.label.join(
    "",
  )} (少ない順)`,
  differentPriceGreater: `${prizeDifferenceTableHeaders.differentPrice.label.join(
    "",
  )} (多い順)`,
  differentPriceLess: `${prizeDifferenceTableHeaders.differentPrice.label.join(
    "",
  )} (少ない順)`,
}
