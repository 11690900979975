import { yupResolver } from "@hookform/resolvers/yup"
import {
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  Box,
  Card,
  Stack,
} from "@mui/material"
import {
  FieldArrayWithId,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { putMaterialOperationOut } from "src/api/material-operation-out"
import {
  Material,
  MaterialOperationStock,
  MaterialStorage,
  MaterialShelf,
} from "src/api/models"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { tableHeadStyle } from "src/components/molecules/CardTableCells"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getToday } from "src/utils"

export interface MaterialOutResult {
  material: Material
  storage: MaterialStorage
  shelf: MaterialShelf
  stockBefore: number
  stock: number
  note: string
}

interface MaterialOperationOutFormShelf {
  shelfId: number
  stock: number
  note?: string
}

interface MaterialOperationOutFormInput {
  shelves: MaterialOperationOutFormShelf[]
}

interface MaterialOperationOutFormProps {
  stock: MaterialOperationStock
  onFinish: (results: MaterialOutResult[]) => void
}

export const InventoryMaterialOutForm: React.FC<
  MaterialOperationOutFormProps
> = ({ stock, onFinish }: MaterialOperationOutFormProps) => {
  const { arcadeCd } = useParams()
  const { material } = stock
  const shelfStocks = stock.shelfStocks

  const validationSchema = Yup.object({
    shelves: Yup.array(
      Yup.object({
        stock: Yup.number()
          .typeError("数値を入力してください")
          .min(0, "0以上の値を入力してください")
          .required("必須です"),
        shelfId: Yup.number().required("必須です"),
        note: Yup.string(),
      }).required("必須です"),
    ).required("必須です"),
    note: Yup.string(),
  })

  const useFormReturn = useForm<MaterialOperationOutFormInput>({
    resolver: yupResolver<MaterialOperationOutFormInput>(validationSchema),
    defaultValues: {
      shelves: shelfStocks
        .filter((s) => s.shelfStock.stock > 0)
        .map(({ shelf }) => ({ shelfId: shelf.id, stock: 0, note: "" })),
    },
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useFormReturn
  const { fields } = useFieldArray({
    control,
    name: "shelves",
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<MaterialOperationOutFormInput> = async (
    data,
  ) => {
    const { shelves } = data
    const isAllZero = !shelves.some((shelf) => shelf.stock > 0)
    if (isAllZero) {
      return
    }

    const request = {
      materials: [
        {
          materialCd: stock.material.materialCd,
          shelves: shelves
            .filter((s) => s.stock > 0)
            .map((s) => ({ ...s, note: s.note || "" })),
        },
      ],
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "店外出庫",
          showSuccessMessage: true,
          promise: async () => {
            await putMaterialOperationOut(arcadeCd, getToday(), request)
            const results = shelfStocks.map((shelfStock) => {
              const shelf = shelves.find(
                (s) => s.shelfId == shelfStock.shelf.id,
              )
              return {
                material: stock.material,
                storage: shelfStock.storage,
                shelf: shelfStock.shelf,
                stockBefore: shelfStock.shelfStock.stock,
                stock: shelf?.stock || 0,
                note: shelf?.note || "",
              }
            })
            onFinish(results)
          },
        },
      ]))
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ py: 2, px: 3 }}>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            rowSpacing={2}
          >
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography variant="h1">{material.materialName}</Typography>
            </Grid>

            <Grid item xs={5} sx={tableHeadStyle} />
            <Grid item xs={2} sx={tableHeadStyle}>
              在庫
            </Grid>
            <Grid item xs={3} sx={tableHeadStyle}>
              出庫数
            </Grid>
            <Grid item xs={2} sx={tableHeadStyle}>
              出庫後
            </Grid>

            {fields.map((field, index) => (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <InventoryMaterialOutFormShelf
                  {...{ stock, useFormReturn, field, index }}
                  key={field.id}
                />
              </>
            ))}
          </Grid>
        </Card>

        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting}
          fullWidth
        >
          店外に出庫する
        </Button>
      </form>
    </Box>
  )
}

interface MaterialOperationOutFormShelfProps {
  stock: MaterialOperationStock
  useFormReturn: UseFormReturn<MaterialOperationOutFormInput, object>
  field: FieldArrayWithId<MaterialOperationOutFormInput, "shelves", "id">
  index: number
}

const InventoryMaterialOutFormShelf: React.FC<
  MaterialOperationOutFormShelfProps
> = ({
  stock,
  useFormReturn,
  field,
  index,
}: MaterialOperationOutFormShelfProps) => {
  const {
    formState: { errors },
    control,
    register,
  } = useFormReturn
  const shelfErrors = (errors.shelves && errors.shelves[index]) || {}
  const shelfStock = stock.shelfStocks.find((s) => s.shelf.id === field.shelfId)
  const { storage, shelf } = shelfStock || {}
  const value = useWatch({
    name: `shelves.${index}`,
    control,
  })

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ display: "flex", alignItems: "center" }}
      rowSpacing={2}
    >
      <Grid item xs={5} pr={1}>
        {storage?.name} {shelf?.name}
      </Grid>
      <Grid item xs={2}>
        <strong>{shelfStock?.shelfStock.stock}</strong> 個
      </Grid>
      <Grid item xs={3}>
        <TextField
          error={"stock" in shelfErrors}
          helperText={shelfErrors.stock?.message || ""}
          {...register(`shelves.${index}.stock`)}
          inputProps={{ inputMode: "numeric" }}
          sx={{ mr: 1 }}
        />
      </Grid>
      <Grid item xs={2}>
        <strong>
          {(shelfStock?.shelfStock.stock || 0) - (Number(value.stock) || 0)}
        </strong>{" "}
        個
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="備考"
          fullWidth
          error={"note" in shelfErrors}
          helperText={shelfErrors.note?.message}
          {...register(`shelves.${index}.note`)}
        />
      </Grid>
    </Grid>
  )
}

interface MaterialOperationOutConfirmProps {
  results: MaterialOutResult[]
}

export const InventoryMaterialOutConfirm: React.FC<
  MaterialOperationOutConfirmProps
> = ({ results }: MaterialOperationOutConfirmProps) => {
  const { material } = (results.length >= 1 && results[0]) || {}

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack sx={{ mb: 2 }}>
        <AlertCaptionCard label="下記の通り出庫しました" />
      </Stack>
      <Card sx={{ py: 2, px: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h1">{material?.materialName || ""}</Typography>
          </Grid>

          <Grid item xs={5} sx={tableHeadStyle} />
          <Grid item xs={2} sx={tableHeadStyle}>
            出庫前
          </Grid>
          <Grid item xs={3} sx={tableHeadStyle}>
            出庫数
          </Grid>
          <Grid item xs={2} sx={tableHeadStyle}>
            出庫後
          </Grid>

          {results.map((result) => (
            <>
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>

              <Grid item xs={5} pr={1}>
                {result.storage.name} {result.shelf.name}
              </Grid>
              <Grid item xs={2}>
                <strong>{result.stockBefore}</strong> 個
              </Grid>
              <Grid item xs={2}>
                <strong>{result.stock}</strong> 個
              </Grid>
              <Grid item xs={2}>
                <strong>{result.stockBefore - result.stock}</strong> 個
              </Grid>
              <Grid item xs={12}>
                {result.note}
              </Grid>
            </>
          ))}
        </Grid>
      </Card>
    </Box>
  )
}
