import { useMemo } from "react"

import { alpha } from "@mui/material"
import { useParams, useNavigate } from "react-router-dom"

import {
  PrizeFloorMapPoint,
  PrizePlacementStatus,
  PrizePlacementStatusExecutionStatusEnum,
} from "src/api/models"
import emptyIcon from "src/assets/icon_empty_transparent.png"
import finishedIcon from "src/assets/icon_finished_transparent.png"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
  FloorMapPointNameBox,
} from "src/components/organisms/FloorMapBox"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import {
  convertToFloorMapPoint,
  getInventoryGroupByPlacementType,
  getInventoryGroupColorByPlacementType,
  InventoryFloorMapColor,
  isFloorMapPointAvailable,
  PrizeInventoryFloorMapPoint,
} from "src/domains/prizes/inventoryFloorMapRepository"
import {
  PlacementType,
  convertPrizePlacementTypeToURLPath,
} from "src/domains/prizes/placementStatusRepository"
import { useFloorMapColors } from "src/hooks/useFloorMapColors"
import { useInventoryExecuteBoothFloorMap } from "src/hooks/useInventoryExecuteBoothFloorMap"
import { theme } from "src/theme"

type InventoryExecuteBoothFloorMapProps = {
  placementType: Exclude<PlacementType, "storage">
}

export const InventoryPrizeExecuteBoothFloorMap: React.FC<
  InventoryExecuteBoothFloorMapProps
> = ({ placementType }) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const { floorMapPoints, placementStatusesPerPointId } =
    useInventoryExecuteBoothFloorMap({
      placementType,
    })
  const { floorMapColors } = useFloorMapColors()

  const onClickPoint = (point: BaseFloorMapPoint) => {
    const { id, boothShelf, onBoothShelf } =
      point as PrizeInventoryFloorMapPoint
    const { isAvailable } =
      (placementType === PlacementType.InBooth ? boothShelf : onBoothShelf) ||
      {}
    if (!isAvailable) return

    navigate(
      `/arcades/${arcadeCd}/inventory/prizes/execute/${convertPrizePlacementTypeToURLPath(
        placementType,
      )}/${id}`,
    )
  }

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <InventoryPrizeExecuteBoothFloorMapPointBox
      key={point.id}
      {...{
        point,
        onClickPoint,
        floorMapPoints,
        floorMapColors,
        placementType,
        placementStatusesPerPointId,
      }}
    />
  )

  return (
    <FloorMapBox
      {...{
        floorMapPoints: floorMapPoints.map((p) => convertToFloorMapPoint(p)),
        onClickPoint,
        getFloorMapPointBox,
      }}
    />
  )
}

interface InventoryExecuteBoothFloorMapPointBoxProps
  extends FloorMapPointBoxProps {
  floorMapPoints: PrizeFloorMapPoint[]
  floorMapColors: InventoryFloorMapColor[]
  placementType: Exclude<PlacementType, "storage">
  placementStatusesPerPointId: {
    [pointId: number]: PrizePlacementStatus | undefined
  }
}

export const InventoryPrizeExecuteBoothFloorMapPointBox: React.FC<
  InventoryExecuteBoothFloorMapPointBoxProps
> = ({
  point,
  onClickPoint = () => undefined,
  floorMapPoints,
  floorMapColors,
  placementType,
  placementStatusesPerPointId,
}: InventoryExecuteBoothFloorMapPointBoxProps) => {
  const placementStatus = placementStatusesPerPointId[point.id]
  const { Executed, Empty } = PrizePlacementStatusExecutionStatusEnum
  const isPointExecuted = placementStatus?.executionStatus === Executed
  const isPointEmpty = placementStatus?.executionStatus === Empty

  const isInBooth = placementType === PlacementType.InBooth
  const isAvailable = useMemo(
    () =>
      isFloorMapPointAvailable(isInBooth, point as PrizeInventoryFloorMapPoint),
    [isInBooth, point],
  )

  const backgroundColor = useMemo(() => {
    if (!isAvailable) {
      return alpha(theme.palette.text.disabled, 0.7)
    }

    // NOTE: 棚卸ツール以外と共用の FloorMapPoint 型は棚情報を持っていないので、state から取得
    const { id: groupId } =
      getInventoryGroupByPlacementType(
        point as PrizeInventoryFloorMapPoint,
        placementType,
      ) ?? {}
    const color =
      groupId &&
      getInventoryGroupColorByPlacementType({
        floorMapPoints,
        floorMapColors,
        groupId,
        placementType,
      })

    if (color) {
      return alpha(color, 0.7)
    }
    return "transparent"
  }, [isAvailable, floorMapColors, floorMapPoints, placementType, point])

  return (
    <FloorMapPointBox
      {...{
        point,
        onClickPoint,
      }}
      sx={{
        backgroundColor,
        cursor: isAvailable ? "pointer" : "default",
      }}
    >
      {isPointExecuted && (
        <img
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            padding: "5%",
          }}
          src={finishedIcon}
          alt="済"
        />
      )}
      {isPointEmpty && (
        <img
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            padding: "5%",
          }}
          src={emptyIcon}
          alt="空"
        />
      )}
      <FloorMapPointNameBox {...{ point }} />
    </FloorMapPointBox>
  )
}
