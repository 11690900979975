/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialOperationInParams,
  GetMaterialOperationInResponse,
  PatchMaterialOperationInRequest,
  PutMaterialOperationInRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】検収済みの材料リストを取得
 */
export const getMaterialOperationIn = (
  arcadeCd: string,
  params: GetMaterialOperationInParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialOperationInResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/in`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】着荷した材料を検収・登録
 */
export const putMaterialOperationIn = (
  arcadeCd: string,
  date: string,
  putMaterialOperationInRequest: PutMaterialOperationInRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/in/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationInRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】検収した材料の SEAMS データ処理状態フラグを更新
 */
export const patchMaterialOperationIn = (
  arcadeCd: string,
  date: string,
  materialCd: string,
  patchMaterialOperationInRequest: PatchMaterialOperationInRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/in/${date}/${materialCd}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialOperationInRequest,
    },
    options,
  )
}
export type GetMaterialOperationInResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialOperationIn>>
>
export type PutMaterialOperationInResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationIn>>
>
export type PatchMaterialOperationInResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialOperationIn>>
>
