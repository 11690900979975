/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  PatchPrizeShelvesOrderRequest,
  PutPrizeShelvesRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚を追加、変更
 */
export const putPrizeShelf = (
  arcadeCd: string,
  storageId: number,
  putPrizeShelvesRequest: PutPrizeShelvesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages/${storageId}/shelves`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeShelvesRequest,
    },
    options,
  )
}
/**
 * @summary 棚を削除
 */
export const deletePrizeShelf = (
  arcadeCd: string,
  storageId: number,
  shelfId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages/${storageId}/shelves/${shelfId}`,
      method: "DELETE",
    },
    options,
  )
}
/**
 * @summary 棚の並び替え
 */
export const patchPrizeShelvesOrder = (
  arcadeCd: string,
  storageId: number,
  patchPrizeShelvesOrderRequest: PatchPrizeShelvesOrderRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/storages/${storageId}/shelves/order`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeShelvesOrderRequest,
    },
    options,
  )
}
export type PutPrizeShelfResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeShelf>>
>
export type DeletePrizeShelfResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeShelf>>
>
export type PatchPrizeShelvesOrderResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeShelvesOrder>>
>
