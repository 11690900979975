import { useEffect, useState } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Box,
  CircularProgress,
  Button,
  DialogContent,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"

import {
  Prize,
  PrizeBoothShelf,
  PrizeFloorMapPoint,
  PrizeOnBoothShelf,
  PutPrizeOperationStocksRequest,
  PrizeShelf,
  PrizeStorage,
  PrizeStorageElement,
} from "src/api/models"
import { putPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import {
  getPlacementShelfOrBoothLabel,
  PlacementType,
} from "src/domains/prizes/placementStatusRepository"
import { useLoading } from "src/hooks/useLoading"

export interface SelectedPlacementIds {
  storageId?: PrizeStorage["id"]
  placementId:
    | PrizeShelf["id"]
    | PrizeBoothShelf["id"]
    | PrizeOnBoothShelf["id"]
}

export type InventoryPrizeAddModalFormInput = Partial<SelectedPlacementIds>

interface InventoryPrizeAddModalProps {
  showModal: boolean
  prize: Prize
  onClose: () => void
  onFinish?: () => void
  placementType: PlacementType
  storages?: PrizeStorageElement[]
  floorMapPoints?: PrizeFloorMapPoint[]
  selectedStorageId?: number | null
  selectedPlacementId?: number | null
}

export const InventoryPrizeAddModal: React.FC<InventoryPrizeAddModalProps> = ({
  showModal,
  prize,
  onClose,
  onFinish = () => undefined,
  placementType,
  storages,
  floorMapPoints = [],
  selectedStorageId = null,
  selectedPlacementId = null,
}) => {
  const { arcadeCd } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    control,
    getFieldState,
    resetField,
    setValue,
  } = useForm<InventoryPrizeAddModalFormInput>()

  const storageId = useWatch({ control, name: "storageId" })

  const availableShelves =
    storages
      ?.find((storage) => storage.storage.id === storageId)
      ?.shelves?.filter((shelf) => shelf.isAvailable) || []

  const availableFloorMapPoints = floorMapPoints.filter((point) => {
    switch (placementType) {
      case PlacementType.InBooth:
        return point.boothShelf.isAvailable
      case PlacementType.OnBooth:
        return point.onBoothShelf.isAvailable
      default:
        throw new Error()
    }
  })

  useEffect(() => {
    // NOTE: 棚・ブースの選択が更新された場合は強制する
    selectedStorageId && setValue("storageId", selectedStorageId)
    selectedPlacementId && setValue("placementId", selectedPlacementId)
  }, [selectedStorageId, selectedPlacementId, setValue])

  useEffect(() => {
    // NOTE: 保管場所が選択された場合に棚をリセットする
    getFieldState("storageId").isDirty && resetField("placementId")
  }, [control, getFieldState, resetField, setValue, storageId])

  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmit = async ({ placementId }: InventoryPrizeAddModalFormInput) => {
    if (!arcadeCd) return
    if (!placementId) return

    const request: PutPrizeOperationStocksRequest = {
      prizeCd: prize.prizeCd,
      placement: placementType,
      placementId,
    }
    submitPromises([
      {
        subject: "登録",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeOperationStocks(arcadeCd, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">
            以下の景品を{getPlacementShelfOrBoothLabel(placementType)}
            に登録しますか？
          </Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Box
            sx={{
              maxWidth: "80%",
              my: 1,
              mx: "auto",
            }}
          >
            <PrizeImageBox
              prizeCd={prize.prizeCd}
              alt={prize.prizeName}
              noImageSize="large"
            />
          </Box>

          <List sx={{ backgroundColor: "background.default", p: 0 }}>
            {isSubmitting && (
              <ListItem>
                <CircularProgress
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "auto",
                  }}
                />
              </ListItem>
            )}
            {!!storages && (
              <ListItem sx={{ display: "flex", flexDirection: "column" }}>
                <Box width="100%">
                  <Typography variant="body2" color="text.secondary">
                    保管場所名
                  </Typography>
                </Box>
                <Box width="100%">
                  <FormControl fullWidth error={"storageId" in errors}>
                    <Controller
                      name="storageId"
                      control={control}
                      rules={{ required: "必須です" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value ?? ""}
                          sx={{ backgroundColor: "background.paper" }}
                        >
                          <MenuItem key="" value="" />
                          {storages?.map(({ storage }) => (
                            <MenuItem key={storage.id} value={storage.id}>
                              {storage.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.storageId?.message && (
                      <FormHelperText>
                        {errors.storageId?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </ListItem>
            )}
            {!!storageId && (
              <ListItem sx={{ display: "flex", flexDirection: "column" }}>
                <Box width="100%">
                  <Typography variant="body2" color="text.secondary">
                    棚名
                  </Typography>
                </Box>
                <Box width="100%">
                  <FormControl fullWidth error={"placementId" in errors}>
                    <Controller
                      name="placementId"
                      control={control}
                      rules={{ required: "必須です" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={
                            (availableShelves.find(
                              (s) => s.id === field.value,
                            ) &&
                              field.value) ||
                            ""
                          }
                          sx={{ backgroundColor: "background.paper" }}
                        >
                          <MenuItem key="" value="" />
                          {availableShelves?.map((shelf) => (
                            <MenuItem key={shelf.id} value={shelf.id}>
                              {shelf.isKidsMarket
                                ? "【KM景品専用】" + shelf.name
                                : shelf.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.placementId?.message && (
                      <FormHelperText>
                        {errors.placementId?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </ListItem>
            )}
            {availableFloorMapPoints.length > 0 && (
              <ListItem sx={{ display: "flex", flexDirection: "column" }}>
                <Box width="100%">
                  <Typography variant="body2" color="text.secondary">
                    ブース名
                  </Typography>
                </Box>
                <Box width="100%">
                  <FormControl fullWidth error={"placementId" in errors}>
                    <Controller
                      name="placementId"
                      control={control}
                      rules={{ required: "必須です" }}
                      render={({ field }) => (
                        <Select {...field} value={field.value ?? ""}>
                          <MenuItem key="" value="" />
                          {availableFloorMapPoints.map((point) => (
                            <MenuItem
                              key={point.id}
                              value={
                                placementType === PlacementType.InBooth
                                  ? point.boothShelf.id
                                  : point.onBoothShelf.id
                              }
                            >
                              {point.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.placementId?.message && (
                      <FormHelperText>
                        {errors.placementId?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </ListItem>
            )}

            <Divider />

            <ListItem sx={{ display: "flex", flexDirection: "column" }}>
              <Box width="100%">
                <Typography variant="body2" color="text.secondary">
                  景品名
                </Typography>
              </Box>
              <Box width="100%">{prize.prizeName}</Box>
            </ListItem>
          </List>
        </DialogContent>
        <CustomDialogActions>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => onClose()}
          >
            キャンセル
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            追加する
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
