import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { PrizeFloorMapPoint } from "src/api/models"
import { getPrizeFloorMapPoint } from "src/api/prize-floor-map"
import { getPrizeInventoryExecute } from "src/api/prize-inventory-execute"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { getPrizePlacementStatuses } from "src/api/prize-placement-statuses"
import { InventoryPrizeExecuteDetails } from "src/components//templates/InventoryPrizeExecuteDetails"
import {
  inventoryExecuteDetailsSearchParamsState,
  InventoryPrizeExecuteDetailsFilter,
} from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsFilter"
import { InventoryPrizeExecuteDetailsMenuItem } from "src/components/organisms/prizes/InventoryPrizeExecuteDetailsMenu"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { sortPlacementStocks } from "src/domains/prizes/inventoryStockRepository"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"

export const InventoryPrizeExecuteInBoothDetails = () => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()

  const floorMapPointReturn = useResource({
    subject: "棚卸フロアマップの取得",
    fetch:
      arcadeCd && inventoryFloorMapPointId
        ? () =>
            getPrizeFloorMapPoint(arcadeCd, Number(inventoryFloorMapPointId))
        : undefined,
    recoilKey: `getInventoryFloorMapPoint:${arcadeCd}:${inventoryFloorMapPointId}`,
  })
  const inventoryFloorMapPoint =
    floorMapPointReturn?.resource?.data.floorMapPoint
  const placementName = inventoryFloorMapPoint
    ? inventoryFloorMapPoint.name + "［内］の景品一覧"
    : ""

  return (
    <MainContentLayout
      title={placementName}
      renderFilter={() => <InventoryPrizeExecuteDetailsFilter />}
      renderContent={() => (
        <InventoryPrizeExecuteInBoothDetailsMenu
          inventoryFloorMapPoint={inventoryFloorMapPoint}
          refetchFloorMapPoint={floorMapPointReturn.refetch}
        />
      )}
    />
  )
}

type InventoryExecuteInBoothDetailsMenuProps = {
  inventoryFloorMapPoint?: PrizeFloorMapPoint
  refetchFloorMapPoint: () => void
}

const InventoryPrizeExecuteInBoothDetailsMenu: React.FC<
  InventoryExecuteInBoothDetailsMenuProps
> = ({ inventoryFloorMapPoint, refetchFloorMapPoint }) => {
  const { arcadeCd, inventoryFloorMapPointId } = useParams()

  const boothShelf = useMemo(
    () => inventoryFloorMapPoint?.boothShelf,
    [inventoryFloorMapPoint],
  )

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentPrizeExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.prizeExecutionPeriods || [],
  )

  const { resource: statusesResource, refetch: refetchStatuses } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentPrizeExecutionPeriod
        ? async () => {
            if (!boothShelf) return
            return await getPrizePlacementStatuses(
              arcadeCd,
              PlacementType.InBooth,
              currentPrizeExecutionPeriod.id,
              { placementId: boothShelf.id },
            )
          }
        : undefined,
    recoilKey: `getPlacementStatuses:${arcadeCd}:${
      PlacementType.InBooth
    }:${JSON.stringify(currentPrizeExecutionPeriod)}:${boothShelf?.id}`,
  })
  const boothStatuses = useMemo(
    () => statusesResource?.data.boothStatuses || [],
    [statusesResource],
  )

  const searchParams = useRecoilValue(inventoryExecuteDetailsSearchParamsState)
  const { resource: historiesResource, refetch: refetchHistories } =
    useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentPrizeExecutionPeriod
          ? () =>
              getPrizeInventoryExecute(
                arcadeCd,
                currentPrizeExecutionPeriod.id,
                {
                  prizeCd: searchParams.prizeCd,
                  prizeName: searchParams.prizeName,
                  prizeNameKana: searchParams.prizeNameKana,
                  ipName: searchParams.ipName,
                  makerName: searchParams.makerName,
                  placementType: PlacementType.InBooth,
                  placementId: boothShelf?.id,
                },
              )
          : undefined,
      recoilKey: `getInventoryExecute:${arcadeCd}:${JSON.stringify(
        currentPrizeExecutionPeriod,
      )}:${JSON.stringify(searchParams)}:${
        PlacementType.InBooth
      }:${boothShelf?.id}`,
    })
  const histories = historiesResource?.data.histories

  const { resource: stocksResource, refetch: refetchStocks } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && inventoryFloorMapPointId
        ? () =>
            getPrizeOperationStocks(arcadeCd, {
              prizeName: searchParams.prizeName,
              prizeNameKana: searchParams.prizeNameKana,
              prizeCd: searchParams.prizeCd,
              inventoryFloorMapPointId: Number(inventoryFloorMapPointId),
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${inventoryFloorMapPointId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = stocksResource?.data.stocks
    if (stocks && stocks.length > 0) {
      return sortPlacementStocks(
        stocks.flatMap((s) => s.boothStocks),
        searchParams,
      )
    } else {
      return []
    }
  }, [searchParams, stocksResource])

  const refetch = () => {
    refetchFloorMapPoint()
    refetchStatuses()
    refetchHistories()
    refetchStocks()
  }

  const menuItems: InventoryPrizeExecuteDetailsMenuItem[] = useMemo(() => {
    const boothStocks =
      (boothShelf &&
        filteredStocks
          ?.map((boothStock) => {
            const { prizeCd, prizeName, unitPerCarton } = boothStock
            const prize = {
              prizeCd,
              prizeName,
              unitPerCarton,
            }
            const history = histories?.find(
              (history) => history.boothStock?.id === boothStock.id,
            )
            const executedStock = history?.stock
            const executed = !!history
            return {
              prize,
              anyStock: boothStock.stock,
              placementStockId: boothStock.id,
              placementType: PlacementType.InBooth,
              executedStock,
              executed,
              executedAt: history?.executedAt,
            }
          })
          .filter(Boolean)) ??
      []

    const stockPrizeCds = boothStocks.map((s) => s.prize.prizeCd)
    const { InBooth } = PlacementType
    const historiesWithoutBoothStock = (histories || [])
      .filter(
        (h) => h.placement === InBooth && h.placementId === boothShelf?.id,
      )
      .filter((h) => !stockPrizeCds.includes(h.prizeCd))
      .filter((h) => !h.boothStock || h.boothStock.stock <= 0)
      .map((h) => ({
        prize: {
          prizeCd: h.prizeCd,
          prizeName: h.prizeName,
        },
        placementType: PlacementType.InBooth,
        executedStock: h.stock,
        executed: true,
        executedAt: h.executedAt,
      }))

    return [...boothStocks, ...historiesWithoutBoothStock]
  }, [boothShelf, filteredStocks, histories])

  return (
    <InventoryPrizeExecuteDetails
      placementType="in_booth"
      placementId={boothShelf?.id}
      placementStocks={filteredStocks}
      placementStatus={boothStatuses[0]}
      menuItems={menuItems}
      histories={histories}
      onFinish={() => refetch()}
    />
  )
}
