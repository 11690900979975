import { useMemo, useState } from "react"

import { TextField, MenuItem, Select } from "@mui/material"
import {
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import { atom, useRecoilState } from "recoil"

import { getMaterialMachines } from "src/api/material-machines"
import { getMaterialStorages } from "src/api/material-storages"
import { MaterialOperationStock, MaterialStorage } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { materialOperationStockFilterSortByLabels } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"

export const inventoryMaterialVerifyStockSearchParamsState =
  atom<InventoryMaterialVerifyStockSearchParams>({
    key: "inventoryMaterialVerifyStockSearchParamsState",
    default: {},
  })

export type InventoryMaterialVerifyStockSearchParams = {
  materialName?: MaterialOperationStock["material"]["materialName"]
  materialNameKana?: string
  materialCd?: MaterialOperationStock["material"]["materialCd"]
  storageId?: number
  shelfId?: number
  amMachineNumber?: MaterialOperationStock["machineStocks"][0]["amMachine"]["amMachineNumber"]
  amMachineName?: MaterialOperationStock["machineStocks"][0]["amMachine"]["amMachineName"]
  materialMachineId?: number
  ipName?: MaterialOperationStock["material"]["ipName"]
  makerName?: MaterialOperationStock["material"]["makerName"]
  sortBy?: keyof typeof materialOperationStockFilterSortByLabels
}

export const InventoryMaterialVerifyStockFilter: React.FC = () => {
  const { arcadeCd } = useParams()
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialVerifyStockSearchParamsState,
  )
  const [storageId, setStorageId] = useState<number | undefined>(undefined)

  const { resource: storagesResource } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorages:${arcadeCd}`,
  })
  const storages = useMemo(
    () => storagesResource?.data.storages || [],
    [storagesResource],
  )
  const shelves = useMemo(
    () => storages.find((e) => storageId === e.storage.id)?.shelves || [],
    [storageId, storages],
  )
  const shelfOptions = useMemo(
    () =>
      shelves.map((shelf) => ({
        label: shelf.name,
        value: shelf.id,
      })),
    [shelves],
  )

  const { resource: machinesResource } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}`,
  })
  const materialMachineOptions = useMemo(
    () =>
      (machinesResource?.data.materialMachines || []).map(
        ({ amMachine, materialMachine }) => ({
          label: getDisplayMaterialMachineName(amMachine, materialMachine),
          value: materialMachine.id,
        }),
      ),
    [machinesResource],
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.materialCd ||
          searchParams.materialName ||
          searchParams.materialNameKana ||
          searchParams.makerName ||
          searchParams.ipName ||
          searchParams.storageId ||
          searchParams.shelfId ||
          searchParams.materialMachineId ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "materialCd",
          label: "材料CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialName",
          label: "材料名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialNameKana",
          label: "材料名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "storageId",
          label: "保管場所",
          render: (form) => (
            <StorageIdAutocomplete
              storages={storages.map((e) => e.storage)}
              storageId={searchParams.storageId}
              setStorageId={setStorageId}
              form={form}
            />
          ),
        },
        {
          name: "shelfId",
          label: "棚",
          hiddenAt: (values) =>
            values["storageId"] === undefined || values["storageId"] === "",
          render: ({ field, fieldState: { error } }) => (
            <SearchAutoComplete
              key={`storageId${storageId}-shelfId`} // storageId 変更時に key を変更して、state をリセットすることで、InputValue が残るのを防ぐ
              items={shelfOptions}
              {...field}
              defaultValue={
                shelfOptions.filter(
                  ({ value }) => value === searchParams.shelfId,
                )[0]?.value
              }
              error={!!error}
            />
          ),
        },
        {
          name: "materialMachineId",
          label: "材料機械",
          render: ({ field, fieldState: { error } }) => (
            <SearchAutoComplete
              items={materialMachineOptions}
              defaultValue={
                materialMachineOptions.filter(
                  (option) => option.value === searchParams.materialMachineId,
                )[0]?.value
              }
              {...field}
              error={!!error}
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(materialOperationStockFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}

type StorageIdAutocompleteProps = {
  storages: MaterialStorage[]
  storageId: number | undefined
  setStorageId: React.Dispatch<React.SetStateAction<number | undefined>>
  form: {
    field: ControllerRenderProps<
      InventoryMaterialVerifyStockSearchParams,
      | "storageId"
      | "materialCd"
      | "materialName"
      | "materialNameKana"
      | "makerName"
      | "ipName"
      | "shelfId"
      | "sortBy"
      | "materialMachineId"
      | "amMachineNumber"
      | "amMachineName"
    >
    fieldState: ControllerFieldState
  }
}

const StorageIdAutocomplete = ({
  storages,
  storageId,
  setStorageId,
  form: {
    field,
    fieldState: { error },
  },
}: StorageIdAutocompleteProps) => {
  const formContext = useFormContext()
  const storageOptions = (storages || []).map((storage) => ({
    label: storage.name,
    value: storage.id,
  }))
  return (
    <SearchAutoComplete
      items={storageOptions}
      defaultValue={
        storageOptions.filter(({ value }) => value === storageId)[0]?.value
      }
      data-testid="storage"
      {...field}
      error={!!error}
      onChange={(value) => {
        setStorageId(value as number)
        field.onChange(value)
        formContext.setValue("shelfId", undefined)
      }}
    />
  )
}
