import { ErrorOutline } from "@mui/icons-material"
import { Card, List, ListItem, Stack, Typography } from "@mui/material"

import { MaterialMachinesElement } from "src/api/models"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { theme } from "src/theme"

type InventoryMaterialAmMachineWarningCardProps = {
  materialMachines: MaterialMachinesElement[]
}

export const InventoryMaterialAmMachineWarningCard: React.FC<
  InventoryMaterialAmMachineWarningCardProps
> = ({ materialMachines }) => {
  return (
    <Card sx={{ mb: 2, background: theme.palette.error.light }}>
      <Stack sx={{ p: 1 }}>
        <Stack direction="row" sx={{ pb: 1, alignItems: "center" }}>
          <ErrorOutline
            sx={(theme) => ({
              color: theme.palette.error.main,
              mr: 1,
            })}
          />
          <Typography variant="body2" color="error.main">
            搬出した材料機械内に材料データが残っています
          </Typography>
        </Stack>
        <Card sx={{ p: 1 }}>
          <List sx={{ px: 0.5, width: "100%" }}>
            <ListItem sx={{ p: 0, alignItems: "flex-start" }}>
              <Typography variant="body2">1.</Typography>
              <Typography variant="body2" sx={{ pl: 1 }}>
                該当する材料機械から情物一致で棚に戻し処理をしてください
              </Typography>
            </ListItem>
            <ListItem sx={{ p: 0, alignItems: "flex-start" }}>
              <Typography variant="body2">2.</Typography>
              <Typography variant="body2" sx={{ pl: 1 }}>
                該当機械に残った材料データを０個で即時棚卸してください
              </Typography>
            </ListItem>
          </List>
          <List sx={{ pt: 1, px: 0.5, width: "100%" }}>
            {materialMachines.map((materialMachine) => (
              <ListItem
                key={materialMachine.materialMachine.id}
                sx={{ p: 0, alignItems: "flex-start" }}
              >
                <Typography variant="body2">・</Typography>
                <Typography variant="body2">
                  {getDisplayMaterialMachineName(
                    materialMachine.amMachine,
                    materialMachine.materialMachine,
                  )}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Card>
      </Stack>
    </Card>
  )
}
