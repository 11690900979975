import { useRecoilState } from "recoil"

import { DateRangePicker } from "src/components/atoms/DateRangePicker"
import { inventoryMaterialStockLedgerDateRangePickerDateLabelState } from "src/recoil/inventoryMaterials"

export const MaterialStockLedgerDateRangePicker = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    inventoryMaterialStockLedgerDateRangePickerDateLabelState,
  )
  return (
    <DateRangePicker
      dateRangeLabel={dateRangeLabel}
      setDateRangeLabel={setDateRangeLabel}
    />
  )
}
