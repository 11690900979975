import { useEffect, Suspense } from "react"

import * as Sentry from "@sentry/react"
import axios from "axios"
import { Outlet } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getMe } from "src/api/users"
import { refreshInterceptor } from "src/apicli"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import {
  isTimeout,
  isUnauthorized,
  refreshToken,
} from "src/domains/authRepository"
import { useAuth } from "src/hooks/useAuth"
import { useResource } from "src/hooks/useResource"
import {
  cognitoSubState,
  idTokenState,
  isLoginState,
  meState,
  snackbarErrorMessageState,
} from "src/recoil"

export const AuthProvider = () => {
  return (
    <Suspense fallback={<LoadingBox />}>
      <AuthProviderInner />
    </Suspense>
  )
}

export const AuthProviderInner = () => {
  const setIdToken = useSetRecoilState(idTokenState)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const { login } = useAuth()

  useResource({
    subject: "トークンのリフレッシュ",
    hideErrorMessage: true,
    fetch: async () => {
      try {
        const res = await refreshToken()
        const { credentials } = res.data
        if (credentials) login(credentials)
        return res
      } catch (e) {
        if (!axios.isAxiosError(e)) throw e
        if (isTimeout(e)) {
          return setErrorMessage("タイムアウトしました")
        }
        if (isUnauthorized(e)) {
          return setIdToken(null)
        }
        throw e
      }
    },
    providerName: "AuthProvider",
    recoilKey: "refreshToken",
  })

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      refreshInterceptor(
        async () => {
          try {
            const {
              data: { credentials },
            } = await refreshToken()
            return credentials?.idToken || null
          } catch (e) {
            if (!axios.isAxiosError(e)) throw e
            if (isUnauthorized(e)) return null

            throw e
          }
        },
        (tok: string | null) => {
          axios.defaults.headers.common["Authorization"] = `Bearer ${tok ?? ""}`
          setIdToken(tok)
        },
      ),
    )
  }, [login, setErrorMessage, setIdToken])

  const setMe = useSetRecoilState(meState)

  const isLogin = useRecoilValue(isLoginState)
  const cognitoSub = useRecoilValue(cognitoSubState)
  useResource({
    subject: "ログイン中のユーザー情報の取得",
    fetch: async () => {
      const res = await getMe()
      const { data } = res
      if (data) {
        Sentry.setUser({
          id: data.user.id.toString(),
        })
        setMe(data)
      }
      return res
    },
    skip: !isLogin,
    providerName: "AuthProvider",
    // NOTE: cognitoSub が変わったら再取得する
    recoilKey: `getMe:${cognitoSub}`,
  })

  return <Outlet />
}
