import { LoadingButton } from "@mui/lab"
import { Box, Grid, DialogContent, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import {
  PrizeInventoryDifference,
  PutPrizeInventoryExecuteFixPrizeCdRequest,
} from "src/api/models"
import { putPrizeInventoryExecuteFixPrizeCd } from "src/api/prize-inventory-execute"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { useSubmitting } from "src/hooks/useSubmitting"
import { getToday } from "src/utils"

interface InventoryFixPrizeCdModalProps {
  showModal: boolean
  difference: PrizeInventoryDifference
  selectedPrizeCdInSeams?: string
  onClose: () => void
  onFinish?: () => void
}

export const InventoryPrizeFixPrizeCdModal: React.FC<
  InventoryFixPrizeCdModalProps
> = ({
  showModal,
  difference,
  selectedPrizeCdInSeams,
  onClose,
  onFinish = () => undefined,
}: InventoryFixPrizeCdModalProps) => {
  const { arcadeCd } = useParams()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm()

  const { submitPromises } = useSubmitting()
  const onSubmit = async () => {
    if (!arcadeCd || !selectedPrizeCdInSeams) return
    const request: PutPrizeInventoryExecuteFixPrizeCdRequest = {
      prizeCd: selectedPrizeCdInSeams,
      historyIds: difference.historyIds,
    }

    await submitPromises([
      {
        subject: "景品CDの書き換え",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeInventoryExecuteFixPrizeCd(
            arcadeCd,
            getToday(),
            request,
          )
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">
            GiGO NAVIの景品CDを書き換えますか？
          </Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Box sx={{ pt: 3 }}>
            <Grid container sx={{ textAlign: "center", fontWeight: "bold" }}>
              <Grid item xs={5}>
                <Typography variant="caption" color="neutral.600">
                  修正前
                </Typography>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <Typography variant="caption" color="neutral.600">
                  修正後
                </Typography>
              </Grid>

              <Grid item xs={5}>
                {difference.prizeCd}
              </Grid>
              <Grid item xs={2}>
                →
              </Grid>
              <Grid item xs={5} sx={{ color: "success.main" }}>
                {selectedPrizeCdInSeams}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            書き換える
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
