import { SetStateAction, useMemo, useState } from "react"

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import {
  Typography,
  Stack,
  MenuItem,
  Select,
  TableRow,
  Link,
  TableHead,
  Card,
  TextField,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { PrizeDeliveryElement } from "src/api/models"
import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { PrizeDetailModal } from "src/components/organisms/prizes/PrizeDetailModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  excludePrizeDeliveryEntryCategories,
  sortFnPrizeDeliveries,
} from "src/domains/prizes/deliveryRepository"
import {
  PrizeOrderSale,
  PrizeOrderSales,
  calcPrizeOrderSales,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { filterAccordionSearchState } from "src/recoil"
import { getDaysAgo, getJpDateLabel, getRatioLabel, getToday } from "src/utils"

type PrizeWeeklyOrderSalesDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(7),
  end: getToday(),
}

const prizeWeeklyOrderSalesDateRangeLabelState =
  atom<PrizeWeeklyOrderSalesDateRangeLabelState>({
    key: "prizeWeeklyOrderSalesDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizeWeeklyOrderSales: React.FC = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizeWeeklyOrderSalesDateRangeLabelState,
  )

  return (
    <MainContentLayout
      title="着荷景品別の実績一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 2 }}>
            <DateRangePicker
              dateRangeLabel={dateRangeLabel}
              setDateRangeLabel={setDateRangeLabel}
            />
          </Card>
          <Stack>
            <PrizeWeeklyOrderSalesFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizeWeeklyOrderSalesInner />}
    />
  )
}

const sortByLabels = {
  salesAsc: "売上実績(昇順)",
  salesDesc: "売上実績(降順)",
  PDPBAsc: "景品PDPB(昇順)",
  PDPBDesc: "景品PDPB(降順)",
  arriveAtOrderAsc: "着荷日順",
  machineInputDateOrderAsc: "投入可能日順",
} as const

type PrizeWeeklyOrderSalesFilterSearchParams = {
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
  makerName?: string
  ipName?: string
  sortBy: "" | keyof typeof sortByLabels
}

const defaultSearchParams = {
  sortBy: "",
}

const PrizeWeeklyOrderSalesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: PrizeWeeklyOrderSalesFilterSearchParams =
    recoilSearchParams["prizeWeeklyOrderSalesSearchParams"] ??
    defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeWeeklyOrderSalesFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeWeeklyOrderSalesSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="並び替え"
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} fullWidth />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              fullWidth
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(sortByLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

const PrizeWeeklyOrderSalesInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const dateRangeLabel = useRecoilValue(
    prizeWeeklyOrderSalesDateRangeLabelState,
  )
  const searchParams: PrizeWeeklyOrderSalesFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "prizeWeeklyOrderSalesSearchParams"
    ] ?? defaultSearchParams

  const { start, end } = dateRangeLabel
  const { sortBy } = searchParams

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: start,
            to: end,
            prizeCd: searchParams.prizeCd,
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${start}:${end}:${JSON.stringify(searchParams)}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries || [],
    [prizeDeliveriesReturn],
  )

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const sales = useMemo(() => {
    const filterFn = (e: PrizeOrderSale) =>
      !excludePrizeDeliveryEntryCategories.includes(e.delivery.entryCategory)

    const sortFn = (a: PrizeOrderSale, b: PrizeOrderSale) => {
      if (sortBy === "salesAsc") {
        return (a.sales || 0) - (b.sales || 0)
      } else if (sortBy === "salesDesc") {
        return (b.sales || 0) - (a.sales || 0)
      } else if (sortBy === "PDPBAsc") {
        return (a.pdpb || 0) - (b.pdpb || 0)
      } else if (sortBy === "PDPBDesc") {
        return (b.pdpb || 0) - (a.pdpb || 0)
      } else if (
        sortBy === "arriveAtOrderAsc" ||
        sortBy === "machineInputDateOrderAsc"
      ) {
        return sortFnPrizeDeliveries(sortBy)(a, b)
      }

      return 0
    }

    return calcPrizeOrderSales(
      prizeDeliveries,
      prizeBoothSales,
      filterFn,
      sortFn,
    )
  }, [prizeDeliveries, prizeBoothSales, sortBy])

  return <PrizeWeeklyOrderSalesTable sales={sales} />
}

interface PrizeWeeklyOrderSalesTableProps {
  sales: PrizeOrderSales
}

const PrizeWeeklyOrderSalesTable: React.FC<PrizeWeeklyOrderSalesTableProps> = ({
  sales,
}) => {
  const stateKey = "prizeWeeklyOrderSalesTable"
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPrizeDelivery, setSelectedPrizeDelivery] =
    useState<PrizeDeliveryElement>()
  const { isAdmin } = useUserRole()

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 300px)",
      }}
    >
      <PaginatedTable
        scrollableY
        scrollableX
        stickyHeader
        noMargin
        items={sales.items}
        stateKey={stateKey}
        header={
          <TableHead sx={{ th: { whiteSpace: "nowrap" } }}>
            <TableRow>
              <ExtTableCell border sticky zIndex={100} fixedWidth={320}>
                景品名
              </ExtTableCell>
              <ExtTableCell fixedWidth={120}>売上実績</ExtTableCell>
              <ExtTableCell fixedWidth={120}>ブース数</ExtTableCell>
              <ExtTableCell>景品PDPB</ExtTableCell>
              <ExtTableCell fixedWidth={120}>P/O</ExtTableCell>
              <ExtTableCell fixedWidth={120}>対ブース平均</ExtTableCell>
              <ExtTableCell fixedWidth={120}>消化率</ExtTableCell>
              <ExtTableCell fixedWidth={120}>理論残在庫</ExtTableCell>
              <ExtTableCell fixedWidth={80}>ランク</ExtTableCell>
              <ExtTableCell fixedWidth={127}>着荷日</ExtTableCell>
              <ExtTableCell fixedWidth={127}>投入可能日</ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell header word="入庫数" rowSpan={2} />
                  <AdminExtTableCell header word="払出個数" rowSpan={2} />
                  <AdminExtTableCell header word="景品単価" rowSpan={2} />
                  <AdminExtTableCell header word="景品代" rowSpan={2} />
                  <AdminExtTableCell header word="店舗全体PDPB" rowSpan={2} />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(item, index) => {
          let alertMessage = null
          if (item.payoutRate && item.payoutRate <= 0.1) {
            alertMessage = "P/Oが低すぎます"
          } else if (item.payoutRate && item.payoutRate >= 0.4) {
            alertMessage = "P/Oが高すぎます"
          }

          return (
            <TableRow
              key={index}
              sx={{
                td: {
                  px: 1,
                },
              }}
            >
              <ExtTableCell border sticky zIndex={99}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {alertMessage && (
                    <ErrorOutlineIcon
                      sx={(theme) => ({
                        color: theme.palette.error.main,
                        mr: 1,
                      })}
                    />
                  )}
                  <Link
                    underline="none"
                    sx={{ color: "primary.main", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedPrizeDelivery({
                        prize: item.prize,
                        delivery: item.delivery,
                      })
                      setIsModalOpen(true)
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      color={alertMessage ? "error.main" : undefined}
                    >
                      {item.prize.prizeName}
                    </Typography>
                  </Link>
                </Stack>
                {alertMessage && (
                  <Typography variant="body2" color="error.main" pt={1}>
                    {alertMessage}
                  </Typography>
                )}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.count ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.pdpb?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.payoutRate)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.pdpbRatio)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.consumptionRate)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.logicalStock ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "center" }}>
                {item.prizeRank ?? "-"}
              </ExtTableCell>
              <ExtTableCell>
                {getJpDateLabel(item.delivery.arriveAt)}
              </ExtTableCell>
              <ExtTableCell>
                {getJpDateLabel(item.delivery.machineInputDate)}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.stock?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.prize?.unitPriceJpy?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payoutPrice?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={sales.total.pdpb?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
      {selectedPrizeDelivery && (
        <PrizeDetailModal
          showModal={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setSelectedPrizeDelivery(undefined)
          }}
          prize={selectedPrizeDelivery.prize}
          delivery={selectedPrizeDelivery.delivery}
        />
      )}
    </Stack>
  )
}
