import { useEffect, Suspense } from "react"

import { Outlet, useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"

import { getArcade } from "src/api/arcades"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { useResource } from "src/hooks/useResource"
import {
  alertDefaultValueState,
  alertParamsState,
  currentArcadeState,
} from "src/recoil"

export const ArcadeProvider = () => {
  return (
    <Suspense fallback={<LoadingBox />}>
      <ArcadeProviderInner />
    </Suspense>
  )
}

const ArcadeProviderInner = () => {
  const { arcadeCd } = useParams()
  const setCurrentArcade = useSetRecoilState(currentArcadeState)
  const setAlertDefaultValue = useSetRecoilState(alertDefaultValueState)
  const setAlertParams = useSetRecoilState(alertParamsState)

  const { resource } = useResource({
    subject: "店舗情報の取得",
    fetch: arcadeCd ? () => getArcade(arcadeCd) : undefined,
    recoilKey: `getArcade:${arcadeCd}`,
    providerName: "ArcadeProvider",
  })

  useEffect(() => {
    const { arcade, arcadeAlertDefaultValue } = resource?.data || {}
    if (arcade) {
      setCurrentArcade(arcade)
    } else {
      throw new Error()
    }
    if (arcadeAlertDefaultValue) {
      setAlertDefaultValue(arcadeAlertDefaultValue)
      const { payoutRateRange, payoutRate, salesRange, sales } =
        arcadeAlertDefaultValue
      setAlertParams((a) => ({
        ...a,
        payoutRateRange,
        payoutRate,
        salesRange,
        sales,
      }))
    }
  }, [
    resource,
    arcadeCd,
    setCurrentArcade,
    setAlertDefaultValue,
    setAlertParams,
  ])

  return <Outlet />
}
