import { useMemo, useState, PropsWithChildren } from "react"

import {
  CheckCircleOutline,
  ErrorOutline as ErrorOutlineIcon,
  Close,
} from "@mui/icons-material"
import { Typography, Stack, Button, IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeMeterReads, putPrizeMeterReads } from "src/api/prize-sales"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { CsvTable } from "src/components/organisms/CsvTable"
import { FileDropzone } from "src/components/organisms/FileDropzone"
import {
  PrizeMeterReadDatePicker,
  prizeMeterReadSelectedDateState,
} from "src/components/organisms/prizes/PrizeMeterReadDatePicker"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  meterReadErrorText,
  parseMeterReadCsvs,
} from "src/domains/prizes/meterReadCsv"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { useUploadCsvFiles } from "src/hooks/useUploadCsvFiles"
import { getUpdatableMeterReadDayjs } from "src/utils"

type UploadResult = {
  ufoKey?: File[]
  usb?: File[]
}

export const PrizeMeterReadCsv: React.FC = () => {
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null)

  return (
    <MainContentLayout
      title="プライズ売上情報の入力・更新"
      renderFilter={
        uploadResult
          ? undefined
          : () => (
              <PrizeMeterReadDatePicker
                minDate={getUpdatableMeterReadDayjs()}
              />
            )
      }
      renderContent={() =>
        uploadResult ? (
          <UploadCompleteScreen
            uploadedUfoKeyCsvFiles={uploadResult.ufoKey}
            uploadedUsbCsvFiles={uploadResult.usb}
          />
        ) : (
          <PrizeMeterReadCsvInner setUploadResult={setUploadResult} />
        )
      }
    />
  )
}

const displayDataColumns = [
  "ファイル名",
  "行番号",
  "ブース名",
  "日付",
  "100円",
  "500円",
  "プライズアウト",
  "エラー",
]

type PrizeMeterReadCsvInnerProps = {
  setUploadResult: (result: UploadResult) => void
}

const PrizeMeterReadCsvInner: React.FC<PrizeMeterReadCsvInnerProps> = ({
  setUploadResult,
}) => {
  const { arcadeCd } = useParams()
  const selectedDate = useRecoilValue(prizeMeterReadSelectedDateState)

  const boothElements = useResource({
    subject: "メーターリード結果の取得",
    skip: !arcadeCd || !selectedDate,
    fetch:
      arcadeCd && selectedDate
        ? () =>
            getPrizeMeterReads(arcadeCd, {
              from: selectedDate,
              to: selectedDate,
            })
        : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}`,
  }).resource?.data.booths

  const {
    uploadedFiles: uploadedUfoKeyCsvFiles,
    uploadedCsvSet: uploadedUfoKeyCsvSet,
    onUploadCsvs: onUploadUfoKeyCsv,
    deleteFile: deleteUfoKeyFile,
  } = useUploadCsvFiles({ requiredColumns: [] })

  const {
    uploadedFiles: uploadedUsbCsvFiles,
    uploadedCsvSet: uploadedUsbCsvSet,
    onUploadCsvs: onUploadUsbCsv,
    deleteFile: deleteUsbFile,
  } = useUploadCsvFiles({ requiredColumns: [] })

  const { submitPromises } = useSubmitting()

  const { ufoKeyMeterReadSet, usbMeterReadSet, allResults, hasError } =
    useMemo(() => {
      const parseResult = parseMeterReadCsvs({
        boothElements,
        selectedDate,
        ufoCsvSet: uploadedUfoKeyCsvSet,
        usbCsvSet: uploadedUsbCsvSet,
      })

      const allResults = [
        ...parseResult.ufoKey.values(),
        ...parseResult.usb.values(),
      ].flat()

      const hasError = allResults.some(({ errors }) => errors != null)

      return {
        ufoKeyMeterReadSet: parseResult.ufoKey,
        usbMeterReadSet: parseResult.usb,
        allResults,
        hasError,
      }
    }, [boothElements, selectedDate, uploadedUfoKeyCsvSet, uploadedUsbCsvSet])

  const displayMeterReadCsv = useMemo(() => {
    if (allResults.length === 0) {
      return null
    }

    return {
      headerRow: {
        columns: displayDataColumns,
      },
      rows: allResults.map((item) => ({
        columns: [
          item.meta.fileName,
          String(item.meta.rowIndex + 1),
          item.data.boothName,
          item.data.recordedAt,
          String(item.data.yen100CoinCount),
          String(item.data.yen500CoinCount),
          String(item.data.payout),
          item.errors
            ?.map((error) => `・${meterReadErrorText(error)}`)
            .join("\n") || "",
        ],
      })),
    }
  }, [allResults])

  const onSubmit = () => {
    if (!arcadeCd) return

    if (hasError) {
      throw new Error("CSVファイルにエラーがあります")
    }

    submitPromises([
      {
        subject: "プライズ売上情報CSV登録",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeMeterReads(arcadeCd, {
            meters: allResults.map(({ data }) => data),
            softMeters: [],
          })
          setUploadResult({
            ufoKey: uploadedUfoKeyCsvFiles,
            usb: uploadedUsbCsvFiles,
          })
        },
      },
    ])
  }

  return (
    <>
      {/* APIのエラー時に表示する */}
      {hasError && (
        <Stack mb={2}>
          <AlertCaptionCard
            label="アップロードに失敗したファイルがあります"
            variant="error"
          />
        </Stack>
      )}

      <Stack sx={{ pb: 2, gap: 2 }}>
        <Typography variant="h2">UFOキーから取得したCSV</Typography>
        {uploadedUfoKeyCsvFiles?.map((file) => (
          <FileNameListItem
            key={file.name}
            fileName={file.name}
            onDelete={deleteUfoKeyFile}
            hasError={ufoKeyMeterReadSet
              .get(file.name)
              ?.some(({ errors }) => errors != null)}
          >
            {file.name}
          </FileNameListItem>
        ))}
        <FileDropzone multiple onFiles={onUploadUfoKeyCsv} />
      </Stack>

      <Stack sx={{ pb: 3, gap: 2 }}>
        <Typography variant="h2">USBから取得したCSV</Typography>
        {uploadedUsbCsvFiles?.map((file) => (
          <FileNameListItem
            key={file.name}
            fileName={file.name}
            onDelete={deleteUsbFile}
            hasError={usbMeterReadSet
              .get(file.name)
              ?.some(({ errors }) => errors != null)}
          >
            {file.name}
          </FileNameListItem>
        ))}
        <FileDropzone multiple onFiles={onUploadUsbCsv} />
      </Stack>

      {displayMeterReadCsv && (
        <Stack sx={{ pb: 3 }}>
          <CsvTable
            csv={displayMeterReadCsv}
            limit={20}
            borderedCell
            overrideCellSxProps={[
              {
                index: displayDataColumns.length - 1, // エラー列
                sx: {
                  color: "error.main",
                  whiteSpace: "pre-wrap",
                },
              },
            ]}
          />
        </Stack>
      )}

      <Stack>
        <Button
          type="button"
          variant="contained"
          disabled={hasError}
          onClick={() => onSubmit()}
        >
          登録する
        </Button>
      </Stack>
    </>
  )
}

type FileNameListItemProps = {
  fileName: string
  onDelete: (fileName: string) => void
  hasError?: boolean
}

const FileNameListItem: React.FC<PropsWithChildren<FileNameListItemProps>> = ({
  fileName,
  children,
  onDelete,
  hasError = false,
}) => (
  <Stack
    sx={{
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {hasError ? (
        <ErrorOutlineIcon
          sx={(theme) => ({
            color: theme.palette.error.main,
            mr: 1,
          })}
        />
      ) : (
        <CheckCircleOutline
          sx={(theme) => ({
            color: theme.palette.icon.green,
            mr: 1,
          })}
        />
      )}
      <Typography variant="body1" color={hasError ? "error.main" : "gray.90"}>
        {children}
      </Typography>
    </Stack>

    <IconButton
      aria-label="close"
      onClick={() => onDelete(fileName)}
      sx={{ p: 0 }}
    >
      <Close />
    </IconButton>
  </Stack>
)

const FileListUl = styled("ul")`
  li {
    margin-top: 8px;
    margin-bottom: 8px;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

type UploadCompleteScreenProps = {
  uploadedUfoKeyCsvFiles?: File[]
  uploadedUsbCsvFiles?: File[]
}

const UploadCompleteScreen: React.FC<UploadCompleteScreenProps> = ({
  uploadedUfoKeyCsvFiles = [],
  uploadedUsbCsvFiles = [],
}) => {
  return (
    <>
      <Stack mb={2}>
        <AlertCaptionCard label="下記の通り登録しました" />
      </Stack>

      <Stack sx={{ pb: 2, gap: 2 }}>
        <Typography variant="h2">UFOキーから取得したCSV</Typography>
        <FileListUl>
          {uploadedUfoKeyCsvFiles.map((file) => (
            <Typography variant="body1" key={file.name} component="li">
              {file.name}
            </Typography>
          ))}
        </FileListUl>
      </Stack>

      <Stack sx={{ pb: 4, gap: 2 }}>
        <Typography variant="h2">USBから取得したCSV</Typography>
        <FileListUl>
          {uploadedUsbCsvFiles.map((file) => (
            <Typography variant="body1" key={file.name} component="li">
              {file.name}
            </Typography>
          ))}
        </FileListUl>
      </Stack>
    </>
  )
}
