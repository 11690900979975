import { useParams } from "react-router-dom"

import { InventoryMaterialStockDetails } from "src/components/templates/InventoryMaterialStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryMaterialVerifyStockDetails: React.FC = () => {
  const { materialCd } = useParams()

  return (
    <MainContentLayout
      title="在庫確認・店内移動"
      subtitle="店内在庫の確認・移動"
      renderContent={() => (
        <InventoryMaterialStockDetails materialCd={materialCd ?? ""} />
      )}
      backButtonLabel="検索画面に戻る"
    />
  )
}
