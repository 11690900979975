/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeBoothSalesParams,
  GetPrizeBoothSalesResponse,
  GetPrizeMeterReadResponse,
  GetPrizeMeterReadsParams,
  GetPrizeMeterReadsResponse,
  PostPrizeMeterReadsRequest,
  PutPrizeMeterReadsRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 日毎の売上一覧を取得
 */
export const getPrizeBoothSales = (
  arcadeCd: string,
  params: GetPrizeBoothSalesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeBoothSalesResponse>(
    { url: `/arcades/${arcadeCd}/prizes/sales`, method: "GET", params },
    options,
  )
}
/**
 * @summary メーターリード一覧の取得
 */
export const getPrizeMeterReads = (
  arcadeCd: string,
  params: GetPrizeMeterReadsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeMeterReadsResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/sales/meterReads`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary メーターリードの初期設定
 */
export const initPrizeMeterReads = (
  arcadeCd: string,
  postPrizeMeterReadsRequest: PostPrizeMeterReadsRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/sales/meterReads`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPrizeMeterReadsRequest,
    },
    options,
  )
}
/**
 * @summary メーターリードの作成・更新
 */
export const putPrizeMeterReads = (
  arcadeCd: string,
  putPrizeMeterReadsRequest: PutPrizeMeterReadsRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/sales/meterReads`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeMeterReadsRequest,
    },
    options,
  )
}
/**
 * @summary メーターリード詳細の取得
 */
export const getPrizeMeterRead = (
  arcadeCd: string,
  meterReadId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeMeterReadResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/sales/meterReads/${meterReadId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary シンカクラウドの自動連携の実行
 */
export const execPrizePosReads = (
  arcadeCd: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/arcades/${arcadeCd}/prizes/sales/posReads`, method: "POST" },
    options,
  )
}
export type GetPrizeBoothSalesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeBoothSales>>
>
export type GetPrizeMeterReadsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeMeterReads>>
>
export type InitPrizeMeterReadsResult = NonNullable<
  Awaited<ReturnType<typeof initPrizeMeterReads>>
>
export type PutPrizeMeterReadsResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeMeterReads>>
>
export type GetPrizeMeterReadResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeMeterRead>>
>
export type ExecPrizePosReadsResult = NonNullable<
  Awaited<ReturnType<typeof execPrizePosReads>>
>
