import { Suspense, useEffect } from "react"

import {
  Add as AddIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material"
import {
  Card,
  Box,
  Divider,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  CircularProgress,
  FormHelperText,
} from "@mui/material"
import {
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormReturn,
  useWatch,
} from "react-hook-form"
import { useRecoilValue } from "recoil"

import { getMachines } from "src/api/machines"
import {
  Machine,
  PrizeToneMachineAlert,
  PrizeToneMachineAlertPayoutRateRangeEnum,
  PrizeToneMachineAlertSalesRangeEnum,
} from "src/api/models"
import {
  AlertParams,
  getAlertRangeMenuItem,
} from "src/components/organisms/AlertFormModal"
import { useResource } from "src/hooks/useResource"
import { alertDefaultValueState, alertParamsState } from "src/recoil"
import { theme } from "src/theme"

export type MachineAlertParams = Pick<
  PrizeToneMachineAlert,
  "machineName" | "payoutRateRange" | "payoutRate" | "salesRange" | "sales"
>

interface MachineAlertsProps {
  useFormReturn: UseFormReturn<AlertParams>
}

export const MachineAlerts: React.FC<MachineAlertsProps> = ({
  useFormReturn,
}: MachineAlertsProps) => {
  const alertDefaultValue = useRecoilValue(alertDefaultValueState)
  const alertParams = useRecoilValue(alertParamsState)
  const { machineAlerts } = alertParams

  const { control, setValue } = useFormReturn
  const useFieldArrayReturn = useFieldArray({
    control,
    name: "machineAlerts",
  })
  const { fields, insert } = useFieldArrayReturn

  useEffect(() => {
    setValue("machineAlerts", machineAlerts)
  }, [machineAlerts, setValue])

  const defaultMachineAlert = {
    machineName: "",
    payoutRateRange: alertDefaultValue.payoutRateRange,
    payoutRate: alertDefaultValue.payoutRate,
    salesRange: alertDefaultValue.salesRange,
    sales: alertDefaultValue.sales,
  }

  return (
    <Card sx={{ background: theme.palette.neutral[100] }}>
      <Box p={2} display="flex">
        <Box flexGrow={1}>機種別アラート設定</Box>
        <IconButton
          disableRipple
          onClick={() => insert(0, defaultMachineAlert)}
          sx={{
            color: "white",
            backgroundColor: "primary.main",
          }}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {fields.length > 0 && (
        <>
          <Divider />

          <Suspense
            fallback={
              <Box display="flex" alignItems="center" mb={1}>
                <CircularProgress sx={{ margin: "auto" }} />
              </Box>
            }
          >
            <MachineAlertCards {...{ useFormReturn, useFieldArrayReturn }} />
          </Suspense>
        </>
      )}
    </Card>
  )
}

interface MachineAlertCardsProps {
  useFormReturn: UseFormReturn<AlertParams>
  useFieldArrayReturn: UseFieldArrayReturn<AlertParams>
}

const MachineAlertCards: React.FC<MachineAlertCardsProps> = ({
  useFormReturn,
  useFieldArrayReturn,
}) => {
  const { resource } = useResource({
    subject: "機種一覧情報の取得",
    fetch: () => getMachines(),
    recoilKey: "getMachines",
  })
  const machines = resource?.data.machines || []

  const { fields } = useFieldArrayReturn

  return (
    <Box>
      {fields.map((field, index) => {
        return (
          <Box key={field.id} m={1}>
            <MachineAlertCard
              {...{
                useFormReturn,
                useFieldArrayReturn,
                machines,
                index,
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

interface MachineAlertCardProps {
  useFormReturn: UseFormReturn<AlertParams>
  useFieldArrayReturn: UseFieldArrayReturn<AlertParams>
  machines: Machine[]
  index: number
}

const MachineAlertCard: React.FC<MachineAlertCardProps> = ({
  useFormReturn,
  useFieldArrayReturn,
  machines,
  index,
}: MachineAlertCardProps) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormReturn
  const { insert, remove } = useFieldArrayReturn
  const value = useWatch({
    name: `machineAlerts.${index}`,
    control,
  })
  const machineAlertErrors =
    (errors.machineAlerts && errors.machineAlerts[index]) || {}

  return (
    <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Grid container sx={{ display: "flex", alignItems: "center" }} p={1}>
        <Grid item xs={12} py={2} flexGrow={1}>
          <FormControl fullWidth error={"machineName" in machineAlertErrors}>
            <InputLabel id="machine-name">機種名</InputLabel>
            <Controller
              name={`machineAlerts.${index}.machineName`}
              control={control}
              render={({ field }) => (
                <Select
                  label="機種名"
                  labelId="machine-name"
                  {...field}
                  value={field.value || ""}
                  inputProps={{ inputMode: "none" }}
                >
                  <MenuItem key="" value="" />
                  {machines.map((m) => (
                    <MenuItem key={m.name} value={m.name}>
                      {m.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {machineAlertErrors.machineName?.message && (
              <FormHelperText>
                {machineAlertErrors.machineName?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={5} pb={2}>
          <TextField
            label="PO率"
            error={"payoutRate" in machineAlertErrors}
            helperText={machineAlertErrors.payoutRate?.message || ""}
            {...register(`machineAlerts.${index}.payoutRate`)}
            inputProps={{ inputMode: "decimal" }}
          />
        </Grid>
        <Grid item xs={2} pb={2} pl={1}>
          ％
        </Grid>
        <Grid item xs={5} pb={2} pl={1}>
          <FormControl
            fullWidth
            error={"payoutRateRange" in machineAlertErrors}
          >
            <Controller
              name={`machineAlerts.${index}.payoutRateRange`}
              control={control}
              render={({ field }) => (
                <Select {...field} value={field.value || ""}>
                  {getAlertRangeMenuItem(
                    PrizeToneMachineAlertPayoutRateRangeEnum,
                  )}
                </Select>
              )}
            />
            {machineAlertErrors.payoutRateRange?.message && (
              <FormHelperText>
                {machineAlertErrors.payoutRateRange?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={5} pb={2}>
          <TextField
            label="売上"
            error={"sales" in machineAlertErrors}
            helperText={machineAlertErrors.sales?.message || ""}
            {...register(`machineAlerts.${index}.sales`)}
            inputProps={{ inputMode: "numeric" }}
          />
        </Grid>
        <Grid item xs={2} pb={2} pl={1}>
          円
        </Grid>
        <Grid item xs={5} pb={2} pl={1}>
          <FormControl fullWidth error={"salesRange" in machineAlertErrors}>
            <Controller
              name={`machineAlerts.${index}.salesRange`}
              control={control}
              render={({ field }) => (
                <Select {...field} value={field.value || ""}>
                  {getAlertRangeMenuItem(PrizeToneMachineAlertSalesRangeEnum)}
                </Select>
              )}
            />
            {machineAlertErrors.salesRange?.message && (
              <FormHelperText>
                {machineAlertErrors.salesRange?.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ width: "28px", mr: 1 }}>
        <IconButton
          disableRipple
          onClick={() => insert(index + 1, value)}
          sx={{
            color: "white",
            backgroundColor: "neutral.400",
            mb: 3,
          }}
        >
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          disableRipple
          onClick={() => remove(index)}
          sx={{
            color: "white",
            backgroundColor: "error.main",
          }}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Card>
  )
}
