import { useCallback, useState } from "react"

import { Avatar, Button, Menu, Typography } from "@mui/material"
import shadows from "@mui/material/styles/shadows"

import AccountCicle from "src/assets/mui_account_circle.svg"

type Props = React.PropsWithChildren<{
  userName?: string
  avatarOnly?: boolean
  disabled?: boolean
}>

export const AccountMenu: React.FC<Props> = ({
  userName,
  avatarOnly,
  disabled,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Button
        sx={{ p: 1, borderRadius: 1, gap: 1 }}
        disabled={disabled}
        onClick={handleOpen}
      >
        <Avatar src={AccountCicle} sx={{ width: 36, height: 36 }} />
        {!avatarOnly && userName && (
          <Typography variant="body1" color="textPrimary">
            {userName}
          </Typography>
        )}
      </Button>
      <Menu
        id="menu-appbar"
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
        PaperProps={{
          sx: {
            py: 1,
            borderRadius: 0.5,
            // theme で shadows を上書きしていて elevation が使えないため boxShadow を直接指定
            boxShadow: shadows[8],
          },
        }}
      >
        {children}
      </Menu>
    </>
  )
}
