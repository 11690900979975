/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type MaterialMachineWarningEnum =
  (typeof MaterialMachineWarningEnum)[keyof typeof MaterialMachineWarningEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialMachineWarningEnum = {
  None: "none",
  AmMachineArcadeChanged: "amMachineArcadeChanged",
  AmMachineDisabled: "amMachineDisabled",
} as const
