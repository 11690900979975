import { PropsWithChildren } from "react"

import { Close } from "@mui/icons-material"
import { IconButton, DialogTitle, DialogTitleProps, Box } from "@mui/material"

export type DialogTitleWithCloseProps = {
  onClose: () => void
} & DialogTitleProps

export const DialogTitleWithClose: React.FC<
  PropsWithChildren<DialogTitleWithCloseProps>
> = ({ children, onClose, ...dialogTitleProps }) => (
  <DialogTitle {...dialogTitleProps}>
    <Box sx={{ pr: 2 }}>{children}</Box>
    <IconButton
      aria-label="close"
      onClick={() => onClose()}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
      }}
    >
      <Close />
    </IconButton>
  </DialogTitle>
)
