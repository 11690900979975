import { Fragment, useState } from "react"

import { Box, Card, Table, TableBody, Grid, Button, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { InventoryPrizeOutModal } from "src/components/organisms/prizes/InventoryPrizeOutModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { hideEmptyPrizeState } from "src/recoil/inventory"

export const InventoryPrizeOutDetails: React.FC = () => {
  return (
    <MainContentLayout
      title="店外出庫"
      renderContent={() => <InventoryPrizeOutDetailsMenu />}
      backButtonLabel="検索画面に戻る"
    />
  )
}

export const InventoryPrizeOutDetailsMenu: React.FC = () => {
  const navigate = useNavigate()
  const { arcadeCd, prizeCd } = useParams()
  const [showModal, setShowModal] = useState(false)

  const { resource } = useResource({
    subject: "在庫情報の取得",
    fetch:
      arcadeCd && prizeCd
        ? () => getPrizeOperationStocks(arcadeCd, { prizeCd })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${prizeCd}`,
  })
  const stock = resource?.data.stocks.find((s) => s.prize.prizeCd === prizeCd)

  const { prizeName } = stock?.prize || {}
  const hideEmptyPrize = useRecoilValue(hideEmptyPrizeState)

  const listItems = [
    {
      key: "景品名",
      value: prizeName,
    },
    {
      key: "景品CD",
      value: prizeCd,
    },
  ].filter((item) => !!item)

  return (
    <>
      <Stack gap={2}>
        <Card>
          <Table>
            <TableBody>
              <TableBorderedRow>
                <ExtTableCell colSpan={2}>
                  <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                    <PrizeImageBox
                      prizeCd={prizeCd ?? ""}
                      alt={prizeName}
                      noImageSize="large"
                    />
                  </Box>
                </ExtTableCell>
              </TableBorderedRow>

              {listItems.map(
                (item) =>
                  item && (
                    <TableBorderedRow key={item.key}>
                      <CardKeyCell>{item.key}</CardKeyCell>
                      <CardValueCell>{item.value}</CardValueCell>
                    </TableBorderedRow>
                  ),
              )}

              <TableBorderedRow>
                <CardKeyCell>
                  プライズ
                  <br />
                  機外在庫
                </CardKeyCell>
                <CardValueCell>
                  <Grid container>
                    {stock?.shelfStocks.length === 0 && (
                      <Grid item xs={12}>
                        なし
                      </Grid>
                    )}
                    {stock?.shelfStocks
                      .filter((s) => (hideEmptyPrize ? s.stock > 0 : true))
                      .map((s) => (
                        <Fragment key={s.shelfId}>
                          <Grid item xs={9} pr={1} pb={1}>
                            {s.storageName} {s.shelfName}
                          </Grid>

                          <Grid item xs={3} pb={1}>
                            {s.stock} 個
                          </Grid>
                        </Fragment>
                      ))}
                  </Grid>
                </CardValueCell>
              </TableBorderedRow>
            </TableBody>
          </Table>
        </Card>

        <Stack>
          <Button
            variant="contained"
            fullWidth
            disabled={!stock || stock.shelfStocks.length === 0}
            onClick={() => {
              setShowModal(true)
            }}
          >
            店外に出庫する
          </Button>
        </Stack>
      </Stack>

      {stock && (
        <InventoryPrizeOutModal
          showModal={showModal}
          stock={stock}
          onClose={() => setShowModal(false)}
          onFinish={() => navigate(-1)}
        />
      )}
    </>
  )
}
