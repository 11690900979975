import { Card, CardContent, List, Typography, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const MaterialTop: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="材料業務"
      renderContent={() => (
        <Stack gap={2}>
          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                入庫・出庫
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/register/accept`}
                >
                  入庫検収
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/register/histories/in`}
                >
                  入庫ログ一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/materials/register/out`}>
                  店外出庫
                </MenuButton>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/register/histories/out`}
                >
                  出庫ログ一覧
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                材料検索・移動
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/move/temporary`}
                >
                  荷捌き中の材料一覧
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/materials/move/stocks`}>
                  材料一覧から投入
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/materials/move/machines`}>
                  材料機械から戻し
                </MenuButton>
                <MenuButton to={`/arcades/${arcadeCd}/materials/placements`}>
                  棚・材料機械一覧から移動
                </MenuButton>
              </List>
            </CardContent>
          </Card>

          <Card>
            <CardContent sx={{ pt: 3, ":last-child": { pb: 2 } }}>
              <Typography variant="h2" sx={{ mb: 1 }}>
                副帳票
              </Typography>
              <List>
                <MenuButton
                  to={`/arcades/${arcadeCd}/materials/ledger/materials`}
                >
                  副帳票材料一覧
                </MenuButton>
              </List>
            </CardContent>
          </Card>
        </Stack>
      )}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}`)}
    />
  )
}
