import { useMemo, useState } from "react"

import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  Accordion,
  AccordionDetails,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material"
import { SubmitHandler } from "react-hook-form"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getArcade } from "src/api/arcades"
import { getInventoryCsvExport } from "src/api/inventory-csv"
import { getMaterialInventoryDifferences } from "src/api/material-inventory-differences"
import { postMaterialInventoryExecutionPeriodEnd } from "src/api/material-inventory-execution-period"
import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import {
  MaterialInventoryDifference,
  PostMaterialInventoryExecutionPeriodEndRequest,
  MaterialInventoryDifferenceGroupEnum,
} from "src/api/models"
import { AccordionSummaryWithButton } from "src/components/atoms/AccordionSummaryWithButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { InventoryExecutionPeriodWarning } from "src/components/organisms/InventoryExecutionPeriodWarning"
import {
  InventoryExecutionPeriodEndFormInput,
  InventoryMaterialExecutionPeriodEndModal,
} from "src/components/organisms/materials/InventoryMaterialExecutionPeriodEndModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  isMaterialPeriodEndedToday,
  findCurrentFeatureExecutionPeriod,
} from "src/domains/inventoryExecutionPeriodRepository"
import {
  materialDifferenceTableHeaders,
  getMaterialDifferenceLabel,
  materialPlacementTypeNames,
} from "src/domains/materials/materialInventoryHistoriesRepository"
import {
  getNotExecutedPlacements,
  MaterialPlacementTypeWithAll,
} from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentArcadeState,
  currentInventoryPeriodState,
  inventoryPeriodsState,
  snackbarErrorMessageState,
} from "src/recoil"

export const InventoryMaterialConfirmation = () => {
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const isPeriodEndedToday =
    inventoryPeriods && isMaterialPeriodEndedToday(inventoryPeriods)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  if (currentMaterialExecutionPeriod) {
    return (
      <MainContentLayout
        title="材料棚卸確定"
        caption="現在の材料棚卸期間を確定"
        renderContent={() => (
          <Card sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {isPeriodEndedToday ? (
                <Grid item xs={12}>
                  <Typography variant="caption" color="success.main">
                    材料棚卸確定処理済です。結果出力を行った翌日から次の棚卸期間が開始します。
                  </Typography>
                </Grid>
              ) : (
                <InventoryMaterialConfirmationMenu />
              )}
            </Grid>
          </Card>
        )}
      />
    )
  }

  return (
    <InventoryExecutionPeriodWarning
      title="材料棚卸確定"
      period={currentInventoryPeriod}
      featurePeriod={currentMaterialExecutionPeriod}
    />
  )
}

const InventoryMaterialConfirmationMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const { resource } = useResource({
    subject: "取り込み済の循環棚卸オーダーの取得",
    hideErrorMessage: true,
    fetch:
      arcadeCd && currentInventoryPeriod
        ? () => getInventoryCsvExport(arcadeCd, currentInventoryPeriod.id)
        : undefined,
    recoilKey: `getInventoryCsvExport:${arcadeCd}:${JSON.stringify(
      currentInventoryPeriod,
    )}`,
  })
  const exportedCsv = resource?.data.csv

  const { resource: statusesResource } = useResource({
    subject: "棚卸実施状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? async () => {
            return await getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementTypeWithAll.All,
              currentMaterialExecutionPeriod.id,
            )
          }
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${
      MaterialPlacementTypeWithAll.All
    }:${JSON.stringify(currentMaterialExecutionPeriod)}`,
  })
  const placementStatusRes = statusesResource?.data
  const notExecutedPlacements = useMemo(
    () =>
      placementStatusRes
        ? getNotExecutedPlacements(placementStatusRes).filter(
            (p) => p.placementName !== "荷捌き",
          )
        : [],
    [placementStatusRes],
  )

  const { resource: differencesResource } = useResource({
    subject: "差分情報の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? async () => {
            return await getMaterialInventoryDifferences(
              arcadeCd,
              currentMaterialExecutionPeriod.id,
            )
          }
        : undefined,
    recoilKey: `getMaterialInventoryDifferences:${arcadeCd}:${JSON.stringify(
      currentMaterialExecutionPeriod,
    )}`,
  })

  const differencesRes = differencesResource?.data
  const differences = useMemo(
    () => differencesRes?.differences || [],
    [differencesRes],
  )
  const { NotInGigoNavi, DiffPriceOver } = MaterialInventoryDifferenceGroupEnum
  const differencesNotInGigoNavi = differences.filter(
    (difference) => difference.group === NotInGigoNavi,
  )
  const differencesNotApproved = differences.filter(
    (difference) =>
      difference.group === DiffPriceOver && !difference.isApproved,
  )

  const { isAvailableInventoryExecutionPeriodEnd } = useUserRole()
  // 棚卸確定が可能か判定
  // 1. (荷捌きを除く)全ての場所の実査が完了している
  // 2. 差分確認
  //  - GiGONAVI に存在しない差分がない
  //  - 戻しが10万円を超える差分が全て承認済み
  const isEndableExecution =
    exportedCsv &&
    notExecutedPlacements.length === 0 &&
    differencesNotInGigoNavi.length === 0 &&
    differencesNotApproved.length === 0
  const isSubmittable =
    isEndableExecution && isAvailableInventoryExecutionPeriodEnd

  const setCurrentArcade = useSetRecoilState(currentArcadeState)
  const [showModal, setShowModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmit: SubmitHandler<InventoryExecutionPeriodEndFormInput> = async (
    data,
  ) => {
    if (!arcadeCd) return
    if (!isAvailableInventoryExecutionPeriodEnd) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!currentInventoryPeriod) {
      return setErrorMessage("現在の棚卸期間が取得できませんでした")
    }
    const request: PostMaterialInventoryExecutionPeriodEndRequest = {
      note: data.note ?? "",
    }
    submitPromises([
      {
        subject: "棚卸確定処理",
        showSuccessMessage: true,
        promise: async () => {
          await postMaterialInventoryExecutionPeriodEnd(arcadeCd, request)

          const {
            data: { arcade },
          } = await getArcade(arcadeCd)
          setCurrentArcade(arcade)
          setShowModal(false)
        },
      },
    ])
  }

  return (
    <>
      {!exportedCsv && (
        <Grid item xs={12} data-testid="exportedCsv-section">
          <Card sx={{ px: 2, py: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="error.main">
                循環棚卸オーダーが取り込まれていません。
              </Typography>
              <Button
                variant="contained"
                sx={{ px: 1, py: 0.5, whiteSpace: "nowrap" }}
                onClick={() =>
                  navigate(`/arcades/${arcadeCd}/inventory/seams/import`)
                }
              >
                ページに移動
              </Button>
            </Stack>
          </Card>
        </Grid>
      )}
      {notExecutedPlacements.length > 0 && (
        <Grid item xs={12} data-testid="notExecutedPlacements-section">
          <Accordion>
            <AccordionSummaryWithButton
              label="対象をみる"
              labelExpanded="　閉じる　"
            >
              <Typography variant="caption" color="error.main">
                棚卸が完了していない棚・材料機械があります。
                <br />
                （荷捌きは棚卸未完了でも確定可能）
              </Typography>
            </AccordionSummaryWithButton>

            <AccordionDetails>
              <PaginatedTable
                scrollableX
                noMargin
                items={notExecutedPlacements}
                stateKey="inventoryConfirmationNotExecutedPlacements"
                header={
                  <TableHead>
                    <TableRow sx={{ th: { px: 1, whiteSpace: "nowrap" } }}>
                      <ExtTableCell>外／内</ExtTableCell>
                      <ExtTableCell>棚・材料機械名</ExtTableCell>
                    </TableRow>
                  </TableHead>
                }
                renderRow={({ placementType, placementName }) => {
                  return (
                    <TableBorderedRow
                      sx={{
                        td: {
                          p: 1,
                          whiteSpace: "nowrap",
                        },
                      }}
                      key={`${placementType}-${placementName}`}
                      data-testid={`${placementType}-${placementName}`}
                    >
                      <ExtTableCell>
                        {materialPlacementTypeNames[placementType]}
                      </ExtTableCell>
                      <ExtTableCell>{placementName}</ExtTableCell>
                    </TableBorderedRow>
                  )
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {differencesNotInGigoNavi.length > 0 && (
        <Grid item xs={12} data-testid="differencesNotInGigoNavi-section">
          <Accordion>
            <AccordionSummaryWithButton
              label="対象をみる"
              labelExpanded="　閉じる　"
            >
              <Typography variant="caption" color="error.main">
                循環棚卸オーダーの材料でGiGO
                NAVIに登録されておらず、棚卸が確認できないものがあります。
              </Typography>
            </AccordionSummaryWithButton>

            <AccordionDetails>
              <InventoryMaterialConfirmationDifferencesTable
                differences={differencesNotInGigoNavi}
                stateKey="inventoryConfirmationDifferencesNotInGigoNavi"
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {differencesNotApproved.length > 0 && (
        <Grid item xs={12} data-testid="differencesNotApproved-section">
          <Card sx={{ px: 2 }}>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="caption" color="error.main">
                承認が完了していない「戻しが10万円を超える項目」があります。
              </Typography>
              <Button
                variant="contained"
                color="error"
                sx={{ my: 1 }}
                onClick={() =>
                  navigate(
                    `/arcades/${arcadeCd}/inventory/materials/differences`,
                  )
                }
              >
                対象画面に遷移
              </Button>
            </Stack>
          </Card>
        </Grid>
      )}
      {isEndableExecution && (
        <Grid item xs={12} data-testid="inventoryCompleted-section">
          <Typography variant="caption" color="primary.main">
            全ての棚・材料機械の棚卸が完了しています。
            <br />
            （荷捌きは棚卸未完了でも確定可能）
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setShowModal(true)}
          disabled={!isSubmittable}
        >
          材料の棚卸確定
        </Button>
      </Grid>

      <InventoryMaterialExecutionPeriodEndModal
        showModal={showModal}
        onSubmit={onSubmit}
        onClose={() => setShowModal(false)}
        isSubmitting={isSubmitting}
        totalInventoryPrices={differencesRes?.totalInventoryPrices}
      />
    </>
  )
}

interface InventoryConfirmationDifferencesTableProps {
  differences: MaterialInventoryDifference[]
  stateKey: string
}

export const InventoryMaterialConfirmationDifferencesTable: React.FC<
  InventoryConfirmationDifferencesTableProps
> = ({ differences, stateKey }: InventoryConfirmationDifferencesTableProps) => {
  const materialNameGradation =
    "-webkit-gradient(linear, 95% 0%, 98% 0%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))"
  const getLines = (itemName: string[]) =>
    itemName.map((line, i) => <p key={`${i}-${line}`}>{line}</p>)

  return (
    <PaginatedTable
      scrollableX
      noMargin
      items={differences}
      stateKey={stateKey}
      header={
        <TableHead>
          <TableRow
            sx={{ th: { px: 1, whiteSpace: "nowrap", textAlign: "center" } }}
          >
            <ExtTableCell>
              {getLines(materialDifferenceTableHeaders.materialCd.label)}
            </ExtTableCell>
            <ExtTableCell>
              {getLines(
                materialDifferenceTableHeaders.materialCdsInSeams.label,
              )}
            </ExtTableCell>
            <ExtTableCell sx={{ textAlign: "start !important" }}>
              {getLines(materialDifferenceTableHeaders.materialName.label)}
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(difference) => {
        const item = getMaterialDifferenceLabel(difference)
        return (
          <TableBorderedRow
            sx={{
              td: {
                p: 1,
                textAlign: "center",
              },
            }}
            key={`${difference.materialCd}-${difference.materialCdsInSeams.join(
              "/",
            )}`}
            data-testid={`${
              difference.materialCd
            }-${difference.materialCdsInSeams.join("/")}`}
          >
            <ExtTableCell>{item.materialCd}</ExtTableCell>
            <ExtTableCell>
              {item.materialCdsInSeams.map((materialCdInSeams) => (
                <Box key={materialCdInSeams}>{materialCdInSeams}</Box>
              ))}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                textAlign: "start !important",
                whiteSpace: "nowrap",
                maxWidth: 400,
                WebkitMaskImage: materialNameGradation,
              }}
            >
              <CardItemNameBox>{item.materialName}</CardItemNameBox>
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
