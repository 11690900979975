/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeToneBoothSalesParams,
  GetPrizeToneBoothSalesResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ブース売上情報を取得
 */
export const getPrizeToneBoothSales = (
  arcadeCd: string,
  machineName: string,
  boothName: string,
  params: GetPrizeToneBoothSalesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeToneBoothSalesResponse>(
    {
      url: `/arcades/${arcadeCd}/tone/boothSales/${machineName}/${boothName}`,
      method: "GET",
      params,
    },
    options,
  )
}
export type GetPrizeToneBoothSalesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeToneBoothSales>>
>
