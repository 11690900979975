import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialInventoryHistories } from "src/api/material-inventory-histories"
import { getMaterialInventoryPlacementStatuses } from "src/api/material-inventory-placement-statuses"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { getMaterialStorage } from "src/api/material-storages"
import { MaterialShelf, MaterialStorage } from "src/api/models"
import { inventoryMaterialExecuteSingleSearchParamsState } from "src/components/organisms/materials/InventoryMaterialExecuteSingleFilter"
import {
  InventoryMaterialExecuteSingle,
  InventoryMaterialExecuteSingleMenuItem,
} from "src/components/templates/InventoryMaterialExecuteSingle"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findCurrentFeatureExecutionPeriod } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { sortPlacementStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { currentInventoryPeriodState } from "src/recoil"

export const InventoryMaterialExecuteStorageDetails = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const storageReturn = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
  })
  const storage = storageReturn?.resource?.data.storage
  const shelves = storageReturn?.resource?.data.shelves
  const shelf = shelves?.find((shelf) => shelf.id.toString() === shelfId)
  const placementName = storage && shelf && `${storage.name} ${shelf.name}`

  return (
    <MainContentLayout
      title={placementName ? placementName + " の棚卸" : ""}
      renderContent={() => (
        <InventoryMaterialExecuteStorageDetailsMenu
          storage={storage}
          shelf={shelf}
          refetchStorage={storageReturn.refetch}
        />
      )}
      disableBackButton
    />
  )
}
type InventoryExecuteStorageDetailsMenuProps = {
  storage?: MaterialStorage
  shelf?: MaterialShelf
  refetchStorage: () => void
}

const InventoryMaterialExecuteStorageDetailsMenu: React.FC<
  InventoryExecuteStorageDetailsMenuProps
> = ({ storage, shelf, refetchStorage }) => {
  const { arcadeCd, shelfId } = useParams()

  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)
  const currentMaterialExecutionPeriod = findCurrentFeatureExecutionPeriod(
    currentInventoryPeriod?.materialExecutionPeriods || [],
  )

  const { resource: statusesResource, refetch: refetchStatuses } = useResource({
    subject: "棚卸状況の取得",
    fetch:
      arcadeCd && currentMaterialExecutionPeriod
        ? async () => {
            if (!shelf) return
            return await getMaterialInventoryPlacementStatuses(
              arcadeCd,
              MaterialPlacementType.Storage,
              currentMaterialExecutionPeriod.id,
              { placementId: shelf.id },
            )
          }
        : undefined,
    recoilKey: `getMaterialInventoryPlacementStatuses:${arcadeCd}:${MaterialPlacementType.Storage}:${currentMaterialExecutionPeriod?.id}:${shelf?.id}`,
  })
  const shelfStatuses = useMemo(
    () => statusesResource?.data.shelfStatuses || [],
    [statusesResource],
  )

  const searchParams = useRecoilValue(
    inventoryMaterialExecuteSingleSearchParamsState,
  )
  const { resource: historiesResource, refetch: refetchHistories } =
    useResource({
      subject: "棚卸実行記録の取得",
      fetch:
        arcadeCd && currentMaterialExecutionPeriod
          ? () =>
              getMaterialInventoryHistories(
                arcadeCd,
                currentMaterialExecutionPeriod.id,
                {
                  materialCd: searchParams.materialCd,
                  materialName: searchParams.materialName,
                  materialNameKana: searchParams.materialNameKana,
                  ipName: searchParams.ipName,
                  makerName: searchParams.makerName,
                },
              )
          : undefined,
      recoilKey: `getMaterialInventoryHistories:${arcadeCd}:${currentMaterialExecutionPeriod?.id}:${JSON.stringify(
        searchParams,
      )}`,
    })
  const histories = historiesResource?.data.histories

  const { resource: stocksResource, refetch: refetchStocks } = useResource({
    subject: "在庫検索結果の取得",
    fetch:
      arcadeCd && shelfId
        ? () =>
            getMaterialOperationStocks(arcadeCd, {
              materialName: searchParams.materialName,
              materialNameKana: searchParams.materialNameKana,
              materialCd: searchParams.materialCd,
              shelfIds: [Number(shelfId)],
              ipName: searchParams.ipName,
              makerName: searchParams.makerName,
            })
        : undefined,
    recoilKey: `getMaterialOperationStocks:${arcadeCd}:${shelfId}:${JSON.stringify(
      searchParams,
    )}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = stocksResource?.data.stocks
    if (stocks && stocks.length > 0) {
      return sortPlacementStocks(
        stocks.flatMap((s) =>
          s.shelfStocks.map((shelfStock) => ({
            material: s.material,
            ...shelfStock,
          })),
        ),
        searchParams,
      )
    } else {
      return []
    }
  }, [searchParams, stocksResource])

  const refetch = () => {
    refetchStorage()
    refetchStatuses()
    refetchHistories()
    refetchStocks()
  }

  const menuItems: InventoryMaterialExecuteSingleMenuItem[] = useMemo(() => {
    const shelfStocks =
      (shelf &&
        filteredStocks
          ?.map((shelfStock) => {
            const {
              material: { materialName, materialCd, unitPerCarton },
              shelfStock: { id },
            } = shelfStock
            const material = {
              materialCd,
              materialName,
              unitPerCarton,
            }
            const history = histories?.find(
              (history) => history.shelfStock?.id === id,
            )
            const executedStock = history?.stock
            const executed = !!history
            return {
              material,
              anyStock: shelfStock.shelfStock.stock,
              placementStockId: shelfStock.shelfStock.id,
              placementType: MaterialPlacementType.Storage,
              executedStock,
              executed,
              executedAt: history?.executedAt,
            }
          })
          .filter(Boolean)) ??
      []

    const stockMaterialCds = shelfStocks.map((s) => s.material.materialCd)
    const { Storage } = MaterialPlacementType
    const historiesWithoutShelfStock = (histories || [])
      .filter((h) => h.placement === Storage && h.placementId === shelf?.id)
      .filter((h) => !stockMaterialCds.includes(h.materialCd))
      .filter((h) => !h.shelfStock || h.shelfStock.stock <= 0)
      .map((h) => ({
        material: {
          materialCd: h.materialCd,
          materialName: h.material.materialName,
        },
        placementType: MaterialPlacementType.Storage,
        executedStock: h.stock,
        executed: true,
        executedAt: h.executedAt,
      }))

    return [...shelfStocks, ...historiesWithoutShelfStock]
  }, [filteredStocks, histories, shelf])

  return (
    <InventoryMaterialExecuteSingle
      placementType="storage"
      placementId={shelf?.id}
      storageId={storage?.id}
      placementStatus={shelfStatuses[0]}
      placementStocks={filteredStocks}
      menuItems={menuItems}
      histories={histories}
      onFinish={() => refetch()}
    />
  )
}
