/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * AND / OR 条件。デフォルトは OR
 */
export type ArcadeAlertDefaultValueConjunctionEnum =
  (typeof ArcadeAlertDefaultValueConjunctionEnum)[keyof typeof ArcadeAlertDefaultValueConjunctionEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ArcadeAlertDefaultValueConjunctionEnum = {
  And: "and",
  Or: "or",
} as const
