import { Box, CircularProgress } from "@mui/material"

interface LoadingBoxProps {
  relative?: boolean
}

export const LoadingBox: React.FC<LoadingBoxProps> = ({
  relative = false,
}: LoadingBoxProps) => {
  return (
    <Box
      sx={{
        ...(relative
          ? { position: "relative" }
          : { position: "absolute", top: 0, left: 0 }),
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  )
}
