import {
  GetMaterialInventoryPlacementStatusesResponse,
  MaterialInventoryPlacementStatusExecutionStatusEnum,
} from "src/api/models"

// NOTE: API 由来の PlacementEnum が複数あるのを共通化するための型
export const MaterialPlacementType = {
  Storage: "storage",
  InMachine: "in_machine",
} as const
export type MaterialPlacementType =
  (typeof MaterialPlacementType)[keyof typeof MaterialPlacementType]

export const MaterialPlacementTypeWithAll = {
  ...MaterialPlacementType,
  All: "all",
} as const
export type MaterialPlacementTypeWithAll =
  (typeof MaterialPlacementTypeWithAll)[keyof typeof MaterialPlacementTypeWithAll]

export const getMaterialPlacementTypeLabel = (
  materialPlacementType: MaterialPlacementType,
) => {
  if (materialPlacementType === "storage") {
    return "棚"
  }
  if (materialPlacementType === "in_machine") {
    return "材料機械内"
  }
  return ""
}

export const getMaterialPlacementShelfOrMachineLabel = (
  placementType: MaterialPlacementType,
) => {
  if (placementType === "storage") {
    return "棚"
  }
  if (placementType === "in_machine") {
    return "材料機械"
  }
  return ""
}

export const getMaterialPlacementTypeText = (
  placementType: MaterialPlacementType,
) => {
  if (placementType === MaterialPlacementType.Storage) {
    return "外"
  }
  if (placementType === MaterialPlacementType.InMachine) {
    return "内"
  }
  const _never: never = placementType
  return ""
}

export const getMaterialExecutionStatusText = (
  executionStatus: MaterialInventoryPlacementStatusExecutionStatusEnum,
) => {
  if (
    executionStatus ===
    MaterialInventoryPlacementStatusExecutionStatusEnum.Empty
  ) {
    return "空"
  }
  if (
    executionStatus ===
    MaterialInventoryPlacementStatusExecutionStatusEnum.Executed
  ) {
    return "済"
  }
  if (
    executionStatus ===
    MaterialInventoryPlacementStatusExecutionStatusEnum.NotExecuted
  ) {
    return "null"
  }
  return "null"
}

export const getNotExecutedPlacements = (
  placementStatusRes: GetMaterialInventoryPlacementStatusesResponse,
): {
  placementType: MaterialPlacementType
  placementName: string
}[] => {
  const { shelfStatuses, machineStatuses } = placementStatusRes || {}
  const { NotExecuted } = MaterialInventoryPlacementStatusExecutionStatusEnum
  const { Storage, InMachine } = MaterialPlacementType
  return [
    ...(shelfStatuses || [])
      .filter((s) => s.isAvailable && s.executionStatus === NotExecuted)
      .map(({ placementName }) => ({
        placementType: Storage,
        placementName,
      })),
    ...(machineStatuses || [])
      .filter((s) => s.isAvailable && s.executionStatus === NotExecuted)
      .map(({ placementName }) => ({
        placementType: InMachine,
        placementName,
      })),
  ]
}

export const materialPlacementFilterSortByLabels = {
  phoneticOrderAsc: "あいうえお順",
  phoneticOrderDesc: "あいうえお順（降順）",
  phoneticIpOrderAsc: "IP順",
  phoneticIpOrderDesc: "IP順（降順）",
} as const

export const PlacementValidation = {
  Valid: "valid",
  Invalid: "invalid",
  All: "all",
} as const
export type PlacementValidation =
  (typeof PlacementValidation)[keyof typeof PlacementValidation]

export const PlacementTypeWithAll = {
  ...MaterialPlacementType,
  All: "all",
} as const
export type PlacementTypeWithAll =
  (typeof PlacementTypeWithAll)[keyof typeof PlacementTypeWithAll]
