import { TextField, MenuItem, Select } from "@mui/material"
import { useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { operationStockFilterSortByLabels } from "src/domains/prizes/inventoryStockRepository"
import { inventoryTemporaryStoragesSearchParamsState } from "src/recoil/inventory"

export const InventoryPrizeTemporaryStoragesFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryTemporaryStoragesSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.prizeCd ||
          searchParams.prizeName ||
          searchParams.prizeNameKana ||
          searchParams.makerName ||
          searchParams.ipName ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField id={field.name} {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },

        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(operationStockFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
