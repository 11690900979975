import { useParams } from "react-router-dom"

import { InventoryMaterialStockDetails } from "src/components//templates/InventoryMaterialStockDetails"
import { MainContentLayout } from "src/components/templates/MainContentLayout"

export const InventoryMaterialVerifyTemporaryDetails: React.FC = () => {
  const { materialCd } = useParams()

  return (
    <MainContentLayout
      title="在庫確認・店内移動"
      subtitle="荷捌き中の材料の確認・移動"
      renderContent={() => (
        <InventoryMaterialStockDetails
          materialCd={materialCd ?? ""}
          isTemporaryStorages
        />
      )}
      backButtonLabel="一覧画面に戻る"
    />
  )
}
