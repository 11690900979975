import { useCallback, useMemo, useState } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  Button,
  Switch,
  TableHead,
  TableRow,
  DialogContent,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PrizeFloorMapPoint,
  PrizePlacementAvailability,
  PrizeShelf,
  PrizeStorage,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { patchPrizePlacementAvailabilities } from "src/api/prize-placement-availabilities"
import { getPrizeStorages } from "src/api/prize-storages"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  filterPlacements,
  InventoryPrizePlacementsFilter,
} from "src/components/organisms/prizes/InventoryPrizePlacementsFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PlacementStock,
  temporaryStorageName,
} from "src/domains/prizes/inventoryStockRepository"
import {
  convertPrizePlacementTypeToURLPath,
  PlacementType,
  PlacementValidation,
} from "src/domains/prizes/placementStatusRepository"
import { useInventoryPlacementsCsv } from "src/hooks/useInventoryPlacementsCsv"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { inventoryPlacementsSearchParamsState } from "src/recoil/inventory"
import { theme } from "src/theme"

export type InventoryPrizePlacementsSearchParams = {
  name?: string
  placementType?: PlacementType
  placementValidation?: PlacementValidation
}

export interface Placement {
  placementType: PlacementType
  placementId: number
  shelfOrPointId: number
  name: string
  isAvailable: boolean
  storage?: PrizeStorage
  shelf?: PrizeShelf
  floorMapPoint?: PrizeFloorMapPoint
  placementStocks?: PlacementStock[]
}

export const InventoryPrizePlacements = () => {
  const [showSwitches, setShowSwitches] = useState(false)
  const { isEditablePlacementAvailability } = useUserRole()

  return (
    <MainContentLayout
      title="棚・ブース一覧"
      renderAction={() =>
        isEditablePlacementAvailability ? (
          <Button
            variant="contained"
            onClick={() => setShowSwitches(!showSwitches)}
          >
            {showSwitches ? "変更しない" : "有効/無効変更"}
          </Button>
        ) : undefined
      }
      renderFilter={() => <InventoryPrizePlacementsFilter />}
      renderContent={() => (
        <InventoryPrizePlacementsMenu {...{ showSwitches, setShowSwitches }} />
      )}
    />
  )
}

interface InventoryPrizePlacementsMenuProps {
  showSwitches: boolean
  setShowSwitches: React.Dispatch<React.SetStateAction<boolean>>
}

const InventoryPrizePlacementsMenu: React.FC<
  InventoryPrizePlacementsMenuProps
> = ({ showSwitches, setShowSwitches }) => {
  const { arcadeCd } = useParams()

  const { resource: storagesResource, refetch: refetchStorages } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  })
  const storages = useMemo(
    () => storagesResource?.data.storages || [],
    [storagesResource],
  )

  const { resource: floorMapPointsResource, refetch: refetchFloorMapPoints } =
    useResource({
      subject: "ブースリストの取得",
      fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
      recoilKey: `getInventoryFloorMap:${arcadeCd}`,
    })
  const floorMapPoints = useMemo(
    () => floorMapPointsResource?.data.floorMapPoints || [],
    [floorMapPointsResource],
  )

  const { resource: inventoryStocksResource, refetch: refetchInventoryStocks } =
    useResource({
      subject: "在庫検索結果の取得",
      fetch: arcadeCd ? () => getPrizeOperationStocks(arcadeCd) : undefined,
      recoilKey: `getInventoryStocks:${arcadeCd}`,
    })
  const { shelfStocks, boothStocks, onBoothStocks } = useMemo(() => {
    const stocks = inventoryStocksResource?.data.stocks || []
    return {
      shelfStocks: stocks.flatMap((stock) => stock.shelfStocks),
      boothStocks: stocks.flatMap((stock) => stock.boothStocks),
      onBoothStocks: stocks.flatMap((stock) => stock.onBoothStocks),
    }
  }, [inventoryStocksResource])

  const refetch = () => {
    refetchStorages()
    refetchFloorMapPoints()
    refetchInventoryStocks()
  }

  const placements = useMemo(() => {
    const { Storage, InBooth, OnBooth } = PlacementType
    return [
      ...storages.flatMap<Placement>(({ storage, shelves }) =>
        (shelves || []).map((shelf) => ({
          placementType: Storage,
          placementId: shelf.id,
          shelfOrPointId: shelf.id,
          name: `${storage.name} ${shelf.name}`,
          isAvailable: shelf.isAvailable,
          storage,
          shelf,
          placementStocks: shelfStocks.filter(
            (shelfStock) => shelfStock.shelfId === shelf.id,
          ),
        })),
      ),
      ...floorMapPoints.flatMap<Placement>((floorMapPoint) => [
        {
          placementType: InBooth,
          placementId: floorMapPoint.boothShelf.id,
          shelfOrPointId: floorMapPoint.id,
          name: floorMapPoint.name,
          isAvailable: floorMapPoint.boothShelf.isAvailable,
          floorMapPoint,
          placementStocks: boothStocks.filter(
            (boothStock) =>
              boothStock.boothShelfId === floorMapPoint.boothShelf.id,
          ),
        },
        {
          placementType: OnBooth,
          placementId: floorMapPoint.onBoothShelf.id,
          shelfOrPointId: floorMapPoint.id,
          name: floorMapPoint.name,
          isAvailable: floorMapPoint.onBoothShelf.isAvailable,
          floorMapPoint,
          placementStocks: onBoothStocks.filter(
            (onBoothStock) =>
              onBoothStock.onBoothShelfId === floorMapPoint.onBoothShelf.id,
          ),
        },
      ]),
    ]
  }, [storages, floorMapPoints, shelfStocks, boothStocks, onBoothStocks])

  const [placementAvailabilities, setPlacementAvailabilities] = useState<
    PrizePlacementAvailability[]
  >([])
  const switchedPlacements = useMemo(
    () =>
      placementAvailabilities
        .map((availability) =>
          placements.find(
            (placement) =>
              placement.placementType === availability.placementType &&
              placement.placementId === availability.placementId,
          ),
        )
        .filter((placement): placement is Placement => !!placement),
    [placementAvailabilities, placements],
  )

  const searchParams = useRecoilValue(inventoryPlacementsSearchParamsState)
  const filteredPlacements = useMemo(
    () => filterPlacements(placements, searchParams),
    [placements, searchParams],
  )

  const { downloadPlacementsCsv } = useInventoryPlacementsCsv()

  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmitAvailabilities = () => {
    const request = {
      placements: placementAvailabilities,
    }

    arcadeCd &&
      submitPromises([
        {
          subject: "有効/無効の更新",
          showSuccessMessage: true,
          promise: async () => {
            await patchPrizePlacementAvailabilities(arcadeCd, request)
            setPlacementAvailabilities([])
            setShowSwitches(false)
            setShowAvailabilityModal(false)
            refetch()
          },
        },
      ])
  }

  return (
    <Stack gap={2}>
      <InventoryPrizePlacementsTable
        {...{
          filteredPlacements,
          switchedPlacements,
          setPlacementAvailabilities,
          showSwitches,
        }}
      />

      {showSwitches ? (
        <Box
          sx={{
            background: "white",
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            position: "sticky",
            bottom: 0,
            left: 0,
          }}
        >
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => setShowSwitches(false)}
            sx={{ mr: 1 }}
          >
            キャンセル
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowAvailabilityModal(true)}
            disabled={placementAvailabilities.length === 0}
            sx={{ ml: 1 }}
          >
            確定
          </Button>
        </Box>
      ) : (
        <Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={() => downloadPlacementsCsv(filteredPlacements)}
          >
            URLをCSVに出力する
          </Button>
        </Stack>
      )}

      <PlacementAvailabilitiesModal
        showModal={showAvailabilityModal}
        onSubmit={() => onSubmitAvailabilities()}
        onClose={() => setShowAvailabilityModal(false)}
        isSubmitting={isSubmitting}
        switchedPlacements={switchedPlacements}
      />
    </Stack>
  )
}

type InventoryPrizePlacementsTableProps = {
  filteredPlacements: Placement[]
  switchedPlacements: Placement[]
  setPlacementAvailabilities: React.Dispatch<
    React.SetStateAction<PrizePlacementAvailability[]>
  >
  showSwitches: boolean
}

const InventoryPrizePlacementsTable: React.FC<
  InventoryPrizePlacementsTableProps
> = ({
  filteredPlacements,
  switchedPlacements,
  setPlacementAvailabilities,
  showSwitches,
}) => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()
  const { Storage, InBooth, OnBooth } = PlacementType

  const placementHasStocks = (placement: Placement) =>
    (placement.placementStocks || []).length > 0

  const isPlacementSwitched = (placement: Placement) =>
    switchedPlacements.some(
      (switchedPlacement) =>
        switchedPlacement.placementType === placement.placementType &&
        switchedPlacement.placementId === placement.placementId,
    )

  const onClickSwitch = (placement: Placement) => {
    const { placementType, placementId, isAvailable } = placement
    const isAlreadySwitched = isPlacementSwitched(placement)
    setPlacementAvailabilities((availabilities) =>
      isAlreadySwitched
        ? availabilities.filter(
            (availability) =>
              !(
                availability.placementType === placementType &&
                availability.placementId === placementId
              ),
          )
        : [
            ...availabilities,
            {
              placementId,
              placementType,
              isAvailable: !isAvailable,
            },
          ],
    )
  }

  const isDisabled = useCallback((placement: Placement): boolean => {
    return (
      (placementHasStocks(placement) && placement.isAvailable) ||
      placement.storage?.name === temporaryStorageName
    )
  }, [])

  const [isAllSwitched, setIsAllSwitched] = useState(true)
  const onClickAllSwitch = useCallback(() => {
    const updatedAvailabilities = filteredPlacements.reduce(
      (availabilities: PrizePlacementAvailability[], placement) => {
        const { placementType, placementId, isAvailable } = placement
        const availability = {
          placementId,
          placementType,
          isAvailable: !isAllSwitched,
        }
        if (!isDisabled(placement) && isAvailable === isAllSwitched) {
          return [...availabilities, availability]
        }
        return availabilities
      },
      [],
    )
    setPlacementAvailabilities(updatedAvailabilities)
    setIsAllSwitched((isAllSwitched) => !isAllSwitched)
  }, [
    isAllSwitched,
    filteredPlacements,
    isDisabled,
    setPlacementAvailabilities,
  ])

  const handleOnClick = useCallback(
    (placement: Placement) => {
      const { placementType, shelfOrPointId, isAvailable, storage, shelf } =
        placement
      if (!isAvailable) return

      if (placementType === PlacementType.Storage)
        return navigate(
          `/arcades/${arcadeCd}/prizes/placements/storage/${storage?.id}/${shelf?.id}`,
        )

      navigate(
        `/arcades/${arcadeCd}/prizes/placements/${convertPrizePlacementTypeToURLPath(
          placementType,
        )}/${shelfOrPointId}`,
      )
    },
    [arcadeCd, navigate],
  )

  return (
    <PaginatedTable
      noMargin
      items={filteredPlacements}
      stateKey="inventoryPlacementsTable"
      header={
        <TableHead>
          <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
            {showSwitches && (
              <ExtTableCell>
                <Switch
                  onClick={() => onClickAllSwitch()}
                  checked={isAllSwitched}
                />
              </ExtTableCell>
            )}
            <ExtTableCell>
              有効/
              <br />
              無効
            </ExtTableCell>
            <ExtTableCell>区分</ExtTableCell>
            <ExtTableCell>名前</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(placement) => {
        const { placementType, shelfOrPointId, name, isAvailable } = placement
        const isAvailableAfterSwitching = !isAvailable
        const isSwitched = isPlacementSwitched(placement)

        return (
          <TableBorderedRow
            key={`${placementType}-${shelfOrPointId}-${name}`}
            data-testid={`${placementType}-${shelfOrPointId}-${name}`}
            sx={{ ...(!isAvailable && { background: theme.palette.gray[20] }) }}
          >
            {showSwitches && (
              <ExtTableCell sx={{ p: 0 }}>
                <Switch
                  onClick={() => onClickSwitch(placement)}
                  checked={isSwitched ? isAvailableAfterSwitching : isAvailable}
                  disabled={isDisabled(placement)}
                />
              </ExtTableCell>
            )}
            <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
              {isSwitched ? (
                isAvailableAfterSwitching ? (
                  <Typography
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      p: 1,
                    }}
                    variant="subtitle1"
                  >
                    有効
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      background: theme.palette.text.disabled,
                      color: "white",
                      p: 1,
                    }}
                    variant="subtitle1"
                  >
                    無効
                  </Typography>
                )
              ) : isAvailable ? (
                <Typography color="primary.main" p={1} variant="subtitle1">
                  有効
                </Typography>
              ) : (
                <Typography color="text.disabled" p={1} variant="subtitle1">
                  無効
                </Typography>
              )}
            </ExtTableCell>
            <ExtTableCell sx={{ p: 1 }}>
              {placementType === Storage && "外"}
              {placementType === InBooth && (
                <strong style={{ color: theme.palette.error.main }}>内</strong>
              )}
              {placementType === OnBooth && (
                <strong style={{ color: theme.palette.error.main }}>上</strong>
              )}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  background: (theme) => theme.palette.neutral[200],
                },
                p: 1,
              }}
              onClick={() => handleOnClick(placement)}
            >
              {name}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}

type PlacementAvailabilitiesModalProps = {
  showModal: boolean
  onSubmit: () => void
  onClose: () => void
  isSubmitting: boolean
  switchedPlacements: Placement[]
}

const PlacementAvailabilitiesModal: React.FC<
  PlacementAvailabilitiesModalProps
> = ({
  showModal,
  onSubmit,
  onClose,
  isSubmitting,
  switchedPlacements,
}: PlacementAvailabilitiesModalProps) => {
  const { Storage, InBooth, OnBooth } = PlacementType

  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="h1">
            本当に変更しますか？
          </Typography>

          <PaginatedTable
            noMargin
            items={switchedPlacements}
            header={
              <TableHead>
                <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
                  <ExtTableCell>変更後</ExtTableCell>
                  <ExtTableCell>区分</ExtTableCell>
                  <ExtTableCell>名前</ExtTableCell>
                </TableRow>
              </TableHead>
            }
            renderRow={(placement) => {
              const { placementType, shelfOrPointId, name, isAvailable } =
                placement
              const isAvailableAfterSwitching = !isAvailable

              return (
                <TableBorderedRow
                  key={`${placementType}-${shelfOrPointId}-${name}`}
                >
                  <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                    {isAvailableAfterSwitching ? (
                      <Typography
                        sx={{
                          background: theme.palette.primary.main,
                          color: "white",
                          p: 1,
                        }}
                        variant="subtitle1"
                      >
                        有効
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          background: theme.palette.text.disabled,
                          color: "white",
                          p: 1,
                        }}
                        variant="subtitle1"
                      >
                        無効
                      </Typography>
                    )}
                  </ExtTableCell>
                  <ExtTableCell sx={{ p: 1 }}>
                    {placementType === Storage && "外"}
                    {placementType === InBooth && (
                      <strong style={{ color: theme.palette.error.main }}>
                        内
                      </strong>
                    )}
                    {placementType === OnBooth && (
                      <strong style={{ color: theme.palette.error.main }}>
                        上
                      </strong>
                    )}
                  </ExtTableCell>
                  <ExtTableCell
                    sx={{
                      p: 1,
                    }}
                  >
                    {name}
                  </ExtTableCell>
                </TableBorderedRow>
              )
            }}
          />
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            fullWidth
            loading={isSubmitting}
            onClick={onSubmit}
          >
            変更する
          </LoadingButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}
