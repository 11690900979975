import { useMemo, useState } from "react"

import { Typography, Button, Link, Card, Stack } from "@mui/material"
import { useParams, Link as RouterLink } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import { getArcade } from "src/api/arcades"
import { postExecutionPeriodsEnd } from "src/api/execution-periods"
import {
  getInventoryCsvExport,
  getInventoryCsvOutput,
} from "src/api/inventory-csv"
import { Csv } from "src/api/models"
import { CsvTable } from "src/components/organisms/CsvTable"
import { InventoryExecutionPeriodEndModal } from "src/components/organisms/InventoryExecutionPeriodEndModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  OUTPUT_REQUIRED_COLUMNS,
  PCS_RIGHT_COLUMN,
  ITEM_CD_LEFT_COLUMN,
} from "src/domains/inventoryCsvRepository"
import { isPeriodEndAlreadySet } from "src/domains/inventoryExecutionPeriodRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  currentArcadeState,
  currentInventoryPeriodState,
  inventoryPeriodsState,
  snackbarErrorMessageState,
} from "src/recoil"
// eslint-disable-next-line strict-dependencies/strict-dependencies
import { getNextSeamsUploadDateTime, getToday } from "src/utils"

export const InventoryCsvOutput = () => {
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const isPeriodEndedToday =
    inventoryPeriods && isPeriodEndAlreadySet(inventoryPeriods)

  return (
    <MainContentLayout
      title="結果出力(SEAMS棚卸データ連携)"
      subtitle={isPeriodEndedToday ? "棚卸結果出力" : undefined}
      caption="※荷捌きの棚卸実施内容は含まれません"
      renderContent={() => (
        <Card sx={{ p: 2 }}>
          {isPeriodEndedToday ? (
            <Stack>
              <Typography variant="caption" color="success.main">
                棚卸確定処理済です。明日から次の棚卸期間が開始します。
              </Typography>
            </Stack>
          ) : (
            <InventoryCsvOutputMenu />
          )}
        </Card>
      )}
    />
  )
}

const InventoryCsvOutputMenu = () => {
  const { arcadeCd } = useParams()

  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const currentInventoryPeriod = useRecoilValue(currentInventoryPeriodState)

  const { resource: exportCsvResource } = useResource({
    subject: "取り込み済の循環棚卸オーダーの取得",
    hideErrorMessage: true,
    fetch:
      arcadeCd && currentInventoryPeriod
        ? () => getInventoryCsvExport(arcadeCd, currentInventoryPeriod.id)
        : undefined,
    recoilKey: `getInventoryCsvExport:${arcadeCd}:${JSON.stringify(
      currentInventoryPeriod,
    )}`,
  })
  const exportCsv = exportCsvResource?.data.csv

  const columnIndexes = useMemo(() => {
    if (!exportCsv) return

    const exportHeader = exportCsv?.headerRow?.columns || []
    // 会社〜保管場所
    const columnIndexes = OUTPUT_REQUIRED_COLUMNS.map((requiredColumn) =>
      exportHeader.findIndex((headerColumn) => headerColumn === requiredColumn),
    )
    if (columnIndexes.includes(-1)) {
      setErrorMessage("出力する列の位置を検出できませんでした")
      return
    }
    // 列名が空白だけど景品CDが入っている列
    const prizeCdIndex =
      exportHeader.findIndex(
        (headerColumn) => headerColumn === ITEM_CD_LEFT_COLUMN,
      ) + 1
    if (prizeCdIndex === 0) {
      setErrorMessage("景品CD列の位置を検出できませんでした")
      return
    }
    columnIndexes.push(prizeCdIndex)
    // 列名が空白だけどPCSが入っている列（全列PCS固定でOK）
    const pcsIndex =
      exportHeader.findIndex(
        (headerColumn) => headerColumn === PCS_RIGHT_COLUMN,
      ) - 1
    if (pcsIndex === -2) {
      setErrorMessage("PCS列の位置を検出できませんでした")
      return
    }
    columnIndexes.push(pcsIndex)

    return columnIndexes
  }, [exportCsv, setErrorMessage])

  const { resource: outputCsvResource } = useResource({
    subject: "棚卸結果の取得",
    fetch:
      arcadeCd && currentInventoryPeriod
        ? async () => {
            if (!exportCsv || !columnIndexes) return
            return await getInventoryCsvOutput(
              arcadeCd,
              currentInventoryPeriod.id,
              { columnIndexes },
            )
          }
        : undefined,
    skip: !exportCsv || !columnIndexes,
    recoilKey: `getInventoryCsvOutput:${arcadeCd}:${JSON.stringify(
      currentInventoryPeriod,
    )}:${JSON.stringify(columnIndexes)}`,
  })
  const outputCsv = outputCsvResource?.data.csv

  const { downloadCsv } = useDownloadCsv()

  const { isAvailableInventoryExecutionPeriodEnd } = useUserRole()
  const setCurrentArcade = useSetRecoilState(currentArcadeState)

  const [showModal, setShowModal] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmit = async (outputCsv: Csv) => {
    if (!arcadeCd) return
    if (!isAvailableInventoryExecutionPeriodEnd) {
      return setErrorMessage("権限の関係で失敗しました")
    }
    if (!currentInventoryPeriod) {
      return setErrorMessage("現在の棚卸期間が取得できませんでした")
    }
    submitPromises([
      {
        subject: "棚卸確定処理",
        showSuccessMessage: true,
        promise: async () => {
          const { startAt } = currentInventoryPeriod
          downloadCsv({
            csv: outputCsv,
            // NOTE: CSV 出力時点では棚卸確定を未実施なので、棚卸期間終了日は getToday() で取得する
            fileName: `inventory_output_${arcadeCd}_${startAt}_${getToday()}.csv`,
          })
          await postExecutionPeriodsEnd(arcadeCd)

          const {
            data: { arcade },
          } = await getArcade(arcadeCd)
          setCurrentArcade(arcade)
          setShowModal(false)
        },
      },
    ])
  }

  const now = getNextSeamsUploadDateTime()

  if (outputCsv) {
    return (
      <>
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption" sx={{ my: 1 }}>
              {`次にGiGO NAVIの棚卸データがSEAMSに連携されるのは ${now} です。`}
              <br />
              ※すべてのデータが反映されるまで20分ほど時間がかかります。
            </Typography>
          </Stack>
          <CsvTable {...{ csv: outputCsv }} />

          <Stack>
            <Button
              variant="contained"
              onClick={() => setShowModal(true)}
              // TODO: add validation with FeatureExecutionPeriods
              disabled={!isAvailableInventoryExecutionPeriodEnd}
            >
              SEAMS棚卸データ連携
            </Button>
          </Stack>
        </Stack>

        <InventoryExecutionPeriodEndModal
          showModal={showModal}
          onSubmit={() => onSubmit(outputCsv)}
          onClose={() => setShowModal(false)}
          isSubmitting={isSubmitting}
        />
      </>
    )
  }

  return (
    <Stack direction="row">
      今月分の循環棚卸オーダーの取り込みがまだの場合は、
      <Link
        component={RouterLink}
        to={`/arcades/${arcadeCd}/inventory/seams/import`}
      >
        こちら
      </Link>
      から作業してください。
    </Stack>
  )
}
