import { useCallback } from "react"

import axios from "axios"
import { useSetRecoilState } from "recoil"

import { isForbidden } from "src/domains/authRepository"
import { LoadingItem } from "src/hooks/useLoading"
import {
  snackbarSuccessMessageState,
  snackbarErrorMessageState,
} from "src/recoil"

export type SubmitItem = LoadingItem

export const useSubmitting = () => {
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  type Result = { success: boolean; error: unknown[] }

  const submitPromises = useCallback(
    async (submitItems: SubmitItem[]): Promise<Result> => {
      let result: Result = { success: true, error: [] }

      if (!setSuccessMessage || !setErrorMessage) {
        return { success: false, error: [] }
      }

      await Promise.all(
        submitItems.map(async (i) => {
          try {
            await i.promise()
            i.showSuccessMessage &&
              setSuccessMessage(`${i.subject}に成功しました`)
          } catch (e) {
            result = { success: false, error: [...result.error, e] }

            if (i.hideErrorMessage) {
              return
            }

            if (axios.isAxiosError(e) && isForbidden(e)) {
              setErrorMessage("権限の関係で失敗しました")
            } else {
              console.log(e)
              setErrorMessage(`${i.subject}に失敗しました`)
            }
          }
        }),
      )

      return result
    },
    [setErrorMessage, setSuccessMessage],
  )

  return { submitPromises }
}
