import { useRecoilState } from "recoil"

import { DateRangePicker } from "src/components/atoms/DateRangePicker"
import { defaultDateRangePickerDateLabelStateSelector } from "src/recoil"

export const DefaultDateRangePicker = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    defaultDateRangePickerDateLabelStateSelector,
  )
  return (
    <DateRangePicker
      dateRangeLabel={dateRangeLabel}
      setDateRangeLabel={setDateRangeLabel}
    />
  )
}
