import { Suspense, useState, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  CircularProgress,
  Card,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  TextField,
  Divider,
  DialogContent,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import {
  PrizeShelf,
  Prize,
  PrizeStorage,
  RequestPrizeOperationMoveBoothPlacementEnum,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import {
  putPrizeOperationMoveBetweenStorages,
  putPrizeOperationMoveToStorage,
} from "src/api/prize-operation-move"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { getPrizeStorages } from "src/api/prize-storages"
import {
  getPlacementMoveFrom,
  StockMoveFrom,
  updateStockMoveFrom,
} from "src/components//templates/InventoryPrizeStockDetails"
import { BackButton } from "src/components/atoms/BackButton"
import { tableHeadStyle } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { temporaryStorageName } from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { snackbarErrorMessageState } from "src/recoil"

export interface ShelfMoveTo {
  storage: PrizeStorage
  shelf: PrizeShelf
}

export interface IdsMoveTo {
  storageId: PrizeStorage["id"]
  shelfId: PrizeShelf["id"]
}

export type InventoryPrizeMoveToShelfFormInput = Partial<IdsMoveTo>

interface InventoryPrizeMoveToShelfProps {
  prize: Prize
  stockMoveFrom: StockMoveFrom
  onFinish: () => void
}

export const InventoryPrizeMoveToShelf: React.FC<
  InventoryPrizeMoveToShelfProps
> = (props) => {
  return (
    <Box>
      <Box mb={2}>移動先の棚を選択してください</Box>

      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        }
      >
        <InventoryPrizeMoveToShelfMenu {...props} />
      </Suspense>
    </Box>
  )
}

const InventoryPrizeMoveToShelfMenu: React.FC<
  InventoryPrizeMoveToShelfProps
> = ({ prize, stockMoveFrom, onFinish }) => {
  const { arcadeCd } = useParams()
  const { resource } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  })
  const storages = resource?.data.storages
  const filteredStorages = storages?.filter(({ storage, shelves }) =>
    prize.isKidsMarket
      ? !!shelves?.some((shelf) => shelf.isKidsMarket) ||
        storage.name === temporaryStorageName
      : true,
  )

  const [idsMoveTo, setIdsMoveTo] = useState<IdsMoveTo>()
  const shelfMoveTo = useMemo(() => {
    const { storageId, shelfId } = idsMoveTo || {}
    const e = storages?.find(({ storage }) => storage.id === storageId)
    const shelf = e?.shelves?.find((s) => s.id === shelfId)

    if (e && shelf) {
      return { storage: e.storage, shelf }
    }
    return
  }, [idsMoveTo, storages])

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InventoryPrizeMoveToShelfFormInput>()

  const value = {
    storageId: useWatch({ control, name: "storageId" }),
    shelfId: useWatch({ control, name: "shelfId" }),
  }
  const shelves =
    filteredStorages
      ?.find(({ storage }) => storage.id === value.storageId)
      ?.shelves?.filter(
        (shelf) =>
          shelf.isAvailable &&
          (prize.isKidsMarket ? shelf.isKidsMarket || shelf.name === "" : true),
      ) || []
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const onSubmit: SubmitHandler<InventoryPrizeMoveToShelfFormInput> = (
    data,
  ) => {
    const { storageId, shelfId } = data

    if (shelfId && shelfId === stockMoveFrom.shelfStock?.shelfId) {
      setErrorMessage("移動前と同じ棚には移動できません")
      return
    }

    if (storageId && shelfId) {
      setIdsMoveTo({ storageId, shelfId })
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 2, mb: 2 }}>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              保管場所
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth error={"storageId" in errors}>
                <Controller
                  name="storageId"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} value={field.value ?? ""}>
                      <MenuItem key="" value="" sx={{ height: 36 }} />
                      {filteredStorages?.map(({ storage }) => (
                        <MenuItem key={storage.id} value={storage.id}>
                          {storage.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.storageId?.message && (
                  <FormHelperText>{errors.storageId?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {shelves.length > 0 && (
              <>
                <Grid item xs={4} pt={1}>
                  棚
                </Grid>
                <Grid item xs={8} pt={1}>
                  <FormControl fullWidth error={"shelfId" in errors}>
                    <Controller
                      name="shelfId"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} value={field.value ?? ""}>
                          <MenuItem key="" value="" />
                          {shelves?.map((shelf) => (
                            <MenuItem key={shelf.id} value={shelf.id}>
                              {shelf.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.shelfId?.message && (
                      <FormHelperText>{errors.shelfId?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Card>

        <Button
          variant="contained"
          type="submit"
          sx={{ mb: 2 }}
          fullWidth
          disabled={!value.storageId || !value.shelfId}
        >
          選択した棚に移動する
        </Button>
      </form>

      {shelfMoveTo && (
        <InventoryMoveToShelfModal
          showModal={!!shelfMoveTo}
          prize={prize}
          defaultStockMoveFrom={stockMoveFrom}
          shelfMoveTo={shelfMoveTo}
          onClose={() => setIdsMoveTo(undefined)}
          onFinish={() => onFinish()}
        />
      )}
    </>
  )
}

export interface InventoryMoveToShelfModalFormInput {
  stock: number
}

interface InventoryMoveToShelfModalProps {
  showModal: boolean
  prize: Prize
  defaultStockMoveFrom: StockMoveFrom
  shelfMoveTo: ShelfMoveTo
  onClose: () => void
  onFinish: () => void
}

export const InventoryMoveToShelfModal: React.FC<
  InventoryMoveToShelfModalProps
> = (props) => {
  const {
    showModal,
    onClose,
    prize: { prizeCd, prizeName },
  } = props

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h1" pb={1}>
          {prizeName}
        </Typography>

        <Box
          sx={{
            maxWidth: "80%",
            my: 1,
            mx: "auto",
          }}
        >
          <PrizeImageBox
            prizeCd={prizeCd}
            alt={prizeName}
            noImageSize="large"
          />
        </Box>
      </DialogTitleWithClose>

      <Suspense fallback={<LoadingBox relative />}>
        <InventoryMoveToShelfModalForm {...props} />
      </Suspense>
    </CustomDialog>
  )
}

const InventoryMoveToShelfModalForm: React.FC<
  InventoryMoveToShelfModalProps
> = ({
  prize,
  defaultStockMoveFrom,
  shelfMoveTo,
  onClose,
  onFinish,
}: InventoryMoveToShelfModalProps) => {
  const { arcadeCd } = useParams()
  const { prizeCd } = prize

  const { resource } = useResource({
    subject: "在庫情報の取得",
    fetch: arcadeCd
      ? () => getPrizeOperationStocks(arcadeCd, { prizeCd })
      : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${prizeCd}`,
  })
  const { stockMoveFrom, shelfStocks } = useMemo(() => {
    const stocks = resource?.data.stocks
    if (!stocks) {
      return {
        stockMoveFrom: defaultStockMoveFrom,
        shelfStocks: [],
      }
    }

    const stockMoveFrom = updateStockMoveFrom(defaultStockMoveFrom, stocks)
    const shelfStocks = stocks.flatMap((stock) =>
      stock.shelfStocks.filter(
        (stock) => stock.shelfId === shelfMoveTo.shelf.id,
      ),
    )
    return { stockMoveFrom, shelfStocks }
  }, [resource, defaultStockMoveFrom, shelfMoveTo])

  const isFromBooth =
    !!defaultStockMoveFrom.boothStock || !!defaultStockMoveFrom.onBoothStock
  const floorMapPoints = useResource({
    subject: "移動元ブース情報の取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    skip: !isFromBooth,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  }).resource?.data.floorMapPoints

  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  const shelfMoveToStockCount =
    shelfStocks.find((s) => s.prizeCd === prizeCd)?.stock || 0

  const validationSchema = Yup.object({
    stock: Yup.number()
      .required("必須です")
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .max(placementMoveFrom.stock, "移動前の在庫数を越えています"),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<InventoryMoveToShelfModalFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stock: placementMoveFrom.stock || 0,
    },
  })
  const value = {
    stock: Number(useWatch({ control, name: "stock" })) || 0,
  }

  const { submitPromises } = useSubmitting()

  const onSubmit: SubmitHandler<InventoryMoveToShelfModalFormInput> = async (
    data,
  ) => {
    const { shelfStock, boothStock, onBoothStock } = stockMoveFrom
    const putMove = (arcadeCd: string) => {
      const baseRequest = {
        from: {},
        to: { shelfId: shelfMoveTo.shelf.id },
        prize: {
          prizeCd,
          stock: data.stock,
        },
      }

      if (shelfStock) {
        const request = {
          ...baseRequest,
          from: { shelfId: shelfStock.shelfId },
        }
        return putPrizeOperationMoveBetweenStorages(arcadeCd, request)
      }
      if (boothStock) {
        const floorMapPoint = floorMapPoints?.find(
          (p) => p.boothShelf.id === boothStock?.boothShelfId,
        )
        if (!floorMapPoint) return
        const request = {
          ...baseRequest,
          from: {
            inventoryFloorMapPointId: floorMapPoint?.id,
            placement: RequestPrizeOperationMoveBoothPlacementEnum.InBooth,
          },
        }
        return putPrizeOperationMoveToStorage(arcadeCd, request)
      }
      if (onBoothStock) {
        const floorMapPoint = floorMapPoints?.find(
          (p) => p.onBoothShelf.id === onBoothStock?.onBoothShelfId,
        )
        if (!floorMapPoint) return
        const request = {
          ...baseRequest,
          from: {
            inventoryFloorMapPointId: floorMapPoint?.id,
            placement: RequestPrizeOperationMoveBoothPlacementEnum.OnBooth,
          },
        }
        return putPrizeOperationMoveToStorage(arcadeCd, request)
      }
      return
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "棚への移動",
          showSuccessMessage: true,
          promise: async () => {
            await (putMove(arcadeCd) || new Promise((_, reject) => reject()))
            onClose()
            onFinish()
          },
        },
      ]))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={8} sx={tableHeadStyle}>
            移動させる数
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name={"stock"}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.stock?.message}
                  helperText={errors.stock?.message}
                  inputProps={{ inputMode: "numeric" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "right" }}>
            個
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid item xs={2} sx={tableHeadStyle}>
            移動前
          </Grid>
          <Grid item xs={6}>
            {placementMoveFrom.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong> 個
          </Grid>

          <Grid item xs={12} mb={1} />

          <Grid item xs={2} />
          <Grid item xs={6}>
            {shelfMoveTo.storage.name} {shelfMoveTo.shelf.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfMoveToStockCount}</strong> 個
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          <Grid item xs={2} sx={tableHeadStyle}>
            移動後
          </Grid>
          <Grid item xs={6}>
            {placementMoveFrom.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock - value.stock}</strong> 個
          </Grid>

          <Grid item xs={12} mb={1} />

          <Grid item xs={2} />
          <Grid item xs={6}>
            {shelfMoveTo.storage.name} {shelfMoveTo.shelf.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfMoveToStockCount + value.stock}</strong> 個
          </Grid>
        </Grid>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          fullWidth
          sx={{ height: 44, lineHeight: 1.2, px: 0.5 }}
        >
          景品移動を保存
        </LoadingButton>
      </CustomDialogActions>
    </form>
  )
}
