/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialOperationOutParams,
  GetMaterialOperationOutResponse,
  PatchMaterialOperationOutRequest,
  PutMaterialOperationOutRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】出庫記録取得
 */
export const getMaterialOperationOut = (
  arcadeCd: string,
  params: GetMaterialOperationOutParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialOperationOutResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/out`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】店外出庫
 */
export const putMaterialOperationOut = (
  arcadeCd: string,
  date: string,
  putMaterialOperationOutRequest: PutMaterialOperationOutRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/out/${date}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationOutRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】出庫記録の SEAMS データ処理状態フラグを更新
 */
export const patchMaterialOperationOut = (
  arcadeCd: string,
  date: string,
  materialCd: string,
  patchMaterialOperationOutRequest: PatchMaterialOperationOutRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/out/${date}/${materialCd}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchMaterialOperationOutRequest,
    },
    options,
  )
}
export type GetMaterialOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialOperationOut>>
>
export type PutMaterialOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationOut>>
>
export type PatchMaterialOperationOutResult = NonNullable<
  Awaited<ReturnType<typeof patchMaterialOperationOut>>
>
