/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type PutMaterialInventoryPlacementTypeStatusRequestStatusEnum =
  (typeof PutMaterialInventoryPlacementTypeStatusRequestStatusEnum)[keyof typeof PutMaterialInventoryPlacementTypeStatusRequestStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PutMaterialInventoryPlacementTypeStatusRequestStatusEnum = {
  Open: "open",
  Locked: "locked",
} as const
