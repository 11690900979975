import { useMemo } from "react"

import {
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { PrizeOperationStock } from "src/api/models"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeTemporaryStoragesFilter } from "src/components/organisms/prizes/InventoryPrizeTemporaryStoragesFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { InventoryVerifyStockSearchParams } from "src/domains/prizes/inventoryMoveRepository"
import {
  filterTemporaryStorages,
  sortOperationStocks,
  temporaryStorageName,
} from "src/domains/prizes/inventoryStockRepository"
import { useResource } from "src/hooks/useResource"
import {
  hideEmptyPrizeState,
  inventoryTemporaryStoragesSearchParamsState,
} from "src/recoil/inventory"
import { theme } from "src/theme"

export type InventoryPrizeTemporaryStoragesSearchParams = Pick<
  InventoryVerifyStockSearchParams,
  "prizeName" | "prizeNameKana" | "prizeCd" | "ipName" | "makerName" | "sortBy"
>

export const InventoryPrizeTemporaryStorages: React.FC = () => {
  const [hideEmptyPrize, setHideEmptyPrize] =
    useRecoilState(hideEmptyPrizeState)

  return (
    <MainContentLayout
      title="荷捌き中の景品一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryPrizeTemporaryStoragesFilter />
          <FormControlLabel
            checked={hideEmptyPrize}
            onChange={() => setHideEmptyPrize(!hideEmptyPrize)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryPrizeTemporaryStoragesMenu />}
    />
  )
}

const InventoryPrizeTemporaryStoragesMenu = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const searchParams = useRecoilValue(
    inventoryTemporaryStoragesSearchParamsState,
  )
  const hideEmptyPrize = useRecoilValue(hideEmptyPrizeState)
  const { resource } = useResource({
    subject: "荷捌き中の景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeOperationStocks(arcadeCd, {
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            prizeCd: searchParams.prizeCd,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${JSON.stringify(searchParams)}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = resource?.data.stocks
    if (!stocks) return

    return sortOperationStocks(
      filterTemporaryStorages(stocks, hideEmptyPrize),
      searchParams,
    )
  }, [hideEmptyPrize, resource, searchParams])

  return (
    <>
      {filteredStocks && (
        <Stack>
          <TemporaryStoragesTable
            stocks={filteredStocks}
            onSelectedPrizeCd={(prizeCd: string) =>
              navigate(
                `/arcades/${arcadeCd}/prizes/move/temporary/prizes/${prizeCd}`,
              )
            }
          />
        </Stack>
      )}
    </>
  )
}

interface TemporaryStoragesTableProps {
  stocks: PrizeOperationStock[]
  onSelectedPrizeCd: (prizeCd: string) => void
}

const TemporaryStoragesTable: React.FC<TemporaryStoragesTableProps> = ({
  stocks,
  onSelectedPrizeCd,
}: TemporaryStoragesTableProps) => {
  return (
    <PaginatedTable
      noMargin
      items={stocks}
      stateKey="temporaryStorageTable"
      header={
        <TableHead>
          <TableRow>
            <ExtTableCell>景品名</ExtTableCell>
            <ExtTableCell sx={{ whiteSpace: "nowrap" }}>着荷数</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(stock) => (
        <TableBorderedRow key={stock.prize.prizeCd}>
          <ExtTableCell
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                background: theme.palette.neutral[200],
              },
            }}
            onClick={() => onSelectedPrizeCd(stock.prize.prizeCd)}
          >
            <CardItemNameBox>{stock.prize.prizeName}</CardItemNameBox>
          </ExtTableCell>
          <ExtTableCell>
            {
              stock.shelfStocks.find(
                (shelfStock) => shelfStock.storageName === temporaryStorageName,
              )?.stock
            }
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
