/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

/**
 * 通常業務区分 (in=入庫、out=出庫、to=投入、back=戻し、execute=実査)
 */
export type MaterialStockLedgerHistoryCategoryEnum =
  (typeof MaterialStockLedgerHistoryCategoryEnum)[keyof typeof MaterialStockLedgerHistoryCategoryEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MaterialStockLedgerHistoryCategoryEnum = {
  In: "in",
  Out: "out",
  To: "to",
  Back: "back",
  Execute: "execute",
} as const
