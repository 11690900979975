import { startTransition, useMemo } from "react"

import { Check, Sync } from "@mui/icons-material"
import {
  Box,
  Typography,
  Grid,
  Card,
  Stack,
  Select,
  MenuItem,
  TextField,
  Chip,
  Divider,
  Button,
  Link,
} from "@mui/material"
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import {
  PrizeBooth,
  PrizeDailyPlan,
  PrizeDelivery,
  PrizeMonthlyPlan,
} from "src/api/models"
import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import { getPrizeDailyPlans, replacePrize } from "src/api/prize-plans"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { DatePicker } from "src/components/organisms/DatePicker"
import { SeeMore } from "src/components/organisms/SeeMore"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  getPrizeChangePlans,
  PrizeDailyPlanWithMonthlyPlan,
} from "src/domains/prizes/dailyRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import {
  datePickerDateLabelStateSelector,
  filterAccordionSearchState,
} from "src/recoil"
import {
  formatApiDate,
  getDaysAgo,
  getMonthEnd,
  getMonthStart,
  round,
} from "src/utils"

const prizeDailyChangesStatusLabels = {
  all: "",
  changed: "対応済み",
  notChanged: "未対応",
} as const

type PrizeDailyChangesSearchParams = {
  boothName?: string
  boothCategory?: string
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
  makerName?: string
  ipName?: string
  prizeDailyChangesStatus: keyof typeof prizeDailyChangesStatusLabels
}

const defaultPrizeDailyChangesSearchParams: PrizeDailyChangesSearchParams = {
  prizeDailyChangesStatus: "all",
}

export const PrizeDailyChanges: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="当日のブース変更一覧"
      renderFilter={() => <PrizeDailyChangesFilter />}
      renderContent={() => <PrizeDailyChangesInner />}
      onClickBackButton={() => navigate(`/arcades/${arcadeCd}/prizes`)}
    />
  )
}

const PrizeDailyChangesFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )

  const searchParams =
    recoilSearchParams["prizeDailyChangesSearchParams"] ??
    defaultPrizeDailyChangesSearchParams
  const setSearchParams = (params: PrizeDailyChangesSearchParams) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeDailyChangesSearchParams: params,
    }))

  return (
    <Stack gap={2}>
      <Card>
        <Box p={2}>
          <DatePicker />
        </Box>
      </Card>
      <Card>
        <FilterAccordion
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          accordionLabel="絞り込み"
          formInputs={[
            {
              name: "boothName",
              label: "プライズ機種名（ブース名）",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "prizeCd",
              label: "景品CD",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "prizeName",
              label: "景品名",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "prizeNameKana",
              label: "景品名カナ",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "makerName",
              label: "メーカー名",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "ipName",
              label: "IP名",
              render: ({ field, fieldState: { error } }) => (
                <TextField {...field} error={!!error} />
              ),
            },
            {
              name: "prizeDailyChangesStatus",
              label: "対応ステータス",
              render: ({ field }) => (
                <Select {...field}>
                  {Object.entries(prizeDailyChangesStatusLabels).map(
                    ([key, value]) => (
                      <MenuItem key={key} value={key} sx={{ height: 36 }}>
                        {value}
                      </MenuItem>
                    ),
                  )}
                </Select>
              ),
            },
          ]}
        />
      </Card>
    </Stack>
  )
}

type CardData = {
  yesterdayPlan?: PrizeDailyPlan
  todayPlan: PrizeDailyPlan
  booth: PrizeBooth
  monthlyPlan?: PrizeMonthlyPlan
  delivery?: PrizeDelivery
  stocks: PrizeDailyPlanWithMonthlyPlan["stocks"]
}

const PrizeDailyChangesInner: React.FC = () => {
  const { arcadeCd } = useParams()

  const recoilSearchParams = useRecoilValue(filterAccordionSearchState)

  const searchParams: PrizeDailyChangesSearchParams =
    recoilSearchParams["prizeDailyChangesSearchParams"] ??
    defaultPrizeDailyChangesSearchParams

  const datePickerDateLabel = useRecoilValue(datePickerDateLabelStateSelector)

  const {
    resource: prizeDailyPlansResource,
    refetchForce: refetchPrizeDailyPlans,
  } = useResource({
    subject: "当日入れ替える景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: getDaysAgo(1, datePickerDateLabel),
            to: datePickerDateLabel,
            boothName: searchParams.boothName,
            boothCategory: searchParams.boothCategory,
            prizeCd: searchParams.prizeCd,
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}:${JSON.stringify(searchParams)}`,
  })
  const prizeDailyPlans = useMemo(
    () => prizeDailyPlansResource?.data.plans ?? [],
    [prizeDailyPlansResource?.data.plans],
  )

  const from = formatApiDate(getMonthStart(datePickerDateLabel))
  const to = formatApiDate(getMonthEnd(datePickerDateLabel))
  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from,
            to,
            prizeCd: searchParams.prizeCd,
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${from}:${to}:${JSON.stringify(searchParams)}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries ?? [],
    [prizeDeliveriesReturn?.data.deliveries],
  )

  const getPrizeOperationStocksReturn = useResource({
    subject: "在庫情報の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeOperationStocks(arcadeCd, {
            prizeName: searchParams.prizeName,
            prizeNameKana: searchParams.prizeNameKana,
            prizeCd: searchParams.prizeCd,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getPrizeOperationStocks:${arcadeCd}:${JSON.stringify(searchParams)}`,
  }).resource
  const prizeOperationStocks = useMemo(
    () => getPrizeOperationStocksReturn?.data.stocks ?? [],
    [getPrizeOperationStocksReturn?.data.stocks],
  )

  const prizeChangePlans = useMemo(
    () =>
      getPrizeChangePlans(
        prizeDailyPlans,
        prizeDeliveries,
        prizeOperationStocks,
      ),
    [prizeDailyPlans, prizeDeliveries, prizeOperationStocks],
  )

  const { submitPromises } = useSubmitting()
  const updateIsPrizeReplaced = async (id: number) => {
    const result = await submitPromises([
      {
        subject: "入替オペレーションの実行",
        showSuccessMessage: true,
        promise: async () => {
          arcadeCd && (await replacePrize(arcadeCd, id))
        },
      },
    ])

    if (!result.success) throw result.error
    startTransition(() => {
      refetchPrizeDailyPlans()
    })
  }

  const cardData = useMemo(() => {
    const todayChangePlans: PrizeDailyPlanWithMonthlyPlan[] = []
    const yesterdayChangePlans: PrizeDailyPlanWithMonthlyPlan[] = []
    prizeChangePlans.forEach((p) => {
      if (formatApiDate(p.plan.recordedAt) === datePickerDateLabel) {
        todayChangePlans.push(p)
      } else {
        yesterdayChangePlans.push(p)
      }
    })

    const data: CardData[] = []
    todayChangePlans.forEach((p) => {
      if (p.plan.isPrizePlanChanged || p.plan.isSettingChanged) {
        const booth = p.booth
        data.push({
          todayPlan: p.plan,
          yesterdayPlan: yesterdayChangePlans.find(
            (plan) => plan.booth.boothName === p.booth.boothName,
          )?.plan,
          booth,
          monthlyPlan: p.monthlyPlan,
          stocks: p.stocks,
        })
      }
    })

    // 当日に変更があった場合のみ表示するので、前日分のみのデータは削除する。
    return data.filter((d) => d.todayPlan)
  }, [datePickerDateLabel, prizeChangePlans])

  const filteredCardData = useMemo(
    () =>
      cardData?.filter((d) => {
        if (d.todayPlan?.isPrizeReplaced) {
          return (
            searchParams.prizeDailyChangesStatus === "changed" ||
            searchParams.prizeDailyChangesStatus === "all"
          )
        }
        return (
          searchParams.prizeDailyChangesStatus === "notChanged" ||
          searchParams.prizeDailyChangesStatus === "all"
        )
      }),
    [cardData, searchParams.prizeDailyChangesStatus],
  )

  return (
    <Stack gap={2} width="100%">
      <Card sx={{ width: "100%", display: "flex", flexShrink: 0 }}>
        <Stack
          divider={<Divider />}
          sx={{
            width: "100%",
            display: "flex",
            flexShrink: 0,
          }}
        >
          <Stack direction="row" gap={2} p={2} alignItems="center">
            <Typography variant="caption" width="118px">
              全ブース
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <Chip label="完了" color="info" size="small" />
              <Typography variant="caption">
                {cardData.reduce((acc, data) => {
                  return acc + (data.todayPlan.isPrizeReplaced ? 1 : 0)
                }, 0)}
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Chip label="未対応" color="error" size="small" />
              <Typography variant="caption" color="error">
                {cardData.reduce((acc, data) => {
                  return acc + (data.todayPlan.isPrizeReplaced ? 0 : 1)
                }, 0)}
              </Typography>
            </Stack>
          </Stack>
          <Stack p={2} pt={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="caption" width="118px">
                入替のみ
              </Typography>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="完了" color="info" size="small" />
                <Typography variant="caption">
                  {cardData.reduce((acc, data) => {
                    if (
                      data.todayPlan.isPrizePlanChanged &&
                      !data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 1 : 0)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="未対応" color="error" size="small" />
                <Typography variant="caption" color="error">
                  {cardData.reduce((acc, data) => {
                    if (
                      data.todayPlan.isPrizePlanChanged &&
                      !data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 0 : 1)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="caption" width="118px">
                設定変更のみ
              </Typography>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="完了" color="info" size="small" />
                <Typography variant="caption">
                  {cardData.reduce((acc, data) => {
                    if (
                      !data.todayPlan.isPrizePlanChanged &&
                      data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 1 : 0)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="未対応" color="error" size="small" />
                <Typography variant="caption" color="error">
                  {cardData.reduce((acc, data) => {
                    if (
                      !data.todayPlan.isPrizePlanChanged &&
                      data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 0 : 1)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="caption" width="118px">
                入替＆設定変更
              </Typography>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="完了" color="info" size="small" />
                <Typography variant="caption">
                  {cardData.reduce((acc, data) => {
                    if (
                      data.todayPlan.isPrizePlanChanged &&
                      data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 1 : 0)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} height="40px" alignItems="center">
                <Chip label="未対応" color="error" size="small" />
                <Typography variant="caption" color="error">
                  {cardData.reduce((acc, data) => {
                    if (
                      data.todayPlan.isPrizePlanChanged &&
                      data.todayPlan.isSettingChanged
                    ) {
                      return acc + (data.todayPlan.isPrizeReplaced ? 0 : 1)
                    }
                    return acc
                  }, 0)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <SeeMore
        items={filteredCardData}
        itemCountPerShow={10}
        renderWrapper={(renderItems) => (
          <Grid container spacing={2}>
            {renderItems()}
          </Grid>
        )}
        renderItem={(item) => (
          <Grid
            key={item.booth.boothName}
            item
            xs={12}
            sm={6}
            sx={{ contentVisibility: "auto", containIntrinsicHeight: "650px" }}
          >
            <PrizeChangeCard
              data={item}
              updateIsPrizeReplaced={updateIsPrizeReplaced}
            />
          </Grid>
        )}
      />
    </Stack>
  )
}

interface PrizeChangeCardProps {
  data: CardData
  updateIsPrizeReplaced: (id: number) => Promise<void>
}

const PrizeChangeCard: React.FC<PrizeChangeCardProps> = ({
  data,
  updateIsPrizeReplaced,
}: PrizeChangeCardProps) => {
  const { arcadeCd } = useParams()

  return (
    <Card>
      <Stack sx={{ p: 2, gap: 2 }}>
        <Typography variant="h3">{data.booth.boothName}</Typography>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
          <Typography variant="body2">入替・設定変更</Typography>
          {data.todayPlan?.isPrizeReplaced ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Check />}
              onClick={() =>
                data.todayPlan && updateIsPrizeReplaced(data.todayPlan.id)
              }
            >
              未対応にする
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Sync />}
              disabled={!data.todayPlan}
              onClick={() =>
                data.todayPlan && updateIsPrizeReplaced(data.todayPlan.id)
              }
            >
              完了にする
            </Button>
          )}
        </Stack>
      </Stack>
      <Typography variant="subtitle2" sx={{ py: 1, px: 2 }}>
        前日
      </Typography>
      <Divider />
      <Stack sx={{ px: 2 }}>
        <Stack
          sx={{ height: "56px", flexDirection: "row", alignItems: "center" }}
        >
          {data.yesterdayPlan ? (
            <Link
              to={`/arcades/${arcadeCd}/prizes/${data.yesterdayPlan.prize.prizeCd}?delivery=${data.delivery?.id}`}
              component={RouterLink}
              underline="none"
              sx={(theme) => ({ color: theme.palette.primary.main })}
            >
              <Typography variant="subtitle2">
                {data.yesterdayPlan.prize.prizeName}
              </Typography>
            </Link>
          ) : (
            <Typography variant="subtitle2">-</Typography>
          )}
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            景品CD
          </Typography>
          <Typography variant="caption">
            {data.yesterdayPlan?.prize.prizeCd ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            設定
          </Typography>
          <Typography variant="caption">
            {data.yesterdayPlan?.setting ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            ブース区分
          </Typography>
          <Typography variant="caption">
            {data.yesterdayPlan?.boothCategory ?? "-"}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="subtitle2" sx={{ py: 1, px: 2 }}>
        当日
      </Typography>
      <Divider />
      <Stack sx={{ px: 2 }}>
        <Stack
          sx={{ height: "56px", flexDirection: "row", alignItems: "center" }}
        >
          {data.todayPlan ? (
            <>
              {data.todayPlan.isPrizePlanChanged && (
                <Chip
                  label="当日更新"
                  color="info"
                  size="small"
                  sx={{ mr: "10px" }}
                />
              )}
              <Link
                to={`/arcades/${arcadeCd}/prizes/${data.todayPlan.prize.prizeCd}?delivery=${data.delivery}`}
                component={RouterLink}
                underline="none"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                <Typography variant="subtitle2">
                  {data.todayPlan.prize.prizeName}
                </Typography>
              </Link>
            </>
          ) : (
            <Typography variant="subtitle2">-</Typography>
          )}
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            景品CD
          </Typography>
          <Typography variant="caption">
            {data.todayPlan?.prize.prizeCd ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            設定
          </Typography>
          <Typography variant="caption">
            {data.todayPlan?.isSettingChanged && (
              <Chip
                label="当日更新"
                color="info"
                size="small"
                sx={{ mr: "10px" }}
              />
            )}
            {data.todayPlan?.setting ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            ブース区分
          </Typography>
          <Typography variant="caption">
            {data.todayPlan?.boothCategory ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            単価
          </Typography>
          <Typography variant="caption">
            {data.todayPlan?.prize.unitPriceJpy ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            1個獲得金額
          </Typography>
          <Typography variant="caption">
            {data.monthlyPlan?.expectedCapturePrice ?? "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            見込みP/O
          </Typography>
          <Typography variant="caption">
            {data.monthlyPlan?.expectedCapturePrice &&
            data.todayPlan?.prize.unitPriceJpy
              ? round(
                  (data.monthlyPlan.expectedCapturePrice /
                    data.todayPlan.prize.unitPriceJpy) *
                    100,
                  1,
                )
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2} py={1}>
          <Typography variant="caption" width="74px" color="gray.50">
            在庫保管場所
          </Typography>
          {data.stocks.boothShelfNames.length === 0 &&
          data.stocks.onBoothShelfNames.length === 0 &&
          data.stocks.shelfNames.length === 0 ? (
            <Typography variant="caption">{"-"}</Typography>
          ) : (
            <Stack>
              {data.stocks.shelfNames.map((shelfName) => (
                <Typography variant="caption" key={shelfName}>
                  {shelfName}
                </Typography>
              ))}
              {data.stocks.onBoothShelfNames.map((onBoothShelfName) => (
                <Typography variant="caption" key={onBoothShelfName}>
                  {onBoothShelfName + " (プライズ機上)"}
                </Typography>
              ))}
              {data.stocks.boothShelfNames.map((boothShelfName) => (
                <Typography variant="caption" key={boothShelfName}>
                  {boothShelfName}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
