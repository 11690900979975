import {
  Grid,
  Button,
  Card,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material"
import { useForm, useWatch, SubmitHandler, Controller } from "react-hook-form"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { getMaterialStorages } from "src/api/material-storages"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { InventoryMaterialMoveToShelfSelectFormInput } from "src/components/templates/InventoryMaterialMoveToShelf"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"

export const InventoryMaterialPlacementMoveFromMultipleMaterialsShelves =
  () => {
    return (
      <MainContentLayout
        title="複数のシリンダーから一括で移動"
        caption="移動先の棚を選択してください"
        renderContent={() => <InventoryMaterialMoveToShelfMenu />}
      />
    )
  }

const InventoryMaterialMoveToShelfMenu: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const searchParams = new URLSearchParams(search)

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InventoryMaterialMoveToShelfSelectFormInput>()

  const { resource: resourceStorages } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}`,
  })
  const storages = (resourceStorages?.data.storages || []).map((e) => e.storage)
  const shelves =
    resourceStorages?.data.storages
      ?.find(({ storage }) => storage.id === value.storageId)
      ?.shelves?.filter((shelf) => shelf.isAvailable) || []

  const value = {
    storageId: useWatch({ control, name: "storageId" }),
    shelfId: useWatch({ control, name: "shelfId" }),
  }

  const onSubmit: SubmitHandler<InventoryMaterialMoveToShelfSelectFormInput> = (
    data,
  ) => {
    const { storageId, shelfId } = data

    if (storageId && shelfId) {
      navigate({
        pathname: `${pathname}/${encodeURIComponent(
          storageId,
        )}/${encodeURIComponent(shelfId)}`,
        ...(searchParams && { search: `?${searchParams.toString()}` }),
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 2, mb: 2 }}>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={4}>
            保管場所
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth error={"storageId" in errors}>
              <Controller
                name="storageId"
                control={control}
                render={({ field, fieldState }) => (
                  <SearchAutoComplete
                    {...field}
                    items={storages.map((storage) => ({
                      label: storage.name,
                      value: storage.id,
                    }))}
                    error={!!fieldState.error}
                  />
                )}
              />
              {errors.storageId?.message && (
                <FormHelperText>{errors.storageId?.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {shelves.length > 0 && (
            <>
              <Grid item xs={4} pt={1}>
                棚
              </Grid>
              <Grid item xs={8} pt={1}>
                <FormControl fullWidth error={"shelfId" in errors}>
                  <Controller
                    name="shelfId"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} value={field.value ?? ""}>
                        <MenuItem key="" value="" />
                        {shelves?.map((shelf) => (
                          <MenuItem key={shelf.id} value={shelf.id}>
                            {shelf.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.shelfId?.message && (
                    <FormHelperText>{errors.shelfId?.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Card>

      <Button
        variant="contained"
        type="submit"
        fullWidth
        disabled={!value.storageId || !value.shelfId}
      >
        選択した棚に移動する
      </Button>
    </form>
  )
}
