import { useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Edit, Delete } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  DialogContent,
  TextField,
  Stack,
  IconButton,
  TableRow,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  PrizeBoothCategory,
  PutPrizeSettingBoothCategoryRequest,
} from "src/api/models"
import {
  deletePrizeSettingBoothCategory,
  getPrizeSettingBoothCategories,
  putPrizeSettingBoothCategory,
} from "src/api/prize-settings"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"

export const PrizeBoothCategories = () => {
  return (
    <MainContentLayout
      title="プライズ機ブース区分一覧"
      renderContent={() => <PrizeBoothCategoriesInner />}
    />
  )
}

const PrizeBoothCategoriesInner: React.FC = () => {
  const { arcadeCd } = useParams()

  const { resource: boothCategoriesResource, refetch } = useResource({
    subject: "ブース区分リストの取得",
    fetch: arcadeCd
      ? () => getPrizeSettingBoothCategories(arcadeCd)
      : undefined,
    recoilKey: `getPrizeSettingBoothCategories:${arcadeCd}`,
  })
  const boothCategories = useMemo(
    () => boothCategoriesResource?.data.boothCategories || [],
    [boothCategoriesResource],
  )

  const [showAddModal, setShowAddModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedPrizeBoothCategory, setSelectedPrizeBoothCategory] = useState<
    PrizeBoothCategory | undefined
  >(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const onFinishModal = () => refetch()

  return (
    <>
      <Button
        variant="contained"
        sx={{ mr: 2, mb: 2, whiteSpace: "nowrap" }}
        startIcon={<Add />}
        onClick={() => setShowAddModal(true)}
      >
        追加
      </Button>

      <Stack sx={{ maxHeight: "80dvh" }}>
        <PrizeBoothCategoriesTable
          prizeBoothCategories={boothCategories}
          onClickEdit={(boothCategory: PrizeBoothCategory) => {
            setSelectedPrizeBoothCategory(boothCategory)
            setShowUpdateModal(true)
          }}
          onClickDelete={(boothCategory: PrizeBoothCategory) => {
            setSelectedPrizeBoothCategory(boothCategory)
            setShowDeleteModal(true)
          }}
        />
      </Stack>

      <PrizeBoothCategoryAddModal
        showModal={showAddModal}
        prizeBoothCategories={boothCategories}
        onClose={() => setShowAddModal(false)}
        onFinish={onFinishModal}
      />

      <PrizeBoothCategoryUpdateModal
        showModal={showUpdateModal}
        prizeBoothCategory={selectedPrizeBoothCategory}
        prizeBoothCategories={boothCategories}
        onClose={() => {
          setShowUpdateModal(false)
          setSelectedPrizeBoothCategory(undefined)
        }}
        onFinish={onFinishModal}
      />

      <PrizeBoothCategoryDeleteModal
        showModal={showDeleteModal}
        prizeBoothCategory={selectedPrizeBoothCategory}
        onClose={() => {
          setShowDeleteModal(false)
          setSelectedPrizeBoothCategory(undefined)
        }}
        onFinish={onFinishModal}
      />
    </>
  )
}

type PrizeBoothCategoriesTableProps = {
  prizeBoothCategories: PrizeBoothCategory[]
  onClickEdit: (boothCategory: PrizeBoothCategory) => void
  onClickDelete: (boothCategory: PrizeBoothCategory) => void
}

const PrizeBoothCategoriesTable: React.FC<PrizeBoothCategoriesTableProps> = ({
  prizeBoothCategories,
  onClickEdit,
  onClickDelete,
}: PrizeBoothCategoriesTableProps) => {
  return (
    <PaginatedTable
      noMargin
      scrollableY
      items={prizeBoothCategories}
      stateKey="prizeBoothCategories"
      renderRow={(boothCategory) => {
        return (
          <TableRow key={boothCategory.name} data-testid={boothCategory.name}>
            <ExtTableCell sx={{ p: 2 }}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle1">
                  {boothCategory.name}
                </Typography>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <IconButton onClick={() => onClickEdit(boothCategory)}>
                    <Edit color="primary" fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => onClickDelete(boothCategory)}>
                    <Delete color="error" fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

type PrizeBoothCategoryFormInput = Omit<
  PutPrizeSettingBoothCategoryRequest,
  "id"
>

type PrizeBoothCategoryAddModalProps = {
  showModal: boolean
  prizeBoothCategories: PrizeBoothCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const PrizeBoothCategoryAddModal: React.FC<
  PrizeBoothCategoryAddModalProps
> = ({
  showModal,
  onClose,
  prizeBoothCategories,
  onFinish = () => undefined,
}) => {
  const { arcadeCd } = useParams()
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) => !prizeBoothCategories.some((bc) => bc.name === value),
      ),
  })
  const useFormReturn = useForm<PrizeBoothCategoryFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
    },
  })
  const { reset } = useFormReturn

  const onSubmit: SubmitHandler<PrizeBoothCategoryFormInput> = async ({
    name,
  }) => {
    if (!arcadeCd) return
    const request: PutPrizeSettingBoothCategoryRequest = {
      name,
    }

    await submitPromises([
      {
        subject: "ブース区分の追加",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingBoothCategory(arcadeCd, request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="ブース区分を追加"
    />
  )
}

type PrizeBoothCategoryUpdateModalProps = {
  showModal: boolean
  prizeBoothCategory?: PrizeBoothCategory
  prizeBoothCategories: PrizeBoothCategory[]
  onClose: () => void
  onFinish?: () => void
}

export const PrizeBoothCategoryUpdateModal: React.FC<
  PrizeBoothCategoryUpdateModalProps
> = ({
  showModal,
  prizeBoothCategory,
  prizeBoothCategories,
  onClose,
  onFinish = () => undefined,
}) => {
  const { arcadeCd } = useParams()
  const { submitPromises } = useSubmitting()

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("必須です")
      .test(
        "should-not-be-duplicated",
        "既にその名称は登録されています",
        (value) =>
          !prizeBoothCategories
            .filter(
              (bc) => prizeBoothCategory && bc.id !== prizeBoothCategory.id,
            )
            .some((bc) => bc.name === value),
      ),
  })
  const useFormReturn = useForm<PrizeBoothCategoryFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: prizeBoothCategory?.name || "",
    },
  })
  const { setValue, reset } = useFormReturn

  useMemo(() => {
    if (prizeBoothCategory) {
      setValue("name", prizeBoothCategory.name)
    }
  }, [prizeBoothCategory, setValue])

  const onSubmit: SubmitHandler<PrizeBoothCategoryFormInput> = async ({
    name,
  }) => {
    if (!arcadeCd || !prizeBoothCategory) return
    const request: PutPrizeSettingBoothCategoryRequest = {
      id: prizeBoothCategory.id,
      name: name,
    }

    await submitPromises([
      {
        subject: "ブース区分の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeSettingBoothCategory(arcadeCd, request)
          reset()
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <EditModalInner
      showModal={showModal}
      onSubmit={onSubmit}
      onClose={() => {
        reset()
        onClose()
      }}
      useFormReturn={useFormReturn}
      title="ブース区分編集"
    />
  )
}

type EditModalInnerProps = {
  showModal: boolean
  onSubmit: SubmitHandler<PrizeBoothCategoryFormInput>
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormReturn: UseFormReturn<PrizeBoothCategoryFormInput, any>
  title: string
}

const EditModalInner: React.FC<EditModalInnerProps> = ({
  showModal,
  onSubmit,
  onClose,
  useFormReturn,
  title,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useFormReturn

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose
          sx={{ textAlign: "left", mt: 1 }}
          variant="h2"
          onClose={onClose}
        >
          <Typography variant="h2">{title}</Typography>
        </DialogTitleWithClose>
        <DialogContent>
          <Stack gap={1}>
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.gray[50] })}
            >
              ブース区分名
            </Typography>
            <Controller
              control={control}
              name={"name"}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}

type PrizeBoothCategoryDeleteModalProps = {
  showModal: boolean
  prizeBoothCategory?: PrizeBoothCategory
  onClose: () => void
  onFinish?: () => void
}

export const PrizeBoothCategoryDeleteModal: React.FC<
  PrizeBoothCategoryDeleteModalProps
> = ({
  showModal,
  prizeBoothCategory,
  onClose,
  onFinish = () => undefined,
}) => {
  const { arcadeCd } = useParams()
  const { submitPromises } = useSubmitting()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onClickDelete = async () => {
    if (!arcadeCd || !prizeBoothCategory) return
    setIsSubmitting(true)
    await submitPromises([
      {
        subject: "ブース区分の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeSettingBoothCategory(arcadeCd, prizeBoothCategory.id)
          onClose()
          onFinish()
        },
      },
    ])
    setIsSubmitting(false)
  }

  if (prizeBoothCategory) {
    // NOTE: onClose 時に prizeBoothCategory が undefined になるので、message のちらつきを防止
    return (
      <DeleteModal
        showModal={showModal}
        onSubmit={onClickDelete}
        onClose={onClose}
        isSubmitting={isSubmitting}
        message={`「${prizeBoothCategory.name}」を削除しますか？`}
      />
    )
  } else {
    return <></>
  }
}
