import { useState, useCallback } from "react"

import axios from "axios"
import { useSetRecoilState } from "recoil"

import { isForbidden } from "src/domains/authRepository"
import {
  snackbarSuccessMessageState,
  snackbarErrorMessageState,
} from "src/recoil"

export interface LoadingItem {
  promise: () => Promise<unknown>
  subject: string
  showSuccessMessage?: boolean
  hideErrorMessage?: boolean
}

export const useLoading = (
  loadingStateSetter: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const loadPromises = useCallback(
    async (loadingItems: LoadingItem[]) => {
      if (!setSuccessMessage || !setErrorMessage) {
        return
      }

      loadingStateSetter(true)

      try {
        await Promise.all(
          loadingItems.map(async (i) => {
            try {
              await i.promise()
              i.showSuccessMessage &&
                setSuccessMessage(`${i.subject}に成功しました`)
            } catch (e) {
              if (i.hideErrorMessage) {
                return
              }

              if (axios.isAxiosError(e) && isForbidden(e)) {
                setErrorMessage("権限の関係で失敗しました")
              } else {
                console.log(e)
                setErrorMessage(`${i.subject}に失敗しました`)
              }
            }
          }),
        )
      } finally {
        loadingStateSetter(false)
        setIsInitialLoading(false)
      }
    },
    [setErrorMessage, setSuccessMessage, loadingStateSetter],
  )

  return { loadPromises, isInitialLoading }
}
