import { PrizeArrivalElement } from "src/api/models"

export const getPrizeArrivalCount = (arrival: PrizeArrivalElement) => {
  const {
    arrival: { accepted, orderCount },
    inHistory,
  } = arrival

  if (accepted && inHistory !== undefined) {
    return inHistory.stock
  }
  return orderCount
}
