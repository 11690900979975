import { forwardRef, PropsWithChildren } from "react"

import { Button, ButtonProps } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const BackButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<
    Pick<ButtonProps, "variant" | "size" | "fullWidth" | "onClick" | "color">
  >
>(({ children, ...props }, ref) => {
  const navigate = useNavigate()
  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={() => navigate(-1)}
      {...props}
      ref={ref}
    >
      {children}
    </Button>
  )
})

BackButton.displayName = "BackButton"
