/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type PrizeShelfStorageTypeEnum =
  (typeof PrizeShelfStorageTypeEnum)[keyof typeof PrizeShelfStorageTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrizeShelfStorageTypeEnum = {
  ForStorage: "for_storage",
  ForReplenishment: "for_replenishment",
  InBooth: "in_booth",
} as const
