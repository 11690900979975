import { Suspense, useEffect, useState } from "react"

import { ManageSearch, Sync } from "@mui/icons-material"
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Link,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  Checkbox,
  Stack,
  Button,
} from "@mui/material"
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { PrizeTonePrizeChange } from "src/api/models"
import { patchPrizeToneBoothSalePrizeChange } from "src/api/prize-tone-booths"
import { getPrizeTonePrizeChanges } from "src/api/prize-tone-prize-changes"
import finishedIcon from "src/assets/icon_finished.png"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardSubtitleCell,
  CardItemNameBox,
  CardValueCell,
  boldRedStyle,
} from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { DatePicker } from "src/components/organisms/DatePicker"
import { PrizeChangeModal } from "src/components/organisms/PrizeChangeModal"
import {
  encodeBoothUnitsQuery,
  getBoothUnitUILabel,
} from "src/domains/prizes/boothRepository"
import { useResource } from "src/hooks/useResource"
import {
  alertParamsState,
  datePickerDateLabelStateSelector,
  hideNotChangedPrizeState,
  snackbarSuccessMessageState,
} from "src/recoil"
import { getToday, roundNearest100 } from "src/utils"

export const TonePrizeChanges: React.FC = () => {
  const { arcadeCd } = useParams()
  const [hideNotChanged, setHideNotChanged] = useRecoilState(
    hideNotChangedPrizeState,
  )
  const navigate = useNavigate()

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h1">
            当日入れ替える景品一覧
          </Typography>

          <Box mb={2}>
            <Card>
              <Box p={2}>
                <DatePicker />
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            入れ替え済ではない景品を表示しない
            <Checkbox
              checked={hideNotChanged}
              onClick={() => setHideNotChanged(!hideNotChanged)}
            />
          </Box>

          <Grid container>
            <Suspense fallback={<CircularProgress sx={{ margin: "auto" }} />}>
              <TonePrizeChangesInner />
            </Suspense>
          </Grid>

          <Box pt={2}>
            <BackButton onClick={() => navigate(`/arcades/${arcadeCd}/prizes`)}>
              戻る
            </BackButton>
          </Box>
        </Container>
      </Box>
    </>
  )
}

const TonePrizeChangesInner: React.FC = () => {
  const { arcadeCd } = useParams()

  const hideNotChanged = useRecoilValue(hideNotChangedPrizeState)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)

  const datePickerDateLabel = useRecoilValue(datePickerDateLabelStateSelector)

  const { resource: prizeChangesResource, refetch: refetchPrizeChanges } =
    useResource({
      subject: "当日入れ替える景品一覧の取得",
      fetch: arcadeCd
        ? () => getPrizeTonePrizeChanges(arcadeCd, datePickerDateLabel)
        : undefined,
      recoilKey: `getPrizeChanges:${arcadeCd}:${datePickerDateLabel}`,
    })
  const prizeChanges = prizeChangesResource?.data.prizeChanges

  useEffect(() => {
    if (prizeChanges?.length === 0) {
      setSuccessMessage("当日入れ替える景品が存在しませんでした")
    }
  }, [datePickerDateLabel, prizeChanges?.length, setSuccessMessage])

  const changedPrizesCount = prizeChanges?.filter(
    (c) => c.isPrizeChanged,
  ).length

  return (
    <>
      {(prizeChanges || []).length > 0 && (
        <Grid
          item
          xs={12}
          mb={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          入れ替え済の景品
          <Typography
            variant="h2"
            pl={1}
            pr={0.5}
            sx={{ letterSpacing: "0.4rem" }}
          >
            {changedPrizesCount}/{prizeChanges?.length}
          </Typography>
        </Grid>
      )}

      {prizeChanges
        ?.filter((prizeChange) =>
          hideNotChanged ? prizeChange.isPrizeChanged : true,
        )
        .map((prizeChange) => (
          <Grid
            key={getBoothUnitUILabel(prizeChange.boothUnit)}
            item
            xs={12}
            sm={6}
          >
            <PrizeChangeCard
              {...{
                prizeChange,
                refetchPrizeChanges,
              }}
            />
          </Grid>
        ))}
    </>
  )
}

interface PrizeChangeCardProps {
  prizeChange: PrizeTonePrizeChange
  refetchPrizeChanges: () => Promise<void>
}

const PrizeChangeCard: React.FC<PrizeChangeCardProps> = ({
  prizeChange,
  refetchPrizeChanges,
}: PrizeChangeCardProps) => {
  const [showModal, setShowModal] = useState(false)
  const { arcadeCd } = useParams()
  const { previousDay, currentDay } = prizeChange
  const shouldChangePrize = previousDay.prizeCd !== currentDay.prizeCd
  const shouldChangeSetting = previousDay.setting !== currentDay.setting

  const [isPrizeChanged, setIsPrizeChanged] = useState(
    prizeChange.isPrizeChanged,
  )

  const updatePrizeChange = async (
    prizeChange: PrizeTonePrizeChange,
    isPrizeChanged: boolean,
  ) => {
    const { machineName, boothName } = prizeChange.boothUnit
    const { date } = currentDay

    if (!arcadeCd) {
      throw new Error()
    }

    const { data } = await patchPrizeToneBoothSalePrizeChange(
      arcadeCd,
      machineName,
      boothName,
      date,
      {
        isPrizeChanged,
      },
    )
    const isPrizeChangedRes = data.isPrizeChanged
    if (isPrizeChangedRes !== undefined) {
      setIsPrizeChanged(isPrizeChangedRes)

      refetchPrizeChanges()
    } else {
      throw new Error()
    }
  }

  const navigate = useNavigate()
  const setAlertParams = useSetRecoilState(alertParamsState)
  const onClickFloorMapLink = () => {
    // NOTE: フロアマップのアラート日付に選択中日付とタブをセット。選択中日付が未来の場合は今日をセット
    const dateLabel =
      currentDay.date <= getToday() ? currentDay.date : getToday()
    setAlertParams((p) => ({ ...p, dateLabel }))
    navigate(
      `/arcades/${arcadeCd}/prizes/sales/toneFloorMap?boothUnit=${encodeBoothUnitsQuery(
        [prizeChange.boothUnit],
      )}`,
    )
  }

  return (
    <Card
      sx={{
        m: 1,
        pt: 1,
        pb: 1,
      }}
    >
      <Table size="small">
        <TableBody>
          <TableRow>
            <CardValueCell colSpan={2}>
              <Typography variant="h2">
                {getBoothUnitUILabel(prizeChange.boothUnit)}
              </Typography>
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardSubtitleCell colSpan={2}>前日</CardSubtitleCell>
          </TableRow>
          <TableRow>
            <CardValueCell colSpan={2}>
              <CardItemNameBox>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/search/toneStocks/${encodeURIComponent(
                    previousDay.prizeCd,
                  )}`}
                  underline="none"
                >
                  {previousDay.prizeName}
                </Link>
              </CardItemNameBox>
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell>設定</CardKeyCell>
            <CardValueCell>{previousDay.setting}</CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell>単価</CardKeyCell>
            <CardValueCell>
              {previousDay.unitPriceJpy?.toLocaleString() || "-"}円/個
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell colSpan={2} sx={{ pb: 0 }}>
              獲得金額目安
            </CardKeyCell>
          </TableRow>
          <TableRow>
            <CardKeyCell>売上</CardKeyCell>
            <CardValueCell>
              {previousDay.expectSale?.expectSales
                ? roundNearest100(
                    previousDay.expectSale?.expectSales,
                  ).toLocaleString()
                : "-"}
              円
            </CardValueCell>
          </TableRow>
          <TableRow>
            <CardKeyCell>PO率</CardKeyCell>
            <CardValueCell>
              {previousDay.expectSale?.expectPayoutRate
                ? `${previousDay.expectSale?.expectPayoutRate.toFixed(1)}%`
                : "-"}
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardSubtitleCell colSpan={2}>当日</CardSubtitleCell>
          </TableRow>
          <TableRow>
            <CardValueCell colSpan={2}>
              <CardItemNameBox>
                <Link
                  component={RouterLink}
                  to={`/arcades/${arcadeCd}/prizes/search/toneStocks/${encodeURIComponent(
                    currentDay.prizeCd,
                  )}`}
                  underline="none"
                  sx={{ ...(shouldChangePrize && boldRedStyle) }}
                >
                  {currentDay.prizeName}
                </Link>
              </CardItemNameBox>
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell>設定</CardKeyCell>
            <CardValueCell sx={{ ...(shouldChangeSetting && boldRedStyle) }}>
              {currentDay.setting}
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell>単価</CardKeyCell>
            <CardValueCell>
              {currentDay.unitPriceJpy?.toLocaleString() || "-"}円/個
            </CardValueCell>
          </TableRow>

          <TableRow>
            <CardKeyCell colSpan={2} sx={{ pb: 0 }}>
              獲得金額目安
            </CardKeyCell>
          </TableRow>
          <TableRow>
            <CardKeyCell>売上</CardKeyCell>
            <CardValueCell>
              {currentDay.expectSale?.expectSales
                ? roundNearest100(
                    currentDay.expectSale?.expectSales,
                  ).toLocaleString()
                : "-"}
              円
            </CardValueCell>
          </TableRow>
          <TableRow>
            <CardKeyCell>PO率</CardKeyCell>
            <CardValueCell>
              {currentDay.expectSale?.expectPayoutRate
                ? `${currentDay.expectSale?.expectPayoutRate.toFixed(1)}%`
                : "-"}
            </CardValueCell>
          </TableRow>

          <TableRow>
            <ExtTableCell colSpan={2}>
              <Stack sx={{ gap: 1.25, pt: 1.5 }}>
                <Button
                  variant="outlined"
                  aria-label="prize-change"
                  startIcon={
                    isPrizeChanged ? (
                      <img
                        src={finishedIcon}
                        style={{ width: 20, height: 20 }}
                      />
                    ) : (
                      <Sync />
                    )
                  }
                  onClick={() => setShowModal(true)}
                >
                  {isPrizeChanged ? "入れ替え処理を戻す" : "入れ替える"}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ManageSearch />}
                  onClick={onClickFloorMapLink}
                >
                  フロアマップを見る
                </Button>
              </Stack>
            </ExtTableCell>
          </TableRow>
        </TableBody>
      </Table>

      <CustomDialog
        fullWidth
        maxWidth="sm"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Suspense
          fallback={
            <Box sx={{ p: 1 }}>
              <CircularProgress sx={{ display: "block", margin: "auto" }} />
            </Box>
          }
        >
          <PrizeChangeModal
            prizeCd={prizeChange.currentDay.prizeCd}
            boothUnit={prizeChange.boothUnit}
            isPrizeChanged={isPrizeChanged}
            updatePrizeChange={() =>
              updatePrizeChange(prizeChange, !isPrizeChanged)
            }
            onClose={() => setShowModal(false)}
          />
        </Suspense>
      </CustomDialog>
    </Card>
  )
}
