import { useMemo } from "react"

import { Typography, TextField, MenuItem, alpha, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { RequestPrizeOperationMoveBoothPlacementEnum } from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
} from "src/components/organisms/FloorMapBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import {
  convertToFloorMapPoint,
  isFloorMapPointAvailable,
  PrizeInventoryFloorMapPoint,
} from "src/domains/prizes/inventoryFloorMapRepository"
import { convertPrizePlacementTypeToURLPath } from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import { moveFromBoothPlacementTypeState } from "src/recoil/inventory"
import { theme } from "src/theme"

export const InventoryPrizeMoveFromBooth: React.FC = () => {
  const { InBooth, OnBooth } = RequestPrizeOperationMoveBoothPlacementEnum
  const [selectedPlacementType, setSelectedPlacementType] = useRecoilState(
    moveFromBoothPlacementTypeState,
  )

  return (
    <MainContentLayout
      title="フロアマップから入替／移動"
      renderFilter={() => (
        <Stack gap={2}>
          <Typography>移動元のブースを選択してください</Typography>
          <TextField
            select
            label="プライズ機内／プライズ機上"
            fullWidth
            onChange={(e) => {
              const placementType = Object.values(
                RequestPrizeOperationMoveBoothPlacementEnum,
              ).find((v) => v === e.target.value)
              setSelectedPlacementType(placementType || InBooth)
            }}
            value={selectedPlacementType}
          >
            <MenuItem value={InBooth} key={InBooth}>
              プライズ機内
            </MenuItem>
            <MenuItem value={OnBooth} key={OnBooth}>
              プライズ機上
            </MenuItem>
          </TextField>
        </Stack>
      )}
      renderContent={() => <InventoryPrizeMoveFromBoothMenu />}
    />
  )
}

const InventoryPrizeMoveFromBoothMenu = () => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()

  const selectedPlacementType = useRecoilValue(moveFromBoothPlacementTypeState)

  const { resource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  })
  const floorMapPoints = useMemo(
    () => resource?.data.floorMapPoints.map((p) => convertToFloorMapPoint(p)),
    [resource],
  )

  const onClickPoint = (point: BaseFloorMapPoint) => {
    const placementType = convertPrizePlacementTypeToURLPath(
      selectedPlacementType,
    )
    navigate(
      `/arcades/${arcadeCd}/prizes/placements/${placementType}/${point.id}/prizes`,
    )
  }

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <InventoryPrizeMoveFromBoothFloorMapPointBox
      key={point.id}
      {...{
        point,
        onClickPoint,
        placementType: selectedPlacementType,
      }}
    />
  )

  return (
    <>
      {floorMapPoints && (
        <FloorMapBox
          {...{
            floorMapPoints: floorMapPoints,
            onClickPoint,
            getFloorMapPointBox,
          }}
        />
      )}
    </>
  )
}

interface InventoryMoveFromBoothFloorMapPointBoxProps
  extends FloorMapPointBoxProps {
  placementType: RequestPrizeOperationMoveBoothPlacementEnum
}

export const InventoryPrizeMoveFromBoothFloorMapPointBox: React.FC<
  InventoryMoveFromBoothFloorMapPointBoxProps
> = ({
  point,
  onClickPoint = () => undefined,
  placementType,
}: InventoryMoveFromBoothFloorMapPointBoxProps) => {
  const isInBooth =
    placementType === RequestPrizeOperationMoveBoothPlacementEnum.InBooth
  const isAvailable = useMemo(
    () =>
      isFloorMapPointAvailable(isInBooth, point as PrizeInventoryFloorMapPoint),
    [isInBooth, point],
  )

  const backgroundColor = useMemo(() => {
    if (!isAvailable) {
      return alpha(theme.palette.text.disabled, 0.7)
    }

    return "transparent"
  }, [isAvailable])

  return (
    <FloorMapPointBox
      {...{
        point,
        onClickPoint: isAvailable ? onClickPoint : () => undefined,
      }}
      sx={{
        backgroundColor,
        cursor: isAvailable ? "pointer" : "default",
      }}
    />
  )
}
