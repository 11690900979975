import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  FeatureExecutionPeriod,
  GetPrizeInventoryExecuteResponse,
  Csv,
  FeatureExecutionPeriodStatusEnum,
} from "src/api/models"
import { getPrizeInventoryExecute } from "src/api/prize-inventory-execute"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import { placementTypeNames } from "src/domains/prizes/inventoryExecuteRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useUserRole } from "src/hooks/useUserRole"
import { currentArcadeState, inventoryPeriodsState } from "src/recoil"
import { formatApiDate } from "src/utils"

export const InventoryPrizeExecuteHistoriesCsv: React.FC = () => {
  const { arcadeCd } = useParams()

  const { downloadCsv } = useDownloadCsv()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const arcade = useRecoilValue(currentArcadeState)

  const getCsv = useCallback(
    (res: GetPrizeInventoryExecuteResponse): Csv => ({
      headerRow: {
        columns: [
          "分類",
          "保管場所名",
          "棚名",
          "フロアマップ位置名",
          "景品CD",
          "個数",
          "景品名",
          "棚卸日",
          "実施者",
        ],
      },
      rows: res.histories.map((history) => ({
        columns: [
          placementTypeNames[history.placement],
          history.shelfStock?.storageName || "",
          history.shelfStock?.shelfName || "",
          history.boothStock?.boothShelfName ||
            history.onBoothStock?.onBoothShelfName ||
            "",
          history.prizeCd,
          history.stock.toString(),
          history.prizeName,
          formatApiDate(history.executedAt),
          history.executedBy?.name || "",
        ],
      })),
    }),
    [],
  )

  // NOTE: 棚卸確定未済 = 終了日が 2200-12-31 の棚卸期間は除外
  const inventoryPeriods = useRecoilValue(inventoryPeriodsState)
  const prizeExecutionPeriods = useMemo(
    () =>
      inventoryPeriods?.flatMap((period) =>
        period.prizeExecutionPeriods.filter(
          (prizePeriod) =>
            prizePeriod.status === FeatureExecutionPeriodStatusEnum.Ended,
        ),
      ),
    [inventoryPeriods],
  )

  const [selectedPrizeExecutionPeriodId, setSelectedPrizeExecutionPeriodId] =
    useState<FeatureExecutionPeriod["id"] | undefined>()
  const selectedPrizeExecutionPeriod = useMemo(
    () =>
      prizeExecutionPeriods?.find(
        (period) => period.id === selectedPrizeExecutionPeriodId,
      ),
    [prizeExecutionPeriods, selectedPrizeExecutionPeriodId],
  )

  const { isAvailableInventoryExecuteHistoriesCsv } = useUserRole()

  useEffect(
    () =>
      prizeExecutionPeriods &&
      setSelectedPrizeExecutionPeriodId(prizeExecutionPeriods[0]?.id),
    [prizeExecutionPeriods],
  )
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const onSubmit = useCallback(async () => {
    arcadeCd &&
      arcade &&
      selectedPrizeExecutionPeriod &&
      submitPromises([
        {
          subject: "店内総在庫証明の取得",
          promise: async () => {
            const { data } = await getPrizeInventoryExecute(
              arcadeCd,
              selectedPrizeExecutionPeriod.id,
            )
            const csv = getCsv(data)
            if (csv) {
              downloadCsv({
                csv,
                fileName: `景品店内総在庫証明_${arcadeCd}_${arcade.name}_${selectedPrizeExecutionPeriod.startAt}_${selectedPrizeExecutionPeriod.endAt}.csv`,
              })
            } else {
              throw new Error()
            }
          },
        },
      ])
  }, [
    arcade,
    arcadeCd,
    downloadCsv,
    getCsv,
    submitPromises,
    selectedPrizeExecutionPeriod,
  ])

  return (
    <MainContentLayout
      title="景品店内総在庫証明CSV"
      renderContent={() => (
        <Stack gap={2}>
          <Stack>
            <Typography variant="caption">
              全ての保管場所単位別のカウント結果を表示します。GiGO
              NAVIを確定したら自動生成されます。
              <br />
              このファイルは会社から求められた時のみ提出します。
            </Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            {selectedPrizeExecutionPeriodId ? (
              <FormControl fullWidth>
                <InputLabel id="period">期間</InputLabel>
                <Select
                  fullWidth
                  label="期間"
                  labelId="period"
                  value={selectedPrizeExecutionPeriodId}
                  onChange={(e) =>
                    setSelectedPrizeExecutionPeriodId(
                      e.target.value as FeatureExecutionPeriod["id"],
                    )
                  }
                >
                  {prizeExecutionPeriods?.map(({ id, startAt, endAt }) => (
                    <MenuItem key={`${startAt}〜${endAt}`} value={id}>
                      {startAt}〜
                      {endAt !== executionPeriodTentativeEndAt && endAt}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : prizeExecutionPeriods?.length === 0 ? (
              "出力可能な期間がありません"
            ) : (
              <CircularProgress />
            )}
          </Card>
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            disabled={
              isSubmitting ||
              !selectedPrizeExecutionPeriodId ||
              !isAvailableInventoryExecuteHistoriesCsv(arcadeCd)
            }
          >
            CSV出力
          </Button>
        </Stack>
      )}
    />
  )
}
