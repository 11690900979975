import { atom, selector } from "recoil"

import { GetUserResponse } from "src/api/models"
import { CognitoSub, IdToken } from "src/types"

export const idTokenState = atom<IdToken | null>({
  key: "idTokenState",
  default: null,
})

export const isLoginState = selector({
  key: "isLoginState",
  get: ({ get }): boolean => !!get(idTokenState),
})

export const cognitoSubState = atom<CognitoSub | null>({
  key: "cognitoSubState",
  default: null,
})

export const meState = atom<GetUserResponse | undefined>({
  key: "meState",
  default: undefined,
})

export const lastFetchedVersionAtState = atom<number | null>({
  key: "lastFetchedVersionAtState",
  default: null,
})
