/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  DeletePrizeOperationStocksRequest,
  GetPrizeOperationStocksParams,
  GetPrizeOperationStocksResponse,
  PatchPrizeOperationStocksRequest,
  PostPrizeOperationStocksRequest,
  PutPrizeOperationStocksRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 店内在庫を削除
 */
export const deletePrizeOperationStocks = (
  arcadeCd: string,
  deletePrizeOperationStocksRequest: DeletePrizeOperationStocksRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/stocks`,
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      data: deletePrizeOperationStocksRequest,
    },
    options,
  )
}
/**
 * @summary 在庫検索
 */
export const getPrizeOperationStocks = (
  arcadeCd: string,
  params?: GetPrizeOperationStocksParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeOperationStocksResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/stocks`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 店内在庫の個数を変更
 */
export const patchPrizeOperationStocks = (
  arcadeCd: string,
  patchPrizeOperationStocksRequest: PatchPrizeOperationStocksRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/stocks`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: patchPrizeOperationStocksRequest,
    },
    options,
  )
}
/**
 * @summary 店内在庫を一括で更新
 */
export const postPrizeOperationStocks = (
  arcadeCd: string,
  postPrizeOperationStocksRequest: PostPrizeOperationStocksRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/stocks`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: postPrizeOperationStocksRequest,
    },
    options,
  )
}
/**
 * @summary 店内在庫を0個で新規登録 (在庫移動を伴わない)
 */
export const putPrizeOperationStocks = (
  arcadeCd: string,
  putPrizeOperationStocksRequest: PutPrizeOperationStocksRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/stocks`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationStocksRequest,
    },
    options,
  )
}
export type DeletePrizeOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof deletePrizeOperationStocks>>
>
export type GetPrizeOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeOperationStocks>>
>
export type PatchPrizeOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof patchPrizeOperationStocks>>
>
export type PostPrizeOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof postPrizeOperationStocks>>
>
export type PutPrizeOperationStocksResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationStocks>>
>
