/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeInventoryGroupsParams,
  GetPrizeInventoryGroupsResponse,
  PutPrizeInventoryGroupRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 棚卸グループ一覧を取得
 */
export const getPrizeInventoryGroups = (
  arcadeCd: string,
  params?: GetPrizeInventoryGroupsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeInventoryGroupsResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/groups`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 棚卸グループを設定
 */
export const putPrizeInventoryGroups = (
  arcadeCd: string,
  putPrizeInventoryGroupRequest: PutPrizeInventoryGroupRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/inventory/groups`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeInventoryGroupRequest,
    },
    options,
  )
}
export type GetPrizeInventoryGroupsResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeInventoryGroups>>
>
export type PutPrizeInventoryGroupsResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeInventoryGroups>>
>
