import { useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  TextField,
  TableRow,
  Table,
  TableBody,
  DialogContent,
  Typography,
} from "@mui/material"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import {
  Prize,
  PutPrizeOperationInRequest,
  PrizeArrivalElement,
} from "src/api/models"
import { putPrizeOperationIn } from "src/api/prize-operation-in"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { DatePicker } from "src/components/organisms/DatePicker"
import { getPrizeArrivalCount } from "src/domains/prizes/arrivalRepository"
import { useSubmitting } from "src/hooks/useSubmitting"
import { theme } from "src/theme"
import { getToday } from "src/utils"

interface InventoryAcceptFormInput {
  cartonsCount: number
  separatedCount: number
}

interface InventoryAcceptModalProps {
  showModal: boolean
  arrival?: PrizeArrivalElement
  prize: Prize
  onClose: () => void
  onFinish?: () => void
}

export const InventoryPrizeAcceptModal: React.FC<InventoryAcceptModalProps> = ({
  showModal,
  arrival,
  prize,
  onClose,
  onFinish = () => undefined,
}: InventoryAcceptModalProps) => {
  const { arcadeCd } = useParams()
  const { orderCount, accepted } = arrival?.arrival || {}
  const { unitPerCarton } = prize

  const acceptDate = getToday()

  const validationSchema = Yup.object({
    cartonsCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
    separatedCount: Yup.number()
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .required("必須です"),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = useForm<InventoryAcceptFormInput>({
    resolver: yupResolver<InventoryAcceptFormInput>(validationSchema),
    defaultValues: {
      cartonsCount: 0,
      separatedCount: arrival ? getPrizeArrivalCount(arrival) : 0,
    },
  })
  const value = {
    cartonsCount: Number(useWatch({ control, name: "cartonsCount" })) || 0,
    separatedCount: Number(useWatch({ control, name: "separatedCount" })) || 0,
  }
  const stock = value.cartonsCount * unitPerCarton + value.separatedCount

  // NOTE: 着荷予定選択を変更時に値をリセットする処理
  useEffect(() => {
    setValue("cartonsCount", 0)
    setValue("separatedCount", arrival ? getPrizeArrivalCount(arrival) : 0)
  }, [arrival, setValue])

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryAcceptFormInput> = async () => {
    if (!arcadeCd) return
    const request: PutPrizeOperationInRequest = {
      prizes: [
        {
          prizeCd: prize.prizeCd,
          stock,
          prizeOrderId: arrival?.arrival.id,
        },
      ],
    }

    await submitPromises([
      {
        subject: "検収",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeOperationIn(arcadeCd, acceptDate, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1"> {prize.prizeName}</Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Box
            sx={{
              maxWidth: "80%",
              my: 1,
              mx: "auto",
            }}
          >
            <PrizeImageBox
              prizeCd={prize.prizeCd}
              alt={prize.prizeName}
              noImageSize="large"
            />
          </Box>

          <Box sx={{ pt: 3 }}>
            <Table size="small" sx={{ td: { border: "none", px: 0 }, mb: 3 }}>
              <TableBody>
                {orderCount && (
                  <TableRow>
                    <ExtTableCell colSpan={2}>
                      着荷予定数 <strong>{orderCount}</strong> 個
                    </ExtTableCell>
                  </TableRow>
                )}
                {!arrival && (
                  <TableRow>
                    <ExtTableCell colSpan={2}>
                      検収日
                      <DatePicker />
                    </ExtTableCell>
                  </TableRow>
                )}
                {unitPerCarton > 0 && (
                  <TableRow>
                    <ExtTableCell colSpan={2}>
                      カートン入数 <strong>{unitPerCarton}</strong> 個
                    </ExtTableCell>
                  </TableRow>
                )}
                <TableRow
                  sx={{
                    td: { borderTop: `1px solid ${theme.palette.divider}` },
                  }}
                >
                  <ExtTableCell>着荷数</ExtTableCell>
                  <ExtTableCell align="right">
                    合計 <strong>{stock}</strong> 個
                  </ExtTableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              {unitPerCarton > 0 && (
                <>
                  <Grid item xs={2.5} pb={2}>
                    箱
                  </Grid>
                  <Grid item xs={4} pb={2}>
                    <TextField
                      error={"cartonsCount" in errors}
                      helperText={errors.cartonsCount?.message}
                      {...register("cartonsCount")}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item xs={1.5} pb={2} pl={1}>
                    CT
                  </Grid>
                  <Grid item xs={4} pb={2} />
                </>
              )}

              <Grid item xs={2.5} pb={2}>
                バラ
              </Grid>
              <Grid item xs={4} pb={2}>
                <TextField
                  error={"separatedCount" in errors}
                  helperText={errors.separatedCount?.message}
                  {...register("separatedCount")}
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
              <Grid item xs={1.5} pb={2} pl={1}>
                個
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            {accepted ? "検収内容を変更" : "検収済にする"}
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
