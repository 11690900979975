import { useMemo, useEffect, startTransition } from "react"

import {
  Grid,
  Typography,
  Card,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Button,
  Checkbox,
  ListItemText,
  Stack,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import { getMaterialMachines } from "src/api/material-machines"
import {
  AmMachine,
  MaterialMachine,
  MaterialMachineWarningEnum,
} from "src/api/models"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { InventoryMaterialAmMachineWarningCard } from "src/components/organisms/materials/InventoryMaterialAmMachineWarningCard"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortAmMachines } from "src/domains/amMachinesRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useResource } from "src/hooks/useResource"

export interface InventoryMaterialMoveFromMachineFormInput {
  amMachineNumber: AmMachine["amMachineNumber"]
  materialMachineIds: MaterialMachine["id"][]
}

export const InventoryMaterialMoveFromMachine: React.FC = () => {
  return (
    <MainContentLayout
      title="材料機械から戻し"
      renderContent={() => <InventoryMoveFromMachineMenu />}
    />
  )
}

const InventoryMoveFromMachineMenu = () => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()

  const { resource: materialMachinesResource } = useResource({
    subject: "材料機械リストの取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    recoilKey: `getMaterialMachines:${arcadeCd}}`,
  })
  const materialMachines = materialMachinesResource?.data.materialMachines
  // AM機器一覧は材料機械一覧の中の AmMachine から重複排除して算出する
  const amMachines = useMemo(
    () =>
      sortAmMachines(
        Array.from(
          new Map(
            materialMachinesResource?.data.materialMachines.map((machine) => [
              machine.amMachine.amMachineNumber,
              machine.amMachine,
            ]),
          ).values(),
        ),
        {
          sortBy: "phoneticOrderAsc",
        },
      ),
    [materialMachinesResource],
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<InventoryMaterialMoveFromMachineFormInput>()

  const amMachineNumber = useWatch({ control, name: "amMachineNumber" })
  const materialMachineIds = useWatch({ control, name: "materialMachineIds" })

  useEffect(() => {
    reset({
      materialMachineIds: undefined,
      amMachineNumber,
    })
  }, [reset, amMachineNumber])

  const onSubmit: SubmitHandler<InventoryMaterialMoveFromMachineFormInput> = ({
    materialMachineIds,
  }) => {
    materialMachineIds.length === 1
      ? navigate(
          `/arcades/${arcadeCd}/materials/placements/machine/${materialMachineIds[0]}/materials`,
        )
      : navigate(
          `/arcades/${arcadeCd}/materials/placements/machine/materials/multiple?machineIds=${materialMachineIds.join(
            ",",
          )}`,
        )
  }
  const filteredMaterialMachines =
    materialMachines?.filter(
      (materialMachine) =>
        materialMachine.amMachine.amMachineNumber === amMachineNumber,
    ) || []
  const warningMaterialMachines =
    materialMachines?.filter(
      (materialMachine) =>
        materialMachine.materialMachine.warning !==
        MaterialMachineWarningEnum.None,
    ) || []

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Typography variant="body2">
          移動元の材料機械を選択してください
        </Typography>
        {warningMaterialMachines.length > 0 && (
          <InventoryMaterialAmMachineWarningCard
            materialMachines={warningMaterialMachines}
          />
        )}
        <Card sx={{ p: 2 }}>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              AM機器
            </Grid>
            <Grid item xs={8} mb={2}>
              <FormControl fullWidth error={"amMachineNumber" in errors}>
                <Controller
                  name="amMachineNumber"
                  control={control}
                  render={({ field: { onChange, ...rest }, fieldState }) => (
                    <SearchAutoComplete
                      {...rest}
                      onChange={(e) => startTransition(() => onChange(e))}
                      items={amMachines.map((amMachine) => ({
                        label: amMachine.amMachineName,
                        value: amMachine.amMachineNumber,
                      }))}
                      error={!!fieldState.error}
                    />
                  )}
                />
                {errors.amMachineNumber?.message && (
                  <FormHelperText>
                    {errors.amMachineNumber?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {filteredMaterialMachines.length > 0 && (
              <>
                <Grid item xs={4} pt={1}>
                  シリンダー
                </Grid>
                <Grid item xs={8} pt={1}>
                  <FormControl fullWidth error={"materialMachineIds" in errors}>
                    <Controller
                      name="materialMachineIds"
                      control={control}
                      render={({ field }) => (
                        <Select
                          multiple={true}
                          {...field}
                          value={field.value ?? []}
                          onChange={(e) => field.onChange(e)}
                          renderValue={() =>
                            filteredMaterialMachines
                              .filter((m) =>
                                field.value.includes(m.materialMachine.id),
                              )
                              .map((m) =>
                                getDisplayMaterialMachineName(
                                  m.amMachine,
                                  m.materialMachine,
                                  true,
                                ),
                              )
                              .join(", ")
                          }
                        >
                          {filteredMaterialMachines?.map((materialMachine) => (
                            <MenuItem
                              key={materialMachine.materialMachine.id}
                              value={materialMachine.materialMachine.id}
                            >
                              <Checkbox
                                checked={(field.value || []).includes(
                                  materialMachine.materialMachine.id,
                                )}
                              />
                              <ListItemText
                                primary={getDisplayMaterialMachineName(
                                  materialMachine.amMachine,
                                  materialMachine.materialMachine,
                                  true,
                                )}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.materialMachineIds?.message && (
                      <FormHelperText>
                        {errors.materialMachineIds?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={!materialMachineIds}
        >
          次へ
        </Button>
      </Stack>
    </form>
  )
}
