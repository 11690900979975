import { useEffect } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Add, Remove } from "@mui/icons-material"
import {
  Box,
  IconButton,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Autocomplete,
  Typography,
  DialogContent,
} from "@mui/material"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import { PrizeToneBoothInfo } from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { getBoothUnitUILabel } from "src/domains/prizes/boothRepository"
import { snackbarSuccessMessageState } from "src/recoil"
import { filterUniqueArray } from "src/utils"

interface BoothDetailsAddFormInput {
  boothUnitUILabels: { value?: string }[]
}

interface BoothDetailsAddFormProps {
  addBooths: (inputtedBooths: PrizeToneBoothInfo[]) => void
  onClose: () => void
  booths?: PrizeToneBoothInfo[]
}

export const BoothDetailsAddForm: React.FC<BoothDetailsAddFormProps> = ({
  addBooths,
  onClose,
  booths = [],
}: BoothDetailsAddFormProps) => {
  const validationSchema = Yup.object({
    boothUnitUILabels: Yup.array(
      Yup.object({
        value: Yup.string().required("必須です"),
      }),
    ).required("必須です"),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<BoothDetailsAddFormInput>({
    resolver: yupResolver<BoothDetailsAddFormInput>(validationSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "boothUnitUILabels",
  })
  useEffect(() => {
    append({ value: undefined })
  }, [append])

  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)
  const onSubmit: SubmitHandler<BoothDetailsAddFormInput> = (data) => {
    const inputtedBooths: PrizeToneBoothInfo[] = data.boothUnitUILabels
      .map((label) => label.value)
      .filter(filterUniqueArray)
      .map((label) => booths.find((b) => label === getBoothUnitUILabel(b)))
      .filter((b): b is PrizeToneBoothInfo => !!b)
    addBooths(inputtedBooths)
    setSuccessMessage("ブースを追加しました")
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitleWithClose onClose={onClose}>ブース追加</DialogTitleWithClose>

      <DialogContent>
        <Typography sx={{ mb: 2 }}>ブースを選択してください</Typography>
        {fields.map((field, index) => (
          <Box
            key={field.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            mb={1}
          >
            <FormControl
              fullWidth
              error={
                errors.boothUnitUILabels && !!errors.boothUnitUILabels[index]
              }
              sx={{ mr: 2 }}
            >
              <Autocomplete
                options={booths.map((b) => getBoothUnitUILabel(b))}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="ブース名"
                      {...register(`boothUnitUILabels.${index}.value`)}
                      error={
                        errors.boothUnitUILabels &&
                        !!errors.boothUnitUILabels[index]
                      }
                    />
                  )
                }}
              />
              {errors.boothUnitUILabels && errors.boothUnitUILabels[index] && (
                <FormHelperText>
                  {errors.boothUnitUILabels[index]?.value?.message}
                </FormHelperText>
              )}
            </FormControl>

            <IconButton
              disableRipple
              onClick={() => remove(index)}
              sx={{
                color: "white",
                backgroundColor: "error.main",
              }}
            >
              <Remove fontSize="inherit" />
            </IconButton>
          </Box>
        ))}

        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <IconButton
            disableRipple
            onClick={() => append({ value: undefined })}
            sx={{
              color: "white",
              backgroundColor: "primary.main",
              margin: "auto",
            }}
          >
            <Add fontSize="inherit" />
          </IconButton>
        </Box>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>追加せず戻る</BackButton>
        <Button variant="contained" type="submit" fullWidth>
          追加
        </Button>
      </CustomDialogActions>
    </form>
  )
}
