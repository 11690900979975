import { Alert } from "@mui/material"

interface ConfidentialAlertProps {
  short?: boolean
}

export const ConfidentialAlert: React.FC<ConfidentialAlertProps> = ({
  short = false,
}: ConfidentialAlertProps) => {
  // NOTE: 本番環境では表示しない
  if (!IS_DEBUG && !IS_VADDY && !IS_STAGING) {
    return <></>
  }

  if (short) {
    return (
      <Alert variant="filled" severity="warning">
        [開発環境] 社外秘は入力NGです
      </Alert>
    )
  }

  return (
    <Alert variant="filled" severity="warning">
      開発環境。社外秘情報を入力しないでください
      <br />
      （情報が社外のクローラーに渡ります）
    </Alert>
  )
}
