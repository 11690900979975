/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  PutMaterialOperationMoveBetweenMachinesRequest,
  PutMaterialOperationMoveBetweenStoragesRequest,
  PutMaterialOperationMoveToMachineRequest,
  PutMaterialOperationMoveToStorageRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】ブース間の材料移動
 */
export const putMaterialOperationMoveBetweenMachines = (
  arcadeCd: string,
  putMaterialOperationMoveBetweenMachinesRequest: PutMaterialOperationMoveBetweenMachinesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/move/betweenMachines`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationMoveBetweenMachinesRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】保管場所間の材料移動
 */
export const putMaterialOperationMoveBetweenStorages = (
  arcadeCd: string,
  putMaterialOperationMoveBetweenStoragesRequest: PutMaterialOperationMoveBetweenStoragesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/move/betweenStorages`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationMoveBetweenStoragesRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】ブース内に材料投入
 */
export const putMaterialOperationMoveToMachine = (
  arcadeCd: string,
  putMaterialOperationMoveToMachineRequest: PutMaterialOperationMoveToMachineRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/move/toMachine`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationMoveToMachineRequest,
    },
    options,
  )
}
/**
 * @summary 【材料】保管場所に材料戻し
 */
export const putMaterialOperationMoveToStorage = (
  arcadeCd: string,
  putMaterialOperationMoveToStorageRequest: PutMaterialOperationMoveToStorageRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/operation/move/toStorage`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialOperationMoveToStorageRequest,
    },
    options,
  )
}
export type PutMaterialOperationMoveBetweenMachinesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationMoveBetweenMachines>>
>
export type PutMaterialOperationMoveBetweenStoragesResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationMoveBetweenStorages>>
>
export type PutMaterialOperationMoveToMachineResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationMoveToMachine>>
>
export type PutMaterialOperationMoveToStorageResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialOperationMoveToStorage>>
>
