/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialInventoryGroupsParams,
  GetMaterialInventoryGroupsResponse,
  PutMaterialInventoryGroupRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚卸グループ一覧を取得
 */
export const getMaterialInventoryGroups = (
  arcadeCd: string,
  params?: GetMaterialInventoryGroupsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryGroupsResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/groups`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】棚卸グループを設定
 */
export const putMaterialInventoryGroups = (
  arcadeCd: string,
  putMaterialInventoryGroupRequest: PutMaterialInventoryGroupRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/groups`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryGroupRequest,
    },
    options,
  )
}
export type GetMaterialInventoryGroupsResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryGroups>>
>
export type PutMaterialInventoryGroupsResult = NonNullable<
  Awaited<ReturnType<typeof putMaterialInventoryGroups>>
>
