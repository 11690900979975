import { Suspense, useState, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  CircularProgress,
  Button,
  Typography,
  TextField,
  Divider,
  Chip,
  DialogContent,
  alpha,
} from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import {
  PrizeFloorMapPoint,
  Prize,
  RequestPrizeOperationMoveBoothPlacementEnum,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import {
  putPrizeOperationMoveBetweenBooths,
  putPrizeOperationMoveToBooth,
} from "src/api/prize-operation-move"
import { getPrizeOperationStocks } from "src/api/prize-operation-stocks"
import {
  getPlacementMoveFrom,
  StockMoveFrom,
  updateStockMoveFrom,
} from "src/components//templates/InventoryPrizeStockDetails"
import { BackButton } from "src/components/atoms/BackButton"
import { tableHeadStyle } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
} from "src/components/organisms/FloorMapBox"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import {
  convertToFloorMapPoint,
  isFloorMapPointAvailable,
  PrizeInventoryFloorMapPoint,
} from "src/domains/prizes/inventoryFloorMapRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { snackbarErrorMessageState } from "src/recoil"
import { theme } from "src/theme"

export interface InventoryPrizeMoveToBoothFormInput {
  floorMapPointId?: number
}

interface InventoryPrizeMoveToBoothProps {
  prize: Prize
  stockMoveFrom: StockMoveFrom
  isInBooth: boolean
  onFinish: () => void
}

export const InventoryPrizeMoveToBooth: React.FC<
  InventoryPrizeMoveToBoothProps
> = (props) => {
  return (
    <Box>
      <Box mb={2}>移動先のブースを選択してください</Box>

      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        }
      >
        <InventoryMoveToBoothMenu {...props} />
      </Suspense>
    </Box>
  )
}

const InventoryMoveToBoothMenu: React.FC<InventoryPrizeMoveToBoothProps> = ({
  prize,
  stockMoveFrom,
  isInBooth,
  onFinish,
}) => {
  const { arcadeCd } = useParams()
  const { resource } = useResource({
    subject: "ブースリストの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  })
  const floorMapPoints = resource?.data.floorMapPoints

  const [floorMapPointIdMoveTo, setFloorMapPointIdMoveTo] = useState<number>()
  const floorMapPointMoveTo = floorMapPoints?.find(
    (p) => p.id === floorMapPointIdMoveTo,
  )

  const { setValue, handleSubmit, control } =
    useForm<InventoryPrizeMoveToBoothFormInput>()

  const value = {
    floorMapPointId: useWatch({
      control,
      name: "floorMapPointId",
    }),
  }
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const isOriginalInBooth = (floorMapPointId: number) => {
    const { boothShelf } =
      floorMapPoints?.find((p) => p.id === floorMapPointId) || {}
    const { boothStock } = stockMoveFrom
    return !!(
      isInBooth &&
      boothShelf &&
      boothStock &&
      boothShelf.id === boothStock.boothShelfId
    )
  }

  const isOriginalOnBooth = (floorMapPointId: number) => {
    const { onBoothShelf } =
      floorMapPoints?.find((p) => p.id === floorMapPointId) || {}
    const { onBoothStock } = stockMoveFrom
    return !!(
      !isInBooth &&
      onBoothShelf &&
      onBoothStock &&
      onBoothShelf.id === onBoothStock.onBoothShelfId
    )
  }

  const onSubmit: SubmitHandler<InventoryPrizeMoveToBoothFormInput> = (
    data,
  ) => {
    const { floorMapPointId } = data
    const floorMapPoint = floorMapPoints?.find((p) => p.id === floorMapPointId)

    if (floorMapPointId && isOriginalInBooth(floorMapPointId)) {
      setErrorMessage("移動前と同じブースには投入できません")
      return
    }
    if (floorMapPointId && isOriginalOnBooth(floorMapPointId)) {
      setErrorMessage("移動前と同じブース上には移動できません")
      return
    }

    setFloorMapPointIdMoveTo(floorMapPoint?.id)
  }

  const onClickPoint = (point: BaseFloorMapPoint) => {
    setValue("floorMapPointId", point.id)
  }

  const shouldFill = (point: BaseFloorMapPoint) =>
    point.id === value.floorMapPointId

  const isOriginalPoint = (point: BaseFloorMapPoint) =>
    isOriginalInBooth(point.id) || isOriginalOnBooth(point.id)

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <InventoryMoveToBoothFloorMapPointBox
      key={point.id}
      {...{
        point,
        onClickPoint,
        shouldFill,
        isOriginalPoint,
        isInBooth,
      }}
    />
  )

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ mb: 2 }}>
          {floorMapPoints && (
            <FloorMapBox
              {...{
                floorMapPoints: floorMapPoints.map((p) =>
                  convertToFloorMapPoint(p),
                ),
                onClickPoint,
                getFloorMapPointBox,
              }}
            />
          )}
        </Grid>

        <Button
          variant="contained"
          type="submit"
          sx={{ mb: 2 }}
          fullWidth
          disabled={!value.floorMapPointId}
        >
          {isInBooth
            ? "選択したブースに景品を投入する"
            : "選択したブース上に景品を移動する"}
        </Button>
      </form>

      {floorMapPointMoveTo && floorMapPoints && (
        <InventoryMoveToBoothModal
          showModal={!!floorMapPointMoveTo}
          prize={prize}
          defaultStockMoveFrom={stockMoveFrom}
          floorMapPointMoveTo={floorMapPointMoveTo}
          floorMapPoints={floorMapPoints}
          isInBooth={isInBooth}
          onClose={() => setFloorMapPointIdMoveTo(undefined)}
          onFinish={() => onFinish()}
        />
      )}
    </>
  )
}

interface InventoryMoveToBoothModalProps {
  showModal: boolean
  prize: Prize
  defaultStockMoveFrom: StockMoveFrom
  floorMapPointMoveTo: PrizeFloorMapPoint
  floorMapPoints: PrizeFloorMapPoint[]
  isInBooth: boolean
  onClose: () => void
  onFinish: () => void
}

export const InventoryMoveToBoothModal: React.FC<
  InventoryMoveToBoothModalProps
> = (props) => {
  const {
    showModal,
    onClose,
    prize: { prizeCd, prizeName },
  } = props

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitleWithClose onClose={onClose}>
        <Typography variant="h1" pb={1}>
          {prizeName}
        </Typography>

        <Box
          sx={{
            maxWidth: "80%",
            my: 1,
            mx: "auto",
          }}
        >
          <PrizeImageBox
            prizeCd={prizeCd}
            alt={prizeName}
            noImageSize="large"
          />
        </Box>
      </DialogTitleWithClose>

      <Suspense fallback={<LoadingBox relative />}>
        <InventoryMoveToBoothModalForm {...props} />
      </Suspense>
    </CustomDialog>
  )
}

export interface InventoryMoveToBoothModalFormInput {
  stock: number
}

export const InventoryMoveToBoothModalForm: React.FC<
  InventoryMoveToBoothModalProps
> = ({
  prize,
  defaultStockMoveFrom,
  floorMapPointMoveTo,
  floorMapPoints,
  isInBooth,
  onClose,
  onFinish,
}) => {
  const { arcadeCd } = useParams()
  const { prizeCd } = prize

  const { resource } = useResource({
    subject: "在庫情報の取得",
    fetch:
      arcadeCd && prizeCd
        ? () => getPrizeOperationStocks(arcadeCd, { prizeCd })
        : undefined,
    recoilKey: `getInventoryStocks:${arcadeCd}:${prizeCd}`,
  })
  const { stockMoveFrom, boothStocks, onBoothStocks } = useMemo(() => {
    const stocks = resource?.data.stocks
    if (!stocks) {
      return {
        stockMoveFrom: defaultStockMoveFrom,
        boothStocks: [],
        onBoothStocks: [],
      }
    }
    const stockMoveFrom = updateStockMoveFrom(defaultStockMoveFrom, stocks)
    const boothStocks = stocks.flatMap((stock) =>
      stock.boothStocks.filter(
        (stock) => stock.boothShelfId === floorMapPointMoveTo.boothShelf.id,
      ),
    )
    const onBoothStocks = stocks.flatMap((stock) =>
      stock.onBoothStocks.filter(
        (stock) => stock.onBoothShelfId === floorMapPointMoveTo.onBoothShelf.id,
      ),
    )
    return { stockMoveFrom, boothStocks, onBoothStocks }
  }, [resource, defaultStockMoveFrom, floorMapPointMoveTo])

  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  const placementMoveToStockCount = useMemo(() => {
    if (isInBooth) {
      return boothStocks.find((s) => s.prizeCd === prizeCd)?.stock || 0
    }
    // NOTE: in_booth でない場合は、on_booth
    return onBoothStocks.find((s) => s.prizeCd === prizeCd)?.stock || 0
  }, [boothStocks, onBoothStocks, isInBooth, prizeCd])

  const validationSchema = Yup.object({
    stock: Yup.number()
      .required("必須です")
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .max(placementMoveFrom.stock, "移動前の在庫数を越えています"),
  })

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<InventoryMoveToBoothModalFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stock: placementMoveFrom.stock || 0,
    },
  })
  const value = {
    stock: Number(useWatch({ control, name: "stock" })) || 0,
  }

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<InventoryMoveToBoothModalFormInput> = async (
    data,
  ) => {
    const { shelfStock, boothStock, onBoothStock } = stockMoveFrom
    const putMove = (arcadeCd: string) => {
      const { InBooth, OnBooth } = RequestPrizeOperationMoveBoothPlacementEnum
      const baseRequest = {
        from: {},
        to: {
          inventoryFloorMapPointId: floorMapPointMoveTo.id,
          placement: isInBooth ? InBooth : OnBooth,
        },
        prize: {
          prizeCd,
          stock: data.stock,
        },
      }

      if (shelfStock) {
        const request = {
          ...baseRequest,
          from: { shelfId: shelfStock.shelfId },
        }
        return putPrizeOperationMoveToBooth(arcadeCd, request)
      }
      if (boothStock) {
        const floorMapPoint = floorMapPoints.find(
          (p) => p.boothShelf.id === boothStock?.boothShelfId,
        )
        if (!floorMapPoint) return
        const request = {
          ...baseRequest,
          from: {
            inventoryFloorMapPointId: floorMapPoint?.id,
            placement: InBooth,
          },
        }
        return putPrizeOperationMoveBetweenBooths(arcadeCd, request)
      }
      if (onBoothStock) {
        const floorMapPoint = floorMapPoints.find(
          (p) => p.onBoothShelf.id === onBoothStock?.onBoothShelfId,
        )
        if (!floorMapPoint) return
        const request = {
          ...baseRequest,
          from: {
            inventoryFloorMapPointId: floorMapPoint?.id,
            placement: OnBooth,
          },
        }
        return putPrizeOperationMoveBetweenBooths(arcadeCd, request)
      }
      return
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: isInBooth ? "ブースへの投入" : "プライズ機上への移動",
          showSuccessMessage: true,
          promise: async () => {
            await (putMove(arcadeCd) || new Promise((_, reject) => reject()))
            onClose()
            onFinish()
          },
        },
      ]))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={8} sx={tableHeadStyle}>
            移動させる数
          </Grid>
          <Grid item xs={3}>
            <Controller
              control={control}
              name={"stock"}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.stock?.message}
                  helperText={errors.stock?.message}
                  inputProps={{ inputMode: "numeric" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "right" }}>
            個
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid item xs={2} sx={tableHeadStyle}>
            移動前
          </Grid>
          <Grid item xs={6}>
            {placementMoveFrom.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong> 個
          </Grid>

          <Grid item xs={12} mb={1} />

          <Grid item xs={2} />
          <Grid item xs={6}>
            {floorMapPointMoveTo.name}{" "}
            {!isInBooth && <Chip label="プライズ機上" size="small" />}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveToStockCount}</strong> 個
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          <Grid item xs={2} sx={tableHeadStyle}>
            移動後
          </Grid>
          <Grid item xs={6}>
            {placementMoveFrom.name}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock - value.stock}</strong> 個
          </Grid>

          <Grid item xs={12} mb={1} />

          <Grid item xs={2} />
          <Grid item xs={6}>
            {floorMapPointMoveTo.name}{" "}
            {!isInBooth && <Chip label="プライズ機上" size="small" />}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveToStockCount + value.stock}</strong> 個
          </Grid>
        </Grid>
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          fullWidth
          sx={{ height: 44, lineHeight: 1.2, px: 0.5 }}
        >
          {isInBooth ? "景品投入を保存" : "景品移動を保存"}
        </LoadingButton>
      </CustomDialogActions>
    </form>
  )
}

interface InventoryMoveToBoothFloorMapPointBoxProps
  extends FloorMapPointBoxProps {
  shouldFill: (point: BaseFloorMapPoint) => boolean
  isOriginalPoint: (point: BaseFloorMapPoint) => boolean
  isInBooth: boolean
}

export const InventoryMoveToBoothFloorMapPointBox: React.FC<
  InventoryMoveToBoothFloorMapPointBoxProps
> = ({
  point,
  onClickPoint = () => undefined,
  shouldFill,
  isOriginalPoint,
  isInBooth,
}: InventoryMoveToBoothFloorMapPointBoxProps) => {
  const isAvailable = useMemo(
    () =>
      isFloorMapPointAvailable(isInBooth, point as PrizeInventoryFloorMapPoint),
    [isInBooth, point],
  )

  const backgroundColor = useMemo(() => {
    if (!isAvailable) {
      return alpha(theme.palette.text.disabled, 0.7)
    }

    if (shouldFill(point)) {
      return theme.palette.success.light
    }
    if (isOriginalPoint(point)) {
      return alpha(theme.palette.neutral[500], 0.3)
    }
    return "transparent"
  }, [shouldFill, isOriginalPoint, point, isAvailable])

  return (
    <FloorMapPointBox
      {...{
        point,
        onClickPoint: isAvailable ? onClickPoint : () => undefined,
      }}
      sx={{
        backgroundColor,
        cursor: isAvailable ? "pointer" : "default",
      }}
    />
  )
}
