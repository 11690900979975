import { Box, Typography, Card, Table, TableBody } from "@mui/material"
import { useParams } from "react-router-dom"

import { getPrizeArrivals } from "src/api/prize-arrivals"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"

export const InventoryPrizeDetails: React.FC = () => {
  return (
    <MainContentLayout
      title="入庫検収・景品詳細"
      renderContent={() => <InventoryPrizeDetailsCard />}
    />
  )
}

const InventoryPrizeDetailsCard = () => {
  const { arcadeCd, prizeCd, date } = useParams()

  const { resource } = useResource({
    subject: "詳細情報の取得",
    fetch:
      arcadeCd && date && prizeCd
        ? () => getPrizeArrivals(arcadeCd, { from: date, to: date, prizeCd })
        : undefined,
    recoilKey: `getArrivals:${arcadeCd}:${date}:${date}:${prizeCd}`,
  })
  const arrival = resource?.data.arrivals[0]

  if (!arrival) return <></>

  const {
    arrival: { orderCount },
    prize,
  } = arrival
  const {
    prizeName,
    unitPerCarton,
    makerName,
    ipName,
    unitPriceJpy,
    assortmentKindCount,
    targetSegmentName,
  } = prize

  const listItems = [
    {
      key: "景品CD",
      value: prize.prizeCd,
    },
    {
      key: "景品名",
      value: prizeName,
    },
    {
      key: "着荷予定数",
      value: orderCount.toLocaleString(),
    },
    {
      key: "カートン入数",
      value: unitPerCarton.toLocaleString(),
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "単価",
      value: `${unitPriceJpy?.toLocaleString()}円`,
    },
    {
      key: "アソート種数",
      value: `${assortmentKindCount.toLocaleString()}種`,
    },
    {
      key: "対象層",
      value: targetSegmentName,
    },
  ].filter((item) => !!item)

  return (
    <Card>
      <Table>
        <TableBody>
          <TableBorderedRow>
            <ExtTableCell colSpan={2}>
              <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                <PrizeImageBox
                  prizeCd={prize.prizeCd}
                  alt={prizeName}
                  noImageSize="large"
                />
              </Box>
            </ExtTableCell>
          </TableBorderedRow>

          {listItems.map(
            (item) =>
              item &&
              item.value && (
                <TableBorderedRow key={item.key}>
                  <CardKeyCell>
                    <Typography variant="body2">{item.key}</Typography>
                  </CardKeyCell>
                  <CardValueCell>
                    <Typography variant="body2"> {item.value}</Typography>
                  </CardValueCell>
                </TableBorderedRow>
              ),
          )}
        </TableBody>
      </Table>
    </Card>
  )
}
