import { Suspense } from "react"

import { Stack } from "@mui/material"
import { useLocation, useParams } from "react-router-dom"

import { getPrizeDelivery } from "src/api/prize-deliveries"
import { getPrizes } from "src/api/prizes"
import { BackButton } from "src/components/atoms/BackButton"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PrizeDetails } from "src/components/organisms/prizes/PrizeDetails"
import { useResource } from "src/hooks/useResource"
import { NotFound } from "src/pages/NotFound"

export const PrizeShow: React.FC = () => {
  return (
    <Stack p={3} gap={2}>
      <Suspense fallback={<LoadingBox />}>
        <PrizeShowInner />
      </Suspense>

      <BackButton>戻る</BackButton>
    </Stack>
  )
}

const PrizeShowInner: React.FC = () => {
  const { arcadeCd, prizeCd } = useParams()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const prizeDeliveryId = Number(query.get("prizeDeliveryId"))

  const prizesReturn = useResource({
    subject: "景品の取得",
    fetch: arcadeCd ? () => getPrizes(arcadeCd, { prizeCd }) : undefined,
    recoilKey: `getPrizes:${arcadeCd}:${prizeCd}`,
  }).resource
  const prize = prizesReturn?.data.prizes[0]

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品の取得",
    fetch:
      arcadeCd && !isNaN(prizeDeliveryId)
        ? () => getPrizeDelivery(arcadeCd, prizeDeliveryId)
        : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${prizeDeliveryId}`,
    skip: !prizeDeliveryId || isNaN(prizeDeliveryId),
  }).resource
  const delivery = prizeDeliveriesReturn?.data.delivery

  if (!prize) return <NotFound />

  return <PrizeDetails prize={prize} delivery={delivery} />
}
