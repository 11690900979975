import { Select, MenuItem } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { MaterialStockLedgerHistory } from "src/api/models"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { materialStockLedgerSortByLabels } from "src/domains/materials/materialStockLedgerRepository"

export const inventoryMaterialStockLedgerSearchParamsState =
  atom<InventoryMaterialStockLedgerSearchParams>({
    key: "inventoryMaterialStockLedgerSearchParamsState",
    default: {},
  })

export type InventoryMaterialStockLedgerSearchParams = {
  sortBy?: keyof typeof materialStockLedgerSortByLabels
}

export const InventoryMaterialStockLedgerFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialStockLedgerSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="並び替え"
      defaultExpanded={!!searchParams.sortBy}
      formInputs={[
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(materialStockLedgerSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterStockLedgers = (
  ledgerHistories: MaterialStockLedgerHistory[],
  params: InventoryMaterialStockLedgerSearchParams,
) => {
  const { sortBy } = params

  const getSortCompareBy = () => {
    if (sortBy === "recordedAtAsc") {
      return (a: MaterialStockLedgerHistory, b: MaterialStockLedgerHistory) =>
        (a.recordedAt || "") <= (b.recordedAt || "") ? 1 : -1
    }
    if (sortBy === "recordedAtDesc") {
      return (a: MaterialStockLedgerHistory, b: MaterialStockLedgerHistory) =>
        (a.recordedAt || "") >= (b.recordedAt || "") ? 1 : -1
    }

    return () => 1
  }

  return ledgerHistories.sort(getSortCompareBy())
}
