import { LoadingButton } from "@mui/lab"
import { DialogContent, Typography, TextField } from "@mui/material"
import { SubmitHandler, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { applyMaterialInventoryDifference } from "src/api/material-inventory-differences"
import {
  MaterialInventoryDifference,
  PutMaterialInventoryApprovalApplyRequest,
  FeatureExecutionPeriod,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { useSubmitting } from "src/hooks/useSubmitting"

type InventoryMaterialApplyDifferenceFormInput = { application: string }

interface InventoryMaterialApplyDifferenceModalProps {
  showModal: boolean
  difference: MaterialInventoryDifference
  materialExecutionPeriodId: FeatureExecutionPeriod["id"]
  onClose: () => void
  onFinish?: () => void
}

export const InventoryMaterialApplyDifferenceModal: React.FC<
  InventoryMaterialApplyDifferenceModalProps
> = ({
  showModal,
  difference,
  materialExecutionPeriodId,
  onClose,
  onFinish = () => undefined,
}: InventoryMaterialApplyDifferenceModalProps) => {
  const { arcadeCd } = useParams()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<InventoryMaterialApplyDifferenceFormInput>({
    defaultValues: {
      application: difference.approvalApplication,
    },
    mode: "onChange",
  })

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<
    InventoryMaterialApplyDifferenceFormInput
  > = async ({ application }) => {
    if (!arcadeCd) return
    const request: PutMaterialInventoryApprovalApplyRequest = {
      application,
      materialCd: difference.materialCd || "",
      importedStock: difference.importedStock || 0,
      stock: difference.stock || 0,
      consumedPrice: difference.consumedPrice || 0,
      differentPrice: difference.differentPrice || 0,
    }

    await submitPromises([
      {
        subject: "材料差分の承認申請",
        showSuccessMessage: true,
        promise: async () => {
          await applyMaterialInventoryDifference(
            arcadeCd,
            materialExecutionPeriodId,
            request,
          )
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={onClose}
      scroll="paper"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitleWithClose onClose={onClose}>
          <Typography variant="h1">戻しが10万円を超えた理由</Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="理由を入力"
            multiline
            rows={4}
            {...register("application", {
              required: "必須です",
              maxLength: {
                value: 100,
                message:
                  "文字数上限を超えています。100文字以内で入力してください",
              },
            })}
            error={!!errors.application}
            helperText={errors.application?.message}
          />
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </CustomDialogActions>
      </form>
    </CustomDialog>
  )
}
