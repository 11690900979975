import { useState, useMemo, useCallback, useEffect } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Typography,
  Button,
  TableHead,
  TextField,
  TableRow,
  CircularProgress,
  DialogContent,
  alpha,
  Stack,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  PatchPrizeShelvesOrderRequest,
  PutPrizeStoragesRequest,
  PrizeShelf,
  PrizeStorage,
} from "src/api/models"
import { patchPrizeShelvesOrder } from "src/api/prize-shelves"
import {
  deletePrizeStorage,
  getPrizeStorage,
  putPrizeStorages,
} from "src/api/prize-storages"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import {
  SortableListTableRow,
  SortableTable,
} from "src/components/organisms/SortableTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { temporaryStorageName } from "src/domains/prizes/inventoryStockRepository"
import { getShelfStorageTypeLabel } from "src/domains/prizes/shelfRepository"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { currentArcadeState } from "src/recoil"
import { theme } from "src/theme"

export const InventoryPrizeShelves = () => {
  const { arcadeCd, storageId } = useParams()

  const { resource, refetch } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
  })
  const storage = resource?.data.storage
  const shelves = resource?.data.shelves

  return (
    <MainContentLayout
      title={storage?.name ?? ""}
      renderContent={() => (
        <InventoryPrizeShelvesMenu
          storage={storage}
          shelves={shelves}
          refetch={refetch}
        />
      )}
    />
  )
}

type InventoryPrizeShelvesMenuProps = {
  storage?: PrizeStorage
  shelves?: PrizeShelf[]
  refetch: () => Promise<void>
}

const InventoryPrizeShelvesMenu: React.FC<InventoryPrizeShelvesMenuProps> = ({
  storage,
  shelves,
  refetch,
}) => {
  const { arcadeCd, storageId } = useParams()
  const navigate = useNavigate()

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const isTemporaryStorage = useMemo(
    () => storage?.name === temporaryStorageName,
    [storage?.name],
  )

  const onSubmitEditName = async ({ storageName }: { storageName: string }) => {
    if (!arcadeCd || !storageId || isNaN(Number(storageId))) return
    const request: PutPrizeStoragesRequest = {
      id: Number(storageId),
      name: storageName,
    }
    submitPromises([
      {
        subject: "保管場所の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putPrizeStorages(arcadeCd, request)
          refetch()
          setEditModalOpen(false)
        },
      },
    ])
  }

  const onDelete = async () => {
    const numStorageId = Number(storageId)
    if (!arcadeCd || isNaN(numStorageId)) return

    submitPromises([
      {
        subject: "保管場所の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deletePrizeStorage(arcadeCd, numStorageId)
          return navigate(-1)
        },
      },
    ])
  }

  return (
    <>
      <Stack gap={2}>
        <Stack>
          <InventoryPrizeShelvesTable storage={storage} shelves={shelves} />
        </Stack>

        <Stack>
          <Button
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            disabled={isTemporaryStorage}
            onClick={() => !isTemporaryStorage && setEditModalOpen(true)}
          >
            保管場所の名前を変更
          </Button>
        </Stack>

        <Stack>
          <Button
            variant="outlined"
            sx={{ background: "white" }}
            fullWidth
            component={RouterLink}
            disabled={isTemporaryStorage}
            to={
              isTemporaryStorage
                ? "#"
                : `/arcades/${arcadeCd}/prizes/settings/storages/${storageId}/shelves/new`
            }
          >
            棚を追加
          </Button>
        </Stack>

        <Stack>
          <Button
            variant="contained"
            color="error"
            fullWidth
            disabled={isTemporaryStorage}
            onClick={() => !isTemporaryStorage && setDeleteModalOpen(true)}
          >
            保管場所を削除
          </Button>
        </Stack>
      </Stack>

      <CustomDialog
        fullWidth
        maxWidth="sm"
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      >
        <EditModal
          onSubmit={onSubmitEditName}
          onClose={() => setEditModalOpen(false)}
          currentName={storage?.name ?? ""}
          isSubmitting={isSubmitting}
        />
      </CustomDialog>

      <DeleteModal
        showModal={deleteModalOpen}
        onSubmit={onDelete}
        onClose={() => setDeleteModalOpen(false)}
        isSubmitting={isSubmitting}
      />
    </>
  )
}

type InventoryShelvesTableProps = {
  storage?: PrizeStorage
  shelves?: PrizeShelf[]
}

const InventoryPrizeShelvesTable: React.FC<InventoryShelvesTableProps> = ({
  storage,
  shelves,
}) => {
  const { arcadeCd, storageId } = useParams()
  const navigate = useNavigate()

  const arcade = useRecoilValue(currentArcadeState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises

  const sortedItems = useMemo(
    () =>
      [...((shelves ?? []) satisfies PrizeShelf[])].sort(
        (a, b) => a.displayOrder - b.displayOrder,
      ),
    [shelves],
  )

  const [items, setItems] = useState(sortedItems)

  const onChangeDrag = useCallback(
    (newState: PrizeShelf[]) => {
      if (!arcadeCd || !storage) return
      setItems(newState)
      const request: PatchPrizeShelvesOrderRequest = {
        shelfOrders: newState.map(({ id }, index) => ({
          id,
          displayOrder: index + 1,
        })),
      }
      submitPromises([
        {
          subject: "棚の並び替え",
          showSuccessMessage: true,
          promise: () => patchPrizeShelvesOrder(arcadeCd, storage.id, request),
        },
      ])
    },
    [arcadeCd, storage, submitPromises],
  )

  return (
    <>
      {isSubmitting && (
        <CircularProgress
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            position: "absolute",
          }}
        />
      )}
      <SortableTable
        header={
          <TableHead>
            <TableRow>
              <ExtTableCell />
              <ExtTableCell>名称</ExtTableCell>
              <ExtTableCell>保管分類</ExtTableCell>
              <ExtTableCell>棚卸グループ</ExtTableCell>
              {arcade?.hasKidsMarket && <ExtTableCell>棚の種別</ExtTableCell>}
            </TableRow>
          </TableHead>
        }
        items={items}
        renderRow={({ item }) => (
          <SortableListTableRow
            id={item.id}
            hover
            sx={{
              color: theme.palette.neutral[900],
              cursor: "pointer",
              ...(!item.isAvailable && {
                background: alpha(theme.palette.text.disabled, 0.3),
              }),
            }}
            onClick={() => {
              if (storage?.name === temporaryStorageName) return
              if (!item.isAvailable) return

              navigate(
                `/arcades/${arcadeCd}/prizes/settings/storages/${storageId}/shelves/${item.id}`,
              )
            }}
            key={item.id}
            data-testid={item.id}
          >
            <ExtTableCell>{item.name}</ExtTableCell>
            <ExtTableCell>
              {getShelfStorageTypeLabel(item.storageType)}
            </ExtTableCell>
            <ExtTableCell>{item.inventoryGroup?.groupName}</ExtTableCell>
            {arcade?.hasKidsMarket && (
              <ExtTableCell>
                {item.isKidsMarket ? "KM景品専用" : ""}
              </ExtTableCell>
            )}
          </SortableListTableRow>
        )}
        onChangeDrag={onChangeDrag}
      />
    </>
  )
}

type EditModalProps = {
  currentName: string
  onSubmit: (params: { storageName: string }) => Promise<void>
  onClose: React.MouseEventHandler<HTMLButtonElement>
  isSubmitting: boolean
}

const EditModal: React.FC<EditModalProps> = ({
  onSubmit,
  onClose,
  currentName,
  isSubmitting,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ storageName: string }>()
  useEffect(() => {
    reset({ storageName: currentName })
  }, [currentName, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Typography gutterBottom variant="h1">
          保管場所の名前
        </Typography>

        <Controller
          control={control}
          name={"storageName"}
          defaultValue={currentName ?? ""}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ width: "100%" }}
              error={!!errors.storageName?.message}
              helperText={errors.storageName?.message}
            />
          )}
        />
      </DialogContent>

      <CustomDialogActions>
        <BackButton onClick={onClose}>保存せず戻る</BackButton>
        <LoadingButton
          variant="contained"
          fullWidth
          type="submit"
          loading={isSubmitting}
        >
          保存
        </LoadingButton>
      </CustomDialogActions>
    </form>
  )
}
