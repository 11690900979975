import { TextField, MenuItem, Select } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { InventoryMaterialExecuteSingleMenuItem } from "src/components/templates/InventoryMaterialExecuteSingle"
import {
  inventoryMaterialExecuteDetailsFilterSortByLabels,
  InventoryMaterialExecuteSingleSearchParams,
  materialExecutionStatus,
} from "src/domains/materials/materialInventoryHistoriesRepository"

export const inventoryMaterialExecuteSingleSearchParamsState =
  atom<InventoryMaterialExecuteSingleSearchParams>({
    key: "InventoryMaterialExecuteSingleSearchParamsState",
    default: {
      materialExecutionStatus: "notExecuted",
    },
  })

export const InventoryMaterialExecuteSingleFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialExecuteSingleSearchParamsState,
  )

  const isMaterialExecutionStatusChanged =
    searchParams.materialExecutionStatus !== "notExecuted"

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え・表示切替"
      defaultExpanded={
        !!(
          searchParams.materialCd ||
          searchParams.materialName ||
          searchParams.materialNameKana ||
          searchParams.makerName ||
          searchParams.ipName ||
          searchParams.sortBy ||
          isMaterialExecutionStatusChanged
        )
      }
      formInputs={[
        {
          name: "materialCd",
          label: "材料CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialName",
          label: "材料名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "materialNameKana",
          label: "材料名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              placeholder="カタカナ部分一致"
              error={!!error}
              helperText={error?.message}
            />
          ),
        },
        {
          name: "materialExecutionStatus",
          label: "棚卸状態",
          render: ({ field }) => (
            <Select
              {...field}
              value={field.value ?? ""}
              inputProps={{
                "aria-label": "materialExecutionStatus",
              }}
            >
              {Object.entries(materialExecutionStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} value={field.value ?? ""}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(
                inventoryMaterialExecuteDetailsFilterSortByLabels,
              ).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

export const filterInventoryMaterialExecuteSingleMenuItems = (
  menuItems: InventoryMaterialExecuteSingleMenuItem[],
  params: InventoryMaterialExecuteSingleSearchParams,
) => {
  const { materialExecutionStatus } = params

  return menuItems.filter((item) => {
    if (materialExecutionStatus === "executed") {
      return item.executed
    }
    if (materialExecutionStatus === "notExecuted") {
      return !item.executed
    }
    return true
  })
}
