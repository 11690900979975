import React, { useMemo } from "react"

import { CardContent, List, Card } from "@mui/material"
import { useParams } from "react-router-dom"

import { getMaterialStorage } from "src/api/material-storages"
import { MaterialShelf } from "src/api/models"
import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

export const InventoryMaterialPlacementStorage = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const { resource: storageResource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
    skip: !storageId,
  })
  const { storage, shelf } = useMemo(() => {
    if (storageResource) {
      const { storage, shelves } = storageResource.data
      return {
        storage,
        shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
      }
    }
    return { storage: undefined, shelf: undefined }
  }, [storageResource, shelfId])

  return (
    <MainContentLayout
      title={storage && shelf ? storage.name + " " + shelf.name : ""}
      renderContent={() => (
        <InventoryMaterialPlacementStorageInner shelf={shelf} />
      )}
    />
  )
}

type InventoryMaterialPlacementStorageInnerProps = {
  shelf?: MaterialShelf
}

export const InventoryMaterialPlacementStorageInner: React.FC<
  InventoryMaterialPlacementStorageInnerProps
> = ({ shelf }) => {
  const { isAvailable } = shelf || {}

  return !isAvailable ? (
    <Card>
      <CardContent>無効に設定されています</CardContent>
    </Card>
  ) : (
    <InventoryMaterialPlacementStorageMenu />
  )
}

const InventoryMaterialPlacementStorageMenu: React.FC = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const menuItems = arcadeCd
    ? [
        {
          href: `/arcades/${arcadeCd}/materials/placements/storage/${storageId}/${shelfId}/materials`,
          title: "保管されている材料一覧",
        },
        {
          href: `/arcades/${arcadeCd}/inventory/materials/execute/storage/${storageId}/${shelfId}`,
          title: "棚卸実施",
        },
      ]
    : []

  return (
    <Card>
      <CardContent>
        <List sx={{ width: "100%", color: theme.palette.primary.main }}>
          {menuItems.map((i) => (
            <MenuButton key={i.title} to={i.href}>
              {i.title}
            </MenuButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
