import { forwardRef } from "react"

import { Search } from "@mui/icons-material"
import ClearIcon from "@mui/icons-material/Clear"
import {
  Card,
  CardContent,
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  TextFieldProps,
} from "@mui/material"
import IconButton from "@mui/material/IconButton"

export type SearchBarProps = {
  searchButtonText?: string
  showDeleteButton?: boolean
  onClickDeleteButton?: React.MouseEventHandler<HTMLButtonElement>
} & TextFieldProps

export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      searchButtonText = "検索",
      showDeleteButton,
      onClickDeleteButton,
      ...restProps
    },
    ref,
  ) => {
    return (
      <Card>
        <CardContent sx={{ ":last-child": { pb: 2 } }}>
          <Box sx={{ display: "flex" }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      sx={(theme) => ({
                        color: theme.palette.gray[40],
                      })}
                      fontSize="small"
                    >
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: showDeleteButton && (
                  <InputAdornment position="end">
                    <IconButton
                      sx={(theme) => ({
                        px: 0,
                        color: theme.palette.gray[40],
                      })}
                      onClick={onClickDeleteButton}
                    >
                      <ClearIcon sx={{ width: "14px", height: "14px" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                "aria-label": "searchbar",
              }}
              variant="outlined"
              {...restProps}
              inputRef={ref}
            />
            <Button type="submit" sx={{ ml: 2, px: 2 }}>
              {searchButtonText}
            </Button>
          </Box>
        </CardContent>
      </Card>
    )
  },
)

SearchBar.displayName = "SearchBar"
