import { useRef, useEffect } from "react"

/**
 * ページを開いたら自動で印刷ダイアログを表示する
 */
export const useAutoPrint = ({
  disabled,
  isReady,
}: {
  disabled?: boolean
  isReady: boolean
}) => {
  const hasPrinted = useRef(false)

  useEffect(() => {
    if (disabled) return

    // ロード完了を待つ
    if (!isReady) return

    // 印刷処理が複数回呼ばれるのを防ぐ
    if (hasPrinted.current) return
    hasPrinted.current = true

    setTimeout(() => {
      // ロード完了後に表示を少し待ってから印刷ダイアログを表示する
      window.print()

      // 印刷完了もしくはキャンセル後に自動でウィンドウを閉じる
      window.close()
    }, 200)
  }, [disabled, isReady])
}
