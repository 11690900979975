import { useState } from "react"

import { TabContext, TabPanel } from "@mui/lab"
import { Box, Typography, Container, Tabs, Tab } from "@mui/material"
import { useSearchParams } from "react-router-dom"

import { BoothsSalesTab } from "src/components/templates/BoothsSalesTab"
import { BoothsSearchTab } from "src/components/templates/BoothsSearchTab"

export type BoothsPageTab = "search" | "sales"

export const Booths: React.FC = () => {
  const [URLSearchParams, SetURLSearchParams] = useSearchParams()
  const tab = URLSearchParams.get("tab")

  const defaultTab = tab === "search" || tab === "sales" ? tab : "search"

  const [boothsPageTab, setBoothsPageTab] = useState<BoothsPageTab>(defaultTab)

  const onChangeSelectedTab = (value: BoothsPageTab) => {
    setBoothsPageTab(value)
    SetURLSearchParams({ tab: value })
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h1">
            ブース状況一覧
          </Typography>

          <TabContext value={boothsPageTab}>
            <Box sx={{ mb: 2 }}>
              <Tabs
                value={boothsPageTab}
                onChange={(_, value) => onChangeSelectedTab(value)}
              >
                <Tab value="search" label="入れ替え候補検索" />
                <Tab value="sales" label="売上・PO" />
              </Tabs>
            </Box>

            <TabPanel value="search" sx={{ padding: 0 }}>
              <BoothsSearchTab />
            </TabPanel>

            <TabPanel value="sales" sx={{ padding: 0 }}>
              <BoothsSalesTab />
            </TabPanel>
          </TabContext>
        </Container>
      </Box>
    </>
  )
}
