/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMaterialInventoryApprovalsResponse,
  GetMaterialInventoryDifferencesParams,
  GetMaterialInventoryDifferencesResponse,
  PutMaterialInventoryApprovalApplyRequest,
  PutMaterialInventoryApprovalApproveRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 【材料】棚卸時の在庫と論理在庫の差分一覧を取得
 */
export const getMaterialInventoryDifferences = (
  arcadeCd: string,
  materialExecutionPeriodId: number,
  params?: GetMaterialInventoryDifferencesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryDifferencesResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/differences/${materialExecutionPeriodId}`,
      method: "GET",
      params,
    },
    options,
  )
}
/**
 * @summary 【材料】承認済みの差分一覧を取得
 */
export const getMaterialInventoryApprovals = (
  arcadeCd: string,
  materialExecutionPeriodId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMaterialInventoryApprovalsResponse>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/approvals/${materialExecutionPeriodId}`,
      method: "GET",
    },
    options,
  )
}
/**
 * @summary 戻しが高額な差分の承認の申請
 */
export const applyMaterialInventoryDifference = (
  arcadeCd: string,
  materialExecutionPeriodId: number,
  putMaterialInventoryApprovalApplyRequest: PutMaterialInventoryApprovalApplyRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/approvals/${materialExecutionPeriodId}/apply`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryApprovalApplyRequest,
    },
    options,
  )
}
/**
 * @summary 戻しが高額な差分の承認
 */
export const approveMaterialInventoryDifference = (
  arcadeCd: string,
  materialExecutionPeriodId: number,
  putMaterialInventoryApprovalApproveRequest: PutMaterialInventoryApprovalApproveRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/materials/inventory/approvals/${materialExecutionPeriodId}/approve`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMaterialInventoryApprovalApproveRequest,
    },
    options,
  )
}
export type GetMaterialInventoryDifferencesResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryDifferences>>
>
export type GetMaterialInventoryApprovalsResult = NonNullable<
  Awaited<ReturnType<typeof getMaterialInventoryApprovals>>
>
export type ApplyMaterialInventoryDifferenceResult = NonNullable<
  Awaited<ReturnType<typeof applyMaterialInventoryDifference>>
>
export type ApproveMaterialInventoryDifferenceResult = NonNullable<
  Awaited<ReturnType<typeof approveMaterialInventoryDifference>>
>
