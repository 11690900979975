/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetMachineResponse,
  GetMachinesResponse,
  PutMachineRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 機種一覧を取得
 */
export const getMachines = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMachinesResponse>(
    { url: `/machines`, method: "GET" },
    options,
  )
}
/**
 * @summary 機種を作成・更新
 */
export const putMachine = (
  putMachineRequest: PutMachineRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/machines`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putMachineRequest,
    },
    options,
  )
}
/**
 * @summary 機種を削除
 */
export const deleteMachine = (
  machineName: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/machines/${machineName}`, method: "DELETE" },
    options,
  )
}
/**
 * @summary 機種詳細を取得
 */
export const getMachine = (
  machineName: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetMachineResponse>(
    { url: `/machines/${machineName}`, method: "GET" },
    options,
  )
}
export type GetMachinesResult = NonNullable<
  Awaited<ReturnType<typeof getMachines>>
>
export type PutMachineResult = NonNullable<
  Awaited<ReturnType<typeof putMachine>>
>
export type DeleteMachineResult = NonNullable<
  Awaited<ReturnType<typeof deleteMachine>>
>
export type GetMachineResult = NonNullable<
  Awaited<ReturnType<typeof getMachine>>
>
