import { TableHead, TableRow } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles"

import { Csv } from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"

interface CsvTableProps {
  csv: Csv
  limit?: number
  borderedCell?: boolean
  overrideCellSxProps?: {
    index: number
    sx: SxProps<Theme>
  }[]
}

export const CsvTable = ({
  csv,
  limit = 10,
  borderedCell = false,
  overrideCellSxProps = [],
}: CsvTableProps) => {
  const columnNames = csv?.headerRow?.columns || []
  const rows = csv?.rows || []

  return (
    <PaginatedTable
      scrollableX
      noMargin
      limit={limit}
      items={rows}
      header={
        <TableHead>
          <TableRow>
            {columnNames.map((columnName, i) => (
              <ExtTableCell
                border={borderedCell}
                sx={{ px: 1, whiteSpace: "nowrap" }}
                key={`${i}-${columnName}`}
              >
                {columnName}
              </ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(row, i) => {
        return (
          <TableRow
            sx={{
              td: { p: 1 },
            }}
            key={`${i}-${row.columns.join(",")}`}
            data-testid={`${i}-${row.columns.join(",")}`}
          >
            {row.columns.map((column, i) => {
              const sx = overrideCellSxProps.find((v) => v.index === i)
              return (
                <ExtTableCell
                  border={borderedCell}
                  key={`${i}-${column}`}
                  sx={{
                    whiteSpace: "nowrap",
                    ...(sx && sx.sx),
                  }}
                >
                  {column}
                </ExtTableCell>
              )
            })}
          </TableRow>
        )
      }}
    />
  )
}
