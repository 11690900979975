/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  PutPrizeOperationMoveBetweenBoothsRequest,
  PutPrizeOperationMoveBetweenStoragesRequest,
  PutPrizeOperationMoveToBoothRequest,
  PutPrizeOperationMoveToStorageRequest,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ブース間の景品移動
 */
export const putPrizeOperationMoveBetweenBooths = (
  arcadeCd: string,
  putPrizeOperationMoveBetweenBoothsRequest: PutPrizeOperationMoveBetweenBoothsRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/move/betweenBooths`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationMoveBetweenBoothsRequest,
    },
    options,
  )
}
/**
 * @summary 保管場所間の景品移動
 */
export const putPrizeOperationMoveBetweenStorages = (
  arcadeCd: string,
  putPrizeOperationMoveBetweenStoragesRequest: PutPrizeOperationMoveBetweenStoragesRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/move/betweenStorages`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationMoveBetweenStoragesRequest,
    },
    options,
  )
}
/**
 * @summary ブース内に景品投入
 */
export const putPrizeOperationMoveToBooth = (
  arcadeCd: string,
  putPrizeOperationMoveToBoothRequest: PutPrizeOperationMoveToBoothRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/move/toBooth`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationMoveToBoothRequest,
    },
    options,
  )
}
/**
 * @summary 保管場所に景品戻し
 */
export const putPrizeOperationMoveToStorage = (
  arcadeCd: string,
  putPrizeOperationMoveToStorageRequest: PutPrizeOperationMoveToStorageRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/arcades/${arcadeCd}/prizes/operation/move/toStorage`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putPrizeOperationMoveToStorageRequest,
    },
    options,
  )
}
export type PutPrizeOperationMoveBetweenBoothsResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationMoveBetweenBooths>>
>
export type PutPrizeOperationMoveBetweenStoragesResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationMoveBetweenStorages>>
>
export type PutPrizeOperationMoveToBoothResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationMoveToBooth>>
>
export type PutPrizeOperationMoveToStorageResult = NonNullable<
  Awaited<ReturnType<typeof putPrizeOperationMoveToStorage>>
>
