import React, { useMemo } from "react"

import { CardContent, List, Card } from "@mui/material"
import { useParams } from "react-router-dom"

import { PrizeStorage, PrizeShelf } from "src/api/models"
import { getPrizeStorage, getPrizeStorages } from "src/api/prize-storages"
import { MenuButton } from "src/components/atoms/MenuButton"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { findShelfWithId } from "src/domains/prizes/storageRepository"
import { useResource } from "src/hooks/useResource"
import { theme } from "src/theme"

export const InventoryPrizePlacementStorage = () => {
  const { arcadeCd, storageId, shelfId } = useParams()

  const { resource: storageResource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getPrizeStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getStorage:${arcadeCd}:${storageId}`,
    skip: !storageId,
  })
  const { resource: storagesResource } = useResource({
    subject: "保管場所情報の取得",
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
    skip: !!storageId,
  })
  const { storage, shelf } = useMemo(() => {
    if (storageResource) {
      const { storage, shelves } = storageResource.data
      return {
        storage,
        shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
      }
    }
    if (storagesResource && shelfId) {
      return findShelfWithId(storagesResource.data.storages, shelfId)
    }
    return { storage: undefined, shelf: undefined }
  }, [storageResource, storagesResource, shelfId])

  return (
    <MainContentLayout
      title={storage && shelf ? `${storage.name} ${shelf.name}` : ""}
      renderContent={() => (
        <InventoryPlacementStorageInner storage={storage} shelf={shelf} />
      )}
    />
  )
}

type InventoryPlacementStorageInnerProps = {
  storage?: PrizeStorage
  shelf?: PrizeShelf
}

export const InventoryPlacementStorageInner: React.FC<
  InventoryPlacementStorageInnerProps
> = ({ storage, shelf }) => {
  const { isAvailable } = shelf || {}

  return !isAvailable ? (
    <Card>
      <CardContent>無効に設定されています</CardContent>
    </Card>
  ) : (
    <InventoryPlacementStorageMenu
      storageId={storage?.id}
      shelfId={shelf?.id}
    />
  )
}

type InventoryPlacementStorageMenuProps = {
  storageId?: number
  shelfId?: number
}

const InventoryPlacementStorageMenu: React.FC<
  InventoryPlacementStorageMenuProps
> = ({ storageId, shelfId }) => {
  const { arcadeCd } = useParams()

  const menuItems = arcadeCd
    ? [
        {
          href: `/arcades/${arcadeCd}/prizes/placements/storage/${storageId}/${shelfId}/prizes`,
          title: "保管されている景品一覧",
        },
        {
          href: `/arcades/${arcadeCd}/inventory/prizes/execute/storage/${storageId}/${shelfId}`,
          title: "棚卸実施",
        },
      ]
    : []

  return (
    <Card>
      <CardContent>
        <List sx={{ width: "100%", color: theme.palette.primary.main }}>
          {menuItems.map((i) => (
            <MenuButton key={i.title} to={i.href}>
              {i.title}
            </MenuButton>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
