import { Fragment, Suspense, useCallback, useMemo, useState } from "react"

import {
  Print,
  Edit,
  EditNote,
  Launch,
  CategoryOutlined,
} from "@mui/icons-material"
import {
  Typography,
  Stack,
  Tab,
  Tabs,
  Button,
  Link,
  IconButton,
  DialogContent,
  Divider,
  Chip,
  Paper,
} from "@mui/material"
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { PrizeDailyPlan, PrizeDailyPlansElement } from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { Span } from "src/components/atoms/primitives"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import {
  FloorMapBox,
  FloorMapPointBox,
  FloorMapPointBoxProps,
  PrizeDailySalesFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import {
  defaultSearchParams as defaultPlansSearchParams,
  PrizeDailyFilter,
  PrizeDailyFilterSearchParams,
} from "src/components/organisms/prizes/PrizeDailyFilter"
import {
  defaultSearchParams as defaultFloorMapSearchParams,
  PrizeDailyFloorMapFilter,
  PrizeDailyFloorMapFilterSearchParams,
  prizeDailyFloorMapFilterSearchParamsState,
} from "src/components/organisms/prizes/PrizeDailyFloorMapFilter"
import {
  SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH,
  SelectablePaginatedTable,
} from "src/components/organisms/SelectablePaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { isPrizeDailyPlanUpdatable } from "src/domains/prizes/dailyRepository"
import {
  BaseFloorMapPoint,
  convertPrizePlanFloorMapPoint,
  PrizePlanFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"
import { getJpDateLabel } from "src/utils"

const tabs = [
  { key: "prize", name: "投入景品" },
  { key: "floorMap", name: "投入計画マップ" },
] as const
type PrizeDailyTab = (typeof tabs)[number]["key"]

const prizeDailyTabState = atom<PrizeDailyTab>({
  key: "prizeDailyTabState",
  default: tabs[0].key,
})

export const PrizeDaily: React.FC = () => {
  const { arcadeCd } = useParams()

  // 画面遷移して戻ってきたときにタブの状態を保持するために recoil を使用
  const [tab, setTab] = useRecoilState(prizeDailyTabState)

  return (
    <MainContentLayout
      title="ブース別投入景品一覧"
      renderContent={() => (
        <>
          <Stack sx={{ pb: 2 }}>
            <Tabs
              value={tab}
              onChange={(_, value) => setTab(value)}
              scrollButtons={false}
            >
              {tabs.map((t) => (
                <Tab key={t.key} value={t.key} label={t.name} />
              ))}
            </Tabs>
          </Stack>

          <Stack>
            {tab === "prize" && (
              <>
                <Stack sx={{ pb: 2 }}>
                  <PrizeDailyFilter />
                </Stack>
                <Suspense fallback={<LoadingBox />}>
                  <PrizeDailyPlans />
                </Suspense>
              </>
            )}
            {tab === "floorMap" && (
              <>
                <Paper
                  sx={{
                    px: 3,
                    py: 3.5,
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Stack sx={{ flex: 1 }}>
                    <PrizeDailyFloorMapFilter />
                  </Stack>
                  <Button
                    variant="outlined"
                    startIcon={<Print />}
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/prizes/plans/daily/floorMap/printSettings`}
                  >
                    印刷設定
                  </Button>
                </Paper>
                <Suspense fallback={<LoadingBox />}>
                  <PrizeDailyFloorMap />
                </Suspense>
              </>
            )}
          </Stack>
        </>
      )}
    />
  )
}

type TableData = PrizeDailyPlansElement
const PrizeDailyPlans: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const searchParams: PrizeDailyFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)["prizeDailySearchParams"] ??
    defaultPlansSearchParams

  const [selectedDailyPlans, setSelectedDailyPlans] = useState<
    PrizeDailyPlansElement[]
  >([])

  const {
    dateRangeLabel: { start, end },
    boothName,
    boothCategory,
    prizeCd,
    prizeName,
    sortBy,
  } = searchParams

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: start,
            to: end,
            boothCategory,
            prizeCd,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${start}:${end}:${boothCategory}:${prizeCd}`,
  }).resource
  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans

  const tableData: TableData[] = useMemo(() => {
    const filteredPlans =
      prizeDailyPlans
        ?.filter((prizeDailyPlan) => {
          if (!prizeName && !boothName) return true
          return (
            (prizeName &&
              prizeDailyPlan.plan?.prize.prizeName?.includes(prizeName)) ||
            (boothName && prizeDailyPlan.plan?.boothName?.includes(boothName))
          )
        })
        .sort((a, b) => {
          if (!a.plan || !b.plan) return 1

          if (
            sortBy === "boothCategoryAsc" &&
            a.plan.boothCategory !== b.plan.boothCategory
          ) {
            return a.plan.boothCategory > b.plan.boothCategory ? 1 : -1
          }

          return a.plan.recordedAt > b.plan.recordedAt ? -1 : 1
        }) ?? []

    return filteredPlans.map((prizeDailyPlan) => ({
      ...prizeDailyPlan,
    }))
  }, [boothName, prizeDailyPlans, prizeName, sortBy])

  return (
    <>
      <Stack
        sx={{
          pb: 2,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          {selectedDailyPlans.length > 0 && (
            <Typography variant="body2" sx={{ mr: 2 }}>
              {selectedDailyPlans.length}件選択中
            </Typography>
          )}
          <Button
            variant="outlined"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<Edit />}
            disabled={selectedDailyPlans.length === 0}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${
                  searchParams.dateRangeLabel.start
                }&to=${
                  searchParams.dateRangeLabel.end
                }&prizeDailyPlanIds=${selectedDailyPlans
                  .map(({ plan }) => plan.id)
                  .join(",")}`,
              )
            }
          >
            複数ブース編集
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<EditNote />}
            onClick={() =>
              navigate(`/arcades/${arcadeCd}/prizes/plans/daily/bulk`)
            }
          >
            一括入力
          </Button>
          <Button
            variant="outlined"
            sx={{ mr: 2, whiteSpace: "nowrap" }}
            startIcon={<CategoryOutlined />}
            endIcon={<Launch />}
            onClick={() =>
              // 選択中の開始日、終了日を反映した状態で着荷予定景品一覧を開く
              window.open(
                `/arcades/${arcadeCd}/prizes/register/deliveries?${new URLSearchParams({ start, end })}`,
                "_blank",
              )
            }
          >
            着荷予定景品一覧
          </Button>
        </Stack>
      </Stack>
      <PrizeDailyTable
        tableData={tableData}
        selectedDailyPlans={selectedDailyPlans}
        setSelectedDailyPlans={setSelectedDailyPlans}
      />
    </>
  )
}

interface PrizeDailyTableProps {
  tableData: TableData[]
  selectedDailyPlans: TableData[]
  setSelectedDailyPlans: React.Dispatch<React.SetStateAction<TableData[]>>
}

const PrizeDailyTable: React.FC<PrizeDailyTableProps> = ({
  tableData,
  selectedDailyPlans,
  setSelectedDailyPlans,
}) => {
  const stateKey = "prizeDailyTable"
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const searchParams: PrizeDailyFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)["prizeDailySearchParams"] ??
    defaultPlansSearchParams

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 360px)",
      }}
    >
      <SelectablePaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={tableData}
        disabledItems={tableData.filter(
          (plan) => !isPrizeDailyPlanUpdatable(plan.plan.recordedAt),
        )}
        stateKey={stateKey}
        renderHeaderCells={() => (
          <>
            <ExtTableCell
              sticky
              zIndex={4}
              fixedWidth={124}
              sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH }}
            >
              日付
            </ExtTableCell>
            <ExtTableCell
              border
              sticky
              zIndex={4}
              fixedWidth={180}
              sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH + 124 }}
            >
              プライズ機種名（ブース名）
            </ExtTableCell>
            <ExtTableCell fixedWidth={162}>ブース区分</ExtTableCell>
            <ExtTableCell fixedWidth={162}>景品CD</ExtTableCell>
            <ExtTableCell fixedWidth={162}>設定</ExtTableCell>
            <ExtTableCell fixedWidth={260}>景品名</ExtTableCell>
          </>
        )}
        renderRowCells={({ plan, booth }) => {
          return (
            <Fragment key={plan?.id}>
              <ExtTableCell
                sticky
                zIndex={3}
                sx={{ left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH }}
              >
                {getJpDateLabel(plan.recordedAt)}
              </ExtTableCell>
              <ExtTableCell
                border
                sticky
                zIndex={3}
                sx={{
                  left: SELECTABLE_PAGINATED_TABLE_CHECKBOX_COL_WIDTH + 124,
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flex: 1,
                  }}
                >
                  <Link
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/prizes/plans/daily/booth/${booth.boothName}?from=${searchParams.dateRangeLabel.start}&to=${searchParams.dateRangeLabel.end}`}
                    underline="none"
                    sx={{ fontWeight: "bold" }}
                  >
                    {booth.boothName}
                  </Link>
                  <Stack sx={{ p: 1 }}>
                    <IconButton
                      color="primary"
                      disabled={!isPrizeDailyPlanUpdatable(plan.recordedAt)}
                      onClick={() =>
                        navigate(
                          `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${searchParams.dateRangeLabel.start}&to=${searchParams.dateRangeLabel.end}&prizeDailyPlanIds=${plan?.id}`,
                        )
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Stack>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isBoothCategoryChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.boothCategory}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isPrizePlanChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan?.prize.prizeCd}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {plan.isSettingChanged && (
                    <Chip label="当日更新" color="info" size="small" />
                  )}
                  <Typography variant="body2">{plan.setting}</Typography>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>{plan?.prize.prizeName}</ExtTableCell>
            </Fragment>
          )
        }}
        checkIsSameItem={(a, b) => a.plan.id === b.plan.id}
        selectedItems={selectedDailyPlans}
        onChangeSelectedItems={(selectedItems) =>
          setSelectedDailyPlans(selectedItems)
        }
      />
    </Stack>
  )
}

const PrizeDailyFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()
  const searchParams: PrizeDailyFloorMapFilterSearchParams = useRecoilValue(
    prizeDailyFloorMapFilterSearchParamsState,
  )

  const floorMapReturn = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource
  const floorMapPoints = floorMapReturn?.data.floorMapPoints

  const date = searchParams.datePickerDateLabel
  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}`,
  }).resource
  const prizeDailyPlans = prizeDailyPlansReturn?.data.plans

  const [selectedPlans, setSelectedPlans] = useState<PrizeDailyPlan[]>([])

  const onClickPoint = (point: BaseFloorMapPoint) => {
    const { plans } = point as PrizePlanFloorMapPoint
    setSelectedPlans(plans)
  }

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <FloorMapPointBox
      point={point}
      onClickPoint={onClickPoint}
      sx={{
        backgroundColor: "transparent",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      <PrizeDailySalesFloorMapPointBox key={point.id} point={point} />
    </FloorMapPointBox>
  )

  const planFloorMapPoints: PrizePlanFloorMapPoint[] = useMemo(
    () =>
      (floorMapPoints || []).map((p) =>
        convertPrizePlanFloorMapPoint(p, prizeDailyPlans || []),
      ),
    [floorMapPoints, prizeDailyPlans],
  )

  const renderDescription = useCallback(
    () => (
      <Typography variant="caption">
        ※前日までの入力情報から当日変更があった場合、ブースが
        <Span sx={{ fontWeight: "bold", color: "sub.cyan" }}>水色</Span>
        になります
      </Typography>
    ),
    [],
  )

  return (
    <>
      {floorMapPoints && (
        <FloorMapBox
          floorMapPoints={planFloorMapPoints}
          onClickPoint={onClickPoint}
          getFloorMapPointBox={getFloorMapPointBox}
          renderDescription={renderDescription}
        />
      )}
      <PrizeDailyFloorMapModal
        showModal={selectedPlans.length > 0}
        plans={selectedPlans}
        onClose={() => setSelectedPlans([])}
      />
    </>
  )
}

type PrizeDailyFloorMapModalProps = {
  showModal: boolean
  plans: PrizeDailyPlan[]
  onClose: () => void
}

export const PrizeDailyFloorMapModal: React.FC<
  PrizeDailyFloorMapModalProps
> = ({ showModal, plans, onClose }) => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()
  const searchParams: PrizeDailyFloorMapFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "prizeDailyFloorMapSearchParams"
    ] ?? defaultFloorMapSearchParams

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <DialogContent>
        <Stack gap={2}>
          {plans.map((plan, i) => (
            <Stack key={plan.id} gap={2}>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  景品名
                </Typography>
                <Typography>{plan.prize.prizeName}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  プライズ機種名（ブース名）
                </Typography>
                <Typography>{plan.boothName}</Typography>
              </Stack>
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  設定
                </Typography>
                <Typography>
                  {plan.setting !== "" ? plan.setting : "-"}
                </Typography>
              </Stack>
              {plans.length - 1 !== i && <Divider />}
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
        <Button
          variant="contained"
          fullWidth
          onClick={() =>
            navigate(
              `/arcades/${arcadeCd}/prizes/plans/daily/edit?from=${
                searchParams.datePickerDateLabel
              }&to=${searchParams.datePickerDateLabel}&prizeDailyPlanIds=${plans
                .map((plan) => plan.id)
                .join(",")}`,
            )
          }
        >
          編集する
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}
