import { useState, useMemo } from "react"

import { SearchRounded } from "@mui/icons-material"
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  TableHead,
  TableRow,
  Typography,
  alpha,
  Card,
  CardContent,
} from "@mui/material"
import { useRecoilValue } from "recoil"

import {
  PrizePlacementStatus,
  PrizePlacementStatusExecutionStatusEnum,
} from "src/api/models"
import emptyIcon from "src/assets/icon_empty.png"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { currentArcadeState } from "src/recoil"
import { theme } from "src/theme"
import { getJpDateLabel } from "src/utils"

const NOT_SELECTED = "_not_selected" as const

export type InventoryPrizeExecutePlacementListItem<T extends PlacementType> = {
  // NOTE: プライズ機外の場合は shelfId, プライズ機内／上の場合は inventoryFloorMapPointId
  storageId?: T extends "storage" ? number : never
  shelfId?: T extends "storage" ? number : never
  inventoryFloorMapPointId?: T extends "storage" ? never : number
  name: string
  isAvailable: boolean
  isKidsMarket?: T extends "storage" ? boolean : never
  groupName?: string
  executionStatus?: PrizePlacementStatusExecutionStatusEnum
  executedAt?: PrizePlacementStatus["executedAt"]
}

type InventoryExecuteListProps<T extends PlacementType> = {
  placement: T
  groupNames: string[]
  listItems: InventoryPrizeExecutePlacementListItem<T>[]
  handleOnClick: (listItem: InventoryPrizeExecutePlacementListItem<T>) => void
}

export const InventoryPrizeExecuteList = <T extends PlacementType>({
  placement,
  groupNames,
  listItems,
  handleOnClick,
}: InventoryExecuteListProps<T>) => {
  const [selectedGroupName, setSelectedGroupName] =
    useState<string>(NOT_SELECTED)

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} pb={2}>
        <Card>
          <CardContent sx={{ ":last-child": { pb: 2 } }}>
            <TextField
              select
              label="棚卸グループ"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSelectedGroupName(e.target.value)}
              value={selectedGroupName}
            >
              <MenuItem value={NOT_SELECTED}>指定なし</MenuItem>
              {groupNames.map((groupName: string) => (
                <MenuItem value={groupName} key={groupName}>
                  {groupName}
                </MenuItem>
              ))}
            </TextField>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <InventoryPrizeExecuteListMenu
          placement={placement}
          selectedGroupName={selectedGroupName}
          listItems={listItems}
          handleOnClick={handleOnClick}
        />
      </Grid>
    </Grid>
  )
}

type InventoryExecuteListMenuProps<T extends PlacementType> = Omit<
  InventoryExecuteListProps<T>,
  "groupNames"
> & {
  selectedGroupName: string
}
const InventoryPrizeExecuteListMenu = <T extends PlacementType>({
  placement,
  selectedGroupName,
  listItems,
  handleOnClick,
}: InventoryExecuteListMenuProps<T>) => {
  const arcade = useRecoilValue(currentArcadeState)

  const filteredListItems = useMemo(
    () =>
      selectedGroupName !== NOT_SELECTED
        ? listItems.filter(({ groupName }) => groupName === selectedGroupName)
        : listItems,
    [selectedGroupName, listItems],
  )

  return (
    <PaginatedTable
      noMargin
      items={filteredListItems}
      stateKey="inventoryExecuteList"
      header={
        <TableHead>
          <TableRow>
            <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
              {placement === PlacementType.Storage ? "棚" : "プライズ機"}
            </ExtTableCell>
            <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
              棚卸グループ
            </ExtTableCell>
            {arcade?.hasKidsMarket && placement === PlacementType.Storage && (
              <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
                棚の種別
              </ExtTableCell>
            )}
            <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
              確定
            </ExtTableCell>
            <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
              実査日
            </ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(listItem) => {
        const {
          shelfId,
          inventoryFloorMapPointId,
          name,
          groupName,
          executionStatus,
          executedAt,
          isAvailable,
          isKidsMarket,
        } = listItem
        const { Executed, Empty } = PrizePlacementStatusExecutionStatusEnum
        return (
          <TableBorderedRow
            key={
              placement === PlacementType.Storage
                ? shelfId
                : inventoryFloorMapPointId
            }
            sx={{
              td: {
                ...(!isAvailable && {
                  background: alpha(theme.palette.text.disabled, 0.3),
                }),
              },
              "&:hover td": {
                cursor: "pointer",
                background: (theme) => theme.palette.neutral[200],
              },
            }}
            onClick={() => handleOnClick(listItem)}
          >
            <ExtTableCell>{name}</ExtTableCell>
            <ExtTableCell sx={{ p: 1 }}>{groupName}</ExtTableCell>
            {arcade?.hasKidsMarket && placement === PlacementType.Storage && (
              <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
                {isKidsMarket ? "KM景品専用" : ""}
              </ExtTableCell>
            )}
            <ExtTableCell sx={{ whiteSpace: "nowrap", p: 1 }}>
              {executionStatus === Executed && (
                <img
                  src={finishedIcon}
                  alt="済"
                  style={{ width: 32, height: 32 }}
                />
              )}
              {executionStatus === Empty && (
                <img
                  src={emptyIcon}
                  alt="空"
                  style={{ width: 32, height: 32 }}
                />
              )}
              {!isAvailable && (
                <Typography
                  sx={{
                    background: theme.palette.text.disabled,
                    color: "white",
                    p: 1,
                    textAlign: "center",
                  }}
                  variant="subtitle1"
                >
                  無効
                </Typography>
              )}
            </ExtTableCell>
            <ExtTableCell sx={{ px: 1, whiteSpace: "nowrap" }}>
              {getJpDateLabel(executedAt, "")}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
