import { ScrollRestoration, Outlet } from "react-router-dom"

const scrollRestorePaths = ["/prizes/new-arrivals"] as const

export const CustomScrollRestoration: React.FC = () => (
  <>
    <ScrollRestoration
      getKey={(location, _matches) =>
        scrollRestorePaths.some((path) => location.pathname.match(path))
          ? location.key
          : null
      }
    />
    <Outlet />
  </>
)
