import { useMemo } from "react"

import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialMachine } from "src/api/material-machines"
import { MaterialMachine } from "src/api/models"
import { inventoryMaterialExecuteSingleSearchParamsState } from "src/components/organisms/materials/InventoryMaterialExecuteSingleFilter"
import {
  InventoryMaterialExecuteSingle,
  InventoryMaterialExecuteSingleMenuItem,
} from "src/components/templates/InventoryMaterialExecuteSingle"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useInventoryExecuteMaterials } from "src/hooks/useInventoryExecuteMaterials"
import { useResource } from "src/hooks/useResource"
import { formatApiDate } from "src/utils"

export const InventoryMaterialExecuteInMachineMaterialMachineDetails = () => {
  const { arcadeCd, materialMachineId } = useParams()

  const materialMachineReturn = useResource({
    subject: "材料機械の取得",
    fetch:
      arcadeCd && materialMachineId
        ? () => getMaterialMachine(arcadeCd, Number(materialMachineId))
        : undefined,
    recoilKey: `getMaterialMachine:${arcadeCd}:${materialMachineId}`,
  })
  const materialMachine = materialMachineReturn?.resource?.data.materialMachine
  const amMachine = materialMachineReturn?.resource?.data.amMachine
  const placementName = getDisplayMaterialMachineName(
    amMachine,
    materialMachine,
  )

  return (
    <MainContentLayout
      title={placementName + "［内］の材料一覧"}
      renderContent={() => (
        <InventoryMaterialExecuteInMachineMaterialMachineDetailsMenu
          materialMachine={materialMachine}
          refetchMaterialMachine={materialMachineReturn.refetch}
        />
      )}
      disableBackButton
    />
  )
}

type InventoryMaterialExecuteInMachineMaterialMachineDetailsMenuProps = {
  materialMachine?: MaterialMachine
  refetchMaterialMachine: () => void
}

const InventoryMaterialExecuteInMachineMaterialMachineDetailsMenu: React.FC<
  InventoryMaterialExecuteInMachineMaterialMachineDetailsMenuProps
> = ({ materialMachine, refetchMaterialMachine }) => {
  const { arcadeCd } = useParams()

  const machineShelf = useMemo(
    // TODO: fix
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => materialMachine?.machineShelves![0],
    [materialMachine],
  )
  const searchParams = useRecoilValue(
    inventoryMaterialExecuteSingleSearchParamsState,
  )

  const {
    filteredStocks,
    machineStatuses,
    histories,
    refetchStatuses,
    refetchHistories,
    refetchStocks,
  } = useInventoryExecuteMaterials({
    arcadeCd: arcadeCd || "",
    machineShelf: machineShelf,
    searchParams: searchParams,
  })

  const refetch = () => {
    refetchMaterialMachine()
    refetchStatuses()
    refetchHistories()
    refetchStocks()
  }

  const menuItems: InventoryMaterialExecuteSingleMenuItem[] = useMemo(() => {
    const machineStocks =
      (machineShelf &&
        filteredStocks
          ?.map((machineStock) => {
            const {
              material: { materialCd, materialName, unitPerCarton },
            } = machineStock
            const material = {
              materialCd,
              materialName,
              unitPerCarton,
            }
            const history = histories?.find(
              (history) =>
                history.machineStock?.id === machineStock.machineStock.id,
            )
            const executedStock = history?.stock
            const executed = !!history
            return {
              material,
              anyStock: machineStock.machineStock.stock,
              placementStockId: machineStock.machineStock.id,
              placementType: MaterialPlacementType.InMachine,
              executedStock,
              executed,
              executedAt: formatApiDate(history?.executedAt || ""),
            }
          })
          .filter(Boolean)) ??
      []

    const stockMaterialCds = machineStocks.map((s) => s.material.materialCd)
    const { InMachine } = MaterialPlacementType
    const historiesWithoutMachineStock = (histories || [])
      .filter(
        (h) => h.placement === InMachine && h.placementId === machineShelf?.id,
      )
      .filter((h) => !stockMaterialCds.includes(h.materialCd))
      .filter((h) => !h.machineStock || h.machineStock.stock <= 0)
      .map((h) => ({
        material: {
          materialCd: h.materialCd,
          materialName: h.material.materialName,
        },
        placementType: MaterialPlacementType.InMachine,
        executedStock: h.stock,
        executed: true,
        executedAt: h.executedAt,
      }))

    return [...machineStocks, ...historiesWithoutMachineStock]
  }, [machineShelf, filteredStocks, histories])

  return (
    <InventoryMaterialExecuteSingle
      placementType="in_machine"
      placementId={machineShelf?.id}
      placementStocks={filteredStocks}
      placementStatus={machineStatuses[0]}
      menuItems={menuItems}
      histories={histories}
      onFinish={() => refetch()}
    />
  )
}
