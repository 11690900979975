import { capitalize } from "@mui/material"

export const compareByPhoneticOrder = (a: string, b: string) =>
  a.localeCompare(b, "ja")

const katakanaToHiragana = (src: string) => {
  return src.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

const hiraganaToKatakana = (src: string) => {
  return src.replace(/[\u3041-\u3096]/g, (match) => {
    const chr = match.charCodeAt(0) + 0x60
    return String.fromCharCode(chr)
  })
}

const hankakuToZenkaku = (src: string) => {
  const replaced = src.replace(/[A-Za-z0-9!-~]/g, (match) => {
    const chr = match.charCodeAt(0) + 0xfee0
    return String.fromCharCode(chr)
  })
  if (replaced !== src) return replaced
  return src
    .replace(/"/g, "”")
    .replace(/'/g, "’")
    .replace(/`/g, "‘")
    .replace(/\\/g, "￥")
    .replace(/ /g, "　")
    .replace(/~/g, "〜")
}

const zenkakuToHankaku = (src: string) => {
  const replaced = src.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (match) => {
    const chr = match.charCodeAt(0) - 0xfee0
    return String.fromCharCode(chr)
  })
  if (replaced !== src) return replaced
  return (
    src
      .replace(/”/g, '"')
      .replace(/’/g, "'")
      .replace(/‘/g, "`")
      .replace(/￥/g, "\\")
      // eslint-disable-next-line no-irregular-whitespace
      .replace(/　/g, " ")
      .replace(/〜/g, "~")
  )
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&")
}

export const generateFuzzyRegExp = (searchWord: string) => {
  const chars = searchWord.split("").map((char) => {
    const hankaku = zenkakuToHankaku(char)
    const zenkaku = hankakuToZenkaku(char)
    const escapedChar = escapeRegExp(hankaku)
    // 正規表現のエスケープ対象文字の場合はエスケープ後文字(半角)と全角文字
    if (hankaku !== escapedChar) return `(${escapedChar}|${zenkaku})`
    const hiragana = katakanaToHiragana(char)
    const katakana = hiraganaToKatakana(char)
    // 変換後にひらがなとカタカナが同じ場合は漢字か英数字
    if (hiragana === katakana) return `(${hankaku}|${zenkaku})`
    return `(${hiragana}|${katakana})`
  })
  const fuzzyRegExp = new RegExp(`(${chars.join("")})`, "ig")
  return fuzzyRegExp
}

export const convertNameFromSnakeCase = (snakeCaseString: string) =>
  snakeCaseString
    .split("_")
    .map((word) => capitalize(word))
    .join(" ")
