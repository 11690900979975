import {
  CheckCircleOutline,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material"
import { Card, Stack, Typography, Link, useTheme } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

export type AlertCaptionCardProps = {
  label: string
  variant?: "success" | "error"
} & (
  | {
      rightLinkText: string
      rightLinkTo: string
    }
  | {
      rightLinkText?: undefined
      rightLinkTo?: undefined
    }
)
export const AlertCaptionCard: React.FC<AlertCaptionCardProps> = ({
  label,
  variant = "success",
  rightLinkText,
  rightLinkTo,
}) => {
  const theme = useTheme()
  const bgcolor =
    variant === "success" ? theme.palette.gray["50"] : theme.palette.error.light
  return (
    <Card
      sx={{
        display: "flex",
        p: 1,
        bgcolor,
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          {variant === "success" && (
            <CheckCircleOutline
              sx={(theme) => ({
                color: theme.palette.icon.green,
                mr: 1,
              })}
            />
          )}
          {variant === "error" && (
            <ErrorOutlineIcon
              sx={(theme) => ({
                color: theme.palette.error.main,
                mr: 1,
              })}
            />
          )}
          <Typography
            variant="body2"
            color={variant === "success" ? "info.contrastText" : "error.main"}
          >
            {label}
          </Typography>
        </Stack>
        {rightLinkText && rightLinkTo && (
          <Link
            to={rightLinkTo}
            component={RouterLink}
            underline="none"
            sx={(theme) => ({ color: theme.palette.primary.main })}
          >
            <Typography variant="body2" color="info.contrastText">
              {rightLinkText}
            </Typography>
          </Link>
        )}
      </Stack>
    </Card>
  )
}
