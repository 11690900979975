/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type { GetVersionsResponse } from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary バージョン情報を取得
 */
export const getVersions = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetVersionsResponse>(
    { url: `/versions`, method: "GET" },
    options,
  )
}
export type GetVersionsResult = NonNullable<
  Awaited<ReturnType<typeof getVersions>>
>
