import { SetStateAction } from "react"

import { MenuItem, Select, TextField } from "@mui/material"
import { useRecoilState } from "recoil"

import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { filterAccordionSearchState } from "src/recoil"
import { formatApiDate, getMonthEnd, getMonthStart } from "src/utils"

const prizeMonthlyFilterSortByLabels = {
  arriveAtOrderAsc: "着荷日順",
  machineInputDateOrderAsc: "投入可能日順",
} as const

export type PrizeMonthlyFilterSearchParams = {
  machineInputDateRange: DateRangePickerDateLabel
  prizeCd?: string
  prizeName?: string
  prizeNameKana?: string
  makerName?: string
  ipName?: string
  sortBy: "arriveAtOrderAsc" | "machineInputDateOrderAsc"
}

export const defaultSearchParams: PrizeMonthlyFilterSearchParams = {
  machineInputDateRange: {
    start: formatApiDate(getMonthStart()),
    end: formatApiDate(getMonthEnd()),
  },
  sortBy: "arriveAtOrderAsc",
}

export const PrizeMonthlyFilter: React.FC = () => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams =
    recoilSearchParams["prizeMonthlySearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeMonthlyFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeMonthlySearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞込み・並び替え"
      formInputs={[
        {
          name: "machineInputDateRange",
          fullWidth: true,
          label: "投入可能日",
          render: ({ field }) => (
            <DateRangePicker
              startInputLabel="開始日"
              endInputLabel="終了日"
              dateRangeLabel={field.value as DateRangePickerDateLabel}
              setDateRangeLabel={field.onChange}
            />
          ),
        },
        {
          name: "prizeCd",
          label: "景品CD",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeName",
          label: "景品名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "prizeNameKana",
          label: "景品名カナ",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "makerName",
          label: "メーカー名",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} />
          ),
        },
        {
          name: "ipName",
          label: "IP名",
          render: ({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              placeholder="カタカナ部分一致"
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(prizeMonthlyFilterSortByLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
      ]}
    />
  )
}
