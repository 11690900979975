import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

import {
  Prize,
  PrizeDelivery,
  PrizeDeliveryElement,
  PrizeMonthlyPlan,
} from "src/api/models"
import { DateRangePickerDateLabel } from "src/components/atoms/DateRangePicker"
import { formatApiDate } from "src/utils"

dayjs.extend(isBetween)

export const excludePrizeDeliveryEntryCategories = [
  "定番景品",
  "キッズマーケット景品",
]

export const prizeDeliveriesSortByLabels = {
  arriveAtOrderAsc: "着荷日順",
  arriveAtOrderDesc: "着荷日順（降順）",
  machineInputDateOrderAsc: "投入可能日順",
  machineInputDateOrderDesc: "投入可能日順（降順）",
} as const

export type PrizeDeliveriesSortBy = keyof typeof prizeDeliveriesSortByLabels

export const sortFnPrizeDeliveries =
  (sortBy: keyof typeof prizeDeliveriesSortByLabels) =>
  (a: PrizeDeliveryElement, b: PrizeDeliveryElement) => {
    // 着荷日 or 指定投入日
    if (sortBy === "arriveAtOrderAsc" || sortBy === "arriveAtOrderDesc") {
      const aDate = formatApiDate(a.delivery.arriveAt)
      const bDate = formatApiDate(b.delivery.arriveAt)
      if (aDate !== bDate) {
        const isDesc = sortBy === "arriveAtOrderDesc"
        return (isDesc ? -1 : 1) * aDate.localeCompare(bDate)
      }
    } else if (
      sortBy === "machineInputDateOrderAsc" ||
      sortBy === "machineInputDateOrderDesc"
    ) {
      const aDate = formatApiDate(a.delivery.machineInputDate)
      const bDate = formatApiDate(b.delivery.machineInputDate)
      if (aDate !== bDate) {
        const isDesc = sortBy === "machineInputDateOrderDesc"
        return (isDesc ? -1 : 1) * aDate.localeCompare(bDate)
      }
    }

    // 景品名
    if (a.prize.prizeName !== b.prize.prizeName) {
      return a.prize.prizeName.localeCompare(b.prize.prizeName)
    }

    // 入数降順
    if (a.delivery.orderCarton !== b.delivery.orderCarton) {
      return b.delivery.orderCarton - a.delivery.orderCarton
    }

    // ID昇順
    if (a.delivery.id !== b.delivery.id) {
      return a.delivery.id - b.delivery.id
    }

    return 0
  }

export const filterFnPrizeDeliveries =
  (
    filterLabel:
      | {
          machineInputDateRange?: DateRangePickerDateLabel
          arriveAtDateRange?: DateRangePickerDateLabel
        }
      | undefined = undefined,
  ) =>
  (e: PrizeDeliveryElement) => {
    const isExcluded = excludePrizeDeliveryEntryCategories.includes(
      e.delivery.entryCategory,
    )
    if (isExcluded) {
      return false
    }

    if (filterLabel?.machineInputDateRange && e.delivery.machineInputDate) {
      const isBetweenMachineInputDate = dayjs(
        e.delivery.machineInputDate,
      ).isBetween(
        filterLabel.machineInputDateRange.start,
        filterLabel.machineInputDateRange.end,
        "day",
        "[]",
      )
      if (!isBetweenMachineInputDate) {
        return false
      }
    }

    if (filterLabel?.arriveAtDateRange && e.delivery.arriveAt) {
      const isBetweenArriveAtDate = dayjs(e.delivery.arriveAt).isBetween(
        filterLabel.arriveAtDateRange.start,
        filterLabel.arriveAtDateRange.end,
        "day",
        "[]",
      )
      if (!isBetweenArriveAtDate) {
        return false
      }
    }

    return true
  }

export type PrizeDeliveryWithPlan = {
  delivery: PrizeDelivery
  prize: Prize
  plan?: PrizeMonthlyPlan
}

export const getPrizeDeliveriesWithPlan = function (
  prizeDeliveries: PrizeDeliveryElement[],
): PrizeDeliveryWithPlan[] {
  return prizeDeliveries.map((delivery) => {
    return {
      delivery: delivery.delivery,
      prize: delivery.prize,
      plan: delivery.plan,
    }
  })
}

export const getDeliveredQuantityLabel = (
  unitPerCarton: number | undefined,
  orderCarton: number | undefined,
) => {
  if (unitPerCarton !== undefined && orderCarton !== undefined) {
    return (unitPerCarton * orderCarton).toLocaleString("ja-JP")
  }
  return "-"
}
