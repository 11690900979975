import { useCallback } from "react"

import { DeletePrizeOperationStocksRequest } from "src/api/models"
import { deletePrizeOperationStocks } from "src/api/prize-operation-stocks"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useSubmitting } from "src/hooks/useSubmitting"

export const useDeleteInventoryStocks = () => {
  const { submitPromises } = useSubmitting()
  const onDeleteInventoryStocks = useCallback(
    async ({
      arcadeCd,
      placementStockId,
      placement,
    }: {
      arcadeCd: string
      placementStockId: number
      placement: PlacementType
    }) => {
      const request: DeletePrizeOperationStocksRequest = {
        placement,
        placementStockId,
      }

      await submitPromises([
        {
          subject: "在庫の削除",
          showSuccessMessage: true,
          promise: async () => {
            await deletePrizeOperationStocks(arcadeCd, request)
          },
        },
      ])
    },
    [submitPromises],
  )

  return {
    onDeleteInventoryStocks,
  }
}
