import React from "react"

import { DialogContent } from "@mui/material"

import { Prize, PrizeDelivery } from "src/api/models"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { PrizeDetails } from "src/components/organisms/prizes/PrizeDetails"

type PrizeDetailModalProps = {
  showModal: boolean
  onClose: () => void
  prize: Prize
  delivery?: PrizeDelivery
}

export const PrizeDetailModal: React.FC<PrizeDetailModalProps> = ({
  showModal,
  onClose,
  prize,
  delivery,
}) => {
  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <DialogTitleWithClose sx={{ pb: 0, my: 1 }} onClose={onClose} />
      <DialogContent>
        <PrizeDetails
          prize={prize}
          delivery={delivery}
          ListWrapper={React.Fragment}
        />
      </DialogContent>
    </CustomDialog>
  )
}
