/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */

export type GetPrizePlacementTypeStatusResponseStatusEnum =
  (typeof GetPrizePlacementTypeStatusResponseStatusEnum)[keyof typeof GetPrizePlacementTypeStatusResponseStatusEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPrizePlacementTypeStatusResponseStatusEnum = {
  Open: "open",
  Locked: "locked",
} as const
