import { useMemo } from "react"

import { GlobalStyles } from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeBoothSales } from "src/api/prize-sales"
import {
  FloorMapPointBox,
  FloorMapPointBoxProps,
  PrizeDailySalesFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import { FloorMapPaper } from "src/components/organisms/FloorMapPaper"
import {
  PrizePlanWithSaleFloorMapPoint,
  convertPrizePlanWithSalesFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import {
  calcPrizeDailySales,
  PrizeDailySale,
} from "src/domains/prizes/prizeSalesRepository"
import { useAutoPrint } from "src/hooks/useAutoPrint"
import { useResource } from "src/hooks/useResource"
import { currentArcadeState } from "src/recoil"
import { getJpDateLabel, getToday, isValidDateLabel } from "src/utils"

export const PrizeDailySalesFloorMapPrint: React.FC = () => {
  const { arcadeCd } = useParams()
  const currentArcade = useRecoilValue(currentArcadeState)

  const [searchParams] = useSearchParams()

  const dateParams = searchParams.get("date") || ""
  const date = isValidDateLabel(dateParams) ? dateParams : getToday()
  const points = searchParams.get("points") || ""
  const autoPrint = Boolean(searchParams.get("auto"))
  const pageSize = searchParams.get("pageSize") === "a3" ? "a3" : "a4"

  const floorMapReturn = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource
  const floorMapPoints = floorMapReturn?.data.floorMapPoints

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () => getPrizeDailyPlans(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${date}:${date}`,
  }).resource
  const prizeDailyPlans = useMemo(
    () => prizeDailyPlansReturn?.data.plans ?? [],
    [prizeDailyPlansReturn?.data.plans],
  )

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () => getPrizeDeliveries(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${date}:${date}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries || [],
    [prizeDeliveriesReturn],
  )

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: date, to: date })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${date}:${date}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const prizeOrderSales = useMemo(() => {
    const sortFn = (a: PrizeDailySale, b: PrizeDailySale) => {
      return (a.sales || 0) - (b.sales || 0)
    }

    return calcPrizeDailySales(
      prizeBoothSales,
      prizeDailyPlans,
      prizeDeliveries,
      () => true,
      sortFn,
    )
  }, [prizeBoothSales, prizeDailyPlans, prizeDeliveries])

  const planFloorMapPoints: PrizePlanWithSaleFloorMapPoint[] = useMemo(() => {
    // points が指定されていればそのポイントのみ、指定されていなければ全てのポイントを表示する
    const pointIds = points.split(",").map(Number).filter(Boolean)

    return (floorMapPoints || [])
      .filter((p) => pointIds.length === 0 || pointIds.includes(p.id))
      .map((p) =>
        convertPrizePlanWithSalesFloorMapPoint(
          p,
          prizeDailyPlans || [],
          prizeOrderSales.items,
        ),
      )
  }, [points, floorMapPoints, prizeDailyPlans, prizeOrderSales.items])

  const getFloorMapPointBox = ({ point, offset }: FloorMapPointBoxProps) => (
    <FloorMapPointBox
      point={point}
      offset={offset}
      sx={{
        backgroundColor: "transparent",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      <PrizeDailySalesFloorMapPointBox key={point.id} point={point} />
    </FloorMapPointBox>
  )

  // ページを開いたら自動で印刷ダイアログを表示する
  useAutoPrint({
    disabled: !autoPrint,
    isReady: Boolean(currentArcade && planFloorMapPoints),
  })

  if (!currentArcade || !planFloorMapPoints) {
    return null
  }

  return (
    <>
      <GlobalStyles
        styles={{
          // デフォルトでA3横で印刷されるように指定
          "@page": {
            size: `${pageSize.toUpperCase()} landscape`,
            margin: 0,
          },
        }}
      />
      {/* TODO: 複数ページを印刷したい場合 points を分割して複数の FloorMapPaper を表示する */}
      <FloorMapPaper
        pageSize={pageSize}
        title={`${currentArcade.name} フロアマップ ${getJpDateLabel(date)}`}
        points={planFloorMapPoints}
        getFloorMapPointBox={getFloorMapPointBox}
      />
    </>
  )
}
