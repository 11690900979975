import { useMemo } from "react"

import { Checkbox, FormControlLabel, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import {
  InventoryMaterialOutStockFilter,
  materialOperationOutStockSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialOutStockFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { sortMaterialOperationStocks } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { hideEmptyMaterialState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export const InventoryMaterialOut: React.FC = () => {
  const [hideEmptyMaterial, setHideEmptyMaterial] = useRecoilState(
    hideEmptyMaterialState,
  )

  return (
    <MainContentLayout
      title="店外出庫"
      renderFilter={() => (
        <Stack gap={2}>
          <InventoryMaterialOutStockFilter />
          <FormControlLabel
            checked={hideEmptyMaterial}
            onChange={() => setHideEmptyMaterial(!hideEmptyMaterial)}
            control={<Checkbox />}
            disableTypography
            label="0個のものは表示しない"
          />
        </Stack>
      )}
      renderContent={() => <InventoryMaterialStocksTable />}
    />
  )
}

const InventoryMaterialStocksTable = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const hideEmptyMaterial = useRecoilValue(hideEmptyMaterialState)
  const searchParams = useRecoilValue(
    materialOperationOutStockSearchParamsState,
  )

  const { resource } = useResource({
    subject: "在庫検索結果の取得",
    fetch: arcadeCd
      ? () =>
          getMaterialOperationStocks(arcadeCd, {
            materialName: searchParams.materialName,
            materialNameKana: searchParams.materialNameKana,
            materialCd: searchParams.materialCd,
            storageId: searchParams.storageId,
            shelfIds: searchParams.shelfId ? [searchParams.shelfId] : undefined,
            ipName: searchParams.ipName,
            makerName: searchParams.makerName,
          })
      : undefined,
    recoilKey: `getMaterialOperationStocks:${JSON.stringify(searchParams)}`,
  })
  const filteredStocks = useMemo(() => {
    const stocks = resource?.data.stocks || []
    const stocksWithShelfStocks = stocks.filter((stock) =>
      hideEmptyMaterial
        ? stock.shelfStocks.some((s) => s.shelfStock.stock > 0)
        : true,
    )
    return sortMaterialOperationStocks(stocksWithShelfStocks, searchParams)
  }, [hideEmptyMaterial, resource, searchParams])

  return (
    <PaginatedTable
      noMargin
      items={filteredStocks}
      stateKey="inventoryOut-inventoryStocksTable"
      renderRow={(stock) => (
        <TableBorderedRow key={stock.material.materialCd}>
          <ExtTableCell
            sx={{
              color: theme.palette.primary.main,
              "&:hover": {
                cursor: "pointer",
                background: theme.palette.neutral[200],
              },
            }}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/materials/register/out/details/${stock.material.materialCd}`,
              )
            }
          >
            <CardItemNameBox>{stock.material.materialName}</CardItemNameBox>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}
