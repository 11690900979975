import {
  GetPrizePlacementStatusesResponse,
  PrizePlacementStatus,
  PrizePlacementStatusExecutionStatusEnum,
} from "src/api/models"

// NOTE: API 由来の PlacementEnum が複数あるのを共通化するための型
export const PlacementType = {
  Storage: "storage",
  OnBooth: "on_booth",
  InBooth: "in_booth",
} as const
export type PlacementType = (typeof PlacementType)[keyof typeof PlacementType]

export const PlacementValidation = {
  Valid: "valid",
  Invalid: "invalid",
  All: "all",
} as const
export type PlacementValidation =
  (typeof PlacementValidation)[keyof typeof PlacementValidation]

export const PlacementTypeWithAll = {
  ...PlacementType,
  All: "all",
} as const
export type PlacementTypeWithAll =
  (typeof PlacementTypeWithAll)[keyof typeof PlacementTypeWithAll]

export const convertPrizePlacementTypeToURLPath = (
  placementType: PlacementType,
) => {
  if (placementType === "storage") {
    return "storage"
  }
  if (placementType === "in_booth") {
    return "inBooth"
  }
  if (placementType === "on_booth") {
    return "onBooth"
  }
  return ""
}

export const convertURLPathToPrizePlacementType = (
  str: string,
): PlacementType => {
  if (str === "storage") {
    return "storage"
  }
  if (str === "inBooth" || str === "in-booth") {
    return "in_booth"
  }
  if (str === "onBooth" || str === "on-booth") {
    return "on_booth"
  }
  return "storage"
}

export const getPlacementTypeLabel = (placementType: PlacementType) => {
  if (placementType === "storage") {
    return "棚"
  }
  if (placementType === "in_booth") {
    return "ブース内"
  }
  if (placementType === "on_booth") {
    return "ブース上"
  }
  return ""
}

export const getPlacementShelfOrBoothLabel = (placementType: PlacementType) => {
  if (placementType === "storage") {
    return "棚"
  }
  if (placementType === "in_booth" || placementType === "on_booth") {
    return "ブース"
  }
  return ""
}

export const getPlacementTypeText = (placementType: PlacementType) => {
  if (placementType === PlacementType.Storage) {
    return "外"
  }
  if (placementType === PlacementType.InBooth) {
    return "内"
  }
  if (placementType === PlacementType.OnBooth) {
    return "上"
  }
  const _never: never = placementType
  return ""
}

export const getExecutionStatusText = (
  executionStatus: PrizePlacementStatusExecutionStatusEnum,
) => {
  if (executionStatus === PrizePlacementStatusExecutionStatusEnum.Empty) {
    return "空"
  }
  if (executionStatus === PrizePlacementStatusExecutionStatusEnum.Executed) {
    return "済"
  }
  if (executionStatus === PrizePlacementStatusExecutionStatusEnum.NotExecuted) {
    return "null"
  }
  return "null"
}

export type PrizePlacementStatusWithType = PrizePlacementStatus & {
  placementType: PlacementType
}

export const getNotExecutedPlacements = (
  placementStatusRes: GetPrizePlacementStatusesResponse,
): PrizePlacementStatusWithType[] => {
  const { shelfStatuses, boothStatuses, onBoothStatuses } =
    placementStatusRes || {}
  const { NotExecuted } = PrizePlacementStatusExecutionStatusEnum
  const { Storage, InBooth, OnBooth } = PlacementType
  return [
    ...(shelfStatuses || [])
      .filter((s) => s.isAvailable && s.executionStatus === NotExecuted)
      .map((placement) => ({
        ...placement,
        placementType: Storage,
      })),
    ...(boothStatuses || [])
      .filter((s) => s.isAvailable && s.executionStatus === NotExecuted)
      .map((placement) => ({
        ...placement,
        placementType: InBooth,
      })),
    ...(onBoothStatuses || [])
      .filter((s) => s.isAvailable && s.executionStatus === NotExecuted)
      .map((placement) => ({
        ...placement,
        placementType: OnBooth,
      })),
  ]
}
