/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeDeliveriesParams,
  GetPrizeDeliveriesResponse,
  GetPrizeDeliveryResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 発注景品(着荷予定景品)のリストを取得
 */
export const getPrizeDeliveries = (
  arcadeCd: string,
  params: GetPrizeDeliveriesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeDeliveriesResponse>(
    { url: `/arcades/${arcadeCd}/prizes/deliveries`, method: "GET", params },
    options,
  )
}
/**
 * @summary 発注景品(着荷予定景品)の詳細を取得
 */
export const getPrizeDelivery = (
  arcadeCd: string,
  prizeDeliveryId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeDeliveryResponse>(
    {
      url: `/arcades/${arcadeCd}/prizes/deliveries/${prizeDeliveryId}`,
      method: "GET",
    },
    options,
  )
}
export type GetPrizeDeliveriesResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeDeliveries>>
>
export type GetPrizeDeliveryResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeDelivery>>
>
