import React, {
  useMemo,
  useState,
  useCallback,
  SetStateAction,
  FormEventHandler,
} from "react"

import { ReportProblemOutlined } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Typography,
  FormControlLabel,
  Checkbox,
  TableHead,
  TableRow,
  Switch,
  Card,
  CardContent,
  DialogContent,
  Button,
  Stack,
  Select,
  MenuItem,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useRecoilState } from "recoil"

import { getArcades } from "src/api/arcades"
import { getAreas } from "src/api/areas"
import { Area, Csv, FeatureExecutionPeriodStatusEnum } from "src/api/models"
import { getSettings, patchSettings } from "src/api/settings"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { Span } from "src/components/atoms/primitives"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { DatePicker } from "src/components/organisms/DatePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { ArcadeDetail, getArcadeDetails } from "src/domains/arcadeRepository"
import {
  executionPeriodsSortLabel,
  lastEndAtFilterLabels,
  featureExecutionStatusLabels,
  LastExecutionPeriodSearchParams,
  filterArcadeDetailsByLastExecutionPeriods,
} from "src/domains/inventoryExecutionPeriodRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useLoading } from "src/hooks/useLoading"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { filterAccordionSearchState, meState } from "src/recoil"
import { filterUniqueArray, getToday } from "src/utils"

export const LastExecutionPeriods: React.FC = () => {
  const navigate = useNavigate()

  return (
    <MainContentLayout
      title="棚卸確定ステータス"
      renderContent={() => <LastExecutionPeriodsInner />}
      onClickBackButton={() => navigate("/my-page")}
    />
  )
}

const defaultSearchParams: LastExecutionPeriodSearchParams = {
  lastEndAt: getToday(),
  lastEndAtFilter: "endAtOlder",
  prizeExecutionStatus: undefined,
  materialExecutionStatus: undefined,
  areaId: undefined,
  sortBy: "",
}

const LastExecutionPeriodsInner: React.FC = () => {
  const me = useRecoilValue(meState)
  const { isAdmin } = useUserRole()

  const { resource: settingsResource, refetch: settingsRefetch } = useResource({
    subject: "全店舗棚卸カウント無し状態の取得",
    fetch: getSettings,
    recoilKey: `getSettings`,
    skip: !isAdmin,
  })
  const isEnableSkip = settingsResource?.data.isExecutionSkippable

  const searchParams: LastExecutionPeriodSearchParams =
    useRecoilValue(filterAccordionSearchState)[
      "lastExecutionPeriodSearchParams"
    ] ?? defaultSearchParams

  const { downloadCsv } = useDownloadCsv()

  const userArcadeCds =
    me?.userArcades
      .map((arcade) => arcade.arcadeCd)
      ?.filter(filterUniqueArray) || []
  const userAreaIds =
    me?.userArcades.map((arcade) => arcade.areaId)?.filter(filterUniqueArray) ||
    []

  const { resource: arcadesResource } = useResource({
    subject: "店舗一覧の取得",
    fetch: () => getArcades(),
    recoilKey: `getArcades`,
  })
  const arcades = useMemo(
    () => arcadesResource?.data.arcades || [],
    [arcadesResource],
  ).filter((arcade) =>
    isAdmin ? true : userArcadeCds.includes(arcade.arcadeCd),
  )

  const { resource: areasResource } = useResource({
    subject: "エリアリストの取得",
    fetch: getAreas,
    recoilKey: `getAreas`,
  })
  const areas = useMemo(
    () => areasResource?.data.areas || [],
    [areasResource],
  ).filter((area) => (isAdmin ? true : userAreaIds.includes(area.id)))

  const [showAbolishedArcades, setShowAbolishedArcadesState] =
    useState<boolean>(false)

  const arcadeDetails = useMemo(
    () => getArcadeDetails(arcades, areas),
    [arcades, areas],
  )
  const filteredArcades = useMemo(
    () =>
      filterArcadeDetailsByLastExecutionPeriods(
        arcadeDetails,
        searchParams,
        showAbolishedArcades,
      ),
    [arcadeDetails, searchParams, showAbolishedArcades],
  )

  const [showModal, setShowModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmitSkipSetting: React.FormEventHandler<HTMLFormElement> =
    useCallback(
      async (e) => {
        e.preventDefault()
        await submitPromises([
          {
            subject: "店舗の棚卸カウント無し設定",
            showSuccessMessage: true,
            promise: async () => {
              await patchSettings({
                isExecutionSkippable: !isEnableSkip,
              })
              await settingsRefetch()
            },
          },
        ])
        setShowModal(false)
      },
      [settingsRefetch, submitPromises, isEnableSkip],
    )

  const onClickDownloadCsv = useCallback(async () => {
    const today = getToday()
    const csv: Csv = {
      headerRow: {
        columns: [
          "店舗名",
          "所属エリア",
          "最終棚卸確定日",
          "景品棚卸",
          "材料棚卸",
          "棚卸カウント無し",
          "KM景品取扱",
        ],
      },
      rows: arcadeDetails.map((a) => ({
        columns: [
          a.name,
          a.areaName,
          a.lastEndAt,
          toDisplayExecutionStatus(a.lastPrizeExecutionStatus),
          toDisplayExecutionStatus(a.lastMaterialExecutionStatus),
          a.isSkipExecutionEnabled ? "YES" : "NO",
          a.hasKidsMarket ? "YES" : "NO",
        ],
      })),
    }

    downloadCsv({ csv, fileName: `棚卸確定ステータス_${today}.csv` })
  }, [arcadeDetails, downloadCsv])

  return (
    <>
      {isAdmin && (
        <Stack mb={2}>
          <Card>
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    onClick={() => setShowModal(true)}
                    checked={isEnableSkip}
                    disabled={isSubmitting || !isAdmin}
                  />
                }
                label="全店舗での棚卸機能制御フラグ"
                labelPlacement="start"
                sx={{ span: { fontWeight: "bold" } }}
              />
            </CardContent>
          </Card>
        </Stack>
      )}

      <Stack>
        <LastExecutionPeriodFilter areas={areas} />
      </Stack>

      <Stack direction="row" alignItems="center" py={1}>
        <FormControlLabel
          checked={showAbolishedArcades}
          onChange={() => setShowAbolishedArcadesState(!showAbolishedArcades)}
          control={<Checkbox />}
          disableTypography
          label="廃止された店舗を表示する"
        />
      </Stack>

      <Stack sx={{ maxHeight: "calc(100dvh - 380px)", mb: 2 }}>
        <LastExecutionPeriodsTable arcadeDetails={filteredArcades} />
      </Stack>

      <Stack>
        <Button
          variant="contained"
          sx={{ px: 0, flex: 1 }}
          onClick={onClickDownloadCsv}
        >
          CSVダウンロード
        </Button>
      </Stack>

      <ExecutionFlagModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmit={onSubmitSkipSetting}
        isSubmitting={isSubmitting}
        isEnableSkip={isEnableSkip || false}
      />
    </>
  )
}

interface LastExecutionPeriodFilterProps {
  areas: Array<Area>
}

const LastExecutionPeriodFilter: React.FC<LastExecutionPeriodFilterProps> = ({
  areas,
}: LastExecutionPeriodFilterProps) => {
  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams: LastExecutionPeriodSearchParams =
    recoilSearchParams["lastExecutionPeriodSearchParams"] ?? defaultSearchParams
  const setSearchParams = (
    params: SetStateAction<LastExecutionPeriodSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      lastExecutionPeriodSearchParams: params,
    }))

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      formInputs={[
        {
          name: "lastEndAt",
          label: "最終棚卸確定日",
          render: ({ field }) => <DatePicker hideTodayButton {...field} />,
        },
        {
          name: "lastEndAtFilter",
          label: "期間",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              {Object.entries(lastEndAtFilterLabels).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
        {
          name: "prizeExecutionStatus",
          label: "景品棚卸",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value={undefined} sx={{ height: 36 }} />
              {Object.entries(featureExecutionStatusLabels).map(
                ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value.label}
                  </MenuItem>
                ),
              )}
            </Select>
          ),
        },
        {
          name: "materialExecutionStatus",
          label: "材料棚卸",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value={undefined} sx={{ height: 36 }} />
              {Object.entries(featureExecutionStatusLabels)
                .filter(
                  ([_, value]) =>
                    value.key === FeatureExecutionPeriodStatusEnum.Active ||
                    value.key === FeatureExecutionPeriodStatusEnum.Ended,
                )
                .map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value.label}
                  </MenuItem>
                ))}
            </Select>
          ),
        },
        {
          name: "areaId",
          label: "エリア名",
          render: ({ field }) => (
            <SearchAutoComplete
              items={areas.map((a) => ({
                label: a.name,
                value: a.id,
              }))}
              {...field}
            />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field} fullWidth>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(executionPeriodsSortLabel).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}

const toDisplayExecutionStatus = (
  status: FeatureExecutionPeriodStatusEnum | undefined,
) => {
  switch (status) {
    case "active":
      return "未実査"
    case "ended":
      return "全実査済"
    case "skipped":
      return "カウント無し済"
    case "kids_market_ended":
      return "KM景品のみ済"
    default:
      return "-"
  }
}

const LastExecutionPeriodsTable: React.FC<{
  arcadeDetails: Array<ArcadeDetail>
}> = ({ arcadeDetails }) => {
  return (
    <PaginatedTable
      scrollableY
      stickyHeader
      items={arcadeDetails}
      header={
        <TableHead sx={{ th: { textAlign: "center" } }}>
          <TableRow>
            <ExtTableCell border sticky fixedWidth={280} zIndex={100}>
              店舗名
            </ExtTableCell>
            <ExtTableCell fixedWidth={180}>所属エリア</ExtTableCell>
            <ExtTableCell fixedWidth={120}>最終棚卸確定日</ExtTableCell>
            <ExtTableCell fixedWidth={140}>景品棚卸</ExtTableCell>
            <ExtTableCell fixedWidth={140}>材料棚卸</ExtTableCell>
            <ExtTableCell fixedWidth={120}>棚卸カウント 無し</ExtTableCell>
            <ExtTableCell fixedWidth={120}>KM景品取扱</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(a) => {
        return (
          <TableRow
            hover
            key={a.arcadeCd}
            data-testid={a.arcadeCd}
            sx={{ td: { px: 1, py: 2 } }}
          >
            <ExtTableCell border sticky zIndex={99}>
              {a.name}
            </ExtTableCell>
            <ExtTableCell align="center">{a.areaName}</ExtTableCell>
            <ExtTableCell align="center">
              {a.lastEndAt !== "" ? a.lastEndAt + " 23:59:00" : a.lastEndAt}
            </ExtTableCell>
            <ExtTableCell align="center">
              <Stack
                direction={"row"}
                textAlign="center"
                justifyContent="center"
                gap={0.5}
              >
                {a.lastPrizeExecutionStatus === "active" && (
                  <ReportProblemOutlined sx={{ color: "warning.main" }} />
                )}
                <Span
                  sx={{
                    ...(a.lastPrizeExecutionStatus === "active" && {
                      color: "warning.main",
                    }),
                  }}
                >
                  {toDisplayExecutionStatus(a.lastPrizeExecutionStatus)}
                </Span>
              </Stack>
            </ExtTableCell>
            <ExtTableCell align="center">
              <Stack
                direction={"row"}
                textAlign="center"
                justifyContent="center"
                gap={0.5}
              >
                {a.lastMaterialExecutionStatus === "active" && (
                  <ReportProblemOutlined sx={{ color: "warning.main" }} />
                )}
                <Span
                  sx={{
                    ...(a.lastMaterialExecutionStatus === "active" && {
                      color: "warning.main",
                    }),
                  }}
                >
                  {toDisplayExecutionStatus(a.lastMaterialExecutionStatus)}
                </Span>
              </Stack>
            </ExtTableCell>
            <ExtTableCell align="center">
              <Switch checked={a.isSkipExecutionEnabled} disabled={true} />
            </ExtTableCell>
            <ExtTableCell align="center">
              <Switch checked={a.hasKidsMarket} disabled={true} />
            </ExtTableCell>
          </TableRow>
        )
      }}
    />
  )
}

interface ExecutionFlagModalProps {
  showModal: boolean
  setShowModal: (value: SetStateAction<boolean>) => void
  onSubmit: FormEventHandler<HTMLFormElement>
  isSubmitting: boolean
  isEnableSkip: boolean
}

const ExecutionFlagModal: React.FC<ExecutionFlagModalProps> = ({
  showModal,
  setShowModal,
  onSubmit,
  isSubmitting,
  isEnableSkip,
}) => {
  const { isAdmin } = useUserRole()

  return (
    <CustomDialog
      fullWidth
      maxWidth="sm"
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <form onSubmit={onSubmit}>
        <DialogTitleWithClose onClose={() => setShowModal(false)}>
          <Typography variant="h1">棚卸機能制御フラグ</Typography>
        </DialogTitleWithClose>

        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            全店舗での棚卸機能制御フラグを
            <Typography fontWeight="bold" color="red">
              {isEnableSkip ? "実査" : "棚卸カウント無し"}
            </Typography>
            にします。実行しますか？
          </Typography>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!isAdmin}
            loading={isSubmitting}
            fullWidth
          >
            保存
          </LoadingButton>
        </DialogContent>
      </form>
    </CustomDialog>
  )
}
