/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  PutUserPasswordResetForceResponse,
  RefreshRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignInRequest,
  SignInResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary ログイン
 */
export const signin = (
  signInRequest: SignInRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SignInResponse>(
    {
      url: `/signin`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: signInRequest,
    },
    options,
  )
}
/**
 * @summary パスワードリセット
 */
export const resetPassword = (
  resetPasswordRequest: ResetPasswordRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ResetPasswordResponse>(
    {
      url: `/password/reset`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: resetPasswordRequest,
    },
    options,
  )
}
/**
 * @summary リフレッシュ
 */
export const refreshToken = (
  refreshRequest: RefreshRequest,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SignInResponse>(
    {
      url: `/refresh`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: refreshRequest,
    },
    options,
  )
}
/**
 * @summary ログアウト
 */
export const signOut = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/signout`, method: "POST" }, options)
}
/**
 * @summary ユーザーのパスワードを強制的にリセット
 */
export const putUserPasswordResetForce = (
  userId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PutUserPasswordResetForceResponse>(
    { url: `/users/${userId}/password/reset`, method: "PUT" },
    options,
  )
}
/**
 * @summary 店舗ユーザー(Staff|Cheif)のパスワードを強制的にリセット
 */
export const putArcadeUserPasswordResetForce = (
  arcadeCd: string,
  userId: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PutUserPasswordResetForceResponse>(
    {
      url: `/users/arcades/${arcadeCd}/user/${userId}/password/reset`,
      method: "PUT",
    },
    options,
  )
}
export type SigninResult = NonNullable<Awaited<ReturnType<typeof signin>>>
export type ResetPasswordResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>
export type RefreshTokenResult = NonNullable<
  Awaited<ReturnType<typeof refreshToken>>
>
export type SignOutResult = NonNullable<Awaited<ReturnType<typeof signOut>>>
export type PutUserPasswordResetForceResult = NonNullable<
  Awaited<ReturnType<typeof putUserPasswordResetForce>>
>
export type PutArcadeUserPasswordResetForceResult = NonNullable<
  Awaited<ReturnType<typeof putArcadeUserPasswordResetForce>>
>
