/* eslint-disable */
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * u1-api
 * OpenAPI spec version: 1.0.0
 */
import type {
  GetPrizeToneFloorMapParams,
  GetPrizeToneFloorMapResponse,
} from "./models"
import { customInstance } from "./mutator/custom-instance"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary フロアマップを取得
 */
export const getPrizeToneFloorMap = (
  arcadeCd: string,
  params: GetPrizeToneFloorMapParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetPrizeToneFloorMapResponse>(
    { url: `/arcades/${arcadeCd}/tone/floorMap`, method: "GET", params },
    options,
  )
}
export type GetPrizeToneFloorMapResult = NonNullable<
  Awaited<ReturnType<typeof getPrizeToneFloorMap>>
>
