import { useEffect, useState } from "react"

import { useRecoilState } from "recoil"

import { FloorMapBoxProps } from "src/components/organisms/FloorMapBox"
import { BaseFloorMapPoint } from "src/domains/prizes/floorMapRepository"
import { floorMapScrollPositionState, floorMapZoomRangeState } from "src/recoil"

interface FloorMapPosition {
  x: number
  y: number
}
export type FloorMapScrollPosition = FloorMapPosition
type FloorMapSize = FloorMapPosition

const getFloorMapSize = (floorMapPoints: BaseFloorMapPoint[]): FloorMapSize =>
  floorMapPoints.reduce(
    (currentMax, point) => ({
      x: Math.max(currentMax.x, point.bottomRightX),
      y: Math.max(currentMax.y, point.bottomRightY),
    }),
    { x: 0, y: 0 },
  )

export interface RectCoords {
  startX: number
  startY: number
  endX: number
  endY: number
  width: number
  height: number
}

export const getRectCoords = (point: BaseFloorMapPoint): RectCoords => {
  const { topLeftX, topLeftY, bottomRightX, bottomRightY } = point

  const [startX, startY] = [topLeftX, topLeftY]
  const [endX, endY] = [bottomRightX, bottomRightY]
  const width = endX - startX
  const height = endY - startY

  return {
    startX,
    startY,
    endX,
    endY,
    width,
    height,
  }
}

const getUpperLeftPoint = (points: BaseFloorMapPoint[]) => {
  const minStartY = Math.min(...points.map((a) => a.topLeftY))
  const uppermostPoints = points.filter((a) => a.topLeftY === minStartY)
  if (uppermostPoints.length === 1) {
    return uppermostPoints[0]
  }
  const minStartX = Math.min(...uppermostPoints.map((a) => a.topLeftX))
  const upperLeftPoint = uppermostPoints.find((a) => a.topLeftX === minStartX)
  return upperLeftPoint || points[0]
}

const getZoomRatio = (zoomRange: number) => 0.5 * (zoomRange / 50)

const calcScrollTo = (
  card: HTMLDivElement,
  pointToScroll: BaseFloorMapPoint,
  zoomRatio: number,
) => {
  const { topLeftX, topLeftY, bottomRightX, bottomRightY } = pointToScroll
  const [pointCenterInCanvasX, pointCenterInCanvasY] = [
    (topLeftX + bottomRightX) / 2,
    (topLeftY + bottomRightY) / 2,
  ]
  const [pointCenterX, pointCenterY] = [
    pointCenterInCanvasX * zoomRatio,
    pointCenterInCanvasY * zoomRatio,
  ]
  const { clientWidth, clientHeight } = card
  const [scrollToX, scrollToY] = [
    pointCenterX - clientWidth / 2,
    pointCenterY - clientHeight / 2,
  ]

  return { scrollToX, scrollToY }
}

export const useFloorMap = (
  cardRef: React.RefObject<HTMLDivElement>,
  floorMapPoints: BaseFloorMapPoint[],
  shouldScroll?: FloorMapBoxProps["shouldScroll"],
) => {
  const floorMapSize = getFloorMapSize(floorMapPoints)
  const [pointToScroll, setPointToScroll] = useState<BaseFloorMapPoint>()

  useEffect(() => {
    if (!shouldScroll) {
      return
    }
    const scrollTargetPoints = floorMapPoints.filter((point) =>
      shouldScroll(point),
    )
    setPointToScroll(getUpperLeftPoint(scrollTargetPoints))
  }, [floorMapPoints, shouldScroll])

  const [zoomRange, setZoomRange] = useRecoilState(floorMapZoomRangeState)
  const minZoomRange = 1
  const maxZoomRange = 100
  const zoomRatio = getZoomRatio(zoomRange)

  const [scrollPosition, setScrollPosition] = useRecoilState(
    floorMapScrollPositionState,
  )

  const onChangeSlider = (value: number) => {
    if (!cardRef.current) {
      return
    }

    const newZoomRange = Math.min(Math.max(value, minZoomRange), maxZoomRange)
    if (newZoomRange === zoomRange) {
      return
    }

    const { clientWidth, clientHeight } = cardRef.current
    const gradientRatio = newZoomRange / zoomRange
    // NOTE: ズーム後の左上座標 = (ズーム前の左上座標 + ズームによる左上〜中央の距離の変化量) * ズームでマップ全体の大きさが変わる倍率
    const newScrollPosition = {
      x:
        (scrollPosition.x + (1 - gradientRatio ** -1) * (clientWidth / 2)) *
        gradientRatio,
      y:
        (scrollPosition.y + (1 - gradientRatio ** -1) * (clientHeight / 2)) *
        gradientRatio,
    }
    cardRef.current.scroll({
      left: newScrollPosition.x,
      top: newScrollPosition.y,
    })
    setZoomRange(newZoomRange)
    setScrollPosition(newScrollPosition)
  }

  useEffect(() => {
    // NOTE: 負荷軽減のため、スクロール完了時の 1 回のみ Recoil に保存
    // ref: https://stackoverflow.com/questions/4620906/how-do-i-know-when-ive-stopped-scrolling
    let timer: NodeJS.Timeout | null = null
    const callback = () => {
      if (!cardRef.current) {
        return
      }
      const { scrollLeft, scrollTop } = cardRef.current
      setScrollPosition({ x: scrollLeft, y: scrollTop })
    }

    if (!cardRef.current) {
      return
    }
    cardRef.current.onscroll = () => {
      if (timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => callback(), 150)
    }
  }, [cardRef, setScrollPosition])

  useEffect(() => {
    if (!cardRef.current || !pointToScroll) {
      return
    }

    const { scrollToX, scrollToY } = calcScrollTo(
      cardRef.current,
      pointToScroll,
      zoomRatio,
    )
    setScrollPosition({ x: scrollToX, y: scrollToY })
  }, [pointToScroll, zoomRatio, cardRef, setScrollPosition])

  useEffect(() => {
    const { x, y } = scrollPosition
    const { scrollLeft, scrollTop } = cardRef.current || {}
    if (
      Math.max(0, Math.floor(x)) !== scrollLeft ||
      Math.max(0, Math.floor(y)) !== scrollTop
    ) {
      cardRef.current?.scroll({ left: x, top: y })
    }
  }, [scrollPosition, cardRef])

  return {
    floorMapSize,
    zoomRatio,
    zoomRange,
    onChangeSlider,
  }
}
