import { Event } from "@sentry/react"

// ref: https://docs.sentry.io/platforms/javascript/configuration/filtering/
export const isRefreshError = (event: Event): boolean => {
  return (
    event.breadcrumbs?.some((breadcrumb) => {
      if (!breadcrumb.data) {
        return false
      }
      const method = breadcrumb.data["method"]
      const url = breadcrumb.data["url"]
      const statusCode = breadcrumb.data["status_code"]

      return (
        method === "POST" && url?.includes("/api/refresh") && statusCode === 401
      )
    }) || false
  )
}
