import { SetStateAction, useMemo } from "react"

import { Edit } from "@mui/icons-material"
import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Link,
  IconButton,
} from "@mui/material"
import dayjs from "dayjs"
import { Link as RouterLink, useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { DatePicker } from "src/components/organisms/DatePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  defaultSearchParams as defaultPrizeMonthlySearchParams,
  PrizeMonthlyFilter,
  PrizeMonthlyFilterSearchParams,
} from "src/components/organisms/prizes/monthly/PrizeMonthlyFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  filterFnPrizeDeliveries,
  getDeliveredQuantityLabel,
  getPrizeDeliveriesWithPlan,
  PrizeDeliveryWithPlan,
  sortFnPrizeDeliveries,
} from "src/domains/prizes/deliveryRepository"
import { useResource } from "src/hooks/useResource"
import { filterAccordionSearchState } from "src/recoil"
import {
  getMonthEnd,
  getMonthStart,
  formatApiDate,
  getJpDateLabel,
  getRatioLabel,
  shouldDisableMonth,
} from "src/utils"

type PrizeMonthlyFromState = string

const defaultMonth = formatApiDate(getMonthStart())

export const PrizeMonthly: React.FC = () => {
  return (
    <MainContentLayout
      title="プライズ投入計画一覧"
      renderFilter={() => (
        <Stack gap={2}>
          <PrizeMonthlyMonthSelect />
          <Stack>
            <PrizeMonthlyFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => <PrizeMonthlyInner />}
    />
  )
}

const prizeMonthlyMonthState = atom<PrizeMonthlyFromState>({
  key: "prizeMonthlyMonthState",
  default: defaultMonth,
})

const PrizeMonthlyMonthSelect: React.FC = () => {
  const [month, setMonth] = useRecoilState(prizeMonthlyMonthState)

  const [recoilSearchParams, setRecoilSearchParams] = useRecoilState(
    filterAccordionSearchState,
  )
  const searchParams =
    recoilSearchParams["prizeMonthlySearchParams"] ??
    defaultPrizeMonthlySearchParams
  const setSearchParams = (
    params: SetStateAction<PrizeMonthlyFilterSearchParams>,
  ) =>
    setRecoilSearchParams((prev) => ({
      ...prev,
      prizeMonthlySearchParams: params,
    }))

  return (
    <Stack
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
      })}
    >
      <DatePicker
        value={month}
        onChange={(v) => {
          setMonth(v)
          setSearchParams({
            ...searchParams,
            machineInputDateRange: {
              start: formatApiDate(getMonthStart(v)),
              end: formatApiDate(getMonthEnd(v)),
            },
          })
        }}
        format={"YYYY年MM月"}
        views={["year", "month"]}
        openTo="month"
        shouldDisableMonth={shouldDisableMonth}
        hideTodayButton
      />
    </Stack>
  )
}

const PrizeMonthlyInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const month = useRecoilValue(prizeMonthlyMonthState)
  const searchParams: PrizeMonthlyFilterSearchParams =
    useRecoilValue(filterAccordionSearchState)["prizeMonthlySearchParams"] ??
    defaultPrizeMonthlySearchParams
  const {
    prizeCd,
    prizeName,
    prizeNameKana,
    ipName,
    makerName,
    machineInputDateRange,
    sortBy,
  } = searchParams

  const to = useMemo(() => formatApiDate(getMonthEnd(dayjs(month))), [month])

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: month,
            to,
            prizeCd,
            prizeName,
            prizeNameKana,
            ipName,
            makerName,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${month}:${to}:${prizeCd}:${prizeName}:${prizeNameKana}:${ipName}:${makerName}`,
  }).resource
  const prizeDeliveries = prizeDeliveriesReturn?.data.deliveries

  const deliveries: PrizeDeliveryWithPlan[] = useMemo(() => {
    if (!prizeDeliveries) return []

    return getPrizeDeliveriesWithPlan(
      prizeDeliveries
        .filter(filterFnPrizeDeliveries({ machineInputDateRange }))
        .sort(sortFnPrizeDeliveries(sortBy)),
    )
  }, [prizeDeliveries, machineInputDateRange, sortBy])

  return <PrizeMonthlyTable deliveries={deliveries} />
}

interface PrizeMonthlyTableProps {
  deliveries: PrizeDeliveryWithPlan[]
}

const PrizeMonthlyTable: React.FC<PrizeMonthlyTableProps> = ({
  deliveries,
}) => {
  const { arcadeCd } = useParams()

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        stickyHeader
        noMargin
        items={deliveries}
        stateKey="inventoryMaterialStockLedgerTable"
        header={
          <TableHead>
            <TableRow
              sx={{
                th: {
                  py: 2,
                  px: 1,
                  minHeight: "86px",
                  minWidth: "72px",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={216}>
                景品名
              </ExtTableCell>
              <ExtTableCell>1個獲得金額</ExtTableCell>
              <ExtTableCell>初週消化率</ExtTableCell>
              <ExtTableCell>ブース展開数</ExtTableCell>
              <ExtTableCell>料金設定</ExtTableCell>
              <ExtTableCell>ブース区分</ExtTableCell>
              <ExtTableCell border>投入予定機種</ExtTableCell>
              <ExtTableCell>着荷日</ExtTableCell>
              <ExtTableCell>投入可能日</ExtTableCell>
              <ExtTableCell>指定投入日</ExtTableCell>
              <ExtTableCell>チャーター便投入可能日</ExtTableCell>
              <ExtTableCell>画像</ExtTableCell>
              <ExtTableCell>入数</ExtTableCell>
              <ExtTableCell>納品数量（CT）</ExtTableCell>
              <ExtTableCell>納品数量（バラ）</ExtTableCell>
              <ExtTableCell>単価</ExtTableCell>
              <ExtTableCell>キャラクター名</ExtTableCell>
              <ExtTableCell>景品サイズ</ExtTableCell>
              <ExtTableCell>景品物販分類</ExtTableCell>
              <ExtTableCell>ターゲット</ExtTableCell>
              <ExtTableCell>推奨投入機械</ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(delivery) => {
          const {
            id: prizeDeliveryId,
            orderCarton,
            arriveAt,
            machineInputDate,
            specifiedEntryDate,
            charterFlightDate,
          } = delivery.delivery
          const {
            id: prizeMonthlyPlanId,
            expectedCapturePrice,
            expectedConsumptionRate,
            numOfBooths,
            fee,
            playCount,
            boothCategory,
            machine,
          } = delivery.plan || {}
          const {
            prizeCd,
            prizeName,
            unitPerCarton,
            unitPriceJpy,
            prizeCategory,
            ipName,
            targetSegmentName,
            recommendedMachine,
            widthCm,
            depthCm,
            heightCm,
          } = delivery.prize

          return (
            <TableRow
              sx={{
                minHeight: "96px",
                td: {
                  px: 1,
                  py: 2,
                },
              }}
              key={prizeDeliveryId}
            >
              <ExtTableCell border sticky zIndex={99} fixedWidth={216}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    p: 1,
                  }}
                >
                  <Typography variant="caption">{prizeName}</Typography>
                  <Link
                    component={RouterLink}
                    to={`/arcades/${arcadeCd}/prizes/plans/monthly/${prizeDeliveryId}/${prizeMonthlyPlanId || "new"}`}
                  >
                    <IconButton>
                      <Edit color="primary" />
                    </IconButton>
                  </Link>
                </Stack>
              </ExtTableCell>
              <ExtTableCell>{expectedCapturePrice ?? "-"}</ExtTableCell>
              <ExtTableCell>
                {getRatioLabel(expectedConsumptionRate)}
              </ExtTableCell>
              <ExtTableCell>{numOfBooths ?? "-"}</ExtTableCell>
              <ExtTableCell>
                {fee && playCount ? playCount + "回" + fee + "円" : "-"}
              </ExtTableCell>
              <ExtTableCell>{boothCategory ?? "-"}</ExtTableCell>
              <ExtTableCell border>{machine ?? "-"}</ExtTableCell>

              <ExtTableCell>{getJpDateLabel(arriveAt)}</ExtTableCell>
              <ExtTableCell>{getJpDateLabel(machineInputDate)}</ExtTableCell>
              <ExtTableCell>{getJpDateLabel(specifiedEntryDate)}</ExtTableCell>
              <ExtTableCell>{getJpDateLabel(charterFlightDate)}</ExtTableCell>
              <ExtTableCell>
                <PrizeImageBox prizeCd={prizeCd ?? ""} />
              </ExtTableCell>
              <ExtTableCell>{unitPerCarton}</ExtTableCell>
              <ExtTableCell>{orderCarton}</ExtTableCell>
              <ExtTableCell>
                {getDeliveredQuantityLabel(unitPerCarton, orderCarton)}
              </ExtTableCell>
              <ExtTableCell>{unitPriceJpy}</ExtTableCell>
              <ExtTableCell>{ipName}</ExtTableCell>
              <ExtTableCell>
                {widthCm}cm*{depthCm}cm*{heightCm}cm
              </ExtTableCell>
              <ExtTableCell>{prizeCategory}</ExtTableCell>
              <ExtTableCell>{targetSegmentName}</ExtTableCell>
              <ExtTableCell>{recommendedMachine}</ExtTableCell>
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
