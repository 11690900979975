import { useCallback } from "react"

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  DragEndEvent,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { UnfoldMoreRounded } from "@mui/icons-material"
import {
  Box,
  Card,
  Table,
  TableBody,
  Link,
  Stack,
  TableRowProps,
} from "@mui/material"

import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import { theme } from "src/theme"

type TableItem<T> = { id: UniqueIdentifier } & T

interface SortableTableProps<T> {
  items: TableItem<T>[]
  onChangeDrag: (newItems: TableItem<T>[]) => void | Promise<void>
  renderRow: (props: { item: TableItem<T> }) => React.ReactElement
  limit?: number
  header?: React.ReactNode
}

export const SortableTable = <T,>({
  items,
  onChangeDrag,
  renderRow,
  header = <></>,
}: SortableTableProps<T>) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // 5px ドラッグした時にソート機能を有効にする
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (over === null) {
        return
      }
      if (active.id !== over.id) {
        const oldIndex = items
          .map((item) => {
            return item.id
          })
          .indexOf(active.id)
        const newIndex = items
          .map((item) => {
            return item.id
          })
          .indexOf(over.id)
        const newState = arrayMove(items, oldIndex, newIndex)
        onChangeDrag(newState)
      }
    },
    [items, onChangeDrag],
  )

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Card sx={{ m: 1 }}>
          <Box>
            <Table>
              {header}
              <TableBody>{items.map((item) => renderRow({ item }))}</TableBody>
            </Table>
          </Box>
        </Card>
      </SortableContext>
    </DndContext>
  )
}

interface ListTableRowProps {
  id: string | number
  onClick: React.MouseEventHandler<HTMLTableCellElement>
}

export const ItemTypes = {
  SortableListTableRow: "SortableListTableRow",
}

export const SortableListTableRow: React.FC<
  ListTableRowProps & Omit<TableRowProps, "xs" | "styles" | "id">
> = ({ id, onClick, children, ...tableRowProps }) => {
  const { isMobile } = useBreakpoints()

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <>
      <TableBorderedRow
        {...tableRowProps}
        hover
        ref={setNodeRef}
        sx={{ ...style, cursor: "pointer" }}
        onClick={onClick}
        {...attributes}
        {...(!isMobile ? listeners : {})}
      >
        <ExtTableCell sx={{ p: 0 }}>
          <Link underline="none" sx={{ color: theme.palette.neutral[900] }}>
            <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
              <Box
                // https://docs.dndkit.com/api-documentation/sensors/pointer#recommendations
                sx={{ touchAction: "none", p: 3, pr: 0, display: "flex" }}
                {...(isMobile ? listeners : {})}
              >
                <UnfoldMoreRounded />
              </Box>
            </Stack>
          </Link>
        </ExtTableCell>
        {children}
      </TableBorderedRow>
    </>
  )
}
