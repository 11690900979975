import { useEffect } from "react"

import * as Sentry from "@sentry/react"
import axios from "axios"
import { createRoot } from "react-dom/client"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import { App } from "src/App"
import { getEnv } from "src/env"
import { isRefreshError } from "src/sentry"

axios.defaults.baseURL = getEnv().U1_API_BASE_URL

// ref: https://docs.sentry.io/platforms/javascript/guides/react/
// NOTE: 開発環境およびVaddy環境では sentry にエラーを送信しない (送信したいときは、適宜 ON にする)
if (!(IS_DEBUG || IS_VADDY)) {
  Sentry.init({
    dsn: "https://67694dab26e14cc6829278d482541c25@o1127043.ingest.sentry.io/6309761",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: getEnv().SENTRY_ENVIRONMENT,
    debug: false,
    beforeSend: (event) => (isRefreshError(event) ? null : event),
    attachStacktrace: true,
    ignoreErrors: ["Unexpected token '<'"],
  })
}

// API より先行して開発するときのために、本番環境以外で Mock Service Worker を有効にする
// ref: https://mswjs.io/docs/integrations/browser/#conditionally-enable-mocking
async function enableMocking() {
  const enable = IS_DEBUG || IS_VADDY || IS_STAGING
  if (!enable) return

  const { worker } = await import("./mocks/browser")

  await worker.start({
    // handler が定義されていなくても warning を表示せず実際にリクエストを送信する
    // ref: https://mswjs.io/docs/api/setup-server/listen/#onunhandledrequest
    onUnhandledRequest: "bypass",
  })
}

enableMocking().then(() => {
  const mountNode = document.getElementById("app")
  if (mountNode) {
    const root = createRoot(mountNode)
    root.render(<App />)
  }
})
