import React, { useCallback, useMemo } from "react"

import {
  Typography,
  TableHead,
  TableRow,
  Card,
  Table,
  TableBody,
  Button,
  Stack,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialStockLedger } from "src/api/material-stock-ledger"
import {
  CsvRow,
  Material,
  MaterialStockLedgerHistory,
  MaterialStockLedgerHistoryCategoryEnum,
} from "src/api/models"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import {
  filterStockLedgers,
  InventoryMaterialStockLedgerFilter,
  inventoryMaterialStockLedgerSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialStockLedgerFilter"
import { MaterialStockLedgerDateRangePicker } from "src/components/organisms/MaterialStockLedgerDateRangePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  getLedgerTableItem,
  ledgerTableItemNames,
} from "src/domains/materials/materialStockLedgerRepository"
import { useDownloadCsv } from "src/hooks/useDownloadCsv"
import { useResource } from "src/hooks/useResource"
import { inventoryMaterialStockLedgerDateRangePickerDateLabelStateSelector } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export const InventoryMaterialStockLedger = () => {
  const { arcadeCd, materialCd, placementId } = useParams()

  const { histories, placementName, material } =
    useResource({
      subject: "副帳票の取得",
      fetch:
        arcadeCd && materialCd && placementId
          ? () =>
              getMaterialStockLedger(arcadeCd, materialCd, Number(placementId))
          : undefined,
      recoilKey: `getMaterialStockLedger:${arcadeCd}:${materialCd}:${placementId}`,
    }).resource?.data ?? {}

  return (
    <MainContentLayout
      title="副帳票"
      subtitle={placementName}
      renderFilter={() => (
        <Stack gap={2}>
          <Card sx={{ p: 2 }}>
            <MaterialStockLedgerDateRangePicker />
          </Card>
          <Stack>
            <InventoryMaterialStockLedgerFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => (
        <InventoryMaterialStockLedgerMenu
          ledgerHistories={histories}
          placementName={placementName}
          material={material}
        />
      )}
    />
  )
}

type InventoryMaterialStockLedgerMenuProps = {
  ledgerHistories?: MaterialStockLedgerHistory[]
  placementName?: string
  material?: Material
}

const InventoryMaterialStockLedgerMenu: React.FC<
  InventoryMaterialStockLedgerMenuProps
> = ({ ledgerHistories, placementName, material }) => {
  const searchParams = useRecoilValue(
    inventoryMaterialStockLedgerSearchParamsState,
  )
  const dateRangePickerDateLabel = useRecoilValue(
    inventoryMaterialStockLedgerDateRangePickerDateLabelStateSelector,
  )

  const filteredLedgerHistories = useMemo(() => {
    if (!ledgerHistories) return []
    return filterStockLedgers(ledgerHistories, searchParams).filter(
      (ledgerHistory) =>
        dateRangePickerDateLabel.start <= ledgerHistory.recordedAt &&
        ledgerHistory.recordedAt <=
          dateRangePickerDateLabel.end + "T23:59:59.9", // 副帳票は時刻まで保持しているため、dateRange.end の時刻を調整する
    )
  }, [dateRangePickerDateLabel, ledgerHistories, searchParams])

  const { downloadCsv } = useDownloadCsv()
  const onSubmit = useCallback(async () => {
    if (!filteredLedgerHistories || !placementName || !material) return
    const headerRow = {
      columns: Object.values(ledgerTableItemNames).map((itemName) =>
        itemName.join(""),
      ),
    }

    const rows: CsvRow[] = filteredLedgerHistories.map((ledgerHistory) => {
      const tableItems: { [key: string]: string | number | string[] } =
        getLedgerTableItem(ledgerHistory, material.unitPerCarton)
      return {
        columns: Object.keys(tableItems).map((key) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          tableItems[key]!.toString(),
        ),
      }
    })
    downloadCsv({
      csv: { headerRow, rows },
      fileName: `副帳票CSV_${placementName}-${material.materialName}.csv`,
    })
  }, [filteredLedgerHistories, placementName, material, downloadCsv])

  return (
    <Stack gap={2}>
      {material && <MaterialDetailCard material={material} />}

      <Stack>
        {material && (
          <InventoryMaterialStockLedgerTable
            ledgerHistories={filteredLedgerHistories ?? []}
            material={material}
            placementName={placementName ?? ""}
          />
        )}
      </Stack>
      <Stack>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onSubmit()}
          disabled={!filteredLedgerHistories}
          sx={{ py: 1.375 }}
        >
          <Typography variant="subtitle1">副帳票のCSV出力</Typography>
        </Button>
      </Stack>
    </Stack>
  )
}

interface MaterialDetailCardProps {
  material: Material
}

const MaterialDetailCard: React.FC<MaterialDetailCardProps> = ({
  material,
}) => {
  return (
    <Card sx={{ p: 2 }}>
      <Table>
        <TableBody>
          <TableRow>
            <ExtTableCell sx={{ p: 1, pl: 0, color: theme.palette.gray[50] }}>
              材料名
            </ExtTableCell>
            <ExtTableCell sx={{ p: 0 }}>
              <Typography color={theme.palette.gray[80]} variant="body2">
                {material.materialName}
              </Typography>
            </ExtTableCell>
          </TableRow>
          <TableRow>
            <ExtTableCell sx={{ p: 1, pl: 0, color: theme.palette.gray[50] }}>
              材料CD
            </ExtTableCell>
            <ExtTableCell sx={{ p: 0 }}>
              <Typography color={theme.palette.gray[80]} variant="body2">
                {material.materialCd}
              </Typography>
            </ExtTableCell>
          </TableRow>
          <TableRow>
            <ExtTableCell sx={{ p: 1, pl: 0, color: theme.palette.gray[50] }}>
              1カートン入り数(バラ)
            </ExtTableCell>
            <ExtTableCell sx={{ p: 0 }}>
              <Typography color={theme.palette.gray[80]} variant="body2">
                {material.unitPerCarton}
              </Typography>
            </ExtTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )
}

interface InventoryMaterialStockLedgerTableProps {
  ledgerHistories: MaterialStockLedgerHistory[]
  material: Material
  placementName: string
}

const InventoryMaterialStockLedgerTable: React.FC<
  InventoryMaterialStockLedgerTableProps
> = ({ ledgerHistories, material, placementName }) => {
  const headerNames = ledgerTableItemNames(placementName)
  const getLines = (itemName: string[]) =>
    itemName.map((line, i) => <p key={`${i}-${line}`}>{line}</p>)

  return (
    <Stack sx={{ maxHeight: "calc(100dvh - 420px)" }}>
      <PaginatedTable
        scrollableY
        scrollableX
        noMargin
        items={ledgerHistories}
        stateKey="inventoryMaterialStockLedgerTable"
        stickyHeader
        header={
          <TableHead>
            <TableRow
              sx={{ th: { whiteSpace: "nowrap", textAlign: "center" } }}
            >
              <ExtTableCell border sticky zIndex={100} fixedWidth={160}>
                {getLines(headerNames.recordedAt)}
              </ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockInCt)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockInPcs)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockOutCt)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockOutPcs)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockCt)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.stockPcs)}</ExtTableCell>
              <ExtTableCell fixedWidth={200}>
                {getLines(headerNames.moveFrom)}
              </ExtTableCell>
              <ExtTableCell fixedWidth={200}>
                {getLines(headerNames.moveTo)}
              </ExtTableCell>
              <ExtTableCell>{getLines(headerNames.totalStocksCt)}</ExtTableCell>
              <ExtTableCell>
                {getLines(headerNames.totalStocksPcs)}
              </ExtTableCell>
              <ExtTableCell>{getLines(headerNames.recordedBy)}</ExtTableCell>
              <ExtTableCell>{getLines(headerNames.category)}</ExtTableCell>
              <ExtTableCell fixedWidth={300}>
                {getLines(headerNames.note)}
              </ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={(ledgerHistory) => {
          const item = getLedgerTableItem(ledgerHistory, material.unitPerCarton)
          return (
            <TableBorderedRow
              sx={(theme) => ({
                td: {
                  p: 1,
                  textAlign: "center",
                  ...(ledgerHistory.category ==
                    MaterialStockLedgerHistoryCategoryEnum.Execute && {
                    color: theme.palette.error.main,
                  }),
                },
              })}
              key={ledgerHistory.recordedAt}
              data-testid={ledgerHistory.recordedAt}
            >
              <ExtTableCell border sticky zIndex={99}>
                {item.recordedAt}
              </ExtTableCell>
              <ExtTableCell>{item.stockInCt}</ExtTableCell>
              <ExtTableCell>{item.stockInPcs}</ExtTableCell>
              <ExtTableCell>{item.stockOutCt}</ExtTableCell>
              <ExtTableCell>{item.stockOutPcs}</ExtTableCell>
              <ExtTableCell>{item.stockCt}</ExtTableCell>
              <ExtTableCell>{item.stockPcs}</ExtTableCell>
              <ExtTableCell>{item.moveFrom}</ExtTableCell>
              <ExtTableCell>{item.moveTo}</ExtTableCell>
              <ExtTableCell>{item.totalStocksCt}</ExtTableCell>
              <ExtTableCell>{item.totalStocksPcs}</ExtTableCell>
              <ExtTableCell>{item.recordedBy}</ExtTableCell>
              <ExtTableCell>{item.category}</ExtTableCell>
              <ExtTableCell>{item.note}</ExtTableCell>
            </TableBorderedRow>
          )
        }}
      />
    </Stack>
  )
}
